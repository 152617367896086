import { Link } from 'react-router-dom';

import Logo from '../../assets/images/LogoBranca.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InfoIcon from '@mui/icons-material/Info';
import Modal from '../../../components/Modal';
import { useFooter } from './useFooter';

export default function Footer() {
  const { handleCloseInfosModal, handleInfosIconClick, isInfosModalOpen } =
    useFooter();

  return (
    <footer className="bottom-0 box-border w-full bg-backgroundPrimaryBuffalo text-white">
      <div className="mx-4 box-border flex flex-col items-center gap-6 p-6 md:mx-11 lg:flex-row lg:items-start">
        <div className="box-border flex items-center justify-center md:w-1/6 lg:justify-start">
          <div className="box-border w-full text-center md:text-left">
            <img
              src={Logo}
              className="mx-auto box-border md:mx-0"
              alt="Footer Logo"
            />
          </div>
        </div>
        <div className="box-border flex w-full flex-col items-center justify-around gap-3 text-center md:w-5/6 lg:flex-row lg:items-start lg:text-left">
          <div className="box-border flex justify-center gap-3 md:justify-start">
            <Link
              to="https://www.instagram.com/buffalomotores/"
              className="ml-2 box-border text-yellow-500"
              target="_blank"
            >
              <InstagramIcon fontSize="large" />
            </Link>
            <Link
              to="https://www.facebook.com/BUFFALOMOTORESACOPLADOS"
              className="ml-2 box-border text-yellow-500"
              target="_blank"
            >
              <FacebookIcon fontSize="large" />
            </Link>
            <Link
              to="https://www.youtube.com/user/BuffaloMotoresAcopl"
              className="ml-2 box-border text-yellow-500"
              target="_blank"
            >
              <YouTubeIcon fontSize="large" />
            </Link>
          </div>
          <div className="box-border flex flex-col items-center md:flex-row md:items-start">
            <div className="box-border flex items-center gap-1">
              <span className="box-border self-baseline text-yellow-500">
                <LocationOnIcon />
              </span>
              <div className="box-border">
                Rua Maria Fontes Machado nº420, Bairro Costeira - CEP :
                83.015-482 São José dos Pinhais/PR
              </div>
            </div>
          </div>
          <div className="box-border flex flex-col items-center gap-3 md:items-start">
            <div className="box-border flex items-center">
              <span className="mr-2 box-border text-yellow-500">
                <PhoneIcon />
              </span>
              <Link to="tel:4130915600" className="box-border">
                <div className="box-border">+55 (41)3091-5600</div>
              </Link>
            </div>
            <div className="box-border flex items-center">
              <span className="mr-2 box-border text-yellow-500">
                <MailOutlineIcon />
              </span>
              <a href="mailto:buffalo@buffalo.com.br" className="box-border">
                buffalo@buffalo.com.br
              </a>
            </div>
          </div>
          <div className="box-border flex flex-col md:flex-row">
            <nav className="box-border pl-5">
              <ul className="box-border list-none p-0">
                <li className="mb-1 box-border">
                  <Link
                    to="/sobre"
                    className="box-border transition-colors hover:text-yellow-400"
                  >
                    A Buffalo
                  </Link>
                </li>
                <li className="mb-1 box-border">
                  <Link
                    to="/produtos"
                    className="box-border transition-colors hover:text-yellow-400"
                  >
                    Produtos
                  </Link>
                </li>
                <li className="mb-1 box-border">
                  <Link
                    to="/assistencia"
                    className="box-border transition-colors hover:text-yellow-400"
                  >
                    Assistência
                  </Link>
                </li>
              </ul>
            </nav>
            <nav className="box-border pl-5">
              <ul className="box-border list-none p-0">
                <li className="mb-1 box-border">
                  <Link
                    to="/representantes"
                    className="box-border transition-colors hover:text-yellow-400"
                  >
                    Representantes
                  </Link>
                </li>
                <li className="mb-1 box-border">
                  <Link
                    to="/contato"
                    className="box-border transition-colors hover:text-yellow-400"
                  >
                    Fale Conosco
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="mt-8 box-border bg-customGrayFooter p-3 text-center">
        <div className="box-border flex items-center justify-center gap-4">
          <div className="box-border text-white">
            © 2025 Buffalo Motores & Acoplados LTDA. Todos os direitos reservados. CNPJ: 07.040.941/0001-06
          </div>
          <InfoIcon onClick={handleInfosIconClick} className="cursor-pointer" />

          <Modal
            iconContainerStyle={{ top: 0, right: '1.5rem' }}
            isOpen={isInfosModalOpen}
            onClose={handleCloseInfosModal}
            contentStyle={'max-sm:!w-[95vw] pl-6 pr-[2px]'}
          >
            <div className="my-6 flex max-h-[90vh] w-full flex-col gap-6 overflow-auto py-4">
              <section>
                <h2 className="mb-4 text-xl font-semibold text-gray-700">
                  Desenvolvido por:
                </h2>
                <dl>
                  <dt className="mb-4 text-lg font-semibold text-gray-700">
                    Gerente do Projeto
                  </dt>
                  <dd className="text-gray-800">Charles Vieira Lopes</dd>
                </dl>
              </section>
              <section>
                <h2 className="mb-4 text-lg font-semibold text-gray-700">
                  Gerente de Contas
                </h2>
                <ul className="space-y-2">
                  <li className="text-gray-800">Matheus Zem</li>
                </ul>
              </section>
              <section>
                <h2 className="mb-4 text-lg font-semibold text-gray-700">
                  Líderes Técnicos
                </h2>
                <ul className="space-y-2">
                  <li className="text-gray-800">Felipe Toledo</li>
                  <li className="text-gray-800">Luis Fernando de Souza</li>
                  <li className="text-gray-800">Matheus José Cardoso</li>
                </ul>
              </section>
              <section>
                <h2 className="mb-4 text-lg font-semibold text-gray-700">
                  Designer de Interface do Usuário
                </h2>
                <ul className="space-y-2">
                  <li className="text-gray-800">Delanda Barbosa</li>
                </ul>
              </section>
              <section>
                <h2 className="mb-4 text-lg font-semibold text-gray-700">
                  Desenvolvedores de Interface
                </h2>
                <ul className="space-y-2">
                  <li className="text-gray-800">Dyeison Felipe Kreuz</li>
                  <li className="text-gray-800">Guilherme Moser Montes</li>
                  <li className="text-gray-800">Marina Matulle</li>
                  <li className="text-gray-800">Natãn Teixeira Vieira</li>
                </ul>
              </section>
              <section>
                <h2 className="mb-4 text-lg font-semibold text-gray-700">
                  Desenvolvedores de Infraestrutura de Aplicações
                </h2>
                <ul className="space-y-2">
                  <li className="text-gray-800">Guilherme Moser Montes</li>
                  <li className="text-gray-800">Natãn Teixeira Vieira</li>
                </ul>
              </section>
              <section>
                <h2 className="mb-4 text-lg font-semibold text-gray-700">
                  Analista de Testes
                </h2>
                <ul className="space-y-2">
                  <li className="text-gray-800">Gustavo Guideti</li>
                </ul>
              </section>
            </div>
          </Modal>
        </div>
      </div>
    </footer>
  );
}
