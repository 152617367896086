import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useModal } from '../../../hooks/useModal';
import { useSearchParams } from '../../../hooks/useSearchParams';
import {
  ItemSelected,
  LabelSizeTypes,
  ProductBarCode,
  ProductsBarCodePaginated,
} from '../../../types/productBarCode';
import { useConfirmReload } from '../../../hooks/useConfirmReload';
import { productBarCodeCache } from '../../../constants/requestCacheName';
import queryClient from '../../../libs/queryClient';
import { labelSizes } from '../../../constants/barCode';

export const useBarCode = () => {
  const [labelSizeParam] = useSearchParams('labelSize') as [LabelSizeTypes];
  const labelSize = labelSizeParam || 'p';

  const inputRef = useRef<HTMLInputElement | null>(null);

  const currentLabelSize = labelSizes[labelSize];

  const totalLabelPerPage = useMemo(
    () => currentLabelSize.columns * currentLabelSize.row,
    [currentLabelSize.columns, currentLabelSize.row],
  );

  const clearAll = useCallback(() => {
    itemsSelected.current = [];
    setItemsRemovedFromList([]);
    setTotalPages(1);
    setTotalBlankLabels(totalLabelPerPage);
    setVisibleLabels([]);
    setCurrentLabelsInPage([]);
    setCheckedState(new Array(totalLabelPerPage).fill(false));
    firstCheckboxIndexClicked.current = null;
  }, [totalLabelPerPage]);

  const [currentProductBarCode, setCurrentProductBarCode] =
    useState<ProductBarCode | null>(null);

  const [totalBlankLabels, setTotalBlankLabels] = useState(totalLabelPerPage);
  const [totalPages, setTotalPages] = useState(1);
  const blankLabesWhenTableRowWasClicked = useRef<number>(totalLabelPerPage);

  const [realLabelNumber, setRealLabelNumber] = useState(0);
  const [currentLabelsInPage, setCurrentLabelsInPage] = useState<
    ItemSelected[]
  >([]);
  const [currentBlankLabel, setCurrentBlankLabel] =
    useState<number>(totalLabelPerPage);
  const [currentTotalPages, setCurrentTotalPages] = useState(1);

  const itemsSelected = useRef<ItemSelected[]>([]);
  const [itemsRemovedFromList, setItemsRemovedFromList] = useState<
    ProductBarCode[]
  >([]);
  const currentItemToDelete = useRef<ItemSelected | null>(null);

  useConfirmReload(itemsSelected.current.length);

  useEffect(() => {
    clearAll();
  }, [clearAll, totalLabelPerPage]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const {
    isModalOpen: isCodeBarModalOpen,
    handleCloseModal: closeCodeBarModal,
    handleOpenModal: handleOpenCodeBarModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: closeConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteAllModalOpen,
    handleCloseModal: handleCloseConfirmDeleteAllModal,
    handleOpenModal: handleOpenConfirmDeleteAllModal,
  } = useModal();

  const handleTableRowClick = (productBarCode: ProductBarCode) => {
    blankLabesWhenTableRowWasClicked.current = totalBlankLabels;
    setCurrentTotalPages(totalPages);
    setCurrentBlankLabel(totalBlankLabels);

    setCurrentProductBarCode(productBarCode);
    // handleChangeLabelNumber(0);
    handleOpenCodeBarModal();
  };

  const handleCloseConfirmDeleteModal = () => {
    currentItemToDelete.current = null;
    closeConfirmDeleteModal();
  };

  const defineLabelsInPage = useCallback(
    (restDivision: number, totalLabelsArray: ItemSelected[]) => {
      if (restDivision === 0) {
        const itemsInPage = totalLabelsArray.slice(-totalLabelPerPage);
        setCurrentLabelsInPage(itemsInPage);
        return;
      }

      const itemsInPage = totalLabelsArray.slice(-restDivision);
      setCurrentLabelsInPage(itemsInPage);
    },
    [totalLabelPerPage],
  );

  const handleAddLabel = () => {
    if (!currentProductBarCode || firstCheckboxIndexClicked.current === null)
      return;

    itemsSelected.current = [
      ...itemsSelected.current,
      {
        ...currentProductBarCode,
        amount: realLabelNumber,
      },
    ];

    const itemsCache =
      queryClient.getQueryData<ProductsBarCodePaginated>(productBarCodeCache);
    const itemToRemove = itemsCache?.items.find(
      (item) => item.id === currentProductBarCode.id,
    );

    if (itemToRemove) {
      setItemsRemovedFromList((prevItemsRemoved) => [
        ...prevItemsRemoved,
        itemToRemove,
      ]);
    }

    setTotalBlankLabels(currentBlankLabel);
    setTotalPages(currentTotalPages);
    resetLabels();
  };

  const handleRemoveLabelClick = (itemSelected: ItemSelected) => {
    currentItemToDelete.current = itemSelected;
    handleOpenConfirmDeleteModal();
  };

  const calculateBlankLabelsInDelete = (filteredItems: ItemSelected[]) => {
    const totalLabels = filteredItems.reduce(
      (acc, item) => acc + item.amount,
      0,
    );

    const division = totalLabels / totalLabelPerPage;
    const totalPages = Math.floor(division);
    const increment = division === 1 ? 0 : 1;
    setTotalPages(totalPages + increment);

    if (totalLabels > totalLabelPerPage) {
      const divisionRest = totalLabels % totalLabelPerPage;
      const blankLabels = totalLabelPerPage - divisionRest;
      setTotalBlankLabels(blankLabels);
      return;
    }

    const blankLabels = totalLabelPerPage - totalLabels;
    setTotalBlankLabels(blankLabels);
  };

  const handleConfirmRemoveItemSelected = () => {
    let itemRemovedId: number;
    const filteredItems = itemsSelected.current.filter((itemSelected) => {
      if (itemSelected.id === currentItemToDelete.current?.id) {
        itemRemovedId = itemSelected.id;
      }

      return itemSelected.id !== currentItemToDelete.current?.id;
    });

    setItemsRemovedFromList((prevItemsRemoved) => {
      return prevItemsRemoved.filter(
        (itemRemoved) => itemRemoved.id !== itemRemovedId,
      );
    });

    calculateBlankLabelsInDelete(filteredItems);
    const currentItemToDeleteId = currentItemToDelete.current?.id;

    setCurrentLabelsInPage((prev) =>
      prev.filter(({ id }) => id !== currentItemToDeleteId),
    );
    setVisibleLabels((prev) =>
      prev.filter(({ id }) => id !== currentItemToDeleteId),
    );
    // handleChangeLabelNumber(0);

    itemsSelected.current = filteredItems;

    handleCloseConfirmDeleteModal();
  };

  const handleRemoveAllLabelsClick = () => {
    handleOpenConfirmDeleteAllModal();
  };

  const handleConfirmDeleteAllLabels = () => {
    clearAll();
    handleCloseConfirmDeleteAllModal();
  };

  const handleIgnoreClick = () => {
    handleCloseCodeBarModal();
  };

  const resetLabels = () => {
    setRealLabelNumber(0);
    blankLabesWhenTableRowWasClicked.current = totalLabelPerPage;
    setCurrentBlankLabel(totalLabelPerPage);
    setCurrentTotalPages(0);
    closeCodeBarModal();
  };

  const handleCloseCodeBarModal = () => {
    resetLabels();

    // Labels
    // setFirstCheckboxIndexClicked(null);
    // firstCheckboxIndexClicked.current = null;
    // setCheckedState(new Array(totalCheckboxes).fill(false));
  };

  // Labels vvv

  const totalCheckboxes = useMemo(
    () => currentLabelSize.columns * currentLabelSize.row,
    [currentLabelSize.columns, currentLabelSize.row],
  );

  const [checkedState, setCheckedState] = useState<boolean[]>(
    new Array(totalLabelPerPage).fill(false),
  );

  const [visibleLabels, setVisibleLabels] = useState<ItemSelected[]>([]);

  const firstCheckboxIndexClicked = useRef<number | null>(null);
  // const [firstCheckboxIndexClicked, setFirstCheckboxIndexClicked] = useState<
  //   number | null
  // >(null);

  const updateVisibleLables = useCallback(
    (index: number) => {
      const totalLabelsAmount = itemsSelected.current.reduce(
        (acc, item) => acc + item.amount,
        0,
      );
      const maxLabelsToShow =
        totalLabelsAmount + Number(inputRef.current?.value ?? 0);

      if (maxLabelsToShow + index > totalCheckboxes) {
        const labelsRestDivision = (maxLabelsToShow + index) % totalCheckboxes;
        const lastLabelsElements =
          currentLabelsInPage.slice(-labelsRestDivision);
        // Seta todos os elementos para true
        setCheckedState(new Array(totalCheckboxes).fill(true));
        setVisibleLabels(lastLabelsElements);

        return;
      }

      setVisibleLabels(currentLabelsInPage);
    },
    [currentLabelsInPage, totalCheckboxes],
  );

  const defineBlankLabelsAndTotalPages = useCallback(
    (realLabels: number) => {
      const realLabelsWithCheckboxClickIndex =
        realLabels + (firstCheckboxIndexClicked.current ?? 0);

      if (
        realLabelsWithCheckboxClickIndex >
        blankLabesWhenTableRowWasClicked.current
        // - (firstCheckboxIndexClicked.current ?? 0)
      ) {
        const labelsWithPageComplete =
          realLabelsWithCheckboxClickIndex -
          blankLabesWhenTableRowWasClicked.current;

        const rest = labelsWithPageComplete % totalLabelPerPage;
        const pagesWithRestLabels = Math.floor(
          labelsWithPageComplete / totalLabelPerPage,
        );

        const blankLabels = rest === 0 ? 0 : totalLabelPerPage - rest;
        const increment = rest === 0 ? 0 : 1;

        setCurrentTotalPages(() => {
          return totalPages + pagesWithRestLabels + increment;
        });

        setCurrentBlankLabel(
          blankLabels,
          // - (firstCheckboxIndexClicked.current ?? 0),
        );

        return;
      }

      const blankLabels =
        blankLabesWhenTableRowWasClicked.current -
        realLabelsWithCheckboxClickIndex;
      // -(firstCheckboxIndexClicked.current ?? 0);

      setCurrentBlankLabel(blankLabels);
      setCurrentTotalPages(totalPages);
    },
    [totalLabelPerPage, totalPages],
  );

  const handleChangeLabelNumber = useCallback(
    (labelNumber: number) => {
      // const realLabels =
      //   Math.ceil(labelNumber / currentLabelSize.columns) *
      //   currentLabelSize.columns;
      const realLabels = labelNumber;

      const totalLabelPerPage = currentLabelSize.row * currentLabelSize.columns;

      const totalLabelsArray = [
        ...itemsSelected.current
          .map((itemSelected) =>
            Array<ItemSelected>(itemSelected.amount).fill(itemSelected),
          )
          .flat(),
        ...(currentProductBarCode
          ? Array<ItemSelected>(realLabels).fill({
              ...currentProductBarCode,
              amount: realLabels,
            })
          : []),
      ];

      const restDivision =
        totalLabelsArray.length +
        ((firstCheckboxIndexClicked.current ?? 0) % totalLabelPerPage);

      defineLabelsInPage(restDivision, totalLabelsArray);
      if (firstCheckboxIndexClicked.current !== null) {
        setRealLabelNumber(realLabels);
        defineBlankLabelsAndTotalPages(realLabels);
      }
    },
    [
      currentLabelSize.columns,
      currentLabelSize.row,
      currentProductBarCode,
      defineBlankLabelsAndTotalPages,
      defineLabelsInPage,
    ],
  );

  const handleCheckboxClick = useCallback(
    (index: number) => {
      // setFirstCheckboxIndexClicked(index);
      firstCheckboxIndexClicked.current = index;
      const newCheckedState = [...checkedState].map((_, i) => i >= index);
      setCheckedState(newCheckedState);

      updateVisibleLables(index);

      if (inputRef.current?.value) {
        handleChangeLabelNumber(+inputRef.current.value);
      }
    },
    [checkedState, handleChangeLabelNumber, updateVisibleLables],
  );

  useEffect(() => {
    if (firstCheckboxIndexClicked.current !== null) {
      updateVisibleLables(firstCheckboxIndexClicked.current);
    }
  }, [updateVisibleLables, currentLabelsInPage]);

  return {
    isCodeBarModalOpen,
    currentLabelSize,
    currentProductBarCode,
    realLabelNumber,
    currentBlankLabel,
    totalPages,
    currentLabelsInPage,
    itemsSelected: itemsSelected.current,
    isConfirmDeleteModalOpen,
    totalBlankLabels,
    currentTotalPages,
    isConfirmDeleteAllModalOpen,
    currentItemToDelete: currentItemToDelete.current,
    itemsRemovedFromList,
    inputRef,
    visibleLabels,
    checkedState,
    maxLabelsToShow: currentLabelsInPage.length,
    totalCheckboxes,
    firstCheckboxIndexClicked: firstCheckboxIndexClicked.current,
    handleChangeLabelNumber,
    handleCheckboxClick,
    handleIgnoreClick,
    handleConfirmDeleteAllLabels,
    handleRemoveAllLabelsClick,
    handleCloseConfirmDeleteAllModal,
    handleConfirmRemoveItemSelected,
    handleCloseConfirmDeleteModal,
    handleRemoveLabelClick,
    handleAddLabel,
    handleTableRowClick,
    handleCloseCodeBarModal,
  };
};
