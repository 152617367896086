export default function DescriptionIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
      <image
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAEUSURBVDhPY6ApCA0NZUtJSTkCxGeIwcnJySuhWrGDtLQ0fqCiw1AuQQBUewnKxA6Ati4GKvqM7hJcGKj2G5CugWrHBKS4DgSAPpIDGrgUykUFQIlyoIEvYbYTiS8A8QugvnCoMQgAlJiWmpqqC+USDYCGRQFxBZSLAKMGEg2wGgg0KAIoeByI+4EGlyPhNKgSMADlpKSkpBJkNUA9y4H0diDtClUGtuUSEIdiwYcTExNFocoYgIbZAcVWoqkB4UygoYj0CBTAmoWAipyAeBfQIBOgGgcgPgr0jTJUGg4wEjguA0EAKGcNxLOAeBJQkxpUGAWQZCAxgD4GAgNfiVwMNNAG3cBaoMAqSjDQjESocdQEDAwACuQKnk2oM7IAAAAASUVORK5CYII="
        height="100%"
        width="100%"
        style={{ filter: 'invert(1) brightness(2)' }}
      />
    </svg>
  );
}
