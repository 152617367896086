import { SelectChangeEvent } from '@mui/material';
import { useState, useEffect, useCallback, useRef, ChangeEvent } from 'react';
import {
  coordinatesByProductIdApiCache,
  PartsGroupByProductIdApiCache,
  partsGroupItemsByProductIdApiCache,
} from '../../../constants/requestCacheName';
import { useQuery } from 'react-query';
import {
  getAllPartsGroupByProductId,
  getAllPartsGroupItemsByProductId,
} from '../../../services/partsGroupItem';
import {
  FindAllPartsGroup,
  PartsGroupOptionalDescriptionType,
} from '../../../types/partsGroup';
import { FindAllPartsGroupItem } from '../../../types/partsGroupItem';
import { FindAllCoordinates } from '../../../types/partsGroupCoordinate';
import { getAllCoordinatesByProductId } from '../../../services/partsGroupItemCoordinate';
import { usePartsCatalogStore } from '../../../store/partsCatalog';
import { useParams } from 'react-router-dom';
import { verifyScreenIsLessThan } from '../../../utils/verifyScreenIsLessThan';
import { useSearchParams } from '../../../hooks/useSearchParams';

export const usePartsGroup = (currentPartGroupId: number | undefined) => {
  const isMobile = verifyScreenIsLessThan(1250);
  const { productId } = useParams();

  const [productName, partGroupName] = useSearchParams(
    'product',
    'partGroupName',
  );

  const currentProductId = useRef(+(productId ?? -1));

  const {
    state: {shouldShowFirstCurrentPartsGroup, currentPartsGroup},
    actions: {
      setCurrentPartsGroup,
      setCurrentPartClicked,
      setCurrentPartIdHover,
      setCurrentProductId,
    },
  } = usePartsCatalogStore();

    useQuery<FindAllPartsGroup>(
      `${PartsGroupByProductIdApiCache}`,
      async () =>
        (await getAllPartsGroupByProductId(+currentProductId.current!)).data,
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
      },
    );

  const { data: partsGroup } = useQuery<FindAllPartsGroup>(
    `${PartsGroupByProductIdApiCache}${productId}`,
    async () => (await getAllPartsGroupByProductId(+productId!)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

    useQuery<FindAllPartsGroupItem>(
      partsGroupItemsByProductIdApiCache,
      async () =>
        (await getAllPartsGroupItemsByProductId(+currentProductId.current!))
          .data,
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
      },
    );

  useQuery<FindAllCoordinates>(
    coordinatesByProductIdApiCache,
    async () => {
      return (await getAllCoordinatesByProductId(+currentProductId.current!))
        .data;
    },
    {
      retry: false,
      enabled: false,
    },
  );

  const [selectedPartGroup, setSelectedPartGroup] = useState('');
  const [currentPartsGroupToDeleteId, setCurrentPartsGroupToDeleteId] =
    useState<number | null>(null);

  const [partsGroupInUse, setPartsGroupInUse] = useState<string[]>([]);

  const handleClickPartsGroup = useCallback(
    (
      partGroup: PartsGroupOptionalDescriptionType,
      productId?: number | null,
    ) => {
      if (productId) {
        setCurrentProductId(productId);
      }
      setCurrentPartsGroup(partGroup);

      setCurrentPartClicked(null);
      setCurrentPartIdHover(null);
    },
    [
      setCurrentPartClicked,
      setCurrentPartIdHover,
      setCurrentPartsGroup,
      setCurrentProductId,
    ],
  );

  useEffect(() => {
    if (partGroupName) {
      const currentPartsGroup = partsGroup?.items.find(
        (partsGroup) => partsGroup.name === partGroupName,
      );
      if (currentPartsGroup) {
        handleClickPartsGroup(currentPartsGroup);

        return;
      }
    }

    if (partsGroup?.items) {
      setCurrentPartsGroup(partsGroup.items[0]);
      return;
    }
    setCurrentPartsGroup(null);
  }, [handleClickPartsGroup, partGroupName, partsGroup, setCurrentPartsGroup]);

  useEffect(() => {
    if (partsGroup && partsGroup.items.length > 0) {
      setSelectedPartGroup(partsGroup.items[0].name);
    }

    if (shouldShowFirstCurrentPartsGroup) {
      setCurrentPartsGroup(currentPartsGroup);
      setSelectedPartGroup(currentPartsGroup?.name!);
      return
    }
    // if (partsGroup?.items) {
    //   setCurrentPartsGroup(partsGroup.items[0]);
    //   return;
    // }

    // setCurrentPartsGroup(null);
  }, [currentPartsGroup, partsGroup, setCurrentPartsGroup, shouldShowFirstCurrentPartsGroup]);

  useEffect(() => {
    const inUse = partsGroup?.items.map((item) => item.name) ?? [];
    setPartsGroupInUse(inUse);
  }, [partsGroup]);

  const handleSelectPartGroup = (event: ChangeEvent<HTMLSelectElement>) => {
    const partGroup = partsGroup?.items.find(
      (partGroup) => partGroup.name === event.target.value,
    );
    if (partGroup) {
      setCurrentPartsGroup(partGroup);
      setCurrentPartClicked(null);
      setCurrentPartIdHover(null);
    }
    setSelectedPartGroup(event.target.value);
  };

  const handleRemoveIconClick = (partGroupId: number) => {
    if (partGroupId === currentPartsGroupToDeleteId) {
      setCurrentPartsGroupToDeleteId(null);
      return;
    }

    setCurrentPartsGroupToDeleteId(partGroupId);
  };

  return {
    isMobile,
    selectedPartGroup,
    currentPartsGroupToDeleteId,
    partsGroupInUse,
    productName,
    partsGroup,
    productId,
    handleClickPartsGroup,
    handleSelectPartGroup,
    handleRemoveIconClick,
  };
};
