import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import DocumentTypesContent from '../../../components/DocumentTypesContent';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import { useDocumentTypes } from './useDocumentTypes';

export default function DocumentTypes() {
  const {
    isMenageModalOpen,
    documentTypesIdUpdate,
    isConfirmDeleteModalOpen,
    isShowEditButton,
    dirtyFields,
    errors,
    documentType,
    errorMessage,
    clearError,
    handleConfirmDelete,
    handleClearDocumentTypeField,
    handleSubmit,
    register,
    handleEdit,
    handleButtonOkClick,
    handleCloseConfirmDeleteModal,
    handleDeleteButtonClick,
    handleTableRowClick,
    handleAddDocumentTypeClick,
    handleCloseMenageModal,
  } = useDocumentTypes();

  return (
    <>
      <DocumentTypesContent
        onTableRowClick={handleTableRowClick}
        onAddDocumentTypeClick={handleAddDocumentTypeClick}
      />

      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseMenageModal}
        cancelIcon={false}
      >
        <HeaderModalManageItem
          showDeleteButton={!!documentTypesIdUpdate}
          deleteButtonContent="Apagar tipo de documento"
          onDeleteButtonClick={handleDeleteButtonClick}
          isEditable={!isShowEditButton}
          title={
            documentTypesIdUpdate
              ? 'Tipo de documento'
              : 'Novo tipo de documento'
          }
          mainButtonContent={isShowEditButton ? 'Editar' : 'Ok'}
          onCancelClick={handleCloseMenageModal}
          onOkClick={
            !isShowEditButton ? handleSubmit(handleButtonOkClick) : undefined
          }
          onEditClick={isShowEditButton ? handleEdit : undefined}
        />

        <div className="mb-52 mr-24 mt-24 w-full">
          <Input
            type="text"
            placeholder="Tipo de documento"
            disabled={isShowEditButton}
            helperText={errors.documentType?.message}
            isDirty={dirtyFields.documentType}
            onCancelIconClick={handleClearDocumentTypeField}
            {...register('documentType')}
            value={documentType}
          />
        </div>
      </Modal>

      <ConfirmModal
        contentMessage="Deseja excluir esse tipo de descrição?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
        onNotConfirm={handleCloseConfirmDeleteModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
