import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Box,
} from '@mui/material';
import {
  partsCatalogTableStyle,
  refCellStyle,
  tableRowSelectedStyle,
} from './styles';
import { usePartsCatalogTable } from './usePartsCatalogTable';
import { useEffect, useRef } from 'react';
import ToolTipCustom from '../ToolTip';

type PartsCatalogTableProps = {
  currentPartHoverId: number | null;
  currentPartGroupId: number | undefined;
  currentPartGroupName: string | undefined;
  onRowMouseEnter?: (partId: number) => void;
  onRowMouseOut?: () => void;
  onRowClick?: (partId: number) => void;
  isMobile: boolean;
};

export default function PartsCatalogTable({
  currentPartHoverId,
  currentPartGroupId,
  currentPartGroupName,
  onRowMouseEnter,
  onRowMouseOut,
  onRowClick,
  isMobile,
}: PartsCatalogTableProps) {
  const {
    selectedPart,
    partClickedIndex,
    tableRef,
    parts,
    currentPartClicked,
    handleSelectPartMobile,
    handleTableRowClick,
  } = usePartsCatalogTable(currentPartGroupId, onRowClick);

  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]);

  useEffect(() => {
    if (currentPartHoverId !== null && parts) {
      const index = parts.findIndex(
        (partsGroupItem) => partsGroupItem.part?.id === currentPartHoverId,
      );
      if (index !== -1 && rowRefs.current[index]) {
        const row = rowRefs.current[index];
        const container = tableRef.current;
        if (row && container) {
          const rowTop = row.offsetTop;
          const rowHeight = row.offsetHeight;
          const containerHeight = container.clientHeight;
          const containerScrollTop = container.scrollTop;
          const tableHeaderHeightInPx = 57;

          if (rowTop - tableHeaderHeightInPx < containerScrollTop) {
            container.scrollTop = rowTop - tableHeaderHeightInPx;
          } else if (
            rowTop + rowHeight >
            containerScrollTop + containerHeight
          ) {
            container.scrollTop = rowTop + rowHeight - containerHeight;
          }
        }
      }
    }
  }, [currentPartHoverId, parts]);

  return (
    <Box component="section" sx={partsCatalogTableStyle}>
      {isMobile ? (
        <select
          value={selectedPart}
          onChange={handleSelectPartMobile}
          className="w-full cursor-pointer rounded-lg border-2 p-2 font-bold uppercase tracking-widest text-black"
        >
          <option value={'Selecione a peça'} className="w-full">
            Selecione a peça
          </option>
          {parts?.map(
            ({ part }) =>
              part && (
                <option
                  value={`${part.id} - ${part.name}`}
                  key={part.name}
                  style={{ width: '100%' }}
                >
                  {part.id} - {part.name}
                </option>
              ),
          )}
        </select>
      ) : (
        <>
          <div className="mb-2 text-left font-medium text-black 2xl:text-2xl">
            Peças
            <span className="text-2xl text-yellow-500 2xl:text-4xl">.</span>
          </div>
          <TableContainer
            sx={{
              overflowX: 'hidden',
              overflowY: 'auto',
              borderRadius: '16px',
              border: '2px solid #d4d4d8',
              maxHeight: 'calc(100vh - 17.25rem)',
              width: '29rem',
            }}
            ref={tableRef}
            className="max-w-[32rem] bg-white max-xl:my-2 max-xl:max-h-40 max-xl:max-w-full max-xl:px-2"
            onMouseLeave={() => {
              if (onRowMouseOut) {
                onRowMouseOut();
              }
            }}
          >
            <Table sx={{ width: '100%' }} className="w-full table-auto">
              <TableHead className="sticky top-0 bg-backgroundPaper">
                <TableRow>
                  <ToolTipCustom
                    title="Referência da vista explodida"
                    placement="top-start"
                  >
                    <TableCell
                      sx={{
                        p: '1rem 0.5rem 1rem 1.5rem',
                        cursor: 'pointer',
                        borderBottom: '2px solid #d4d4d8',
                        fontWeight: 'bold',
                      }}
                    >
                      Ref.
                    </TableCell>
                  </ToolTipCustom>
                  <ToolTipCustom title="Nome da peça" placement="top-start">
                    <TableCell
                      sx={{
                        paddingX: '0.5rem',
                        cursor: 'pointer',
                        borderBottom: '2px solid #d4d4d8',
                      }}
                    >
                      Descrição
                    </TableCell>
                  </ToolTipCustom>
                  <ToolTipCustom title="Código da peça" placement="top-start">
                    <TableCell
                      sx={{
                        paddingX: '0.5rem',
                        cursor: 'pointer',
                        borderBottom: '2px solid #d4d4d8',
                      }}
                    >
                      Código
                    </TableCell>
                  </ToolTipCustom>
                  <ToolTipCustom
                    title="Quantidade presente no conjunto"
                    placement="top-start"
                  >
                    <TableCell
                      sx={{
                        p: '1rem 1.5rem 1rem 0.5rem',
                        cursor: 'pointer',
                        borderBottom: '2px solid #d4d4d8',
                      }}
                    >
                      Qtd.
                    </TableCell>
                  </ToolTipCustom>
                </TableRow>
              </TableHead>
              <TableBody>
                {parts?.map((partsGroupItem, index) => {
                  const { part } = partsGroupItem;
                  if (currentPartClicked?.part.id === part?.id) {
                    partClickedIndex.current = index;
                  }
                  const isSelected = currentPartClicked?.part.id === part?.id;
                  const isHovered = currentPartHoverId === part?.id;
                  const isLastRow = index === parts.length - 1;
                  return (
                    part && (
                      <TableRow
                        key={part.id}
                        ref={(el) => (rowRefs.current[index] = el)}
                        sx={{
                          // ...(isSelected ? tableRowSelectedStyle : {}),
                          // ...(isHovered
                          //   ? { backgroundColor: 'rgba(234, 179, 8, 0.5)' }
                          //   : {}),
                          backgroundColor: isSelected
                            ? '#eab308'
                            : isHovered
                              ? 'rgba(234, 179, 8, 0.5)'
                              : undefined,
                          overflow: 'hidden',
                          '&:hover': {
                            backgroundColor: isSelected
                              ? '#eab308'
                              : 'rgba(234, 179, 8, 0.5)',
                          },
                          borderBottom: isLastRow
                            ? 'none'
                            : '2px solid #d4d4d8',
                        }}
                        onMouseEnter={() => {
                          if (onRowMouseEnter) {
                            onRowMouseEnter(part.id);
                          }
                        }}
                        onClick={() => handleTableRowClick(partsGroupItem)}
                      >
                        <TableCell
                          component="th"
                          align="left"
                          scope="row"
                          sx={{
                            ...refCellStyle,
                            p: '1rem 0.5rem 1rem 1.5rem',
                            cursor: 'pointer',
                          }}
                        >
                          <div className="h-5 w-12 cursor-pointer rounded-lg font-bold">
                            {partsGroupItem.tag}
                          </div>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            padding: '16px',
                            cursor: 'pointer',
                            ...refCellStyle,
                          }}
                        >
                          {part.name}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            width: '5rem',
                            padding: '16px',
                            cursor: 'pointer',
                            ...refCellStyle,
                          }}
                        >
                          {part.id}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            p: '1rem 1.5rem 1rem 0.5rem',
                            cursor: 'pointer',
                            ...refCellStyle,
                          }}
                        >
                          <div className="flex justify-between">
                            {partsGroupItem.amount}
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
}
