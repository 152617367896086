import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { logout } from '../services/auth';
import { useLocation } from 'react-router-dom';
import { pagesMapper } from '../constants/pages';
export function useAutoLogout() {

  const AUTO_LOGOUT_TIME = 2 * 60 * 60 * 1000;
  const { mutate: logoutUser } = useMutation({
    mutationFn: logout,

    onSuccess: () => {
      localStorage.removeItem('lastActivity');
      window.location.href = '/login';
    },

    onError: () => {
      alert('Falha ao sair');
    },
  });

  const location = useLocation();


  const EqualRoute = Object.values(pagesMapper).some(page => page.path === location.pathname);

  useEffect(() => {
    if (EqualRoute || location.pathname === '/catalogo') {
      const updateActivity = () => {
        localStorage.setItem('lastActivity', Date.now().toString());
      };

      const checkInactivity = () => {
        const lastActivity = localStorage.getItem('lastActivity');
        if (
          lastActivity &&
          Date.now() - parseInt(lastActivity) > AUTO_LOGOUT_TIME
        ) {
          logoutUser();
        }
      };

      // Atualiza o tempo de atividade em eventos do usuário
      window.addEventListener('keydown', updateActivity);

      const interval = setInterval(checkInactivity, 1 * 60 * 60 * 1000);

      updateActivity();

      return () => {
        window.removeEventListener('keydown', updateActivity);
        clearInterval(interval);
      };
    }
  }, [AUTO_LOGOUT_TIME, EqualRoute, location.pathname, logoutUser])
}
