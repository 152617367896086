import { useNavigate, useParams } from 'react-router-dom';
import { backButtonStyle } from './styles';
import { Box, BoxProps, SxProps, Theme } from '@mui/material';
import { twMerge } from 'tailwind-merge';
import { useSearchParams } from '../../hooks/useSearchParams';

type BackButtonProps = BoxProps & {
  textClassName?: string;
};

export default function BackButton({
  sx,
  className,
  textClassName,
  ...rest
}: BackButtonProps) {
  const navigate = useNavigate();

  const [partsCatalog] =  useSearchParams(
      'partsCatalog',
    );
  console.log("🚀 ~ partsCatalog:", partsCatalog)

  return (
    <Box
      sx={{ ...backButtonStyle, ...sx } as SxProps<Theme>}
      onClick={() => (partsCatalog ? navigate(-1) : navigate('/catalogo'))}
      {...rest}
      className={twMerge('w-fit cursor-pointer', className)}
    >
      <span className={twMerge('text-primary', textClassName)}>Voltar</span>
    </Box>
  );
}
