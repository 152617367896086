import { twMerge } from 'tailwind-merge';
import ListingHeader from '../ListingHeader';
import Table from '../Table';
import { useCategoryContent } from './useCategoryContent';
import SearchInput from '../SearchInput';
import Main from '../Main';
import Loading from '../Loading';
import Footer from '../Footer';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import Alert from '../Alert';
import EditButton from '../EditButton';

type CategoryContentProps = {
  handleAddCategoryClick: () => void;
  handleTableRowClick: (
    categoryId: number,
    categoryName: string,
    lineId: number,
    lineName: string,
  ) => void;
};

export default function CategoryContent({
  handleAddCategoryClick,
  handleTableRowClick,
}: CategoryContentProps) {
  const {
    categoriesShown,
    categories,
    categoryFilter,
    inputRef,
    isFetchingCategories,
    isSearchInputDirty,
    lineColWidth,
    lines,
    sortField,
    canEditOrder,
    errorMessage,
    categoriesLength,
    categoriesShownLength,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleButtonCancelEditClick,
    clearError,
    handleDragEnd,
    defineItemsShown,
    handleChangeEditOrderButtonClick,
    handleCancelSearch,
    handleFilter,
    handleSearch,
    handleSortTable,
  } = useCategoryContent();

  return (
    <>
      <ListingHeader
        add
        onAddIconClick={handleAddCategoryClick}
        pdfTop="3rem"
        pdf={false}
      >
        <div className="mt-4">
          <div className="mb-4 flex justify-between">
            <h1 className="text-2xl">Categorias</h1>

            <EditButton
              setContentList={defineItemsShown}
              contentList={categoriesShown}
              finishMessage="Salvar ordem"
              canEdit={canEditOrder}
              editMessage="Editar ordem"
              cancelMessage="Cancelar ordenação"
              onCancelEditClick={handleButtonCancelEditClick}
              onEditButtonClick={handleChangeEditOrderButtonClick}
              disable={categoryFilter === 'Todos' || !categoryFilter}
            />
          </div>
          <h2>Linhas</h2>
          <div
            className="mb-4 flex flex-wrap gap-2 rounded-lg bg-[#e0e0e0] px-2 py-2"
            style={{
              pointerEvents: canEditOrder ? 'none' : 'auto',
              opacity: canEditOrder ? 0.5 : 1,
            }}
          >
            <span
              onClick={() => handleFilter('Todos')}
              className={twMerge(
                'flex w-fit cursor-pointer items-center justify-center rounded-lg px-4 max-md:py-1',
                !categoryFilter || categoryFilter === 'Todos'
                  ? 'border border-primary bg-backgroundPaper text-primary'
                  : 'text-primaryText',
              )}
            >
              Todas
            </span>
            {lines?.items.map((line) => (
              <span
                key={line.id}
                onClick={() => handleFilter(line.name)}
                className={twMerge(
                  'flex w-fit cursor-pointer items-center justify-center rounded-lg px-4 max-md:py-1',
                  categoryFilter === line.name
                    ? 'border border-primary bg-backgroundPaper text-primary'
                    : 'text-primaryText',
                )}
              >
                {line.name}
              </span>
            ))}
          </div>
          <SearchInput
            ref={inputRef}
            onSearch={handleSearch}
            onButtonClick={handleSearchDatabase}
            buttonContent="Buscar"
            showCancelOptions={isSearchInputDirty}
            onKeyDown={handleEnterClickSearchInput}
            onCancelSearch={handleCancelSearch}
            disabled={canEditOrder}
          />
        </div>
        <Table.Head rowStyle={`${canEditOrder && 'pr-[4.25rem]'}`}>
          <Table.Cell
            items={categoriesShown}
            type="name"
            onSort={handleSortTable}
            isSortActive={sortField === 'name'}
          >
            Categorias
          </Table.Cell>
          <Table.Cell
            items={categoriesShown}
            type="line.name"
            onSort={handleSortTable}
            isSortActive={sortField === 'line.name'}
            style={{ width: `${lineColWidth + 2.5}ch` }}
          >
            Linhas
          </Table.Cell>
        </Table.Head>
      </ListingHeader>

      <Main>
        {isFetchingCategories && <Loading className="pt-10" />}
        {!isFetchingCategories && categories && (
          <Table.Root itemType="Categorias" itemsLength={categoriesLength}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                isDropDisabled={!canEditOrder}
                droppableId="category-products"
                type="list"
                direction="vertical"
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {categoriesShown.map((category, index) => (
                      <Table.Row
                        key={category.id}
                        onClick={() =>
                          handleTableRowClick(
                            category.id,
                            category.name,
                            category.line.id,
                            category.line.name,
                          )
                        }
                      >
                        <Draggable
                          isDragDisabled={!canEditOrder}
                          draggableId={category.id.toString()}
                          index={index}
                          key={category.id}
                        >
                          {(provided) => (
                            <div
                              className="flex w-full justify-between bg-backgroundPaper"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="flex flex-1 justify-between">
                                <Table.CellStatic>
                                  {category.name}
                                </Table.CellStatic>
                                <Table.CellStatic
                                  style={{ width: `${lineColWidth}ch` }}
                                >
                                  <span className="text-secondaryText">
                                    {category.line?.name}
                                  </span>
                                </Table.CellStatic>
                              </div>
                              {canEditOrder && (
                                <img
                                  src="/icons/drag.png"
                                  alt="Ícone para arrastar Descrição"
                                  className="mr-4 size-5 cursor-grab"
                                />
                              )}
                            </div>
                          )}
                        </Draggable>
                      </Table.Row>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Table.Root>
        )}
      </Main>

      <Footer
        current={categoriesShownLength}
        total={categoriesLength}
        itemType="Categorias"
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
