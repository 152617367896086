import { LogVinculate } from '../../types/productDuimp';
import HeaderModalManageItem from '../HeaderModalManageItem';
import Modal from '../Modal';

type DuimpDetailsProps = {
  isConfirmResponseModalOpen: boolean;
  handleCloseConfirmResponseModal: () => void;
  responseData: LogVinculate | undefined;
};

export default function DuimpDetailsVinculateModal({
  isConfirmResponseModalOpen,
  handleCloseConfirmResponseModal,
  responseData,
}: DuimpDetailsProps) {
  return (
    <>
      <Modal
        isOpen={isConfirmResponseModalOpen}
        onClose={handleCloseConfirmResponseModal}
        cancelIcon={false}
        height="90vh"
        contentStyle="overflow-y-scroll"
      >
        <HeaderModalManageItem
          title={'Detalhes do lançamento'}
          mainButtonContent={''}
          onCancelClick={handleCloseConfirmResponseModal}
          isEditable={false}
        />

        <div className="my-12 flex flex-col gap-4">
          <ul>
            <li>
              Total de fabricantes com{' '}
              <span className="text-green-500">SUCESSO</span>:{' '}
              <span>{responseData?.totalFabricantesComSucesso}</span>
            </li>
            <li>
              Total de fabricantes com{' '}
              <span className="text-red-500">ERROS</span>:{' '}
              <span>{responseData?.totalFabricantesComErro}</span>
            </li>
          </ul>

          <hr />
          <div className="flex flex-col gap-4">
            <div>
              {(responseData?.fabricantesComSucesso || []).length > 0 && (
                <>
                  <div>
                    Fabricantes com{' '}
                    <span className="text-green-500">SUCESSO</span>
                  </div>
                  <table className="mt-2 w-full table-auto border-collapse border border-gray-300 text-left">
                    <thead>
                      <tr>
                        <th className="border border-gray-300 px-4 py-2">
                          Código SISCOMEX
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Versão SISCOMEX
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Sequência
                        </th>
                        <th className="border border-gray-300 px-4 py-2">
                          Sucesso
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {responseData?.fabricantesComSucesso.map(
                        (fabricante, index) => (
                          <tr
                            key={index}
                            className="odd:bg-gray-100 even:bg-white"
                          >
                            <td className="border border-gray-300 px-4 py-2">
                              {fabricante.codigo}
                            </td>
                            <td className="border border-gray-300 px-4 py-2">
                              {fabricante.versao}
                            </td>
                            <td className="border border-gray-300 px-4 py-2">
                              {fabricante.seq}
                            </td>
                            <td className="border border-gray-300 px-4 py-2">
                              {fabricante.sucesso ? 'Sim' : 'Não'}
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </div>

            <div>
              {(responseData?.fabricantesComErro || []).length > 0 && (
                <>
                  <div>
                    Fabricantes com <span className="text-red-500">ERROS</span>
                  </div>
                  {responseData?.fabricantesComErro.map((fabricante, index) => (
                    <ul key={index}>
                      <li>Sequência: {fabricante.seq}</li>
                      <li>Erros:</li>
                      {fabricante.erros.map((erro) => (
                        <li>{erro}</li>
                      ))}
                    </ul>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
