import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';

export const headerStyle: SxProps<Theme> = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  padding: '1rem 2rem 0 2rem',
  zIndex: '2',
  backgroundColor: grey['200'],

  '@media (max-width: 760px)': {
    padding: 0,
  },
};

export const pdfText: SxProps<Theme> = {
  position: 'absolute',
  right: '2rem',
  cursor: 'pointer',

  '@media(max-width: 760px)': {
    right: '0.5rem',
  },
};

export const backButtonStyle: SxProps<Theme> = {
  '@media (max-width: 760px)': {
    marginTop: '1rem',
  },
};
