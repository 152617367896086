import {
  EmailSignaturesDomainPaginated,
  UpdateDomainSignatureDto,
  UpdateDomainSignaturesArrayDto,
} from '../types/emailSignatureDomain';
import { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const updateEmailSignatureDomain = async (
  dto: UpdateDomainSignatureDto[],
) => {
  const body: UpdateDomainSignaturesArrayDto = {
    signatureDomains: dto,
  };

  const response = await api.put<void>('/signature-domain/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const findAllEmailSignaturesDomainByDomainIdPaginated = async (
  domainId: number,
  page: number,
  direction: 'ASC' | 'DESC' | null = 'ASC',
  limit?: number | null,
  signature?: number,
) => {
  const url = addSearchParamsInUrl(
    `/signature-domain/v1/domain-id/${domainId}/search`,
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    { name: 'signature', value: signature },
    { name: 's', value: '' },
  );
  const response = await api.get<EmailSignaturesDomainPaginated>(url);
  return response;
};
