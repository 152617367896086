import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import SerchInput from '../../../components/SearchInput';
import Table from '../../../components/Table';
import { useCategories } from './useCategories';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ConfirmModal from '../../../components/ConfirmModal';
import SelectModal from '../../../components/Admin/SelectModal';
import Input from '../../../components/Input';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { twMerge } from 'tailwind-merge';
import Alert from '../../../components/Alert';
import VinculationHeader from '../../../components/VinculationHeader';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CategoryContent from '../../../components/CategoryContent';
import ProductsPartsContent from '../../../components/ProductsPartsContent';

export default function Categories() {
  const {
    category,
    isMenageModalOpen,
    showEditContent,
    categoryIdUpdate,
    isShowEditButton,
    errors,
    isConfirmModalOpen,
    categoryProductsShown,
    isFetchingCategoryProducts,
    categoryProducts,
    currentLine,
    currentCategoryLineName,
    sortFieldCategoryProducts,
    categoryProductsInputRef,
    isCategoryProductsInputDirty,
    isLineModalOpen,
    isConfirmRemoveProductModalOpen,
    isSelectProductModalOpen,
    categoryFieldValue,
    categoryProductsLength,
    domains,
    domainWidth,
    checkProductsDomains,
    errorMessage,
    canEditOrder,
    productsWithoutImage,
    nameColWidth,
    isConfirmUploadWithoutImageModalOpen,
    handleConfirmUploadProductsWithoutImage,
    handleCloseConfirmUploadWithoutImageModal,
    verifyAllDomainsChecked,
    verifyDomainsPartialyChecked,
    handleToggleCheckDomains,
    handleChangeEditOrderButtonClick,
    clearError,
    handleCheckDomain,
    handleDragEnd,
    handleCategoryProductsSearch,
    handleClearCategoryField,
    handleCloseSelectProductModal,
    handleLinkProductButtonClick,
    handleRemoveProduct,
    handleConfirmProductRemove,
    handleCloseRemoveProductModal,
    handleCancelCategoryProductsSearch,
    handleAddCategoryClick,
    handleTableRowClick,
    handleCloseMenageModal,
    handleSubmit,
    register,
    handleButtonOkClick,
    handleDeleteButtonClick,
    handleEditButtonClick,
    handleCloseConfirmModal,
    handleConfirmDelete,
    handleSelectLine,
    handleCloseLineModal,
    handleOpenLines,
    handleSortCategoryProductsTable,
    handleLinkProductClick,
  } = useCategories();

  return (
    <>
      <CategoryContent
        handleAddCategoryClick={handleAddCategoryClick}
        handleTableRowClick={handleTableRowClick}
      />

      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseMenageModal}
        cancelIcon={false}
        width={
          categoryIdUpdate
            ? `${window.innerWidth > 1400 ? '80vw' : '95vw'}`
            : '40rem'
        }
        contentStyle={`${categoryIdUpdate ? 'h-[90vh]' : ''}`}
      >
        <div className="relative">
          <HeaderModalManageItem
            showDeleteButton={!!categoryIdUpdate}
            deleteButtonContent="Apagar categoria"
            isEditable={!isShowEditButton}
            onDeleteButtonClick={handleDeleteButtonClick}
            title={categoryIdUpdate ? 'Categoria' : 'Nova categoria'}
            mainButtonContent={
              categoryIdUpdate && !showEditContent ? 'Editar' : 'Ok'
            }
            onCancelClick={handleCloseMenageModal}
            onOkClick={
              !isShowEditButton ? handleSubmit(handleButtonOkClick) : undefined
            }
            onEditClick={isShowEditButton ? handleEditButtonClick : undefined}
          />
          <div className="mr-24 mt-8 w-full">
            <div className="flex items-center justify-between">
              <Input
                maxLength={55}
                type="text"
                placeholder="Categoria"
                value={category}
                disabled={isShowEditButton}
                isDirty={showEditContent && !!categoryFieldValue}
                onCancelIconClick={handleClearCategoryField}
                {...register('category')}
              />
            </div>
            <span className="text-xs text-red-600">
              {errors.category?.message}
            </span>
            <div className="flex cursor-pointer items-center gap-6 border-b-[1px]">
              <span className="w-fit">Linha</span>
              <Input
                readOnly
                value={currentLine}
                onClick={isShowEditButton ? undefined : handleOpenLines}
                className={`cursor-pointer border-none p-0 text-${currentLine !== 'Nenhuma' ? 'primary' : 'secondaryText'}`}
                inputStyle="cursor-pointer"
                {...register('line')}
              />
              <ChevronRightIcon
                className="text-secondaryText"
                onClick={isShowEditButton ? undefined : handleOpenLines}
              />
            </div>
            <span className="text-xs text-red-600">{errors.line?.message}</span>
          </div>
          <div className="mt-8" />
          {categoryIdUpdate && (
            <>
              <VinculationHeader
                isFething={isFetchingCategoryProducts}
                isVinculationDisabled={!showEditContent}
                onVinculateClick={handleLinkProductButtonClick}
                vinculationButtonContent="Vincular produto"
                onControlButtonClick={handleChangeEditOrderButtonClick}
                orderControlButtonContent={
                  canEditOrder ? 'Concluir ordem' : 'Editar ordem'
                }
                vinculationContent={
                  <>
                    {categoryProductsLength > 0
                      ? categoryProductsLength
                      : 'Nenhum'}{' '}
                    produto
                    {categoryProductsLength > 1
                      ? 's estão vinculados'
                      : ' está vinculado'}{' '}
                    a essa categoria
                  </>
                }
              />

              <SerchInput
                ref={categoryProductsInputRef}
                onSearch={handleCategoryProductsSearch}
                onButtonClick={handleCancelCategoryProductsSearch}
                showCancelOptions={isCategoryProductsInputDirty}
                onCancelSearch={handleCancelCategoryProductsSearch}
                disabled={canEditOrder}
              />

              <Table.Head rowStyle="px-0 items-center">
                <div className="ml-4 flex">
                  <Table.Cell
                    items={categoryProductsShown}
                    type="id"
                    onSort={handleSortCategoryProductsTable}
                    isSortActive={sortFieldCategoryProducts === 'id'}
                    className="w-[5.5rem]"
                  >
                    <span className="text-xs">Código</span>
                  </Table.Cell>
                  <Table.Cell
                    items={categoryProductsShown}
                    type="name"
                    onSort={handleSortCategoryProductsTable}
                    isSortActive={sortFieldCategoryProducts === 'name'}
                    style={{ width: `${nameColWidth}ch` }}
                  >
                    <span className="text-xs">Nome</span>
                  </Table.Cell>
                  <div className="flex gap-8">
                    <Table.Cell items={categoryProductsShown} className="w-20">
                      <span className="w-20 text-center text-xs">
                        Revis. aprov.
                      </span>
                    </Table.Cell>
                    <Table.Cell items={categoryProductsShown} className="w-8">
                      <span className="w-8 text-center text-xs">Novo</span>
                    </Table.Cell>
                    <Table.Cell items={categoryProductsShown} className="w-20">
                      <span className="w-20 text-center text-xs">
                        Fora de linha
                      </span>
                    </Table.Cell>
                    <Table.Cell items={categoryProductsShown} className="w-36">
                      <span className="w-36 text-center text-xs">
                        Imagem indisponível
                      </span>
                    </Table.Cell>
                  </div>
                </div>
                <div
                  className={twMerge(
                    'flex gap-4',
                    showEditContent && canEditOrder
                      ? `mr-[4.3rem]`
                      : `mr-[1.1rem]`,
                  )}
                >
                  {domains?.items.map((domain) => (
                    <Table.Cell
                      items={categoryProductsShown}
                      key={`label-${domain.id}`}
                      style={{ width: `${domainWidth}ch` }}
                      className="items-center"
                    >
                      <div className="flex w-full items-center gap-2">
                        <div className="relative flex items-center">
                          {verifyDomainsPartialyChecked(domain.id) && (
                            <div className="pointer-events-none absolute -left-[2px] flex items-center">
                              <IndeterminateCheckBoxIcon
                                className="text-[#0075ff]"
                                fontSize="small"
                              />
                            </div>
                          )}
                          <input
                            onChange={(event) =>
                              handleToggleCheckDomains(
                                domain.id,
                                event.target.checked,
                              )
                            }
                            checked={verifyAllDomainsChecked(domain.id)}
                            type="checkbox"
                            id={`domain${domain.id}`}
                            className="size-4"
                            disabled={isShowEditButton}
                          />
                        </div>
                        <span className="text-center text-sm">
                          {domain.name}
                        </span>
                      </div>
                    </Table.Cell>
                  ))}
                </div>
              </Table.Head>

              {isFetchingCategoryProducts && <Loading heightNormal />}
              {!isFetchingCategoryProducts && categoryProducts && (
                <Table.Root
                  itemType="Produtos"
                  itemsLength={categoryProductsLength}
                  paddingTop="0"
                  className="h-[calc(90vh-28rem)] overflow-y-scroll"
                  showNoContentMessage={false}
                >
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable
                      isDropDisabled={isShowEditButton || !canEditOrder}
                      droppableId="category-products"
                      type="list"
                      direction="vertical"
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {categoryProductsShown.map((product, index) => (
                            <Table.Row key={product.id} className="ml-4 px-0">
                              <Draggable
                                isDragDisabled={
                                  isShowEditButton || !canEditOrder
                                }
                                draggableId={product.id.toString()}
                                index={index}
                                key={product.id}
                              >
                                {(provided) => (
                                  <div
                                    className="flex w-full justify-between bg-backgroundPaper"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div
                                      className="relative flex flex-1 items-center"
                                      onClick={() =>
                                        showEditContent &&
                                        handleRemoveProduct(product.id)
                                      }
                                    >
                                      <Table.Cell
                                        items={categoryProducts.items}
                                        type="id"
                                        className="w-[5.5rem]"
                                      >
                                        {product.id}
                                      </Table.Cell>
                                      <Table.Cell
                                        items={categoryProducts.items}
                                        type="name"
                                        style={{ width: `${nameColWidth}ch` }}
                                      >
                                        <span>{product.name}</span>
                                      </Table.Cell>
                                      <div className="flex gap-8">
                                        <Table.Cell
                                          items={categoryProductsShown}
                                          className="w-20"
                                        >
                                          <div className="w-full text-center">
                                            <input
                                              readOnly
                                              type="checkbox"
                                              className="size-4"
                                              checked={product.reviewedApproved}
                                            />
                                          </div>
                                        </Table.Cell>
                                        <Table.Cell
                                          items={categoryProductsShown}
                                          className="w-8"
                                        >
                                          <div className="w-full text-center">
                                            <input
                                              readOnly
                                              type="checkbox"
                                              className="size-4"
                                              checked={product.new}
                                            />
                                          </div>
                                        </Table.Cell>
                                        <Table.Cell
                                          items={categoryProductsShown}
                                          className="w-20"
                                        >
                                          <div className="w-full text-center">
                                            <input
                                              readOnly
                                              type="checkbox"
                                              className="size-4"
                                              checked={product.outOfLine}
                                            />
                                          </div>
                                        </Table.Cell>
                                        <Table.Cell
                                          items={categoryProducts.items}
                                          className="w-36"
                                        >
                                          <div className="w-36 text-center">
                                            {productsWithoutImage.some(
                                              (productHasImage) =>
                                                productHasImage.id ===
                                                  product.id &&
                                                !productHasImage.hasImage,
                                            ) && (
                                              <ImageNotSupportedIcon
                                                fontSize="small"
                                                className="text-zinc-400"
                                              />
                                            )}
                                          </div>
                                        </Table.Cell>
                                      </div>
                                    </div>
                                    <div className="flex items-center gap-4">
                                      {domains?.items.map((domain) => (
                                        <Table.Cell
                                          items={categoryProductsShown}
                                          className="flex items-center justify-center gap-2"
                                          key={`checkbox-${domain.id}`}
                                          style={{
                                            width: `${domainWidth}ch`,
                                          }}
                                        >
                                          <span className="w-full">
                                            <input
                                              checked={checkProductsDomains.some(
                                                (productDomain) =>
                                                  domain.id ===
                                                    productDomain?.domainId &&
                                                  productDomain?.productId ===
                                                    product.id &&
                                                  productDomain?.isCheck,
                                              )}
                                              onChange={(event) =>
                                                handleCheckDomain(
                                                  event.target.checked,
                                                  product.id,
                                                  domain.id,
                                                  product.domains,
                                                )
                                              }
                                              type="checkbox"
                                              id="reviewed-approved"
                                              className="size-4"
                                              disabled={isShowEditButton}
                                            />
                                          </span>
                                        </Table.Cell>
                                      ))}

                                      {!isShowEditButton && canEditOrder && (
                                        <img
                                          src="/icons/drag.png"
                                          alt="Ícone para arrastar Descrição"
                                          className="mr-4 size-5 cursor-grab"
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            </Table.Row>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table.Root>
              )}
            </>
          )}
        </div>
      </Modal>
      <Modal
        width="90vw"
        // contentStyle="p-0 fixed"
        isOpen={isSelectProductModalOpen}
        onClose={handleCloseSelectProductModal}
      >
        <ProductsPartsContent
          handleTableRowClick={handleLinkProductClick}
          footerClassName="w-[90vw]"
          title="Peças e Produtos"
          itemsInUse={categoryProducts?.items}
          isModal
        />
      </Modal>
      <SelectModal
        currentCategoryLineName={currentCategoryLineName}
        isModalOpen={isLineModalOpen}
        onModalClose={handleCloseLineModal}
        onRowClick={handleSelectLine}
      />
      <ConfirmModal
        contentMessage="Deseja excluir essa categoria?"
        isModalOpen={isConfirmModalOpen}
        onCloseModal={handleCloseConfirmModal}
        onConfirm={handleConfirmDelete}
        onNotConfirm={handleCloseConfirmModal}
      />
      <ConfirmModal
        contentMessage="O produto selecionado será desvinculado dessa categoria. Deseja prosseguir?"
        isModalOpen={isConfirmRemoveProductModalOpen}
        onCloseModal={handleCloseRemoveProductModal}
        onConfirm={handleConfirmProductRemove}
        onNotConfirm={handleCloseRemoveProductModal}
      />
      <ConfirmModal
        contentMessage="Produtos sem imagem foram selecionados. Deseja prosseguir?"
        isModalOpen={isConfirmUploadWithoutImageModalOpen}
        onCloseModal={handleCloseConfirmUploadWithoutImageModal}
        onConfirm={handleConfirmUploadProductsWithoutImage}
        onNotConfirm={handleCloseConfirmUploadWithoutImageModal}
      />
      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
