import { useForm } from 'react-hook-form';
import {
  NewsTypeFormData,
  NewsTypePaginated,
  SaveNewsTypeDto,
  UpdateNewsTypeDto,
} from '../../../types/newsType';
import { newsTypeSchema } from '../../../validations/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { useMutation, useQuery } from 'react-query';
import { useError } from '../../../hooks/useError';
import { newsCache, newsTypesCache } from '../../../constants/requestCacheName';
import {
  deleteNewsType,
  getNewsSortByTypeId,
  reorderNewsByType,
  saveNewsType,
  updateNewsType,
} from '../../../services/newsTypes';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { AxiosError, isAxiosError } from 'axios';
import { useRef, useState } from 'react';
import { useEdit } from '../../../hooks/useEdit';
import { useModal } from '../../../hooks/useModal';
import { NewsListSort, NewsSort, ReorderNewsDto } from '../../../types/news';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { DropResult } from '@hello-pangea/dnd';
import { reorderList } from '../../../utils/dragDrop';

export const useNewsTypes = () => {
  const reorderedTableUpdate = useRef<NewsSort[] | null>(null);
  const currentNewsName = useRef('');
  const [showEditContent, setShowEditContent] = useState(false);

  const {
    data: newsByType,
    isFetching: isFetchingNewsByType,
    refetch: refetchNewsByType,
  } = useQuery<NewsListSort>(
    newsCache,
    async () => {
      const data = (await getNewsSortByTypeId(newsTypesIdUpdate.current!)).data;

      return {
        ...data,
        items: data.items.map((item) => ({
          ...item,
          order: item.order ?? 0,
        })),
      };
    },
    {
      enabled: false,
      retry: false,
    },
  );

  const {
    itemsShown: itemsNewsByTypeShown,
    handleSortTable: sortNewsByTypeTable,
    sortField: sortFieldNewsByType,
    inputRef: newsByTypeInputRef,
    handleReset,
    handleCancelSearch: handleCancelNewsByTypeSearch,
    isSearchInputDirty: isNewsByTypeInputDirty,
    handleSearch: handleNewsByTypeSearch,
    defineItemsShown,
  } = useHandleTable(newsByType?.meta.totalItems ?? 0, newsByType?.items ?? []);

  const newsByTypeShown: NewsSort[] = itemsNewsByTypeShown;

  const handleChangeEditOrderButtonClick = () => {
    if (canEdit) {
      handleUnedit();
      return;
    }

    handleReset();
    handleEdit();
  };

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    reset,
    watch
  } = useForm<NewsTypeFormData>({
    resolver: zodResolver(newsTypeSchema),
    defaultValues: {
      newsType: '',
    },
  });

  const newsType = watch('newsType')

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: updateNewsTypeMutate } = useMutation({
    mutationFn: async ({ id, name }: UpdateNewsTypeDto) => {
      return (await updateNewsType({ id, name })).data;
    },

    onSuccess: (data) => {
      updateItemOnScreen<NewsTypePaginated>(newsTypesCache, data);
      // handleCloseMenageModal();
      handleUnedit()
      setShowEditContent(false)
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar tipo de notícia',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Tipo de notícia já cadastrado');
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteNewsTypeMutate } = useMutation({
    mutationFn: async () => {
      if (newsTypesIdUpdate.current) {
        await deleteNewsType(newsTypesIdUpdate.current);
        return;
      }

      setErrorMessage('Falha ao deletar tipo de newso');
    },

    onSuccess: () => {
      if (newsTypesIdUpdate.current) {
        removeItemFromScreen<NewsTypePaginated>(
          newsTypesCache,
          newsTypesIdUpdate.current,
        );
      }

      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar tipo de notícia',
        setErrorMessage,
        () => {
          if (isAxiosError(error) && error.response?.status === 409) {
            setErrorMessage(
              'Este tipo de notícia só pode ser apagado depois que não houver itens associados a ele',
            );
            return true;
          }
        },
      );
    },
  });

  const newsTypesIdUpdate = useRef<number | null>(null);
  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: closeMenageModal,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: handleCloseConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  const handleTableRowClick = (newsTypeId: number, newsTypeName: string) => {
    newsTypesIdUpdate.current = newsTypeId;
    currentNewsName.current = newsTypeName;
    setValue('newsType', newsTypeName);
    refetchNewsByType();
    handleOpenMenageModal();
  };

  const handleAddNewsTypeClick = () => {
    handleOpenMenageModal();
  };

  const handleCloseMenageModal = () => {
    newsTypesIdUpdate.current = null;
    currentNewsName.current = '';
    reset();
    handleUnedit();
    setShowEditContent(false);
    closeMenageModal();
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmDeleteModal();
  };

  const { mutate: saveNewsTypeMutate } = useMutation({
    mutationFn: async ({ name }: SaveNewsTypeDto) => {
      return (await saveNewsType({ name })).data;
    },

    onSuccess: (data) => {
      addItemOnScreen<NewsTypePaginated>(newsTypesCache, data);
      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar tipo de notícia',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Tipo de notícia já cadastrado');
            return true;
          }
        },
      );
    },
  });

  const handleButtonOkClick = ({ newsType }: NewsTypeFormData) => {
    if (newsTypesIdUpdate.current) {
      if (currentNewsName.current !== newsType) {
        updateNewsTypeMutate({
          id: newsTypesIdUpdate.current!,
          name: newsType,
        });
      }

      handleExecuteReoder();
      return;
    }

    saveNewsTypeMutate({ name: newsType });
  };

  const handleExecuteReoder = () => {
    if (
      newsTypesIdUpdate.current &&
      currentNewsName.current &&
      reorderedTableUpdate.current
    ) {
      const reorderDto: ReorderNewsDto[] = reorderedTableUpdate.current.map(
        ({ id, order }) => ({
          newsId: id,
          order,
        }),
      );

      reorderNewsMutate(reorderDto);
    }
  };

  const handleClearNewsTypeField = () => {
    setValue('newsType', '', { shouldDirty: true });
  };

  const handleConfirmDelete = () => {
    deleteNewsTypeMutate();
    handleCloseConfirmDeleteModal();
  };

  const handleSortNewsByTypeTable = (fieldNameSort: string) => {
    if (!canEdit) {
      sortNewsByTypeTable(fieldNameSort);
    }
  };

  const handleEditButtonClick = () => {
    setShowEditContent(true);
  };

  const isShowEditButton = !!newsTypesIdUpdate.current && !showEditContent;

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedTable = reorderList(
      newsByTypeShown,
      result.source.index,
      result.destination.index,
    );

    reorderedTable.forEach((news, index) => {
      news.order = index + 1;
    });

    reorderedTableUpdate.current = reorderedTable;

    defineItemsShown(reorderedTable);
  };

  const { mutate: reorderNewsMutate } = useMutation({
    mutationFn: async (dto: ReorderNewsDto[]) => {
      if (newsTypesIdUpdate.current) {
        await reorderNewsByType(dto);
        return;
      }

      setErrorMessage('Falha ao reordenar');
    },

    onSuccess: () => {
      handleCloseMenageModal();
    },
    onError: (error) => {
      mutationErrorHandling(error, 'Falha ao reordenar', setErrorMessage);
    },
  });

  return {
    newsTypesIdUpdate: newsTypesIdUpdate.current,
    isMenageModalOpen,
    isConfirmDeleteModalOpen,
    isShowEditButton,
    errors,
    dirtyFields,
    newsType,
    errorMessage,
    isFetchingNewsByType,
    canEdit,
    newsByTypeLength: newsByType?.meta.totalItems ?? 0,
    newsByTypeInputRef,
    isNewsByTypeInputDirty,
    newsByTypeShown,
    sortFieldNewsByType,
    newsByType,
    showEditContent,
    clearError,
    handleConfirmDelete,
    handleClearNewsTypeField,
    register,
    handleSubmit,
    handleButtonOkClick,
    handleCloseConfirmDeleteModal,
    handleDeleteButtonClick,
    handleTableRowClick,
    handleAddNewsTypeClick,
    handleCloseMenageModal,
    handleChangeEditOrderButtonClick,
    handleNewsByTypeSearch,
    handleCancelNewsByTypeSearch,
    handleSortNewsByTypeTable,
    handleDragEnd,
    handleEditButtonClick,
  };
};
