import Modal from '../Modal';

type PopUp = {
  titleField: string;
  subtitleField: string;
  contentField: string;
  footerField: string;
};

type PopupProps = {
  isOpen: boolean;
  onClose: () => void;
  popUp: PopUp | undefined | null;
};

export default function Popup({ isOpen, onClose, popUp }: PopupProps) {
  return (
    <Modal
      iconContainerStyle={{ right: '0rem' }}
      isOpen={isOpen}
      width="fit-content"
      onClose={onClose}
      contentStyle={'mx-5 py-8'}
    >
      <div className="flex flex-col gap-4 break-words">
        <div
          dangerouslySetInnerHTML={{ __html: popUp?.titleField ?? '' }}
          className="text-3xl"
        />
        <div
          dangerouslySetInnerHTML={{ __html: popUp?.subtitleField ?? '' }}
          className="text-2xl"
        />
        <div
          dangerouslySetInnerHTML={{ __html: popUp?.contentField ?? '' }}
          className="text-xl"
        />
        <div
          dangerouslySetInnerHTML={{ __html: popUp?.footerField ?? '' }}
          className="text-xl"
        />
      </div>
    </Modal>
  );
}
