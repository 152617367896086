import { SelectChangeEvent } from '@mui/material';
import { useState, useEffect, useCallback, useRef } from 'react';
import { verifyScreenIsLessThan } from '../../utils/verifyScreenIsLessThan';
import {
  FindAllPartsGroup,
  PartsGroupOptionalDescriptionType,
  PartsGroupSelected,
} from '../../types/partsGroup';
import { useModal } from '../../hooks/useModal';
import {
  deletePartsGroupItemByProductId,
  getAllPartsGroupByProductId,
  getAllPartsGroupItemsByProductId,
} from '../../services/partsGroupItem';
import { useMutation, useQuery } from 'react-query';
import {
  PartsGroupByProductIdApiCache,
  coordinatesByProductIdApiCache,
  partsByProductIdPartsGroupIdApiCache,
  partsGroupItemsByProductIdApiCache,
} from '../../constants/requestCacheName';
import {
  FindAllPartsGroupItem,
  PartsGroupItem,
} from '../../types/partsGroupItem';
import { useQueryCache } from '../../hooks/useQueryCache';
import { useParams } from 'react-router-dom';
import { useSearchParams } from '../../hooks/useSearchParams';
import {
  Coordinate,
  FindAllCoordinates,
} from '../../types/partsGroupCoordinate';
import { getAllCoordinatesByProductId } from '../../services/partsGroupItemCoordinate';
import { usePartsCatalogStore } from '../../store/partsCatalog';

export const usePartsGroup = (
  handleCopyAllPartsGroup: (
    partsGroupItems: PartsGroupItem[],
    coordinates: Coordinate[],
  ) => void,
  onSetCurrentProductId: (productId: number) => void,
  currentPartsGroupId?: number,
) => {
  const isMobile = verifyScreenIsLessThan(1250);
  const { productId } = useParams();

  const [productName, partGroupName] = useSearchParams(
    'product',
    'partGroupName',
  );

  const firstRender = useRef(false);

  const currentProductId = useRef(+(productId ?? -1));

  const {
    state: { shouldShowFirstCurrentPartsGroup, currentPartsGroup },
    actions: {
      setCurrentPartsGroup,
      setCurrentPartClicked,
      setCurrentPartIdHover,
      setCurrentProductId,
      handleUnedit,
      setShouldShowFirstCurrentPartsGroup,
    },
  } = usePartsCatalogStore();

  const {
    isModalOpen: isDeleteAllModalOpen,
    handleCloseModal: handleCloseDeleteAllModal,
    handleOpenModal: handleOpenDeleteAllModal,
  } = useModal();

  const { addItemOnScreen, clearCache } = useQueryCache();

  const { data: productPartsGroup, refetch: refetchProductPartsGroup } =
    useQuery<FindAllPartsGroup>(
      `${PartsGroupByProductIdApiCache}`,
      async () =>
        (await getAllPartsGroupByProductId(+currentProductId.current!)).data,
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
      },
    );

  const { data: partsGroup } = useQuery<FindAllPartsGroup>(
    `${PartsGroupByProductIdApiCache}${productId}`,
    async () => (await getAllPartsGroupByProductId(+productId!)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: partsProduct, refetch: refetchPartsProduct } =
    useQuery<FindAllPartsGroupItem>(
      partsGroupItemsByProductIdApiCache,
      async () =>
        (await getAllPartsGroupItemsByProductId(+currentProductId.current!))
          .data,
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
      },
    );

  const {
    data: currentPartsGroupImagesCoordinates,
    refetch: refetchCoordinates,
  } = useQuery<FindAllCoordinates>(
    coordinatesByProductIdApiCache,
    async () => {
      return (await getAllCoordinatesByProductId(+currentProductId.current!))
        .data;
    },
    {
      retry: false,
      enabled: false,
    },
  );

  const { mutate: deleteAll } = useMutation({
    mutationFn: async () => {
      return (await deletePartsGroupItemByProductId(+productId!)).data;
    },

    onSuccess: () => {
      handleCloseDeleteAllModal();
      clearCache(`${PartsGroupByProductIdApiCache}${productId}`);
      clearCache(
        `${partsByProductIdPartsGroupIdApiCache}${currentPartsGroupId}`,
      );
      handleUnedit();
    },

    onError: () => {
      alert('Falha ao deletar os conjuntos de peças');
    },
  });

  const [selectedPartGroup, setSelectedPartGroup] = useState('');
  const [currentPartsGroupToDeleteId, setCurrentPartsGroupToDeleteId] =
    useState<number | null>(null);

  const [partsGroupInUse, setPartsGroupInUse] = useState<string[]>([]);

  const {
    isModalOpen: isSelectPartsGroupModalOpen,
    handleCloseModal: handleCloseSelectPartsGroupModal,
    handleOpenModal: handleOpenSelectPartsGroupModal,
  } = useModal();

  const {
    isModalOpen: isSelectProductModalOpen,
    handleCloseModal: handleCloseSelectProductModal,
    handleOpenModal: handleOpenSelectProductModal,
  } = useModal();

  const handleClickPartsGroup = useCallback(
    (
      partGroup: PartsGroupOptionalDescriptionType,
      productId?: number | null,
    ) => {
      if (productId) {
        setCurrentProductId(productId);
      }
      setCurrentPartsGroup(partGroup);

      setCurrentPartClicked(null);
      setCurrentPartIdHover(null);
    },
    [
      setCurrentPartClicked,
      setCurrentPartIdHover,
      setCurrentPartsGroup,
      setCurrentProductId,
    ],
  );

  const handleSelectPartsGroup = useCallback(
    (partsGroup: PartsGroupSelected) => {
      const partsGroupSelected: PartsGroupOptionalDescriptionType = {
        ...partsGroup,
        description: { id: -1, name: partsGroup.name }, // Não tenho o id da descrição, mas ele não importa nesse caso
        totalItems: 0,
      };
      addItemOnScreen(
        `${PartsGroupByProductIdApiCache}${productId}`,
        partsGroupSelected,
        true,
      );
      setCurrentPartsGroup(partsGroupSelected);
      handleCloseSelectPartsGroupModal();
    },
    [
      addItemOnScreen,
      handleCloseSelectPartsGroupModal,
      productId,
      setCurrentPartsGroup,
    ],
  );

  // useEffect(() => {
  //   if (partGroupName) {
  //     const currentPartsGroup = partsGroup?.items.find(
  //       (partsGroup) => partsGroup.name === partGroupName,
  //     );
  //     if (currentPartsGroup) {
  //       handleClickPartsGroup(currentPartsGroup);
  //     }
  //   }
  // }, [handleClickPartsGroup, partGroupName, partsGroup]);

    useEffect(() => {
      if (partsGroup && partsGroup.items.length > 0) {
        setSelectedPartGroup(partsGroup.items[0].name);
      }

      console.log("🚀 ~ useEffect ~ shouldShowFirstCurrentPartsGroup:", shouldShowFirstCurrentPartsGroup)
      if (shouldShowFirstCurrentPartsGroup) {
        setCurrentPartsGroup(currentPartsGroup);
        setSelectedPartGroup(currentPartsGroup?.name!);
        return;
      }

      if (partsGroup?.items && !shouldShowFirstCurrentPartsGroup && !firstRender.current) {
      firstRender.current = true;
        setCurrentPartsGroup(partsGroup.items[0]);
        return;
      }

      // setCurrentPartsGroup(null);

    }, [currentPartsGroup, partsGroup, setCurrentPartsGroup, setShouldShowFirstCurrentPartsGroup, shouldShowFirstCurrentPartsGroup]);

  useEffect(() => {
    productPartsGroup?.items.forEach(
      ({ description, id, image, name, totalItems }) => {
        const partsGroup = {
          description: description.name,
          id,
          image,
          name,
          totalParts: totalItems.toString(),
        };

        const partsGroupSelected: PartsGroupOptionalDescriptionType = {
          ...partsGroup,
          description: { id: -1, name: partsGroup.name }, // Não tenho o id da descrição, mas ele não importa nesse caso
          totalItems: 0,
        };
        addItemOnScreen(
          `${PartsGroupByProductIdApiCache}${productId}`,
          partsGroupSelected,
          true,
        );
      },
    );
  }, [addItemOnScreen, productId, productPartsGroup]);

  useEffect(() => {
    if (partsProduct && currentPartsGroupImagesCoordinates) {
      handleCopyAllPartsGroup(
        partsProduct.items,
        currentPartsGroupImagesCoordinates.items,
      );
    }
  }, [
    currentPartsGroupImagesCoordinates,
    handleCopyAllPartsGroup,
    partsProduct,
  ]);

  useEffect(() => {
    if (partsGroup && partsGroup.items.length > 0) {
      setSelectedPartGroup(partsGroup.items[0].name);
    }

    // if (partsGroup?.items) {
    //   setCurrentPartsGroup(partsGroup.items[0]);
    //   return;
    // }

    // setCurrentPartsGroup(null);
  }, [partsGroup, setCurrentPartsGroup]);

  useEffect(() => {
    const inUse = partsGroup?.items.map((item) => item.name) ?? [];
    setPartsGroupInUse(inUse);
  }, [partsGroup]);

  const handleSelectPartGroup = (event: SelectChangeEvent) => {
    setSelectedPartGroup(event.target.value);
  };

  const handleRemoveIconClick = (partGroupId: number) => {
    if (partGroupId === currentPartsGroupToDeleteId) {
      setCurrentPartsGroupToDeleteId(null);
      return;
    }

    setCurrentPartsGroupToDeleteId(partGroupId);
  };

  const handleAddPartsGroupClick = () => {
    handleOpenSelectPartsGroupModal();
  };

  const handleCopyFromProductButtonClick = () => {
    handleOpenSelectProductModal();
  };

  const handleSelectProduct = (productId: number) => {
    currentProductId.current = productId;
    refetchProductPartsGroup();
    refetchPartsProduct();
    refetchCoordinates();
    handleCloseSelectProductModal();
    onSetCurrentProductId(+productId);
  };

  const handleButtonDeleteAllClick = () => {
    handleOpenDeleteAllModal();
  };

  const handleConfirmDeleteAllPartsGroups = () => {
    deleteAll();
  };

  return {
    isMobile,
    selectedPartGroup,
    currentPartsGroupToDeleteId,
    isSelectPartsGroupModalOpen,
    partsGroupInUse,
    isSelectProductModalOpen,
    isDeleteAllModalOpen,
    productName,
    partsGroup,
    productId,
    handleClickPartsGroup,
    handleSelectPartsGroup,
    handleConfirmDeleteAllPartsGroups,
    handleButtonDeleteAllClick,
    handleCloseDeleteAllModal,
    handleCopyFromProductButtonClick,
    handleSelectProduct,
    handleCloseSelectProductModal,
    handleAddPartsGroupClick,
    handleCloseSelectPartsGroupModal,
    handleSelectPartGroup,
    handleRemoveIconClick,
  };
};
