import { FindAllCarriers } from '../types/carriers';
import { api } from './api';

export const carrierMock = {
  items: [
    {
      id: 1,
      name: 'Transportadora teste 1',
      cnpj: '00.702.345/0001-97',
    },
    {
      id: 2,
      name: 'Transportadora teste 2',
      cnpj: '00.702.345/0001-97',
    },
    {
      id: 3,
      name: 'Transportadora teste 3',
      cnpj: '49.276.705/0001-25',
    },
  ],
  meta: {
    totalItems: 10,
  },
};

export const getAllCarriers = async () => {
  const response = await api.get<FindAllCarriers>(`/carrier/v1`);
  // return {
  //   data: carrierMock,
  // };
  return response;
};
