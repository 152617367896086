import ConfirmModal from '../../../components/ConfirmModal';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import ImagesContent from '../../../components/ImagesContent';
import Input from '../../../components/Input';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import { imageTypesText } from '../../../constants/imageTypes';
import { ImageTypes } from '../../../enums/imageTypes';
import { useImages } from './useImages';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AlertIcon from '@mui/icons-material/ReportProblem';

export default function Images() {
  const {
    isMenageModalOpen,
    imageNameUpdate,
    isConfirmDeleteModalOpen,
    previewImage,
    isImageLoading,
    dirtyFields,
    errors,
    reference,
    files,
    imageTypes,
    errorMessage,
    isImageDeleteLoading,
    handleIgnoreExistingImages,
    handleOverrideExistingImages,
    handleCloseAlreadyExistsModal,
    clearError,
    handleUploadImageClick,
    handleDeleteButtonClick,
    handleConfirmDeleteImage,
    handleCloseConfirmDeleteModal,
    handleCancelIconClick,
    register,
    handleButtonOkClick,
    handleAddImageClick,
    handleTableRowClick,
    handleCloseManageModal,
  } = useImages();

  return (
    <>
      <ImagesContent
        onAddImageClick={handleAddImageClick}
        onTableRowClick={handleTableRowClick}
      />

      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseManageModal}
        cancelIcon={false}
      >
        <HeaderModalManageItem
          showDeleteButton={!!imageNameUpdate}
          deleteButtonContent={
            isImageDeleteLoading ? <Loading /> : 'Apagar imagem'
          }
          isEditable={true}
          onDeleteButtonClick={handleDeleteButtonClick}
          title={imageNameUpdate ? 'Imagem' : 'Nova imagem'}
          deleteButtonClassName="relative bottom-[3.75rem]"
          mainButtonContent={
            imageNameUpdate ? '' : `${isImageLoading ? '' : 'Ok'}`
          }
          onCancelClick={handleCloseManageModal}
          onOkClick={handleButtonOkClick}
        />
        {isImageLoading && (
          <div className="absolute right-2 top-0 z-20">
            <Loading />
          </div>
        )}

        <div className="bottom-14 mt-14 flex flex-col gap-8 mb-2">
          <Input
            maxLength={250}
            isDirty={dirtyFields.reference}
            type="text"
            id="reference"
            label="Nome ou código"
            disabled={!!imageNameUpdate || files?.length > 1}
            helperText={errors.reference?.message}
            onCancelIconClick={handleCancelIconClick}
            {...register('reference')}
            value={reference}
          />

          <div className="w-full">
            <label>Tipo da imagem</label>
            <select
              className="w-full border-b border-solid py-4 outline-none"
              disabled={!!imageNameUpdate}
              {...register('type')}
            >
              {imageTypes?.items.map((imageType) => {
                return (
                  <option key={imageType.id} value={imageType.name}>
                    {imageTypesText[imageType.name as ImageTypes] ??
                      imageType.name}
                  </option>
                );
              })}
              {/* {Object.values(ImageTypes).map((imageType) => (
                <option value={imageType}>{imageTypesText[imageType]}</option>
              ))} */}
            </select>
          </div>

          <div>
            <label
              data-preview={!!previewImage}
              onClick={handleUploadImageClick}
              htmlFor="input-file"
              className="flex h-[300px] w-full cursor-pointer justify-center rounded-lg data-[preview=false]:items-center data-[preview=false]:bg-backgroundPrimary"
            >
              {(files?.length <= 1 || !files) && (
                <>
                  {previewImage ? (
                    <div className="h-full w-full">
                      <img
                        src={previewImage}
                        alt="Imagem selecionada"
                        className="mx-auto max-h-[290px] cursor-pointer rounded-lg"
                      />
                    </div>
                  ) : (
                    <FileUploadIcon
                      fontSize="large"
                      className="absolute cursor-pointer text-secondaryText"
                    />
                  )}
                </>
              )}

              {files?.length > 1 && (
                <ul className="size-full max-h-[300px] overflow-auto px-4">
                  {files.map((file) => (
                    <li key={file.name} className="my-4">
                      {file.name}
                    </li>
                  ))}
                </ul>
                // <CheckIcon className="text-green-500" fontSize="large" />
              )}
              <input
                type="file"
                id="input-file"
                multiple
                {...register('image')}
                disabled={!!imageNameUpdate}
                className="absolute flex cursor-pointer opacity-0"
              />
            </label>
            <span className="text-xs text-red-600">
              {errors.image?.message}
            </span>
          </div>
        </div>
      </Modal>

      <ConfirmModal
        contentMessage="Deseja excluir essa imagem?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDeleteImage}
        onNotConfirm={handleCloseConfirmDeleteModal}
      />

      <Modal
        isOpen={!!errorMessage}
        onClose={clearError}
        cancelIcon={false}
        contentStyle="rounded-md p-0"
      >
        <div className="flex w-full flex-col gap-8 p-8">
          <div className="flex gap-4">
            <AlertIcon className="text-zinc-500" fontSize="large" />
            <span className="text-lg text-zinc-500">{errorMessage}.</span>
          </div>
          <div className="flex justify-between">
            <button
              onClick={handleCloseAlreadyExistsModal}
              className="text-primary"
            >
              Cancelar
            </button>
            <button
              onClick={handleOverrideExistingImages}
              className="text-primary"
            >
              Substituir os existentes
            </button>
            <button
              onClick={handleIgnoreExistingImages}
              className="text-primary"
            >
              Ignorar os existentes
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
