import { SxProps, Theme } from '@mui/material';

export const menuCardStyle: SxProps<Theme> = {
  height: '26rem',
  borderRadius: '20px',
  border: '2px solid #E9E9E9',
  boxShadow: 'none',
};

export const cardContentStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
};

export const cardLinksContainerStyle: SxProps<Theme> = {
  height: '12rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const cardSearchLinksStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '2rem',
  gap: '1rem',
};

export const linkContainerStyle: SxProps<Theme> = {
  display: 'flex',
};
