import { Tooltip, TooltipProps } from '@mui/material';
import React, { ReactElement, useState } from 'react';

type ToolTipProps = Omit<TooltipProps, "children"> & {
  children: ReactElement; // Garante que `children` seja um elemento válido
}
export default function ToolTipCustom({title, placement, children, ...props }: ToolTipProps) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip
    title={title}
    onOpen={handleOpen}
    onClose={handleClose}
    open={open}
    placement={placement}
    enterDelay={2000}
    leaveDelay={0}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      },
    }}
    >
      {children}
    </Tooltip>
  );
}
