import { Popover, PopoverContent, PopoverTrigger } from '../ui/Popover';
import { Calendar } from '../ui/Calendar';
import Input from '../Input';
import { formatDateString } from '../../utils/format';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

type DatePickerProps = {
  date: Date | string | undefined | null;
  label?: string;
  disabled?: boolean;
  isDirty?: boolean | undefined;
  onDateSelect: (date: Date | undefined) => void | undefined;
  onInputChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onCancelIconClick?: () => void;
};

export function DatePicker({
  date,
  label,
  disabled = false,
  isDirty,
  onCancelIconClick,
  onInputChange,
  onDateSelect,
}: DatePickerProps) {
  const isValidDate = (value: string) => {
    if (
      !(date instanceof Date) &&
      (!isNaN(Number(value)) || date?.includes('/'))
    ) {
      return false;
    }

    const parsedDate = Date.parse(value);
    return !isNaN(parsedDate);
  };

  return (
    <Popover>
      <PopoverTrigger asChild disabled={disabled}>
        <div>
          {!!date && (
            <Input
              onChange={onInputChange}
              type="text"
              disabled={disabled}
              forceLabelShrink
              isDirty={isDirty}
              label={label}
              onCancelIconClick={onCancelIconClick}
              // value={formatDateString(
              //   date instanceof Date ? date?.toDateString() : date ?? '',
              // )}
              value={
                date instanceof Date || isValidDate(date?.toString() ?? '')
                  ? format(new Date(date ?? ''), 'dd/MM/yyy', { locale: ptBR })
                  : formatDateString(date ?? '')
              }
            />
          )}
          {!date && (
            <Input
              onChange={onInputChange}
              type="text"
              disabled={disabled}
              forceLabelShrink={false}
              isDirty={isDirty}
              label={label}
              onCancelIconClick={onCancelIconClick}
              value=""
            />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date instanceof Date ? date : new Date(date ?? '')}
          onSelect={onDateSelect}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}
