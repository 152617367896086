import BarCode from '../pages/Admin/BarCode';
import DescriptionTypes from '../pages/Admin/DescriptionTypes';
import EmailSignature from '../pages/Admin/EmailSignature';
import Users from '../pages/Admin/Users';
import UserTypes from '../pages/Admin/UserTypes';
import { PagesMapper } from '../types/pages';
import PartsGroup from '../pages/Admin/PartsGroup';
import UploadDocument from '../pages/Admin/UploadDocument';
import Pdf from '../pages/Admin/pdf';
import Parts from '../pages/Parts';
import Synchronization from '../pages/Admin/Synchronization';
import DocumentTypes from '../pages/Admin/DocumentTypes';
import Images from '../pages/Admin/Images';
import ContactUsAdm from '../pages/Admin/ContactUs';
import Questions from '../pages/Admin/Questions';
import Sizing from '../pages/Admin/Sizing';
import NewsTypes from '../pages/Admin/NewsType';
import UploadNews from '../pages/Admin/UploadNews';
import PopUp from '../pages/Admin/PopUp';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import ProductIcon from '@mui/icons-material/Category';
import EngineeringIcon from '@mui/icons-material/Engineering';
import LanguageIcon from '@mui/icons-material/Language';
import DocumentTypesIcon from '@mui/icons-material/Article';
import SyncIcon from '@mui/icons-material/Sync';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { AlignVerticalBottom } from '@mui/icons-material';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import ExtensionIcon from '@mui/icons-material/Extension';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ImageIcon from '@mui/icons-material/Image';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import BarCodeIcon from '../assets/barCodeIcon';
import PartsGroupIcon from '../assets/partsGroupIcon';
import DescriptionIcon from '../assets/descriptionIcon';
import ProductPartsDescriptionIcon from '../assets/productPartsDescription';
import LinesCategoryIcon from '../assets/linesCategoryIcon';
import Products from '../pages/Products';
import DescriptionTypeIcon from '../assets/descriptionType';
import LinkProductsPartsDescription from '../pages/Admin/LinkProductsPartsDescription';
import Lines from '../pages/Admin/Lines';
import ImageTypes from '../pages/Admin/ImageTypes';
import ImageExtension from '../pages/Admin/ImageExtension';
import EmailTrigger from '../pages/Admin/EmailTrigger';
import EmailIcon from '@mui/icons-material/Email';
import Description from '../pages/Admin/Description';
import Domain from '../pages/Admin/Domain';
import ProductsCatalog from '../pages/Admin/ProductsCatalog';
import GridOnIcon from '@mui/icons-material/GridOn';
import Categories from '../pages/Admin/Categories';
import Duimp from '../pages/Admin/Duimp';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ErrosDuimp from '../pages/Admin/ErrorsDuimp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PageviewIcon from '@mui/icons-material/Pageview';
import LogsDuimp from '../pages/LogsDuimp';
// import ProdutosTeste from '../buffalos/pages/ProdutosNavbarTeste';
// import ProdutosTeste2 from '../buffalos/pages/ProdutosTeste2';
// import ProdutosTeste3 from '../buffalos/pages/ProdutosTeste3';
import LabelNF from '../pages/Admin/LabelNF';
import Subject from '../pages/Admin/Subjects';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';

export const pagesMapper: PagesMapper = {
  Assinaturas: {
    path: '/admin/email-signature',
    element: <EmailSignature />,
    icon: <EditNoteIcon />,
  },
  Categorias: {
    path: '/admin/categories',
    element: <Categories />,
    icon: <LinesCategoryIcon />,
  },
  'Código de barras': {
    path: '/admin/bar-code',
    element: <BarCode />,
    icon: <BarCodeIcon isLight />,
  },
  'Conjunto de peças': {
    path: '/admin/parts-group',
    element: <PartsGroup />,
    icon: <PartsGroupIcon />,
  },
  Descrição: {
    path: '/admin/description',
    element: <Description />,
    icon: <DescriptionIcon />,
  },
  'Descrição de peças e produtos': {
    path: '/admin/products-parts-description',
    element: <LinkProductsPartsDescription />,
    icon: <ProductPartsDescriptionIcon />,
  },
  Documentos: {
    path: '/admin/upload-document',
    element: <UploadDocument />,
    icon: <SummarizeIcon />,
  },
  Domínio: {
    path: '/admin/domain',
    element: <Domain />,
    icon: <LanguageIcon />,
  },
  'Etiquetas NF': {
    path: '/admin/label-nf',
    element: <LabelNF />,
    icon: <ProductIcon />,
  },
  Extensão: {
    path: '/admin/image-extension',
    element: <ImageExtension />,
    icon: <ExtensionIcon />,
  },
  Linhas: {
    path: '/admin/lines',
    element: <Lines />,
    icon: <LinesCategoryIcon />,
  },
  PDF: {
    path: '/admin/pdf',
    element: <Pdf />,
    icon: <PictureAsPdfIcon />,
  },
  Peças: {
    path: '/admin/parts',
    element: <Parts />,
    icon: <EngineeringIcon />,
  },
  Produtos: {
    path: '/admin/products',
    element: <Products />,
    icon: <ProductIcon />,
  },
  Sincronização: {
    path: '/admin/synchronization',
    element: <Synchronization />,
    icon: <SyncIcon />,
  },
  'Tipos de descrição': {
    path: '/admin/description-types',
    element: <DescriptionTypes />,
    icon: <DescriptionTypeIcon />,
  },
  'Tipos de documentos': {
    path: '/admin/document-types',
    element: <DocumentTypes />,
    icon: <DocumentTypesIcon />,
  },
  'Tipos de imagem': {
    path: '/admin/image-types',
    element: <ImageTypes />,
    icon: <ImageIcon />,
  },
  'Tipos de usuário': {
    path: '/admin/user-types',
    element: <UserTypes />,
    icon: <PeopleIcon />,
  },
  Imagem: {
    path: '/admin/upload-image',
    element: <Images />,
    icon: <CloudUploadIcon fontSize="small" />,
  },
  Usuários: {
    path: '/admin/users',
    element: <Users />,
    icon: <PersonIcon fontSize="small" />,
  },
  'Fale Conosco': {
    path: '/admin/contact-us',
    element: <ContactUsAdm />,
    icon: <LocalPhoneIcon fontSize="small" />,
  },
  'Perguntas Frequentes': {
    path: '/admin/faq',
    element: <Questions />,
    icon: <QuestionMarkIcon fontSize="small" />,
  },
  Dimensionamentos: {
    path: '/admin/sizing',
    element: <Sizing />,
    icon: <AlignVerticalBottom fontSize="small" />,
  },
  'Tipos de Notícias': {
    path: '/admin/news-types',
    element: <NewsTypes />,
    icon: <WallpaperIcon fontSize="small" />,
  },
  Notícias: {
    path: '/admin/news',
    element: <UploadNews />,
    icon: <NewspaperOutlinedIcon fontSize="small" />,
  },
  'Pop-Up': {
    path: '/admin/pop-up',
    element: <PopUp />,
    icon: <BackupTableIcon fontSize="small" />,
  },
  'Emails de Disparo': {
    path: '/admin/email-trigger',
    element: <EmailTrigger />,
    icon: <EmailIcon fontSize="small" />,
  },
  'Catálogo de Produtos': {
    path: '/admin/products-catalog',
    element: <ProductsCatalog />,
    icon: <GridOnIcon fontSize="small" />,
  },
  Duimp: {
    path: '/admin/duimp',
    element: <Duimp />,
    icon: <LocalAtmIcon fontSize="small" />,
  },
  'Erros Duimp': {
    path: '/admin/duimp/erros',
    element: <ErrosDuimp />,
    icon: <ErrorOutlineIcon fontSize="small" />,
  },
  'Logs Duimp': {
    path: '/admin/duimp/logsDuimp',
    element: <LogsDuimp />,
    icon: <PageviewIcon fontSize="small" />,
  },
  Assuntos: {
    path: '/admin/subjects',
    element: <Subject />,
    icon: <SettingsPhoneIcon fontSize="small" />,
  },
};
