import { ReactNode } from 'react';

type VinculationHeaderProps = {
  isFething: boolean;
  isVinculationDisabled: boolean;
  vinculationContent: ReactNode;
  vinculationButtonContent: string;
  orderControlButtonContent?: string;
  onVinculateClick: () => void;
  onControlButtonClick?: () => void;
};

export default function VinculationHeader({
  isFething,
  isVinculationDisabled,
  vinculationContent,
  vinculationButtonContent,
  orderControlButtonContent,
  onVinculateClick,
  onControlButtonClick,
}: VinculationHeaderProps) {
  return (
    <div className="mb-4 flex justify-between bg-backgroundPrimary px-2 py-4 text-xs">
      {!isFething && <span className="font-bold">{vinculationContent}</span>}
      {!isVinculationDisabled && (
        <div className="flex gap-4 text-primary">
          {orderControlButtonContent && (
            <button
              disabled={isVinculationDisabled}
              className="disabled:cursor-default"
              onClick={onControlButtonClick}
            >
              {orderControlButtonContent}
            </button>
          )}
          <button
            disabled={isVinculationDisabled}
            className="disabled:cursor-default"
            onClick={onVinculateClick}
          >
            {vinculationButtonContent}
          </button>
        </div>
      )}
    </div>
  );
}
