import { useMutation, useQuery } from 'react-query';
import {
  CreateLineDto,
  Line,
  LineFormData,
  Lines,
  ReorderLinesDto,
  UpdateLineDto,
} from '../../../types/lines';
import {
  linesApiCache,
  linesImageCache,
} from '../../../constants/requestCacheName';
import {
  deleteLine,
  getAllLines,
  reorderLines,
  saveLine,
  updateLine,
} from '../../../services/lines';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { lineSchema } from '../../../validations/schemas';
import { AxiosError, isAxiosError } from 'axios';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { useModal } from '../../../hooks/useModal';
import { useEdit } from '../../../hooks/useEdit';
import { ImagesResult } from '../../../types/images';
import { getAllFolderImages } from '../../../services/imageStorage';
import { ImageTypes } from '../../../enums/imageTypes';
import {
  getImageFromApi,
  verifyImageUrlExists,
} from '../../../utils/getImageUrl';
import { useError } from '../../../hooks/useError';
import { useImagesSelectFiltered } from '../../../store/imagesSelectFiltered';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { DropResult } from '@hello-pangea/dnd';
import { reorderList } from '../../../utils/dragDrop';

export const useLines = () => {
  const { data: lines, isFetching } = useQuery<Lines>(
    linesApiCache,
    async () => (await getAllLines()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const currentImageType = useRef<ImageTypes | null>(null);
  const reorderedTableUpdate = useRef<ReorderLinesDto[]>([]);

  const {
    canEdit: canEditOrder,
    handleEdit: handleEditOrder,
    handleUnedit: handleUneditOrder,
  } = useEdit();

  const {
    actions: { setImages, setTotalItems },
  } = useImagesSelectFiltered();

  const { refetch: refetchImages } = useQuery<ImagesResult>(
    linesImageCache,
    async () => await getAllFolderImages(currentImageType.current!),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,

      onSuccess: (data) => {
        setImages(data.items);
        setTotalItems(data.meta.totalItems);
      },
    },
  );

  const { mutate: reorderLinesMutate } = useMutation({
    mutationFn: async (dto: ReorderLinesDto[]) => await reorderLines(dto),

    onError: (error) => {
      mutationErrorHandling(error, 'Falha ao reordenar', setErrorMessage);
    },
  });

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal,
    handleOpenModal,
  } = useModal();

  const {
    isModalOpen: isSelectImageModalOpen,
    handleCloseModal: handleCloseSelectImageModal,
    handleOpenModal: handleOpenSelectImageModal,
  } = useModal();

  const { mutate: save } = useMutation({
    mutationFn: async (createLineDto: CreateLineDto) => {
      return (await saveLine(createLineDto)).data;
    },

    onSuccess: (data) => {
      handleCloseModal();
      addItemOnScreen<Lines>(linesApiCache, data);
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar linha',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Linha já cadastrada');
            return true;
          }
        },
      );
    },
  });

  const { mutate: update } = useMutation({
    mutationFn: async (updateLineDto: UpdateLineDto) => {
      return (await updateLine(updateLineDto)).data;
    },

    onSuccess: (data) => {
      updateItemOnScreen(linesApiCache, data);

      // handleCloseModal();
      handleUnedit();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar linha',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Linha já cadastrada');
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteLineMutate } = useMutation({
    mutationFn: async () => {
      if (lineIdUpdate.current) {
        await deleteLine(lineIdUpdate.current);
        return;
      }

      setErrorMessage('Falha ao deletar linha');
    },

    onSuccess: () => {
      if (lineIdUpdate.current) {
        removeItemFromScreen<Lines>(linesApiCache, lineIdUpdate.current);
      }

      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar linha',
        setErrorMessage,
        () => {
          if (isAxiosError(error) && error.response?.status === 400) {
            setErrorMessage(
              'Esta linha só pode ser apagada depois que não houver itens associados a ela',
            );
            return true;
          }
        },
      );
    },
  });

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<LineFormData>({
    resolver: zodResolver(lineSchema),
    defaultValues: {
      line: '',
      carouselImage: '',
      menuImage: '',
    },
  });

  const line = watch('line');
  const currentCarouselImage = watch('carouselImage');
  const currentMenuImage = watch('menuImage');

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [previewImages, setPreviewImages] = useState<
    Pick<LineFormData, 'carouselImage' | 'menuImage'>
  >({
    carouselImage: '',
    menuImage: '',
  });
  const [imagesInUse, setImagesInUse] = useState<Record<'id', string>[]>([]);

  const {
    itemsShown,
    isSearchInputDirty,
    inputRef,
    sortField,
    defineItemsShown,
    handleSearch,
    handleSortTable: sortTable,
    handleCancelSearch,
    handleReset,
  } = useHandleTable(lines?.meta.totalItems ?? 0, lines?.items ?? []);
  const linesShown: Line[] = itemsShown;

  const lineIdUpdate = useRef<number | null>(null);

  const handleTableRowClick = (
    lineId: number,
    lineName: string,
    menuImage: string,
    carouselImage: string,
  ) => {
    lineIdUpdate.current = lineId;
    setValue('line', lineName);
    setValue('carouselImage', carouselImage ?? '');
    setValue('menuImage', menuImage ?? '');
    handleOpenModal();
    if (carouselImage && menuImage) {
      setPreviewImages({
        carouselImage: getImageFromApi(carouselImage, ImageTypes.linesCarousel),
        menuImage: getImageFromApi(menuImage, ImageTypes.linesMenu),
      });
    }
  };

  const handleButtonOkClick = ({
    line,
    carouselImage,
    menuImage,
  }: LineFormData) => {
    if (lineIdUpdate.current) {
      update({
        id: lineIdUpdate.current,
        name: line,
        carouselImageName: carouselImage,
        menuImageName: menuImage,
      });
      // lineIdUpdate.current = null;
      // setValue('line', '');
      // setValue('carouselImage', '');
      // setValue('menuImage', '');
      setValidUrls({
        carouselImage: undefined,
        menuImage: undefined,
      });
      return;
    }
    save({
      name: line,
      carouselImageName: carouselImage,
      menuImageName: menuImage,
    });
  };

  const handleDeleteButtonClick = () => {
    setIsConfirmModalOpen(true);
  };

  const handleAddLineClick = () => {
    handleOpenModal();
  };

  const handleCloseMenageModal = () => {
    lineIdUpdate.current = null;
    reset();
    setPreviewImages({ carouselImage: '', menuImage: '' });
    handleUnedit();
    handleCloseModal();
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmDelete = () => {
    deleteLineMutate();
    setIsConfirmModalOpen(false);
  };

  const handleClearLine = () => {
    setValue('line', '', { shouldDirty: true });
  };

  const handleSelectImage = (
    imageId: number,
    imageName: string,
    imageType: string,
  ) => {
    const types: Record<string, keyof LineFormData> = {
      [ImageTypes.linesCarousel]: 'carouselImage',
      [ImageTypes.linesMenu]: 'menuImage',
    };

    setValue(types[imageType], imageName);

    setPreviewImages((prev) => ({
      ...prev,
      [types[imageType]]: getImageFromApi(imageName, imageType),
    }));

    handleCloseSelectImageModal();
  };

  const handleInputImageClick = (imageType: ImageTypes) => {
    if (!isShowEditButton) {
      if (imageType === ImageTypes.linesCarousel) {
        setImagesInUse([{ id: `${currentCarouselImage}${imageType}` }]);
      }

      if (imageType === ImageTypes.linesMenu) {
        setImagesInUse([{ id: `${currentMenuImage}${imageType}` }]);
      }

      currentImageType.current = imageType;

      refetchImages();
      handleOpenSelectImageModal();
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedTable = reorderList(
      linesShown,
      result.source.index,
      result.destination.index,
    );

    reorderedTableUpdate.current = reorderedTable.map((reorderItem, index) => {
      reorderItem.order = index + 1;

      return {
        lineId: reorderItem.id,
        order: reorderItem.order,
      };
    });

    defineItemsShown(reorderedTable);
  };

  const handleSortTable = (fieldNameSort: string) => {
    if (canEditOrder) return;

    sortTable(fieldNameSort);
  };

  const handleChangeEditOrderButtonClick = () => {
    if (canEditOrder) {
      if (reorderedTableUpdate.current.length > 0) {
        reorderLinesMutate(reorderedTableUpdate.current);
      }

      handleUneditOrder();
      return;
    }

    handleReset();
    handleEditOrder();
  };

  const handleButtonCancelEditClick = useCallback(() => {
    handleUneditOrder();
  }, [handleUneditOrder]);

  const isShowEditButton = !!lineIdUpdate.current && !canEdit;

  interface PreviewImages {
    carouselImage?: string;
    menuImage?: string;
  }

  const [validUrls, setValidUrls] = useState<PreviewImages>({});

  useEffect(() => {
    const validateImages = async () => {
      const results: PreviewImages = {};

      if (previewImages.carouselImage) {
        results.carouselImage = (await verifyImageUrlExists(
          previewImages.carouselImage,
        ))
          ? previewImages.carouselImage
          : undefined;
      }
      if (previewImages.menuImage) {
        results.menuImage = (await verifyImageUrlExists(
          previewImages.menuImage,
        ))
          ? previewImages.menuImage
          : undefined;
      }

      setValidUrls(results);
    };

    validateImages();
  }, [previewImages.carouselImage, previewImages.menuImage]);

  return {
    currentImageType,
    lines,
    isFetching,
    inputRef,
    linesShown,
    isMenageModalOpen,
    isShowEditButton,
    isConfirmModalOpen,
    errors,
    isSearchInputDirty,
    sortField,
    lineIdUpdate: lineIdUpdate.current,
    dirtyFields,
    isSelectImageModalOpen,
    previewImages,
    errorMessage,
    line,
    imagesInUse,
    canEditOrder,
    validUrls,
    currentMenuImage,
    currentCarouselImage,
    defineItemsShown,
    handleButtonCancelEditClick,
    handleChangeEditOrderButtonClick,
    handleDragEnd,
    clearError,
    handleInputImageClick,
    handleSelectImage,
    handleCloseSelectImageModal,
    handleSearch,
    handleClearLine,
    register,
    handleTableRowClick,
    handleSortTable,
    handleAddLineClick,
    handleCloseMenageModal,
    handleButtonOkClick,
    handleSubmit,
    handleDeleteButtonClick,
    handleEdit,
    handleCloseConfirmModal,
    handleConfirmDelete,
    handleCancelSearch,
  };
};
