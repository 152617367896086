import { descriptionTypesApiCache } from '../../../constants/requestCacheName';
import { getAllDescriptionTypes } from '../../../services/descriptionTypes';
import Footer from '../../Footer';
import SerchInput from '../../SearchInput';
import Table from '../../Table';
import SelectModal from '../SelectModalNew';
import { useDescriptionSelectModal } from './useDescriptionSelectModal';
import CheckIcon from '@mui/icons-material/Check';

type DescriptionSelectModalProps = {
  isDescriptionTypesModalOpen: boolean;
  currentDescriptionTypeName?: string;
  handleCloseDescriptionTypes: () => void;
  onRowClick: (descriptionTypeId: number, descriptionTypeName: string) => void;
};

export default function DescriptionSelectModal({
  isDescriptionTypesModalOpen,
  currentDescriptionTypeName,
  handleCloseDescriptionTypes,
  onRowClick,
}: DescriptionSelectModalProps) {
  const {
    descriptionTypesLength,
    descriptionTypesShownLength,
    descriptionTypes,
    descriptionTypesShown,
    inputRef,
    isSearchInputDirty,
    sortField,
    handleSearch,
    handleCancelSearch,
    handleSortTable,
  } = useDescriptionSelectModal();

  return (
    <>
      <SelectModal
        title="Tipos de descrição"
        isModalOpen={isDescriptionTypesModalOpen}
        onModalClose={handleCloseDescriptionTypes}
        queryKey={descriptionTypesApiCache}
        service={getAllDescriptionTypes}
        headContent={
          <div>
            <SerchInput
              ref={inputRef}
              onSearch={handleSearch}
              onButtonClick={handleCancelSearch}
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
            />
            <Table.Head rowStyle="px-4">
              <Table.Cell
                items={descriptionTypesShown}
                type="name"
                onSort={handleSortTable}
                isSortActive={sortField === 'name'}
              >
                Tipos de descrição
              </Table.Cell>
            </Table.Head>
          </div>
        }
        footerContent={
          <Footer
            current={descriptionTypesShownLength}
            total={descriptionTypesLength}
            itemType="Categorias"
            className="static mb-[-1rem] ml-[-1rem] w-[calc(100%+2rem)] rounded-b-2xl"
          />
        }
      >
        {descriptionTypes && (
          <Table.Root
            itemType="Categorias"
            itemsLength={descriptionTypes.meta.totalItems}
            paddingTop="0"
          >
            {descriptionTypesShown.map((descriptionType) => (
              <Table.Row
                key={descriptionType.id}
                onClick={
                  currentDescriptionTypeName !== descriptionType.name
                    ? () => onRowClick(descriptionType.id, descriptionType.name)
                    : undefined
                }
                className={`items-center px-4 ${currentDescriptionTypeName === descriptionType.name ? 'cursor-default text-secondaryText opacity-50' : ''}`}
              >
                <Table.Cell items={descriptionTypes.items} type="name">
                  {descriptionType.name}
                </Table.Cell>
                {currentDescriptionTypeName === descriptionType.name && (
                  <CheckIcon className="text-primary" />
                )}
              </Table.Row>
            ))}
          </Table.Root>
        )}
      </SelectModal>
    </>
  );
}
