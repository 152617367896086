import { z } from 'zod';
import { ImageTypes } from '../enums/imageTypes';
import { regexEmail, regexCep, regexNumbersWithExtension } from './regex';
import { ContactStatus } from '../enums/contactStatus';

const commonFieldSchema = (requiredMessage: string) =>
  z.string({ required_error: requiredMessage }).trim().min(1, requiredMessage);

export const loginSchema = z.object({
  login: commonFieldSchema('O ID da Buffalo é obrigatório.'),

  password: commonFieldSchema('A senha é obrigatória.').min(
    8,
    'A senha precisa conter pelo menos 8 caracteres.',
  ),
});

export const recoverPasswordSchema = z
  .object({
    password: commonFieldSchema('A senha é obrigatória.').min(
      8,
      'A senha precisa conter pelo menos 8 caracteres.',
    ),
    confirm_password: commonFieldSchema(
      'A confirmação da senha é obrigatórioa.',
    ).min(8, 'A senha precisa conter pelo menos 8 caracteres.'),
  })
  .refine((data) => data.password === data.confirm_password, {
    message: 'As senhas não correspondem.',
    path: ['confirm_password'], // path ao campo que será sinalizado no erro
  });

export const emailSchema = z.object({
  email: commonFieldSchema('O email é obrigatório.'),
});

export const lineSchema = z.object({
  line: commonFieldSchema('A linha é obrigatória.'),
  carouselImage: z.string().optional(),
  menuImage: z.string().optional(),
});

export const productSchema = z.object({
  code: commonFieldSchema('O código é obrigatório.').refine(
    (value) => +value >= 50000,
    { message: 'O código precisa ser no mínimo 50000.' },
  ),
  name: commonFieldSchema('O produto é obrigatório.'),
  reviewedApproved: z.boolean().optional(),
  outOfLine: z.boolean().optional(),
  isNew: z.boolean().optional(),
});

export const categorySchema = z.object({
  category: commonFieldSchema('A categoria é obrigatória.'),
  line: commonFieldSchema('A categoria é obrigatória.').refine(
    (line) => line !== 'Nenhuma',
    'A linha é obrigatória.',
  ),
});

export const domainSchema = z.object({
  domain: commonFieldSchema('O domínio é obrigatório.'),
  imgHighlight: z.string().optional(), //POR ENQUANTO OPCIONAL
  street: commonFieldSchema('A rua é obrigatória.'),
  neighborhood: commonFieldSchema('O bairro é obrigatório.'),
  number: commonFieldSchema('O número é obrigatório.'),
  city: commonFieldSchema('A cidade é obrigatória.'),
  state: commonFieldSchema('O Estado é obrigatório.'),
  cep: commonFieldSchema('O CEP é obrigatório.').regex(
    regexCep,
    'CEP inválido.',
  ),
});

export const documentTypeSchema = z.object({
  documentType: commonFieldSchema('O tipo de documento é obrigatório.'),
});

export const imageTypeSchema = z.object({
  imageType: commonFieldSchema('O tipo de imagem é obrigatório.'),
  extension: commonFieldSchema('A extensão é obrigatória.'),
  maxSize: commonFieldSchema('O tamano máximo é obrigatório.'),
});

export const userSchema = z
  .object({
    user: commonFieldSchema('O usuário é obrigatório.'),
    email: commonFieldSchema('O email é obrigatório.'),
    password: commonFieldSchema('A senha é obrigatória.').min(
      8,
      'A senha precisa conter pelo menos 8 caracteres.',
    ),
    confirm_password: commonFieldSchema(
      'A confirmação da senha é obrigatórioa.',
    ).min(8, 'A senha precisa conter pelo menos 8 caracteres.'),
    userType: commonFieldSchema('O tipo de usuário é obrigatório.').refine(
      (line) => line !== 'Nenhuma',
      'O tipo de usuário é obrigatório.',
    ),
    isActive: z.boolean().optional(),
  })
  .refine((data) => data.password === data.confirm_password, {
    message: 'As senhas não correspondem.',
    path: ['confirm_password'], // path ao campo que será sinalizado no erro
  });

export const usersSchemaEdit = z
  .object({
    user: commonFieldSchema('O usuário é obrigatório.'),
    password: z.string().optional(), // Apenas opcional no esquema base
    confirm_password: z.string().optional(), // Apenas opcional no esquema base
    userType: commonFieldSchema('O tipo de usuário é obrigatório.').refine(
      (line) => line !== 'Nenhuma',
      'O tipo de usuário é obrigatório.',
    ),
    isActive: z.boolean().optional(),
  })
  .refine(
    (data) => {
      // Verifica se a senha é válida se estiver presente
      if (data.password && data.password.length < 8) {
        return false;
      }
      // Verifica se as senhas correspondem caso ambas sejam preenchidas
      if (data.password || data.confirm_password) {
        return data.password === data.confirm_password;
      }
      return true;
    },
    {
      message:
        'A senha precisa conter pelo menos 8 caracteres ou as senhas não correspondem.',
      path: ['password'], // Aponta o erro ao campo de senha
    },
  );

export const userTypesSchema = z.object({
  userType: commonFieldSchema('O tipo de usuário é obrigatório.'),
});

export const emailSignatureSchema = z.object({
  name: commonFieldSchema('O nome é obrigatório.'),
  department: commonFieldSchema('O departamento(português) é obrigatório.'),
  englishDepartament: z.string().nullable().optional(),
  phoneNumber: z
    .string()
    .refine((value) => value.length === 0 || value.length === 18, {
      message: 'Número de telefone inválido.',
    }),
  cellPhoneNumber: z
    .string()
    .refine((value) => value.length === 0 || value.length === 19, {
      message: 'Número de celular inválido.',
    }),
  email: commonFieldSchema('O email é obrigatório.').regex(
    regexEmail,
    'Email inválido.',
  ),
});

export const imageExtensionSchema = z.object({
  name: commonFieldSchema('O nome é obrigatório.'),
  extension: commonFieldSchema('A extensão é obrigatória.'),
  fileType: commonFieldSchema('O tipo de arquivo é obrigatório.'),
});

export const partSchema = z.object({
  code: commonFieldSchema('O código é obrigatório.')
    .refine((value) => +value < 50000, {
      message: 'O código precisa ser menor do que 50000.',
    })
    .refine((value) => +value > 0, {
      message: 'O código precisa ser maior do que 0.',
    }),
  name: commonFieldSchema('a peça é obrigatória.'),
  reviewedApproved: z.boolean().optional(),
  outOfLine: z.boolean().optional(),
  isNew: z.boolean().optional(),
});

export const descriptionTypesSchema = z.object({
  descriptionTypes: commonFieldSchema('O tipo da descrição é obrigatório.'),
});

export const descriptionSchema = z.object({
  description: commonFieldSchema('A descrição é obrigatória'),
  descriptionType: commonFieldSchema('O tipo da descrição é obrigatório.'),
});

export const partsGroupSchema = z.object({
  name: commonFieldSchema('O conjunto de peças é obrigatório.'),
  description: commonFieldSchema('A descrição é obrigatória.'),
  totalParts: z.string().optional(),
  image: commonFieldSchema('A imagem é obrigatória.'),
});

const StatusEnum = z.enum([
  ContactStatus.PENDENTE,
  ContactStatus.ARQUIVADO,
  ContactStatus.CONCLUIDO,
]);

export const contactSchema = z.object({
  empresa: commonFieldSchema('A empresa é obrigatória.'),
  mensagem: commonFieldSchema('A mensagem é obrigatória.'),
  status: StatusEnum,
  resposta: z.string().optional(),
  dtCriacao: commonFieldSchema('Aguardando data de criação.'),
  dtConclusao: commonFieldSchema('Aguardando data de conclusão').optional(),
  email: z.string(),
  nome: z.string(),
});

export const uploadImageSchema = z
  .object({
    type: commonFieldSchema('O tipo de imagem é obrigatório.'),
    reference: z.string().optional(),
    image: z.instanceof(FileList),
    // .refine((file) => file[0]?.type === 'image/svg+xml', {
    //   message: 'Somente arquivos SVG são permitidos.',
    // }),
  })
  .superRefine((data, ctx) => {
    if (data.image.length <= 1 && (data.reference?.trim().length ?? 0) <= 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['reference'],
        message: 'O nome ou código é obrigatório.',
      });
    }
  })
  .superRefine((data, ctx) => {
    if (
      (data.type === ImageTypes.parts || data.type === ImageTypes.products) &&
      !regexNumbersWithExtension.test(data.reference ?? '') &&
      data.image.length <= 1
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['reference'],
        message:
          'Deve conter apenas números para o tipo "Peças" ou "Produtos".',
      });
    }

    if (
      !(
        // Tipos isentos da validação
        (
          data.type === ImageTypes.linesMenu ||
          data.type === ImageTypes.imgHighlight
        )
      ) &&
      data.image[0]?.type !== 'image/svg+xml'
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['image'],
        message: 'Somente arquivos SVG são permitidos.',
      });
    }
  })
  .superRefine((data, ctx) => {
    if (
      (data.type === ImageTypes.linesMenu ||
        data.type === ImageTypes.imgHighlight) &&
      data.image[0]?.type !== 'image/svg+xml' &&
      data.image[0]?.type !== 'image/png' &&
      data.image[0]?.type !== 'image/jpg'
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['image'],
        message:
          'Somente arquivos SVG, PNG ou JPG são permitidos para os tipos "Linhas menu" e "Imagem de destaque".',
      });
    }

    if (
      !(
        // Tipos isentos da validação
        (
          data.type === ImageTypes.linesMenu ||
          data.type === ImageTypes.imgHighlight
        )
      ) &&
      data.image[0]?.type !== 'image/svg+xml'
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['image'],
        message: 'Somente arquivos SVG são permitidos.',
      });
    }
  });

export const uploadDocumentSchema = z
  .object({
    type: commonFieldSchema('O tipo de documento é obrigatório.'),
    reference: z.string().optional(),
    document: z
      .instanceof(FileList)
      .refine((file) => file[0]?.type === 'application/pdf', {
        message: 'Somente arquivos PDF são permitidos.',
      }),
  })
  .superRefine((data, ctx) => {
    if (
      data.document.length <= 1 &&
      (data.reference?.trim().length ?? 0) <= 0
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['reference'],
        message: 'O nome ou código é obrigatório.',
      });
    }
  });

export const questionsSchema = z.object({
  pergunta: commonFieldSchema('A pergunta é obrigatória.'),
  resposta: z.string().refine((arg) => arg !== undefined, {
    message: 'A resposta não pode estar vazia',
  }),
  status: z.boolean(),
  ordem: z.any({ required_error: 'A ordem é obriagatória' }),
});

export const sizingDocSchema = z
  .object({
    reference: z.string().optional(),
    sizing: z
      .instanceof(FileList)
      .refine((file) => file[0]?.type === 'application/pdf', {
        message: 'Somente arquivos PDF são permitidos.',
      }),
  })
  .superRefine((data, ctx) => {
    if (data.sizing.length <= 1 && (data.reference?.trim().length ?? 0) <= 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['reference'],
        message: 'O nome ou código é obrigatório.',
      });
    }
  });

export const newsTypeSchema = z.object({
  newsType: commonFieldSchema('O tipo de notícia é obrigatório.'),
});

export const uploadNewsSchema = z
  .object({
    type: commonFieldSchema('O tipo de notícia é obrigatória.'),
    reference: z.string().optional(),
    news: z
      .instanceof(FileList)
      .refine(
        (file) =>
          file[0]?.type === 'application/pdf' ||
          file[0]?.type === 'video/mp4' ||
          file[0]?.type === 'image/svg+xml',
        {
          message: 'Somente arquivos PDF, SVG ou vídeos em MP4 são permitidos.',
        },
      ),
    fileType: z.string(),
    date: z
      .string()
      .optional()
      .refine(
        (value) => {
          if (!value) return true;

          if (!value.includes('/')) {
            return new Date(value);
          }

          const [day, month, year] = value.split('/').map(Number);
          const isValidDate =
            day > 0 &&
            month > 0 &&
            month <= 12 &&
            year > 0 &&
            !isNaN(new Date(year, month - 1, day).getTime());
          return isValidDate;
        },
        { message: 'Data inválida.' },
      ),
    pdfBook: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.news.length <= 1 && (data.reference?.trim().length ?? 0) <= 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['reference'],
        message: 'O nome ou código é obrigatório.',
      });
    }
  });

export const popUpSchema = z.object({
  titleField: z.string().refine((arg) => arg !== undefined, {
    message: 'A título não pode estar vazio',
  }),
  contentField: z.string().refine((arg) => arg !== undefined, {
    message: 'A conteúdo não pode estar vazio',
  }),
  subtitleField: z.string().refine((arg) => arg !== undefined, {
    message: 'O subtítulo não pode estar vazio',
  }),
  footerField: z.string().refine((arg) => arg !== undefined, {
    message: 'O rodapé não pode estar vazio',
  }),
});

export const emailTriggerSchema = z.object({
  name: z.string().optional(),
  email: commonFieldSchema('O endereço de email é obrigatório.').email(
    'Email inválido.',
  ),
  password: commonFieldSchema('A senha é obrigatória.'),
});

export const updateEmailTriggerSchema = z.object({
  name: z.string().optional(),
  email: commonFieldSchema('O endereço de email é obrigatório.').email(
    'Email inválido.',
  ),
  password: z.string().optional(),
});

export const subjectSchema = z.object({
  name: commonFieldSchema('O assunto é obrigatório'),
});
