import { ComponentProps, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type MainProps = ComponentProps<'main'> & {
  isModal?: boolean;
  children: ReactNode;
};

export default function Main({
  isModal,
  children,
  className,
  ...rest
}: MainProps) {
  return (
    <main
      data-m={isModal}
      className={twMerge(
        'bg-backgroundPrimary px-8 pb-16 data-[m=true]:bg-backgroundPaper data-[m=true]:p-0 max-md:px-0',
        className,
      )}
      {...rest}
    >
      {children}
    </main>
  );
}
