import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';

export const tableStyle: SxProps<Theme> = {
  '@media(max-width: 760px)': {
    mb: '6rem',
  },
};

export const tableCellStyle: SxProps<Theme> = {
  px: '0.5rem',

  '@media(max-width: 500px)': {
    paddingLeft: '0.5rem',
  },
};

export const tableHeadStyle: SxProps<Theme> = {
  width: 'calc(100% - 4rem)',
  zIndex: 2,
  borderBottom: `1px solid ${grey['300']}`,
  '@media(max-width:760px)': {
    width: '100%',
  },
};

export const tableHeadCellStyle: SxProps<Theme> = {
  ...tableCellStyle,
  display: 'flex',
  padding: '0.25rem 0',
  border: 'none',
};

export const tableHeadContentStyle: SxProps<Theme> = {
  cursor: 'pointer',
};

export const tableHeadLastContentStyle: SxProps<Theme> = {
  position: 'absolute',
  right: 0,
  textAlign: 'right',

  '@media (max-width: 760px)': {
    paddingRight: '0.5rem',
  },
};
