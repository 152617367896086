import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSendMailRecoverPassword } from './useSendMailRecoverPassword';
import { useMemo } from 'react';
import Alert from '../../components/Alert';
import { ClipLoader } from 'react-spinners';

export default function SendMailRecoverPassword() {
  const {
    isLoading,
    errors,
    errorMessage,
    alertContent,
    clearError,
    handleSubmit,
    register,
    onSubmit,
    handleCloseAlert,
  } = useSendMailRecoverPassword({
    successIcon: useMemo(
      () => <CheckCircleIcon className="text-success" fontSize="large" />,
      [],
    ),
    errorIcon: useMemo(
      () => <CancelIcon className="text-errorText" fontSize="large" />,
      [],
    ),
  });

  return (
    <div className="h-screen w-screen">
      <div className="flex h-full w-full items-center justify-center p-8 pt-16">
        <div className="mx-auto w-1/3 max-xl:w-1/2 max-md:w-full">
          <h1 className="text-3xl">Problemas para entrar ?</h1>
          <h2 className='mt-2'>
            Digite o seu email e um link será enviado para você redefinir sua
            senha.
          </h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4 flex w-full items-center rounded-lg border border-solid border-zinc-300 pr-4 focus-within:outline focus-within:outline-2 focus-within:outline-sky-400">
              <input
                type={'text'}
                placeholder="Email"
                className="mr-2 w-full rounded-lg px-4 py-4 outline-none"
                {...register('email')}
              />
            </div>
            {errors.email && (
              <span className="text-xs text-red-600">
                {errors.email.message}
              </span>
            )}

            <button
              type="submit"
              className="mt-10 w-full rounded-lg bg-gradient-to-b from-blue-400 to-blue-600 h-[4rem] text-center text-zinc-50 hover:to-blue-500"
            >
              {isLoading ? <ClipLoader size={30} className='mt-1' /> : 'Enviar Email'}
            </button>
          </form>
        </div>
      </div>
      <Alert
        icon={alertContent.icon}
        content={alertContent.message}
        isOpen={!!alertContent.message}
        onClose={handleCloseAlert}
      />
      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </div>
  );
}
