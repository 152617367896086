export default function LinesCategoryIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
      <image
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAErSURBVDhPzZQxS8NQFIXzO4o4dC+oHbpkLvkDgjiGJCRQQsGhgqtjlyyCg3QKiotQSqFDaX+AKHTvb9HvPe9rXzUlwSrtgcO977xzT8J7Ic6/IAzDpyiKXoWeyKVgrmPN3YqsNxYIH8Ip6/sqxPtu5ljnErcZSH8TBEGzCvEPtgW24QResXkscinwN2AGc9gS+QsId8qgekLH9Mvv9H2/ps0W0C/xX8tyDTZWgVT7TG3+ePvDCkQ/oz4LdchOgdA1PZ6RePcQyDd2lCRJXVFp5ruL4/j0V4E20HITYvOgAlMGzM3a1CGFgQhdNt6oD5zVicilwO8xN6TOqRci66esbpU+o55XId4Xqpkr/tvAR9irQuZm1MLAPqI5G1fkUjCnzk/P0aci/yUc5xMglpc+CiQrhQAAAABJRU5ErkJggg=="
        height="100%"
        width="100%"
        style={{ filter: 'invert(1) brightness(2)' }}
      />
    </svg>
  );
}
