import ErrorsDuimpContent from '../../../components/ErrosDuimpContent';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import { useErrorsDuimp } from './useErrorsDuimp';

export default function ErrorsDuimp() {
  const {
    codigoProduto,
    isDuimp,
    caracteristicas,
    ncm,
    errors,
    isManageModalOpen,
    handleCloseManageModal,
    register,
    handleTableRowClick,
  } = useErrorsDuimp();

  return (
    <>
      <ErrorsDuimpContent onTableRowClick={handleTableRowClick} />

      <Modal
        isOpen={isManageModalOpen}
        onClose={handleCloseManageModal}
        cancelIcon={false}
        height="85vh"
        contentStyle="overflow-y-auto pr-1 pt-1"
      >
        <HeaderModalManageItem
          title={'Erro'}
          mainButtonContent={''}
          onCancelClick={handleCloseManageModal}
          isEditable={false}
        />

        <div className="my-12 flex flex-col gap-4">
          <Input
            type="text"
            placeholder="Código do produto"
            disabled={true}
            {...register('codigoProduto')}
            value={codigoProduto}
          />
          <Input
            type="text"
            placeholder="NCM"
            disabled={true}
            {...register('ncm')}
            value={ncm}
          />

          <div>
            <label className="text-sm text-zinc-400">Características</label>
            <textarea
              placeholder="Características"
              readOnly
              {...register('caracteristicas')}
              value={caracteristicas}
              style={{
                width: '100%',
                height: 'auto',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                resize: 'none',
              }}
            />
          </div>

          <Input
            type="text"
            placeholder="Cadastrado no Duimp"
            disabled={true}
            {...register('isDuimp')}
            value={isDuimp}
          />

          <div>
            <label className="text-sm text-zinc-400">Erros</label>
            <textarea
              placeholder="Erros"
              readOnly
              {...register('errors')}
              value={errors}
              style={{
                width: '100%',
                height: 'auto',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                resize: 'none',
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
