import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import Footer from '../../../components/Footer';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import ListingHeader from '../../../components/ListingHeader';
import Loading from '../../../components/Loading';
import Main from '../../../components/Main';
import Modal from '../../../components/Modal';
import SerchInput from '../../../components/SearchInput';
import Table from '../../../components/Table';
import { useDescriptionTypes } from './useDescriptionTypes';

export default function DescriptionTypes() {
  const {
    descriptionTypes,
    descriptionTypesShown,
    inputRef,
    descriptionType,
    isFetching,
    isSearchInputDirty,
    sortField,
    isModalOpen,
    dirtyFields,
    errors,
    currentDescriptionTypesId,
    isConfirmModalOpen,
    isShowEditButton,
    errorMessage,
    clearError,
    handleSearch,
    handleButtonOkClick,
    handleEdit,
    handleCloseConfirmModal,
    handleDeleteButtonClick,
    handleConfirmDelete,
    handleSubmit,
    register,
    handleClearLine,
    handleSortTable,
    handleTableRowClick,
    handleCloseMenageModal,
    handleCancelSearch,
    handleAddDescriptionTypesClick,
  } = useDescriptionTypes();

  return (
    <>
      <ListingHeader
        add
        onAddIconClick={handleAddDescriptionTypesClick}
        pdfTop="3rem"
        pdf={false}
      >
        <div className="mt-4">
          <h1 className="mb-4 text-2xl">Tipos de descrição</h1>
          <SerchInput
            ref={inputRef}
            onSearch={handleSearch}
            onButtonClick={handleCancelSearch}
            showCancelOptions={isSearchInputDirty}
            onCancelSearch={handleCancelSearch}
          />
        </div>
        <Table.Head>
          <Table.Cell
            items={descriptionTypesShown}
            type="name"
            onSort={handleSortTable}
            isSortActive={sortField === 'name'}
          >
            Nome
          </Table.Cell>
        </Table.Head>
      </ListingHeader>
      <Main>
        {isFetching && <Loading />}
        {!isFetching && descriptionTypes && (
          <Table.Root
            itemType="Tipos de descrição"
            itemsLength={descriptionTypes.meta.totalItems}
          >
            {descriptionTypesShown.map((descriptionType) => (
              <Table.Row
                key={descriptionType.id}
                onClick={() =>
                  handleTableRowClick(descriptionType.id, descriptionType.name)
                }
              >
                <Table.Cell items={descriptionTypes.items} type="name">
                  {descriptionType.name}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Root>
        )}
        <Footer
          current={descriptionTypesShown.length}
          total={descriptionTypes?.meta.totalItems ?? 0}
          itemType="Tipos de descrição"
        />

        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseMenageModal}
          cancelIcon={false}
        >
          <HeaderModalManageItem
            showDeleteButton={!!currentDescriptionTypesId}
            deleteButtonContent="Apagar tipo de descrição"
            onDeleteButtonClick={handleDeleteButtonClick}
            isEditable={!isShowEditButton}
            title={
              currentDescriptionTypesId
                ? 'Tipo de descrição'
                : 'Novo tipo de descrição'
            }
            mainButtonContent={isShowEditButton ? 'Editar' : 'Ok'}
            onCancelClick={handleCloseMenageModal}
            onOkClick={
              !isShowEditButton ? handleSubmit(handleButtonOkClick) : undefined
            }
            onEditClick={isShowEditButton ? handleEdit : undefined}
          />
          <div className="mb-52 mr-24 mt-24 w-full">
            <Input
            maxLength={55}
              type="text"
              placeholder="Tipo de descrição"
              disabled={isShowEditButton}
              helperText={errors.descriptionTypes?.message}
              isDirty={dirtyFields.descriptionTypes}
              onCancelIconClick={handleClearLine}
              value={descriptionType}
              {...register('descriptionTypes')}
            />
          </div>
        </Modal>
        <ConfirmModal
          contentMessage="Deseja excluir este tipo de descrição?"
          isModalOpen={isConfirmModalOpen}
          onCloseModal={handleCloseConfirmModal}
          onConfirm={handleConfirmDelete}
          onNotConfirm={handleCloseConfirmModal}
        />
      </Main>

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
