import Footer from '../../components/Footer';
import Header from '../../components/Header';

import BackgroundContato from '../../assets/images/backgrounds/BackgroundContato2x.png';
import Alert from '../../../components/Alert';
import { useContactUs } from './useContactUs';
import { StateUF } from '../../../types/stateUF';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useMemo } from 'react';
import { formatCPF, formatPhoneNumber } from '../../../utils/format';

export default function ContactUs() {
  const {
    alertContent,
    errors,
    subjects,
    cities,
    selectedState,
    states,
    selectedSubjects,
    handleSelect,
    handleSendContact,
    setValue,
    register,
    handleCityChange,
    handleCloseAlert,
    handleStateChange,
  } = useContactUs({
    successIcon: useMemo(
      () => <CheckCircleIcon className="text-success" fontSize="large" />,
      [],
    ),
    errorIcon: useMemo(
      () => <CancelIcon className="text-errorText" fontSize="large" />,
      [],
    ),
  });

  return (
    <>
      <Header />
      <div className="adjust-content-bf flex flex-col">
        <main className="flex-1">
          <img src={BackgroundContato} alt="" className="w-full" />
          <div className="mb-10 mt-5">
            <form className="mx-5" onSubmit={handleSendContact}>
              <div className="flex flex-col gap-4 pb-6">
                <div className="pb-4 text-2xl font-medium">
                  Entre em contato
                  <span className="text-4xl text-yellow-500">.</span>
                </div>
                <div className="text-base text-zinc-400">
                  Envie sua mensagem para tirar dúvidas ou fazer sugestões.
                  Estamos prontos para lhe atender.
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 pb-6 md:grid-cols-3">
                <div className="flex flex-col">
                  <p className="mb-2 text-sm font-semibold">Nome*</p>
                  <input
                    type="text"
                    {...register('name')}
                    // name="nome"
                    // value={formValues.nome}
                    // onChange={handleChange}
                    maxLength={50}
                    className="cursor-pointer rounded border-2 p-2 font-bold tracking-widest text-black"
                  />
                  <p className="mt-1 text-xs text-errorText">
                    {errors.name?.message}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="mb-2 text-sm font-semibold">Email*</p>
                  <input
                    type="email"
                    maxLength={100}
                    {...register('email')}
                    // name="email"
                    // value={formValues.email}
                    // onChange={handleChange}
                    className="w-full cursor-pointer rounded border-2 p-2 font-bold tracking-widest text-black"
                  />
                  <p className="mt-1 text-xs text-errorText">
                    {errors.email?.message}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="mb-2 text-sm font-semibold">Telefone</p>
                  <input
                    type="text"
                    {...register('phone', {
                      onChange: (event) =>
                        setValue(
                          'phone',
                          formatPhoneNumber(event.target.value),
                          {
                            shouldValidate: false,
                          },
                        ),
                    })}
                    // name="telefone"
                    // value={formValues.telefone}
                    // onChange={handleChange}
                    maxLength={15}
                    className="cursor-pointer rounded border-2 p-2 font-bold tracking-widest text-black"
                  />
                  <p className="text-xs text-errorText">
                    {errors.phone?.message}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="mb-2 text-sm font-semibold">CPF</p>
                  <input
                    type="text"
                    maxLength={14}
                    {...register('cpf', {
                      onChange: (event) => {
                        setValue('cpf', formatCPF(event.target.value), {
                          shouldValidate: false,
                        });
                      },
                    })}
                    // name="cpf"
                    // value={formValues.cpf}
                    // onChange={handleChange}
                    className="cursor-pointer rounded border-2 p-2 font-bold tracking-widest text-black"
                  />
                  <p className="text-xs text-errorText">
                    {errors.cpf?.message}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="mb-2 text-sm font-semibold">Empresa</p>
                  <input
                    type="text"
                    {...register('company')}
                    // name="empresa"
                    // value={formValues.empresa}
                    // onChange={handleChange}
                    maxLength={50}
                    className="cursor-pointer rounded border-2 p-2 font-bold tracking-widest text-black"
                  />
                  {/* {errors.empresa && (
                    <p className="text-xs text-errorText">{errors.empresa}</p>
                  )} */}
                </div>
                <div className="flex flex-col gap-5 md:flex-row">
                  <div className="flex w-full flex-col md:w-1/2">
                    <p className="mb-2 text-sm font-semibold">Estado</p>

                    <select
                      {...register('state', { onChange: handleStateChange })}
                      // onChange={handleStateChange}
                      // value={formValues.stateUF?.id}
                      className="cursor-pointer rounded border-2 p-2 font-bold text-black"
                    >
                      <option value="">Selecione um Estado</option>
                      {states &&
                        states.map((state: StateUF) => (
                          <option key={state.id} value={state.id}>
                            {state.nome}
                          </option>
                        ))}
                    </select>
                    <p className="text-xs text-errorText">
                      {errors.state?.message}
                    </p>
                  </div>
                  <div className="flex w-full flex-col md:w-1/2">
                    <p className="mb-2 text-sm font-semibold">Cidade</p>
                    <select
                      {...register('city', { onChange: handleCityChange })}
                      // onChange={handleCityChange}
                      disabled={!selectedState}
                      // value={formValues.cidade?.id}
                      className="cursor-pointer rounded border-2 p-2 font-bold text-black"
                    >
                      <option value="">Selecione uma cidade</option>
                      {cities &&
                        cities.map((city: any) => (
                          <option key={city.id} value={city.id}>
                            {city.nome}
                          </option>
                        ))}
                    </select>
                    <p className="text-xs text-errorText">
                      {errors.city?.message}
                    </p>
                  </div>
                </div>
              </div>
              <p className="mb-2 text-sm font-semibold">Assuntos*</p>
              {subjects?.items && (
                <>
                  <div className="flex gap-3">
                    {subjects.items.map((subject) => (
                      <div
                        key={`${subject.id}${subject.name}`}
                        onClick={() => handleSelect(subject)}
                        className={`inline-block w-fit cursor-pointer rounded-lg border-2 p-2 transition ${
                          selectedSubjects.some((s) => s.id === subject.id)
                            ? 'border-yellow-500 bg-yellow-200'
                            : 'border-zinc-400 bg-zinc-100 hover:text-yellow-600 hover:border-yellow-500'
                        }`}
                      >
                        <span>{subject.name}</span>
                      </div>
                    ))}
                  </div>
                  <p className="text-xs text-errorText">
                    {errors.subject?.message}
                  </p>
                </>
              )}

              <div className="flex flex-col py-6">
                <p className="mb-2 text-sm font-semibold">Mensagem*</p>
                <textarea
                  {...register('message')}
                  // name="mensagem"
                  // value={formValues.mensagem}
                  // onChange={handleChange}
                  maxLength={3000}
                  className="w-full cursor-pointer rounded border-2 p-2 font-bold tracking-widest text-black"
                ></textarea>
                <p className="text-xs text-errorText">
                  {errors.message?.message}
                </p>
              </div>
              <button
                type="submit"
                className="flex w-full items-center justify-center rounded-lg border-2 border-yellow-400 bg-yellow-400 p-2 text-base font-semibold text-zinc-800 md:w-1/5"
              >
                Enviar
              </button>
            </form>
          </div>
        </main>
        <Footer />
      </div>
      <Alert
        icon={alertContent.icon}
        content={alertContent.message}
        isOpen={!!alertContent.message}
        onClose={handleCloseAlert}
      />
    </>
  );
}
