import { useQuery } from 'react-query';
import { Domain } from '../../../types/domain';
import { domainCache } from '../../../constants/requestCacheName';
import { getDomain } from '../../../services/domain';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useHighlightBuffalo = () => {
  const { data: domainShown, isFetching: isDomainFetching } = useQuery<Domain>(
    domainCache,
    async () => (await getDomain()).data,
  );

  const navigate = useNavigate()

  const [isImageLoading, setIsImageLoading] = useState(true);

  const handleImageWasLoaded = () => {
    setIsImageLoading(false);
  };

  const handleLoadStart = () => {
    setIsImageLoading(true);
  };

  const handleRedirectImage = () => {
    if (domainShown?.product) {
      const productId = domainShown.product.id;
      const productName = encodeURIComponent(domainShown.product.name);
      const categoryId = domainShown?.product?.productLineCategoryDomain?.[0]?.category?.id;
      const lineId = domainShown?.product?.productLineCategoryDomain?.[0]?.line.id;

      navigate(`/produto/${productId}/${productName}/categoria/${categoryId}/linha/${lineId}`)
  }
}

  return {
    isImageLoading,
    handleRedirectImage,
    domainShown,
    handleImageWasLoaded,
    handleLoadStart,
    isDomainFetching,
  };
};
