import { Part } from '../../types/parts';
import { useState } from 'react';
import { InitialValue } from '../../components/Admin/ItemModal';
import { useModal } from '../../hooks/useModal';
import { useTitle } from '../../hooks/useTitle';

export const useParts = () => {
  useTitle('Peças');



  const [partClicked, setPartClicked] = useState<Part | null>(null);
  const [isPartDescriptionOpen, setIsPartDescriptionOpen] = useState(false);
  const [partFieldsCurrentValue, setPartFieldsCurrentValue] =
    useState<InitialValue>({
      code: '',
      name: '',
      isNew: false,
      outOfLine: false,
      reviewedApproved: false,
    });

  const {
    isModalOpen: isPartModalOpen,
    handleCloseModal: handleClosePartModal,
    handleOpenModal: handleOpenPartModal,
  } = useModal();

  const handleTableRowClick = (part: Part) => {
    setPartClicked(part);
  };

  const handleCloseModal = () => {
    setPartClicked(null);
  };

  const handleOpenDescription = () => {
    setIsPartDescriptionOpen(true);
  };

  const handleCloseDescription = () => {
    setIsPartDescriptionOpen(false);
  };

  const handleAddIconClick = () => {
    setPartFieldsCurrentValue({
      code: '',
      name: '',
      isNew: false,
      outOfLine: false,
      reviewedApproved: false,
    });
    handleOpenPartModal();
  };

  const handleTablePartEditClick = (
    partId: string,
    partName: string,
    isNew: boolean,
    outOfLine: boolean,
    reviewedApproved: boolean,
  ) => {
    handleOpenPartModal();
    setPartFieldsCurrentValue({
      code: partId,
      name: partName,
      isNew,
      outOfLine,
      reviewedApproved,
    });
  };

  return {
    partClicked,
    isPartDescriptionOpen,
    partFieldsCurrentValue,
    isPartModalOpen,
    handleAddIconClick,
    handleTablePartEditClick,
    handleClosePartModal,
    handleCloseModal,
    handleTableRowClick,
    handleOpenDescription,
    handleCloseDescription,
  };
};
