import { create } from 'zustand';
import { Image, ImagesResult } from '../../types/images';
import { getImageSizes } from '../../services/imageStorage';

type ImagesSelectFilteredProps = {
  state: {
    images: Image[];
    totalItems: number;
    isFetching: boolean;
  };
  actions: {
    addImage: (imageName: string, imageType: string) => void;
    removeImage: (imageName: string) => void;
    filterImages: (
      images: ImagesResult,
      currentPage: number,
      showDimensions?: boolean,
    ) => void;
    loadMoreImages: (
      totalImage: ImagesResult,
      currentPage: number,
      showDimensions?: boolean,
    ) => void;
    setFetch: (value: boolean) => void;
    setImages: (images: Image[]) => void;
    setTotalItems: (totalItems: number) => void;
    clear: () => void;
  };
};

export const useImagesSelectFiltered = create<ImagesSelectFilteredProps>(
  (set) => {
    const initialState = {
      images: [],
      totalItems: 0,
      isFetching: false,
    };

    return {
      state: initialState,
      actions: {
        filterImages: async (images, currentPage, showDimensions = true) => {
          const itemsPerPage = 100;
          const end = currentPage * itemsPerPage;
          const start = end - itemsPerPage;

          let filteredImages = images.items.slice(start, end);

          if (showDimensions) {
            const filteredImagesSized: Image[] = (
              await Promise.all(
                filteredImages.map((image) => {
                  return getImageSizes(image.name, image.type);
                }),
              )
            ).map((imageSize, index) => ({
              ...filteredImages[index],
              ...imageSize,
            }));
            filteredImages = filteredImagesSized;
          }

          set((state) => {
            return {
              state: {
                ...state.state,
                images: filteredImages,
                totalItems: images.meta.totalItems,
              },
            };
          });
        },

        setTotalItems: (totalItems) => {
          set((state) => ({
            state: {
              ...state.state,
              totalItems,
            },
          }));
        },

        loadMoreImages: async (
          totalImages,
          currentPage,
          showDimensions = true,
        ) => {
          const itemsPerPage = 100;
          const end = currentPage * itemsPerPage;

          let filteredImages = totalImages.items.slice(0, end);

          if (showDimensions) {
            const filteredImagesSized: Image[] = (
              await Promise.all(
                filteredImages.map((image) => {
                  return getImageSizes(image.name, image.type);
                }),
              )
            ).map((imageSize, index) => ({
              ...filteredImages[index],
              ...imageSize,
            }));
            filteredImages = filteredImagesSized;
          }

          set((state) => {
            return {
              state: {
                ...state.state,
                images: filteredImages,
                totalItems: totalImages.meta.totalItems,
              },
            };
          });
        },

        addImage: async (imageName, imageType) => {
          const { height, width } = await getImageSizes(imageName, imageType);
          set((state) => ({
            state: {
              ...state.state,
              images: [
                { name: imageName, type: imageType, height, width },
                ...state.state.images,
              ],
            },
          }));
        },

        removeImage: (imageName) => {
          set((state) => ({
            state: {
              ...state.state,
              totalItems: state.state.totalItems - 1,
              images: state.state.images.filter(
                (image) => image.name !== imageName,
              ),
            },
          }));
        },

        setFetch: (value) => {
          set((state) => ({
            ...state,
            state: {
              ...state.state,
              isFetching: value,
            },
          }));
        },

        setImages: (images) => {
          set((state) => ({
            ...state,
            state: {
              ...state.state,
              images: images,
            },
          }));
        },

        clear: () => {
          set((state) => ({
            ...state,
            state: initialState,
          }));
        },
      },
    };
  },
);
