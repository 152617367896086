import { Box, SxProps, Theme, Typography } from '@mui/material';
import { backButtonStyle, pdfText } from './styles';
import { ComponentProps, ReactNode } from 'react';
import BackButton from '../BackButton';
import { verifyScreenIsLessThan } from '../../utils/verifyScreenIsLessThan';
import { twMerge } from 'tailwind-merge';
import { DownloadIcon } from 'lucide-react';

export type ListingHeaderProps = ComponentProps<'header'> & {
  children?: ReactNode;
  backButton?: boolean;
  backButtonSx?: SxProps<Theme>;
  pdfSx?: SxProps<Theme>;
  showBackground?: boolean;
  onPdfClick?: () => void;
  onAddIconClick?: () => void;
  pdf?: boolean;
  pdfTop?: string;
  add?: boolean;
  returnPartsCatalog?: () => void;
  partsCatalog?: boolean;
};

export default function ListingHeader({
  partsCatalog = false,
  returnPartsCatalog,
  children,
  backButton = true,
  showBackground = true,
  // sx,
  backButtonSx,
  pdfSx,
  onPdfClick,
  pdfTop = '1.25rem',
  pdf = true,
  onAddIconClick,
  add = false,
  className,
  ...rest
}: ListingHeaderProps) {
  const isMobile = verifyScreenIsLessThan(760);
  return (
    <>
      {showBackground && (
        <div className="absolute -z-30 h-screen w-screen bg-backgroundPrimary" />
      )}
      <header
        className={twMerge(
          'sticky left-0 right-0 top-0 z-20 bg-backgroundPrimary px-8 pt-4 max-md:p-0',
          className,
        )}
        {...rest}
      >
        <div className="flex">
          <div>
            {backButton && (
              <BackButton
                sx={{ ...backButtonStyle, ...backButtonSx } as SxProps<Theme>}
              />
            )}

            {add && !isMobile && (
              <img
                src="/icons/plus.png"
                alt="Ícone para adicionar linha"
                onClick={onAddIconClick}
                className="absolute right-6 top-1 w-12 cursor-pointer text-primary"
              />
            )}
          </div>

          {/* <span className="absolute -z-10 w-full text-center text-primary max-md:mt-[0.9rem]">
            {auth?.user.role?.type}
          </span> */}
        </div>

        {pdf && (
          <Typography
            color="#42a0dd"
            onClick={onPdfClick}
            sx={{ ...pdfText, mt: pdfTop, ...pdfSx } as SxProps<Theme>}
          >
            <DownloadIcon className="text-blue-500"/>
          </Typography>
        )}
        <Box>{children}</Box>
      </header>
    </>
  );
}
