import { useQuery } from 'react-query';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { Line, Lines } from '../../../types/lines';
import { getAllLines } from '../../../services/lines';
import { linesApiCache } from '../../../constants/requestCacheName';
import { useEffect } from 'react';

export const useSelectModal = (isModalOpen: boolean) => {
  const {
    data: lines,
    isFetching: isLineFetching,
    refetch: refetchLines,
  } = useQuery<Lines>(linesApiCache, async () => (await getAllLines()).data, {
    enabled: false,
    // retry: 1,
  });

  const {
    itemsShown: itemsLinesShown,
    isSearchInputDirty: isLineSearchInputDirty,
    handleSearch: handleSearchLines,
    inputRef: lineInputRef,
    handleCancelSearch: handleCancelLineSearch,
    sortField: sortFieldLines,
    handleSortTable: handleSortLineTable,
  } = useHandleTable(lines?.meta.totalItems ?? 0, lines?.items ?? []);
  const linesShown: Line[] = itemsLinesShown;

  useEffect(() => {
    refetchLines();
  }, [isModalOpen, refetchLines]);

  return {
    isLineSearchInputDirty,
    handleSearchLines,
    lineInputRef,
    handleCancelLineSearch,
    sortFieldLines,
    handleSortLineTable,
    isLineFetching,
    linesShown,
    lines,
  };
};
