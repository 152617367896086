import { SelectChangeEvent } from '@mui/material';
import { useState, useEffect } from 'react';
import { verifyScreenIsLessThan } from '../../../utils/verifyScreenIsLessThan';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { useProductsBuffalo } from '../../pages/Produtos/useProductsBuffalo';

export const useLinesNavbar = () => {
  const { lines } = useProductsBuffalo();
  const isResponsive = verifyScreenIsLessThan(900);

  const [selectedLine, setSelectedLine] = useState('');
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const [currentLineId] = useSearchParams('line');
  const isMobile = verifyScreenIsLessThan(900);

  const handleSelectPartGroup = (event: SelectChangeEvent) => {
    setSelectedLine(event.target.value);
  };

  useEffect(() => {
    if (lines && lines.length > 0) {
      setSelectedLine(lines[0].name);
    }
  }, [lines]);

  const handleMenuClick = () => {
    setIsNavbarOpen((prev) => !prev);
  };

  const handleClickOutOfSideNavbar = () => {
    setIsNavbarOpen(false);
  };

  const lineId = currentLineId ? +currentLineId : null;

  return {
    lines,
    isMobile,
    selectedLine,
    lineId,
    isNavbarOpen,
    isResponsive,
    handleClickOutOfSideNavbar,
    handleSelectPartGroup,
    handleMenuClick,
  };
};
