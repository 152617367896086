export const verifyOpenPdfPermission = (
  onError: (errorMessage: string) => void,
) => {
  const isOpen = window.open(URL.createObjectURL(new Blob()), '_blank');
  if (!isOpen) {
    onError(
      'Falha ao gerar PDF. Desative a opção Bloquear Pop-ups e tente novamente',
    );
  }
  if (isOpen) {
    isOpen.close();
  }
};
