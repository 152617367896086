import { useEffect, useState } from 'react';
import generateBarCodePdf from '../../pdf/barCode/generateBarCodePdf';
import { useError } from '../../hooks/useError';
import { ItemSelected, LabelSizeTypes } from '../../types/productBarCode';
import { useSearchParams } from '../../hooks/useSearchParams';
import generateBarCodeApplePdf from '../../pdf/barCodeApple/generateBarCodeApplePdf';

export const useBarCodeSelecteds = (
  blankLabelsAmount: number,
  itemsSelected: ItemSelected[],
  firstCheckedIndex: number,
) => {
  const [labelSizeParam] = useSearchParams('labelSize') as [LabelSizeTypes];
  const labelSize = labelSizeParam || 'p';

  const [isGenerateLabelDisabled, setIsGenerateLabelDisabled] = useState(true);
  const [isLabelsGenerationLoading, setIsLabelsGenerationLoading] =
    useState(false);

  useEffect(() => {
    if (itemsSelected.length > 0) {
      setIsGenerateLabelDisabled(false);
      return;
    }

    setIsGenerateLabelDisabled(true);
  }, [itemsSelected.length]);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const isBrowserSafari = () => {
    const { userAgent } = navigator;
    return userAgent.includes('Safari') && !userAgent.includes('Chrome');
  };

  const handleGeneratePdf = async () => {
    setIsLabelsGenerationLoading(true);

    if (isBrowserSafari()) {
      await generateBarCodeApplePdf(
        itemsSelected,
        labelSize,
        firstCheckedIndex,
        setErrorMessage,
      );

      setIsLabelsGenerationLoading(false);
      return;
    }

    await generateBarCodePdf(
      itemsSelected,
      labelSize,
      firstCheckedIndex,
      setErrorMessage,
    );
    setIsLabelsGenerationLoading(false);
  };

  return {
    errorMessage,
    isGenerateLabelDisabled,
    isLabelsGenerationLoading,
    handleGeneratePdf,
    clearError,
  };
};
