import Footer from '../../components/Footer';
import Header from '../../components/Header';
import SvgMapaBrasil from '../../components/SvgComponent/SvgMapaBrasil';
import { ClipLoader } from 'react-spinners';

import BackgroundAssistenciaTec from '../../assets/images/backgrounds/BackgroundAssistenciaTecnica2x.png';
import QuestionMarkIcon from '../../assets/images/icons/QuestionMarkIcon.png';
import { useTechnicalAssistance } from './useTechnicalAssistance';
import { Link } from 'react-router-dom';
import { statesMap } from '../../constants/statesUf';
import { formatCep, formatPhoneNumber } from '../../../utils/format';
import SearchIcon from '@mui/icons-material/Search';

export default function TechnicalAssistance() {
  const {
    cities,
    selectedCity,
    selectedUf,
    loading,
    states,
    technicalAssistances,
    searchValue,
    profiles,
    selectedProfile,
    handleChangeProfile,
    handleSearchInputChange,
    handleEnterClick,
    handleChangeCity,
    ref,
    handleChangeState,
    handleSubmit,
  } = useTechnicalAssistance();

  return (
    <>
      <Header />
      <div className="adjust-content-bf">
        <main>
          <img
            src={BackgroundAssistenciaTec}
            alt=""
            className="w-full"
            width={1000}
            height={150}
          />
          <div className="mb-10 mt-5">
            <div className="mx-4 md:mx-5">
              <div className="flex flex-col gap-4 pb-10 md:flex-row">
                <form
                  className="flex w-full flex-col gap-4 2xl:w-2/3"
                  onSubmit={handleSubmit}
                >
                  <div className="text-base text-zinc-400">
                    A Buffalo Motores possui assistências técnicas em todo o
                    Brasil. Consulte abaixo na região desejada todas as
                    assistências da Buffalo.
                  </div>
                  <Link
                    to="/faq"
                    className="flex w-full items-center justify-center gap-2 rounded-lg border-2 border-yellow-400 p-2 text-base font-semibold text-zinc-600 transition-colors hover:bg-zinc-100 lg:w-2/5 2xl:w-1/5"
                  >
                    <img src={QuestionMarkIcon} alt="" />
                    Perguntas Frequentes
                  </Link>
                  <div>
                    <p className="mb-2 font-semibold">Pesquisar</p>
                    <div className="relative flex w-full">
                      <button className="absolute top-0 z-10 h-12 rounded-l-lg px-2.5 text-zinc-50">
                        <SearchIcon className="text-zinc-400" />
                      </button>
                      <input
                        type="text"
                        placeholder="Empresa, Código, Cidade, Estado"
                        name="busca"
                        onKeyDown={handleEnterClick}
                        onChange={(event) =>
                          handleSearchInputChange(event.target.value)
                        }
                        value={searchValue}
                        className="w-full cursor-pointer rounded-l-lg border-2 border-r-0 pl-9 text-xs font-bold uppercase tracking-widest text-black"
                      />
                      <Link
                        to={{
                          pathname: '/assistencia',
                          search: searchValue ? `search=${searchValue}` : '',
                        }}
                        className="rounded-r-lg bg-yellow-500 p-3 font-medium tracking-wide text-black"
                      >
                        Procurar
                      </Link>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="flex flex-col gap-2">
                      <p className="font-semibold">Estado</p>
                      <select
                        disabled={loading}
                        className="w-full cursor-pointer rounded-lg border-2 p-2 font-bold uppercase tracking-widest text-black"
                        value={selectedUf}
                        onChange={(event) =>
                          handleChangeState(event.target.value || null)
                        }
                      >
                        <option value="">Todos os estados</option>
                        {states?.items.map((state) => (
                          <option key={state.name} value={state.name}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="font-semibold">Cidade</p>
                      <select
                        disabled={loading}
                        className="w-full cursor-pointer rounded-lg border-2 p-2 font-bold uppercase tracking-widest text-black"
                        value={selectedCity}
                        onChange={handleChangeCity}
                      >
                        <option value="">Todas as cidades</option>
                        {cities?.items.map((city) => (
                          <option key={city.name} value={city.name}>
                            {city.name.toLowerCase()}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="flex flex-col gap-2">
                      <p className="font-semibold">Produto</p>
                      <select
                        disabled={loading}
                        className="w-full cursor-pointer rounded-lg border-2 p-2 font-bold uppercase tracking-widest text-black"
                        value={selectedProfile}
                        onChange={(event) =>
                          handleChangeProfile(event.target.value || null)
                        }
                      >
                        <option value="">Todos os produtos</option>
                        {profiles?.items.map((profile) => (
                          <option
                            key={profile.description}
                            value={profile.description}
                          >
                            {profile.description}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* <button
                    type="submit"
                    className="delay-50 flex w-full items-center justify-center rounded-lg bg-yellow-500 p-2 text-xl font-bold text-zinc-800 transition-all ease-linear hover:bg-black hover:text-white lg:w-1/4 xl:w-1/5"
                  >
                    Pesquisar
                  </button> */}
                </form>
                <div className="mt-4 hidden w-full justify-center md:mt-0 md:flex md:justify-end 2xl:w-1/3">
                  <SvgMapaBrasil
                    onEstadoClick={(uf: string) =>
                      handleChangeState(statesMap[uf])
                    }
                    className="w-full md:w-auto"
                    selectedEstado={selectedUf}
                    loading={loading}
                  />
                </div>
              </div>
              <ul className="grid grid-cols-1 gap-12  md:grid-cols-2">
                {technicalAssistances?.map((assistance) => (
                  <div
                    className="rounded-xl border border-gray-300 p-5"
                    key={assistance.parcCode}
                  >
                    <div className="flex items-start justify-between">
                      <div>
                        <h1 className="text-lg font-bold">
                          {
                            assistance.technicalAssistanceContacts[0]
                              .nameSiteAssistant
                          }
                        </h1>
                        <h3 className="font-medium text-slate-400">
                          {assistance.parcCode}
                        </h3>
                      </div>
                      <div className="flex flex-col items-end justify-center">
                        <span className="flex-nowrap text-xs font-bold">
                          {assistance.technicalAssistanceContacts[0].city}
                        </span>
                        <span className="text-sm font-medium text-slate-400">
                          {assistance.technicalAssistanceContacts[0].uf}
                        </span>
                      </div>
                    </div>

                    {assistance.technicalAssistanceContacts.map((contact) => (
                      <li className="mt-2 w-full" key={contact.id}>
                        <div className="">
                          {/* {contact.nickName && (
                            <div className="flex text-zinc-700">
                              Falar com:
                              <p className="ml-2 uppercase text-zinc-400">
                                {contact.nickName}
                              </p>
                            </div>
                          )} */}
                          <div className="flex flex-col text-sm">
                            <span>Endereço</span>
                            <p className="font-semibold">
                              {contact.addressType
                                ? `${contact.addressType} `
                                : ''}
                              {contact.address}

                              {contact.addressNumber
                                ? ` ${contact.addressNumber}`
                                : ''}

                              {contact.complement
                                ? `, ${contact.complement}`
                                : ''}

                              {contact.neighborhood
                                ? ` - ${contact.neighborhood}`
                                : ''}
                              {contact.cep
                                ? ` - CEP ${formatCep(contact.cep)}`
                                : ''}
                            </p>
                          </div>
                          <div className="flex items-center justify-start gap-4 text-sm">
                            {contact.phone ? (
                              <div className="flex flex-col items-start">
                                <span>Telefone</span>
                                <span className="font-semibold">
                                  {formatPhoneNumber(contact.phone)}
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="flex flex-col">
                              <span>e-mail</span>
                              <p className="font-semibold">{contact.email}</p>
                            </div>
                          </div>
                          {contact.cellPhone && (
                            <div className="flex flex-col items-start text-sm">
                              <span>Celular</span>
                              <span className="font-semibold">
                                {contact.cellPhone}
                              </span>
                            </div>
                          )}

                          {contact.profileContact.length > 0 && (
                            <div className="flex flex-col items-start justify-center gap-1 text-sm">
                              <span className="mt-1">
                                Produtos
                              </span>
                              <ul className="flex flex-wrap gap-2">
                                {contact.profileContact.map(
                                  (profileContact) => (
                                    <li
                                      key={`${profileContact.contactId}${profileContact.profileId}`}
                                      className="rounded-2xl border border-zinc-400 px-2 py-1"
                                    >
                                      {profileContact.profile.description}
                                    </li>
                                  ),
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
                  </div>
                ))}
              </ul>
              {loading && (
                <div className="mt-4 flex justify-center">
                  <ClipLoader color="#000" loading={loading} size={50} />
                  <div className="ml-4 text-base font-semibold text-zinc-600">
                    Carregando assistências
                  </div>
                </div>
              )}
              <div ref={ref} />
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </>
  );
}
