import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import Loading from '../Loading';
import Main from '../Main';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { useDocumentTypesContent } from './useDocumentTypesContent';

type DocumentTypesContentProps = {
  isModal?: boolean;
  onTableRowClick: (id: number, name: string) => void;
  onAddDocumentTypeClick?: () => void;
};

export default function DocumentTypesContent({
  isModal,
  onTableRowClick,
  onAddDocumentTypeClick,
}: DocumentTypesContentProps) {
  const {
    documentTypes,
    documentTypesLength,
    documentTypesShown,
    inputRef,
    isFetching,
    isSearchInputDirty,
    sortField,
    documentTypesShownLength,
    handleSearch,
    handleCancelSearch,
    handleSortTable,
  } = useDocumentTypesContent();

  return (
    <>
      {!isModal && (
        <ListingHeader
          add
          onAddIconClick={onAddDocumentTypeClick}
          pdfTop="3rem"
          pdf={false}
        >
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">Tipo de documento</h1>
            <SearchInput
              ref={inputRef}
              onSearch={handleSearch}
              // onButtonClick={handleCancelSearch}
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
            />
          </div>

          <Table.Head>
            <Table.Cell
              items={documentTypesShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
            >
              Tipo de documento
            </Table.Cell>
          </Table.Head>
        </ListingHeader>
      )}

      <Main isModal={isModal}>
        {isFetching && <Loading />}
        {!isFetching && documentTypes && (
          <Table.Root
            itemType="tipos de documentos"
            itemsLength={documentTypesLength}
            className={`${isModal ? 'max-h-[60vh]' : ''}`}
            paddingTop="0"
          >
            {documentTypesShown.map((documentType) => {
              return (
                <Table.Row
                  key={documentType.id}
                  onClick={() =>
                    onTableRowClick(documentType.id, documentType.name)
                  }
                >
                  <Table.Cell items={documentTypesShown} type="name">
                    <span>{documentType.name}</span>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Root>
        )}
      </Main>

      <Footer
        className={`${isModal ? 'mb-1 ml-[5vw] w-[90vw]' : ''}`}
        current={documentTypesShownLength}
        total={documentTypesLength}
        itemType="Tipos de documentos"
        // tableFooter={
        //   documentTypesLength > 100 && (
        //     <TableFooter
        //       currentPage={currentPage}
        //       itemsLength={documentTypesLength}
        //       itemsShownLength={documentTypesShownLength}
        //       pagination={pagination}
        //       onChangePage={handleChangePage}
        //       onChangePagination={handleChangePagination}
        //     />
        //   )
        // }
      />
    </>
  );
}
