import { create } from 'zustand';

type AlertStoreProps = {
  state: {
    message: string | null;
  };
  actions: {
    handleClose: () => void;
    handleOpen: (message: string) => void;
  };
};

export const useAlertStore = create<AlertStoreProps>((set) => {
  return {
    state: {
      message: null,
    },
    actions: {
      handleClose: () => {
        set((state) => ({
          ...state,
          state: { ...state.state, message: null },
        }));
      },
      handleOpen: (message: string) => {
        set((state) => ({ ...state, state: { ...state.state, message } }));
      },
    },
  };
});
