import { useQueryClient, useQuery } from 'react-query';
import { useTitle } from '../../../hooks/useTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { usePartsCatalogStore } from '../../../store/partsCatalog';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  FindAllPartsGroupItem,
  PartsGroupItem,
  PartsGroupItemDomainLiveSearch,
  PartsGroupItemDomainLiveSearchPaginated,
} from '../../../types/partsGroupItem';
import {
  liveSearchPartsGroupItemBuffaloCache,
  liveSearchProductBuffaloCache,
  partsByProductIdPartsGroupIdApiCache,
  productBuffaloCache,
} from '../../../constants/requestCacheName';
import generateProductPartsCatalogPdf from '../../../pdf/productPartsCatalogPdf/generateProductPartsCatalogPdf';
import { useError } from '../../../hooks/useError';
import { useLiveSearch } from '../../hooks/useLiveSearch';
import { getCurrentDomain } from '../../../utils/domain';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { getPartsGroupItemDomainLiveSearchPaginated } from '../../../services/partsGroupItem';
import { getProductByProductId } from '../../services/produtosService';
import { FindAllProductDescriptionDetails } from '../../../types/productsDescription';

export const usePartsCatalag = () => {
  useTitle('Catalogo de peças');

  const { clearCache } = useQueryCache();

  const navigate = useNavigate();




  const { data: partsSearch, refetch: refetchPartsSearch } =
    useQuery<PartsGroupItemDomainLiveSearchPaginated>(
      liveSearchPartsGroupItemBuffaloCache,
      async () =>
        (
          await getPartsGroupItemDomainLiveSearchPaginated(
            productId,
            inputRef.current?.value ?? null,
            getCurrentDomain().id,
          )
        ).data,
      {
        retry: false,
        enabled: false,
        refetchOnWindowFocus: false,
      },
    );

  const {
    inputRef,
    handleClickOutAutoComplete,
    handleSearchInputChange,
    handleClearSearchCache,
    handleClearSearchAndSetFocus,
  } = useLiveSearch({
    cacheName: liveSearchPartsGroupItemBuffaloCache,
    refetch: refetchPartsSearch,
  });

  const [searchClick, setSearchClick] = useState<boolean>(false);

  const handlesearchClick = () => {
    setSearchClick((prev) => !prev);
    clearCache(liveSearchProductBuffaloCache);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleProductSearchClick = async ({
    partsGroupItem,
  }: PartsGroupItemDomainLiveSearch) => {
    const { part, partsGroup } = partsGroupItem;

    setCurrentPartsGroup(partsGroup);

    handleClickPart(+part.id, partsGroupItem);
    handleClearSearchCache();
    setSearchClick(false);
    clearCache(liveSearchProductBuffaloCache);
    if (inputRef.current && isMobile) {
      inputRef.current.value = '';
    }
  };

  const { productId } = useParams();
  const [partId] = useSearchParams('partId');

  const {
    state: { currentPartsGroup, currentPartIdHover, canEdit, shouldShowFirstCurrentPartsGroup, currentPartClicked },
    actions: {
      setShouldShowFirstCurrentPartsGroup,
      setCurrentProductId,
      setCurrentPartClicked,
      setCurrentPartIdHover,
      setCurrentPartsGroup,
    },
  } = usePartsCatalogStore();

useEffect(() => {
  const timeout = setTimeout(() => {
    setShouldShowFirstCurrentPartsGroup(false);
  }, 100);
  return () => clearTimeout(timeout);
}, [setShouldShowFirstCurrentPartsGroup]);

  useEffect(() => {}, [currentPartsGroup]);

  const queryClient = useQueryClient();
  const [currentProduct, setCurrentProduct] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const mobileMaxWidth = 1280;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileMaxWidth);
  const line = useRef<{ id: number; name: string } | null>(null);
  const category = useRef<{ id: number; name: string } | null>(null);

  const getCurrentParts = useCallback(() => {
    const currentParts = queryClient.getQueryData<FindAllPartsGroupItem>(
      `${partsByProductIdPartsGroupIdApiCache}${currentPartsGroup?.id}`,
    );
    return currentParts;
  }, [currentPartsGroup?.id, queryClient]);

  const handlePartMouseEnter = useCallback(
    (partId: number) => {
      setCurrentPartIdHover(partId);
    },
    [setCurrentPartIdHover],
  );

  const handleClickPart = useCallback(
    (partId: number, partGroupItemSelect?: PartsGroupItem) => {
      if (partGroupItemSelect) {
        setCurrentPartClicked(partGroupItemSelect);
        handlePartMouseEnter(partId);
        return;
      }

      const currentParts = getCurrentParts();
      if (currentParts) {
        const part =
          currentParts?.items.find(({ part }) => part?.id === partId) ?? null;
        setCurrentPartClicked(part);
        handlePartMouseEnter(partId);
      }
    },
    [getCurrentParts, handlePartMouseEnter, setCurrentPartClicked],
  );

  useEffect(() => {
    if(shouldShowFirstCurrentPartsGroup) {
      handleClickPart(currentPartClicked?.part?.id!, currentPartClicked!);
    }
  }, [currentPartClicked, handleClickPart, shouldShowFirstCurrentPartsGroup])

  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const handleGeneratePdf = async () => {
    setIsPdfLoading(true);
    try {
      await generateProductPartsCatalogPdf(+productId!, setErrorMessage);
    } catch (error) {
      if (
        typeof error === 'string' &&
        error
          .toLowerCase()
          .includes('open pdf in new window blocked by browser')
      ) {
        setErrorMessage(
          'O navegador bloqueou o PDF.\n Desative a opção Bloquear Pop-ups e tente gerar novamente',
        );
        return;
      }

      setErrorMessage('Ocorreu um erro ao gerar o PDF');
    } finally {
      setIsPdfLoading(false);
    }
  };

  const { data: productData } = useQuery<FindAllProductDescriptionDetails>(
    `${productBuffaloCache}${productId}`,
    () => getProductByProductId(Number(productId)),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  useEffect(() => {
    if (productData) {
      setCurrentProduct({
        id: productData?.product?.id,
        name: productData?.product?.name,
      });
      line.current = {
        id: productData?.product?.line?.id,
        name: productData?.product?.line?.name,
      };
      category.current = {
        id: productData.product.category.id,
        name: productData.product.category.name,
      }
    }
  }, [productData]);

  useEffect(() => {
    if (productId) {
      setCurrentProductId(+productId);
    }
  }, [productId, setCurrentProductId]);

  useEffect(() => {
    if (partId) {
      handleClickPart(+partId);
    }
  }, [currentPartsGroup, handleClickPart, partId]);

  const [openSearch, setOpenSearch] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileMaxWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePartMouseOut = () => {
    setCurrentPartIdHover(null);
  };

  const explodedViewImageName = currentPartsGroup?.image;


  const handleOpenSearch = () => {
    setOpenSearch((prev) => !prev);
  };

  const handleNavigatePartsProduct = () => {
    navigate(`/parts-product/${productId}`, { replace: true });
  };

  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!partsSearch || partsSearch.items.length === 0) return;

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        setSelectedIndex((prev) =>
          Math.min(prev + 1, partsSearch.items.length - 1),
        );
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        setSelectedIndex((prev) => Math.max(prev - 1, 0));
      }

      if (event.key === 'Enter' && selectedIndex !== -1) {
        event.preventDefault();
        handleProductSearchClick(partsSearch.items[selectedIndex]);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [partsSearch, selectedIndex]);

  const handleBackButtonClick = () => {
    const params = new URLSearchParams();

    navigate({
      pathname: `/produto/${currentProduct?.id}/${encodeURIComponent(currentProduct?.name!)}/categoria/${category.current?.id}/linha/${line.current?.id}`,
      search: params.toString(),
    });
  };

  return {
    currentPartIdHover,
    canEdit,
    currentPartsGroup,
    currentProduct,
    explodedViewImageName,
    partsSearch,
    inputRef,
    errorMessage,
    searchClick,
    isPdfLoading,
    isMobile,
    category,
    line,
    openSearch,
    selectedIndex,
    handleBackButtonClick,
    handleNavigatePartsProduct,
    handleOpenSearch,
    clearError,
    handleClickOutAutoComplete,
    handleSearchInputChange,
    handleProductSearchClick,
    handlePartMouseOut,
    handlePartMouseEnter,
    handleClearSearchAndSetFocus,
    handleClickPart,
    handlesearchClick,
    handleGeneratePdf,
  };
};
