import { useMutation, useQuery } from 'react-query';
import { domainCache } from '../../constants/requestCacheName';
import { useHandleTable } from '../../hooks/useHandleTable';
import formatSearch from '../../utils/formatSearch';
import {
  FindAllQuestions,
  Question,
  ReorderQuestionDto,
} from '../../types/questions';
import {
  getAllQuestionsPaginated,
  reorderQuestions,
} from '../../services/questions';
import { useEdit } from '../../hooks/useEdit';
import { DropResult } from '@hello-pangea/dnd';
import { reorderList } from '../../utils/dragDrop';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { mutationErrorHandling } from '../../utils/errorHandling';
import { useError } from '../../hooks/useError';
import { getDomainsPaginated } from '../../services/domain';
import { Domain, FindAllDomainsPaginated } from '../../types/domain';
import { Meta } from '../../types/pagination';
import { questionSearchBaseEndpoint } from '../../constants/endpoints';
import { updateQuestionDomain } from '../../services/questionsDomain';
import { UpdateDomainQuestionDto } from '../../types/questionsDomain';
import { useSearchParams } from '../../hooks/useSearchParams';
import { useNavigate } from 'react-router-dom';
import { Search, search } from '../../services/search';

type Status = 'all' | 'none' | 'partial';

type CheckedDomainsStatus = {
  domainId: number;
  domainName: string;
  status: Status;
};

type QustionDomainChanged = {
  questionId: number;
  id?: number;
  domainId?: number;
};

type FaqFilter = 'Todos' | 'Ativo' | 'Desativado';
type DomainFilter = 'Todos' | string;

export const useQuestionContent = () => {
  const [faqFilter] = useSearchParams('status') as [FaqFilter];

  const [domainFilter] = useSearchParams('domain') as [DomainFilter];

  const [domainWidth, setDomainWidth] = useState<number>();

  const { data: domains } = useQuery<FindAllDomainsPaginated>(
    domainCache,
    async () => (await getDomainsPaginated()).data,
    {
      onSuccess: () => {
        const maxWidth = (domains?.items ?? []).reduce((maxWidth, row) => {
          const width = row.name?.toString().length || 0;
          return width > maxWidth ? width : maxWidth;
        }, 0);
        setDomainWidth((maxWidth > 32 ? maxWidth + 2 : 32) / 2);
      },

      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const { errorMessage, clearError, setErrorMessage } = useError();

  const {
    canEdit: canEditDomain,
    handleEdit: handleEditDomain,
    handleUnedit: handleUneditDomain,
  } = useEdit();

  const { mutate: reorderQuestionsMutate } = useMutation({
    mutationFn: async (dto: ReorderQuestionDto[]) =>
      await reorderQuestions(dto),

    onError: (error) => {
      mutationErrorHandling(error, 'Falha ao reordenar', setErrorMessage);
    },
  });

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return search<FindAllQuestions>(
        baseUrl,
        searchValue,
        page,
        'DESC',
        null,
        [
          { name: 'status', value: faqFilter !== 'Todos' ? faqFilter : '' },
          {
            name: 'domain',
            value: domainFilter,
          },
        ],
      );
    },
    [faqFilter, domainFilter],
  );

  const loadMore = useCallback(
    async (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      return await getAllQuestionsPaginated(page, direction, limit, {
        domain: domainFilter,
        status: faqFilter,
      });
    },
    [domainFilter, faqFilter],
  );

  const {
    sortField,
    inputRef,
    isSearchInputDirty,
    itemsShown,
    itemsShownLength: questionsShownLength,
    itemsLength: questionsLength,
    searchItems,
    isSearchFetching: isFetching,
    itemsLoadMore,
    defineItemsShown,
    handleSearch: searchScreen,
    handleSortTable: sortTable,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleCancelSearch,
    dirtySearchInput,
    refetchSearch,
  } = useHandleTable(
    0,
    [],
    null,
    null,
    questionSearchBaseEndpoint,
    loadMore,
    null,
    doSearch as Search,
  );
  const questionShown: Question[] = itemsShown;

  const {
    canEdit: canEditOrder,
    handleEdit: handleEditOrder,
    handleUnedit: handleUneditOrder,
  } = useEdit();

  const { mutate: updateEmailSignatureDomainMutate } = useMutation({
    mutationFn: async (dto: UpdateDomainQuestionDto[]) =>
      (await updateQuestionDomain(dto)).data,

    onSuccess: () => {
      handleUneditDomain();
      // refetchSearch();
    },

    onError: (error) => {
      // refetchSearch();
      mutationErrorHandling(
        error,
        'Falha ao salvar perguntas frequentes com domínios',
        setErrorMessage,
      );
    },
  });

  const reorderedTableUpdate = useRef<ReorderQuestionDto[]>([]);

  // const isFetching = isFetchingUsersTypes;

  const handleSearch = () => {
    searchScreen<Question>((item, formatedSearch) => {
      return formatSearch(item.pergunta?.toString()).includes(formatedSearch);
    });
  };

  const handleChangeEditOrderButtonClick = () => {
    if (canEditOrder) {
      if (reorderedTableUpdate.current.length > 0) {
        reorderQuestionsMutate(reorderedTableUpdate.current);
      }

      handleUneditOrder();
      return;
    }

    // handleReset();
    setCheckQuestionList(questionShown);
    handleEditOrder();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedTable = reorderList(
      questionShown,
      result.source.index,
      result.destination.index,
    );

    reorderedTableUpdate.current = reorderedTable.map((reorderItem, index) => {
      reorderItem.ordem = index + 1;

      return {
        faqId: reorderItem.id,
        order: reorderItem.ordem,
      };
    });

    defineItemsShown(reorderedTable);
  };

  const handleSortTable = (fieldNameSort: string) => {
    if (canEditOrder) return;

    sortTable(fieldNameSort);
  };

  // TODO Criar componente para check domains

  const findAllQuestions = useMemo<FindAllQuestions>(() => {
    return {
      items: [
        ...(searchItems?.items ?? []),
        ...(itemsLoadMore?.items ?? []),
      ] as Question[],
      meta: itemsLoadMore?.meta ?? (searchItems?.meta as Meta),
    };
  }, [
    itemsLoadMore?.items,
    itemsLoadMore?.meta,
    searchItems?.items,
    searchItems?.meta,
  ]);

  const changedQuestions = useRef<QustionDomainChanged[]>([]);

  const defineDomainStatus = useCallback(
    (
      domain: Domain,
      questions: Question[] = (findAllQuestions?.items as Question[]) ?? [],
    ): CheckedDomainsStatus => {
      let checkedNumber = 0;
      questions.forEach((question) => {
        if (
          question.questionDomains?.some(
            (domainQuestion) => domainQuestion.domain.id === domain.id,
          )
        ) {
          checkedNumber++;
        }
      });

      if (checkedNumber === 0) {
        return {
          domainId: domain.id,
          status: 'none',
          domainName: domain.name,
        };
      }

      if (checkedNumber === findAllQuestions?.items.length) {
        return {
          domainId: domain.id,
          status: 'all',
          domainName: domain.name,
        };
      }

      return {
        domainId: domain.id,
        status: 'partial',
        domainName: domain.name,
      };
    },
    [findAllQuestions],
  );

  const updateChangedQuestionsOnAllDomainsCheck = (
    domain: Domain,
    isChecked: boolean,
  ) => {
    const questionsWithoutSelectedDomain = changedQuestions.current.filter(
      (changedQuestion) => changedQuestion.domainId !== domain.id,
    );

    // Selecionado
    if (isChecked) {
      const updatedChangedQuestions: QustionDomainChanged[] = (
        (findAllQuestions.items ?? []) as Question[]
      )
        .filter(
          (question) =>
            !question.questionDomains?.some(
              (questionDomain) =>
                questionDomain.domain.id === domain.id &&
                // Remove os que foram inseridos artificialmente e não estavam na lista inicial
                questionDomain.id > 0,
            ),
        )
        .map((question) => ({
          domainId: domain.id,
          questionId: question.id,
        }));

      changedQuestions.current = [
        ...questionsWithoutSelectedDomain,
        ...updatedChangedQuestions,
      ];

      return;
    }

    // Não selecionado

    const updatedChangedQuestions: QustionDomainChanged[] = (
      (findAllQuestions.items ?? []) as Question[]
    )
      .filter((question) =>
        question.questionDomains?.some(
          (questionDomain) =>
            questionDomain.domain.id === domain.id && questionDomain.id > 0,
        ),
      )
      .map((question) => {
        return {
          id: question.questionDomains?.find(
            (questionDomain) =>
              questionDomain.domain.id === domain.id && !!questionDomain.id,
          )?.id,

          domainId: domain.id,
          questionId: question.id,
        };
      });

    changedQuestions.current = [
      ...questionsWithoutSelectedDomain,
      ...updatedChangedQuestions,
    ];
  };

  const handleChangeAllDomainsCheck = (domain: Domain, isChecked: boolean) => {
    updateChangedQuestionsOnAllDomainsCheck(domain, isChecked);
    setCheckedItems((prevCheckedItems) => {
      // Remove os domínios relativos ao domain que foi passado
      const updatedCheckedItems: Question[] = prevCheckedItems.map(
        (checkedItem) => ({
          ...checkedItem,
          questionDomains:
            checkedItem.questionDomains?.filter(
              (questionDomain) => questionDomain.domain.id !== domain.id,
            ) ?? [],
        }),
      );

      let status: Status = 'none';
      // Se for selecionado adiciona em todos os domínios
      if (isChecked) {
        updatedCheckedItems.forEach((checkedItem) => {
          checkedItem.questionDomains?.push({ id: -1, domain });
        });
        status = 'all';
      }
      setCheckedDomainsStatus((prevCheckedDomainStatus) =>
        prevCheckedDomainStatus.map((domainStatus) => {
          if (domainStatus.domainId === domain.id) {
            return {
              ...domainStatus,
              status,
            };
          }
          return domainStatus;
        }),
      );
      return updatedCheckedItems;
    });
  };

  const handleChangeEditDomainButtonClick = () => {
    if (canEditDomain) {
      if (changedQuestions.current.length > 0) {
        updateEmailSignatureDomainMutate(changedQuestions.current);

        changedQuestions.current = [];
        return;
      }

      handleUneditDomain();
      return;
    }
    setCheckQuestionList(checkedItems);

    handleEditDomain();
  };

  const updateStatusOnChangeDomainCheck = (
    isCheck: boolean,
    domain: Domain,
    questionId: number,
  ) => {
    const updatedQuestions: Question[] = checkedItems.map((question) => {
      if (question.id === questionId) {
        let questionDomains = question.questionDomains ?? [];

        if (isCheck) {
          questionDomains.push({ domain, id: -1 });
        }

        if (!isCheck) {
          questionDomains = questionDomains.filter(
            (questionDomain) => questionDomain.domain.id !== domain.id,
          );
        }

        return {
          ...question,
          questionDomains,
        };
      }

      return question;
    });

    const status = defineDomainStatus(domain, updatedQuestions);
    setCheckedItems(updatedQuestions);

    setCheckedDomainsStatus((prevDomainStatus) => {
      const updatedStatus = prevDomainStatus.map((domainStatus) =>
        domainStatus.domainId === domain.id ? status : domainStatus,
      );

      return updatedStatus;
    });
  };

  const handleChangeDomainCheck = (
    isCheck: boolean,
    questionDomainId: number | undefined,
    domain: Domain,
    questionId: number,
  ) => {
    updateStatusOnChangeDomainCheck(isCheck, domain, questionId);

    const questionWithoutSelected: QustionDomainChanged[] =
      changedQuestions.current.filter(
        (changedQuestion) =>
          !(
            changedQuestion.domainId === domain.id &&
            changedQuestion.questionId === questionId
          ),
      );
    changedQuestions.current = questionWithoutSelected;
    // Se foi selecionado e veio o ID, já estava selecionado anteriormente
    // Se foi desselecionado e não veio o ID, já estava desselecionado anteriormente
    if ((isCheck && !!questionDomainId) || (!isCheck && !questionDomainId)) {
      return;
    }
    changedQuestions.current.push({
      id: questionDomainId,
      domainId: domain.id,
      questionId,
    });
  };

  useEffect(() => {
    if (domains) {
      const domainsStatus: CheckedDomainsStatus[] = domains?.items.map(
        (domain) => defineDomainStatus(domain),
      );

      setCheckedDomainsStatus(domainsStatus);
    }
  }, [defineDomainStatus, domains]);

  useEffect(() => {
    const question = (findAllQuestions?.items as Question[]) ?? [];

    setCheckedItems(question);
  }, [findAllQuestions]);

  const [checkedItems, setCheckedItems] = useState<Question[]>([]);

  const [checkedDomainsStatus, setCheckedDomainsStatus] = useState<
    CheckedDomainsStatus[]
  >([]);

  const [checkQuestionList, setCheckQuestionList] = useState<Question[]>([]);

  const handleButtonCancelEditClick = () => {
    if (canEditOrder) {
      defineItemsShown(checkQuestionList);
      handleUneditOrder();
    }

    if (canEditDomain) {
      setCheckedItems(checkQuestionList);
      changedQuestions.current = [];
      handleUneditDomain();
    }
  };

  const { canEdit } = useEdit();

  const [statusActive] = useSearchParams('status') as [string];

  const navigate = useNavigate();
  const currentParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  const handleFilterByStatus = useCallback(
    (filterName: string) => {
      if (filterName === 'todos') {
        currentParams.delete('status');
        navigate({ search: currentParams.toString() });
        return;
      }
      currentParams.set('status', filterName.toString());
      navigate({ search: currentParams.toString() });
    },
    [currentParams, navigate],
  );

  const handleFilterByDomain = useCallback(
    (filterName: string) => {
      if (filterName === 'todos') {
        currentParams.delete('domain');
        navigate({ search: currentParams.toString() });
        return;
      }
      currentParams.set('domain', filterName.toString());
      navigate({ search: currentParams.toString() });
    },
    [currentParams, navigate],
  );

  useEffect(() => {
    refetchSearch();
  }, [refetchSearch, faqFilter, domainFilter]);

  return {
    sortField,
    inputRef,
    isSearchInputDirty,
    questionShown,
    questionsShownLength,
    isFetching,
    canEditOrder,
    canEdit,
    errorMessage,
    checkedDomainsStatus,
    canEditDomain,
    domainWidth,
    domains,
    checkedItems,
    questionsLength,
    statusActive,
    domainFilter,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleFilterByStatus,
    handleChangeEditDomainButtonClick,
    handleChangeDomainCheck,
    handleChangeAllDomainsCheck,
    clearError,
    handleDragEnd,
    handleChangeEditOrderButtonClick,
    handleButtonCancelEditClick,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
    dirtySearchInput,
    handleFilterByDomain,
  };
};
