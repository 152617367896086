import {
  EditorContent,
  Editor as EditorTipTap,
  EditorContentProps,
} from '@tiptap/react';
import { forwardRef } from 'react';

type EditorProps = EditorContentProps & {
  editor: EditorTipTap | null;
  helperText?: string;
  style?: string;
};

const Editor = forwardRef<HTMLDivElement, EditorProps>(
  ({ style = '', editor, helperText, ...rest }, ref) => {
    return (
      <div>
        <EditorContent
          editor={editor}
          {...rest}
          ref={ref}
          // Comentado por causa do modal de gerenciamento de peças e produtos, que estava com bug de layout
          //  className='max-h-20'
        />
        <span className="text-xs text-red-600">{helperText}</span>
      </div>
    );
  },
);

export default Editor;
