import { Skeleton } from '@mui/material';

type CardSkeletonProps = {
  width?: string;
  mainSkeletonHeight?: string;
};

export default function CardSkeleton({
  width = '100%',
  mainSkeletonHeight = '15rem',
}: CardSkeletonProps) {
  return (
    <div>
      <Skeleton
        variant="rounded"
        sx={{ height: mainSkeletonHeight, borderRadius: '0.5rem', width }}
      />
      <Skeleton variant="rectangular" sx={{ mt: '2rem' }} />
      <Skeleton variant="rectangular" sx={{ mt: '1rem' }} />
    </div>
  );
}
