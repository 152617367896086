import { ReactNode } from 'react';
import Modal from '../Modal';
import WarningIcon from '@mui/icons-material/Warning';


type WarningModalDuimpProps = {
  isConfirmModalOpen: boolean;
  handleCloseConfirmModal: () => void;
  contentMessage: ReactNode;
};

export default function WarningModalDuimp({
  isConfirmModalOpen,
  handleCloseConfirmModal,
  contentMessage,
}: WarningModalDuimpProps) {
  return (
    <Modal
      isOpen={isConfirmModalOpen}
      onClose={handleCloseConfirmModal}
      cancelIcon={false}
    >
      <div className="my-10">
        <div className="flex items-center justify-center gap-4">
          <WarningIcon fontSize="large" className="text-yellow-500" />
          <p>{contentMessage}</p>
        </div>
        {/* <div className="mt-8 flex justify-center gap-8">
          <button
            onClick={handleCloseConfirmModal}
            className="rounded border-[1px] border-primary px-12 py-1 hover:bg-primary hover:text-zinc-50"
          >
            Ok
          </button>
        </div> */}
      </div>
    </Modal>
  );
}
