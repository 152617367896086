import {
  FindAllUserTypes,
  SaveUserType,
  UpdateUserType,
  UserType,
} from '../types/userType';
import { api } from './api';

export const getAllUserTypes = async () => {
  const response = await api.get<FindAllUserTypes>(`/user-type/v1/find-all`);
  return response;
};

export const saveUserType = async ({ role }: SaveUserType) => {
  const body = { role };

  const response = await api.post<UserType>('/user-type/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const updateUserType = async ({
  id,
  role,
  pagesToAdd,
  pagesToRemove,
}: UpdateUserType) => {
  const body = { id, role, pagesToAdd, pagesToRemove };

  const response = await api.put<UserType>('/user-type/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const deleteUserType = async ({
  userTypeId,
}: Record<'userTypeId', number>) => {
  const response = await api.delete<void>(`/user-type/v1/${userTypeId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};
