import { Link } from 'react-router-dom';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import {
  categoriesNavbarStyle,
  listItemsStyle,
  pagesListStyle,
} from './styles';
import { grey } from '@mui/material/colors';
import { useCategoriesNavbar } from './useCategoriesNavbar';

type CategoriesNavbarProps = {
  onCategoryClick: (categoryId: number) => void;
};

export default function CategoriesNavbar({
  onCategoryClick,
}: CategoriesNavbarProps) {
  const {
    categories,
    categoryId,
    currentLineId,
    isResponsive,
    selectedCategory,
    handleSelectPartGroup,
  } = useCategoriesNavbar();

  return (
    <>
      {isResponsive ? (
        <Box component="nav" sx={categoriesNavbarStyle} color={grey['200']}>
          <span className="text-primary">Categoria</span>
          <Select
            value={selectedCategory}
            onChange={handleSelectPartGroup}
            sx={listItemsStyle}
          >
            {categories?.items.map((category) => (
              <MenuItem
                value={category.name}
                key={category.id}
                onClick={() => onCategoryClick(category.id)}
                sx={{ width: '100vw' }}
              >
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      ) : (
        <Box component="nav" sx={categoriesNavbarStyle}>
          <Box component="ul" sx={pagesListStyle}>
            {categories?.items.map((category, index) => (
              <Box component="li" key={`${category.id}`} sx={listItemsStyle}>
                <Link
                  to={{
                    search: `lineId=${currentLineId ?? '1'}&categoryId=${category.id}`,
                  }}
                  onClick={() => onCategoryClick(category.id)}
                >
                  <Typography
                    component="span"
                    sx={{
                      color:
                        categoryId === category.id ||
                        (index === 0 && !categoryId)
                          ? '#42a0dd'
                          : '#222222',
                      ':hover': {
                        color: '#42a0dd',
                        transition: 'all ease 0.2s',
                      },
                    }}
                  >
                    {category.name}
                  </Typography>
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
}
