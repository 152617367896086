import Modal from '../../Modal';
import SerchInput from '../../SearchInput';
import Table from '../../Table';
import CheckIcon from '@mui/icons-material/Check';
import { useSelectModal } from './useSelectModal';
import Loading from '../../Loading';
import Footer from '../../Footer';

type SelectLineModalProps = {
  currentCategoryLineName: string | null;
  isModalOpen: boolean;
  onRowClick: (lineId: number, lineName: string) => void;
  onModalClose: () => void;
};

export default function SelectModal({
  currentCategoryLineName,
  isModalOpen,
  onRowClick,
  onModalClose,
}: SelectLineModalProps) {
  const {
    handleCancelLineSearch,
    handleSearchLines,
    handleSortLineTable,
    isLineFetching,
    isLineSearchInputDirty,
    lineInputRef,
    linesShown,
    sortFieldLines,
    lines,
  } = useSelectModal(isModalOpen);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onModalClose}
      iconContainerStyle={{ right: '1rem' }}
    >
      <div className="flex h-[85vh] flex-col">
        <div>
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">Linhas</h1>
            <SerchInput
              ref={lineInputRef}
              onSearch={handleSearchLines}
              onButtonClick={handleCancelLineSearch}
              showCancelOptions={isLineSearchInputDirty}
              onCancelSearch={handleCancelLineSearch}
            />
          </div>
          <Table.Head rowStyle="px-0">
            <Table.Cell
              items={linesShown}
              type="name"
              onSort={handleSortLineTable}
              isSortActive={sortFieldLines === 'name'}
            >
              Linhas
            </Table.Cell>
          </Table.Head>
        </div>

        <div className="flex-1 overflow-auto">
          {isLineFetching && (
            <div className="mt-4">
              <Loading heightNormal />
            </div>
          )}
          {!isLineFetching && lines && (
            <Table.Root
              itemType="Categorias"
              itemsLength={lines.meta.totalItems}
              paddingTop="0"
            >
              {linesShown.map((line) => (
                <Table.Row
                  key={line.id}
                  onClick={
                    currentCategoryLineName !== line.name
                      ? () => onRowClick(line.id, line.name)
                      : undefined
                  }
                  className={`items-center px-0 ${currentCategoryLineName === line.name ? 'cursor-default text-secondaryText opacity-50' : ''}`}
                >
                  <Table.Cell items={lines.items} type="name" className="">
                    {line.name}
                  </Table.Cell>
                  {currentCategoryLineName === line.name && (
                    <CheckIcon className="text-primary" />
                  )}
                </Table.Row>
              ))}
            </Table.Root>
          )}
        </div>

        <Footer
          current={linesShown.length}
          total={lines?.meta.totalItems ?? 0}
          itemType="Categorias"
          className="static mb-[-1rem] ml-[-1rem] w-[calc(100%+2rem)] rounded-b-2xl"
        />
      </div>
    </Modal>
  );
}
