import { PopUp } from '../../types/popUp';
import { removeHtmlTags } from '../../utils/removeHtmlTags';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import Loading from '../Loading';
import LoadMore from '../LoadMore';
import Main from '../Main';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { usePopUpContent } from './usePopUpContent';

type PopUpContentProps = {
  onTableRowClick: ({
    titleField,
    contentField,
    subtitleField,
    footerField,
  }: PopUp) => void;
  onAddPopUpClick: () => void;
  onPreviewClick: ({
    titleField,
    contentField,
    subtitleField,
    footerField,
  }: PopUp) => void;
  queryKey: string;
};

export default function PopUpContent({
  onTableRowClick,
  onAddPopUpClick,
  onPreviewClick,
  queryKey,
}: PopUpContentProps) {
  const {
    inputRef,
    isSearchInputDirty,
    isFetchingPopUpList,
    popUpListShown,
    nameColWidth,
    sortField,
    domainWidth,
    domains,
    popUpListShownLength,
    popUpListLength,
    checkPopUpListDomains,
    canEdit,
    isLoadMoreFetching,
    endPageRef,
    handleSortTable,
    handleSearch,
    handleCancelSearch,
    handleCheckDomain,
    handleButtonOkClick,
    handleButtonCancelEditClick,
  } = usePopUpContent(queryKey);

  return (
    <>
      <ListingHeader
        add
        onAddIconClick={onAddPopUpClick}
        pdfTop="3rem"
        pdf={false}
      >
        <div className="mt-4">
          <div className="mb-4 flex justify-between">
            <h1 className="text-2xl">Pop-Up</h1>
            <div className="flex items-center gap-2">
              <button
                onClick={handleButtonOkClick}
                className="text-sm text-primary"
              >
                {canEdit ? 'Concluir domínio' : 'Editar domínio'}
              </button>
              {canEdit && (
                <>
                  <span>|</span>
                  <button
                    onClick={handleButtonCancelEditClick}
                    className="text-sm text-errorText"
                  >
                    Cancelar {canEdit ? 'Seleção' : ''}
                  </button>
                </>
              )}
            </div>
          </div>

          <SearchInput
            ref={inputRef}
            onSearch={handleSearch}
            // onButtonClick={handleCancelSearch}
            showCancelOptions={isSearchInputDirty}
            onCancelSearch={handleCancelSearch}
          />
        </div>
        <Table.Head rowStyle="pl-0 items-center ">
          <div className="ml-5 flex max-w-full">
            <Table.Cell
              items={popUpListShown}
              type="titleField"
              onSort={handleSortTable}
              isSortActive={sortField === 'titleField'}
              style={{ width: `${nameColWidth}ch` }}
            >
              Título
            </Table.Cell>
          </div>

          <div
            className={'mr-[13rem] flex pl-2 1.5xl:mr-[13rem] 2xl:mr-[15rem]'}
          >
            {domains?.items?.map((domain) => (
              <Table.CellStatic
                key={`label-${domain.id}`}
                style={{ width: `${domainWidth}ch` }}
                className="items-center"
              >
                <div className="flex w-28 items-center justify-center gap-2">
                  <span className="text-center text-sm">{domain.name}</span>
                </div>
              </Table.CellStatic>
            ))}
          </div>
        </Table.Head>
      </ListingHeader>

      <Main className="pb-0">
        {isFetchingPopUpList && <Loading loadMore />}
        {!isFetchingPopUpList && popUpListShown && (
          <Table.Root
            itemType="Pop Ups"
            itemsLength={popUpListShownLength}
            className="max-h-[calc(100vh-19rem)] overflow-y-auto"
          >
            {popUpListShown.map((popUp) => {
              return (
                <div key={popUp.id}>
                  <Table.Row className="overflow-hidden pl-4 pr-14" onClick={() => onTableRowClick(popUp)}>
                    <div className="flex w-full">
                      <Table.Cell style={{ width: `${nameColWidth}ch` }}>
                        {removeHtmlTags(popUp.titleField)}
                      </Table.Cell>
                    </div>

                    <div className={'flex'}>
                      {domains?.items.map((domain) => (
                        <Table.CellStatic
                          className="flex w-28 items-center justify-center gap-2"
                          key={`checkbox-${domain.id}`}
                          style={{
                            width: `${domainWidth}ch`,
                          }}
                        >
                          <span className="flex w-28 items-center justify-center">
                            <input
                              checked={checkPopUpListDomains.some(
                                (checkPopUpDomain) =>
                                  domain.id === checkPopUpDomain?.domainId &&
                                  checkPopUpDomain?.popUpId === popUp.id &&
                                  checkPopUpDomain?.isCheck,
                              )}
                              onChange={(event) =>
                                handleCheckDomain(
                                  event.target.checked,
                                  popUp?.id,
                                  domain?.id,
                                  popUp?.domains,
                                )
                              }
                              onClick={(event) => {
                                // Impede que o clique no checkbox propague para a div principal
                                event.stopPropagation();
                              }}
                              type="checkbox"
                              id="sizing-domain"
                              className="size-4"
                              disabled={!canEdit}
                            />
                          </span>
                        </Table.CellStatic>
                      ))}
                    </div>
                    <button
                      className="mr-5 w-72 text-primary"
                      type="button"
                      onClick={() => onPreviewClick(popUp)}
                    >
                      Pré-visualização
                    </button>
                  </Table.Row>
                </div>
              );
            })}
            <LoadMore
              isModal={false}
              isLoadMoreFetching={isLoadMoreFetching}
              isContentFetching={isFetchingPopUpList}
              ref={endPageRef}
            />
          </Table.Root>
        )}
      </Main>

      <Footer
        current={popUpListShownLength}
        total={popUpListLength}
        itemType="Notícias"
      />
    </>
  );
}
