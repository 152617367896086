export const application: Record<
  string,
  Record<'version' | 'versionDate', string>
> = {
  production: {
    version: '2.3.1',
    versionDate: '09/04/2025',
  },
  development: {
    version: '1.0.0',
    versionDate: '19/11/2024',
  },
};
