import { ReactNode, useEffect, useState } from 'react';
import { socket } from '../../libs/socketIo';
import Alert from '../Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

type SocketStatus = {
  message: string;
  icon: ReactNode;
};

type WebsocketProps = {
  eventName: string;
  successMessage: string;
  errorMessage: string;
  onSuccess?: (eventName: string) => void;
};

export default function Websocket({
  eventName,
  successMessage,
  errorMessage,
  onSuccess,
}: WebsocketProps) {
  const [socketStatus, setSocketStatus] = useState<SocketStatus | null>(null);

  const handleCloseAlert = () => {
    setSocketStatus(null);
  };

  useEffect(() => {
    socket.on(eventName, (data: string) => {
      if (data === 'Success') {
        setSocketStatus({
          message: successMessage,
          icon: <CheckCircleIcon className="text-success" fontSize="large" />,
        });

        if (onSuccess) {
          onSuccess(eventName);
        }

        return;
      }

      setSocketStatus({
        message: errorMessage,
        icon: <CancelIcon className="text-errorText" fontSize="large" />,
      });
    });

    return () => {
      socket.off(eventName);
    };
  }, [errorMessage, eventName, onSuccess, successMessage]);

  return (
    <Alert
      icon={socketStatus?.icon}
      isOpen={!!socketStatus}
      content={socketStatus?.message}
      onClose={handleCloseAlert}
    />
  );
}
