import { ErrorsDuimpList } from "../types/errorDuimp";
import { FindAllLogsLaunchDuimp, LogVinculate, FindAllProductsDuimp, ResponseLaunchProduct } from "../types/productDuimp";
import { apiDuimp } from "./apiDuimp";

export const getAllProductsToAdd = async () => {
  const response = await apiDuimp.get<FindAllProductsDuimp>(`v1/produto/listarProdutos`);
  return response;
};

export const launchProducts = async () => {
  const response = await apiDuimp.get<ResponseLaunchProduct>(`v1/produto/adicionarSiscomex`);
    return response;
}

export const getAllErrorsFromDuimp = async () => {
  const response = await apiDuimp.get<ErrorsDuimpList>(`v1/produto/errosProduto`);
  return response;
}

export const getAllLogsLaunchDuimp = async () => {
  const response = await apiDuimp.get<FindAllLogsLaunchDuimp>(`v1/produto/logsLancamento`);
  return response;
}

export const vinculateCompanies = async () => {
  const response = await apiDuimp.get<LogVinculate>(`v1/produto/vincularFabricantesEmProduto`)
  return response;
}
