import { Box, Button, Typography } from '@mui/material';
import {
  explodedViewContainerStyle,
  selectedPartCardTitleStyle,
  selectedPartStyle,
} from './styles';
import { Link } from 'react-router-dom';
import { useExplodedView } from './useExplodedView';
import { Layer, Rect, Stage } from 'react-konva';
import { getImageFromApi, getImageStorage } from '../../../utils/getImageUrl';
import Card from '../CardBuffalo';
import { ImageTypes } from '../../../enums/imageTypes';

type ExplodedViewProps = {
  canEdit: boolean;
  imageName?: string;
  currentPartHoverId: number | null;
  onPartMouseEnter: (partId: number) => void;
  onPartMouseOut: () => void;
  onPartClick: (partId: number) => void;
};

export default function ExplodedView({
  canEdit,
  imageName = '',
  currentPartHoverId,
  onPartMouseEnter,
  onPartMouseOut,
  onPartClick,
}: ExplodedViewProps) {
  const {
    explodedViewReduction,
    isResponsive,
    currentPartClicked,
    screenHeight,
    partsGroupImagesCoordinates,
    handlePartMouseEnter,
    handlePartMouseOut,
  } = useExplodedView(onPartMouseEnter, onPartMouseOut);

  const largura = window.innerWidth > 450;


  return (
    <Box
      component="section"
      sx={{
        ...explodedViewContainerStyle,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 20.5rem)',
        // zIndex: -10,
        marginTop: '0rem',
        paddingTop: '0rem',
      }}
    >
      <div className="mb-2 w-full text-left text-lg font-bold xl:font-medium 2xl:text-2xl">
        <span className="ml-5">Vista explodida</span>
        <span className="text-2xl text-yellow-500">.</span>
      </div>
      <div className="relative h-[300px] w-[600px] max-sm:h-[150px] max-sm:w-[300px]">
        {imageName && (
          <img
            src={getImageStorage(ImageTypes.explodedView, imageName)}
            alt={`Imagem de ${imageName}`}
            width="600px"
            height="300px"
            className="max-sm:h-auto max-sm:w-full"
          />
        )}
        <Stage
          width={600 * explodedViewReduction}
          height={300 * explodedViewReduction}
          className="absolute left-0 top-0"
        >
          <Layer
            width={600 * explodedViewReduction}
            height={300 * explodedViewReduction}
          >
            {!canEdit &&
              partsGroupImagesCoordinates?.items.map((rectangle) => (
                <Rect
                  key={`${rectangle.part.id}${rectangle.x}${rectangle.y}`}
                  x={rectangle.x * explodedViewReduction}
                  y={rectangle.y * explodedViewReduction}
                  width={rectangle.width * explodedViewReduction}
                  height={rectangle.height * explodedViewReduction}
                  fill={
                    rectangle.part.id === currentPartClicked?.part.id
                      ? '#eab308'
                      : rectangle.part.id === currentPartHoverId
                        ? '#f4d983'
                        : 'transparent'
                  }
                  opacity={
                    rectangle.part.id === currentPartHoverId ||
                    rectangle.part.id === currentPartClicked?.part.id
                      ? 0.6
                      : 1
                  }
                  onMouseOut={(e) => {
                    handlePartMouseOut(e);
                    onPartMouseOut();
                  }}
                  onMouseEnter={(e) => {
                    handlePartMouseEnter(e, rectangle.part.id);
                    onPartMouseEnter(rectangle.part.id);
                  }}
                  onPointerDown={() => {
                    onPartClick(rectangle.part.id);
                  }}
                  cornerRadius={5}
                  stroke="#71717a"
                  strokeEnabled={true}
                  strokeWidth={0.5}
                />
              ))}
          </Layer>
        </Stage>
      </div>
      {currentPartClicked && !canEdit && (
        <Box
          sx={{
            ...selectedPartStyle,
            marginTop: '0.5rem',
            padding: '0 1rem',
            overflow: 'none',
          }}
        >
          <div className="mb-2 mt-2 flex w-full items-center justify-start text-left text-lg font-bold xl:font-medium 2xl:text-2xl">
            <span>Peça selecionada</span>
            <span className="text-2xl text-yellow-500">.</span>
            {currentPartClicked.tag !== '' && (
              <div
                className={`ml-3 flex h-auto w-auto min-w-[3rem] ${currentPartClicked?.tag?.length > 3 ? 'px-2' : ''} items-center justify-center rounded-md border border-yellow-500 font-normal`}
              >
                {currentPartClicked?.tag}
              </div>
            )}
          </div>
          <Card.Root
            border
            line
            sxContent={{
              justifyContent: 'space-between',
              '@media (max-height: 925px)': {
                // height: 'calc(100vh - 40rem)'
                height: 'calc(100vh - 40rem)',
              },
              '@media (max-width: 640px)': {
                maxHeight: '9rem',
              },
            }}
            sx={{
              p: '1rem 0',
              // position: 'relative',
              marginX: 'auto',
              height: '11rem',

              // marginTop: '2rem',
              maxWidth: '100%',
              '@media (min-width: 600px)': {
                maxWidth: '600px',
              },
            }}
          >
            <div className="flex w-[96%] items-start justify-center">
              <div className="-mt-10">
                <Card.Image
                  src={getImageFromApi(currentPartClicked?.part.id ?? '')}
                  alt={`Imagem de ${currentPartClicked?.part?.name}`}
                  fallbackSrc="/images/unavailable.png"
                  fallbackAlt="Imagem de produto indisponível"
                  // style={{ marginRight: '1rem' }}
                  containerStyle={{ alignSelf: 'center' }}
                  // width={200}
                  data-smHeight={screenHeight > 700 && largura}
                  className={`absolute -top-5 left-0 mt-14 h-[6rem] w-[100px] bg-transparent data-[smHeight=true]:top-10 sm:relative sm:order-1 sm:mr-0 sm:mt-8 sm:h-36 sm:w-[180px] data-[smHeight=true]:sm:top-0`}
                />
              </div>

              <div
                data-smHeight={screenHeight > 700 && largura}
                className={`-mt-8 ml-28 w-[100%] sm:-mt-6 sm:ml-2`}
              >
                <div className="flex flex-col">
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={selectedPartCardTitleStyle}
                  >
                    {currentPartClicked?.part?.name}{' '}
                  </Typography>
                  <div className="flex items-center justify-between">
                    <span className="text-secondaryText">
                      {currentPartClicked?.part?.id}
                    </span>

                    <span className="text-black">
                      Qtd: {currentPartClicked.amount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Box sx={{ flex: 1 }}>
              <Card.Actions
                positionStatic
                sx={{
                  position: 'absolute',
                  top: '5.5rem',
                  bottom: '0.1rem',
                  right: '1rem',
                }}
                // className=''
              >
                <Link
                  to={`/compatibilidade/${currentPartClicked?.part?.id}/${encodeURIComponent(
                    currentPartClicked?.part?.name ?? '',
                  )}`}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '16px',
                      height: '3.5rem',
                      border: '2px solid #d4d4d8',
                      padding: '0.5rem',
                      fontSize: '0.8rem',
                      backgroundColor: 'white',
                      transition: 'background-color 0.3s, color 0.3s',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.85)',
                        color: 'white',
                        borderColor: '#d4d4d8',
                      },
                    }}
                  >
                    <span style={{ textTransform: 'none' }}>
                      Compatibilidade
                    </span>
                  </Button>
                </Link>
              </Card.Actions>
            </Box>
          </Card.Root>
        </Box>
      )}
    </Box>
  );
}
