import { descriptionSearchByTypeIdBaseEndpoint } from '../../../constants/endpoints';
import { ImageTypes } from '../../../enums/imageTypes';
import { PartsGroupSelected } from '../../../types/partsGroup';
import { getImageFromApi } from '../../../utils/getImageUrl';
import Alert from '../../Alert';
import ConfirmModal from '../../ConfirmModal';
import DescriptionContent from '../../DescriptionContent';
import HeaderModalManageItem from '../../HeaderModalManageItem';
import ImagesContent from '../../ImagesContent';
import Input from '../../Input';
import Loading from '../../Loading';
import Modal from '../../Modal';
import SerchInput from '../../SearchInput';
import Table from '../../Table';
import { usePartsGroupMenageModal } from './usePartsGroupMenageModal';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type PartsGroupManageModalProps = {
  isMenageModalOpen: boolean;
  selectedDescriptionId: number;
  currentPartsGroupSelected: PartsGroupSelected | null;
  onCloseMenageModal: () => void;
};

export default function PartsGroupManageModal({
  currentPartsGroupSelected,
  selectedDescriptionId,
  isMenageModalOpen,
  onCloseMenageModal,
}: PartsGroupManageModalProps) {
  const {
    isShowEditButton,
    dirtyFields,
    image,
    errors,
    isSelectDescriptionModalOpen,
    isConfirmModalOpen,
    isSelectImageModalOpen,
    isFetchingProducts,
    inputRef,
    isSearchInputDirty,
    productsLength,
    sortField,
    descriptions,
    totalParts,
    productsShown,
    name,
    errorMessage,
    // modalHeight,
    screenHeight,
    clearError,
    descriptionService,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
    handleCloseSelectImageModal,
    handleSelectImage,
    handleSelectImageClick,
    handleConfirmDelete,
    handleCloseConfirmModal,
    handleClosePartGroupMenageModal,
    handleDescriptionFieldClick,
    handleSelectDescription,
    handleCloseSelectDescriptionModal,
    handleSubmit,
    handleClearField,
    register,
    handleEdit,
    handleButtonOkClick,
    handleDeleteButtonClick,
  } = usePartsGroupMenageModal(
    selectedDescriptionId,
    currentPartsGroupSelected,
    onCloseMenageModal,
  );

  return (
    <Modal
      isOpen={isMenageModalOpen}
      onClose={handleClosePartGroupMenageModal}
      cancelIcon={false}
      height={'90vh'}
      contentStyle={`${currentPartsGroupSelected?.id ? 'pr-1' : ''} max-h-[95vh] `}
    >
      <div className={`${currentPartsGroupSelected?.id ? 'pr-3' : ''}`}>
        <HeaderModalManageItem
          showDeleteButton={!!currentPartsGroupSelected}
          deleteButtonContent="Apagar conjunto de peças"
          onDeleteButtonClick={handleDeleteButtonClick}
          isEditable={!isShowEditButton}
          title={
            currentPartsGroupSelected?.id
              ? 'Conjunto de peças'
              : 'Novo conjunto de peças'
          }
          mainButtonContent={isShowEditButton ? 'Editar' : 'Ok'}
          onCancelClick={handleClosePartGroupMenageModal}
          onOkClick={
            !isShowEditButton ? handleSubmit(handleButtonOkClick) : undefined
          }
          onEditClick={isShowEditButton ? handleEdit : undefined}
        />
      </div>

      <div
        data-smHeight={screenHeight < 830}
        className="mt-10 flex max-h-[70vh] flex-col gap-4 overflow-auto pr-2 data-[smHeight=true]:max-h-[65vh]"
      >
        <Input
          type="text"
          label="Conjunto de peças"
          disabled={isShowEditButton}
          helperText={errors.name?.message}
          isDirty={!!dirtyFields.name}
          onCancelIconClick={() => handleClearField('name')}
          {...register('name')}
          value={name}
        />
        <div>
          <div
            onClick={handleDescriptionFieldClick}
            className="flex cursor-pointer items-center gap-6 border-b-[1px]"
          >
            <Input
              readOnly
              type="text"
              label="Descrição"
              className="cursor-pointer border-none p-0"
              inputStyle="cursor-pointer"
              classNameContainer="text-primary"
              isDirty={!!dirtyFields.description}
              {...register('description')}
              value={descriptions}
            />
            <ChevronRightIcon className="text-secondaryText" />
          </div>
          <span className="text-xs text-red-600">
            {errors.description?.message}
          </span>
        </div>
        <Input
          type="number"
          label="Total de peças"
          disabled={isShowEditButton}
          helperText={errors.totalParts?.message}
          isDirty={!!dirtyFields.totalParts}
          onCancelIconClick={() => handleClearField('totalParts')}
          max={50}
          {...register('totalParts')}
          value={totalParts}
        />
        <div>
          <div
            onClick={handleSelectImageClick}
            className="flex cursor-pointer items-center gap-6 border-b-[1px]"
          >
            <Input
              readOnly
              type="text"
              label="Imagem"
              className="cursor-pointer border-none p-0"
              inputStyle="cursor-pointer"
              isDirty={!!dirtyFields.image}
              {...register('image')}
              value={image}
            />
            <ChevronRightIcon className="text-secondaryText" />
          </div>
          <span className="text-xs text-red-600">{errors.image?.message}</span>
        </div>
        {image && (
          <img
            src={getImageFromApi(image, 'exploded-view')}
            alt="Preview da imagem selecionada da vista explodida"
          />
        )}
        <div>
          {currentPartsGroupSelected?.id && (
            <>
              <h2 className="mb-4 text-zinc-500">Produtos vinculados</h2>
              {isFetchingProducts && <Loading heightNormal />}
              {!isFetchingProducts && (
                <>
                  <SerchInput
                    ref={inputRef}
                    onSearch={handleSearch}
                    onButtonClick={handleCancelSearch}
                    showCancelOptions={isSearchInputDirty}
                    onCancelSearch={handleCancelSearch}
                  />
                  <Table.Head rowStyle="px-0">
                    <div className="flex">
                      <Table.Cell
                        items={productsShown}
                        type="id"
                        onSort={handleSortTable}
                        isSortActive={sortField === 'id'}
                        className="w-[5.5rem]"
                      >
                        <span className="text-xs">Código</span>
                      </Table.Cell>
                      <Table.Cell
                        items={productsShown}
                        type="name"
                        onSort={handleSortTable}
                        isSortActive={sortField === 'name'}
                      >
                        <span className="text-xs">Nome</span>
                      </Table.Cell>
                    </div>
                  </Table.Head>

                  <Table.Root
                    itemType="Produtos"
                    itemsLength={productsLength}
                    paddingTop="0"
                    showNoContentMessage={false}
                  >
                    {productsShown.map((product) => (
                      <Table.Row key={product.id} className="px-0">
                        <div className="flex">
                          <Table.Cell
                            items={productsShown}
                            type="id"
                            className="w-[5.5rem]"
                          >
                            {product.id}
                          </Table.Cell>
                          <Table.Cell items={productsShown} type="name">
                            <span>{product.name}</span>
                          </Table.Cell>
                        </div>
                      </Table.Row>
                    ))}
                  </Table.Root>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <Modal
        isOpen={isSelectDescriptionModalOpen}
        onClose={handleCloseSelectDescriptionModal}
        width="50rem"
        contentStyle="h-[100vh]"
        iconContainerStyle={{top: '0'}}
      >
        <DescriptionContent
          service={descriptionService}
          handleTableRowClick={handleSelectDescription}
          isModal
          descriptionsInUse={[{ id: selectedDescriptionId }]}
          baseUrl={`${descriptionSearchByTypeIdBaseEndpoint}/3`}
        />
      </Modal>

      <Modal
        isOpen={isSelectImageModalOpen}
        onClose={handleCloseSelectImageModal}
        width="50rem"
        contentStyle="h-[100vh]"
      >
        <ImagesContent
          onTableRowClick={handleSelectImage}
          isModal
          imageType={ImageTypes.explodedView}
        />
      </Modal>

      <ConfirmModal
        contentMessage="Deseja excluir este conjunto de peças?"
        isModalOpen={isConfirmModalOpen}
        onCloseModal={handleCloseConfirmModal}
        onConfirm={handleConfirmDelete}
        onNotConfirm={handleCloseConfirmModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </Modal>
  );
}
