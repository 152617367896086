import SearchIcon from '@mui/icons-material/Search';
import { ComponentProps, forwardRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import CloseIcon from '@mui/icons-material/Close';

type SearchBarProps = ComponentProps<'div'> & {
  placeholderInput?: string;
  onClear?: () => void;
};

const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  (
    { placeholderInput = 'Encontre seu produto', className, onClear, ...rest },
    ref,
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    };

    const handleClear = () => {
      setSearchValue('');
      setIsFocused(false);
      if (ref && typeof ref !== 'function' && ref.current) {
        ref.current.value = '';
      }

      if (onClear) onClear();
    };

    return (
      <div
        className={twMerge(
          'box-border flex h-11 w-full items-center pr-2',
          className,
        )}
        {...rest}
      >
        <button className="absolute z-20 h-11 rounded-l-lg pl-[1.125rem] pr-2.5 text-zinc-50">
          <SearchIcon className="text-zinc-400" />
        </button>
        <div className="relative flex-grow">
          <input
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            type="text"
            name="query"
            ref={ref}
            autoComplete="off"
            placeholder={placeholderInput}
            className={`z-10 box-border h-12 w-full ${isFocused && searchValue ? 'rounded-b-none rounded-t-lg border-b-0' : 'rounded-lg'} border-2 border-[#d4d4d8] px-2.5 pl-12 outline-none`}
          />
          {searchValue && (
            <button
              onClick={handleClear}
              className="absolute right-3 top-[11.5px] z-20 text-zinc-400 hover:text-zinc-600"
            >
              <CloseIcon />
            </button>
          )}
        </div>
      </div>
    );
  },
);

export default SearchBar;
