import { FindAllPages } from '../types/userTypePages';
import { api } from './api';

export const getAllPagesByLoggedUser = async () => {
  const response = await api.get<FindAllPages>(
    `/user-type-pages/v1/pages/user`,
  );
  return response;
};

export const getAllPagesByUserTypeId = async (userTypeId: number) => {
  const response = await api.get<FindAllPages>(
    `/user-type-pages/v1/pages/user-type/${userTypeId}`,
  );
  return response;
};
