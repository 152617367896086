import React from 'react';
import { Box } from '@mui/material';
import { usePartCompatibilities } from './usePartCompatibilities';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TableHead from '../../components/Table/TableHead';
import TableCell from '../../components/Table/TableCell';
import TableRoot from '../../components/Table/TableRoot';
import { ClipLoader } from 'react-spinners';
import PdfButton from '../../components/PdfButton';
import SearchBar from '../../components/SearchBar';
import ProductDetails from '../../components/ProductDetails';

export default function PartCompatibilities() {
  const {
    inputRef,
    sortField,
    partsCompatibilitiesShown,
    partsGroupWidth,
    productExistsInDomain,
    isLoading,
    isPdfLoading,
    endOfListRef,
    canLoadMore,
    currentPart,
    openSearch,
    handleSearch,
    handleOpenSearch,
    // handleBackButtonClick,
    handleTableRowClick,
    handleSortTable,
    handleGeneratePdf,
  } = usePartCompatibilities();

  return (
    <>
      <Header
        menuIcon={false}
        // handleBackButtonClick={handleBackButtonClick}
        openSearch={openSearch}
        onCLickSearchIcon={handleOpenSearch}
        title="Compatibilidade"
        searchBar={false}
        productCatalog={true}
        productsDetail={
          <ProductDetails
            product={currentPart!}
            isMobile={true}
            pdfButton={
              <PdfButton
                onPdfClick={handleGeneratePdf}
                isPdfLoading={isPdfLoading}
                fontSize={1}
              />
            }
          />
        }
        searchParts={
          <div className="relative z-50 w-full">
            <SearchBar
              ref={inputRef}
              searchUseHandleTable={handleSearch}
              placeholderInput="Encontre sua peça"
            />
          </div>
        }
      />
      <main>
        <div className="relative mb-4 flex min-h-[calc(100vh-(224px+96px))] flex-col">
          <div className="medium sticky left-0 right-0 top-[5.5rem] z-10 flex h-[5.2rem] items-center justify-between gap-1 border-b border-slate-400 bg-white/90 p-2 shadow-sm shadow-black/50 backdrop-blur-sm sm:top-24 sm:hidden md:gap-4">
            <div className="flex flex-col items-end gap-4 rounded-md bg-white px-1">
              <div className="md:ml-4 lg:mr-48">
                <div className="mb-2 font-medium text-black md:text-2xl">
                  Compatibilidade
                  <span style={{ color: '#eab308', fontSize: '2.25rem' }}>
                    .
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start gap-4 md:flex-row md:items-center">
              <ProductDetails
                product={currentPart!}
                isMobile={true}
                pdfButton={
                  <PdfButton
                    onPdfClick={handleGeneratePdf}
                    isPdfLoading={isPdfLoading}
                    fontSize={1}
                  />
                }
              />
            </div>

            <div className="hidden w-[150px] md:block" />
          </div>

          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                marginTop: '1rem',
              }}
            >
              <ClipLoader color="#000" loading={isLoading} size={50} />
              <div className="ml-4 text-base font-semibold text-zinc-600">
                Carregando produtos compatíveis
              </div>
            </Box>
          ) : (
            <>
              {!isLoading && partsCompatibilitiesShown.length === 0 && (
                <Box
                  sx={{
                    color: 'red',
                    textAlign: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  O produto não existe no domínio.
                </Box>
              )}

              {productExistsInDomain && (
                <div className="overflow-x-none relative min-h-[calc(100vh-(224px+96px))]">
                  <div
                    data-isOpen={openSearch}
                    className="fixed left-0 right-0 top-[10.5rem] z-10 w-full bg-white/80 shadow-sm shadow-black/50 backdrop-blur-sm sm:top-[5.4rem] lg:top-[5.9rem]"
                  >
                    <TableHead rowStyle="px-2 gap-2 pl-4 sm:px-8 border-t-2">
                      <Box className="flex">
                        <TableCell
                          items={partsCompatibilitiesShown}
                          type="id"
                          onSort={handleSortTable}
                          isSortActive={sortField === 'id'}
                        >
                          Código
                        </TableCell>
                        <TableCell
                          items={partsCompatibilitiesShown}
                          type="name"
                          onSort={handleSortTable}
                          isSortActive={sortField === 'name'}
                        >
                          Nome
                        </TableCell>
                      </Box>
                      <TableCell
                        items={partsCompatibilitiesShown}
                        type="partGroupName"
                        onSort={handleSortTable}
                        isSortActive={sortField === 'partGroupName'}
                        sx={{
                          width: `${partsGroupWidth}ch`,
                          minWidth: '9rem',
                          p: 0,
                          borderBottom: 'none',
                          textAlign: 'center',
                          marginTop: '4px',
                        }}
                      >
                        Conjunto de peças
                      </TableCell>
                    </TableHead>
                  </div>
                  <TableRoot
                    itemsLength={partsCompatibilitiesShown.length}
                    itemType="Produtos"
                    className={`mt-9`}
                  >
                    {partsCompatibilitiesShown.map((product) => (
                      <div
                        key={`${product.id}${product.partId}${product.partGroupName}`}
                        onClick={() =>
                          handleTableRowClick(
                            product.id,
                            product.partId ?? '',
                            product.partGroupName ?? '',
                            product.name,
                          )
                        }
                        // className="gap-2 px-2 pl-4 sm:px-8"
                        className={`flex items-center justify-between border-b border-gray-300 px-8 py-2 hover:border-yellow-500`}
                      >
                        <Box className="flex">
                          <TableCell
                            items={partsCompatibilitiesShown}
                            type="id"
                          >
                            {product.id}
                          </TableCell>
                          <TableCell
                            items={partsCompatibilitiesShown}
                            type="name"
                            className=""
                          >
                            {product.name}
                          </TableCell>
                        </Box>
                        <TableCell
                          items={partsCompatibilitiesShown}
                          type="partGroupName"
                          sx={{
                            width: `${partsGroupWidth}ch`,
                            minWidth: '9rem',
                            p: 0,
                            borderBottom: 'none',
                          }}
                        >
                          {product.partGroupName}
                        </TableCell>
                      </div>
                    ))}
                    <div
                      ref={endOfListRef}
                      style={{
                        display:
                          partsCompatibilitiesShown.length > 0 && canLoadMore
                            ? 'block'
                            : 'none',
                      }}
                    ></div>
                  </TableRoot>
                </div>
              )}
            </>
          )}
        </div>
        <Footer />
      </main>
    </>
  );
}
