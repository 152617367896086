import { useMemo, useState } from 'react';
import MenuBurger from '../../components/MenuBurger';
import SideNavbar from '../../components/SideNavbar';
import { getAuth } from '../../utils/auth';
import { verifyScreenIsLessThan } from '../../utils/verifyScreenIsLessThan';
import ProductsCatalogFullContent from '../../components/ProductsCatalogFullContent';

export default function Home() {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const isMobile = verifyScreenIsLessThan(900);

  const auth = useMemo(() => getAuth(), []);

  const handleMenuClick = () => {
    setIsNavbarOpen((prev) => !prev);
  };

  const handleClickOutOfSideNavbar = () => {
    setIsNavbarOpen(false);
  };

  return (
    <div>
      {auth && !isMobile && (
        <>
          <MenuBurger
            isOpen={isNavbarOpen}
            onClick={handleMenuClick}
            className="ml-4 mt-[26px]"
            isDark={!isNavbarOpen}
          />
          <SideNavbar
            isOpen={isNavbarOpen}
            onClickOut={handleClickOutOfSideNavbar}
          />
        </>
      )}

      {!auth && <ProductsCatalogFullContent />}
    </div>
  );
}
