import { api } from './api';
import {
  Domain,
  FindAllDomainsPaginated,
  SaveDomainDto,
  UpdateDomainDto,
} from '../types/domain';
import { getCurrentDomain } from '../utils/domain';

export const getDomainsPaginated = async () => {
  const response = await api.get<FindAllDomainsPaginated>(`/domain/v1/search`);
  return response;
};

export const saveDomain = async ({
  name,
  imgHighlight,
  cep,
  city,
  neighborhood,
  number,
  state,
  street,
  uf,
  emailSignatureImage,
}: SaveDomainDto) => {
  const body = {
    name,
    imgHighlight,
    cep,
    city,
    neighborhood,
    number,
    state,
    street,
    uf,
    emailSignatureImage,
  };

  const response = await api.post<Domain>('/domain/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const updateDomain = async ({
  id,
  name,
  imgHighlight,
  cep,
  city,
  neighborhood,
  number,
  state,
  street,
  uf,
  emailSignatureImage,
  product
}: UpdateDomainDto) => {
  const body = {
    id,
    name,
    imgHighlight,
    cep,
    city,
    neighborhood,
    number,
    state,
    street,
    uf,
    emailSignatureImage,
    product
  };
  const response = await api.put<Domain>('/domain/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const deleteDomain = async (domainId: number) => {
  const response = await api.delete<Domain>(`/domain/v1/${domainId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const getDomain = async (
  domainId = getCurrentDomain().id, // domínio fixo como 1 (buffalo) por enquanto
) => {
  const response = await api.get<Domain>(`/domain/v1/${domainId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};
