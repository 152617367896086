import ListingHeader from '../ListingHeader';
import { useBarCodeListing } from './useBarCodeListing';
import SearchInput from '../SearchInput';
import Table from '../Table';
import Main from '../Main';
import Footer from '../Footer';
import Loading from '../Loading';
import LoadMore from '../LoadMore';
import Filter from '../Filter';
import { ProductBarCode } from '../../types/productBarCode';
import ConfirmModal from '../ConfirmModal';

type BarCodeListingProps = {
  totalItemsSelected: number;
  itemsRemovedFromList: ProductBarCode[];
  onTableRowClick: (productBarCode: ProductBarCode) => void;
};

export default function BarCodeListing({
  totalItemsSelected,
  itemsRemovedFromList,
  onTableRowClick,
}: BarCodeListingProps) {
  const {
    endPageRef,
    productsBarCodeShownLength,
    labelSize,
    inputRef,
    isFetchingProductsBarCode,
    isLoadMoreFetching,
    isSearchInputDirty,
    productBarCodeLength,
    productsBarCodeShown,
    sortField,
    isConfirmChangeFilterModalOpen,
    handleConfirmChangeFilter,
    handleCloseConfirmChangeFilterModal,
    handleEnterClickSearchInput,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
    handleFilter,
    handleSearchDatabase,
  } = useBarCodeListing(totalItemsSelected);

  return (
    <section className="relative col-span-2 h-screen">
      <ListingHeader pdf={false}>
        <div className="mt-4">
          <h1 className="mb-4 text-2xl">Código de barras</h1>
          <Filter
            filters={[
              {
                name: 'Pequena',
                filter: 'p',
                isCurrent: !labelSize || labelSize?.toLowerCase() === 'p',
              },
              {
                name: 'Média',
                filter: 'm',
                isCurrent: labelSize?.toLowerCase() === 'm',
              },
              {
                name: 'Grande',
                filter: 'g',
                isCurrent: labelSize?.toLowerCase() === 'g',
              },
              {
                name: 'Caixas dos corredores',
                filter: 'cc',
                isCurrent: labelSize?.toLowerCase() === 'cc',
              },
            ]}
            onFilter={handleFilter}
          />
          <SearchInput
            ref={inputRef}
            onSearch={handleSearch}
            onButtonClick={handleSearchDatabase}
            buttonContent="Buscar"
            showCancelOptions={isSearchInputDirty}
            onCancelSearch={handleCancelSearch}
            onKeyDown={handleEnterClickSearchInput}
          />
        </div>
        <Table.Head rowStyle="pr-[3.25rem]">
          <div className="flex w-full">
            <Table.Cell
              items={productsBarCodeShown}
              type="id"
              onSort={handleSortTable}
              isSortActive={sortField === 'id'}
              className="w-24"
            >
              Código
            </Table.Cell>
            <Table.Cell
              items={productsBarCodeShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
              className="flex-1"
            >
              Nome
            </Table.Cell>
            <Table.Cell
              items={productsBarCodeShown}
              type="saleInfo"
              onSort={handleSortTable}
              isSortActive={sortField === 'saleInfo'}
              className="flex-1"
            >
              Info. venda
            </Table.Cell>
            <Table.Cell
              items={productsBarCodeShown}
              type="unit"
              onSort={handleSortTable}
              isSortActive={sortField === 'unit'}
              className="flex-1"
            >
              Unidade
            </Table.Cell>
          </div>
        </Table.Head>
      </ListingHeader>

      <Main className="pb-0">
        {isFetchingProductsBarCode && <Loading loadMore />}
        {!isFetchingProductsBarCode && productsBarCodeShown && (
          <Table.Root
            itemType="Produtos ou peças"
            itemsLength={productsBarCodeShownLength}
            className="max-h-[calc(100vh-19rem)] overflow-y-auto"
          >
            {productsBarCodeShown.map((productBarCode) => {
              const sholdRender = itemsRemovedFromList.every(
                (itemRemoved) => itemRemoved.id !== productBarCode.id,
              );

              return (
                <div key={productBarCode.id}>
                  {sholdRender && (
                    <Table.Row onClick={() => onTableRowClick(productBarCode)}>
                      <div className="flex w-full">
                        <Table.Cell
                          items={productsBarCodeShown}
                          type="id"
                          className="w-24"
                        >
                          {productBarCode.id}
                        </Table.Cell>
                        <Table.Cell
                          items={productsBarCodeShown}
                          type="name"
                          className="flex-1"
                        >
                          {productBarCode.name}
                        </Table.Cell>
                        <Table.Cell
                          items={productsBarCodeShown}
                          type="saleInfo"
                          className="flex-1"
                        >
                          {productBarCode.saleInfo}
                        </Table.Cell>
                        <Table.Cell
                          items={productsBarCodeShown}
                          type="unit"
                          className="flex-1"
                        >
                          {productBarCode.unit}
                        </Table.Cell>
                      </div>
                    </Table.Row>
                  )}
                </div>
              );
            })}
            <LoadMore
              isModal={false}
              isLoadMoreFetching={isLoadMoreFetching}
              isContentFetching={isFetchingProductsBarCode}
              ref={endPageRef}
            />
          </Table.Root>
        )}
      </Main>
      <Footer
        current={productsBarCodeShownLength}
        total={productBarCodeLength}
        itemType="Peças ou produtos"
        className="absolute bottom-0 mr-8"
      />

      <ConfirmModal
        contentMessage={
          'Ao alterar o tamanho da etiqueta, sua pré seleção será perdida. Deseja continuar mesmo assim?'
        }
        isModalOpen={isConfirmChangeFilterModalOpen}
        onCloseModal={handleCloseConfirmChangeFilterModal}
        onNotConfirm={handleCloseConfirmChangeFilterModal}
        onConfirm={handleConfirmChangeFilter}
      />
    </section>
  );
}
