import LinkProductsPartsDescriptionModal from '../../../components/Admin/LinkProductsPartsDescriptionModal';
import ProductsPartsContent from '../../../components/ProductsPartsContent';
import { useLinkProductsPartsDescription } from './useLinkProductsPartsDescription';

export default function LinkProductsPartsDescription() {
  const {
    isMenageModalOpen,
    currentProductsPartsClicked,
    handleTableRowClick,
    handleCloseMenageModal,
  } = useLinkProductsPartsDescription();

  return (
    <>
      <ProductsPartsContent handleTableRowClick={handleTableRowClick} />

      <LinkProductsPartsDescriptionModal
        typeId={(currentProductsPartsClicked?.id ?? 0) < 50000 ? 1 : 2}
        productId={currentProductsPartsClicked?.id}
        productName={currentProductsPartsClicked?.name}
        isModalOpen={isMenageModalOpen}
        onModalClose={handleCloseMenageModal}
      />
    </>
  );
}
