import { useMutation, useQuery } from 'react-query';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { useTitle } from '../../../hooks/useTitle';
import { usersCache } from '../../../constants/requestCacheName';
import {
  FindUsersPaginated,
  SaveUser,
  UpdateUser,
  User,
  UsersFormData,
} from '../../../types/user';
import {
  deleteUser,
  getUsersPaginated,
  saveUser,
  updateUser,
} from '../../../services/user';
import formatSearch from '../../../utils/formatSearch';
import { useModal } from '../../../hooks/useModal';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useEdit } from '../../../hooks/useEdit';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { userSchema, usersSchemaEdit } from '../../../validations/schemas';
import { AxiosError } from 'axios';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { useNavigate } from 'react-router-dom';
import { useError } from '../../../hooks/useError';
import { getTableColumnWidthByBiggestElement } from '../../../utils/tableColumnStyles';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { Search, search as searchDatabase } from '../../../services/search';
import { userSearchBaseEndpoint } from '../../../constants/endpoints';
import { getAllUserTypes } from '../../../services/userType';
import { FindAllUserTypes } from '../../../types/userType';

export const useUsers = () => {
  useTitle('Usuários');

  const [typeUser] = useSearchParams('userType') as [string];

  const navigate = useNavigate();

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { data: usersType, isFetching: isFetchingUsers } =
    useQuery<FindAllUserTypes>(
      usersCache,
      async () => (await getAllUserTypes()).data,
      {
        retry: false,
        refetchOnWindowFocus: false,
      },
    );

  const { mutate: saveUserMutate } = useMutation({
    mutationFn: async ({ password, role, user, email }: SaveUser) => {
      return (await saveUser({ password, role, user, email })).data;
    },

    onSuccess: (data) => {
      addItemOnScreen<FindUsersPaginated>(usersCache, data);
      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar usuário',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Usuário já cadastrado');
            return true;
          }
        },
      );
    },
  });

  const { mutate: updateUserMutate } = useMutation({
    mutationFn: async ({ id, role, user, isActive, password, email }: UpdateUser) => {
      return (await updateUser({ id, role, user, isActive, password, email })).data;
    },

    onSuccess: (data) => {
      updateItemOnScreen<FindUsersPaginated>(usersCache, data);
      // handleCloseMenageModal();
      handleUnedit();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar usuário',
        setErrorMessage,
      );
    },
  });

  const { mutate: deleteUserMutate } = useMutation({
    mutationFn: async ({ userId }: Record<'userId', number>) => {
      return (await deleteUser({ userId })).data;
    },

    onSuccess: () => {
      removeItemFromScreen<FindUsersPaginated>(
        usersCache,
        userIdUpdate.current!,
      );
      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar usuário',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.statusCode === 409
          ) {
            setErrorMessage(
              'Este usuário só pode ser apagado depois que não houver associações a ele',
            );
            return true;
          }
        },
      );
    },
  });

  const userIdUpdate = useRef<number | null>(null);
  const currentUserTypes = useRef<Record<'id', number>[]>([]);

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
  } = useForm<UsersFormData>({
    resolver: zodResolver(userIdUpdate.current ? usersSchemaEdit : userSchema),
    defaultValues: {
      password: '',
      user: '',
      email: '',
      userType: 'Nenhuma',
      isActive: true,
      confirm_password: '',
    },
  });

  const password = watch('password');
  const user = watch('user');
  const userType = watch('userType');
  const isActive = watch('isActive');
  const confirmPassword = watch('confirm_password');
  const email = watch('email');

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return searchDatabase<User>(
        baseUrl,
        searchValue,
        page,
        null,
        null,
        typeUser ? [{ name: 'role', value: typeUser }] : undefined,
      );
    },
    [typeUser],
  );

  const loadMore = useCallback(
    async (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      return await getUsersPaginated(page, direction, limit, typeUser);
    },
    [typeUser],
  );

  const endPageRef = useRef<HTMLDivElement>(null);

  const {
    itemsShown,
    isSearchInputDirty,
    sortField,
    handleSearch: search,
    inputRef,
    currentPage,
    searchItems: userItems,
    pagination,
    // handleFilter: filter,
    handleChangePage,
    handleChangePagination,
    itemsShownLength: usersShownLength,
    handleCancelSearch,
    itemsLength: usersLength,
    isSearchImageFetching,
    isLoadMoreFetching,
    refetchSearch,
    handleSearchDatabase: handleSearchInAllUsers,
    handleSortTable,
  } = useHandleTable(
    0,
    [],
    null,
    null,
    userSearchBaseEndpoint,
    loadMore,
    null,
    doSearch as Search,
  );
  const usersShown: User[] = itemsShown;

  useEffect(() => {
    refetchSearch();
  }, [refetchSearch, typeUser]);

  // const handleFilter = useCallback(
  //   (filterName: string) => {
  //     navigate({ search: `f=${filterName}` }, { replace: true });
  //     filter(filterName);
  //   },
  //   [filter, navigate],
  // );

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: closeMenageModal,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const {
    isModalOpen: isUserRoleModalOpen,
    handleCloseModal: handleCloseUserRoleModal,
    handleOpenModal: handleOpenUserRoleModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: handleCloseConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  const userTypeColWidth = useMemo(
    () =>
      getTableColumnWidthByBiggestElement<User>(
        usersShown ?? [],
        'role.type',
        15,
      ),
    [usersShown],
  );

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const currentUserType = watch('userType');

  const handleCloseMenageModal = () => {
    userIdUpdate.current = null;
    reset();
    handleUnedit();
    closeMenageModal();
  };

  const handleSearch = () => {
    search<User>((item, formatedSearch) => {
      return (
        formatSearch(item.user?.toString()).includes(formatedSearch) ||
        formatSearch(item.role.type?.toString()).includes(formatedSearch)
      );
    });
  };

  const handleAddUsersClick = () => {
    handleOpenMenageModal();
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmDeleteModal();
  };

  const handleButtonOkClick = async ({
    password,
    user,
    email,
    userType,
    isActive,
  }: UsersFormData) => {
    if (userIdUpdate.current) {
      updateUserMutate({
        id: userIdUpdate.current,
        user,
        email,
        role: userType,
        isActive: !!isActive,
        password,
      });
      return;
    }
    saveUserMutate({ password, role: userType, user, email });
  };

  const handleEditButtonClick = () => {
    handleEdit();
  };

  const handleOpenUsersRole = () => {
    handleOpenUserRoleModal();
  };

  const handleSelectUserRole = (roleId: number, roleName: string) => {
    currentUserTypes.current = [{ id: roleId }];
    setValue('userType', roleName);
    setError('userType', {});
    handleCloseUserRoleModal();
  };

  const handleMainTableRowClick = (
    userId: number,
    user: string,
    email: string,
    userRoleId: number,
    userRole: string,
    isActive: boolean,
  ) => {
    userIdUpdate.current = userId;
    currentUserTypes.current = [{ id: userRoleId }];
    setValue('user', user);
    setValue('email', email);
    setValue('userType', userRole);
    setValue('isActive', isActive);
    handleOpenMenageModal();
  };

  const handleConfirmDeleteUser = () => {
    if (userIdUpdate.current) {
      deleteUserMutate({ userId: userIdUpdate.current });
    }
    handleCloseConfirmDeleteModal();
  };

  const handleClearField = (fieldName: keyof UsersFormData) => {
    setValue(fieldName, '', { shouldDirty: true });
  };

  const isShowEditButton = !!userIdUpdate.current && !canEdit;

  const currentParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  const handleFilterByTypeUser = useCallback(
    (filterName: string) => {
      if (filterName === 'todos') {
        currentParams.delete('userType');
        navigate({ search: currentParams.toString() }, { replace: true });
        return;
      }
      currentParams.set('userType', filterName.toString());
      navigate({ search: currentParams.toString() }, { replace: true });
    },
    [currentParams, navigate],
  );

  return {
    searchItems: userItems,
    confirmPassword,
    isActive,
    isLoadMoreFetching,
    endPageRef,
    userType,
    user,
    password,
    email,
    isFetchingUsers,
    isSearchInputDirty,
    inputRef,
    sortField,
    usersShown,
    usersType,
    isSearchImageFetching,
    usersShownLength,
    currentPage,
    pagination,
    isMenageModalOpen,
    userIdUpdate: userIdUpdate.current,
    isShowEditButton,
    isConfirmDeleteModalOpen,
    isUserRoleModalOpen,
    errors,
    dirtyFields,
    currentUserType,
    usersLength,
    errorMessage,
    userTypeColWidth,
    typeUser,
    currentUserTypes: currentUserTypes.current,
    handleFilterByTypeUser,
    clearError,
    handleClearField,
    handleConfirmDeleteUser,
    handleMainTableRowClick,
    register,
    handleSubmit,
    handleSelectUserRole,
    handleCloseUserRoleModal,
    handleOpenUsersRole,
    handleEditButtonClick,
    handleButtonOkClick,
    handleDeleteButtonClick,
    handleCloseConfirmDeleteModal,
    handleAddUsersClick,
    handleCloseMenageModal,
    handleChangePage,
    handleChangePagination,
    handleSearch,
    handleCancelSearch,
    handleSortTable,
    handleSearchInAllUsers,
  };
};
