import { useModal } from '../../../hooks/useModal';
import { useTitle } from '../../../hooks/useTitle';
import { ContactFormData } from '../../../types/contactUs';
import { useEdit } from '../../../hooks/useEdit';
import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ContactStatus } from '../../../enums/contactStatus';
import { useContactFetch } from './useContactFetch';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import { getCurrentDomain } from '../../../utils/domain';

export const useContact = () => {
  useTitle('Fale Conosco');

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: handleClose,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmModalOpen,
    handleCloseModal: handleCloseConfirmModal,
    handleOpenModal: handleOpenConfirmModal,
  } = useModal();

  const {
    isModalOpen: isSelectModalOpen,
    handleCloseModal: handleCloseSelectModal,
    handleOpenModal: handleOpenSelectModal,
  } = useModal();

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const isUneditable = canEdit;

  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content: '',
    editorProps: {
      // editable: () => isUneditable,
      attributes: {
        class: 'border p-2 outline-none flex-1 min-h-80',
      },
    },
  });

  const [errorMensageResposta, setErrorMensageResposta] = useState('');

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    watch,
  } = useForm<ContactFormData>({
    // resolver: zodResolver(contactSchema),
    defaultValues: {
      empresa: '',
      mensagem: '',
      status: ContactStatus.PENDENTE,
      resposta: '',
    },
  });

  const message = watch('mensagem');

  const currentContactId = useRef<number | null>(null);
  const currentStatus = useRef<ContactStatus>(ContactStatus.PENDENTE);

  const handleCloseMenageModal = useCallback(() => {
    currentContactId.current = null;
    reset();

    handleUnedit();
    handleClose();
  }, [handleClose, handleUnedit, reset]);

  const { errorMessage, clearError, update, archiveContactMutate } =
    useContactFetch(
      currentContactId.current,
      handleCloseMenageModal,
      handleCloseMenageModal,
      handleCloseConfirmModal,
    );

  function formatDateTime(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  const handleTableRowClick = (
    contactId: number,
    contactEmpresa: string,
    mensagem: string,
    status: ContactStatus,
    resposta: string,
    dtCriacao: Date,
    dtConclusao: Date,
    email: string,
    nome: string,
  ) => {
    currentStatus.current = status;
    currentContactId.current = contactId;
    setValue('empresa', contactEmpresa);
    setValue('mensagem', mensagem);
    setValue('status', status);
    setValue('resposta', resposta);
    setValue('dtCriacao', formatDateTime(new Date(dtCriacao)));
    setValue('dtConclusao', formatDateTime(new Date(dtConclusao)));
    setValue('email', email);
    setValue('nome', nome);

    if (editor) {
      editor.commands.setContent(resposta);
    }

    handleOpenMenageModal();
  };

  const handleArchiveButtonClick = () => {
    handleOpenConfirmModal();
  };

  const handleConfirmArchive = () => {
    archiveContactMutate();
  };

  const handleButtonOkClick = ({ status }: ContactFormData) => {
    const resposta = editor?.getHTML() ?? '';
    if (resposta === '<p></p>') {
      setErrorMensageResposta('A resposta não pode ser vazia!');
      return;
    }

    setErrorMensageResposta('');
    if (currentContactId.current) {
      update({
        id: currentContactId.current,
        resposta: resposta,
        status: status,
        domainId: getCurrentDomain().id, //FIXO DOMÍNIO BUFFALO POR ENQUANTO
      });
      currentContactId.current = null;
      setValue('resposta', '');

      return;
    }
  };

  const handleClearAnswer = () => {
    setValue('resposta', '', { shouldDirty: true });
    setError('resposta', {});
  };

  const isShowAnswerButton = !!currentContactId.current && !canEdit;

  return {
    isMenageModalOpen,
    currentContactId: currentContactId.current,
    isConfirmModalOpen,
    isShowAnswerButton,
    contactStatus: currentStatus.current,
    errors,
    dirtyFields,
    isSelectModalOpen,
    message,
    errorMessage,
    isUneditable,
    editor,
    errorMensageResposta,
    handleCloseSelectModal,
    handleOpenSelectModal,
    handleClearAnswer,
    handleConfirmArchive,
    handleCloseConfirmModal,
    handleEdit,
    handleButtonOkClick,
    register,
    handleSubmit,
    handleArchiveButtonClick,
    handleCloseMenageModal,
    handleTableRowClick,
    clearError,
  };
};
