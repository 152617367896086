import { format } from 'date-fns';
import { Content, ContextPageSize } from 'pdfmake/interfaces';

const commonFooter = (
  currentPage: number,
  totalPages: number,
  context: ContextPageSize,
  showInFirstPage = false,
): Content => {
  const currentDate = new Date();
  const formatedDate = format(currentDate, 'dd/MM/yyyy');

  return [
    {
      text: currentPage > 1 || showInFirstPage ? formatedDate : '',
      fontSize: 10,
      marginLeft: 16,
    },
    {
      text:
        currentPage > 1 || showInFirstPage
          ? `Página ${currentPage} de ${totalPages}`
          : '',
      fontSize: 10,
      alignment: 'right',
      relativePosition: {
        x: -16,
        y: -12,
      },
    },
  ];
};

export default commonFooter;
