import { useQuery } from 'react-query';
import { ErrorDuimp, FindAllErrorsDuimp } from '../../types/errorDuimp';
import { errorsDuimpCache } from '../../constants/requestCacheName';
import { useHandleTable } from '../../hooks/useHandleTable';
import { getAllErrorsFromDuimp } from '../../services/duimp';
import { useError } from '../../hooks/useError';
import { useNavigate } from 'react-router-dom';

export const useErrorsDuimpContent = () => {
  const navigate = useNavigate();

  const { data: errorsListDuimp, isFetching: isErrorDuimpFetching } =
    useQuery<FindAllErrorsDuimp>(
      errorsDuimpCache,
      async () => (await getAllErrorsFromDuimp()).data,
      {
        refetchOnWindowFocus: false,
        retry: false,
      },
    );

  const {
    itemsShown,
    itemsShownLength: errorsListDuimpShownLength,
    itemsLength: errorsListDuimpLength,
  } = useHandleTable(errorsListDuimp?.length ?? 0, errorsListDuimp ?? []);
  const errorsListDuimpShown: ErrorDuimp[] = itemsShown;

  const isFetching = isErrorDuimpFetching;

  const { errorMessage, clearError } = useError();

  return {
    clearError,
    navigate,
    errorMessage,
    isFetching,
    errorsListDuimp,
    errorsListDuimpLength,
    errorsListDuimpShown,
    errorsListDuimpShownLength,
  };
};
