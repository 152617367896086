import { Card, CardContent, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
  cardContentStyle,
  cardLinksContainerStyle,
  cardSearchLinksStyle,
  linkContainerStyle,
  menuCardStyle,
} from './styles';
import { useMenuCard } from './useMenuCard';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import SearchBar from '../SearchBar';
import { AutoComplete } from '../AutoComplete';
import BreadCrumb from '../BreadCrumb';

export default function MenuCard() {
  const {
    auth,
    currentLineCatalogUrl,
    currentLine,
    inputRef,
    handleSearchInputChange,
    handleClearSearchAndSetFocus,
    productsSearch,
    handleClickOutAutoComplete,
    selectedIndex,
    handleProductSearchClick,
    handlePdfCatalogButtonClick,
  } = useMenuCard();

  return (
    <Card sx={menuCardStyle}>
      <CardContent sx={cardContentStyle}>
        {auth && (
          <Link to="/login" className="mb-4 text-center">
            <PersonOutlineIcon />
            <span className="ml-2 text-sm text-primary">{auth.user.user}</span>
          </Link>
        )}
        <h2 className="mb-8 text-lg">
          Bem vindo ao catálogo de produtos da Buffalo.
        </h2>
        <p className="text-sm text-zinc-400">
          Selecione uma <span className="text-secondary">linha</span> e uma{' '}
          <span className="text-primary">categoria</span> nas barras acima para
          carregar os produtos desejados, ou pesquise uma peça ou produto nos
          botões abaixo
        </p>
        <Box sx={cardLinksContainerStyle}>
          <Box component="div" sx={cardSearchLinksStyle}>
            <Box sx={linkContainerStyle}>
              <SearchIcon />
              <Link to="/parts">
                <span className="text-primary hover:underline">Peças</span>
              </Link>
            </Box>
            <Box sx={linkContainerStyle}>
              <SearchIcon />
              <Link to="/products">
                <span className="text-primary hover:underline">Produtos</span>
              </Link>
            </Box>
          </Box>
          {currentLineCatalogUrl && (
            <div className="text-center">
              <button
                onClick={handlePdfCatalogButtonClick}
                className="cursor-pointer text-primary disabled:cursor-default disabled:opacity-60"
              >
                <DownloadOutlinedIcon className="text-black" /> Catálogo de
                peças da linha {currentLine}
              </button>
            </div>
          )}
          <div>
            <SearchBar
              className="w-full pr-2"
              ref={inputRef}
              onClick={handleSearchInputChange}
              onChange={handleSearchInputChange}
              onClear={handleClearSearchAndSetFocus}
            />
            {productsSearch && (
              <AutoComplete.Root
                items={productsSearch.items}
                onOutClick={handleClickOutAutoComplete}
                className="absolute w-[22.25rem] border-2 border-t-0"
              >
                {productsSearch.items.map((productLineCategory, index) => {
                  const isSelected = index === selectedIndex;

                  return (
                    <AutoComplete.Item
                      key={`${productLineCategory.product.id}${productLineCategory.line.id}${productLineCategory.category.id}`}
                      onClick={() =>
                        handleProductSearchClick(productLineCategory)
                      }
                      className={`p-2 ${isSelected ? 'bg-zinc-300' : ''}`}
                      ref={(el) => {
                        if (isSelected && el) {
                          el.scrollIntoView({ block: 'nearest' });
                        }
                      }}
                    >
                      <div>
                        {productLineCategory.product.id} -{' '}
                        {productLineCategory.product.name}
                        <BreadCrumb
                          items={[
                            productLineCategory.line.name,
                            productLineCategory.category.name,
                          ]}
                        />
                      </div>
                    </AutoComplete.Item>
                  );
                })}
              </AutoComplete.Root>
            )}
          </div>
        </Box>
      </CardContent>
    </Card>
  );
}
