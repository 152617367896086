import { useRef } from 'react';

type EditButtonProps<List> = {
  canEdit: boolean;
  editMessage: string;
  finishMessage: string;
  contentList: List[];
  disable?: boolean;
  cancelMessage: string;
  setContentList: (content: any[]) => void;
  onEditButtonClick: () => void;
  onCancelEditClick: () => void;
};

export default function EditButton<List>({
  finishMessage,
  contentList,
  canEdit,
  editMessage,
  disable,
  cancelMessage,
  setContentList,
  onCancelEditClick,
  onEditButtonClick,
}: EditButtonProps<List>) {
  const initialContent = useRef<List[]>([]);

  const handleEditButtonClick = () => {
    // Faz uma cópia profunda, não mantendo realação de contentList com initialContent.current
    initialContent.current = JSON.parse(JSON.stringify(contentList));
    onEditButtonClick();
  };

  const handleCancelButtonClick = () => {
    setContentList([...initialContent.current]);
    onCancelEditClick();
    // initialContent.current = [];
  };

  return (
    <div className="flex w-fit items-center gap-2">
      <button
        onClick={handleEditButtonClick}
        className="text-sm text-primary disabled:opacity-50"
        // style={{
        //   pointerEvents: disable  || !disable ? 'none' : 'auto',
        //   opacity: disable || !disable  ? 0.5 : 1,
        // }}
        disabled={disable}
      >
        {canEdit ? finishMessage : editMessage}
      </button>

      {canEdit && (
        <>
          <span>|</span>
          <button
            onClick={handleCancelButtonClick}
            className="text-sm text-errorText"
          >
            {cancelMessage}
          </button>
        </>
      )}
    </div>
  );
}
