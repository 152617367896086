import { Content } from 'pdfmake/interfaces';
import { ProductFilters } from '../types/productFilters';
import { getImageFromApi } from '../utils/getImageUrl';
import { urlToPureSvg } from '../utils/urlToPureSvg';

const listingCommonHeader = async (
  title: string,
  search = '',
  image?: Record<'id' | 'name', string> | null,
  currentFilter?: ProductFilters,
  item?: string,
): Promise<Content> => {
  const logoSvg = await urlToPureSvg(getImageFromApi('logo-buffalo', 'utils'));
  const searchSvg = await urlToPureSvg(getImageFromApi('search', 'utils'));

  return [
    {
      columns: [
        { text: title, fontSize: 16, marginTop: 12, alignment: 'right', width: 'auto' },
        {
          text: '.',
          color: '#eab308',
          alignment: 'left',
          fontSize: 25,
          marginTop: 4,
        },
        image
          ? [
              {
                columns: [
                  item
                    ? {
                        svg: item,
                        fit: [50, 50],
                        alignment: 'right',
                      }
                    : [],
                  [
                    {
                      text: image.name,
                      margin: [8, 8, 0, 0],
                      fontSize: 10,
                      alignment: 'left',
                    },
                    {
                      text: image.id,
                      margin: [8, 4, 0, 8],
                      fontSize: 10,
                      color: '#bdbdbd',
                      alignment: 'left',
                    },
                  ],
                ],
                alignment: 'center',
                absolutePosition: { y: 16, x: -120 },
              },
            ]
          : '',
        currentFilter
          ? {
              text:
                currentFilter.charAt(0).toUpperCase() + currentFilter.slice(1),
              relativePosition: {
                x: currentFilter === 'Fora de linha' ? -64 : -48,
                y: 12,
              },
              fontSize: 14,
            }
          : [],
        {
          svg: logoSvg!,
          width: 120,
          height: 40,
          absolutePosition: { x: 440, y: 16 },
        },
      ],
      margin: [32, 16],
    },
    {
      canvas: [
        {
          type: 'rect',
          h: 32,
          w: 532,
          x: 32,
          y: image ? 16 : 8,
          color: '#e0e0e0',
          r: 5,
        },
      ],
    },
    {
      svg: searchSvg!,
      fit: [15, 15],
      absolutePosition: { x: 48, y: image ? 90 : 82 },
    },
    { text: search, absolutePosition: { x: 76, y: image ? 88 : 80 } },
  ];
};

export default listingCommonHeader;
