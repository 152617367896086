import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTheme } from '@mui/material';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { useTitle } from '../../../hooks/useTitle';
import { useNavigate } from 'react-router-dom';
import { fetchProdutos } from '../../services/produtosService';
import { ProductInPageSort } from '../../../types/products';
import { Category } from '../../types/linesAndCategories';
import {
  linesWithCategoriesCache,
  liveSearchProductBuffaloCache,
} from '../../../constants/requestCacheName';
import { useQuery } from 'react-query';
import { getProductLineCategoriesDomainLiveSearchPaginated } from '../../../services/productLineCategoryDomain';
import {
  ProductsLineCategoryDomainLiveSearch,
  ProductsLineCategoryDomainLiveSearchPaginated,
} from '../../../types/productLineCategoryDomain';
import { useLiveSearch } from '../../hooks/useLiveSearch';
import { getCurrentDomain } from '../../../utils/domain';
import { getAllLinesWithCategories } from '../../../services/lines';
import {
  FindAllLinesWithCategory,
  LineWithCategory,
} from '../../../types/lines';
import { usePartsCatalogStore } from '../../../store/partsCatalog';

export const useProductsBuffalo = () => {
  useTitle('Produtos');

  const domainId = getCurrentDomain().id;
  const [selectedLine, setSelectedLine] = useState('');
  const [selectedCurrentCategory, setSelectedCurrentCategory] = useState('');

  const isLiveSearch = useRef(false);

  const { data: productsSearch, refetch: refetchProductsSearch } =
    useQuery<ProductsLineCategoryDomainLiveSearchPaginated>(
      liveSearchProductBuffaloCache,
      async () =>
        (
          await getProductLineCategoriesDomainLiveSearchPaginated(
            inputRef.current?.value ?? null,
            getCurrentDomain().id,
          )
        ).data,
      {
        retry: false,
        enabled: false,
        refetchOnWindowFocus: false,
      },
    );

  const { data: linesWithCategories } = useQuery<FindAllLinesWithCategory>(
    linesWithCategoriesCache,
    async () => (await getAllLinesWithCategories(domainId)).data,
    {
      retry: false,
      // enabled: false,
      refetchOnWindowFocus: false,
    },
  );

  const lines = useMemo(
    () => linesWithCategories?.items.map(({ id, name }) => ({ id, name })),
    [linesWithCategories?.items],
  );

  const categories = useMemo(
    () => linesWithCategories?.items.flatMap(({ categories }) => categories),
    [linesWithCategories?.items],
  );

  const {
    inputRef,
    handleClickOutAutoComplete,
    handleSearchInputChange,
    handleClearSearchCache,
    handleClearSearchAndSetFocus,
  } = useLiveSearch({ refetch: refetchProductsSearch });

  const handleProductSearchClick = async ({
    product,
    category,
    line,
  }: ProductsLineCategoryDomainLiveSearch) => {
    handleClearSearchCache();
    navigate(
      `/produto/${product.id}/${encodeURIComponent(product.name)}/categoria/${category.id}/linha/${line.id}`,
    );
  };

  const theme = useTheme();
  const navigate = useNavigate();
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);
  const [showCurrentLineAndCategory, setshowCurrentLineAndCategory] =
    useState(true);

  const [lineId, category] = useSearchParams('line', 'category');

  const currentLineId = useRef(lineId ? +lineId : 1);
  const currentCategoryId = useRef<number | null>(null);

  const endOfListRef = useRef<HTMLDivElement>(null);

  const currentPage = useRef<number>(1);
  const [productsInPage, setProductsInPage] = useState<ProductInPageSort[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  // const [lines, setLines] = useState<Line[]>([]);
  // const [categories, setCategories] = useState<Category[]>([]);

  const currentLine = useMemo<LineWithCategory | null>(() => {
    const currentLine = linesWithCategories?.items.find(
      ({ id }) => id === Number(lineId),
    );

    return currentLine ?? null;
  }, [lineId, linesWithCategories?.items]);

  useEffect(() => {
    if (lineId) {
      currentLineId.current = +lineId;
      return;
    }
    currentLineId.current = 1;
  }, [lineId]);

  useEffect(() => {
    const currentCategory = linesWithCategories?.items
      .flatMap(({ categories, id }) =>
        categories?.map((cat) => ({ ...cat, lineId: id })),
      )
      .find((cat) => cat?.name === category);

    currentCategoryId.current = currentCategory?.id ?? null;
    if (currentCategory) {
      setCurrentCategory(currentCategory);
      setSelectedCurrentCategory(currentCategory.name);
    }
  }, [category, linesWithCategories?.items]);

  // useEffect(() => {
  //   const fetchLinhasECategorias = async () => {
  //     try {
  //       const data = await fetchLinhasCategorias(
  //         getCurrentDomain().id.toString(),
  //       );
  //       setLines(data.lines);
  //       // setCategories(data.lines.flatMap((line: Line) => line.categories));
  //     } catch (error) {
  //       console.error('Erro ao buscar linhas e categorias:', error);
  //     }
  //   };

  //   fetchLinhasECategorias();
  // }, []);

  const fetchAndSetProducts = useCallback(async () => {
    setIsFetching(true);
    setIsLoadingMore(true);

    try {
      const productsData = await fetchProdutos(
        String(currentLineId.current),
        currentPage.current,
        20,
        currentCategoryId.current
          ? String(currentCategoryId.current)
          : undefined,
      );

      if (Array.isArray(productsData.list)) {
        setProductsInPage((prevProducts) => {
          const combinedProducts = [...prevProducts, ...productsData.list];
          const uniqueCombinedProducts = Array.from(
            new Map(
              combinedProducts.map((product) => [product.idProduto, product]),
            ).values(),
          );

          return uniqueCombinedProducts.map((product: any) => ({
            ...product,
            name: product.produto,
          }));
        });

        setCanLoadMore(productsData.list.length > 0);
      } else {
        console.error('Estrutura inesperada de productsData:', productsData);
      }
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
    } finally {
      setIsFetching(false);
      setIsLoadingMore(false);
    }
  }, []);

  const handleFetchCategories = useCallback(
    async (lineId: number) => {
      if (lineId === currentLineId.current && !currentCategoryId.current)
        return;

      setProductsInPage([]);
      isLiveSearch.current = false;
      currentLineId.current = Number(lineId);
      currentCategoryId.current = null;
      setCurrentCategory(null);
      currentPage.current = 1;
      setProductsInPage([]);
      // setCategories([]);
      // try {
      //   const data = await fetchLinhasCategorias(
      //     getCurrentDomain().id.toString(),
      //   );
      //   const newCategories =
      //     data.lines.find((line: Line) => line.id === lineId)?.categories || [];
      //   setCategories(newCategories);
      // } catch (error) {
      //   console.error('Erro ao buscar categorias:', error);
      // }
      fetchAndSetProducts();
    },
    [fetchAndSetProducts],
  );

  useEffect(() => {
    const lines = linesWithCategories?.items ?? [];

    if (!lineId && lines.length > 0) {
      currentLineId.current = Number(lines[0].id);
      navigate({ search: `line=${lines[0].id}` });
    }

    if (lines.length > 0 && !isLiveSearch.current) {
      fetchAndSetProducts();
    }
    // useEffect(() => {
    //   if (!lineId && lines.length > 0) {
    //     currentLineId.current = Number(lines[0].id);
    //     navigate({ search: `line=${lines[0].id}` });
    //   }

    //   if (lines.length > 0 && !isLiveSearch.current) {
    //     fetchAndSetProducts();
    //   }
  }, [lineId, fetchAndSetProducts, navigate, linesWithCategories?.items]);

  const handleFetchProducts = async (categoryId: number) => {
    const currentIsLiveSearch = isLiveSearch.current;
    isLiveSearch.current = false;

    if (categoryId !== currentCategoryId.current || currentIsLiveSearch) {
      currentCategoryId.current = categoryId;
      currentPage.current = 1;
      setProductsInPage([]);
      fetchAndSetProducts();
      return;
    }

    if (categoryId === currentCategoryId.current) {
      currentCategoryId.current = null;
      currentPage.current = 1;
      setProductsInPage([]);
      fetchAndSetProducts();
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      params.delete('category');
      navigate({
        search: params.toString(),
      });
      setCurrentCategory(null);
    }
  };

  const handleLoadMore = useCallback(() => {
    if (canLoadMore && !isLiveSearch.current) {
      currentPage.current++;
      fetchAndSetProducts();
    }
  }, [canLoadMore, fetchAndSetProducts]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        handleLoadMore();
      }
    });

    if (endOfListRef.current) {
      intersectionObserver.observe(endOfListRef.current);
    }

    return () => {
      if (intersectionObserver) {
        intersectionObserver.disconnect();
      }
    };
  }, [handleLoadMore]);

  const handleHoverCurrentLineAndCategory = () => {
    setshowCurrentLineAndCategory(false);
  };

  const filteredCategories = useMemo(() => {
    if (!lineId) return [];

    return (
      linesWithCategories?.items.find((line) => line.id.toString() === lineId)
        ?.categories || []
    );
  }, [lineId, linesWithCategories?.items]);

  const handleSelectLine = (value: string) => {
    const lines = linesWithCategories?.items.find(
      (line) => line.name === value,
    );

    navigate({
      search: `line=${lines?.id}`,
    });

    if (lines) {
      handleFetchCategories(lines?.id);
    }

    setSelectedLine(value);
    setSelectedCurrentCategory('');
  };

  const handleSelectCategory = (value: string) => {
    const selectedCategoryName = value;

    const selectedCategory = filteredCategories.find(
      (category) => category.name === selectedCategoryName,
    );

    if (selectedCategoryName) {
      navigate({
        search: `line=${lineId}&category=${selectedCategory?.name}`,
      });
    }
    if(selectedCategoryName === selectedCurrentCategory) {
      setSelectedCurrentCategory('');
      handleFetchCategories(+lineId!);
      navigate({
        search: `line=${lineId}`,
      });
      return
    }

    if (selectedCategory) {
      handleFetchProducts(selectedCategory.id);
    }


    setSelectedCurrentCategory(selectedCategoryName);
  };

  useEffect(() => {
    const lines = linesWithCategories?.items.find(
      (line) => line.id === +lineId!,
    );

    const selectedCategory = filteredCategories.find(
      (categorys) => categorys.name === category,
    );
    if (lines) {
      setSelectedLine(lines.name);
    }

    if (selectedCategory) {
      setSelectedCurrentCategory(selectedCategory.name);
    }
  }, [lineId, category, linesWithCategories?.items, filteredCategories]);

  const [openSearch, setOpenSearch] = useState(false);

  const handleOpenSearch = () => {
    setOpenSearch((prev) => !prev);
  };

  useEffect(() => {
    const updateSearchState = () => {
      if (window.innerWidth >= 640) {
        setOpenSearch(true); // Sempre aberto em telas sm ou maiores
      }
    };

    // Executa a verificação inicial
    updateSearchState();

    // Adiciona um listener para quando a tela for redimensionada
    window.addEventListener('resize', updateSearchState);

    return () => {
      window.removeEventListener('resize', updateSearchState);
    };
  }, []);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!productsSearch || productsSearch.items.length === 0) return;

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        setSelectedIndex((prev) =>
          Math.min(prev + 1, productsSearch.items.length - 1),
        );
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        setSelectedIndex((prev) => Math.max(prev - 1, 0));
      }

      if (event.key === 'Enter' && selectedIndex !== -1) {
        event.preventDefault();
        handleProductSearchClick(productsSearch.items[selectedIndex]);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [productsSearch, selectedIndex]);

  return {
    openSearch,
    theme,
    products: { items: productsInPage },
    inputRef,
    productsSearch,
    linesWithCategories,
    lineId,
    currentCategory,
    showCurrentLineAndCategory,
    currentLine,
    lines: lines ?? [],
    categories: categories ?? [],
    setshowCurrentLineAndCategory,
    handleHoverCurrentLineAndCategory,
    handleProductSearchClick,
    handleClickOutAutoComplete,
    handleFetchCategories,
    handleFetchProducts,
    handleSearchInputChange,
    navigate,
    endOfListRef,
    isFetching,
    productsInPage,
    canLoadMore,
    isLoadingMore,
    selectedLine,
    handleSelectLine,
    selectedCategory: selectedCurrentCategory,
    handleSelectCategory,
    filteredCategories,
    handleOpenSearch,
    handleClearSearchAndSetFocus,
    selectedIndex,
  };
};
