import Footer from '../../components/Footer';
import Header from '../../components/Header';
import BackgroundDimensionamento from '../../assets/images/backgrounds/BackgroundDimensionamento.jpg';
import { useDimensionamento } from './useDimensionamento';
import Table from '../../components/Table';
import { DownloadIcon } from 'lucide-react';

export default function Dimensionamento() {
  const { sizingsShown, handleGeneratePdf } = useDimensionamento();

  return (
    <>
      <Header />
      <div className="flex flex-col">
        <main className="h-screen">
          <img src={BackgroundDimensionamento} alt="" className="h-60 w-full" />
          <div className="mb-6 mt-6 max-h-[calc(100vh-19rem)] overflow-y-auto">
            <div className="mx-4 mt-2 rounded-md border-2 border-zinc-200 p-6 md:mx-11">
              {sizingsShown &&
                sizingsShown?.items.map((sizing) => {
                  <Table.Head>
                    <Table.Cell
                      items={sizingsShown.items}
                      type="name"
                      className="flex-1"
                    >
                      Cálculo de Dimensionamento
                    </Table.Cell>
                    <Table.Cell items={sizingsShown.items} type="url">
                      Arquivo
                    </Table.Cell>
                  </Table.Head>;
                  return (
                    <Table.Row key={`${sizing.id}-${sizing.name}`}>
                      <Table.Cell items={sizingsShown.items} type="name">
                        {sizing.name}
                      </Table.Cell>
                      <button
                        onClick={() => handleGeneratePdf(sizing.url)}
                        className="rounded-md border-2 bg-zinc-200 px-2 text-black hover:bg-slate-100"
                      >
                        <DownloadIcon className="text-blue-500" />
                      </button>
                    </Table.Row>
                  );
                })}
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}
