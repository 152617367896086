import {
  CreateImageExtensionDto,
  FindAllImageExtensions,
  ImageExtension,
  UpdateImageExtensionDto,
} from '../types/imageExtension';
import { api } from './api';

export const getAllImageExtensions = async () => {
  const response = await api.get<FindAllImageExtensions>(
    '/image-extension/v1/search',
  );
  return response;
};

export const saveImageExtension = async ({
  name,
  extension,
  fileType,
}: CreateImageExtensionDto) => {
  const body: CreateImageExtensionDto = {
    name,
    extension,
    fileType,
  };

  const response = await api.post<ImageExtension>('/image-extension/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const updateImageExtension = async ({
  id,
  extension,
  name,
  fileType,
}: UpdateImageExtensionDto) => {
  const body: UpdateImageExtensionDto = {
    id,
    extension,
    name,
    fileType,
  };

  const response = await api.put<ImageExtension>('/image-extension/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const deleteImageExtension = async (imageExtensionId: number) => {
  const response = await api.delete<void>(
    `/image-extension/v1/${imageExtensionId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return response;
};
