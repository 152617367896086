type BarCodeIconProps = {
  isLight?: boolean;
};

export default function BarCodeIcon({ isLight }: BarCodeIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <image
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAe0lEQVQ4T+2U0Q2AMAhEXzdxlDqJjqKb1El0FEcxVyEx/plQv8oPOQoXSjgSwZaC+WhGOAMHMAGbeW9eeHj95AS8Rk/CGSjeocgWYAdG884hrOSnKV+5qwW9PnfCPsN7J/ra1DH8rxQXunwx4bt2hT8fh7Cz2OwehnV4AYXhYhVxnSRhAAAAAElFTkSuQmCC"
        height="1.25rem"
        style={{ filter: isLight ? 'invert(1) brightness(2)' : 'initial' }}
      />
    </svg>
  );
}
