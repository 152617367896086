import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type BreadCrumbProps = {
  items: string[];
};

export default function BreadCrumb({ items }: BreadCrumbProps) {
  return (
    <div className="flex items-center text-xs">
      {items.map((item, index) => (
        <div key={item} className="flex items-center">
          <span>{item}</span>
          {!(index === items.length - 1) && (
            <ChevronRightIcon className="!text-lg text-zinc-600" />
          )}
        </div>
      ))}
    </div>
  );
}
