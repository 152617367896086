import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';

export const explodedViewContainerStyle: SxProps<Theme> = {
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  // paddingRight: '3rem',
  '@media (max-width: 800px)': {
    order: 1,

  },
};
