
import HTMLFlipBook from 'react-pageflip';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import { useBookPdf } from './useBookPdf';
import './pdfBook.css';

// const J = HTMLFlipBook as any

export default function PdfToImage() {

  const {
    ref,
    isMobile,
    div,
    isDesktop,
    inputKey,
    totalPages,
    pages,
    inputPage,
    handlePageFlip,
    prevPage,
    nextPage,
    setFlipBookReady,
    handleInputChange,
    handleInputSubmit,
    handleInputFocus,
    handleBlur,
    handleFirstPage,
    handleLastPage,
    handlePageClick,
    handleFlip,
  } = useBookPdf();

  return (
    <div className="relative flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-slate-400">
      {pages.length > 0 ? (
        <>
          <div
            className={`margin relative flex h-[75%] w-[100%] flex-1 flex-col items-center justify-center overflow-hidden bg-slate-400 md:mr-[37%] md:h-[85%] md:w-[85%] lg:mr-[35%] lg:h-[75%] lg:w-[75%] xl:ml-[5%] xl:h-[60%] xl:w-[60%] sm:h-[50%] sm:w-[100%] ${totalPages === 1 ? 'marginOnePage md:ml-[78%] md:mr-[0%] lg:ml-[75%] lg:mr-[0%] xl:ml-[65%]' : ''}`}
            ref={isDesktop ? div : null}
          >
            <HTMLFlipBook
              width={370}
              height={524}
              size={`${isMobile ? 'fixed' : 'stretch'}`}
              minWidth={100}
              maxWidth={850}
              minHeight={400}
              maxHeight={2250}
              startPage={0}
              drawShadow={true}
              flippingTime={isMobile ? 1000 : 1000}
              usePortrait={true}
              autoSize={true}
              startZIndex={10}
              maxShadowOpacity={0.5}
              mobileScrollSupport={true}
              clickEventForward={true}
              useMouseEvents={!isMobile}
              swipeDistance={50}
              showPageCorners={true}
              disableFlipByClick={false}
              showCover={!isMobile}
              style={{}}
              ref={ref}
              onInit={() => setFlipBookReady(true)}
              onFlip={(e) => {
                handlePageFlip();
                handleFlip(e.data);
              }}
              className={`rounded-lg ${isMobile ? 'h-[50%]': ''}`}
            >
              {pages.map((page, index) => (
                <div
                  key={index}
                  className="shadow-lg"
                  onClick={(e) => handlePageClick(index)}
                >
                  <img
                    src={page}
                    alt={`Page ${index + 1}`}
                    className="rounded-lg shadow-lg"
                  />
                </div>
              ))}
            </HTMLFlipBook>
          </div>
          <ChevronLeftIcon
            style={{
              height: '10rem',
              width: '5rem',
              paddingLeft: '10px',
            }}
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
              },
            }}
            className="absolute left-0 text-white hover:bg-[#292929] hover:bg-opacity-40"
            onClick={prevPage}
          />
          <ChevronRightIcon
            style={{
              height: '10rem',
              width: '5rem',
              paddingRight: '10px',
            }}
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
              },
            }}
            className="absolute right-0 text-white hover:bg-[#292929] hover:bg-opacity-40"
            onClick={nextPage}
          />
          <div className="absolute bottom-0 flex w-full items-center justify-center gap-1 p-1">
            <TurnLeftIcon
              style={{ width: '2.5rem', height: '2.5rem', padding: '8px' }}
              className="text-white hover:border-b-2 hover:border-white hover:bg-slate-300 hover:bg-opacity-40"
              onClick={handleFirstPage}
            />
            <ArrowForwardIcon
              style={{ width: '2.5rem', height: '2.5rem', padding: '8px' }}
              className="rotate-180 text-white hover:border-t-2 hover:border-white hover:bg-slate-300 hover:bg-opacity-30"
              onClick={prevPage}
            />
            <input
              ref={inputKey}
              type="text"
              className="h-6 w-14 text-center text-sm focus:outline-none"
              value={inputPage}
              onChange={handleInputChange}
              onKeyDown={handleInputSubmit}
              onFocus={handleInputFocus}
              onBlur={handleBlur}
            />
            <ArrowForwardIcon
              style={{ width: '2.5rem', height: '2.5rem', padding: '8px' }}
              className="text-white hover:border-b-2 hover:border-white hover:bg-slate-300 hover:bg-opacity-40"
              onClick={nextPage}
            />
            <TurnRightIcon
              style={{ width: '2.5rem', height: '2.5rem', padding: '8px' }}
              className="text-white hover:border-b-2 hover:border-white hover:bg-slate-300 hover:bg-opacity-40"
              onClick={handleLastPage}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
