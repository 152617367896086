import { useCallback, useEffect, useRef } from 'react';
import { useQueryCache } from '../../hooks/useQueryCache';
import { liveSearchProductBuffaloCache } from '../../constants/requestCacheName';

export type LiveSearch = {
  cacheName?: string;
  refetch: () => void;
};

export const useLiveSearch = ({
  cacheName = liveSearchProductBuffaloCache,
  refetch,
}: LiveSearch) => {
  const { clearCache } = useQueryCache();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClearSearchCache = useCallback(() => {
    clearCache(cacheName);
  }, [cacheName, clearCache]);

  const handleClickOutAutoComplete = useCallback(() => {
    handleClearSearchCache();
  }, [handleClearSearchCache]);

  const handleSearchInputChange = useCallback(() => {
    if (inputRef.current?.value === '') {
      handleClearSearchCache();
      return;
    }
    refetch();
  }, [handleClearSearchCache, refetch]);

  useEffect(() => {
    return () => handleClearSearchCache();
  }, [handleClearSearchCache]);

  const handleClearSearchAndSetFocus = () => {
    handleClearSearchCache();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return {
    inputRef,
    handleClearSearchCache,
    handleSearchInputChange,
    handleClickOutAutoComplete,
    handleClearSearchAndSetFocus,
  };
};
