import { VinculateSizingInDomain } from "../types/sizingDomain";
import { api } from "./api";

export const updateSizingDomainVinculation = async (
  vinculations: VinculateSizingInDomain[],
) => {
  const body = {
    vinculations,
  };

  const response = await api.put<void>(
    `/sizing-domain/v1`,
    body,
  );

  return response;
};
