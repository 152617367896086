import { ComponentProps } from 'react';
import TableRow from './TableRow';
import { twMerge } from 'tailwind-merge';

type TableHeadProps = ComponentProps<'div'> & {
  rowStyle?: string;
};

export default function TableHead({
  rowStyle,
  children,
  ...rest
}: TableHeadProps) {
  return (
    <div className="static text-zinc-400" {...rest}>
      <TableRow isHead className={twMerge('py-[2px]', rowStyle)}>
        {children}
      </TableRow>
    </div>
  );
}
