import { ComponentProps, ReactNode } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { twMerge } from 'tailwind-merge';

type AddButtonProps = ComponentProps<'div'> & {
  content: ReactNode;
};

export default function AddButton({
  content,
  className,
  ...rest
}: AddButtonProps) {
  return (
    <div className={twMerge('cursor-pointer', className)} {...rest}>
      <AddCircleIcon className="text-green-500" />
      <span className="ml-1 text-sm hover:text-primary hover:underline">
        {content}
      </span>
    </div>
  );
}
