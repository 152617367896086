import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { common } from '@mui/material/colors';

export const mainStyle: SxProps<Theme> = {
  px: '2rem',
  minHeight: '100vh',

  '@media (max-width: 760px)': {
    px: 0,
  },
};

export const titleStyle: SxProps<Theme> = {
  fontSize: '1.5rem',
  margin: '1rem 0',

  '@media(max-width: 760px)': {
    marginLeft: '0.5rem',
  },
};

export const backButtonStyle: SxProps<Theme> = {
  flex: 1,
  textAlign: 'start',

  '@media (max-width: 760px)': {
    display: 'none',
  },
};

export const secondaryHeaderStyle: SxProps<Theme> = {
  flex: 1,
  textAlign: 'left',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '0.5rem',
  marginTop: '-0.35rem',
  backgroundColor: common.white,
  borderRadius: '0.5rem',

  '@media (max-width: 760px)': {
    position: 'static',
    left: 'auto',
    transform: 'none',
    alignSelf: 'center',
    marginRight: '1rem',
    justifyContent: 'flex-end',
    marginTop: '0.75rem',
  },

  '@media (max-width: 500px)': {
    marginRight: 0,
  },
};

export const tableLoadingContainerStyle: SxProps<Theme> = {
  margin: '9rem 0 3rem 0',

  '@media (max-width: 760px)': {
    marginTop: '8rem',
  },
};

export const tableStyle: SxProps<Theme> = {
  paddingTop: '3rem',
};

export const tableHadStyle: SxProps<Theme> = {
  position: 'fixed',
};

export const compatibilityTextStyle: SxProps<Theme> = {
  cursor: 'pointer',
  fontSize: '0.9rem',
};
