import EmailSignatureItem from '../../../components/EmailSignatureItem';
import LoadMore from '../../../components/LoadMore';
import { useEmailSignaturesListing } from './useEmailSignaturesListing';

export default function EmailSignaturesListing() {
  const {
    signaturesImages,
    emailSignatureDomainsShown,
    isLoadMoreLoading,
    endPageRef,
    setIsSignatureLoading,
    handleCopy,
  } = useEmailSignaturesListing();

  return (
    <div className="m-2 flex flex-col gap-8">
      {/* <Loading loadMore className="absolute inset-0 flex justify-center" /> */}
      {emailSignatureDomainsShown?.map(({ signature, domain, id }) => (
        <div key={id} className="absolute -ml-[50rem] mb-5 flex items-end">
          <EmailSignatureItem
            domain={domain}
            signature={signature}
            setIsLoading={setIsSignatureLoading}
            id={id.toString()}
          />
          {/* <span>.</span> */}
        </div>
      ))}

      <div className="z-30 flex w-full flex-col gap-8 bg-white">
        {signaturesImages.map((image) => (
          <div className="flex items-end">
            <span className="text-lg">.</span>
            <img
              key={image}
              src={image}
              alt="Imagem de assinatura"
              width={640}
              height={213}
            />
            {/* <button onClick={handleCopy} className="text-sm text-primary ml-10">
              Copiar para área de transferência
            </button> */}
          </div>
        ))}
      </div>

      <LoadMore
        isModal={false}
        isLoadMoreFetching={isLoadMoreLoading}
        isContentFetching={false}
        ref={endPageRef}
      />
    </div>
  );
}
