import { createTheme } from '@mui/material';
import { grey, common } from '@mui/material/colors';

const light = createTheme({
  palette: {
    primary: {
      main: grey['900'],
      contrastText: grey['300'],
    },
    secondary: {
      main: '#42a0dd',
      contrastText: grey['200'],
    },
    background: {
      default: grey['200'],
      paper: common['white'],
    },
    text: {
      primary: grey['900'],
      secondary: grey['400'],
    },
  },
  breakpoints: {},
});

export default light;
