import { useCallback, useEffect, useRef, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { getImageFromApi } from '../../../utils/getImageUrl';

type HTMLFlipBookRef = {
  pageFlip: () => {
    flipNext: () => void;
    flipPrev: () => void;
    getPageCount: () => number;
    getCurrentPageIndex: () => number;
    flip: (pageIndex: number) => void;
  };
};

export const useBookPdf = () => {
  const [pages, setPages] = useState<string[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [flipBookReady, setFlipBookReady] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [nextPageNumber, setNextPageNumber] = useState<number>(2);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Defina seu limite para "mobile" aqui
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768); // Condicional para tela desktop

  // const initialInputPage = totalPages > 1
  // ? `${currentPage}-${nextPageNumber}/${totalPages}`
  // : `${totalPages}`;

  const [inputPage, setInputPage] = useState<string>(
    totalPages > 1
      ? `${currentPage}-${nextPageNumber}/${totalPages}`
      : `${totalPages}`,
  );
  const div = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLFlipBookRef>(null);
  const { pdfName } = useParams();
  const containerElement = div.current;

  const convertPDFToImages = useCallback(async () => {
    if (!pdfName) return;
    const pdfUrl = pdfName.startsWith('blob')
      ? pdfName
      : getImageFromApi(pdfName, 'news');
    const loadingTask = pdfjs.getDocument(pdfUrl);
    const pdf = await loadingTask.promise;
    const numPages = pdf.numPages;
    const pageImages: string[] = [];

    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1.5 });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      if (!context) {
        throw new Error('Failed to get 2d context for canvas');
      }

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({ canvasContext: context, viewport }).promise;

      const imageDataUrl = canvas.toDataURL();
      pageImages.push(imageDataUrl);
    }

    setPages(pageImages);
    setTotalPages(numPages);
  }, [pdfName]);

  useEffect(() => {
    convertPDFToImages();
  }, [convertPDFToImages]);

  const firstPageAnimation = (value: string) => {
    // Defina os valores de translate conforme os tamanhos de tela
    const screenSize = window.innerWidth;

    if (screenSize >= 768 && screenSize < 1024) {
      // Large screens (lg)
      return value === 'make' ? 22 : -2;
    } else if (screenSize >= 1024 && screenSize < 1280) {
      // Extra large screens (xl)
      return value === 'make' ? 22 : -1;
    } else {
      // 2xl screens and above
      return value === 'make' ? 25 : -1;
    }
  };

  const lastPageAnimation = (value: string) => {
    // Defina os valores de translate conforme os tamanhos de tela
    const screenSize = window.innerWidth;

    if (screenSize >= 768 && screenSize < 1024) {
      // Large screens (lg)
      return value === 'make' ? 22 : 45;
    } else if (screenSize >= 1024 && screenSize < 1280) {
      // Extra large screens (xl)
      return value === 'make' ? 22 : 45;
    } else {
      // 2xl screens and above
      return value === 'make' ? 25 : 48;
    }
  };

  const handleAnimation = useCallback(
    (translatePorcent: number) => {
      setIsAnimating(true);
      if (containerElement) {
        containerElement.style.transition = 'transform 1s ease'; // Animação de transição
        containerElement.style.transform = `translateX(${translatePorcent}%)`;
        setIsAnimating(false);
      }
    },
    [containerElement],
  );

  const nextPage = () => {
    if (ref.current) {
      if (currentPage !== totalPages) {
        handlePageClick(currentPage - 1);
      }
      ref.current.pageFlip().flipNext();
    }
  };

  const prevPage = () => {
    if (ref.current) {
      if (currentPage !== 1) {
        handlePageClick(currentPage - 1);
      }
      ref.current.pageFlip().flipPrev();
    }
  };

  const handlePageFlip = () => {
    if (ref.current) {
      const currentPageIndex = ref.current.pageFlip().getCurrentPageIndex() + 1;
      setCurrentPage(currentPageIndex); // Atualiza a página atual corretamente
      setNextPageNumber(
        currentPageIndex === totalPages
          ? currentPageIndex
          : currentPageIndex + 1,
      ); // Atualiza a próxima página corretamente
    }
  };

  const handleFirstPage = () => {
    const firstPage = 0;
    handleFlip(firstPage);
    ref.current?.pageFlip().flip(firstPage);
  };

  const handleLastPage = () => {
    const lastPage = pages.length - 1;
    handlePageClick(lastPage);
    ref.current?.pageFlip().flip(lastPage);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputPage(value);
  };

  const inputKey = useRef<HTMLInputElement | null>(null);
  const inputHandled = useRef<boolean>(false);

  const handleInputSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputKey.current) {
      const pageNumber = Number(inputPage);

      if (pageNumber === totalPages) {
        handleLastPage();
      } else if (pageNumber === 1) {
        handleFirstPage();
      } else if (
        !isNaN(pageNumber) &&
        pageNumber > 1 &&
        pageNumber < totalPages
      ) {
        ref.current?.pageFlip().flip(pageNumber - 1);
        handleAnimation(firstPageAnimation('make'));
      } else {
        alert('Número de página inválido');
      }
      inputHandled.current = true;
      inputKey.current.blur();
    }
  };

  const handleInputFocus = () => {
    setInputPage('');
  };

  const handleBlur = () => {
    if (inputHandled.current) {
      inputHandled.current = false;
      return;
    }

    const pageNumber = Number(inputPage.split('-')[0]);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      ref.current?.pageFlip().flip(pageNumber - 1);
      handleAnimation(firstPageAnimation('make'));
    }
  };

  useEffect(() => {
    setInputPage(
      totalPages > 1
        ? `${currentPage}-${nextPageNumber}/${totalPages}`
        : `${totalPages}`,
    );
  }, [currentPage, nextPageNumber, totalPages]);

  // Tipagem do evento e índice da página
  const handlePageClick = (pageIndex: number) => {
    if (pageIndex === 0 && !isAnimating && totalPages > 1) {
      handleAnimation(firstPageAnimation('make'));
    }

    if (pageIndex === pages.length - 1 && !isAnimating && totalPages > 1) {
      handleAnimation(lastPageAnimation('make'));
    }
  };

  const handleFlip = useCallback(
    (currentPage: number) => {
      if (currentPage === 0 && totalPages > 1) {
        handleAnimation(firstPageAnimation('undo'));
      }
      if (currentPage === pages.length - 1 && !isAnimating && totalPages > 1) {
        handleAnimation(lastPageAnimation('undo'));
      }
    },
    [handleAnimation, isAnimating, pages.length, totalPages],
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    ref,
    isMobile,
    isDesktop,
    div,
    inputKey,
    pages,
    totalPages,
    currentPage,
    nextPageNumber,
    inputPage,
    handlePageClick,
    handleFlip,
    handleFirstPage,
    handleLastPage,
    handlePageFlip,
    setFlipBookReady,
    handleInputSubmit,
    handleInputChange,
    handleInputFocus,
    handleBlur,
    prevPage,
    nextPage,
  };
};
