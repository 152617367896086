import { useMutation, useQuery } from 'react-query';
import { descriptionTypesApiCache } from '../../../constants/requestCacheName';
import {
  DescriptionType,
  DescriptionTypeFormData,
  FindAllDescriptionTypes,
} from '../../../types/descriptionTypes';
import {
  deleteDescriptionType,
  getAllDescriptionTypes,
  saveDescriptionType,
  updateDescriptionType,
} from '../../../services/descriptionTypes';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { useModal } from '../../../hooks/useModal';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { descriptionTypesSchema } from '../../../validations/schemas';
import { useRef } from 'react';
import { useEdit } from '../../../hooks/useEdit';
import { AxiosError } from 'axios';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';

type MutationProps = {
  id: number;
  name: string;
};

export const useDescriptionTypes = () => {
  const { data: descriptionTypes, isFetching } =
    useQuery<FindAllDescriptionTypes>(
      descriptionTypesApiCache,
      async () => (await getAllDescriptionTypes()).data,
    );

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();
  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: save } = useMutation({
    mutationFn: async ({ name }: Omit<MutationProps, 'id'>) => {
      return (await saveDescriptionType(name)).data;
    },

    onSuccess: ({ id, name }: MutationProps) => {
      if (id && name) {
        const data = {
          id,
          name,
        };
        addItemOnScreen<FindAllDescriptionTypes>(
          descriptionTypesApiCache,
          data,
        );
      }
      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar tipo de descrição',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Tipo de descrição já cadastrada');
            return true;
          }
        },
      );
    },
  });

  const { mutate: update } = useMutation({
    mutationFn: async ({ id, name }: MutationProps) => {
      return (await updateDescriptionType(id, name)).data;
    },

    onSuccess: ({ id, name }: MutationProps) => {
      if (id && name) {
        const data = {
          id,
          name,
        };
        updateItemOnScreen<FindAllDescriptionTypes>(
          descriptionTypesApiCache,
          data,
        );
      }
      // handleCloseModal();
      handleUnedit()
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar Tipo de descrição',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Tipo de descrição já cadastrada');
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteDescriptionTypeMutate } = useMutation({
    mutationFn: async () => {
      if (currentDescriptionTypesId.current) {
        return (await deleteDescriptionType(currentDescriptionTypesId.current))
          .data;
      }

      setErrorMessage('Falha ao deletar o tipo da descrição');
    },

    onSuccess: () => {
      if (currentDescriptionTypesId.current) {
        removeItemFromScreen<FindAllDescriptionTypes>(
          descriptionTypesApiCache,
          currentDescriptionTypesId.current,
        );
      }
      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar tipo de descrição',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.statusCode === 409
          ) {
            setErrorMessage(
              'Este tipo de descrição só pode ser apagado depois que não houver itens associados a ele',
            );
            return true;
          }
        },
      );
    },
  });

  const {
    itemsShown,
    isSearchInputDirty,
    handleSearch,
    inputRef,
    sortField,
    handleCancelSearch,
    handleSortTable,
  } = useHandleTable(
    descriptionTypes?.meta.totalItems ?? 0,
    descriptionTypes?.items ?? [],
  );

  const { isModalOpen, handleCloseModal, handleOpenModal } = useModal();
  const {
    isModalOpen: isConfirmModalOpen,
    handleCloseModal: handleCloseConfirmModal,
    handleOpenModal: handleOpenConfirmModal,
  } = useModal();

  const currentDescriptionTypesId = useRef<number | null>(null);

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
  } = useForm<DescriptionTypeFormData>({
    resolver: zodResolver(descriptionTypesSchema),
    defaultValues: {
      descriptionTypes: '',
    },
  });

  const descriptionType = watch('descriptionTypes');

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const descriptionTypesShown: DescriptionType[] = itemsShown;

  const handleAddDescriptionTypesClick = () => {
    handleOpenModal();
  };

  const handleTableRowClick = (
    descriptionTypeId: number,
    descriptionTypeName: string,
  ) => {
    currentDescriptionTypesId.current = descriptionTypeId;
    setValue('descriptionTypes', descriptionTypeName);
    handleOpenModal();
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmModal();
  };

  const handleConfirmDelete = () => {
    deleteDescriptionTypeMutate();
    handleCloseConfirmModal();
  };

  const handleClearLine = () => {
    setValue('descriptionTypes', '', { shouldDirty: true });
  };

  const handleCloseMenageModal = () => {
    currentDescriptionTypesId.current = null;
    setValue('descriptionTypes', '', { shouldDirty: true });
    setError('descriptionTypes', {});
    handleUnedit();
    handleCloseModal();
  };

  const handleButtonOkClick = ({
    descriptionTypes,
  }: DescriptionTypeFormData) => {
    if (currentDescriptionTypesId.current) {
      update({ id: currentDescriptionTypesId.current, name: descriptionTypes });
      // currentDescriptionTypesId.current = null;
      // setValue('descriptionTypes', '');
      return;
    }
    save({ name: descriptionTypes });
  };

  const isShowEditButton = !!currentDescriptionTypesId.current && !canEdit;

  return {
    descriptionTypes,
    isSearchInputDirty,
    handleSearch,
    inputRef,
    descriptionTypesShown,
    isFetching,
    sortField,
    isModalOpen,
    errors,
    descriptionType,
    dirtyFields,
    currentDescriptionTypesId: currentDescriptionTypesId.current,
    isConfirmModalOpen,
    isShowEditButton,
    errorMessage,
    clearError,
    handleCloseMenageModal,
    handleEdit,
    handleAddDescriptionTypesClick,
    handleDeleteButtonClick,
    handleCloseConfirmModal,
    register,
    handleSubmit,
    handleSortTable,
    handleTableRowClick,
    handleCancelSearch,
    handleConfirmDelete,
    handleButtonOkClick,
    handleClearLine,
  };
};
