export const phoneNumberMask = (
  value: string | undefined,
  numbersBeforeHyphen: number = 5,
) => {
  if (!value) return '';

  return (
    '+55 ' +
    value
      .replace('+55 ', '') // Remove todos os caracteres não numéricos
      .replace(/[\D]/g, '')
      .replace(/^(\d{2})(\d)/, '($1) $2') // Adiciona o +55 e formata o DDD
      .replace(new RegExp(`(\\d{${numbersBeforeHyphen}})(\\d)`), '$1-$2') // Adiciona o hífen entre o 5º e 6º dígitos
      .replace(/(-\d{4})(\d+?)/, '$1')
  ); // Limita o número de dígitos após o hífen
};

export const cepMask = (value: string | undefined) => {
  if (!value) return '';

  return value
    .replace(/\D/g, '')
    .slice(0, 8) // Remove todos os caracteres que não são dígitos
    .replace(/^(\d{5})(\d{3})/, '$1-$2');
};

export const removeCepMask = (value: string | undefined) => {
  if (!value) return '';

  return value.replace(/\D/g, ''); // Remove todos os caracteres que não são dígitos
};

export const removePhoneMask = (value: string) => {
  return value.replace(/[\D]/g, '');
};
