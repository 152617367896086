import { verifyOpenPdfPermission } from '../verifyPdfPermissions';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TableCell, TDocumentDefinitions } from 'pdfmake/interfaces';
import { PackingList } from '../../types/packingList';
import { format } from 'date-fns';
import { getImageFromApi } from '../../utils/getImageUrl';
import { urlToPureSvg } from '../../utils/urlToPureSvg';

const generatePackingListPdf = async (
  packingList: PackingList[],
  departureDate: Date,
  carrierName: string,
  onError: (errorMessage: string) => void,
) => {
  verifyOpenPdfPermission(onError);

  pdfMake.vfs = pdfFonts.vfs;

  const buffaloLogo = await urlToPureSvg(
    getImageFromApi('logo-buffalo', 'utils'),
  );

  const currentDate = new Date();
  const formatedDate = format(currentDate, 'dd/MM/yyyy HH:mm');

  const tableBody = packingList.map((nf) => [
    {
      fontSize: 10,
      text: nf.recipient,
      border: [false, false, false, false],
    } as TableCell,
    {
      fontSize: 10,
      text: nf.city.nome,
      border: [false, false, false, false],
    },
    {
      fontSize: 10,
      text: nf.city.uf.sigla,
      border: [false, false, false, false],
    },
    {
      fontSize: 10,
      text: nf.nf,
      border: [false, false, false, false],
    },
    {
      fontSize: 10,
      text: nf.volumes,
      border: [false, false, false, false],
    },
    {
      fontSize: 10,
      text: nf.responsible.user,
      border: [false, false, false, false],
    },
    {
      fontSize: 10,
      text: '______________________________________________',
      border: [false, false, false, false],
    },
  ]);

  const pdfDefinitions: TDocumentDefinitions = {
    pageSize: 'A4',
    pageMargins: [10, 70, 10, 32],
    pageOrientation: 'landscape',

    header: [
      {
        stack: [
          {
            marginTop: 28,
            text: `Romaneio de Embarque - ${format(departureDate, 'dd/MM/yyyy')}`,
            alignment: 'center',
          },
          {
            svg: buffaloLogo,
            absolutePosition: { x: -16, y: 16 },
            alignment: 'right',
            width: 90,
            height: 30,
            marginRight: 16,
          },
          {
            text: `Transportador     ${carrierName}`,
            margin: [10, 10, 0, 0],
            fontSize: 10,
          },
        ],
      },
    ],
    content: [
      {
        table: {
          widths: [230, 100, 20, 50, 70, 80, 210],
          headerRows: 0,
          body: [
            [
              {
                fillColor: '#D3D3D3',
                fontSize: 9,
                text: 'Destinatário',
                border: [false, false, false, false],
              },
              {
                fillColor: '#D3D3D3',
                fontSize: 9,
                text: 'Cidade',
                border: [false, false, false, false],
              },
              {
                fillColor: '#D3D3D3',
                fontSize: 9,
                text: 'UF',
                border: [false, false, false, false],
              },
              {
                fillColor: '#D3D3D3',
                fontSize: 9,
                text: 'NF',
                border: [false, false, false, false],
              },
              {
                fillColor: '#D3D3D3',
                fontSize: 9,
                text: 'Nº de Volumes',
                border: [false, false, false, false],
              },
              {
                fillColor: '#D3D3D3',
                fontSize: 9,
                text: 'Responsável',
                border: [false, false, false, false],
              },
              {
                fillColor: '#D3D3D3',
                fontSize: 9,
                text: 'Motorista',
                border: [false, false, false, false],
              },
            ],
            ...tableBody,
          ],
        },
        layout: {
          hLineColor: () => 'gray',
        },
      },
    ],
    footer: (currentPage, totalPages) => [
      {
        text: `Impresso em ${formatedDate}`,
        fontSize: 10,
        marginLeft: 16,
      },
      {
        text: `Página ${currentPage} de ${totalPages}`,
        fontSize: 10,
        alignment: 'right',
        relativePosition: {
          x: -16,
          y: -12,
        },
      },
    ],
  };

  pdfMake.createPdf(pdfDefinitions).getBlob((blob) => {
    const url = URL.createObjectURL(blob);

    const pdf = window.open(url, '_blank');

    if (!pdf) {
      onError(
        'Falha ao gerar PDF. Desative a opção Bloquear Pop-ups e tente novamente',
      );
    }
  });
};

export default generatePackingListPdf;
