export const urlToFileList = async (
  url: string,
  fileName: string,
  metaType: string = 'application/pdf',
) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      return null;
    }

    const blob = await response.blob();
    const file = new File([blob], fileName, { type: metaType });
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    return dataTransfer.files;
  } catch (error) {
    return null;
  }
};
