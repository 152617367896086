import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import React, { ComponentProps, useMemo } from 'react';

export type CheckboxStatus = 'all' | 'none' | 'partial';

type CheckboxProps = Omit<ComponentProps<'input'>, 'type'> & {
  status?: CheckboxStatus;
  labelClickable?: boolean;
  label?: string;
};

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ status, label, labelClickable = false, checked, ...rest }, ref) => {
    const isCheckedByStatus = useMemo(() => {
      if (status) {
        return status === 'all';
      }
    }, [status]);

    return (
      <div className="flex w-full items-center gap-2">
        <div className="relative flex items-center">
          {status === 'partial' && (
            <div className="pointer-events-none absolute -left-[2px] flex items-center">
              <IndeterminateCheckBoxIcon
                className="text-[#0075ff]"
                fontSize="small"
              />
            </div>
          )}
          <input
            type="checkbox"
            id={label}
            {...rest}
            checked={checked ?? isCheckedByStatus}
            ref={ref}
          />
        </div>
        {labelClickable && (
          <label htmlFor={label} className="text-sm">
            {label}
          </label>
        )}
        {!labelClickable && (
          <span className="text-center text-sm">{label}</span>
        )}
      </div>
    );
  },
);

export default Checkbox;
