import { useEffect, useMemo, useRef, useState } from 'react';
import {
  carriersCache,
  packingListCache,
} from '../../../constants/requestCacheName';
import { useTitle } from '../../../hooks/useTitle';
import { getLabelNFByAccessKey } from '../../../services/labelNF';
import { LabelItem } from '../../../types/labelNF';
import { useMutation, useQuery } from 'react-query';
import { regexOnlyNumber } from '../../../validations/regex';
import { useModal } from '../../../hooks/useModal';
import { totalCheckboxes } from '../../../constants/labelNF';
import generateLabelNFPdf from '../../../pdf/labelsNF/generateLabelNFPdf';
import { useError } from '../../../hooks/useError';
import {
  DeletePackingListDto,
  FindAllPackingList,
  FinishPackingListDto,
  PackingList,
} from '../../../types/packingList';
import { Carriers, FindAllCarriers } from '../../../types/carriers';
import {
  deletePackingList,
  finishPackingList,
  getAllPackingListByCarrierAndDate,
} from '../../../services/packingList';
import { getAllCarriers } from '../../../services/carriers';
import { CheckboxStatus } from '../../../components/Checkbox';
import generatePackingListPdf from '../../../pdf/packingList/generatePackingListPdf';
import { mutationErrorHandling } from '../../../utils/errorHandling';

type PackingListCheck = PackingList & {
  check: boolean;
};

export const useLabelNF = () => {
  useTitle('Etiquetas NF');

  const accessKey = useRef<string | null>(null);
  const accessKeyInputRef = useRef<HTMLInputElement>(null);
  const firstCheckboxIndexClicked = useRef<number | null>(null);
  const currentNFToDelete = useRef<PackingListCheck | null>(null);

  const { mutate: finishPackingListMutate } = useMutation({
    mutationFn: async (dto: FinishPackingListDto[]) => {
      finishPackingList(dto);
    },

    onSuccess: (_, dto) => {},

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao finalizar romaneio',
        setErrorMessage,
      );
    },
  });

  const { mutate: deleteMutate } = useMutation({
    mutationFn: async (dto: DeletePackingListDto[]) => {
      deletePackingList(dto);
    },

    onSuccess: (_, dto) => {
      const notDeletedNFs = packingList.filter(
        ({ id }) => !dto.some((data) => id === data.id),
      );
      setPackingList(notDeletedNFs);
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao realizar as deleções',
        setErrorMessage,
      );
    },
  });

  const [labels, setLabels] = useState<LabelItem[]>([]);
  const [checkedState, setCheckedState] = useState<boolean[]>(
    new Array(totalCheckboxes).fill(false),
  );
  const [labelsShowIndex, setLabelsShowIndex] = useState<LabelItem[]>([]);
  const [visibleLabels, setVisibleLabels] = useState<LabelItem[]>([]);
  const [isLabelNFPdfLoading, setIsLabelNFPdfLoading] = useState(false);
  const [currentCarrier, setCurrentCarrier] = useState<Carriers | null>(null);
  const [packingList, setPackingList] = useState<PackingListCheck[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const {
    isModalOpen: isGeneratePdfModalOpen,
    handleCloseModal: closeGeneratePdfModalModal,
    handleOpenModal: handleOpenGeneratePdfModalModal,
  } = useModal();

  const {
    isModalOpen: isPackingListModalOpen,
    handleCloseModal: closePackingListModalModal,
    handleOpenModal: handleOpenPackingListModalModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteOneModalOpen,
    handleCloseModal: handleCloseConfirmDeleteOneModal,
    handleOpenModal: handleOpenConfirmDeleteOneModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteAllModalOpen,
    handleCloseModal: handleCloseConfirmDeleteAllModal,
    handleOpenModal: handleOpenConfirmDeleteAllModal,
  } = useModal();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const lastLabel = useMemo(() => labels.at(-1), [labels]);

  const { mutate: labelNFMutate } = useMutation({
    mutationFn: async () => {
      if (accessKey.current) {
        return (await getLabelNFByAccessKey(accessKey.current)).data;
      }
    },

    onSuccess: (data) => {
      if (data) {
        if (data.alreadyExists) {
          setErrorMessage('Nota Fiscal já cadastrada');
        }
        setLabels((prev) => {
          const prevLabelsMap = new Map(prev.map((item) => [item.id, item]));

          prevLabelsMap.set(accessKey.current, {
            ...data.item,
            // Props a mais
            labelAmount: +data.item.transp.vol.qVol._text,
            labelAccessKey: accessKey.current,
          });

          return Array.from(prevLabelsMap.values());
        });

        if (accessKeyInputRef.current) {
          accessKeyInputRef.current.value = '';
        }
      }
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao finalizar romaneio',
        setErrorMessage,
      );
    },
  });

  // const { refetch: refetchLabelNF } = useQuery<LabelNF | null>(
  //   labelNFCache,
  //   async () => {
  //     if (accessKey.current) {
  //       return (await getLabelNFByAccessKey(accessKey.current)).data;
  //     }

  //     return null;
  //   },
  //   {
  //     // enabled: false,
  //     cacheTime: 0,
  //     retry: false,
  //     refetchOnWindowFocus: false,

  //     onSuccess: (data) => {
  //       if (data) {
  //         if (data.alreadyExists) {
  //           setErrorMessage('Nota Fiscal já cadastrada');
  //         }
  //         setLabels((prev) => {
  //           const prevLabelsMap = new Map(prev.map((item) => [item.id, item]));

  //           prevLabelsMap.set(accessKey.current, {
  //             ...data.item,
  //             // Props a mais
  //             labelAmount: +data.item.transp.vol.qVol._text,
  //             labelAccessKey: accessKey.current,
  //           });

  //           return Array.from(prevLabelsMap.values());
  //         });
  //       }
  //     },
  //   },
  // );

  const { data: carriers, refetch: refetchCarriers } =
    useQuery<FindAllCarriers | null>(
      carriersCache,
      async () => {
        return (await getAllCarriers()).data;
      },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,

        onSuccess: (data) => {
          setCurrentCarrier(data?.[0] ?? null);
        },
      },
    );

  const { refetch: refetchPackingList } = useQuery<FindAllPackingList | null>(
    [packingListCache, currentCarrier?.id, selectedDate],
    async () => {
      if (currentCarrier?.id) {
        const data = (
          await getAllPackingListByCarrierAndDate(
            currentCarrier?.id,
            selectedDate ?? new Date(),
          )
        ).data;

        return data;
      }

      return null;
    },
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const packingListWithCheck = data?.map((item) => ({
          ...item,
          check: item.printed === 'S',
        }));
        setPackingList(packingListWithCheck ?? []);
      },
    },
  );

  useEffect(() => {
    if (currentCarrier) {
      refetchPackingList();
    }
  }, [currentCarrier, selectedDate, refetchPackingList]);

  const handleChangeAccessKey = (value: string) => {
    if (value.length < 44) return;

    accessKey.current = value;
    labelNFMutate();
  };

  const handleChangeLabelsAmount = (labelAccessKey: string, amount: string) => {
    if (amount && !regexOnlyNumber.test(amount)) return;

    setLabels((prevLabels) => {
      const updatedLabels = prevLabels.map((label) => {
        if (label.labelAccessKey === labelAccessKey) {
          return {
            ...label,
            labelAmount: +amount,
          };
        }

        return label;
      });

      return updatedLabels;
    });
  };

  const handleRemoveLabel = (labelAccessKey: string) => {
    setLabels((prevLabels) => [
      ...prevLabels.filter((label) => label.labelAccessKey !== labelAccessKey),
    ]);
  };

  const handleRemoveAllLabels = () => {
    setLabels([]);

    if (accessKeyInputRef.current) {
      accessKeyInputRef.current.value = '';
    }
  };

  const handleGeneratePdfButtonClick = () => {
    const indexArray = labels.flatMap((item) =>
      Array(item.labelAmount).fill(item),
    );
    setLabelsShowIndex(indexArray);
    handleOpenGeneratePdfModalModal();
  };

  const handleCloseGeneratePdfModalModal = () => {
    firstCheckboxIndexClicked.current = null;
    setCheckedState(new Array(totalCheckboxes).fill(false));
    closeGeneratePdfModalModal();
  };

  const maxLabelsToShow = useMemo(
    () => labels.reduce((acc, item) => acc + item.labelAmount, 0),
    [labels],
  );

  const nfsCheckStatus = useMemo<CheckboxStatus>(() => {
    if (packingList.every(({ check }) => !check)) {
      return 'none';
    }

    if (packingList.every(({ check }) => check)) {
      return 'all';
    }

    return 'partial';
  }, [packingList]);

  const handleCheckboxClick = (index: number) => {
    firstCheckboxIndexClicked.current = index;
    const newCheckedState = [...checkedState].map((_, i) => i >= index);
    setCheckedState(newCheckedState);

    if (maxLabelsToShow + index > totalCheckboxes) {
      const labelsRestDivision = (maxLabelsToShow + index) % totalCheckboxes;

      const lastLabelsElements = labelsShowIndex.slice(-labelsRestDivision);
      // Seta todos os elementos para true
      setCheckedState(new Array(totalCheckboxes).fill(true));
      setVisibleLabels(lastLabelsElements);

      return;
    }

    setVisibleLabels(labelsShowIndex);
  };

  const handleGenerateLabelNFPdf = async () => {
    if (firstCheckboxIndexClicked.current === null) return;

    setIsLabelNFPdfLoading(true);
    await generateLabelNFPdf(
      labels,
      firstCheckboxIndexClicked.current,
      setErrorMessage,
    );
    setIsLabelNFPdfLoading(false);
  };

  const handlePackingListClick = () => {
    handleOpenPackingListModalModal();
    refetchCarriers();
  };

  const handleClosePackingListModal = () => {
    setCurrentCarrier(null);
    closePackingListModalModal();
  };

  const handleChangeNfCheck = (nfId: number, check: boolean) => {
    setPackingList((prev) => {
      return prev.map((item) => {
        if (item.id === nfId) {
          return { ...item, check };
        }
        return item;
      });
    });
  };

  const handleChangeAllNfsCheck = (check: boolean) => {
    setPackingList((prev) => prev.map((item) => ({ ...item, check })));
  };

  const handleSelectCarrier = (carrierId: number) => {
    const foundCarrier = carriers?.find(({ id }) => carrierId === id);

    if (foundCarrier) {
      setCurrentCarrier(foundCarrier);
    }
  };

  const handleSelectDate = (selectedDate: Date | undefined) => {
    setSelectedDate(selectedDate ?? null);
  };

  const handleGeneratePackingListButtonClick = async () => {
    const packingListNotChecked = packingList.filter(({ check }) => !check);

    if (packingListNotChecked.length <= 0) {
      setErrorMessage(
        'Não é possível gerar o romaneio, pois todos os itens estão marcados ou não há itens',
      );
      return;
    }

    await generatePackingListPdf(
      packingListNotChecked,
      packingList[0].dtDeparture,
      packingList[0].carrier.nome,
      setErrorMessage,
    );

    const packingListNotCheckedDto: FinishPackingListDto[] =
      packingListNotChecked.map(({ id }) => ({ id }));

    finishPackingListMutate(packingListNotCheckedDto);

    setPackingList((prev) => prev.map((item) => ({ ...item, check: true })));
  };

  const handleDeleteNFFromPackingList = (packingList: PackingListCheck) => {
    currentNFToDelete.current = packingList;
    handleOpenConfirmDeleteOneModal();
  };

  const handleConfirmDeleteNFFromPackingList = () => {
    if (!currentNFToDelete.current) return;

    deleteMutate([{ id: currentNFToDelete.current.id }]);
    handleCloseConfirmDeleteOneModal();
  };

  const handleDeleteAllPackingList = () => {
    handleOpenConfirmDeleteAllModal();
  };

  const handleConfirmDeleteAllPackingList = () => {
    const ids = packingList.map(({ id }) => ({ id }));
    deleteMutate(ids);
    handleCloseConfirmDeleteAllModal();
  };

  return {
    labels,
    lastLabel,
    accessKeyInputRef,
    isGeneratePdfModalOpen,
    checkedState,
    maxLabelsToShow,
    labelsShowIndex,
    visibleLabels,
    errorMessage,
    isLabelNFPdfLoading,
    isPackingListModalOpen,
    packingList,
    currentCarrier,
    nfsCheckStatus,
    carriers,
    selectedDate,
    isConfirmDeleteOneModalOpen,
    isConfirmDeleteAllModalOpen,
    currentNFToDelete: currentNFToDelete.current,
    handleConfirmDeleteAllPackingList,
    handleConfirmDeleteNFFromPackingList,
    handleCloseConfirmDeleteOneModal,
    handleCloseConfirmDeleteAllModal,
    handleDeleteAllPackingList,
    handleDeleteNFFromPackingList,
    handleSelectDate,
    handleGeneratePackingListButtonClick,
    handleSelectCarrier,
    handleChangeAllNfsCheck,
    handleChangeNfCheck,
    handleClosePackingListModal,
    handlePackingListClick,
    clearError,
    handleGenerateLabelNFPdf,
    handleCheckboxClick,
    handleGeneratePdfButtonClick,
    handleCloseGeneratePdfModalModal,
    handleRemoveAllLabels,
    handleRemoveLabel,
    handleChangeLabelsAmount,
    handleChangeAccessKey,
  };
};
