import { EmailTrigger } from '../../types/emailTrigger';
import Alert from '../Alert';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import Loading from '../Loading';
import Main from '../Main';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { useEmailTriggerContent } from './useEmailTriggerContent';

type EmailTriggerProps = {
  onTableRowClick: (email: EmailTrigger) => void;
  handleAddEmailRowClick?: () => void;
};

export default function EmailTriggerContent({
  onTableRowClick,
  handleAddEmailRowClick,
}: EmailTriggerProps) {
  const {
    emailTriggerList,
    isFetching,
    inputRef,
    domains,
    newCheckedState,
    isSearchInputDirty,
    emailTriggerListShown,
    domainWidth,
    sortField,
    emailTriggerListLength,
    emailTriggerListShownLength,
    canEdit,
    errorMessage,
    handleSearchDatabase,
    handleEnterClickSearchInput,
    clearError,
    handleButtonCancelEditClick,
    handleSearch,
    handleCancelSearch,
    handleButtonOkClick,
    handleSortTable,
    handleCheckDomain,
  } = useEmailTriggerContent();

  return (
    <>
      <ListingHeader add onAddIconClick={handleAddEmailRowClick} pdf={false}>
        <div className="mt-4">
          <div className="flex justify-between">
            <h1 className="mb-4 text-2xl">Email de Disparo</h1>
            <div className="flex items-center gap-2">
              <button
                onClick={handleButtonOkClick}
                className="text-sm text-primary"
              >
                {canEdit ? 'Salvar domínio' : 'Editar domínio'}
              </button>
              {canEdit && (
                <>
                  <span>|</span>
                  <button
                    onClick={() => handleButtonCancelEditClick()}
                    className="text-sm text-errorText"
                  >
                    Cancelar Seleção
                  </button>
                </>
              )}
            </div>
          </div>
          <SearchInput
            ref={inputRef}
            onSearch={handleSearch}
            onButtonClick={handleSearchDatabase}
            buttonContent="Buscar"
            onKeyDown={handleEnterClickSearchInput}
            showCancelOptions={isSearchInputDirty}
            onCancelSearch={handleCancelSearch}
          />
        </div>

        <Table.Head>
          <Table.Cell
            items={emailTriggerListShown}
            type="name"
            onSort={handleSortTable}
            isSortActive={sortField === 'name'}
            className="w-1/4"
          >
            Nome
          </Table.Cell>
          <div className="flex w-1/2">
            <Table.Cell
              items={emailTriggerListShown}
              type="email"
              onSort={handleSortTable}
              isSortActive={sortField === 'email'}
              className="w-3/5"
            >
              <span className="w-1/4 text-xs">Email</span>
            </Table.Cell>
            {/* <Table.CellStatic className="w-2/5">
              <span className="w-1/4 text-xs">Senha</span>
            </Table.CellStatic> */}
          </div>
          <div className=" flex w-1/4">
            {domains?.items?.map((domain) => (
              <Table.CellStatic
                key={`label-${domain.id}`}
                style={{ width: `${domainWidth}ch` }}
                className="text-secondaryText"
              >
                <span className="w-1/2 text-xs">{domain.name}</span>
              </Table.CellStatic>
            ))}
          </div>
        </Table.Head>
      </ListingHeader>

      <Main>
        {isFetching && <Loading />}
        {!isFetching && emailTriggerList && (
          <Table.Root
            itemType="emails"
            itemsLength={emailTriggerListLength}
            paddingTop="0"
          >
            {emailTriggerListShown.map((email) => {
              return (
                <Table.Row key={email.id} onClick={() => onTableRowClick(email)}>
                  <Table.Cell items={emailTriggerListShown} className="w-1/4">
                    <span>{email.name}</span>
                  </Table.Cell>
                  <div className="flex w-1/2">
                    <Table.Cell items={emailTriggerListShown} className="w-3/5">
                      <div className="w-1/4 text-xs">
                        <span>{email.email}</span>
                      </div>
                    </Table.Cell>
                    {/* <Table.CellStatic className="w-2/5">
                      <div className="w-2/4 text-xs">
                        <span>{email.password}</span>
                      </div>
                    </Table.CellStatic> */}
                  </div>
                  <div className="flex w-1/4">
                    {domains?.items.map((domain) => {
                      return (
                        <Table.CellStatic
                          className="flex"
                          key={`checkbox-${domain.id}`}
                          style={{
                            width: `${domainWidth}ch`,
                          }}
                        >
                          <input
                            checked={newCheckedState?.some(
                              (checkEmailDomain) =>
                                checkEmailDomain?.domainId === domain.id &&
                                checkEmailDomain?.emailId === email.id &&
                                checkEmailDomain?.isCheck,
                            )}
                            onChange={(event) =>
                              handleCheckDomain(
                                event.target.checked,
                                email.id,
                                domain.id,
                              )
                            }
                            onClick={(event) => {
                              // Impede que o clique no checkbox propague para a div principal
                              event.stopPropagation();
                            }}
                            className="size-4"
                            type="checkbox"
                            disabled={!canEdit}
                          />
                        </Table.CellStatic>
                      );
                    })}
                  </div>
                </Table.Row>
              );
            })}
          </Table.Root>
        )}
      </Main>

      <Footer
        current={emailTriggerListShownLength}
        total={emailTriggerListLength}
        itemType="Emails"
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
