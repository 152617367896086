import { useProductsCatalog } from './useProductsCatalog';

import ProductsCatalogFullContent from '../../../components/ProductsCatalogFullContent';

export default function ProductsCatalog() {
  const {
    products,
    productDescriptionSelectedId,
    isPdfLoading,
    errorMessage,
    clearError,
    handleFetchCategories,
    handleFetchProducts,
    handleCloseModal,
    handleOpenModal,
  } = useProductsCatalog();

  return <ProductsCatalogFullContent />;
}
