import { Box, BoxProps, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { actionsStyle } from './styles';

type CardActionsProps = BoxProps & {
  children: ReactNode;
  positionStatic?: boolean;
};

export default function CardActions({
  children,
  positionStatic,
  sx,
  ...rest
}: CardActionsProps) {
  return (
    <Box
      sx={
        {
          ...actionsStyle,
          position: positionStatic ? 'static' : 'absolute',
          ...sx,
        } as SxProps<Theme>
      }
      {...rest}
    >
      {children}
    </Box>
  );
}
