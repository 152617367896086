import DuimpContent from '../../../components/DuimpContent';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import { useDuimp } from './useDuimp';

export default function Duimp() {
  const {
    isMenageModalOpen,
    denominacao,
    ncm,
    codigoInterno,
    descricao,
    register,
    handleTableRowClick,
    handleCloseManageModal,
  } = useDuimp();

  return (
    <>
      <DuimpContent onTableRowClick={handleTableRowClick} />

      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseManageModal}
        cancelIcon={false}
        height="100vh"
        contentStyle="pb-16"
      >
        <HeaderModalManageItem
          title={'Produto'}
          mainButtonContent={''}
          onCancelClick={handleCloseManageModal}
          isEditable={false}
        />

        <div className="bottom-14 mb-4 mt-14 flex flex-col gap-8">
          <Input
            type="text"
            placeholder="Denominação"
            disabled={true}
            {...register('denominacao')}
            value={denominacao}
            className="overflow-x-auto"
          />
          <Input
            type="text"
            placeholder="NCM"
            disabled={true}
            {...register('ncm')}
            value={ncm}
          />
          <div>
            <label className="text-sm text-zinc-400">Descrição</label>
            <textarea
              placeholder="Descrição"
              readOnly
              {...register('descricao')}
              value={descricao}
              style={{
                width: '100%',
                height: '15vh',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                resize: 'none',
              }}
            />
          </div>

          <Input
            type="text"
            placeholder="Código Interno"
            disabled={true}
            {...register('codigoInterno')}
            value={codigoInterno}
          />
        </div>
      </Modal>
    </>
  );
}
