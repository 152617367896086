import { useQuery } from 'react-query';
import { documentTypesCache } from '../../constants/requestCacheName';
import { getDocumentTypesPaginated } from '../../services/documentTypes';
import { DocumentType, DocumentTypePaginated } from '../../types/documentTypes';
import { useHandleTable } from '../../hooks/useHandleTable';

export const useDocumentTypesContent = () => {
  const {
    data: documentTypes,
    isFetching: isDocumentTypesFetching,
    refetch: refetchDocumentTypes,
  } = useQuery<DocumentTypePaginated>(
    documentTypesCache,
    async () => (await getDocumentTypesPaginated(currentPage + 1)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    currentPage,
    sortField,
    inputRef,
    pagination,
    itemsShown,
    handleChangePage,
    handleChangePagination,
    handleSearch: search,
    handleSortTable,
    handleCancelSearch,
    itemsShownLength: documentTypesShownLength,
    isSearchInputDirty,
    itemsLength: documentTypesLength,
  } = useHandleTable(
    documentTypes?.meta.totalItems ?? 0,
    documentTypes?.items ?? [],
    refetchDocumentTypes,
  );
  const documentTypesShown: DocumentType[] = itemsShown;

  const handleSearch = () => {
    search();
  };

  const isFetching = isDocumentTypesFetching;

  return {
    documentTypes,
    isFetching,
    sortField,
    inputRef,
    pagination,
    documentTypesShown,
    documentTypesShownLength,
    isSearchInputDirty,
    documentTypesLength,
    currentPage,
    handleChangePagination,
    handleCancelSearch,
    handleSearch,
    handleChangePage,
    handleSortTable,
  };
};
