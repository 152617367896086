export const getTableColumnWidthByBiggestElement = <
  Element extends Record<string, any>,
>(
  elements: Element[],
  field: string,
  minLength: number,
) => {
  const fields = field.split('.');

  if (fields.length === 3) {
    const [first, second, third] = fields;
    const maxWidth = elements.reduce((maxWidth, row) => {
      if (row[first] && row[first][second]) {
        const width = row[first][second][third]?.toString().length ?? 0;
        return width > maxWidth ? width : maxWidth;
      }

      return maxWidth;
    }, 0);
    return maxWidth > minLength ? maxWidth + 2 : minLength;
  }

  if (fields.length === 2) {
    const [first, second] = fields;
    const maxWidth = elements.reduce((maxWidth, row) => {
      if (row[first]) {
        const width = row[first][second]?.toString().length ?? 0;
        return width > maxWidth ? width : maxWidth;
      }

      return maxWidth;
    }, 0);
    return maxWidth > minLength ? maxWidth + 2 : minLength;
  }

  const maxWidth = elements.reduce((maxWidth, row) => {
    const width = row[field]?.toString().length ?? 0;
    return width > maxWidth ? width : maxWidth;
  }, 0);
  return maxWidth > minLength ? maxWidth + 2 : minLength;
};
