import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { getImageStorage } from '../../utils/getImageUrl';
import commonContent from '../commonContent';
import commonFooter from '../commonFooter';
import { urlToPureSvg } from '../../utils/urlToPureSvg';
import { getAllPartsGroupItemsByLineId } from '../../services/partsGroupItem';
import { verifyOpenPdfPermission } from '../verifyPdfPermissions';

const generateLinePartsCatalogPdf = async (
  lineId: number,
  lineName: string,
  onError: (errorMessage: string) => void,
) => {
  verifyOpenPdfPermission(onError);

  pdfMake.vfs = pdfFonts.vfs;
  const partsCatalogProductsLine = (await getAllPartsGroupItemsByLineId(lineId))
    .data.items;

  const productId = partsCatalogProductsLine[0][0].product.id;
  const isProductImageOk = (
    await fetch(getImageStorage('products', productId), { method: 'HEAD' })
  ).ok;

  const buffaloLogo = await urlToPureSvg(
    isProductImageOk
      ? getImageStorage('utils', 'logo-buffalo')
      : getImageStorage('utils', 'unavailable'),
  );
  const productsContent = await Promise.all(
    partsCatalogProductsLine.map((product, index) => {
      const productContent = commonContent(product, true, index);
      return productContent;
    }),
  );

  const content = [
    [
      {
        text: 'Catálogo de Peças',
        color: '#7a7a7a',
        fontSize: 48,
        alignment: 'center',
        marginTop: 150,
      },
      {
        text: 'Linhas',
        color: '#7a7a7a',
        fontSize: 32,
        alignment: 'center',
        margin: [0, 16],
      },
      { text: lineName, color: '#bababa', fontSize: 32, alignment: 'center' },

      productsContent,
    ] as Content,
  ];

  const pdfDefinitions: TDocumentDefinitions = {
    pageSize: 'A4',
    pageMargins: [32, 90, 32, 32],
    header: [
      {
        svg: buffaloLogo,
        absolutePosition: { x: -16, y: 16 },
        alignment: 'right',
        width: 120,
        height: 40,
        marginRight: 16,
      },
    ],
    content: content,
    footer: commonFooter,
  };
  pdfMake.createPdf(pdfDefinitions).getBlob((blob) => {
    const url = URL.createObjectURL(blob);
    const pdf = window.open(url, '_blank');

    if (!pdf) {
      onError(
        'Falha ao gerar PDF. Desative a opção Bloquear Pop-ups e tente novamente',
      );
    }
  });
};

export default generateLinePartsCatalogPdf;
