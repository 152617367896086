import { Box, Typography } from '@mui/material';
import {
  getImageFromApi,
  verifyImageUrlExists,
} from '../../../utils/getImageUrl';
import { ReactNode, useEffect, useState } from 'react';
import ListIcon from '@mui/icons-material/List';
import { Link } from 'react-router-dom';

type ProductDetailsProps = {
  product: {
    id: number;
    name: string;
  } | null;
  isMobile: boolean;
  className?: string;
  pdfButton?: ReactNode;
  partsList?: boolean;
};

export default function ProductDetails({
  product,
  isMobile,
  partsList,
  className,
  pdfButton,
}: ProductDetailsProps) {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const verifyImage = async () => {
      if (!product?.id) return;

      const url = getImageFromApi(
        product?.id,
        product?.id < 50000 ? 'parts' : 'products',
      );

      const imageExists = await verifyImageUrlExists(url);
      setImageUrl(imageExists ? url : null);
    };
    verifyImage();
  }, [product?.id]);

  if (!product) return null;

  const { id, name } = product;

  return (
    <Box
      sx={{
        marginTop: '2px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isMobile ? '0 0' : '0',
        gap: isMobile ? '8px' : '16px',
      }}
    >
      {!imageUrl && (
        <img
          src={'/images/unavailable.png'}
          width={70}
          height={70}
          alt={name}
        />
      )}
      {imageUrl && (
        <iframe
          src={imageUrl}
          title={name}
          style={{
            width: isMobile ? '60px' : '250px',
            height: isMobile ? '65px' : 'auto',
          }}
          onError={() => setImageUrl(null)}
        />
      )}
      <Box
        sx={{
          textAlign: 'left',
          flex: 'wrap',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            fontSize: isMobile ? '14px' : '24px',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1, // Número máximo de linhas antes do "..."
            overflow: 'hidden',
           maxWidth: '100%', // Ajustado para ocupar todo o espaço disponível
            whiteSpace: 'normal',
          }}
          className="min-w-fit overflow-hidden truncate text-ellipsis"
        >
          {name}
        </Typography>
        <Typography
          sx={{
            color: '#919191',
            fontSize: isMobile ? '14px' : '24px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            minWidth: '6rem',
          }}
        >
          {id}
          <div className="flex gap-2">
            {partsList && (
              <div className={`${product?.id < 50000 ? 'hidden' : 'block'}`}>
                <Link to={`/parts-product/${product?.id}`}>
                  <ListIcon
                    fontSize="medium"
                    className="text-black hover:text-[#eab308]"
                  />
                </Link>
              </div>
            )}
            {pdfButton}
          </div>
        </Typography>
      </Box>
    </Box>
  );
}
