import {
  CreateEmailSignatureDto,
  EmailSignature,
  EmailSignaturesPaginated,
  SendImageEmail,
  UpdateEmailSignatureDto,
} from '../types/emailSignature';
import { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getEmailSignaturesPaginated = async (
  page: number,
  direction?: 'ASC' | 'DESC',
  limit?: number,
  domainName?: string,
) => {
  const url = addSearchParamsInUrl(
    '/signature/v1/search',
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    { name: 'domain', value: domainName },
    { name: 's', value: '' },
  );
  const response = await api.get<EmailSignaturesPaginated>(url);
  return response;
};

export const saveEmailSignature = async ({
  cellPhoneNumber,
  department,
  englishDepartament,
  email,
  name,
  phoneNumber,
}: CreateEmailSignatureDto) => {
  const body = {
    cellPhoneNumber,
    department,
    englishDepartament,
    email,
    name,
    phoneNumber,
  };

  const response = await api.post<EmailSignature>('/signature/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const sendImageEmail = async ({
  signatureId,
  base64,
}: SendImageEmail) => {
  const body = {
    signatureId,
  base64,
  };

  const response = await api.post<EmailSignature>('/signature/v1/send-image', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
}

export const updateEmailSignature = async ({
  id,
  cellPhoneNumber,
  department,
  englishDepartament,
  email,
  name,
  phoneNumber,
}: UpdateEmailSignatureDto) => {
  const body: UpdateEmailSignatureDto = {
    id,
    cellPhoneNumber,
    department,
    englishDepartament,
    email,
    name,
    phoneNumber,
  };

  const response = await api.put<EmailSignature>('/signature/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const deleteEmailSignature = async (signatureId: number) => {
  const response = await api.delete<void>(`/signature/v1/${signatureId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};
