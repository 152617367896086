import { SelectChangeEvent, useTheme } from '@mui/material';
import { useRef, useState, useEffect, useMemo } from 'react';
import {
  FindAllPartsGroupItem,
  PartsGroupItem,
  PartsGroupItemsModified,
} from '../../types/partsGroupItem';
import { verifyScreenIsLessThan } from '../../utils/verifyScreenIsLessThan';
import { useModal } from '../../hooks/useModal';
import { Part } from '../../types/parts';
import { DropResult } from '@hello-pangea/dnd';
import { reorderList } from '../../utils/dragDrop';
import {
  deleteAllParts,
  getAllPartsGroupItemByPartsGroupIdAndProductId,
  getAllProductsByPartsGroupId,
} from '../../services/partsGroupItem';
import {
  PartsGroupByProductIdApiCache,
  partsByProductIdPartsGroupIdApiCache,
} from '../../constants/requestCacheName';
import { useMutation, useQuery } from 'react-query';
import { useQueryCache } from '../../hooks/useQueryCache';
import { useParams } from 'react-router-dom';
import { usePartsCatalogStore } from '../../store/partsCatalog';
import { getAllCoordinatesByProductIdPartsGroupIdPartId } from '../../services/partsGroupItemCoordinate';

export const usePartsCatalogTable = (
  currentPartGroupId: number | undefined,
  onEditPart: (partGroupItem: PartsGroupItem) => void,
  onDragEnd: (partsGroupItem: PartsGroupItem[]) => void,
  onRowClick?: (partId: number) => void,
) => {
  const theme = useTheme();

  const { productId } = useParams();

  const {
    state: {
      currentPartsGroup,
      currentProductId,
      currentPartClicked,
      partsGroupItemModified,
      coordinatesModified,
    },
    actions: {
      addPartsGroupItemsModified,
      handleUnedit,
      modify,
      setCoordinateModified,
    },
  } = usePartsCatalogStore();

  const { clearCache, removeItemFromScreen, addItemOnScreen } = useQueryCache();

  const { mutate: deleteAll } = useMutation({
    mutationFn: async () => {
      if (productId && currentPartGroupId) {
        return (await deleteAllParts(+productId, currentPartGroupId)).data;
      }
    },

    onSuccess: () => {
      handleCloseDeleteAllModal();
      handleUnedit();
      if (currentPartGroupId) {
        removeItemFromScreen(
          `${PartsGroupByProductIdApiCache}${productId}`,
          currentPartGroupId,
        );
        clearCache(
          `${partsByProductIdPartsGroupIdApiCache}${currentPartGroupId}`,
        );
      }
    },

    onError: () => {
      alert('Falha ao deletar as peças');
    },
  });

  const { overrideCache } = useQueryCache();

  const { data: partsData, refetch: refetchParts } =
    useQuery<FindAllPartsGroupItem>(
      `${partsByProductIdPartsGroupIdApiCache}${currentPartsGroup?.id}`,
      async () =>
        (
          await getAllPartsGroupItemByPartsGroupIdAndProductId(
            currentPartsGroup!.id,
            currentProductId!,
          )
        ).data,
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
      },
    );

  const parts = useMemo<FindAllPartsGroupItem>(() => {
    const partsWithotModified = partsData?.items.filter(
      ({ part, partsGroup }) =>
        !partsGroupItemModified.some((modified) => {
          if (
            part.id === modified.partId &&
            partsGroup.id === modified.partsGroupId &&
            +(productId ?? -1) === modified.productId
          ) {
            return true;
          }

          return false;
        }),
    );
    const modifiedsParts: PartsGroupItem[] = partsGroupItemModified
      // ?.filter(
      //   (modified) =>
      //     !partsData?.items.some(({ part, partsGroup, product }) => {
      //       if (
      //         part.id === modified.partId &&
      //         partsGroup.id === modified.partsGroupId &&
      //         product.id === modified.productId
      //       ) {
      //         return true;
      //       }

      //       return false;
      //     }),
      // )
      ?.map((modified) => ({
        amount: modified.amount,
        order: modified.order,
        part: {
          id: modified.partId,
          name: modified.partName,
        },
        partsGroup: {
          id: modified.partsGroupId,
        },
        product: {
          id: modified.productId,
        },
        tag: modified.tag,
      }));

    // const modifiedsParts = modifiedsParts.push({
    //   amount: modified.amount,
    //   order: modified.order,
    //   part: {
    //     id: modified.partId,
    //   },
    //   partsGroup: {
    //     id: modified.partsGroupId,
    //   },
    //   product: {
    //     id: modified.productId,
    //   },
    //   tag: modified.tag,
    // });

    const items = [...(partsWithotModified ?? []), ...modifiedsParts]
      // .filter(({ product, part, partsGroup }) => par)
      .sort((a, b) => a.order - b.order);
    return {
      items,
      meta: { totalItems: partsData?.meta.totalItems ?? 0 },
    };
    // const parts = partsData?.items.map((partGroupItem) => {
    //   for (const modified of partsGroupItemModified) {
    //     if (
    //       partGroupItem.part.id === modified.partId &&
    //       partGroupItem.partsGroup.id === modified.partsGroupId &&
    //       partGroupItem.product.id === modified.productId
    //     ) {
    //       return {
    //         amount: modified.amount,
    //         order: modified.order,
    //         part: {
    //           // ...partGroupItem.part,
    //           id: modified.partId,
    //           name: modified.partName,
    //         },
    //         partsGroup: {
    //           id: modified.partsGroupId,
    //         },
    //         product: {
    //           id: modified.productId,
    //         },
    //         tag: modified.tag,
    //       };
    //     }
    //   }
    //   return partGroupItem;
    // });

    // return {
    //   items: parts ?? [],
    //   meta: { totalItems: partsData?.meta.totalItems ?? 0 },
    // };
  }, [
    partsData?.items,
    partsData?.meta.totalItems,
    partsGroupItemModified,
    productId,
  ]);

  const [selectedPart, setSelectedPart] = useState('Selecione a peça');
  const [partsInUse, setPartsInUse] = useState<number[]>([]);

  const tableRef = useRef<HTMLDivElement>(null);

  const partClickedIndex = useRef(0);
  const isTableClicked = useRef(false);
  const currentPartToChangeId = useRef<PartsGroupItem | null>(null);

  const isMobile = verifyScreenIsLessThan(1250);

  const [currentPartToDeleteId, setCurrentPartToDeleteId] = useState<
    number | null
  >(null);

  const {
    isModalOpen: isSelectPartModalOpen,
    handleCloseModal: handleCloseSelectPartModal,
    handleOpenModal: handleOpenSelectPartModal,
  } = useModal();

  const {
    isModalOpen: isSelectProductModalOpen,
    handleCloseModal: handleCloseSelectProductModal,
    handleOpenModal: handleOpenSelectProductModal,
  } = useModal();

  const {
    isModalOpen: isDeleteAllModalOpen,
    handleCloseModal: handleCloseDeleteAllModal,
    handleOpenModal: handleOpenDeleteAllModal,
  } = useModal();

  useEffect(() => {
    if (currentPartsGroup) {
      refetchParts();
    }
  }, [currentPartsGroup, refetchParts]);

  useEffect(() => {
    if (isMobile) {
      if (currentPartClicked) {
        setSelectedPart(
          `${currentPartClicked?.part?.id} - ${currentPartClicked?.part?.name}`,
        );
        return;
      }

      setSelectedPart('Selecione a peça');
    }

    if (!isTableClicked.current) {
      const tableCellHeightInPx = 53;
      const partClickedPosition =
        tableCellHeightInPx * partClickedIndex.current;

      tableRef.current?.scrollTo({
        top: partClickedPosition,
      });
      return;
    }

    isTableClicked.current = false;
  }, [currentPartClicked, isMobile]);

  useEffect(() => {
    const inUse: number[] = [];

    parts?.items.forEach(({ part }) => {
      if (part.name) inUse.push(part.id);
    });

    setPartsInUse(inUse);
  }, [parts?.items]);

  const handleSelectPart = (
    { id, name }: Part,
    previousPartsGroupItem?: PartsGroupItem | null,
  ) => {
    if (productId && currentPartsGroup?.id) {
      modify();
      if (previousPartsGroupItem) {
        let partGroupIsInArray = false;
        partsGroupItemModified.forEach((partsGroupItem) => {
          if (partsGroupItem.partId === previousPartsGroupItem.part.id) {
            partsGroupItem.partId = id;
            partsGroupItem.previousPartId = previousPartsGroupItem.part.id;
            partGroupIsInArray = true;
          }
        });

        const { amount, order, tag, part } = previousPartsGroupItem;
        if (!partGroupIsInArray) {
          const partsGrouItem: PartsGroupItemsModified = {
            partId: id,
            productId: +productId,
            partsGroupId: currentPartsGroup.id,
            amount,
            order,
            tag,
            partName: part.name ?? '',
            previousPartId: part.id,
          };
          addPartsGroupItemsModified(partsGrouItem);
        }

        if (parts) {
          const cache: FindAllPartsGroupItem = {
            ...parts,
            items: parts.items.map((item) => {
              if (item.part.id === previousPartsGroupItem.part.id) {
                item.part = {
                  id,
                  name,
                };
              }

              return item;
            }),
          };

          overrideCache(
            `${partsByProductIdPartsGroupIdApiCache}${currentPartsGroup?.id}`,
            cache,
          );
        }

        return;
      }

      const partsGrouItem: PartsGroupItemsModified = {
        partId: id,
        productId: +productId,
        partsGroupId: currentPartsGroup.id,
        amount: 1,
        partName: name,
        order: (parts?.items.at(-1)?.order ?? 0) + 1,
        tag: '',
      };

      addPartsGroupItemsModified(partsGrouItem);

      const cache: PartsGroupItem = {
        ...partsGrouItem,
        part: { id, name },
        product: { id: +productId },
        partsGroup: { id: currentPartsGroup.id },
      };

      addItemOnScreen<FindAllPartsGroupItem>(
        `${partsByProductIdPartsGroupIdApiCache}${currentPartsGroup?.id}`,
        cache,
        true,
      );
    }
  };

  const handleSelectPartMobile = (event: SelectChangeEvent) => {
    setSelectedPart(event.target.value);
  };

  const handleTableRowClick = ({ part }: PartsGroupItem) => {
    isTableClicked.current = true;
    if (onRowClick) {
      if (part) {
        onRowClick(part.id);
      }
    }
  };

  const handleChangePartClick = (part: PartsGroupItem) => {
    currentPartToChangeId.current = part;
    handleOpenSelectPartModal();
  };

  const handleRemoveIconClick = (partGroupId: number) => {
    if (partGroupId === currentPartToDeleteId) {
      setCurrentPartToDeleteId(null);
      return;
    }

    setCurrentPartToDeleteId(partGroupId);
  };

  const handleSelectPartTableRowClick = (part: Part) => {
    handleSelectPart(part, currentPartToChangeId.current);
    handleCloseSelectPartModal();
    currentPartToChangeId.current = null;
    if (onRowClick) onRowClick(part.id);
  };

  const handleAddPartClick = () => {
    handleOpenSelectPartModal();
  };

  const handleEditPart = (partsGroupItemModified: PartsGroupItem) => {
    const partsGroupItems =
      parts?.items.map((partsGroupItem) => {
        if (partsGroupItem.part.id === partsGroupItemModified.part.id) {
          partsGroupItem = partsGroupItemModified;
          partsGroupItemModified = partsGroupItem;
        }
        return partsGroupItem;
      }) ?? [];

    overrideCache<FindAllPartsGroupItem>(
      `${partsByProductIdPartsGroupIdApiCache}${currentPartsGroup?.id}`,
      {
        items: partsGroupItems,
        meta: { totalItems: parts?.meta.totalItems ?? 0 },
      },
    );

    onEditPart(partsGroupItemModified);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedTable = reorderList(
      parts?.items ?? [],
      result.source.index,
      result.destination.index,
    );

    reorderedTable.forEach((description, index) => {
      description.order = index + 1;
    });

    overrideCache(
      `${partsByProductIdPartsGroupIdApiCache}${currentPartsGroup?.id}`,
      {
        items: reorderedTable,
        meta: { totalItems: parts?.meta.totalItems ?? 0 },
      },
    );

    // setCurrentParts(reorderedTable);

    onDragEnd(reorderedTable);
  };

  const handleCopyDescriptionsButtonClick = () => {
    handleOpenSelectProductModal();
  };

  const handleSelectProduct = async (productIdClicked: number) => {
    if (productId) {
      const productParts = await getAllPartsGroupItemByPartsGroupIdAndProductId(
        currentPartsGroup!.id,
        productIdClicked,
      );

      const productCoordinates =
        await getAllCoordinatesByProductIdPartsGroupIdPartId({
          productId: productIdClicked,
          partsGroupId: currentPartsGroup!.id,
        });

      overrideCache<FindAllPartsGroupItem>(
        `${partsByProductIdPartsGroupIdApiCache}${currentPartsGroup?.id}`,
        productParts.data,
      );

      productParts.data.items.forEach(
        ({ amount, order, part, partsGroup, tag }) => {
          const partsGroupItemModified: PartsGroupItemsModified = {
            amount,
            order,
            partId: part.id,
            partsGroupId: partsGroup.id,
            partName: part.name ?? '',
            productId: +productId,
            tag,
          };
          addPartsGroupItemsModified(partsGroupItemModified);
        },
      );
      const currentCoordinatesCopied = productCoordinates.data.items.map(
        ({ part, partsGroup, height, width, x, y }) => ({
          partId: part.id,
          partGroupId: partsGroup.id,
          height,
          width,
          x,
          y,
          id: `${part.id}${partsGroup.id}${productId}${x}${y}`,
        }),
      );

      setCoordinateModified([
        ...coordinatesModified,
        ...currentCoordinatesCopied,
      ]);

      // refetchProductParts();
      handleCloseSelectProductModal();
    }
  };

  const handleButtonDeleteAllClick = () => {
    handleOpenDeleteAllModal();
  };

  const handleConfirmDeleteAllParts = () => {
    deleteAll();
  };

  const selectProductsService = async () => {
    const data = await getAllProductsByPartsGroupId(currentPartGroupId!);
    return data;
  };

  return {
    theme,
    selectedPart,
    isMobile,
    tableRef,
    partClickedIndex,
    currentPartToDeleteId,
    isSelectPartModalOpen,
    partsInUse,
    parts: parts?.items,
    isSelectProductModalOpen,
    isDeleteAllModalOpen,
    currentPartClicked,
    selectProductsService,
    handleSelectPartMobile,
    handleConfirmDeleteAllParts,
    handleButtonDeleteAllClick,
    handleCloseDeleteAllModal,
    handleSelectProduct,
    handleCopyDescriptionsButtonClick,
    handleCloseSelectProductModal,
    handleDragEnd,
    handleEditPart,
    handleChangePartClick,
    handleCloseSelectPartModal,
    handleSelectPartTableRowClick,
    handleSelectPart,
    handleTableRowClick,
    handleAddPartClick,
    handleRemoveIconClick,
  };
};
