import { ProductDuimp } from '../../types/productDuimp';
import Alert from '../Alert';
import ConfirmModal from '../ConfirmModal';
import DuimpDetailsModal from '../DuimpDetailsModal';
import DuimpDetailsVinculateModal from '../DuimpDetailsModalVinculate';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import Loading from '../Loading';
import Main from '../Main';
import Table from '../Table';
import WarningModalDuimp from '../WarningModalDuimp';
import { useDuimpContent } from './useDuimpContent';

type DuimpContentProps = {
  isModal?: boolean;
  onTableRowClick: (duimp: ProductDuimp) => void;
};

export default function DuimpContent({
  isModal,
  onTableRowClick,
}: DuimpContentProps) {
  const {
    isFetching,
    productListDuimp,
    productListDuimpLength,
    productListDuimpShown,
    productListDuimpShownLength,
    isConfirmResponseModalOpen,
    errorMessage,
    isConfirmLaunchModalOpen,
    responseData,
    isLoading,
    isConfirmWaitModalOpen,
    isConfirmVinculateModalOpen,
    responseDataVinculate,
    isConfirmResponseVinculateModalOpen,
    handleCloseConfirmResponseVinculateModal,
    handleCloseConfirmWaitModal,
    navigate,
    handleCloseConfirmResponseModal,
    handleLaunchProducts,
    handleCloseConfirmLaunchModal,
    handleConfirmLaunch,
    clearError,
    handleVinculateCompanyProduct,
    handleCloseConfirmVinculateModal,
    handleConfirmVinculate,
  } = useDuimpContent();

  return (
    <>
      {!isModal && (
        <ListingHeader pdfTop="3rem" pdf={false}>
          <div className="my-4">
            <div className="flex items-start justify-between">
              <h1 className="mb-4 text-2xl">Produtos Duimp</h1>
              <div className="flex items-start gap-4">
                <button
                  onClick={() => handleLaunchProducts()}
                  className={`text-base ${productListDuimpLength ? 'text-primary' : 'text-gray-300'}`}
                  disabled={!productListDuimpLength || isLoading}
                >
                  {isLoading ? 'Carregando...' : 'Lançar Produtos'}
                </button>
                <button
                  onClick={() => navigate('/admin/duimp/logsDuimp')}
                  className="text-base text-primary"
                >
                  Visualizar Logs
                </button>
                <button
                  onClick={() => navigate('/admin/duimp/erros')}
                  className="text-base text-red-500"
                >
                  Visualizar Erros
                </button>
                <button
                  onClick={() => handleVinculateCompanyProduct()}
                  className="text-base text-primary"
                >
                  Vincular Fabricante Faltante
                </button>
              </div>
            </div>
          </div>

          <Table.Head>
            <Table.Cell
              items={productListDuimpShown}
              type="name"
              className="w-1/12 whitespace-nowrap"
            >
              Código Interno
            </Table.Cell>
            <Table.Cell items={productListDuimpShown} className="w-4/5">
              Denominação
            </Table.Cell>
            <Table.Cell items={productListDuimpShown} className="w-2/12">
              NCM
            </Table.Cell>
          </Table.Head>
        </ListingHeader>
      )}
      <Main isModal={isModal}>
        {isFetching && <Loading />}
        {!isFetching ? (
          productListDuimp ? (
            <Table.Root
              itemType="produtosDuimp"
              itemsLength={productListDuimpLength}
              className={`${isModal ? 'max-h-[60vh]' : ''}`}
              paddingTop="0"
            >
              {productListDuimpShown?.map((product) => {
                return (
                  <Table.Row
                    key={product.codigosInterno?.[0]}
                    onClick={() => onTableRowClick(product)}
                  >
                    <Table.Cell
                      items={productListDuimpShown}
                      type="codProd"
                      className="w-1/12"
                    >
                      <span>
                        {product.codigosInterno?.[0] || 'Sem código interno'}
                      </span>
                    </Table.Cell>
                    <Table.Cell
                      items={productListDuimpShown}
                      type="denominacao"
                      className="w-4/5"
                    >
                      <span>{product.denominacao}</span>
                    </Table.Cell>
                    <Table.Cell
                      items={productListDuimpShown}
                      type="ncm"
                      className="w-2/12"
                    >
                      <span>{product.ncm}</span>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Root>
          ) : (
            <div className="flex justify-center p-8">
              Nenhum produto foi encontrado
            </div>
          )
        ) : (
          <></>
        )}
      </Main>

      <Footer
        className={`${isModal ? 'mb-1 ml-[5vw] w-[90vw]' : ''}`}
        current={productListDuimpShownLength}
        total={productListDuimpLength}
        itemType="Produtos a lançar"
      />

      <ConfirmModal
        contentMessage={`Deseja lançar todos os produtos para o Siscomex?`}
        isModalOpen={isConfirmLaunchModalOpen}
        onCloseModal={handleCloseConfirmLaunchModal}
        onNotConfirm={handleCloseConfirmLaunchModal}
        onConfirm={handleConfirmLaunch}
      />

      <ConfirmModal
        contentMessage={`Deseja vincular os fabricantes aos produtos sem vínculo a operador estrangeiro ?`}
        isModalOpen={isConfirmVinculateModalOpen}
        onCloseModal={handleCloseConfirmVinculateModal}
        onNotConfirm={handleCloseConfirmVinculateModal}
        onConfirm={handleConfirmVinculate}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />

      <DuimpDetailsModal
        isConfirmResponseModalOpen={isConfirmResponseModalOpen}
        handleCloseConfirmResponseModal={handleCloseConfirmResponseModal}
        responseData={responseData}
      />

      <DuimpDetailsVinculateModal
        isConfirmResponseModalOpen={isConfirmResponseVinculateModalOpen}
        handleCloseConfirmResponseModal={handleCloseConfirmResponseVinculateModal}
        responseData={responseDataVinculate}
      />

      <WarningModalDuimp
        contentMessage={'Aguarde a finalização do lançamento...'}
        handleCloseConfirmModal={handleCloseConfirmWaitModal}
        isConfirmModalOpen={isConfirmWaitModalOpen}
      />
    </>
  );
}
