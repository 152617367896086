import { useTheme } from '@mui/material';
import TableRowMui, {
  TableRowProps as TableRowPropsMui,
} from '@mui/material/TableRow';
import { twMerge } from 'tailwind-merge';

type TableRowProps = TableRowPropsMui & {
  isHead?: boolean;
};

export default function TableRow({
  children,
  isHead = false,
  className,
  ...rest
}: TableRowProps) {
  const theme = useTheme();

  return (
    <TableRowMui
      sx={{
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        ':hover': {
          borderBottom: !isHead ? '1px solid #42a0dd' : ``,
        },
      }}
      className={twMerge(
        `justify-between border-b px-8 py-2 ${!isHead ? 'cursor-pointer' : 'py-0'}`,
        className,
      )}
      {...rest}
    >
      {children}
    </TableRowMui>
  );
}
