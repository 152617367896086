import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Box,
  BoxProps,
  Typography,
  Table,
} from '@mui/material';
import { useFieldsTable } from './useFieldsTable';
import { FieldDomainItem } from '../../types/domainImageCoordinate';
import { fieldsTableStyle } from './styles';
import { common, grey } from '@mui/material/colors';
import {
  refCellStyle,
  refColumnContentStyle,
} from '../PartsCatalogTable/styles';
import ConfirmModal from '../ConfirmModal';
import Alert from '../Alert';

type FieldsTableProps = BoxProps & {
  canEdit: boolean;
  disableButtonCoordinates?: boolean;
  currentFieldHoverId: number | null;
  onDeleteField: (fieldId: number) => void;
  onEditField: (fieldDomainItem: FieldDomainItem) => void;
  onRowMouseEnter?: (fieldId: number) => void;
  onRowMouseOut?: () => void;
  // onRowClick?: (fieldId: number) => void;
  onModalCopyCoordinates?: () => void;
};

export default function FieldsTable({
  canEdit,
  currentFieldHoverId,
  onDeleteField,
  onEditField,
  onRowMouseEnter,
  onRowMouseOut,
  onModalCopyCoordinates,
  disableButtonCoordinates,
  // onRowClick,
  ...rest
}: FieldsTableProps) {
  const {
    fields,
    tableRef,
    currentFieldClicked,
    fieldClickedIndex,
    isDeleteAllCoordinatesModalOpen,
    theme,
    errorMessage,
    coordinates,
    clearError,
    handleConfirmDeleteAllCoordinates,
    handleCloseAllCoordinatesModal,
    handleDeleteAllCoordinatesButtonClick,
    handleTableRowClick,
  } = useFieldsTable(onEditField);

  return (
    <>
      <Box component="section" {...rest} sx={fieldsTableStyle}>
        <Typography
          component="h3"
          color={grey['600']}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            gap: '1rem',
            '@media(min-width: 1250px)': {
              width: '100%',
              backgroundColor: common.white,
            },
          }}
        >
          Campos
          {canEdit && (
            <div className="rounded text-sm text-primary">
              <button
                disabled={
                  disableButtonCoordinates ||
                  (coordinates?.items.length ?? 0) > 0
                }
                onClick={onModalCopyCoordinates}
                className="cursor-pointer text-left hover:underline disabled:cursor-default disabled:no-underline disabled:opacity-60"
              >
                Copiar coordenadas
              </button>
            </div>
          )}
          {canEdit && (
            <div className="mb-4 rounded text-sm text-errorText">
              <button
                disabled={(coordinates?.items.length ?? 0) <= 0}
                onClick={handleDeleteAllCoordinatesButtonClick}
                className="cursor-pointer text-left hover:underline disabled:cursor-default disabled:no-underline disabled:opacity-60"
              >
                Deletar todas as coordenadas
              </button>
            </div>
          )}
        </Typography>

        <TableContainer
          sx={{
            overflowX: 'hidden',
            maxHeight: '100vh',
          }}
          ref={tableRef}
          className={`mb-2 max-w-[32rem] bg-white max-xl:my-2 max-xl:max-h-40 max-xl:max-w-full max-xl:px-2`}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={refCellStyle} align="center">
                  <Typography sx={refColumnContentStyle}>Ref.</Typography>
                </TableCell>
                <TableCell sx={{ color: '#aaaaaa' }}>Campo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields?.map((fieldObj, index) => {
                const { field } = fieldObj;

                if (currentFieldClicked?.field.id === field?.id) {
                  fieldClickedIndex.current = index;
                }

                return (
                  field && (
                    <TableRow
                      key={field.id}
                      className="cursor-pointer overflow-hidden"
                      style={{
                        overflow: 'hidden',
                      }}
                    >
                      <TableCell
                        sx={refCellStyle}
                        className="cursor-pointer"
                        onMouseEnter={() => {
                          if (onRowMouseEnter) {
                            onRowMouseEnter(field.id);
                          }
                        }}
                        onClick={() => handleTableRowClick(fieldObj)}
                      >
                        <div
                          onMouseEnter={() => {
                            if (onRowMouseEnter) {
                              onRowMouseEnter(field.id);
                            }
                          }}
                          onClick={() => handleTableRowClick(fieldObj)}
                          className={`mr-4 w-[3.4rem] cursor-pointer rounded-lg border border-solid text-center ${currentFieldHoverId === field.id ? 'border-primary' : 'border-[#cdcdcd]'}`}
                        >
                          <input
                            type="text"
                            value={field.id}
                            readOnly={true}
                            className={`remove-arrows h-10 w-[3.25rem] rounded-lg text-center outline-none read-only:cursor-pointer ${currentFieldClicked?.field.id === field.id ? 'bg-primary text-zinc-200' : 'bg-backgroundPaper'}`}
                            onMouseOut={onRowMouseOut}
                            onMouseEnter={() => {
                              if (onRowMouseEnter) {
                                onRowMouseEnter(field.id);
                              }
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell
                        sx={{
                          color: '#aaaaaa',
                          borderBottomColor:
                            currentFieldHoverId === field.id ||
                            currentFieldClicked?.field.id === field.id
                              ? theme.palette.secondary.main
                              : '',
                        }}
                        onMouseOut={onRowMouseOut}
                        onMouseEnter={() => {
                          if (onRowMouseEnter) {
                            onRowMouseEnter(field.id);
                          }
                        }}
                        onClick={() => handleTableRowClick(fieldObj)}
                      >
                        {field.nameField}
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <ConfirmModal
        contentMessage={'Deseja excluir todas as coordenadas'}
        isModalOpen={isDeleteAllCoordinatesModalOpen}
        onCloseModal={handleCloseAllCoordinatesModal}
        onNotConfirm={handleCloseAllCoordinatesModal}
        onConfirm={handleConfirmDeleteAllCoordinates}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
