import { useQuery } from 'react-query';
import { partsGroupApiCache } from '../../constants/requestCacheName';
import { useHandleTable } from '../../hooks/useHandleTable';
import { getPartsGroupPaginated } from '../../services/partsGroup';
import { PartsGroup, PartsGroupPaginated } from '../../types/partsGroup';
import { partsGroupSearchBaseEndpoint } from '../../constants/endpoints';
import { useMemo } from 'react';
import { getTableColumnWidthByBiggestElement } from '../../utils/tableColumnStyles';

export const usePartsGroupContent = () => {
  const {
    data: partsGroup,
    isFetching: isFetchingPartsGroup,
    refetch: refetchPartsGroupPaginated,
  } = useQuery<PartsGroupPaginated>(
    partsGroupApiCache,
    async () => (await getPartsGroupPaginated(currentPage + 1)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  // const {
  //   isFetching: isFetchingAllPartsGroup,
  //   refetch: refetchAllPartsGroupPaginated,
  // } = useQuery<FindAllPartsGroup>(
  //   partsGroupApiCache,
  //   async () => (await getAllPartsGroup()).data,
  //   {
  //     retry: false,
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //   },
  // );

  const {
    currentPage,
    sortField,
    inputRef,
    pagination,
    isSearchInputDirty,
    itemsLength: partsGroupLength,
    itemsShownLength: partsGroupShownLength,
    itemsShown,
    isSearchFetching,
    endPageRef,
    isLoadMoreFetching,
    originalSearchItems,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch: search,
    handleChangePage,
    handleChangePagination,
    handleSortTable,
    isSearch,
    handleCancelSearch,
  } = useHandleTable(
    partsGroup?.meta.totalItems ?? 0,
    partsGroup?.items ?? [],
    refetchPartsGroupPaginated,
    null,
    partsGroupSearchBaseEndpoint,
    getPartsGroupPaginated,
  );
  const partsGroupShown: PartsGroup[] = itemsShown;

  const handleSearch = () => {
    search();
  };

  const isFetching = isFetchingPartsGroup || isSearchFetching;

  const lineColWidth = useMemo(
    () =>
      getTableColumnWidthByBiggestElement<PartsGroup>(
        originalSearchItems ?? [],
        'description.name',
        10,
      ),
    [originalSearchItems],
  );

  return {
    currentPage,
    sortField,
    inputRef,
    pagination,
    isSearchInputDirty,
    partsGroupLength,
    partsGroupShownLength,
    partsGroupShown,
    partsGroup,
    isFetching,
    isSearch,
    endPageRef,
    isLoadMoreFetching,
    lineColWidth,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch,
    handleChangePage,
    handleChangePagination,
    handleSortTable,
    handleCancelSearch,
  };
};
