import {
  EmailTrigger,
  FindAllEmailTriggerList,
  SaveEmailTriggerDto,
  UpdateEmailTriggerDto,
  UpdateVinculationEmailTriggerDto,
  VinculateEmailTriggerInDomain,
} from '../types/emailTrigger';
import { api } from './api';

export const saveEmailTrigger = async ({
  name,
  email,
  password,
}: SaveEmailTriggerDto) => {
  const body = {
    name,
    email,
    password,
  };

  const response = await api.post<EmailTrigger>('/email-trigger/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const updateEmailTrigger = async ({
  id,
  name,
  email,
  password,
}: UpdateEmailTriggerDto) => {
  const body = {
    id,
    name,
    email,
    password,
  };
  const response = await api.put<EmailTrigger>('/email-trigger/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const deleteEmailTrigger = async (emailId: number) => {
  const response = await api.delete<EmailTrigger>(
    `/email-trigger/v1/${emailId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return response;
};

export const getAllEmailTriggerList = async () => {
  const response = await api.get<FindAllEmailTriggerList>(
    `/email-trigger/v1/find-all`,
  );
  return response;
};

export const updateVinculationEmailTrigger = async ({
  emailId,
  domainId,
}: UpdateVinculationEmailTriggerDto) => {
  const body = {
    emailId,
    domainId,
  };
  const response = await api.put<EmailTrigger>(
    '/email-trigger/v1/vinculate',
    body,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return response;
};

export const updateEmailTriggerDomainVinculation = async (
  vinculations: VinculateEmailTriggerInDomain[],
) => {
  const body = {
    vinculations,
  };

  const response = await api.put<void>(
    `/email-trigger/v1/vinculate`,
    body,
  );

  return response;
};
