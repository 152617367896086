import {
  Categories,
  Category,
  ReorderCategory,
  ReorderCategoryDto,
} from '../types/categories';
import { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getAllCategories = async () => {
  const response = await api.get<Categories>('/category/v1/find-all');
  return response;
};

export const getCategoriesPaginated = async (
  page: number,
  direction: 'ASC' | 'DESC' | null = 'ASC',
  limit?: number | null,
  line?: string,
) => {
  const url = addSearchParamsInUrl(
    '/categories/v1/search',
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    { name: 'line', value: line },
    { name: 's', value: '' },
  );
  const response = await api.get<Categories>(url);
  return response;
};

export const getAllCategoriesByLineId = async (lineId: number) => {
  const response = await api.get<Categories>(`/category/v1/line-id/${lineId}`);
  return response;
};

export const saveCategory = async (categoryName: string, lineName: string) => {
  const body = {
    categoryName,
    lineName,
  };

  const response = await api.post<Category>('/category/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const updateCategory = async (
  categoryId: number,
  categoryName: string,
  lineName: string,
) => {
  const body = {
    categoryId,
    categoryName,
    lineName,
  };

  const response = await api.put<Category>('/category/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const deleteCategory = async (categoryId: number) => {
  const response = await api.delete<Category>(`/category/v1/${categoryId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const reorderCategory = async (dto: ReorderCategoryDto[]) => {
  const response = await api.put<ReorderCategory>(`/category/v1/reorder`, {
    categories: dto,
  });
  return response;
};
