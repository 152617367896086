import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { getImageFromApi, getImageStorage } from '../../utils/getImageUrl';
import { Product } from '../../types/products';
import Card from '../Card';
import Modal from '../Modal';
import CardSkeleton from '../CardSkeleton';
import { useHomeCard } from './useHomeCard';
import { Role } from '../../types/userType';

type ItemCardProps = {
  product: Product;
  productDescriptionSelectedId: number | null;
  onOpenModal: (productId: number) => void;
  onCloseModal: () => void;
};

export default function HomeCard({
  product,
  productDescriptionSelectedId,
  onOpenModal,
  onCloseModal,
}: ItemCardProps) {
  const {
    isImageLoading,
    auth,
    handleImageWasLoaded,
    handleLoadStart,
    handleCheckField,
  } = useHomeCard(product);

  return (
    <>
      <Card.Root key={product.id} sx={{ minHeight: '26rem' }} border>
        {isImageLoading && <CardSkeleton />}

        <Box sx={{ display: isImageLoading ? 'none' : 'bock' }}>
          <Card.Image
            src={getImageFromApi(product.id, 'products')}
            alt={`Imagem do ${product.name}`}
            fallbackSrc={`/images/unavailable.png`}
            fallbackAlt="Imagem de produto indisponível"
            onLoadStart={handleLoadStart}
            onLoad={handleImageWasLoaded}
            onClick={() => onOpenModal(product.id)}
            className="cursor-pointer"
          />
          <Card.IsNew text={product.new ? 'Novo' : ''} />
          <Card.Infos productId={product.id} productName={product.name} />
          {auth && (
            <div className="mx-auto mt-1 w-fit">
              <div>
                <input
                  type="checkbox"
                  name="reviewedApproved"
                  id={`reviewedApproved${product.id}`}
                  disabled={auth.user.role.type === Role.AFTER_SALES}
                  checked={product.reviewedApproved}
                  onChange={(event) => handleCheckField(event, product.id)}
                />
                <label
                  htmlFor={`reviewedApproved${product.id}`}
                  className="ml-2 text-sm"
                >
                  Revisado e aprovado
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="new"
                  id={`new${product.id}`}
                  disabled={auth.user.role.type === Role.AFTER_SALES}
                  checked={product.new}
                  onChange={(event) => handleCheckField(event, product.id)}
                />
                <label htmlFor={`new${product.id}`} className="ml-2 text-sm">
                  Novo
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="outOfLine"
                  id={`outOfLine${product.id}`}
                  disabled={auth.user.role.type === Role.AFTER_SALES}
                  checked={product.outOfLine}
                  onChange={(event) => handleCheckField(event, product.id)}
                />
                <label
                  htmlFor={`outOfLine${product.id}`}
                  className="ml-2 text-sm"
                >
                  Fora de linha
                </label>
              </div>
            </div>
          )}
          <Card.Actions>
            <Card.Action
              text="Descrição"
              onClick={() => onOpenModal(product.id)}
            />
            <Link
              to={{
                pathname: `/parts-catalog/${product.id}`,
                search: `product=${product.name}${product.associatedProductId ? `&previus-version=${product.associatedProductId}` : ''}`,
              }}
              onClick={() => {
                sessionStorage.setItem(
                  'previousUrl',
                  window.location.pathname + window.location.search,
                );
              }}
            >
              <Card.Action text="Catálogo de peças" />
            </Link>
          </Card.Actions>
          <Modal
            isOpen={productDescriptionSelectedId === product.id}
            onClose={onCloseModal}
          >
            <Card.Root contentClassName="max-sm:mx-0 max-sm:mt-0 pb-4">
              <div>
                <h2 className="mb-8 text-center text-xl">Descrição</h2>
                <Card.Image
                  src={getImageStorage('products', product.id)}
                  alt={`Imagem do ${product.name}`}
                  fallbackSrc={`/images/unavailable.png`}
                  fallbackAlt="Imagem de produto indisponível"
                />
                <Card.IsNew text={product.new ? 'Novo' : ''} />
                <Card.Infos productId={product.id} productName={product.name} />
                <Card.Table productId={product.id} className="mt-7 pt-2" />
              </div>
            </Card.Root>
          </Modal>
        </Box>
      </Card.Root>
    </>
  );
}
