import {
  CoordinateToDelete,
  CoordinatesModified,
  FindAllCoordinates,
  PureCoordinatesModified,
  SaveUpdateCoordiante,
} from '../types/partsGroupCoordinate';
import { api } from './api';

export const getAllCoordinatesByProductIdPartsGroupIdPartId = async ({
  productId,
  partsGroupId,
}: Record<'partsGroupId' | 'productId', number>) => {
  const response = await api.get<FindAllCoordinates>(
    `/parts-group-item-coordinate/v1/product-id/${productId}/parts-group-id/${partsGroupId}`,
  );
  return response;
};

export const getAllCoordinatesByProductId = async (productId: number) => {
  const response = await api.get<FindAllCoordinates>(
    `/parts-group-item-coordinate/v1/product-id/${productId}`,
  );
  return response;
};

export const saveUpdateCoordinate = async (
  coordinateDto: CoordinatesModified[],
  productId: number,
) => {
  const dto: PureCoordinatesModified[] = coordinateDto.map(
    ({ height, partGroupId, partId, width, x, y, previousX, previousY }) => ({
      productId,
      height,
      partsGroupId: partGroupId,
      partId,
      width,
      x,
      y,
      previousX,
      previousY,
    }),
  );

  const body = {
    items: dto,
  };

  const response = await api.put<SaveUpdateCoordiante>(
    `/parts-group-item-coordinate/v1/create-update`,
    body,
  );

  return response;
};

export const deleteCoordinates = async (
  coordinateDeleteDto: CoordinateToDelete[],
) => {
  const dto = {
    items: coordinateDeleteDto,
  };

  const response = await api.delete<void>(`parts-group-item-coordinate/v1`, {
    data: dto,
  });

  return response;
};
