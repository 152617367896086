import Input from '../../Input';
import Modal from '../../Modal';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { ContactFormData } from '../../../types/contactUs';
import { ContactStatus } from '../../../enums/contactStatus';
import EditorMenu from '../../Editor/EditorMenu';
import { Editor as EditorType } from '@tiptap/react';
import Editor from '../../Editor';
import HeaderModalManageItem from '../../HeaderModalManageItem';

type ContactFormDataBoolean = {
  [K in keyof ContactFormData]: boolean;
};

type ContactManageModalProps = {
  isMenageModalOpen: boolean;
  currentContactId: number | null;
  isShowAnswerButton: boolean;
  contactStatus: ContactStatus;
  errors: FieldErrors<ContactFormData>;
  dirtyFields: Partial<Readonly<ContactFormDataBoolean>>;
  isUneditable: boolean;
  editor: EditorType | null;
  message: string;
  errorMensageResposta: string;
  register: UseFormRegister<ContactFormData>;
  handleCloseMenageModal: () => void;
  handleArchiveButtonClick: () => void;
  onOkClick: () => void;
  handleEdit: () => void;
  handleClearAnswer: () => void;
};

export default function ContactManageModal({
  isMenageModalOpen,
  isShowAnswerButton,
  contactStatus,
  message,
  editor,
  errorMensageResposta,
  register,
  handleEdit,
  handleCloseMenageModal,
  handleArchiveButtonClick,
  onOkClick,
}: ContactManageModalProps) {
  const isContactFinished = contactStatus === ContactStatus.CONCLUIDO;
  const isContactArchived = contactStatus === ContactStatus.ARQUIVADO;
  const canArchive = !(isContactFinished || isContactArchived);

  return (
    <>
      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseMenageModal}
        cancelIcon={false}
        contentStyle="overflow-y-auto pb-5"
      >
        <HeaderModalManageItem
          showDeleteButton={canArchive}
          deleteButtonContent="Arquivar Chamado"
          onDeleteButtonClick={handleArchiveButtonClick}
          isEditable={!isShowAnswerButton}
          title={'Chamado'}
          mainButtonContent={
            isContactFinished
              ? ''
              : isShowAnswerButton
                ? 'Responder'
                : 'Concluir'
          }
          onCancelClick={handleCloseMenageModal}
          onOkClick={!isShowAnswerButton ? onOkClick : undefined}
          onEditClick={isShowAnswerButton ? handleEdit : undefined}
        />
        <div className="mt-5 flex w-full flex-col">
          <Input
            type="text"
            label="Status:"
            disabled={true}
            className="border-none font-bold"
            forceLabelShrink
            {...register('status')}
          />
        </div>
        <div className="flex w-full flex-col">
          <Input
            type="text"
            label="Nome:"
            disabled={true}
            className="border-none font-bold"
            forceLabelShrink
            {...register('nome')}
          />
        </div>
        <div className="flex w-full flex-col">
          <Input
            type="text"
            label="Email:"
            disabled={true}
            className="border-none font-bold"
            forceLabelShrink
            {...register('email')}
          />
        </div>
        {isContactFinished && (
          <div className="flex w-full flex-col">
            <Input
              type="text"
              label="Concluído em:"
              disabled={true}
              className="border-none font-bold"
              forceLabelShrink
              {...register('dtConclusao')}
            />
          </div>
        )}
        <div className="flex w-full flex-col">
          <Input
            type="text"
            label="Criado em:"
            disabled={true}
            className="border-none font-bold"
            forceLabelShrink
            {...register('dtCriacao')}
          />
        </div>
        <div className="flex w-full flex-col">
          <Input
            type="text"
            label="Empresa:"
            disabled={true}
            className="border-none font-bold"
            forceLabelShrink
            {...register('empresa')}
          />
        </div>
        <div className="flex w-full flex-col">
          <Input
            type="text"
            label="Mensagem:"
            multiline
            disabled={true}
            value={message}
            className="border-none font-bold"
            {...register('mensagem')}
          />
        </div>
        {(isContactFinished || !isShowAnswerButton) && (
          <div className="mb-5">
            <label>Resposta</label>
            <EditorMenu editor={editor} disabled={false} />
            <Editor editor={editor} helperText={errorMensageResposta} />
          </div>
        )}
      </Modal>
    </>
  );
}
