import { useMutation, useQuery } from 'react-query';
import { newsTypesCache } from '../../constants/requestCacheName';
import {
  NewsType,
  NewsTypePaginated,
  ReorderNewsTypeDto,
} from '../../types/newsType';
import {
  getNewsTypesPaginated,
  reorderNewsType,
} from '../../services/newsTypes';
import { useHandleTable } from '../../hooks/useHandleTable';
import { useCallback, useRef } from 'react';
import { useEdit } from '../../hooks/useEdit';
import { DropResult } from '@hello-pangea/dnd';
import { reorderList } from '../../utils/dragDrop';
import { mutationErrorHandling } from '../../utils/errorHandling';
import { useError } from '../../hooks/useError';

export const useNewsTypesContent = () => {
  const {
    data: newsTypes,
    isFetching: isNewsTypesFetching,
    refetch: refetchNewsTypes,
  } = useQuery<NewsTypePaginated>(
    newsTypesCache,
    async () => (await getNewsTypesPaginated(currentPage + 1)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    canEdit: canEditOrder,
    handleEdit: handleEditOrder,
    handleUnedit: handleUneditOrder,
  } = useEdit();

  const reorderedTableUpdate = useRef<ReorderNewsTypeDto[]>([]);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: reorderNewsTypeMutate } = useMutation({
    mutationFn: async (dto: ReorderNewsTypeDto[]) => await reorderNewsType(dto),

    onError: (error) => {
      mutationErrorHandling(error, 'Falha ao reordenar', setErrorMessage);
    },
  });

  const {
    currentPage,
    sortField,
    inputRef,
    itemsShown,
    handleSearch: search,
    handleSortTable: sortTable,
    handleCancelSearch,
    defineItemsShown,
    handleReset,
    itemsShownLength: newsTypesShownLength,
    isSearchInputDirty,
    itemsLength: newsTypesLength,
  } = useHandleTable(
    newsTypes?.meta.totalItems ?? 0,
    newsTypes?.items ?? [],
    refetchNewsTypes,
  );
  const newsTypesShown: NewsType[] = itemsShown;

  const handleSearch = () => {
    search();
  };

  const handleSortTable = (fieldNameSort: string) => {
    if (canEditOrder) return;

    sortTable(fieldNameSort);
  };

  const handleButtonCancelEditClick = useCallback(() => {
    reorderedTableUpdate.current = [];
    handleUneditOrder();
  }, [handleUneditOrder]);

  const handleChangeEditOrderButtonClick = useCallback(() => {
    if (canEditOrder) {
      if (reorderedTableUpdate.current.length > 0) {
        reorderNewsTypeMutate(reorderedTableUpdate.current);
      }

      handleUneditOrder();
      return;
    }

    handleReset();
    handleEditOrder();
  }, [
    canEditOrder,
    handleEditOrder,
    handleReset,
    handleUneditOrder,
    reorderNewsTypeMutate,
  ]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedTable = reorderList(
      newsTypesShown,
      result.source.index,
      result.destination.index,
    );

    reorderedTableUpdate.current = reorderedTable.map((reorderItem, index) => {
      reorderItem.order = index + 1;

      return {
        newsTypeId: reorderItem.id,
        order: reorderItem.order,
      };
    });

    defineItemsShown(reorderedTable);
  };

  const isFetching = isNewsTypesFetching;

  return {
    newsTypes,
    isFetching,
    sortField,
    inputRef,
    newsTypesShown,
    newsTypesShownLength,
    isSearchInputDirty,
    newsTypesLength,
    canEditOrder,
    errorMessage,
    clearError,
    handleDragEnd,
    handleButtonCancelEditClick,
    handleChangeEditOrderButtonClick,
    defineItemsShown,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
  };
};
