import { ProductsParts } from '../../../types/productsParts';
import { useModal } from '../../../hooks/useModal';
import { useTitle } from '../../../hooks/useTitle';
import { useRef } from 'react';

export const useLinkProductsPartsDescription = () => {
  useTitle('Descrição de peças e produtos');

  const currentProductsPartsClicked = useRef<ProductsParts | null>(null);

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: handleCloseMenageModal,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const handleTableRowClick = (productPart: ProductsParts) => {
    currentProductsPartsClicked.current = productPart;
    handleOpenMenageModal();
  };

  return {
    isMenageModalOpen,
    currentProductsPartsClicked: currentProductsPartsClicked.current,
    handleTableRowClick,
    handleCloseMenageModal,
  };
};
