import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { ClipLoader } from 'react-spinners';

import BackgroundRepresentantes from '../../assets/images/backgrounds/BackgroundRepresentantes2x.png';

import SvgMapaBrasil from '../../components/SvgComponent/SvgMapaBrasil';
import { statesMap } from '../../constants/statesUf';
import { useRepresentative } from './useRepresentatives';
import { formatPhoneNumber } from '../../../utils/format';

export default function Representative() {
  const {
    representatives,
    states,
    loading,
    selectedState,
    handleSelectUf,
    handleSubmit,
    ref,
  } = useRepresentative();

  return (
    <>
      <Header />
      <div className="adjust-content-bf">
        <main>
          <img
            src={BackgroundRepresentantes}
            alt=""
            className="w-full"
            width={1000}
            height={150}
          />
          <div className="mb-10 mt-5">
            <div className="mx-4 md:mx-5">
              <div className="flex flex-col gap-4 pb-10 md:flex-row">
                <form
                  className="flex w-full flex-col gap-4 2xl:w-2/3"
                  onSubmit={handleSubmit}
                >
                  <div className="text-base text-zinc-400">
                    Pesquise abaixo o representante Buffalo Motores mais perto
                    de você e confira as informações sobre o representante e o
                    local onde você poderá adquirir nossos produtos.
                  </div>
                  <div className="mt-12 grid grid-cols-1 gap-4 lg:grid-cols-2">
                    <div className="flex flex-col gap-2">
                      <p className="font-semibold">Estado</p>
                      <select
                        disabled={loading}
                        className="w-full cursor-pointer rounded-lg border-2 p-2 font-bold uppercase tracking-widest text-black"
                        value={selectedState}
                        onChange={(e) => handleSelectUf(e.target.value)}
                      >
                        <option value="">Todos os estados</option>
                        {states?.items.map((state) => (
                          <option key={state.name} value={state.name}>
                            {statesMap[state.name] || state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </form>
                <div className="mt-4 hidden w-full justify-center md:mt-0 md:flex md:justify-end 2xl:w-1/3">
                  <SvgMapaBrasil
                    onEstadoClick={handleSelectUf}
                    className="w-full md:w-auto"
                    selectedEstado={selectedState}
                    loading={loading}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                {representatives?.map((representative) => {
                  const emails = representative.email?.split(';');
                  return (
                    <div
                      key={representative.vendCode}
                      className="rounded-xl border border-gray-300 p-5"
                    >
                      <div className="flex items-center justify-between">
                        <h2 className="text-lg font-bold">
                          {representative.nickname}
                        </h2>
                        <span className="text-sm text-slate-400">
                          {representative.uf}
                        </span>
                      </div>
                      {(representative.contacts?.length ?? 0) <= 0 && (
                        <>
                          <div className="mt-3 flex items-start gap-5 text-sm">
                            <div className="flex flex-col">
                              <span className="font-bold">Telefone</span>
                              <span>
                                {formatPhoneNumber(representative.phone ?? '')}
                              </span>
                            </div>
                            <div className="flex flex-col">
                              <span className="font-bold">e-mail</span>
                              {
                                emails?.map((email) => (
                                  <p key={email}>{email}</p>
                                ))}
                            </div>
                          </div>
                        </>
                      )}
                      {representative.contacts?.map((contact) => (
                        <div key={contact.description} className="mt-3 text-sm">
                          <div className="flex flex-col">
                            <span>Falar com</span>
                            <span className="font-bold">
                              {contact.description}
                            </span>
                          </div>
                          {contact.details.map((detail, index) => (
                            <div
                              key={detail.id}
                              className="mt-1 flex items-start gap-5"
                            >
                              {contact.details.some(
                                (detail) => detail.phoneNumber,
                              ) && (
                                <div className="flex w-36 flex-col">
                                  {index === 0 && <span>Telefone</span>}
                                  <span className="font-bold">
                                    {formatPhoneNumber(
                                      detail.phoneNumber ?? '',
                                    )}
                                  </span>
                                </div>
                              )}
                              {contact.details.some(
                                (detail) => detail.email,
                              ) && (
                                <div className="flex flex-col">
                                  {index === 0 && <span>e-mail</span>}
                                  <span className="font-bold">
                                    {detail.email ?? ''}
                                  </span>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
              {loading && (
                <div className="mt-4 flex justify-center">
                  <ClipLoader color="#000" loading={loading} size={50} />
                  <div className="ml-4 text-base font-semibold text-zinc-600">
                    Carregando representantes
                  </div>
                </div>
              )}
              <div ref={ref} />
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </>
  );
}
