import { ComponentProps, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type TableCellBodyProps = ComponentProps<'td'> & {
  children?: ReactNode;
};

export default function TableCellStatic({
  children,
  className,
  ...rest
}: TableCellBodyProps) {
  return (
    <td
      className={twMerge(
        'flex items-center py-1 text-sm text-primaryText',
        className,
      )}
      {...rest}
    >
      {children}
    </td>
  );
}
