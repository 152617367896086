import { VinculateNewsInDomain } from "../types/newsDomain";
import { api } from "./api";

export const updateNewsDomainVinculation = async (
  vinculations: VinculateNewsInDomain[],
) => {
  const body = {
    vinculations,
  };

  const response = await api.put<void>(
    `/news-domain/v1`,
    body,
  );

  return response;
};
