import { useCallback, useEffect, useState } from 'react';
import { emailSignatureDomainSearchBaseEndpoint } from '../../../constants/endpoints';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { EmailSignatureDomain } from '../../../types/emailSignatureDomain';
import { findAllEmailSignaturesDomainByDomainIdPaginated } from '../../../services/emailSignatureDomain';
import { useParams } from 'react-router-dom';
import { Search, search } from '../../../services/search';
import html2canvas from 'html2canvas';

export const useEmailSignaturesListing = () => {
  const { domainId, signatureId } = useParams();

  const [signaturesImages, setSignaturesImages] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(true);
  const [isSignatureLoading, setIsSignatureLoading] = useState(true);

  const loadMore = useCallback(
    async (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      const response = await findAllEmailSignaturesDomainByDomainIdPaginated(
        +domainId!,
        page,
        direction,
        10,
        Number(signatureId),
      );
      return response;
    },
    [domainId, signatureId],
  );

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return search<EmailSignatureDomain>(
        baseUrl,
        searchValue,
        page,
        null,
        10,
        [{ name: 'signature', value: signatureId }],
      );
    },
    [signatureId],
  );

  const { itemsShown, endPageRef, searchItems, isSearchFetching } =
    useHandleTable(
      0,
      [],
      null,
      null,
      `${emailSignatureDomainSearchBaseEndpoint}/domain-id/${domainId}`,
      loadMore,
      null,
      doSearch as Search,
    );

  useEffect(() => {
    if (
      searchItems &&
      searchItems?.meta.currentPage >= searchItems?.meta.totalPages
    ) {
      setIsLoadMoreLoading(false);
    }
  }, [searchItems]);

  const emailSignatureDomainsShown = itemsShown as EmailSignatureDomain[];

  useEffect(() => {
    const generateSignaturesImages = async () => {
      await Promise.all(
        emailSignatureDomainsShown.map(async ({ id }) => {
          const element = document.getElementById(id.toString())!;

          const canvas = await html2canvas(element, {
            useCORS: true,
            height: 213,
            width: 640,
          });

          const imageData = canvas.toDataURL('image/png');
          setSignaturesImages((prev) => [...prev, imageData]);
        }),
      );
      setIsLoading(false);
    };

    if (!isSignatureLoading) {
      generateSignaturesImages();
    }
  }, [emailSignatureDomainsShown, isSignatureLoading]);

  const [image, setImage] = useState('');

  useEffect(() => {
    if (signaturesImages.length > 0) {
      // Pegue a primeira imagem do array
      setImage(signaturesImages[0]);
    }
  }, [signaturesImages]);

  const handleCopy = async () => {
    try {
      const res = await fetch(image);
      const imageBlob = await res.blob();

      const clipboardItems = [
        new ClipboardItem({
          [imageBlob.type]: imageBlob,
        }),
      ];

      await navigator.clipboard.write(clipboardItems);

      alert('Imagem copiada com sucesso!');
    } catch (error) {
      console.error(
        'Falha ao copiar a imagem para a área de transferência:',
        error,
      );
    }
  };

  return {
    isLoading,
    signaturesImages,
    isLoadMoreLoading,
    emailSignatureDomainsShown,
    endPageRef,
    isFetching: isSearchFetching,
    setIsSignatureLoading,
    handleCopy,
  };
};
