import { News, NewsListInPageSort, ReorderNewsDto } from '../types/news';
import {
  SaveNewsTypeDto,
  NewsType,
  UpdateNewsTypeDto,
  NewsTypePaginated,
  ReorderNewsTypeDto,
  ReorderNewsType,
} from '../types/newsType';
import generatePaginatedUrl, {
  addSearchParamsInUrl,
} from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getNewsTypesPaginated = async (
  page: number = 1,
  direction: 'ASC' | 'DESC' = 'ASC',
  limit?: number,
) => {
  const url = generatePaginatedUrl('/news-type/v1', page, limit, direction);
  const response = await api.get<NewsTypePaginated>(url);
  return response;
};

export const getNewsSortByTypeId = async (
  typeId: number,
  pagination?: {
    page?: number;
    direction?: 'ASC' | 'DESC' | null;
    limit?: number | null;
  } | null,
) => {
  const url = addSearchParamsInUrl(
    `/news/v1/news-by-type/${typeId}`,
    { name: 'page', value: pagination?.page },
    { name: 'limit', value: pagination?.limit },
    { name: 'direction', value: pagination?.direction },
  );

  const response = await api.get<NewsListInPageSort>(url);
  return response;
};

export const saveNewsType = async ({ name }: SaveNewsTypeDto) => {
  const body = {
    name,
  };

  const response = await api.post<NewsType>('/news-type/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const updateNewsType = async ({ id, name }: UpdateNewsTypeDto) => {
  const body = { id, name };

  const response = await api.put<NewsType>('/news-type/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const deleteNewsType = async (newsTypeId: number) => {
  const response = await api.delete<void>(`/news-type/v1/${newsTypeId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const reorderNewsByType = async (dto: ReorderNewsDto[]) => {
  const response = await api.put<News>(`/news/v1/reorder`, { newsList: dto });
  return response;
};

export const reorderNewsType = async (dto: ReorderNewsTypeDto[]) => {
  const response = await api.put<ReorderNewsType>(`/news-type/v1/reorder`, {
    newsType: dto,
  });
  return response;
};
