import { Box, Typography } from '@mui/material';
import SerchInput from '../../components/SearchInput';
import { secondaryHeaderStyle, titleStyle } from './styles';
import { usePartsProduct } from './useParts';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';
import ListingHeader from '../../components/ListingHeader';
import Modal from '../../components/Modal';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import SecondaryHeader from '../../components/SecondaryHeader';
import BackButton from '../../components/BackButton';
import { getImageFromApi } from '../../utils/getImageUrl';
import TableRoot from '../../components/Table/TableRoot';
import TableCell from '../../components/Table/TableCell';
import TableHead from '../../components/Table/TableHead';
import Alert from '../../components/Alert';
import Main from '../../components/Main';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import { useEffect, useState } from 'react';
import Table from '../../components/Table';
import LoadMore from '../../components/LoadMore';

export default function PartsProduct() {
  const {
    handleClickViewPartsCatalog,
    endPageRef,
    isLoadMoreFetching,
    partsCatalog,
    inputRef,
    sortField,
    partClicked,
    itemsLength,
    partsShown,
    isSearchInputDirty,
    isPartDescriptionOpen,
    productId,
    productNameDecoded,
    errorMessage,
    hasMultipleGroups,
    setHasMultipleGroups,
    backButtonPartsCatalog,
    clearError,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
    handleTableRowClick,
    handleCloseModal,
    handleOpenDescription,
    handleCloseDescription,
    handleGeneratePdf,
  } = usePartsProduct();

  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const handleExpandClick = (partId: number) => {
    setExpandedRow(expandedRow === partId ? null : partId);
  };

  const handleRetractRow = () => {
    setExpandedRow(null);
  };

  useEffect(() => {
    const value = inputRef.current?.value;
    if (value) {
      setExpandedRow(null);
    }
  }, [inputRef, inputRef?.current?.value]);

  return (
    <
      // component="main"
      // sx={{ ...mainStyle, backgroundColor: theme.palette.background.default }}
    >
      <ListingHeader
        backButton={!productId}
        returnPartsCatalog={backButtonPartsCatalog}
        partsCatalog={partsCatalog ? true : false}
        // pdfTop={productId ? '1.5rem' : ''}
        pdfSx={
          productId
            ? {
                '@media(max-width: 760px)': {
                  left: '0.5rem',
                  top: '1.25rem',
                  width: 'fit-content',
                },
              }
            : {}
        }
        onPdfClick={handleGeneratePdf}
      >
        {productId && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '1rem',
            }}
          >
            <Box>
              <BackButton sx={{ '@media(max-width:760px)': { mt: '1rem' } }} />
              <Typography
                component="h1"
                sx={{
                  ...titleStyle,
                  '@media(max-width: 760px)': {
                    margin: '2rem 0 0.5rem 0.5rem',
                  },
                }}
              >
                Peças
              </Typography>
            </Box>
            <Box>
              <SecondaryHeader
                itemId={productId}
                itemName={productNameDecoded}
                itemType="Produtos"
                sx={secondaryHeaderStyle}
                backButton={false}
                isEditable={false}
              />
            </Box>
          </Box>
        )}
        {!productId && (
          <Typography
            component="h1"
            sx={titleStyle}
            marginBottom={!productId ? '2.25rem' : ''}
          >
            Peças
          </Typography>
        )}
        <SerchInput
          ref={inputRef}
          onSearch={handleSearch}
          onButtonClick={handleCancelSearch}
          onCancelSearch={handleCancelSearch}
          showCancelOptions={isSearchInputDirty}
        />
        <TableHead>
          <Box className="flex">
            <TableCell
              items={partsShown}
              type="id"
              onSort={handleSortTable}
              isSortActive={sortField === 'id'}
              onClick={handleRetractRow}
            >
              Código
            </TableCell>
            <TableCell
              items={partsShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
              onClick={handleRetractRow}
            >
              Nome
            </TableCell>
          </Box>
          <Box className="flex w-full max-w-[22rem] justify-end gap-3 0.5sm:gap-5 md:justify-start md:gap-10">
            <Table.Cell
              items={partsShown}
              type="qtdTotal"
              onSort={handleSortTable}
              isSortActive={sortField === 'qtdTotal'}
              onClick={handleRetractRow}
            >
              Qtd. Total
            </Table.Cell>

            <TableCell items={partsShown} type="Conjunto de peça">
              Conjunto de peça
            </TableCell>
          </Box>
        </TableHead>
      </ListingHeader>
      <Main>
        {isLoadMoreFetching && (
          <Loading sx={productId ? { mt: '11rem' } : {}} />
        )}
        {!isLoadMoreFetching && (
          <TableRoot
            itemsLength={partsShown.length}
            itemType="Peças"
            className="pb-7"
          >
            {partsShown.map(({ id, name, qtdTotal, partsGroups, tag }) => {
              const hasMultiplPartsGroups = partsGroups.length > 1;

              const isExpanded = expandedRow === id;

              return (
                <>
                  <Accordion
                    type="single"
                    collapsible
                    className="w-full"
                    value={expandedRow?.toString() ?? ''} // << isso é o mais importante
                    onValueChange={(value) => {
                      const valueAsNumber = Number(value);
                      setExpandedRow(
                        isNaN(valueAsNumber) ? null : valueAsNumber,
                      );
                    }}
                  >
                    <AccordionItem value={id.toString()}>
                      {/* Linha principal */}
                      <div
                        key={id}
                        className={`flex ${isExpanded && hasMultiplPartsGroups ? '!bg-zinc-100' : ''} items-center border-b border-gray-300 px-4 hover:border-blue-500 sm:px-8 ${hasMultiplPartsGroups ? 'py-1' : 'py-2'}`}
                        onClick={() => {
                          handleTableRowClick(id, {
                            product: {
                              id: +productId!,
                              // associatedProductId
                            },
                            partsGroup: {
                              id: partsGroups[0]?.partsGroup?.id,
                              name: partsGroups[0]?.partsGroup?.name,
                              image: partsGroups[0]?.partsGroup?.image,
                            },
                            part: {
                              id,
                              name,
                            },
                            order: 0,
                            tag,
                            amount: qtdTotal,
                          });
                          if (hasMultiplPartsGroups) {
                            setHasMultipleGroups(false);
                          }
                        }}
                      >
                        <Box className="flex w-full items-center gap-8 0.5sm:gap-16">
                          <Table.CellStatic className="w-full max-w-[2rem]">
                            {id}
                          </Table.CellStatic>
                          <Table.CellStatic className="flex w-full text-start">
                            {name}
                          </Table.CellStatic>
                        </Box>
                        <Box className="flex w-full max-w-[13rem] items-center gap-16 0.5sm:gap-20 md:max-w-full md:justify-end md:gap-24">
                          <Table.CellStatic className="text-center">
                            {qtdTotal}
                          </Table.CellStatic>
                          <TableCell
                            items={partsShown}
                            className={`w-auto text-start md:w-full md:max-w-[15rem] ${isExpanded && hasMultiplPartsGroups ? '!bg-zinc-100' : ''}`}
                          >
                            {!hasMultiplPartsGroups ? (
                              <TableCell
                                items={partsShown}
                                className="w-full text-center"
                              >
                                {partsGroups[0]?.partsGroup?.name}
                              </TableCell>
                            ) : (
                              <AccordionTrigger asChild>
                                <TableCell
                                  items={partsShown}
                                  className={`w-10 text-center ${isExpanded && hasMultiplPartsGroups ? '!bg-zinc-100' : ''}`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleExpandClick(id);
                                  }}
                                >
                                  {hasMultiplPartsGroups && (
                                    <button className="text-xl">
                                      {isExpanded ? '−' : '+'}
                                    </button>
                                  )}
                                </TableCell>
                              </AccordionTrigger>
                            )}
                          </TableCell>
                        </Box>
                      </div>
                      <AccordionContent className="overflow-hidden bg-zinc-100 text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                        {hasMultiplPartsGroups &&
                          partsGroups.map(({ partsGroup, qtdInGroup }: any) => (
                            <div
                              key={partsGroup?.id}
                              className={`flex items-center justify-between border-b border-gray-300 px-4 py-3 hover:border-blue-500`}
                              onClick={() => {
                                handleTableRowClick(id, {
                                  product: {
                                    id: +productId!,
                                  },
                                  partsGroup: {
                                    id: partsGroup?.id,
                                    name: partsGroup?.name,
                                    image: partsGroup?.image,
                                  },
                                  part: {
                                    id,
                                    name,
                                  },
                                  order: 0,
                                  tag,
                                  amount: qtdInGroup,
                                });
                                if (hasMultiplPartsGroups) {
                                  setHasMultipleGroups(true);
                                }
                              }}
                            >
                              <Box className="flex w-full items-center justify-end gap-12 px-0 sm:gap-16 md:max-w-full md:gap-24">
                                <TableCell
                                  items={partsShown}
                                  className="!bg-zinc-100"
                                >
                                  {qtdInGroup}
                                </TableCell>
                                <TableCell
                                  items={partsShown}
                                  className="w-full max-w-[9.5rem] !bg-zinc-100 text-start md:max-w-[16rem]"
                                >
                                  {partsGroup?.name}
                                </TableCell>
                              </Box>
                            </div>
                          ))}
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </>
              );
            })}
            <LoadMore
              isModal={false}
              isLoadMoreFetching={isLoadMoreFetching}
              isContentFetching={isLoadMoreFetching}
              ref={endPageRef}
            />
          </TableRoot>
        )}
      </Main>
      <Footer
        current={partsShown.length}
        total={itemsLength}
        itemType="Peças"
        // tableFooter={
        //   pagination <= partsLength && (
        //     <TableFooter
        //       currentPage={currentPage}
        //       itemsLength={partsLength}
        //       itemsShownLength={partsShownLength}
        //       pagination={pagination}
        //       nonPaginationName="Todas"
        //       onChangePage={handleChangePage}
        //       onChangePagination={handleChangePagination}
        //     />
        //   )
        // }
      />
      <Modal isOpen={!!partClicked} onClose={handleCloseModal}>
        {partClicked && (
          <Card.Root key={partClicked.id} sx={{ minHeight: '26rem' }}>
            <Card.Image
              src={getImageFromApi(partClicked.id)}
              alt={`Imagem do ${partClicked.name}`}
              fallbackSrc="images/unavailable.png"
              fallbackAlt="Imagem de produto indisponível"
            />
            <Card.IsNew text={partClicked.new ? 'Novo' : ''} />
            <Card.Infos
              productId={partClicked.id}
              productName={partClicked.name}
            />
            <Card.Actions>
              <Card.Action text="Descrição" onClick={handleOpenDescription} />
              <Link
                to={`/part-compatibilities/${partClicked.id}/${encodeURIComponent(partClicked?.name)}`}
              >
                <Card.Action text="Compatibilidade" />
              </Link>
              {hasMultipleGroups && (
                <Card.Action
                  onClick={handleClickViewPartsCatalog}
                  text="Ver no catalogo"
                />
              )}
            </Card.Actions>
            <Modal
              isOpen={isPartDescriptionOpen}
              onClose={handleCloseDescription}
            >
              <Card.Root>
                <h2 className="mb-8 text-center text-xl">Descrição</h2>
                <Card.Image
                  src={getImageFromApi(partClicked.id)}
                  alt={`Imagem do ${partClicked.name}`}
                  fallbackSrc="images/unavailable.png"
                  fallbackAlt="Imagem de produto indisponível"
                />
                <Card.IsNew text={partClicked.new ? 'Novo' : ''} />
                <Card.Infos
                  productId={partClicked.id}
                  productName={partClicked.name}
                />
                <Card.Table productId={partClicked.id} sx={{ mt: '2rem' }} />
              </Card.Root>
            </Modal>
          </Card.Root>
        )}
      </Modal>

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
