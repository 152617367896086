import { Typography } from '@mui/material';
import { useParts } from './useParts';
import Modal from '../../components/Modal';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import { getImageFromApi } from '../../utils/getImageUrl';
import ItemModal from '../../components/Admin/ItemModal';
import { searchCache } from '../../constants/requestCacheName';
import { partSchema } from '../../validations/schemas';
import { deletePart, savePart, updatePart } from '../../services/parts';
import PartsContent from '../../components/PartsContent';
import { partSearchBaseEndpoint } from '../../constants/endpoints';

export default function Parts() {
  const {
    partClicked,
    isPartDescriptionOpen,
    partFieldsCurrentValue,
    isPartModalOpen,
    handleAddIconClick,
    handleTablePartEditClick,
    handleClosePartModal,
    handleTableRowClick,
    handleCloseModal,
    handleOpenDescription,
    handleCloseDescription,
  } = useParts();

  return (
    <>
      <PartsContent
        onTableRowClick={handleTableRowClick}
        onAddIconClick={handleAddIconClick}
        onTablePartEditClick={handleTablePartEditClick}
      />
      <Modal isOpen={!!partClicked} onClose={handleCloseModal}>
        {partClicked && (
          <Card.Root key={partClicked.id} sx={{ minHeight: '26rem' }}>
            <Card.Image
              src={getImageFromApi(partClicked.id)}
              alt={`Imagem do ${partClicked.name}`}
              fallbackSrc="images/unavailable.png"
              fallbackAlt="Imagem de produto indisponível"
            />
            <Card.IsNew text={partClicked.new ? 'Novo' : ''} />
            <Card.Infos
              productId={partClicked.id}
              productName={partClicked.name}
            />
            <Card.Actions>
              <Card.Action text="Descrição" onClick={handleOpenDescription} />
              <Link
                to={`/part-compatibilities/${partClicked.id}/${encodeURIComponent(partClicked.name)}`}
              >
                <Card.Action text="Compatibilidade" />
              </Link>
            </Card.Actions>
            <Modal
              isOpen={isPartDescriptionOpen}
              onClose={handleCloseDescription}
            >
              <Card.Root>
                <h2 className="mb-8 text-center text-xl">Descrição</h2>
                <Card.Image
                  src={getImageFromApi(partClicked.id)}
                  alt={`Imagem do ${partClicked.name}`}
                  fallbackSrc="images/unavailable.png"
                  fallbackAlt="Imagem de produto indisponível"
                />
                <Card.IsNew text={partClicked.new ? 'Novo' : ''} />
                <Card.Infos
                  productId={partClicked.id}
                  productName={partClicked.name}
                />
                <Card.Table productId={partClicked.id} sx={{ mt: '2rem' }} />
              </Card.Root>
            </Modal>
          </Card.Root>
        )}
      </Modal>
      <ItemModal
        editTitle="Peça"
        addTitle="Nova peça"
        isModalOpen={isPartModalOpen}
        onModalClose={handleClosePartModal}
        type="part"
        // queryKey={partsPaginatedCache}
        queryKey={`${searchCache}${partSearchBaseEndpoint}`}
        validationSchema={partSchema}
        initialValue={partFieldsCurrentValue}
        saveService={savePart}
        updateService={updatePart}
        deleteService={deletePart}
      />
    </>
  );
}
