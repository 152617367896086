import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import Footer from '../../../components/Footer';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import ListingHeader from '../../../components/ListingHeader';
import Loading from '../../../components/Loading';
import Main from '../../../components/Main';
import Modal from '../../../components/Modal';
import SerchInput from '../../../components/SearchInput';
import Table from '../../../components/Table';
import { ImageTypes } from '../../../enums/imageTypes';
import { useLines } from './useLines';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImagesContent from '../../../components/ImagesContent';
import EditButton from '../../../components/EditButton';

export default function Lines() {
  const {
    lines,
    isFetching,
    linesShown,
    inputRef,
    isMenageModalOpen,
    isShowEditButton,
    isConfirmModalOpen,
    errors,
    isSearchInputDirty,
    currentImageType,
    sortField,
    lineIdUpdate,
    dirtyFields,
    isSelectImageModalOpen,
    currentMenuImage,
    errorMessage,
    imagesInUse,
    canEditOrder,
    validUrls,
    defineItemsShown,
    handleButtonCancelEditClick,
    handleChangeEditOrderButtonClick,
    handleDragEnd,
    clearError,
    line,
    handleInputImageClick,
    handleSelectImage,
    handleCloseSelectImageModal,
    handleSearch,
    handleClearLine,
    handleTableRowClick,
    handleSortTable,
    handleAddLineClick,
    handleCloseMenageModal,
    handleButtonOkClick,
    handleSubmit,
    register,
    handleEdit,
    handleDeleteButtonClick,
    handleCloseConfirmModal,
    handleConfirmDelete,
    handleCancelSearch,
    currentCarouselImage,
  } = useLines();

  return (
    <>
      <ListingHeader
        add
        onAddIconClick={handleAddLineClick}
        pdfTop="3rem"
        pdf={false}
      >
        <div className="mt-4">
          <div className="mb-4 flex justify-between">
            <h1 className="text-2xl">Linha</h1>

            <EditButton
              setContentList={defineItemsShown}
              // Para retornar ao estado anterior da pesquisa, colocar linesShown
              contentList={lines?.items ?? []}
              finishMessage="Salvar ordem"
              canEdit={canEditOrder}
              editMessage="Editar ordem"
              cancelMessage='Cancelar ordenação'
              onCancelEditClick={handleButtonCancelEditClick}
              onEditButtonClick={handleChangeEditOrderButtonClick}
            />
          </div>
          <SerchInput
            ref={inputRef}
            onSearch={handleSearch}
            onButtonClick={handleCancelSearch}
            showCancelOptions={isSearchInputDirty}
            onCancelSearch={handleCancelSearch}
            disabled={canEditOrder}
          />
        </div>

        <Table.Head>
          <Table.Cell
            items={linesShown}
            type="name"
            onSort={handleSortTable}
            isSortActive={sortField === 'name'}
          >
            Linhas
          </Table.Cell>
        </Table.Head>
      </ListingHeader>

      <Main>
        {isFetching && <Loading />}
        {!isFetching && lines && (
          <Table.Root itemType="Linhas" itemsLength={lines.meta.totalItems}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                isDropDisabled={!canEditOrder}
                droppableId="lines"
                type="list"
                direction="vertical"
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {linesShown.map((line, index) => (
                      <Table.Row
                        key={line.id}
                        onClick={() =>
                          handleTableRowClick(
                            line.id,
                            line.name,
                            line.menuImage,
                            line.carouselImage,
                          )
                        }
                      >
                        <Draggable
                          isDragDisabled={!canEditOrder}
                          draggableId={line.id.toString()}
                          index={index}
                          key={line.id}
                        >
                          {(provided) => (
                            <div
                              className="flex w-full items-center justify-between bg-backgroundPaper"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Table.Cell
                                items={lines.items}
                                type="name"
                                className=""
                                onSort={handleSortTable}
                              >
                                {line.name}
                              </Table.Cell>

                              {canEditOrder && (
                                <img
                                  src="/icons/drag.png"
                                  alt="Ícone para arrastar linha"
                                  className="mr-4 size-5 cursor-grab"
                                />
                              )}
                            </div>
                          )}
                        </Draggable>
                      </Table.Row>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Table.Root>
        )}

        <Footer
          current={linesShown.length}
          total={linesShown.length}
          itemType="Linhas"
        />

        <Modal
          isOpen={isMenageModalOpen}
          onClose={handleCloseMenageModal}
          cancelIcon={false}
        >
          <HeaderModalManageItem
            showDeleteButton={!!lineIdUpdate}
            deleteButtonContent="Apagar linha"
            onDeleteButtonClick={handleDeleteButtonClick}
            isEditable={!isShowEditButton}
            title={lineIdUpdate ? 'Linha' : 'Nova linha'}
            mainButtonContent={isShowEditButton ? 'Editar' : 'Ok'}
            onCancelClick={handleCloseMenageModal}
            onOkClick={
              !isShowEditButton ? handleSubmit(handleButtonOkClick) : undefined
            }
            onEditClick={isShowEditButton ? handleEdit : undefined}
          />
          <div className="flex flex-col gap-4 pb-4">
            <div className="mr-24 mt-24 w-full">
              <Input
                maxLength={58}
                type="text"
                placeholder="Linha"
                disabled={isShowEditButton}
                helperText={errors.line?.message}
                isDirty={dirtyFields.line}
                onCancelIconClick={handleClearLine}
                {...register('line')}
                value={line}
              />
            </div>
            <div>
              <div
                onClick={() => handleInputImageClick(ImageTypes.linesCarousel)}
                className="flex cursor-pointer items-center gap-6 border-b-[1px]"
              >
                <Input
                  readOnly
                  type="text"
                  disabled={isShowEditButton}
                  label="Imagem do carrossel"
                  className="cursor-pointer border-none p-0"
                  inputStyle="cursor-pointer"
                  isDirty={!!dirtyFields.carouselImage}
                  {...register('carouselImage')}
                  value={currentCarouselImage}
                />
                <ChevronRightIcon className="text-secondaryText" />
              </div>
              <span className="text-xs text-red-600">
                {errors.carouselImage?.message}
              </span>
            </div>
            {validUrls.carouselImage ? (
              <img
                src={validUrls.carouselImage}
                alt="Preview da imagem selecionada do carrossel das linhas"
                className="mx-auto max-h-72 w-fit"
              />
            ) : (
              <div></div>
              // lineIdUpdate && (
              //   <div className="text-xs text-red-600">
              //     Não foi encontrada a refência da imagem, por favor substitua-a
              //     por outra.
              //   </div>
              // )
            )}
            <div>
              <div
                onClick={() => handleInputImageClick(ImageTypes.linesMenu)}
                className="flex cursor-pointer items-center gap-6 border-b-[1px]"
              >
                <Input
                  readOnly
                  type="text"
                  disabled={isShowEditButton}
                  label="Imagem do menu"
                  className="cursor-pointer border-none p-0"
                  inputStyle="cursor-pointer"
                  isDirty={!!dirtyFields.menuImage}
                  {...register('menuImage')}
                  value={currentMenuImage}
                />
                <ChevronRightIcon className="text-secondaryText" />
              </div>
              <span className="text-xs text-red-600">
                {errors.menuImage?.message}
              </span>
            </div>
            {validUrls.menuImage ? (
              <img
                src={validUrls.menuImage}
                alt="Preview da imagem selecionada do menu das linhas"
                className="mx-auto max-h-72 w-fit"
              />
            ) : (
              <div></div>
              // lineIdUpdate && (
              //   <div className="text-xs text-red-600">
              //     Não foi encontrada a refência da imagem, por favor substitua-a
              //     por outra.
              //   </div>
              // )
            )}
          </div>
        </Modal>
        <ConfirmModal
          contentMessage="Deseja excluir essa linha?"
          isModalOpen={isConfirmModalOpen}
          onCloseModal={handleCloseConfirmModal}
          onConfirm={handleConfirmDelete}
          onNotConfirm={handleCloseConfirmModal}
        />

        <Modal
          isOpen={isSelectImageModalOpen}
          onClose={handleCloseSelectImageModal}
          width="50rem"
          contentStyle="h-[100vh]"
        >
          <ImagesContent
            onTableRowClick={handleSelectImage}
            isModal
            itemsInUse={imagesInUse}
            imageType={currentImageType.current}
          />
        </Modal>
      </Main>

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
