import { useRef } from 'react';
import { useModal } from '../../../hooks/useModal';
import { useEdit } from '../../../hooks/useEdit';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  ImageType,
  ImageTypeFormData,
  ImageTypesPaginated,
  SaveImageTypeDto,
  UpdateImageTypeDto,
} from '../../../types/imageTypes';
import { imageTypeSchema } from '../../../validations/schemas';
import { AxiosError, isAxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import {
  imageTypesCache,
  searchCache,
} from '../../../constants/requestCacheName';
import { useQueryCache } from '../../../hooks/useQueryCache';
import {
  deleteImageType,
  saveImageType,
  updateImageType,
} from '../../../services/imageTypes';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { imageExtensionSearchBaseEndpoint } from '../../../constants/endpoints';
import { getAllImageExtensions } from '../../../services/imageExtension';
import { FindAllImageExtensions } from '../../../types/imageExtension';

export const useImageTypes = () => {
  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    reset,
    watch
  } = useForm<ImageTypeFormData>({
    resolver: zodResolver(imageTypeSchema),
    defaultValues: {
      imageType: '',
      extension: '',
      maxSize: '',
    },
  });

  const maxSize = watch('maxSize')
  const imageType = watch('imageType')

  const { data: imageExtensions } = useQuery<FindAllImageExtensions>(
    `${searchCache}${imageExtensionSearchBaseEndpoint}`,
    async () => (await getAllImageExtensions()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: saveImageTypeMutate } = useMutation({
    mutationFn: async ({ name, extensionId, maxSize }: SaveImageTypeDto) => {
      return (await saveImageType({ name, extensionId, maxSize })).data;
    },

    onSuccess: (data) => {
      addItemOnScreen<ImageTypesPaginated>(imageTypesCache, data);
      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar tipo de imagem',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Tipo de imagem já cadastrado');
            return true;
          }
        },
      );
    },
  });

  const { mutate: updateImageTypeMutate } = useMutation({
    mutationFn: async ({
      id,
      name,
      extensionId,
      imageTypeExtensionId,
      maxSize,
    }: UpdateImageTypeDto) => {
      return (
        await updateImageType({
          id,
          name,
          extensionId,
          imageTypeExtensionId,
          maxSize,
        })
      ).data;
    },

    onSuccess: (data) => {
      updateItemOnScreen<ImageTypesPaginated>(imageTypesCache, data);
      // handleCloseMenageModal();
      handleUnedit()
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar tipo de imagem',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Tipo de imagem já cadastrado');
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteImageTypeMutate } = useMutation({
    mutationFn: async () => {
      if (imageTypesIdUpdate.current) {
        await deleteImageType(imageTypesIdUpdate.current);
        return;
      }

      setErrorMessage('Falha ao deletar tipo de imagem');
    },

    onSuccess: () => {
      if (imageTypesIdUpdate.current) {
        removeItemFromScreen<ImageTypesPaginated>(
          imageTypesCache,
          imageTypesIdUpdate.current,
        );
      }

      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar tipo de imagem',
        setErrorMessage,
        () => {
          if (isAxiosError(error) && error.response?.status === 409) {
            setErrorMessage(
              'Este tipo de imagem só pode ser apagado depois que não houver itens associados a ele',
            );
            return true;
          }
        },
      );
    },
  });

  const imageTypesIdUpdate = useRef<number | null>(null);
  const imageTypeExtensionIdUpdate = useRef<number | null>(null);

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: closeMenageModal,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: handleCloseConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const handleTableRowClick = (imageType: ImageType) => {
    imageTypesIdUpdate.current = imageType.id;
    imageTypeExtensionIdUpdate.current = imageType.imageTypeExtensions[0]?.id;
    const imageTypeExtension = imageType.imageTypeExtensions[0];

    setValue('imageType', imageType.name);
    setValue(
      'extension',
      imageTypeExtension?.imageExtension?.id.toString() ?? '',
    );
    setValue('maxSize', imageTypeExtension?.maxSize.toString());
    handleOpenMenageModal();
  };

  const handleAddImageTypeClick = () => {
    handleOpenMenageModal();
  };

  const handleCloseMenageModal = () => {
    imageTypesIdUpdate.current = null;
    imageTypeExtensionIdUpdate.current = null;
    reset();
    handleUnedit();
    closeMenageModal();
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmDeleteModal();
  };

  const handleConfirmDelete = () => {
    deleteImageTypeMutate();
    handleCloseConfirmDeleteModal();
  };

  const handleButtonOkClick = ({
    imageType,
    extension,
    maxSize,
  }: ImageTypeFormData) => {
    if (imageTypesIdUpdate.current) {
      updateImageTypeMutate({
        id: imageTypesIdUpdate.current,
        name: imageType,
        extensionId: +extension,
        maxSize: +maxSize,
        imageTypeExtensionId: imageTypeExtensionIdUpdate.current,
      });
      return;
    }

    saveImageTypeMutate({
      name: imageType,
      extensionId: +extension,
      maxSize: +maxSize,
    });
  };

  const handleClearImageType = (value: keyof ImageTypeFormData) => {
    setValue(value, '', { shouldDirty: true });
  };

  const isShowEditButton = !!imageTypesIdUpdate.current && !canEdit;

  return {
    maxSize,
    imageType,
    isMenageModalOpen,
    imageTypesIdUpdate: imageTypesIdUpdate.current,
    isShowEditButton,
    errors,
    dirtyFields,
    isConfirmDeleteModalOpen,
    errorMessage,
    imageExtensions,
    clearError,
    handleConfirmDelete,
    handleCloseConfirmDeleteModal,
    handleTableRowClick,
    handleClearImageType,
    register,
    handleSubmit,
    handleEdit,
    handleButtonOkClick,
    handleCloseMenageModal,
    handleAddImageTypeClick,
    handleDeleteButtonClick,
  };
};
