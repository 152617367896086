import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useCarouselBuffalo } from './useCarouselBuffalo';
import CardSkeleton from '../CardSkeleton';
import { Link } from 'react-router-dom';
import { getImageFromApi } from '../../../utils/getImageUrl';
import Card from '../CardBuffalo/index';
import process from 'process';
import CarouselRightArrowBuffalo from '../CarouselRightArrowBuffalo';
import CarouselLeftArrowBuffalo from '../CarouselLeftArrowBuffalo';
import style from './styles.module.css';
import CarouselDotBuffalo from '../CarouselDotBuffalo';
import { twMerge } from 'tailwind-merge';

type CorouselBuffaloProps = {
  categoryId: number;
  productId: number;
  lineId: number;
};

export default function CorouselBuffalo({
  categoryId,
  productId,
  lineId,
}: CorouselBuffaloProps) {
  const {
    responsive,
    carouselProductsShown,
    isImageLoading,
    handleImageWasLoaded,
    handleLoadStart,
  } = useCarouselBuffalo(categoryId, productId);

  return (
    <div>
      {carouselProductsShown && (
        <Carousel
          responsive={responsive}
          showDots={true}
          customRightArrow={<CarouselRightArrowBuffalo />}
          customLeftArrow={<CarouselLeftArrowBuffalo />}
          containerClass="pb-8"
          customDot={<CarouselDotBuffalo />}
        >
          {carouselProductsShown.map((product) => (
            <Link
              key={product.id}
              to={{
                pathname: `/produto/${product.id}/${encodeURIComponent(product.name)}/categoria/${categoryId}/linha/${lineId}`,
                // search: `categoryId=${categoryId}`,
              }}
            >
              <Card.Root border className="ml-1 mr-6 py-8">
                {isImageLoading && <CardSkeleton />}

                <div
                  data-loading={isImageLoading}
                  className="block data-[loading=true]:hidden"
                >
                  <Card.Image
                    src={getImageFromApi(
                      product.id,
                      product.id < 50000 ? 'parts' : 'products',
                    )}
                    alt={`Imagem do ${product.name}`}
                    fallbackSrc={`/images/unavailable.png`}
                    fallbackAlt="Imagem de produto indisponível"
                    onLoadStart={handleLoadStart}
                    onLoad={handleImageWasLoaded}
                  />
                  <div>
                    <Card.Infos
                      productId={product.id}
                      productName={product.name}
                      className={twMerge(
                        'mx-auto w-fit text-center',
                        style.cardInfos,
                      )}
                    />
                  </div>

                  <div className="flex h-10 justify-center">
                    <Card.Actions className="w-1/2 rounded-lg border-2 border-yellow-400 text-center text-sm transition-all hover:bg-zinc-200">
                      <Card.Action text="Mais detalhes" />
                    </Card.Actions>
                  </div>
                </div>
              </Card.Root>
            </Link>
          ))}
        </Carousel>
      )}
    </div>
  );
}
