import { useMemo, useRef, useState } from 'react';
import { useModal } from '../../../hooks/useModal';
import { useTitle } from '../../../hooks/useTitle';
import { useEdit } from '../../../hooks/useEdit';
import { useForm } from 'react-hook-form';
import {
  FindAllUserTypes,
  SaveUserType,
  UpdateUserType,
  UserTypesFormData,
} from '../../../types/userType';
import { zodResolver } from '@hookform/resolvers/zod';
import { userTypesSchema } from '../../../validations/schemas';
import {
  pagesByUserTypeCache,
  userTypesCache,
} from '../../../constants/requestCacheName';
import { useMutation, useQuery } from 'react-query';
import {
  deleteUserType,
  saveUserType,
  updateUserType,
} from '../../../services/userType';
import { AxiosError, isAxiosError } from 'axios';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { getAllPagesByUserTypeId } from '../../../services/userTypePages';
import { FindAllPages, Pages } from '../../../types/userTypePages';
import { Page } from '../../../types/pages';
import { ItemInUse } from '../../../types/inUse';
import { adminName } from '../../../constants/rules';

export const useUserTypes = () => {
  useTitle('Tipos de usuários');

  const userTypeIdUpdate = useRef<number | null>(null);

  const pageIdsToAdd = useRef<number[]>([]);
  const pageIdsToRemove = useRef<number[]>([]);
  const currentUserTypeName = useRef('');

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<UserTypesFormData>({
    resolver: zodResolver(userTypesSchema),
    defaultValues: {
      userType: '',
    },
  });

  const userType = watch('userType')
  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { refetch: refetchUserTypePages } = useQuery<FindAllPages>(
    pagesByUserTypeCache,
    async () => {
      return (await getAllPagesByUserTypeId(userTypeIdUpdate.current!)).data;
    },
    {
      retry: false,
      enabled: false,

      onSuccess: (data) => {
        setUserTypePages(data.items);
      },
    },
  );

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: saveUserTypesMutate } = useMutation({
    mutationFn: async ({ role }: SaveUserType) => {
      return (await saveUserType({ role })).data;
    },

    onSuccess: (data) => {
      addItemOnScreen<FindAllUserTypes>(userTypesCache, data);
      realCloseManageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar tipo de usuário',
        setErrorMessage,
        () => {
          if (
            isAxiosError(error) &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Tipo de usuário já cadastrado');
            return true;
          }
        },
      );
    },
  });

  const { mutate: updateUserTypeMutate } = useMutation({
    mutationFn: async ({
      id,
      role,
      pagesToAdd,
      pagesToRemove,
    }: UpdateUserType) => {
      return (await updateUserType({ id, role, pagesToAdd, pagesToRemove }))
        .data;
    },

    onSuccess: (data) => {
      updateItemOnScreen<FindAllUserTypes>(userTypesCache, data);
      // realCloseManageModal();
      handleUnedit()
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar tipo de usuário',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Tipo de usuário já cadastrado');
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteUserMutate } = useMutation({
    mutationFn: async () => {
      await deleteUserType({ userTypeId: userTypeIdUpdate.current! });
    },

    onSuccess: () => {
      removeItemFromScreen<FindAllUserTypes>(
        userTypesCache,
        userTypeIdUpdate.current!,
      );
      realCloseManageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar tipo de usuário',
        setErrorMessage,
        () => {
          if (isAxiosError(error) && error.response?.data.statusCode === 409) {
            setErrorMessage(
              'Este tipo de usuário só pode ser apagado depois que não houver associações a ele',
            );
            return true;
          }
        },
      );
    },
  });

  const {
    isModalOpen: isManageModalOpen,
    handleCloseModal: closeManageModal,
    handleOpenModal: handleOpenManageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: handleCloseConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  const {
    isModalOpen: isSelectModalOpen,
    handleCloseModal: handleCloseSelectModal,
    handleOpenModal: handleOpenSelectModal,
  } = useModal();

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const [currentPageToRemoveId, setCurrentPageToRemoveId] = useState<
    number | null
  >(null);
  const [userTypePages, setUserTypePages] = useState<Pages[]>([]);

  const pagesInUse: ItemInUse[] = useMemo(
    () =>
      userTypePages.map(({ page }) => ({
        id: page.id,
      })),
    [userTypePages],
  );

  const handleAddUserTypesClick = () => {
    handleOpenManageModal();
  };

  const handleTableRowClick = (userTypeId: number, userRole: string) => {
    currentUserTypeName.current = userRole;
    userTypeIdUpdate.current = userTypeId;
    refetchUserTypePages();
    setValue('userType', userRole);
    handleOpenManageModal();
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmDeleteModal();
  };

  const handleRemovePageButtonClick = (
    pageId: number,
    vinculationId: number,
  ) => {
    pageIdsToAdd.current = pageIdsToAdd.current.filter(
      (pageIdAdd) => pageIdAdd !== pageId,
    );

    if (vinculationId > 0) {
      pageIdsToRemove.current.push(pageId);
    }

    setUserTypePages((prevUserTypePages) =>
      prevUserTypePages.filter(
        (userTypePage) => userTypePage.page.id !== pageId,
      ),
    );
  };

  const handleAddPageClick = () => {
    handleOpenSelectModal();
  };

  const removeRepeatedInAddAndDeleted = () => {
    const comuns = pageIdsToAdd.current.filter((num) =>
      pageIdsToRemove.current.includes(num),
    );
    const addPages = pageIdsToAdd.current.filter((id) => !comuns.includes(id));
    const removePages = pageIdsToRemove.current.filter(
      (id) => !comuns.includes(id),
    );

    return [addPages, removePages];
  };

  const handleButtonOkClick = ({ userType }: UserTypesFormData) => {
    if (userTypeIdUpdate.current) {
      const [addPages, removePages] = removeRepeatedInAddAndDeleted();
      updateUserTypeMutate({
        id: userTypeIdUpdate.current,
        role: userType,
        pagesToAdd: addPages,
        pagesToRemove: removePages,
      });
      return;
    }

    saveUserTypesMutate({ role: userType });
  };

  const handleEditButtonClick = () => {
    handleEdit();
  };

  const realCloseManageModal = () => {
    setUserTypePages([]);
    userTypeIdUpdate.current = null;
    currentUserTypeName.current = '';
    setCurrentPageToRemoveId(null);
    reset();
    handleUnedit();
    closeManageModal();
  };

  const handleCloseManageModal = () => {
    if (!isShowEditButton && userTypeIdUpdate.current) {
      setCurrentPageToRemoveId(null);
      // reset();
      handleUnedit();
      return;
    }

    realCloseManageModal();
  };

  const handleCloseSelectPageModal = () => {
    handleCloseSelectModal();
  };

  const handleConfirmDeleteUser = () => {
    deleteUserMutate();
    handleCloseConfirmDeleteModal();
  };

  const handleClearField = (fieldName: 'userType') => {
    setValue(fieldName, '', { shouldDirty: true });
  };

  const handleRemoveIconClick = (pageId: number) => {
    if (pageId === currentPageToRemoveId) {
      setCurrentPageToRemoveId(null);
      return;
    }

    setCurrentPageToRemoveId(pageId);
  };

  const handleSelectPage = (page: Page) => {
    pageIdsToAdd.current.push(page.id);
    pageIdsToRemove.current.filter(
      (pageIdToRemove) => pageIdToRemove !== page.id,
    );
    setUserTypePages((prevPages) => [...prevPages, { id: -1, page }]);
    handleCloseSelectPageModal();
  };

  const isShowEditButton = !!userTypeIdUpdate.current && !canEdit;

  const manageModalMainButtonContent = useMemo(() => {
    if (currentUserTypeName.current === adminName) return '';

    if (userTypeIdUpdate.current && isShowEditButton) return 'Editar';

    return 'Ok';
  }, [isShowEditButton]);

  return {
    isManageModalOpen,
    isShowEditButton,
    userType,
    userIdUpdate: userTypeIdUpdate.current,
    errors,
    dirtyFields,
    isConfirmDeleteModalOpen,
    errorMessage,
    userTypePages,
    currentPageToRemoveId,
    isSelectModalOpen,
    pagesInUse,
    manageModalMainButtonContent,
    currentUserTypeName: currentUserTypeName.current,
    handleSelectPage,
    handleCloseSelectPageModal,
    handleAddPageClick,
    handleRemovePageButtonClick,
    handleRemoveIconClick,
    clearError,
    handleConfirmDeleteUser,
    handleCloseConfirmDeleteModal,
    handleClearField,
    handleEditButtonClick,
    register,
    handleSubmit,
    handleButtonOkClick,
    handleDeleteButtonClick,
    handleTableRowClick,
    handleAddUserTypesClick,
    handleCloseManageModal,
  };
};
