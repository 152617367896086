import { ReactNode } from 'react';
import Loading from '../../Loading';
import Modal from '../../Modal';
import { useSelectModal } from './useSelectModal';
import { AxiosResponse } from 'axios';

type SelectModalProps = {
  title: string;
  isModalOpen: boolean;
  queryKey: string;
  children: ReactNode;
  headContent: ReactNode;
  footerContent: ReactNode;
  service: () => Promise<AxiosResponse>;
  onModalClose: () => void;
};

export default function SelectModal({
  title,
  isModalOpen,
  queryKey,
  children,
  headContent,
  footerContent,
  service,
  onModalClose,
}: SelectModalProps) {
  const { isItemFetching, item } = useSelectModal(
    isModalOpen,
    queryKey,
    service,
  );

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onModalClose}
      iconContainerStyle={{ right: '1rem' }}
    >
      <div className="flex h-[calc(85vh)] flex-col">
        <div>
          <h1 className="mb-4 mt-4 text-2xl">{title}</h1>
          {headContent}
        </div>

        <div className="flex-1">
          {isItemFetching && (
            <div className="mt-4">
              <Loading heightNormal />
            </div>
          )}
          {!isItemFetching && item && children}
        </div>

        {footerContent}
      </div>
    </Modal>
  );
}
