import { Content } from 'pdfmake/interfaces';

const header = (
  currentPage: number,
  productName: string,
  productId: number,
  productImage: string,
  logoImage: string,
): Content =>
  currentPage > 1
    ? [
        { svg: productImage, width: 50, height: 40, margin: 16 },
        {
          text: productName,
          absolutePosition: { x: 50 + 32, y: 24 },
          color: '#222',
        },
        {
          text: productId,
          absolutePosition: { x: 50 + 32, y: 24 + 16 },
          color: '#bababa',
        },
        {
          svg: logoImage,
          absolutePosition: { x: -16, y: 16 },
          alignment: 'right',
          width: 120,
          height: 40,
          marginRight: 16,
        },
      ]
    : [
        {
          svg: logoImage,
          alignment: 'right',
          width: 120,
          height: 40,
          margin: [0, 16, 8, 0],
        },
      ];

export default header;
