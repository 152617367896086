import SecondaryHeader from '../../components/SecondaryHeader';
import SerchInput from '../../components/SearchInput';
import { Box, Typography } from '@mui/material';
import { secondaryHeaderStyle } from './styles';
import { usePartCompatibilities } from './usePartCompatibilities';
import ListingHeader from '../../components/ListingHeader';
import Footer from '../../components/Footer';
import BackButton from '../../components/BackButton';
import TableHead from '../../components/Table/TableHead';
import TableCell from '../../components/Table/TableCell';
import TableRoot from '../../components/Table/TableRoot';
import TableRow from '../../components/Table/TableRow';
import Alert from '../../components/Alert';
import Main from '../../components/Main';

export default function PartCompatibilities() {
  const {
    sortField,
    partId,
    partNameDecoded,
    inputRef,
    isSearchInputDirty,
    partsCompatibilitiesLength,
    partsCompatibilitiesShown,
    partsCompatibilitiesShownLength,
    errorMessage,
    partsGroupWidth,
    clearError,
    handleCancelSearch,
    handleSearch,
    handleTableRowClick,
    handleSortTable,
    handleGeneratePdf,
  } = usePartCompatibilities();

  return (
    <>
      <ListingHeader
        backButton={false}
        pdfTop="1.5rem"
        onPdfClick={handleGeneratePdf}
        pdfSx={{
          '@media(max-width: 760px)': {
            left: '1rem',
            top: '1.3rem',
            width: 'fit-content',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '1rem',
          }}
        >
          <Box sx={{ '@media(max-width: 760px)': { ml: '1rem' } }}>
            <BackButton
              sx={{
                '@media(max-width: 760px)': {
                  mt: '1rem',
                },
              }}
            />
            <Typography
              component="h1"
              fontSize="1.5rem"
              sx={{
                margin: '1rem 0',
                '@media(max-width: 760px)': {
                  margin: '2rem 0 0 0',
                },
              }}
            >
              Compatibilidade
            </Typography>
          </Box>
          <Box>
            <SecondaryHeader
              itemId={partId}
              itemName={partNameDecoded}
              itemType="Peças"
              sx={secondaryHeaderStyle}
              backButton={false}
              isEditable={false}
            />
          </Box>
        </Box>
        <SerchInput
          ref={inputRef}
          onSearch={handleSearch}
          onButtonClick={handleCancelSearch}
          showCancelOptions={isSearchInputDirty}
          onCancelSearch={handleCancelSearch}
        />
        <TableHead>
          <Box className="flex">
            <TableCell
              items={partsCompatibilitiesShown}
              type="id"
              onSort={handleSortTable}
              isSortActive={sortField === 'id'}
            >
              Código
            </TableCell>
            <TableCell
              items={partsCompatibilitiesShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
            >
              Nome
            </TableCell>
          </Box>
          <TableCell
            items={partsCompatibilitiesShown}
            type="partGroupName"
            onSort={handleSortTable}
            isSortActive={sortField === 'partGroupName'}
            sx={{ width: `${partsGroupWidth}ch`, p: 0, borderBottom: 'none' }}
          >
            Conjunto de peças
          </TableCell>
        </TableHead>
      </ListingHeader>

      <Main>
        <TableRoot
          itemsLength={partsCompatibilitiesShown.length}
          itemType="Produtos"
        >
          {partsCompatibilitiesShown.map((product) => (
            <TableRow
              key={`${product.id}${product.partId}${product.partGroupName}`}
              onClick={() =>
                handleTableRowClick(
                  product.id,
                  product.partId ?? '',
                  product.partGroupName ?? '',
                  product.name,
                )
              }
            >
              <Box className="flex">
                <TableCell items={partsCompatibilitiesShown} type="id">
                  {product.id}
                </TableCell>
                <TableCell
                  items={partsCompatibilitiesShown}
                  type="name"
                  className=""
                >
                  {product.name}
                </TableCell>
              </Box>
              <TableCell
                items={partsCompatibilitiesShown}
                type="partGroupName"
                sx={{
                  width: `${partsGroupWidth}ch`,
                  p: 0,
                  borderBottom: 'none',
                }}
              >
                {product.partGroupName}
              </TableCell>
            </TableRow>
          ))}
        </TableRoot>
      </Main>

      {/* <Table
        products={partsCompatibilitiesShown}
        sx={tableStyle}
        headStyle={tableHeadStyle}
        onRowClick={handleTableRowClick}
        onSortTable={handleSortTable}
        allLinesColor={theme.palette.background.paper}
      /> */}
      <Footer
        current={partsCompatibilitiesShownLength}
        total={partsCompatibilitiesLength}
        itemType="Produtos"
        // tableFooter={
        //   pagination <= partsCompatibilitiesLength && (
        //     <TableFooter
        //       currentPage={currentPage}
        //       itemsLength={partsCompatibilitiesLength}
        //       itemsShownLength={partsCompatibilitiesShownLength}
        //       pagination={pagination}
        //       onChangePage={handleChangePage}
        //       onChangePagination={handleChangePagination}
        //     />
        //   )
        // }
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
