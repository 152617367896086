import { useEffect } from 'react';
import { pageTitleBase } from '../constants/utils';

export const useTitle = (title?: string) => {
  useEffect(() => {
    if (!title) {
      document.title = pageTitleBase;
      return;
    }

    document.title = `${title} - ${pageTitleBase}`;
  }, [title]);
};
