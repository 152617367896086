import { Typography } from '@mui/material';
import { titleStyle } from './styles';

export default function CardTitle() {
  return (
    <Typography variant="h6" component="h2" sx={titleStyle}>
      Descrição
    </Typography>
  );
}
