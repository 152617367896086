import ConfirmModal from '../../../components/ConfirmModal';
import { useDescription } from './useDescription';
import DescriptionSelectModal from '../../../components/Admin/DescriptionSelectModal';
import DescriptionManageModal from '../../../components/Admin/DescriptionMenageModal';
import DescriptionContent from '../../../components/DescriptionContent';
import Alert from '../../../components/Alert';
import { descriptionSearchBaseEndpoint } from '../../../constants/endpoints';

export default function Description() {
  const {
    isMenageModalOpen,
    currentDescriptionId,
    isConfirmModalOpen,
    isShowEditButton,
    errors,
    dirtyFields,
    isSelectModalOpen,
    currentDescriptionType,
    initialDescriptionTypeName,
    errorMessage,
    description,
    clearError,
    handleSelectTableRowClick,
    handleCloseSelectModal,
    handleOpenSelectModal,
    handleAddDescriptionClick,
    handleConfirmDelete,
    handleCloseConfirmModal,
    handleEdit,
    handleButtonOkClick,
    handleSubmit,
    register,
    handleDeleteButtonClick,
    handleCloseMenageModal,
    handleClearDescription,
    handleTableRowClick,
  } = useDescription();

  return (
    <>
      <DescriptionContent
        handleTableRowClick={handleTableRowClick}
        handleAddDescriptionClick={handleAddDescriptionClick}
        baseUrl={descriptionSearchBaseEndpoint}
      />

      <DescriptionManageModal
        currentDescriptionId={currentDescriptionId}
        currentDescriptionType={currentDescriptionType}
        dirtyFields={dirtyFields}
        errors={errors}
        handleClearDescription={handleClearDescription}
        handleCloseMenageModal={handleCloseMenageModal}
        handleDeleteButtonClick={handleDeleteButtonClick}
        handleEdit={handleEdit}
        handleOpenSelectModal={handleOpenSelectModal}
        isMenageModalOpen={isMenageModalOpen}
        isShowEditButton={isShowEditButton}
        onOkClick={handleSubmit(handleButtonOkClick)}
        register={register}
        description={description}
      />

      <DescriptionSelectModal
        isDescriptionTypesModalOpen={isSelectModalOpen}
        handleCloseDescriptionTypes={handleCloseSelectModal}
        currentDescriptionTypeName={initialDescriptionTypeName}
        onRowClick={handleSelectTableRowClick}
      />

      <ConfirmModal
        contentMessage="Deseja excluir este tipo de descrição?"
        isModalOpen={isConfirmModalOpen}
        onCloseModal={handleCloseConfirmModal}
        onConfirm={handleConfirmDelete}
        onNotConfirm={handleCloseConfirmModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
