import { CircularProgress, Box, BoxProps } from '@mui/material';
import { twMerge } from 'tailwind-merge';

type LoadingProps = BoxProps & {
  heightNormal?: boolean;
  loadMore?: boolean;
};

export default function Loading({
  heightNormal = false,
  loadMore = false,
  className,
  ...rest
}: LoadingProps) {
  return (
    <Box
      className={twMerge(
        'mt-8 flex justify-center',
        loadMore && 'h-screen pb-36',
        className,
      )}
      {...rest}
    >
      <CircularProgress disableShrink color="secondary" />
    </Box>
  );
}
