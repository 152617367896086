import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

type C = {
  items: any[];
  meta: {
    totalItems: number;
  };
};

export const useQueryCache = () => {
  const queryClient = useQueryClient();

  const addItemOnScreen = useCallback(
    <Cache extends C>(
      cacheName: string,
      data: Cache['items'][number],
      end = false,
    ) => {
      queryClient.setQueryData<Cache>(cacheName, (previousCache) => {
        if (previousCache) {
          return {
            ...previousCache,
            items: end
              ? [...previousCache.items, data]
              : [data, ...previousCache.items],
            meta: {
              totalItems: previousCache.meta.totalItems + 1,
            },
          };
        }

        return previousCache!;
      });
    },
    [queryClient],
  );

  const updateItemOnScreen = useCallback(
    <Cache extends C>(cacheName: string, data: Cache['items'][number]) => {
      queryClient.setQueryData<Cache>(cacheName, (previousCache) => {

        if (previousCache) {
          const updatedCache: Cache = {
            ...previousCache,
            items: previousCache.items.map((item) => {
              if (item.id === data.id) {
                item = data;
              }

              return item;
            }),
          };

          return updatedCache;
        }

        return previousCache!;
      });
    },
    [queryClient],
  );

  const removeItemFromScreen = useCallback(
    <Cache extends C>(cacheName: string, itemId: number) => {
      queryClient.setQueryData<Cache>(cacheName, (previousCache) => {
        if (previousCache) {
          const filteredCache = previousCache?.items.filter(
            (item) => item.id !== itemId,
          );

          return {
            ...previousCache,
            items: filteredCache,
            meta: {
              totalItems: previousCache.meta.totalItems - 1,
            },
          };
        }

        return previousCache!;
      });
    },
    [queryClient],
  );

  const overrideCache = useCallback(
    <Cache>(cacheName: string, newCache: Cache) => {
      queryClient.setQueryData<Cache>(cacheName, newCache);
    },
    [queryClient],
  );

  const clearCache = useCallback(
    (cacheName: string) => {
      queryClient.setQueryData(cacheName, null);
    },
    [queryClient],
  );

  return {
    addItemOnScreen,
    updateItemOnScreen,
    removeItemFromScreen,
    overrideCache,
    clearCache,
  };
};
