import { FindAllParts, Part, PartsPaginated } from '../types/parts';
import generatePaginatedUrl from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getAllParts = async () => {
  const response = await api.get<FindAllParts>('/part/v1/find-all');
  return response;
};

export const getPartsPaginated = async (
  page: number,
  direction: 'ASC' | 'DESC' = 'ASC',
  limit?: number,
) => {
  const url = generatePaginatedUrl('/part/v1', page, limit, direction);
  const response = await api.get<PartsPaginated>(url);
  return response;
};

export const savePart = async (
  partId: number,
  partName: string,
  isNew: boolean,
  reviewedApproved: boolean,
  outOfLine: boolean,
) => {
  const body = {
    id: partId,
    name: partName,
    isNew,
    reviewedApproved,
    outOfLine,
  };

  const response = await api.post<Part>('/part/v1', body);

  return response;
};

export const updatePart = async (
  partId: number,
  partName: string,
  isNew: boolean = false,
  reviewedApproved: boolean = false,
  outOfLine: boolean = false,
) => {
  const body = {
    id: partId,
    name: partName,
    isNew,
    reviewedApproved,
    outOfLine,
  };

  const response = await api.put<Part>('/part/v1', body);

  return response;
};

export const deletePart = async (partId: number) => {
  const response = await api.delete<Part>(`/part/v1/${partId}`);

  return response;
};
