import { ComponentProps, ReactNode } from 'react';

type EditorMenuButtonProps = Omit<ComponentProps<'li'>, 'content'> & {
  content: ReactNode;
  disabled?: boolean;
};

export default function EditorMenuButton({
  content,
  disabled,
  ...rest
}: EditorMenuButtonProps) {
  return (
    <li {...rest}>
      <button
        className="rounded p-1 font-normal enabled:cursor-pointer enabled:hover:bg-zinc-200"
        disabled={disabled}
      >
        {content}
      </button>
    </li>
  );
}
