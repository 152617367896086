import {
  EmailSignature,
  EmailSignaturesPaginated,
  SignatureDomain,
} from '../../types/emailSignature';
import { useHandleTable } from '../../hooks/useHandleTable';
import { emailSignatureSearchBaseEndpoint } from '../../constants/endpoints';
import formatSearch from '../../utils/formatSearch';
import { useMutation, useQuery } from 'react-query';
import { domainCache } from '../../constants/requestCacheName';
import { getDomainsPaginated } from '../../services/domain';
import { Domain, FindAllDomainsPaginated } from '../../types/domain';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEdit } from '../../hooks/useEdit';
import {
  DomainSignature,
  useEmailSignatureStore,
} from '../../store/emailSignature';
import { UpdateDomainSignatureDto } from '../../types/emailSignatureDomain';
import { updateEmailSignatureDomain } from '../../services/emailSignatureDomain';
import { mutationErrorHandling } from '../../utils/errorHandling';
import { useError } from '../../hooks/useError';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from '../../hooks/useSearchParams';
import { getEmailSignaturesPaginated } from '../../services/emailSignature';
import { Search, search } from '../../services/search';
import { Meta } from '../../types/pagination';

type Status = 'all' | 'none' | 'partial';

type CheckedDomainsStatus = {
  domainId: number;
  domainName: string;
  status: Status;
};

export const useEmailSignatureContent = () => {
  const [checkedItems, setCheckedItems] = useState<EmailSignature[]>([]);

  const [domainsFilter] = useSearchParams('domain') as [string];

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return search<EmailSignature>(baseUrl, searchValue, page, null, null, [
        {
          name: 'domain',
          value: domainsFilter !== 'todos' ? domainsFilter : '',
        },
      ]);
    },
    [domainsFilter],
  );

  const handleLoadMore = useCallback(
    async (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      const res = await getEmailSignaturesPaginated(
        page,
        direction,
        limit,
        domainsFilter !== 'todos' ? domainsFilter : '',
      );
      return res;
    },
    [domainsFilter],
  );

  const {
    sortField,
    isSearchFetching: isFetching,
    inputRef,
    itemsLength: emailSignatureLength,
    itemsShown,
    itemsShownLength: emailSignaturesShownLength,
    searchItems,
    itemsLoadMore,
    defineItemsShown,
    refetchSearch,
    isSearchInputDirty,
    isLoadMoreFetching,
    endPageRef,
    isSearchFetching,
    resetLoadMoreCurrentPage,
    handleCancelSearch,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch: searchScreen,
    handleSortTable,
  } = useHandleTable(
    0,
    [],
    null,
    null,
    emailSignatureSearchBaseEndpoint,
    handleLoadMore,
    null,
    doSearch as Search,
  );
  const emailSignaturesShown = itemsShown as EmailSignature[];

  const emailSignatures = useMemo<EmailSignaturesPaginated>(() => {
    return {
      items: [
        ...(searchItems?.items ?? []),
        ...(itemsLoadMore?.items ?? []),
      ] as EmailSignature[],
      meta: itemsLoadMore?.meta ?? (searchItems?.meta as Meta),
    };
  }, [
    itemsLoadMore?.items,
    itemsLoadMore?.meta,
    searchItems?.items,
    searchItems?.meta,
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    const emailSignature = (emailSignatures?.items as EmailSignature[]) ?? [];

    setCheckedItems(emailSignature);
  }, [emailSignatures]);

  useEffect(() => {
    resetLoadMoreCurrentPage();
    refetchSearch();
  }, [domainsFilter, refetchSearch, resetLoadMoreCurrentPage]);

  const { data: domains } = useQuery<FindAllDomainsPaginated>(
    domainCache,
    async () => (await getDomainsPaginated()).data,
    {
      onSuccess: () => {
        const maxWidth = (domains?.items ?? []).reduce((maxWidth, row) => {
          const width = row.name?.toString().length || 0;
          return width > maxWidth ? width : maxWidth;
        }, 0);
        setDomainWidth((maxWidth > 32 ? maxWidth + 2 : 32) / 2);
      },

      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const [checkedDomainsStatus, setCheckedDomainsStatus] = useState<
    CheckedDomainsStatus[]
  >([]);

  const defineDomainStatus = useCallback(
    (
      domain: Domain,
      signatures: EmailSignature[] = (emailSignatures?.items as EmailSignature[]) ??
        [],
    ): CheckedDomainsStatus => {
      let checkedNumber = 0;
      signatures.forEach((emailSignature) => {
        if (
          emailSignature.signatureDomains?.some(
            (domainSignature) => domainSignature.domain.id === domain.id,
          )
        ) {
          checkedNumber++;
        }
      });

      if (checkedNumber === 0) {
        return {
          domainId: domain.id,
          status: 'none',
          domainName: domain.name,
        };
      }

      if (checkedNumber === emailSignatures?.items.length) {
        return {
          domainId: domain.id,
          status: 'all',
          domainName: domain.name,
        };
      }

      return {
        domainId: domain.id,
        status: 'partial',
        domainName: domain.name,
      };
    },
    [emailSignatures],
  );

  useEffect(() => {
    if (domains) {
      const domainsStatus: CheckedDomainsStatus[] = domains?.items.map(
        (domain) => defineDomainStatus(domain),
      );

      setCheckedDomainsStatus(domainsStatus);
    }
  }, [defineDomainStatus, domains]);

  useEffect(() => {
    if (domainsFilter) {
    }
  }, [domainsFilter]);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: updateEmailSignatureDomainMutate } = useMutation({
    mutationFn: async (dto: UpdateDomainSignatureDto[]) =>
      (await updateEmailSignatureDomain(dto)).data,

    onSuccess: () => {
      handleUneditDomain();
      refetchSearch();
    },

    onError: (error) => {
      // refetchSearch();
      mutationErrorHandling(
        error,
        'Falha ao salvar assinaturas de email com domínios',
        setErrorMessage,
      );
    },
  });

  const {
    canEdit: canEditDomain,
    handleEdit: handleEditDomain,
    handleUnedit: handleUneditDomain,
  } = useEdit();

  const [domainWidth, setDomainWidth] = useState<number>();

  const handleSearch = () => {
    searchScreen<EmailSignature>(
      (item, formatedSearch) =>
        formatSearch(item.fullName).includes(formatedSearch) ||
        formatSearch(item.cellPhoneNumber ?? '').includes(formatedSearch) ||
        formatSearch(item.department).includes(formatedSearch) ||
        formatSearch(item.email).includes(formatedSearch) ||
        formatSearch(item.id.toString()).includes(formatedSearch) ||
        formatSearch(item.phoneNumber ?? '').includes(formatedSearch),
    );
  };

  const { state } = useEmailSignatureStore();

  const handleChangeEditDomainButtonClick = () => {
    if (canEditDomain) {
      if (state.changedEmailSignatures.length > 0) {
        updateEmailSignatureDomainMutate(state.changedEmailSignatures);

        state.changedEmailSignatures = [];
        return;
      }

      handleUneditDomain();
      return;
    }
    handleEditDomain();
  };

  const updateStatusOnChangeDomainCheck = (
    isCheck: boolean,
    domain: Domain,
    signatureId: number,
  ) => {
    const updatedEmailSignatures: EmailSignature[] = checkedItems.map(
      (emailSignature) => {
        if (emailSignature.id === signatureId) {
          let signatureDomains: SignatureDomain[] =
            emailSignature.signatureDomains;

          if (isCheck) {
            signatureDomains.push({ domain, id: -1 });
          }

          if (!isCheck) {
            signatureDomains = signatureDomains.filter(
              (signatureDomain) => signatureDomain.domain.id !== domain.id,
            );
          }

          return {
            ...emailSignature,
            signatureDomains,
          };
        }

        return emailSignature;
      },
    );

    const status = defineDomainStatus(domain, updatedEmailSignatures);
    setCheckedItems(updatedEmailSignatures);

    setCheckedDomainsStatus((prevDomainStatus) => {
      const updatedStatus = prevDomainStatus.map((domainStatus) =>
        domainStatus.domainId === domain.id ? status : domainStatus,
      );

      return updatedStatus;
    });
  };

  const handleChangeDomainCheck = (
    isCheck: boolean,
    signatureDomainId: number | undefined,
    domain: Domain,
    signatureId: number,
  ) => {
    updateStatusOnChangeDomainCheck(isCheck, domain, signatureId);

    const emailSignatureWithoutSelected: DomainSignature[] =
      state.changedEmailSignatures.filter(
        (changedSignature) =>
          !(
            changedSignature.domainId === domain.id &&
            changedSignature.signatureId === signatureId
          ),
      );

    state.changedEmailSignatures = emailSignatureWithoutSelected;
    // Se foi selecionado e veio o ID, já estava selecionado anteriormente
    // Se foi desselecionado e não veio o ID, já estava desselecionado anteriormente

    if ((isCheck && !!signatureDomainId) || (!isCheck && !signatureDomainId)) {
      return;
    }

    state.changedEmailSignatures.push({
      id: signatureDomainId,
      domainId: domain.id,
      signatureId,
    });
  };

  const updateChangedEmailSignaturesOnAllDomainsCheck = (
    domain: Domain,
    isChecked: boolean,
  ) => {
    const emailSignaturesWithoutSelectedDomain =
      state.changedEmailSignatures.filter(
        (changedEmailSignature) => changedEmailSignature.domainId !== domain.id,
      );

    // Selecionado
    if (isChecked) {
      const updatedChangedEmailSignatures: DomainSignature[] = (
        (emailSignatures?.items ?? []) as EmailSignature[]
      )
        .filter(
          (emailSignature) =>
            !emailSignature.signatureDomains.some(
              (signatureDomain) =>
                signatureDomain.domain.id === domain.id &&
                // Remove os que foram inseridos artificialmente e não estavam na lista inicial
                signatureDomain.id > 0,
            ),
        )
        .map((emailSignature) => ({
          domainId: domain.id,
          signatureId: emailSignature.id,
        }));

      state.changedEmailSignatures = [
        ...emailSignaturesWithoutSelectedDomain,
        ...updatedChangedEmailSignatures,
      ];

      return;
    }

    // Não selecionado

    const updatedChangedEmailSignatures: DomainSignature[] = (
      (emailSignatures?.items ?? []) as EmailSignature[]
    )
      .filter((emailSignature) =>
        emailSignature.signatureDomains.some(
          (signatureDomain) =>
            signatureDomain.domain.id === domain.id && signatureDomain.id > 0,
        ),
      )
      .map((emailSignature) => {
        return {
          id: emailSignature.signatureDomains.find(
            (signatureDomain) =>
              signatureDomain.domain.id === domain.id && !!signatureDomain.id,
          )?.id,

          domainId: domain.id,
          signatureId: emailSignature.id,
        };
      });

    state.changedEmailSignatures = [
      ...emailSignaturesWithoutSelectedDomain,
      ...updatedChangedEmailSignatures,
    ];
  };

  const handleChangeAllDomainsCheck = (domain: Domain, isChecked: boolean) => {
    updateChangedEmailSignaturesOnAllDomainsCheck(domain, isChecked);

    setCheckedItems((prevCheckedItems) => {
      // Remove os domínios relativos ao domain que foi passado
      const updatedCheckedItems: EmailSignature[] = prevCheckedItems.map(
        (checkedItem) => ({
          ...checkedItem,
          signatureDomains: checkedItem.signatureDomains.filter(
            (signatureDomain) => signatureDomain.domain.id !== domain.id,
          ),
        }),
      );

      let status: Status = 'none';
      // Se for selecionado adiciona em todos os domínios
      if (isChecked) {
        updatedCheckedItems.forEach((checkedItem) => {
          checkedItem.signatureDomains.push({ id: -1, domain });
        });

        status = 'all';
      }

      setCheckedDomainsStatus((prevCheckedDomainStatus) =>
        prevCheckedDomainStatus.map((domainStatus) => {
          if (domainStatus.domainId === domain.id) {
            return {
              ...domainStatus,
              status,
            };
          }

          return domainStatus;
        }),
      );

      return updatedCheckedItems;
    });
  };

  const handleFilter = useCallback(
    (filterName: string) => {
      navigate({ search: `domain=${filterName}` }, { replace: true });
    },
    [navigate],
  );

  const handleDoubleClickFilter = useCallback(
    (filterName: string) => {
      const currentDomain = domains?.items.find(
        (domain) => domain.name === filterName,
      );
      if (currentDomain) {
        navigate(`/admin/email-signature/${currentDomain.id}`);
      }
    },
    [domains?.items, navigate],
  );

  const handleButtonCancelEditClick = useCallback(() => {
    state.changedEmailSignatures = [];
    handleUneditDomain();
  }, [handleUneditDomain, state]);

  return {
    inputRef,
    emailSignaturesShown,
    isSearchInputDirty,
    sortField,
    isFetching,
    emailSignatureLength,
    emailSignaturesShownLength,
    domainWidth,
    domains,
    canEditDomain,
    errorMessage,
    checkedDomainsStatus,
    checkedItems,
    domainsFilter,
    isLoadMoreFetching,
    endPageRef,
    isSearchFetching,
    state,
    defineItemsShown,
    setCheckedItems,
    handleButtonCancelEditClick,
    handleDoubleClickFilter,
    handleFilter,
    handleChangeAllDomainsCheck,
    clearError,
    handleChangeDomainCheck,
    handleChangeEditDomainButtonClick,
    handleSortTable,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleCancelSearch,
    handleSearch,
  };
};
