import {
  ProductsLineCategory,
  ProductsLineCategoryPaginated,
  ReorderProductDto,
} from '../types/productsLineCategory';
import { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getAllProductLineCategoriesByLineId = async ({
  lineId,
}: Record<'lineId', number>) => {
  const response = await api.get<ProductsLineCategory>(
    `/product-line-category/v1/line-id/${lineId}`,
  );
  return response;
};

export const reorderProductsByCategory = async (dto: ReorderProductDto[]) => {
  const response = await api.put<ProductsLineCategory>(
    `/product-line-category/v1/reorder`,
    { products: dto },
  );
  return response;
};

export const getProductLineCategoriesLiveSearchPaginated = async (
  search: string | null,
  domainId: number | null,
  page: number = 1,
  direction: 'ASC' | 'DESC' | null = 'ASC',
  limit?: number | null,
) => {
  const url = addSearchParamsInUrl(
    'product-line-category/v1/live-search',
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'domainId', value: domainId },
    { name: 'direction', value: direction },
    search ? { name: 'query', value: search } : {},
  );

  const response =
    await api.get<ProductsLineCategoryPaginated>(url);
  return response;
};
