import { Param } from '../types/params';
import { FindAllSubjects, FindAllSubjectsPaginated } from '../types/subject';
import { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getSubjectsPaginated = async (
  page: number,
  direction?: 'ASC' | 'DESC',
  limit?: number,
  params?: Param[],
) => {
  const url = addSearchParamsInUrl(
    'subject/v1/search',
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    ...(params ?? []),
  );
  const response = await api.get<FindAllSubjectsPaginated>(url);
  return response;
};

export const saveSubject = async (subjectName: string) => {
  const body = {
    name: subjectName,
  };

  const response = await api.post('/subject/v1', body);
  return response;
};

export const updateSubject = async (subjectId: number, subjectName: string) => {
  const body = {
    id: subjectId,
    name: subjectName,
  };

  const response = await api.put('/subject/v1', body);
  return response;
};

export const deleteSubject = async (subjectId: number) => {
  const response = await api.delete(`/subject/v1/${subjectId}`);
  return response;
};

export const getAllSubjects = async () => {
  const response = await api.get<FindAllSubjects>('/subject/v1/find-all');
  return response;
};
