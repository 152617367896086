import { z } from 'zod';
import { FindAll } from './pagination';
import { userTypesSchema } from '../validations/schemas';

export enum Role {
  ADMIN = 'Administrador',
  AFTER_SALES = 'Pós-vendas',
  REPRESENTATIVES = 'Representantes',
  WAREHOUSE = 'Almoxarifado',
}

export type UserType = {
  id: number;
  type: Role;
};

export type FindAllUserTypes = FindAll<UserType>;

export type SaveUserType = {
  role: string;
};

export type UpdateUserType = {
  id: number;
  role: string;
  pagesToAdd: number[];
  pagesToRemove: number[];
};

export type UserTypesFormData = z.infer<typeof userTypesSchema>;
