import { ComponentProps } from 'react';

type CardInfosProps = ComponentProps<'div'> & {
  productId?: string | number;
  productName?: string;
};

export default function CardInfos({
  productId,
  productName,
  ...rest
}: CardInfosProps) {
  return (
    <div {...rest}>
      <div className="font-semi flex h-24 flex-col justify-center gap-1 text-left">
        {productName && <h3 className="text-xl">{productName}</h3>}
        {productId && <span className="text-secondaryText">{productId}</span>}
      </div>
    </div>
  );
}
