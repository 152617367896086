import { zodResolver } from '@hookform/resolvers/zod';
import { useEdit } from '../../../hooks/useEdit';
import {
  PartsGroupFormData,
  PartsGroupSelected,
  SavePartsGroupDto,
  UpdatePartsGroupDto,
} from '../../../types/partsGroup';
import { partsGroupSchema } from '../../../validations/schemas';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../hooks/useModal';
import { useEffect, useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  deletePartsGroupById,
  savePartsGoup,
  updatePartsGoup,
} from '../../../services/partsGroup';
import { useQueryCache } from '../../../hooks/useQueryCache';
import {
  partsGroupApiCache,
  productsByPartsGroupIdApiCache,
  searchCache,
} from '../../../constants/requestCacheName';
import { isAxiosError } from 'axios';
import { FindAllProducts, Product } from '../../../types/products';
import { getAllProductsByPartsGroupId } from '../../../services/partsGroupItem';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { getDescriptionsByTypeIdPaginated } from '../../../services/description';
import { partsGroupSearchBaseEndpoint } from '../../../constants/endpoints';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { useScreenHeight } from '../../../hooks/useHeight';

export const usePartsGroupMenageModal = (
  selectedDescriptionIdReceived: number,
  currentPartGroupSelected: PartsGroupSelected | null,
  onCloseMenageModal: () => void,
) => {
  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
  } = useForm<PartsGroupFormData>({
    resolver: zodResolver(partsGroupSchema),
    defaultValues: {
      image: '',
      description: '',
      name: '',
      totalParts: '',
    },
  });

  const name = watch('name');
  const descriptions = watch('description');
  const totalParts = watch('totalParts');
  const image = watch('image');

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const {
    data: products,
    isFetching: isFetchingProducts,
    refetch: refetchProducts,
  } = useQuery<FindAllProducts>(
    `${productsByPartsGroupIdApiCache}${currentPartGroupSelected?.id}`,
    async () =>
      (await getAllProductsByPartsGroupId(currentPartGroupSelected?.id ?? 0))
        .data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const { mutate: savePartsGroup } = useMutation({
    mutationFn: async (dto: SavePartsGroupDto) => {
      return (await savePartsGoup(dto)).data;
    },

    onSuccess: (data) => {
      addItemOnScreen(partsGroupApiCache, data);
      addItemOnScreen(`${searchCache}${partsGroupSearchBaseEndpoint}`, data);
      handleClosePartGroupMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar o conjunto de peças',
        setErrorMessage,
      );
    },
  });

  const { mutate: updatePartsGroup } = useMutation({
    mutationFn: async (dto: UpdatePartsGroupDto) => {
      return (await updatePartsGoup(dto)).data;
    },

    onSuccess: (data) => {
      updateItemOnScreen(partsGroupApiCache, data);
      updateItemOnScreen(`${searchCache}${partsGroupSearchBaseEndpoint}`, data);
      // handleClosePartGroupMenageModal();
      handleUnedit()
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar o conjunto de peças',
        setErrorMessage,
      );
    },
  });

  const { mutate: deletePartsGroup } = useMutation({
    mutationFn: async () =>
      deletePartsGroupById(currentPartGroupSelected?.id ?? 0),

    onSuccess: () => {
      if (currentPartGroupSelected) {
        removeItemFromScreen(
          `${searchCache}${partsGroupSearchBaseEndpoint}`,
          currentPartGroupSelected.id,
        );
      }
      handleCloseConfirmModal();
      handleClosePartGroupMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar o conjunto de peças',
        setErrorMessage,
        () => {
          if (isAxiosError(error) && error.response?.data.statusCode === 409) {
            setErrorMessage(
              'Este conjunto de peças só pode ser apagado depois que não houver itens associados a ele.',
            );
            handleCloseConfirmModal();

            return true;
          }
        },
      );

      handleCloseConfirmModal();
    },
  });

  const {
    sortField,
    inputRef,
    isSearchInputDirty,
    itemsLength: productsLength,
    itemsShown,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
  } = useHandleTable(products?.meta.totalItems ?? 0, products?.items ?? []);
  const productsShown: Product[] = itemsShown;

  const { screenHeight } = useScreenHeight();

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const {
    isModalOpen: isSelectDescriptionModalOpen,
    handleCloseModal: handleCloseSelectDescriptionModal,
    handleOpenModal: handleOpenSelectDescriptionModal,
  } = useModal();

  const {
    isModalOpen: isConfirmModalOpen,
    handleCloseModal: handleCloseConfirmModal,
    handleOpenModal: handleOpenConfirmModal,
  } = useModal();

  const {
    isModalOpen: isSelectImageModalOpen,
    handleCloseModal: handleCloseSelectImageModal,
    handleOpenModal: handleOpenSelectImageModal,
  } = useModal();

  const selectedDescriptionId = useRef<number | null>(
    selectedDescriptionIdReceived,
  );

  useEffect(() => {
    selectedDescriptionId.current = selectedDescriptionIdReceived;
  }, [selectedDescriptionIdReceived]);

  useEffect(() => {
    if (currentPartGroupSelected?.id) {
      setValue('description', currentPartGroupSelected?.description);
      setValue('image', currentPartGroupSelected?.image);
      setValue('name', currentPartGroupSelected?.name);
      setValue('totalParts', currentPartGroupSelected?.totalParts);
    }
  }, [currentPartGroupSelected, setValue]);

  useEffect(() => {
    if (currentPartGroupSelected) {
      refetchProducts();
    }
  }, [currentPartGroupSelected, refetchProducts]);

  const handleButtonOkClick = ({
    image,
    name,
    totalParts,
  }: PartsGroupFormData) => {
    if (selectedDescriptionId.current) {
      if (currentPartGroupSelected) {
        updatePartsGroup({
          id: currentPartGroupSelected.id,
          descriptionId: selectedDescriptionId.current,
          image,
          name,
          totalParts: +(totalParts ?? 0),
        });
        currentPartGroupSelected = null;

        return;
      }

      savePartsGroup({
        descriptionId: selectedDescriptionId.current,
        image,
        name,
        totalParts: +(totalParts ?? 0),
      });
    }
  };

  const handleSelectImageClick = () => {
    if (!isShowEditButton) {
      handleOpenSelectImageModal();
    }
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmModal();
  };

  const handleDescriptionFieldClick = () => {
    if (!isShowEditButton) {
      handleOpenSelectDescriptionModal();
    }
  };

  const handleSelectDescription = (
    descriptionId: number,
    descriptionName: string,
  ) => {
    selectedDescriptionId.current = descriptionId;
    setValue('description', descriptionName);
    setError('description', {});
    handleCloseSelectDescriptionModal();
  };

  const handleClearField = (
    fieldName: keyof Pick<PartsGroupFormData, 'name' | 'totalParts'>,
  ) => {
    setValue(fieldName, '', { shouldDirty: true });
  };

  const isShowEditButton = !!currentPartGroupSelected && !canEdit;

  const handleClosePartGroupMenageModal = () => {
    setValue('description', '');
    setValue('image', '');
    setValue('name', '', { shouldDirty: true });
    setValue('totalParts', '', { shouldDirty: true });

    setError('description', {});
    setError('image', {});
    setError('name', {});
    setError('totalParts', {});

    handleUnedit();
    onCloseMenageModal();
  };

  const handleSelectImage = (imageId: number, imageName: string) => {
    setValue('image', imageName);
    setError('image', {});
    handleCloseSelectImageModal();
  };

  const handleConfirmDelete = () => {
    deletePartsGroup();
  };

  const descriptionService = (currentPage: number) => {
    return getDescriptionsByTypeIdPaginated(3, currentPage);
  };

  // const modalHeight = window.innerHeight >= 1440 ? '85vh' : '90vh';

  return {
    isShowEditButton,
    errors,
    dirtyFields,
    isSelectDescriptionModalOpen,
    isConfirmModalOpen,
    isSelectImageModalOpen,
    isFetchingProducts,
    sortField,
    inputRef,
    image,
    isSearchInputDirty,
    productsLength,
    productsShown,
    name,
    errorMessage,
    descriptions,
    totalParts,
    // modalHeight,
    screenHeight,
    clearError,
    descriptionService,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
    handleSelectImage,
    handleCloseSelectImageModal,
    handleSelectImageClick,
    handleConfirmDelete,
    handleCloseConfirmModal,
    handleClosePartGroupMenageModal,
    handleDescriptionFieldClick,
    handleSelectDescription,
    handleCloseSelectDescriptionModal,
    handleClearField,
    handleButtonOkClick,
    handleEdit,
    handleDeleteButtonClick,
    register,
    handleSubmit,
  };
};
