import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';

export const inputAreaStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  position: 'relative',
};

export const inputContainerStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '0.5rem 0.5rem 0 0',
  backgroundColor: grey['300'],
  gap: '1rem',
  padding: '0.5rem 1rem',
  transition: 'all ease 0.2s',
};

export const inputStyle: SxProps<Theme> = {
  flex: 1,
  // '::placeholder': {
  //   color: 'red',
  // },

  // '::-webkit-input-placeholder': {
  //   color: 'red',
  // },

  // '::-moz-placeholder': {
  //   color: 'red',
  // },

  // 'input:-ms-input-placeholder': {
  //   color: 'red',
  // },

  // 'input:-moz-placeholder': {
  //   color: 'red',
  // },
};

export const cancelIconStyle: SxProps<Theme> = {
  cursor: 'pointer',
};

export const cancelButtonStyle: SxProps<Theme> = {
  cursor: 'pointer',
  position: 'absolute',
  right: '1rem',
  transition: 'opacity ease 0.2s',
};
