import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BackgroundProdutos from '../../assets/images/backgrounds/NossosProdutos.svg';
import { useProductsBuffalo } from './useProductsBuffalo';
import HomeCardBuffalo from '../../components/HomeCardBuffalo';
import { ClipLoader } from 'react-spinners';
import CardSkeleton from '../../components/CardSkeleton';
import SearchBar from '../../components/SearchBar';
import SearchIcon from '@mui/icons-material/Search';
import { AutoComplete } from '../../components/AutoComplete';
import BreadCrumb from '../../components/BreadCrumb';
import SearchOffIcon from '@mui/icons-material/SearchOff';

export default function ProdutosTeste3() {
  const {
    inputRef,
    endOfListRef,
    isFetching,
    productsInPage,
    canLoadMore,
    isLoadingMore,
    linesWithCategories,
    selectedLine,
    selectedCategory,
    filteredCategories,
    productsSearch,
    openSearch,
    selectedIndex,
    handleClearSearchAndSetFocus,
    handleProductSearchClick,
    handleClickOutAutoComplete,
    handleSearchInputChange,
    handleSelectLine,
    handleSelectCategory,
    handleOpenSearch,
  } = useProductsBuffalo();

  return (
    <div>
      <div
        className="fixed right-20 top-[2.1rem] z-50 sm:hidden"
        onClick={handleOpenSearch}
      >
        {openSearch ? (
          <SearchOffIcon
            className="hover:text-yellow-500"
            style={{
              fontSize: '2rem',
            }}
          />
        ) : (
          <SearchIcon
            className="hover:text-yellow-500"
            style={{
              fontSize: '2rem',
            }}
          />
        )}
      </div>
      <div className="sticky top-0 z-10">
        <Header onClick={handleClickOutAutoComplete} />
        <div className="hidden h-auto w-full items-center justify-start gap-6 bg-[#343434]/90 py-2 pl-5 sm:flex">
          <span className="w-full max-w-20 text-white">
            {linesWithCategories?.items?.length! > 1 ? 'Linhas' : 'Linha'}
          </span>
          <div className="flex flex-wrap justify-start gap-3">
            {linesWithCategories?.items.map((line) => (
              <button
                key={line.id}
                onClick={() => handleSelectLine(line?.name)}
                className={`rounded-md border px-2 py-1 text-sm ${
                  selectedLine === line.name
                    ? 'border-yellow-500 bg-yellow-500 hover:bg-yellow-500'
                    : 'bg-white hover:text-yellow-500'
                }`}
              >
                {line.name}
              </button>
            ))}
          </div>
        </div>

        <div className="hidden h-auto w-full items-center justify-start gap-5 bg-zinc-100/90 py-2 pl-5 sm:flex">
          <span className="w-20 text-[#343434]">
            {filteredCategories.length > 1 ? 'Categorias' : 'Categoria'}
          </span>
          <div className="flex flex-wrap justify-start gap-3">
            {filteredCategories
              .filter(
                (category) => category.id !== null && category.name !== null,
              )
              .map((category) => (
                <button
                  key={category.id}
                  onClick={() => handleSelectCategory(category?.name)}
                  className={`rounded-md border px-2 py-1 text-sm ${
                    selectedCategory === category.name
                      ? 'border-yellow-500 bg-[#343434] text-yellow-500'
                      : 'bg-white hover:border-yellow-500 hover:text-yellow-500'
                  }`}
                >
                  {category.name}
                </button>
              ))}
          </div>
        </div>
        <div
          data-search={openSearch}
          className="transition-medium absolute top-[17rem] w-full data-[search=false]:-left-[45rem] data-[search=true]:left-0 sm:right-16 sm:top-6 sm:z-40 sm:max-w-[25rem] sm:transition-none data-[search=true]:sm:left-auto mr-2 lg:mr-0 xl:top-6 xl:right-20 xl:max-w-[17rem] 2xl:max-w-[30rem] 2xl:right-3 3xl:max-w-[29rem]"
        >
          <SearchBar
            className="w-full pr-2"
            ref={inputRef}
            onClick={handleSearchInputChange}
            onChange={handleSearchInputChange}
            onClear={handleClearSearchAndSetFocus}
          />
          {productsSearch && (
            <AutoComplete.Root
              items={productsSearch.items}
              onOutClick={handleClickOutAutoComplete}
              className="absolute w-[calc(100%-0.5rem)] border-2 border-t-0"
            >
              {productsSearch.items.map((productLineCategory, index) => {
                const isSelected = index === selectedIndex;

                return (
                  <AutoComplete.Item
                    key={`${productLineCategory.product.id}${productLineCategory.line.id}${productLineCategory.category.id}${productLineCategory.domain.id}`}
                    onClick={() =>
                      handleProductSearchClick(productLineCategory)
                    }
                    className={`p-2 ${isSelected ? 'bg-zinc-300' : ''}`}
                    ref={(el) => {
                      if (isSelected && el) {
                        el.scrollIntoView({ block: 'nearest' });
                      }
                    }}
                  >
                    <div>
                      {productLineCategory.product.id} -{' '}
                      {productLineCategory.product.name}
                      <BreadCrumb
                        items={[
                          productLineCategory.line.name,
                          productLineCategory.category.name,
                        ]}
                      />
                    </div>
                  </AutoComplete.Item>
                );
              })}
            </AutoComplete.Root>
          )}
        </div>
        <div
          data-search={openSearch}
          className="transition-medium flex flex-col items-center justify-start gap-4 bg-zinc-100/90 px-2 pb-2 backdrop-blur-sm data-[search=false]:h-44 data-[search=true]:h-60 sm:hidden sm:h-auto sm:items-center sm:justify-between sm:gap-2 sm:pb-2 sm:transition-none data-[search=true]:sm:h-auto md:flex-row md:px-8 md:pb-0 data-[search=true]:md:h-auto data-[search=true]:xl:h-20"
        >
          <div className="mt-2 flex w-full flex-col items-center gap-2 sm:hidden">
            <div className="flex w-full flex-col items-start">
              <span className="text-left text-lg font-bold text-black">
                Linha
              </span>
              <select
                value={selectedLine}
                onChange={(e) => handleSelectLine(e.target.value)}
                className="w-full cursor-pointer rounded-lg border-2 p-2 font-bold uppercase tracking-widest text-black"
              >
                {linesWithCategories?.items.map((line) => (
                  <option value={line.name} key={line.id} className="w-full">
                    {line.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex w-full flex-col items-start">
              <span className="text-left text-lg font-bold text-black">
                Categoria
              </span>
              <select
                value={selectedCategory}
                onChange={(e) => handleSelectCategory(e.target.value)}
                className="w-full cursor-pointer rounded-lg border-2 p-2 font-bold uppercase tracking-widest text-black"
              >
                <option value="">Selecione a categoria</option>
                {filteredCategories
                  .filter(
                    (category) =>
                      category.id !== null && category.name !== null,
                  )
                  .map((category) => (
                    <option value={category.name} key={category.id}>
                      {category.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="adjust-content-bf">
        <div className="adjust-content-bf flex flex-col">
          <div className="hidden sm:block">
            <img
              src={BackgroundProdutos}
              alt="Background Buffalo Produtos"
              width={1000}
              height={250}
              className="w-full"
            />
          </div>
          <div className="mx-4 flex-1 pb-10 md:mx-11">
            <div>
              <div className="mt-8 flex flex-wrap items-center justify-center gap-5 lg:mx-28 lg:justify-center 2xl:mx-0">
                {productsInPage?.map((product) => (
                  <HomeCardBuffalo
                    key={`${product.idProduto}${product.idLinha}${product.idCategoria}${product.idDominio}`}
                    product={product}
                  />
                ))}
                {isLoadingMore &&
                  Array(20)
                    .fill(null)
                    .map((_, index) => (
                      <CardSkeleton key={`skeleton-${index}`} width="21rem" />
                    ))}
              </div>
              {isFetching && (
                <div className="mt-4 flex justify-center">
                  <ClipLoader color="#000" loading={isFetching} size={50} />
                  <div>Carregando produtos</div>
                </div>
              )}
              <div
                ref={endOfListRef}
                style={{
                  display:
                    productsInPage.length > 0 && canLoadMore ? 'block' : 'none',
                }}
              ></div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
