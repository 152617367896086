import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import EmailTriggerContent from '../../../components/EmailTriggerContent';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import { useEmailTrigger } from './useEmailTrigger';

export default function EmailTrigger() {
  const {
    name,
    email,
    password,
    isMenageModalOpen,
    isConfirmDeleteModalOpen,
    errorMessage,
    emailTriggerIdUpdate,
    isShowEditButton,
    errors,
    dirtyFields,
    handleCloseManageModal,
    handleConfirmDelete,
    handlecloseConfirmDeleteModal,
    handleTableRowClick,
    handleAddEmailClick,
    clearError,
    register,
    handleDeleteButtonClick,
    handleSubmit,
    handleEdit,
    handleButtonOkClick,
    handleClearDomain,
  } = useEmailTrigger();

  return (
    <>
      <EmailTriggerContent
        onTableRowClick={handleTableRowClick}
        handleAddEmailRowClick={handleAddEmailClick}
      />

      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseManageModal}
        cancelIcon={false}
        contentStyle="pr-1"
      >
        <div className="max-h-[85vh] overflow-y-auto pr-4">
          <HeaderModalManageItem
            showDeleteButton={!!emailTriggerIdUpdate}
            deleteButtonContent="Apagar Email"
            onDeleteButtonClick={handleDeleteButtonClick}
            isEditable={!isShowEditButton}
            title={'Email de Disparo'}
            mainButtonContent={isShowEditButton ? 'Editar' : 'Ok'}
            onCancelClick={handleCloseManageModal}
            onOkClick={
              !isShowEditButton ? handleSubmit(handleButtonOkClick) : undefined
            }
            onEditClick={isShowEditButton ? handleEdit : undefined}
          />
          <div className="mb-24">
            <div className="mb-8 mr-24 mt-24 flex w-full flex-col gap-4">
              <Input
                maxLength={250}
                type="text"
                label="Nome"
                id={'Nome'}
                disabled={isShowEditButton}
                helperText={errors.name?.message}
                isDirty={dirtyFields.name}
                onCancelIconClick={() => handleClearDomain('name')}
                {...register('name')}
                value={name}
              />
              <Input
                maxLength={250}
                type="text"
                label="Email"
                id={'Email'}
                disabled={isShowEditButton}
                helperText={errors.email?.message}
                isDirty={dirtyFields.email}
                onCancelIconClick={() => handleClearDomain('email')}
                {...register('email')}
                value={email}
              />
              <Input
                maxLength={250}
                type="text"
                showEye
                label="Senha"
                id={'Senha'}
                disabled={isShowEditButton}
                helperText={errors.password?.message}
                isDirty={dirtyFields.password}
                onCancelIconClick={() => handleClearDomain('password')}
                {...register('password')}
                value={password}
              />
            </div>
          </div>
        </div>
      </Modal>

      <ConfirmModal
        contentMessage="Deseja excluir esse email de disparo?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handlecloseConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
        onNotConfirm={handlecloseConfirmDeleteModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
