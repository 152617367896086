import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import NewsTypeContent from '../../../components/NewsTypeContent';
import VinculationHeader from '../../../components/VinculationHeader';
import { useNewsTypes } from './useNewsTypes';
import SerchInput from '../../../components/SearchInput';
import Table from '../../../components/Table';
import Loading from '../../../components/Loading';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

export default function NewsTypes() {
  const {
    newsTypesIdUpdate,
    isMenageModalOpen,
    isConfirmDeleteModalOpen,
    isShowEditButton,
    errors,
    dirtyFields,
    errorMessage,
    newsType,
    isFetchingNewsByType,
    canEdit,
    newsByTypeLength,
    newsByTypeInputRef,
    isNewsByTypeInputDirty,
    newsByTypeShown,
    sortFieldNewsByType,
    newsByType,
    showEditContent,
    clearError,
    handleConfirmDelete,
    handleClearNewsTypeField,
    register,
    handleSubmit,
    handleButtonOkClick,
    handleCloseConfirmDeleteModal,
    handleDeleteButtonClick,
    handleTableRowClick,
    handleAddNewsTypeClick,
    handleCloseMenageModal,
    handleChangeEditOrderButtonClick,
    handleNewsByTypeSearch,
    handleCancelNewsByTypeSearch,
    handleSortNewsByTypeTable,
    handleDragEnd,
    handleEditButtonClick,
  } = useNewsTypes();

  return (
    <>
      <NewsTypeContent
        onTableRowClick={handleTableRowClick}
        onAddNewsTypeClick={handleAddNewsTypeClick}
      />

      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseMenageModal}
        cancelIcon={false}
        sx={{ overflowY: 'auto' }}
        contentStyle="mb-8"
      >
        <HeaderModalManageItem
          showDeleteButton={!!newsTypesIdUpdate}
          deleteButtonContent="Apagar tipo"
          onDeleteButtonClick={handleDeleteButtonClick}
          isEditable={!isShowEditButton}
          title={newsTypesIdUpdate ? 'Tipo de notícia' : 'Novo tipo de notícia'}
          mainButtonContent={
            newsTypesIdUpdate && !showEditContent ? 'Editar' : 'Ok'
          }
          onCancelClick={handleCloseMenageModal}
          onOkClick={
            !isShowEditButton ? handleSubmit(handleButtonOkClick) : undefined
          }
          onEditClick={isShowEditButton ? handleEditButtonClick : undefined}
        />

        <div className="mb-2 mt-24 w-full">
          <Input
            maxLength={255}
            type="text"
            placeholder="Tipo de notícia"
            disabled={isShowEditButton}
            helperText={errors.newsType?.message}
            isDirty={dirtyFields.newsType}
            onCancelIconClick={handleClearNewsTypeField}
            {...register('newsType')}
            value={newsType}
          />
        </div>
        {newsTypesIdUpdate && (
          <>
            <VinculationHeader
              isFething={isFetchingNewsByType}
              isVinculationDisabled={!showEditContent}
              onVinculateClick={() => {}}
              vinculationButtonContent=""
              onControlButtonClick={handleChangeEditOrderButtonClick}
              orderControlButtonContent={
                canEdit ? 'Concluir ordem' : 'Editar ordem'
              }
              vinculationContent={
                <>
                  {newsByTypeLength > 0 ? newsByTypeLength : 'Nenhuma'} notícia
                  {newsByTypeLength > 1
                    ? 's estão vinculadas'
                    : ' está vinculada'}{' '}
                  a esse tipo
                </>
              }
            />

            <SerchInput
              ref={newsByTypeInputRef}
              onSearch={handleNewsByTypeSearch}
              onButtonClick={handleCancelNewsByTypeSearch}
              showCancelOptions={isNewsByTypeInputDirty}
              onCancelSearch={handleCancelNewsByTypeSearch}
              disabled={canEdit}
            />

            <Table.Head rowStyle="px-0 items-center">
              <div className="ml-4 flex">
                <Table.Cell
                  items={newsByTypeShown}
                  type="id"
                  onSort={handleSortNewsByTypeTable}
                  isSortActive={sortFieldNewsByType === 'id'}
                  className="w-[5.5rem]"
                >
                  <span className="text-xs">Código</span>
                </Table.Cell>
                <Table.Cell
                  items={newsByTypeShown}
                  type="name"
                  onSort={handleSortNewsByTypeTable}
                  isSortActive={sortFieldNewsByType === 'name'}
                >
                  <span className="text-xs">Nome</span>
                </Table.Cell>
              </div>
            </Table.Head>
            {isFetchingNewsByType && <Loading heightNormal />}
            {!isFetchingNewsByType && newsByType && (
              <Table.Root
                itemType="Notícias"
                itemsLength={newsByTypeLength}
                paddingTop="0"
                className="h-[calc(90vh-28rem)]"
                showNoContentMessage={false}
              >
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable
                    isDropDisabled={isShowEditButton || !canEdit}
                    droppableId="news"
                    type="list"
                    direction="vertical"
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {newsByTypeShown.map((news, index) => (
                          <Table.Row key={news.id} className="ml-4 px-0">
                            <Draggable
                              isDragDisabled={isShowEditButton || !canEdit}
                              draggableId={news.id.toString()}
                              index={index}
                              key={news.id}
                            >
                              {(provided) => (
                                <div
                                  className="flex w-full justify-between bg-backgroundPaper"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="relative flex flex-1 items-center">
                                    <Table.Cell
                                      items={newsByType.items}
                                      type="id"
                                      className="w-[5.5rem]"
                                    >
                                      {news.id}
                                    </Table.Cell>
                                    <Table.Cell
                                      items={newsByType.items}
                                      type="name"
                                    >
                                      <span>{news.name}</span>
                                    </Table.Cell>
                                  </div>
                                  {canEdit && (
                                    <img
                                      src="/icons/drag.png"
                                      alt="Ícone para arrastar Descrição"
                                      className="mr-4 size-5 cursor-grab"
                                    />
                                  )}
                                </div>
                              )}
                            </Draggable>
                          </Table.Row>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table.Root>
            )}
          </>
        )}
      </Modal>

      <ConfirmModal
        contentMessage="Deseja excluir esse tipo de descrição?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
        onNotConfirm={handleCloseConfirmDeleteModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
