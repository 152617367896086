import { ImageTypes } from '../enums/imageTypes';
import { regexExtension } from '../validations/regex';

export const getImageFromApi = (
  itemId: string | number,
  itemType: string = 'parts',
  showExtension = true,
  // itemType: ImageTypesEnum | ImageTypes = 'parts',
) => {
  return getImageStorage(itemType, itemId, showExtension);
};

export const getImageStorage = (
  // imageType: ImageTypesEnum | ImageTypes,
  imageType: string,
  imageReference: string | number,
  showExtension = true,
) => {
  let encodedImageReference = encodeURIComponent(imageReference?.toString());

  if (imageType === ImageTypes.explodedView) {
    encodedImageReference = encodeURIComponent(
      imageReference?.toString().replace(/\.(png|jpg)/g, '.svg'),
    );
  }

  const encodedImageType = encodeURIComponent(imageType);
  const hasExtension = regexExtension.test(encodedImageReference);

  return `${process.env.REACT_APP_FIREBASE_IMAGES_STORAGE_BASE_URL}/${encodedImageType}%2F${encodedImageReference}${
    !hasExtension && showExtension ? '.svg' : ''
  }?alt=media`;
};

export const verifyImageUrlExists = async (url: string) => {
  const isSizingNameExits = await fetch(url, { method: 'HEAD' });

  return isSizingNameExits.ok;
};
