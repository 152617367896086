import { useRef, useState, ChangeEvent, useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useQuery } from 'react-query';
import {
  technicalAssistanceUfsApiCache,
  technicalAssistanceCitiesApiCache,
  technicalAssistancesApiCache,
  profileCache,
} from '../../../constants/requestCacheName';
import {
  getStatesTechnicalAssistance,
  getCitiesByState,
  getTechnicalAssistances,
} from '../../services/technicalAssistanceService';
import {
  FindAllTechnicalAssistanceStates,
  TechnicalAssistance,
  TechnicalAssistancePaginated,
} from '../../types/technicalAssistance';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { Meta } from '../../../types/pagination';
import { getAllProfiles } from '../../../services/profile';
import { FindAllProfiles } from '../../../types/profile';
import { useNavigate } from 'react-router-dom';

type Filters = {
  state: string | null;
  city: string | null;
  profile: string | null;
};

export const useTechnicalAssistance = () => {
  const currentStateSelectedId = useRef<string | null>(null);

  const [search] = useSearchParams('search');

  const navigate = useNavigate();

  const filters = useRef<Filters>({
    state: null,
    city: null,
    profile: null,
  });
  const shouldResetTechnicalAssistances = useRef(false);

  const { data: states } = useQuery<FindAllTechnicalAssistanceStates>(
    technicalAssistanceUfsApiCache,
    async () => (await getStatesTechnicalAssistance()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: cities, refetch: refetchCities } =
    useQuery<FindAllTechnicalAssistanceStates>(
      `${technicalAssistanceCitiesApiCache}${currentStateSelectedId.current}`,
      async () =>
        (await getCitiesByState(currentStateSelectedId.current!)).data,
      {
        retry: false,
        refetchOnWindowFocus: false,
      },
    );

  const { data: profiles } = useQuery<FindAllProfiles>(
    profileCache,
    async () => (await getAllProfiles()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const [technicalAssistances, setTechnicalAssistances] = useState<
    TechnicalAssistance[]
  >([]);

  const verifyLoadMore = useCallback((meta: Meta) => {
    if (meta.currentPage >= meta.totalPages) {
      setCanLoadMore(false);
      return;
    }

    setCanLoadMore(true);
  }, []);

  const { refetch: refetchTechnicalAssistances, isFetching: loading } =
    useQuery<TechnicalAssistancePaginated>(
      technicalAssistancesApiCache,
      async () =>
        (
          await getTechnicalAssistances({
            page: currentPage.current,
            state: filters.current.state,
            city: filters.current.city,
            // code: selectedCode,
            // companyName: selectedCompanyName,
            profile: selectedProfile,
            search,
          })
        ).data,
      {
        retry: false,
        refetchOnWindowFocus: false,

        onSuccess: (data) => {
          verifyLoadMore(data.meta);

          if (shouldResetTechnicalAssistances.current) {
            setTechnicalAssistances(data.items);
            shouldResetTechnicalAssistances.current = false;
            return;
          }

          shouldResetTechnicalAssistances.current = false;
          setTechnicalAssistances((prev) => [...prev, ...data.items]);
        },
      },
    );

  const currentPage = useRef(1);

  const [selectedState, setSelectedState] = useState<string>('');
  const [selectedCity, setSelectedCity] = useState<string>('');
  const [selectedProfile, setSelectedProfile] = useState<string>('');
  // const [selectedCompanyName, setSelectedCompanyName] = useState<string>('');
  // const [selectedCode, setSelectedCode] = useState<string>('');
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const handleChangeState = (state: string | null) => {
    currentPage.current = 1;
    currentStateSelectedId.current = state?.toUpperCase() ?? null;
    filters.current.state = state;
    filters.current.city = null;
    setSelectedState(state ?? '');
    refetchCities();
  };

  const handleChangeProfile = (profile: string | null) => {
    currentPage.current = 1;
    filters.current.profile = profile;
    setSelectedProfile(profile ?? '');
  };

  const handleChangeCity = (event: ChangeEvent<HTMLSelectElement>) => {
    currentPage.current = 1;
    filters.current.city = event.target.value;
    setSelectedCity(event.target.value);
  };

  const loadMoreData = useCallback(async () => {
    if (!canLoadMore) return;

    currentPage.current++;
    refetchTechnicalAssistances();
  }, [canLoadMore, refetchTechnicalAssistances]);

  useEffect(() => {
    shouldResetTechnicalAssistances.current = true;
    refetchTechnicalAssistances();
  }, [
    refetchTechnicalAssistances,
    selectedState,
    selectedCity,
    selectedProfile,
  ]);

  // useEffect(() => {
  //   shouldResetTechnicalAssistances.current = true;
  //   refetchTechnicalAssistances();
  // }, [refetchTechnicalAssistances, selectedCity]);

  useEffect(() => {
    if (inView && !loading) {
      loadMoreData();
    }
  }, [inView, loadMoreData, loading]);

  useEffect(() => {
    setCanLoadMore(true);
    shouldResetTechnicalAssistances.current = true;
    refetchTechnicalAssistances();
  }, [refetchTechnicalAssistances, search]);

  useEffect(() => {
    if (search) {
      setSearchValue(search);
    }
  }, [search]);

  const handleSearch = () => {
    currentPage.current = 1;
    shouldResetTechnicalAssistances.current = true;
    refetchTechnicalAssistances();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearch();
  };

  const handleSearchInputChange = (value: string) => {
    setSearchValue(value);
  };

  const handleEnterClick = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      navigate({
        pathname: '/assistencia',
        search: searchValue ? `search=${searchValue}` : '',
      });
    }
  };

  return {
    states,
    cities,
    technicalAssistances,
    loading,
    selectedUf: selectedState,
    selectedCity,
    searchValue,
    profiles,
    selectedProfile,
    handleEnterClick,
    handleChangeProfile,
    handleSearchInputChange,
    ref,
    handleChangeState,
    handleChangeCity,
    handleSubmit,
  };
};
