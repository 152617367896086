import HeaderModalManageItem from '../../HeaderModalManageItem';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Input from '../../Input';
import Modal from '../../Modal';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { DescriptionFormData } from '../../../types/description';
import { useDescriptionMenageModal } from './useDescriptionMenageModal';
import Loading from '../../Loading';
import SerchInput from '../../SearchInput';
import Table from '../../Table';
import Card from '../../Card';

type DescriptionFormDataBoolean = {
  [K in keyof DescriptionFormData]: boolean;
};

type DescriptionManageModalProps = {
  isMenageModalOpen: boolean;
  currentDescriptionId: number | null;
  isShowEditButton: boolean;
  errors: FieldErrors<DescriptionFormData>;
  dirtyFields: Partial<Readonly<DescriptionFormDataBoolean>>;
  currentDescriptionType: string;
  handleOpenSelectModal: () => void;
  register: UseFormRegister<DescriptionFormData>;
  handleCloseMenageModal: () => void;
  handleDeleteButtonClick: () => void;
  onOkClick: () => void;
  handleEdit: () => void;
  handleClearDescription: () => void;
  description?: string;
};

export default function DescriptionManageModal({
  isMenageModalOpen,
  currentDescriptionId,
  isShowEditButton,
  errors,
  dirtyFields,
  currentDescriptionType,
  description,
  handleOpenSelectModal,
  register,
  handleEdit,
  handleCloseMenageModal,
  handleDeleteButtonClick,
  onOkClick,
  handleClearDescription,
}: DescriptionManageModalProps) {
  const {
    inputRef,
    isFetchingAllProducts,
    isSearchInputDirty,
    productsLength,
    productsShown,
    sortField,
    isDescriptionProductModalOpen,
    currentProduct,
    screenHeight,
    handleProductLineClick,
    handleCloseDescriptionProductModal,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
  } = useDescriptionMenageModal(currentDescriptionId);

  return (
    <>
      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseMenageModal}
        cancelIcon={false}
      >
        <HeaderModalManageItem
          showDeleteButton={!!currentDescriptionId}
          deleteButtonContent="Apagar descrição"
          onDeleteButtonClick={handleDeleteButtonClick}
          isEditable={!isShowEditButton}
          title={currentDescriptionId ? 'Descrição' : 'Nova descrição'}
          mainButtonContent={isShowEditButton ? 'Editar' : 'Ok'}
          onCancelClick={handleCloseMenageModal}
          onOkClick={!isShowEditButton ? onOkClick : undefined}
          onEditClick={isShowEditButton ? handleEdit : undefined}
        />
        <div className="mr-24 mt-10 flex w-full flex-col">
          <Input
            type="text"
            label="Descrição"
            disabled={isShowEditButton}
            helperText={errors.description?.message}
            isDirty={!!dirtyFields.description}
            onCancelIconClick={handleClearDescription}
            {...register('description')}
            value={description}
          />
          <div className="mt-4 flex cursor-pointer items-center gap-6 border-b-[1px]">
            <Input
              readOnly
              label="Tipo de descrição"
              value={currentDescriptionType}
              onClick={isShowEditButton ? undefined : handleOpenSelectModal}
              className={`cursor-pointer border-none p-0 text-${currentDescriptionType !== 'Nenhuma' ? 'primary' : 'secondaryText'}`}
              inputStyle="cursor-pointer"
              {...register('descriptionType')}
            />
            <ChevronRightIcon className="text-secondaryText" />
          </div>
          <span className="mt-1 text-xs text-red-600">
            {errors.descriptionType?.message}
          </span>
        </div>
        <div className="mt-8">
          <h2 className="mb-4 text-lg">Peças e produtos vinculados</h2>
          {currentDescriptionId && isFetchingAllProducts && (
            <Loading heightNormal />
          )}
          {currentDescriptionId && !isFetchingAllProducts && (
            <>
              <SerchInput
                ref={inputRef}
                onSearch={handleSearch}
                onButtonClick={handleCancelSearch}
                showCancelOptions={isSearchInputDirty}
                onCancelSearch={handleCancelSearch}
              />
              <Table.Head rowStyle="px-0">
                <div className="flex">
                  <Table.Cell
                    items={productsShown}
                    type="id"
                    onSort={handleSortTable}
                    isSortActive={sortField === 'id'}
                    className="w-[5.5rem]"
                  >
                    <span className="text-xs">Código</span>
                  </Table.Cell>
                  <Table.Cell
                    items={productsShown}
                    type="name"
                    onSort={handleSortTable}
                    isSortActive={sortField === 'name'}
                  >
                    <span className="text-xs">Nome</span>
                  </Table.Cell>
                </div>
              </Table.Head>

              <Table.Root
                itemType="Produtos"
                itemsLength={productsLength}
                paddingTop="0"
                data-smHeight={screenHeight < 800}
                data-xSmHeight={screenHeight < 750}
                data-2xSmHeight={screenHeight < 700}
                className="mb-8 max-h-[17rem] data-[2xSmHeight=true]:!max-h-[10rem] data-[smHeight=true]:max-h-[14rem] data-[xSmHeight=true]:max-h-[12rem]"
                showNoContentMessage={false}
              >
                {productsShown.map((product) => (
                  <Table.Row
                    key={product.id}
                    className="px-0"
                    onClick={() => handleProductLineClick(product)}
                  >
                    <div className="flex">
                      <Table.Cell
                        items={productsShown}
                        type="id"
                        className="w-[5.5rem]"
                      >
                        {product.id}
                      </Table.Cell>
                      <Table.Cell items={productsShown} type="name">
                        <span>{product.name}</span>
                      </Table.Cell>
                    </div>
                  </Table.Row>
                ))}
              </Table.Root>
            </>
          )}
        </div>
      </Modal>
      {currentProduct && (
        <Modal
          isOpen={isDescriptionProductModalOpen}
          onClose={handleCloseDescriptionProductModal}
        >
          <Card.Root>
            <h2 className="mb-8 text-center text-xl">Descrição</h2>
            <Card.Infos
              productId={currentProduct.id}
              productName={currentProduct.name}
            />
            <Card.Table productId={currentProduct.id} className="mt-8" />
          </Card.Root>
        </Modal>
      )}
    </>
  );
}
