import { useQuery, useQueryClient } from 'react-query';
import { useLiveSearch } from '../../buffalos/hooks/useLiveSearch';
import {
  liveSearchPartsGroupItemBuffaloCache,
  liveSearchProductBuffaloCache,
  partsByProductIdPartsGroupIdApiCache,
} from '../../constants/requestCacheName';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useQueryCache } from '../../hooks/useQueryCache';
import {
  FindAllPartsGroupItem,
  PartsGroupItem,
  PartsGroupItemDomainLiveSearch,
  PartsGroupItemDomainLiveSearchPaginated,
} from '../../types/partsGroupItem';
import { getPartsGroupItemDomainLiveSearchPaginated } from '../../services/partsGroupItem';
import { usePartsCatalogStore } from '../../store/partsCatalog';
import { useTheme } from '@mui/material';

export const useSecondaryHeader = (itemId: string) => {
    const theme = useTheme();
  const navigate = useNavigate();
  const mobileMaxWidth = 1280;
  const { clearCache } = useQueryCache();
  const queryClient = useQueryClient();

  const [searchClick, setSearchClick] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileMaxWidth);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const { data: partsSearch, refetch: refetchPartsSearch } =
    useQuery<PartsGroupItemDomainLiveSearchPaginated>(
      liveSearchPartsGroupItemBuffaloCache,
      async () =>
        (
          await getPartsGroupItemDomainLiveSearchPaginated(
            itemId,
            inputRef.current?.value ?? null,
            null, //Catálogo não faz distinção por domínio
          )
        ).data,
      {
        retry: false,
        enabled: false,
        refetchOnWindowFocus: false,
      },
    );

  const {
    state: { currentPartsGroup, currentPartIdHover, canEdit },
    actions: {
      setCurrentProductId,
      setCurrentPartClicked,
      setCurrentPartIdHover,
      setCurrentPartsGroup,
    },
  } = usePartsCatalogStore();

  const {
    inputRef,
    handleClickOutAutoComplete,
    handleSearchInputChange,
    handleClearSearchCache,
    handleClearSearchAndSetFocus,
  } = useLiveSearch({
    cacheName: liveSearchPartsGroupItemBuffaloCache,
    refetch: refetchPartsSearch,
  });

  const handlesearchClick = () => {
    setSearchClick((prev) => !prev);
    clearCache(liveSearchProductBuffaloCache);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const getCurrentParts = useCallback(() => {
    const currentParts = queryClient.getQueryData<FindAllPartsGroupItem>(
      `${partsByProductIdPartsGroupIdApiCache}${currentPartsGroup?.id}`,
    );
    return currentParts;
  }, [currentPartsGroup?.id, queryClient]);

  const handlePartMouseEnter = useCallback(
    (partId: number) => {
      setCurrentPartIdHover(partId);
    },
    [setCurrentPartIdHover],
  );

  const handleClickPart = useCallback(
    (partId: number, partGroupItemSelect?: PartsGroupItem) => {
      if (partGroupItemSelect) {
        setCurrentPartClicked(partGroupItemSelect);
        handlePartMouseEnter(partId);
        return;
      }

      const currentParts = getCurrentParts();
      if (currentParts) {
        const part =
          currentParts?.items.find(({ part }) => part?.id === partId) ?? null;
        setCurrentPartClicked(part);
        handlePartMouseEnter(partId);
      }
    },
    [getCurrentParts, handlePartMouseEnter, setCurrentPartClicked],
  );

  const handleProductSearchClick = useCallback(
    async ({ partsGroupItem }: PartsGroupItemDomainLiveSearch) => {
      const { part, partsGroup } = partsGroupItem;

      setCurrentPartsGroup(partsGroup);

      handleClickPart(+part.id, partsGroupItem);
      handleClearSearchCache();
      setSearchClick(false);
      clearCache(liveSearchProductBuffaloCache);
      if (inputRef.current && isMobile) {
        inputRef.current.value = '';
      }
    },
    [
      clearCache,
      handleClearSearchCache,
      handleClickPart,
      inputRef,
      isMobile,
      setCurrentPartsGroup,
    ],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!partsSearch || partsSearch.items.length === 0) return;

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        setSelectedIndex((prev) =>
          Math.min(prev + 1, partsSearch.items.length - 1),
        );
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        setSelectedIndex((prev) => Math.max(prev - 1, 0));
      }

      if (event.key === 'Enter' && selectedIndex !== -1) {
        event.preventDefault();
        handleProductSearchClick(partsSearch.items[selectedIndex]);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleProductSearchClick, partsSearch, selectedIndex]);

  const [inputWidth, setInputWidth] = useState(0);
  useEffect(() => {
    if (inputRef.current) {
      setInputWidth(inputRef.current.offsetWidth);
    }
  }, []);

  return {
    theme,
    inputRef,
    partsSearch,
    selectedIndex,
    inputWidth,
    handleClearSearchAndSetFocus,
    handleProductSearchClick,
    handleClickOutAutoComplete,
    handleSearchInputChange,
    handleClearSearchCache,
  };
};
