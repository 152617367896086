import { useHandleTable } from '../../hooks/useHandleTable';
import { getProductsBarCodeOptionalyFilteredPaginated } from '../../services/productBarCode';
import { ProductBarCode, LabelSizeTypes } from '../../types/productBarCode';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from '../../hooks/useSearchParams';
import { productBarCodeSearchBaseEndpoint } from '../../constants/endpoints';
import { useCallback, useEffect, useRef } from 'react';
import { useModal } from '../../hooks/useModal';
import { Search, search } from '../../services/search';

export const useBarCodeListing = (totalItemsSelected: number) => {
  const [labelSize] = useSearchParams('labelSize') as [LabelSizeTypes];

  const navigate = useNavigate();

  const currentFilterToChange = useRef('');

  // const {
  //   data: productsBarCode,
  //   isFetching: isFetchingProductsBarCode,
  //   refetch: refetchProductsBarCode,
  // } = useQuery<ProductsBarCodePaginated>(
  //   productBarCodeCache,
  //   async () =>
  //     (
  //       await getProductsBarCodeOptionalyFilteredPaginated(
  //         currentPage,
  //         null,
  //         null,
  //         labelSize,
  //       )
  //     ).data,
  //   {
  //     retry: false,
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //     onSuccess: (data) => {
  //       applySortAfterFilter(data.items, currentItemsSort, sortField);
  //       // if (inputRef.current?.value) {
  //       //   const search = applySearchAfterFilter<ProductBarCode>(
  //       //     data.items,
  //       //     inputRef.current.value,
  //       //     (item, search) => {
  //       //       return (
  //       //         item.id.toString().includes(search) ||
  //       //         item.name.includes(search)
  //       //       );
  //       //     },
  //       //   );
  //       //   overrideCache<ProductsBarCodePaginated>(productBarCodeCache, {
  //       //     items: search,
  //       //     meta: data.meta,
  //       //   });
  //       // }
  //       handleCancelSearch();
  //     },
  //   },
  // );

  const {
    isModalOpen: isConfirmChangeFilterModalOpen,
    handleCloseModal: handleCloseConfirmChangeFilterModal,
    handleOpenModal: handleOpenConfirmChangeFilterModal,
  } = useModal();

  const loadMore = useCallback(
    async (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      const response = await getProductsBarCodeOptionalyFilteredPaginated(
        page,
        direction,
        limit,
        labelSize,
      );
      return response;
    },
    [labelSize],
  );

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return search<ProductBarCode>(baseUrl, searchValue, page, null, null, [
        { name: 'labelSize', value: labelSize },
      ]);
    },
    [labelSize],
  );

  const {
    sortField,
    inputRef,
    itemsLength: productBarCodeLength,
    itemsShown,
    itemsShownLength,
    isLoadMoreFetching,
    endPageRef,
    isSearchInputDirty,
    refetchSearch,
    isSearchFetching,
    handleCancelSearch,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch,
    handleSortTable,
  } = useHandleTable(
    0,
    [],
    null,
    null,
    productBarCodeSearchBaseEndpoint,
    loadMore,
    null,
    doSearch as Search,
  );
  const productsBarCodeShown = itemsShown as ProductBarCode[];

  // useEffect(() => {
  //   refetchProductsBarCode();
  // }, [labelSize, refetchProductsBarCode]);
  useEffect(() => {
    refetchSearch();
  }, [labelSize, refetchSearch]);

  useEffect(() => {
    if (!labelSize) {
      navigate({ search: `labelSize=p` }, { replace: true });
    }
  }, [labelSize, navigate]);

  // useEffect(() => {
  //   if (productsBarCode?.items) {
  //     defineItemsShown(productsBarCode.items);
  //   }
  // }, [defineItemsShown, productsBarCode?.items]);

  const handleFilter = (filterName: string) => {
    currentFilterToChange.current = filterName;
    if (totalItemsSelected > 0) {
      handleOpenConfirmChangeFilterModal();
      return;
    }

    handleConfirmChangeFilter();
  };

  const handleConfirmChangeFilter = () => {
    handleCloseConfirmChangeFilterModal();
    navigate(
      { search: `labelSize=${currentFilterToChange.current}` },
      { replace: true },
    );
  };

  return {
    sortField,
    inputRef,
    productBarCodeLength,
    isLoadMoreFetching,
    endPageRef,
    isSearchInputDirty,
    productsBarCodeShown,
    isFetchingProductsBarCode: isSearchFetching,
    labelSize,
    productsBarCodeShownLength: itemsShownLength,
    isConfirmChangeFilterModalOpen,
    handleConfirmChangeFilter,
    handleCloseConfirmChangeFilterModal,
    handleSearchDatabase,
    handleFilter,
    handleCancelSearch,
    handleEnterClickSearchInput,
    handleSearch,
    handleSortTable,
  };
};
