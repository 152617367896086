import { EmailSignature } from '../../types/emailSignature';
import Alert from '../Alert';
import Footer from '../Footer';
import Loading from '../Loading';
import Main from '../Main';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { useEmailSignatureContent } from './useEmailSignatureContent';
import Filter from '../Filter';
import LoadMore from '../LoadMore';
import { formatPhoneNumber } from '../../utils/format';
import Checkbox from '../Checkbox';
import ListingHeader from '../ListingHeader';
import EditButton from '../EditButton';

type EmailSignatureContentProps = {
  onTableRowClick: (emailSignature: EmailSignature) => void;
  onAddEmailSignatureClick?: () => void;
};

export default function EmailSignatureContent({
  onTableRowClick,
  onAddEmailSignatureClick,
}: EmailSignatureContentProps) {
  const {
    sortField,
    inputRef,
    isSearchInputDirty,
    isFetching,
    emailSignaturesShown,
    emailSignatureLength,
    domainWidth,
    domains,
    emailSignaturesShownLength,
    canEditDomain,
    errorMessage,
    checkedDomainsStatus,
    checkedItems,
    domainsFilter,
    endPageRef,
    isLoadMoreFetching,
    setCheckedItems,
    handleButtonCancelEditClick,
    handleDoubleClickFilter,
    handleFilter,
    handleChangeAllDomainsCheck,
    clearError,
    handleChangeDomainCheck,
    handleChangeEditDomainButtonClick,
    handleSearch,
    handleCancelSearch,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSortTable,
  } = useEmailSignatureContent();

  return (
    <>
      <ListingHeader
        add
        onAddIconClick={onAddEmailSignatureClick}
        pdfTop="3rem"
        pdf={false}
      >
        <div className="mt-4">
          <div className="mb-4 flex justify-between">
            <h1 className="text-2xl">Assinaturas</h1>
            <EditButton
              setContentList={setCheckedItems}
              contentList={checkedItems}
              finishMessage="Concluir domínio"
              canEdit={canEditDomain}
              editMessage="Editar domínio"
              cancelMessage="Cancelar seleção"
              onCancelEditClick={handleButtonCancelEditClick}
              onEditButtonClick={handleChangeEditDomainButtonClick}
              // disable={!domainsFilter || domainsFilter === 'todos'}
            />
          </div>

          <Filter
            title="Domínios"
            filters={[
              {
                filter: 'todos',
                isCurrent: !domainsFilter || domainsFilter === 'todos',
                name: 'Todos',
              },
              ...(domains?.items ?? []).map((domain) => ({
                filter: domain.name,
                isCurrent: domainsFilter === domain.name,
                name: domain.name,
              })),
            ]}
            onFilter={handleFilter}
            onDoubleClickFilter={handleDoubleClickFilter}
          />
          <SearchInput
            ref={inputRef}
            onSearch={handleSearch}
            onButtonClick={handleSearchDatabase}
            showCancelOptions={isSearchInputDirty}
            onCancelSearch={handleCancelSearch}
            buttonContent="Buscar"
            onKeyDown={handleEnterClickSearchInput}
          />
        </div>
        <Table.Head>
          <div className="flex flex-1 justify-between gap-2">
            <Table.Cell
              items={emailSignaturesShown}
              type="fullName"
              onSort={handleSortTable}
              isSortActive={sortField === 'fullName'}
              className="flex-1"
            >
              Nome
            </Table.Cell>
            <Table.Cell
              items={emailSignaturesShown}
              type="department"
              onSort={handleSortTable}
              isSortActive={sortField === 'department'}
              className="flex-1"
            >
              Departamento
            </Table.Cell>
            <Table.Cell
              items={emailSignaturesShown}
              type="email"
              onSort={handleSortTable}
              isSortActive={sortField === 'email'}
              className="flex-1"
            >
              Email
            </Table.Cell>
            <Table.Cell
              items={emailSignaturesShown}
              type="phoneNumber"
              onSort={handleSortTable}
              isSortActive={sortField === 'phoneNumber'}
              className="flex-1"
            >
              Telefone
            </Table.Cell>
            <Table.Cell
              items={emailSignaturesShown}
              type="cellPhoneNumber"
              onSort={handleSortTable}
              isSortActive={sortField === 'cellPhoneNumber'}
              className="flex-1"
            >
              Celular
            </Table.Cell>
          </div>
          <div className="flex gap-4">
            {checkedDomainsStatus.map((domain) => (
              <Table.CellStatic
                key={`label-${domain.domainId}`}
                style={{ width: `${domainWidth}ch` }}
                className="text-secondaryText"
              >
                <Checkbox
                  label={domain.domainName}
                  status={domain.status}
                  onChange={(event) =>
                    handleChangeAllDomainsCheck(
                      { id: domain.domainId, name: domain.domainName },
                      event.target.checked,
                    )
                  }
                  checked={domain.status === 'all'}
                  id={`domain${domain.domainId}`}
                  className="size-4"
                  disabled={!canEditDomain}
                />
              </Table.CellStatic>
            ))}
          </div>
        </Table.Head>
      </ListingHeader>

      <Main>
        {isFetching && <Loading loadMore />}
        {!isFetching && (
          <Table.Root
            itemType="assinaturas"
            itemsLength={emailSignatureLength}
            paddingTop="0"
            className="!overflow-x-hidden"
          >
            {emailSignaturesShown.map((emailSignature) => {
              return (
                <Table.Row key={emailSignature.id}>
                  <div
                    className="flex flex-1 justify-between gap-2"
                    onClick={() => onTableRowClick(emailSignature)}
                  >
                    <Table.CellStatic className="flex-1">
                      {emailSignature.fullName}
                    </Table.CellStatic>
                    <Table.CellStatic className="flex-1">
                      {emailSignature.department}
                    </Table.CellStatic>
                    <Table.CellStatic className="flex-1">
                      {emailSignature.email}
                    </Table.CellStatic>
                    <Table.CellStatic className="flex-1">
                      {formatPhoneNumber(emailSignature.phoneNumber)}
                    </Table.CellStatic>
                    <Table.CellStatic className="flex-1">
                      {formatPhoneNumber(emailSignature.cellPhoneNumber)}
                    </Table.CellStatic>
                  </div>
                  <div className="flex items-center gap-4">
                    {domains?.items.map((domain) => {
                      const initialSignatureDomain =
                        emailSignature.signatureDomains?.find(
                          (signatureDomain) =>
                            signatureDomain.domain.id === domain.id,
                        );

                      const signatureDomain = checkedItems
                        ?.find(
                          (signatureDomain) =>
                            signatureDomain.id === emailSignature.id,
                        )
                        ?.signatureDomains?.find(
                          (signatureDomain) =>
                            signatureDomain.domain.id === domain.id,
                        );
                      return (
                        <Table.CellStatic
                          className="flex items-center justify-center gap-2"
                          key={`checkbox-${domain.id}`}
                          style={{
                            width: `${domainWidth}ch`,
                          }}
                        >
                          <Checkbox
                            checked={signatureDomain?.domain.id === domain.id}
                            onChange={(event) =>
                              handleChangeDomainCheck(
                                event.target.checked,
                                (initialSignatureDomain?.id ?? 0) > 0
                                  ? initialSignatureDomain?.id
                                  : undefined,
                                domain,
                                emailSignature.id,
                              )
                            }
                            className="size-4"
                            disabled={!canEditDomain}
                          />
                        </Table.CellStatic>
                      );
                    })}
                  </div>
                </Table.Row>
              );
            })}
            <LoadMore
              isModal={false}
              isLoadMoreFetching={isLoadMoreFetching}
              isContentFetching={isFetching}
              ref={endPageRef}
            />
          </Table.Root>
        )}
      </Main>

      <Footer
        current={emailSignaturesShownLength}
        total={emailSignatureLength}
        itemType="Assinaturas"
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
