import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  EmailTrigger,
  EmailTriggerFormData,
  FindAllEmailTriggerList,
  SaveEmailTriggerDto,
  UpdateEmailTriggerDto,
} from '../../../types/emailTrigger';
import {
  emailTriggerSchema,
  updateEmailTriggerSchema,
} from '../../../validations/schemas';
import { useRef } from 'react';
import { useModal } from '../../../hooks/useModal';
import { useEdit } from '../../../hooks/useEdit';
import { useError } from '../../../hooks/useError';
import { useMutation } from 'react-query';
import {
  deleteEmailTrigger,
  saveEmailTrigger,
  updateEmailTrigger,
} from '../../../services/emailTrigger';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { emailTriggerCache } from '../../../constants/requestCacheName';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { AxiosError, isAxiosError } from 'axios';

export const useEmailTrigger = () => {
  const emailTriggerIdUpdate = useRef<number | null>(null);
  const { errorMessage, clearError, setErrorMessage } = useError();

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: closeMenageModal,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: handlecloseConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<EmailTriggerFormData>({
    resolver: zodResolver(
      emailTriggerIdUpdate.current
        ? updateEmailTriggerSchema
        : emailTriggerSchema,
    ),
    defaultValues: {
      name: '',
      email: '',
      password: '',
    },
  });

  const name = watch('name');
  const email = watch('email');
  const password = watch('password');

  const handleTableRowClick = (emailTrigger: EmailTrigger) => {
    emailTriggerIdUpdate.current = emailTrigger.id;

    setValue('name', emailTrigger.name);
    setValue('email', emailTrigger.email);
    // setValue('password', emailTrigger.password);
    handleOpenMenageModal();
  };

  const handleAddEmailClick = () => {
    handleOpenMenageModal();
  };

  const handleCloseManageModal = () => {
    emailTriggerIdUpdate.current = null;
    reset();
    handleUnedit();
    closeMenageModal();
  };

  const handleConfirmDelete = () => {
    deleteEmailTriggerMutate();
    handlecloseConfirmDeleteModal();
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmDeleteModal();
  };

  const handleButtonOkClick = ({
    name,
    email,
    password,
  }: EmailTriggerFormData) => {
    const upsertDto = {
      name,
      email,
      password,
    };

    if (emailTriggerIdUpdate.current) {
      updateEmailTriggerMutate({
        id: emailTriggerIdUpdate.current,
        ...upsertDto,
      });
      return;
    }

    saveEmailTriggerMutate(upsertDto);
  };

  const handleClearDomain = (value: keyof EmailTriggerFormData) => {
    setValue(value, '', { shouldDirty: true });
  };

  const isShowEditButton = !!emailTriggerIdUpdate.current && !canEdit;

  const { mutate: saveEmailTriggerMutate } = useMutation({
    mutationFn: async (dto: SaveEmailTriggerDto) => {
      return (await saveEmailTrigger(dto)).data;
    },

    onSuccess: (data) => {
      addItemOnScreen<FindAllEmailTriggerList>(emailTriggerCache, data);
      handleCloseManageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar email de disparo',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Email já cadastrado');
            return true;
          }
        },
      );
    },
  });

  const { mutate: updateEmailTriggerMutate } = useMutation({
    mutationFn: async (dto: UpdateEmailTriggerDto) => {
      return (await updateEmailTrigger(dto)).data;
    },

    onSuccess: (data) => {
      updateItemOnScreen<FindAllEmailTriggerList>(emailTriggerCache, data);

      // handleCloseManageModal();
      handleUnedit();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar email',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Email já cadastrado');
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteEmailTriggerMutate } = useMutation({
    mutationFn: async () => {
      if (emailTriggerIdUpdate.current) {
        await deleteEmailTrigger(emailTriggerIdUpdate.current);
        return;
      }

      setErrorMessage('Falha ao deletar domínio');
    },

    onSuccess: () => {
      if (emailTriggerIdUpdate.current) {
        removeItemFromScreen<FindAllEmailTriggerList>(
          emailTriggerCache,
          emailTriggerIdUpdate.current,
        );
      }

      handleCloseManageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar email',
        setErrorMessage,
        () => {
          if (isAxiosError(error) && error.response?.status === 409) {
            setErrorMessage(
              'Este email só pode ser apagado depois que não houver itens associados a ele',
            );
            return true;
          }
        },
      );
    },
  });

  return {
    name,
    email,
    password,
    isMenageModalOpen,
    errorMessage,
    isConfirmDeleteModalOpen,
    emailTriggerIdUpdate: emailTriggerIdUpdate.current,
    isShowEditButton,
    errors,
    dirtyFields,
    handleClearDomain,
    handleSubmit,
    register,
    clearError,
    handleEdit,
    setErrorMessage,
    handleTableRowClick,
    handleCloseManageModal,
    handleAddEmailClick,
    handlecloseConfirmDeleteModal,
    handleConfirmDelete,
    handleDeleteButtonClick,
    handleButtonOkClick,
  };
};
