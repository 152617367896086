import { useTheme } from '@mui/material';
import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { documentTypesCache } from '../../constants/requestCacheName';
import { useHandleTable } from '../../hooks/useHandleTable';
import generateProductsPdf from '../../pdf/productsPdf/generateProductsPdf';
import { getProductsPaginated } from '../../services/products';
import { Product, ProductDocument } from '../../types/products';
import { useSearchParams } from '../../hooks/useSearchParams';
import { productSearchBaseEndpoint } from '../../constants/endpoints';
import { verifyScreenIsLessThan } from '../../utils/verifyScreenIsLessThan';
import { useError } from '../../hooks/useError';
import { Service } from '.';
import { Search, search as searchDatabase } from '../../services/search';
import { getAuth } from '../../utils/auth';
import { ProductFilters as ProductFiltersShow } from '../../types/productFilters';
import { getDocumentTypesPaginated } from '../../services/documentTypes';
import { DocumentTypePaginated } from '../../types/documentTypes';
import { Role } from '../../types/userType';

type ProductFilters =
  | 'Todos'
  | 'new'
  | 'outOfLine'
  | 'reviewedApproved'
  | 'unavailableImage';

type FilterFields = {
  new: boolean;
  reviewedApproved: boolean;
  outOfLine: boolean;
};

export type ProductClean = FilterFields & {
  id: number;
  name: string;
  isAvailable: boolean;
  description: string;
  productDocument: ProductDocument[];
  hasImage: boolean;
  associatedProductId: number;
};

export const useProductsContent = (
  isModal?: boolean,
  shouldFilter?: boolean,
  service?: Service,
  search?: Search,
) => {
  const theme = useTheme();

  const [productFilter] = useSearchParams('productFilter') as [ProductFilters];
  const [documentTypeUnavailableFilter] = useSearchParams(
    'documentTypeUnavailable',
  ) as [string];
  const [imageUnavailableFilter] = useSearchParams('imageUnavailable') as [
    string,
  ];

  // const [productsHasImage, setProductsHasImage] = useState<ProductHasImage[]>(
  //   [],
  // );

  const [nameWidth, setNameWidth] = useState(0);

  // const verifyProductWithoutImage = async (products: Product[]) => {
  //   const productsHasImage: ProductHasImage[] = await Promise.all(
  //     products.map((product) => verifyProductHasImageByProductId(product?.id)),
  //   );

  //   setProductsHasImage(productsHasImage);
  // };

  const { data: documentTypes } = useQuery<DocumentTypePaginated>(
    documentTypesCache,
    async () => (await getDocumentTypesPaginated()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const navigate = useNavigate();

  const previousInputValue = useRef('');

  const doSearch = useCallback(
    async (baseUrl: string, searchValue: string, page: number) => {
      // Remove o prefixo
      const documentTypeUnavailableFilterValue = documentTypeUnavailableFilter
        ?.split(' ')
        .slice(1)
        .join(' ');

      const search = await searchDatabase<Product>(
        baseUrl,
        searchValue,
        page,
        null,
        null,
        [
          { name: 'productFilter', value: productFilter },
          {
            name: 'documentTypeUnavailable',
            value: documentTypeUnavailableFilterValue,
          },
          {
            name: 'imagesUnavailable',
            value: imageUnavailableFilter,
          },
        ],
      );

      if (inputRef.current && previousInputValue.current) {
        inputRef.current.value = previousInputValue.current;
        previousInputValue.current = '';
      }

      return search;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentTypeUnavailableFilter, imageUnavailableFilter, productFilter],
  );

  const loadMore = useCallback(
    (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      const documentTypeUnavailableFilterValue = documentTypeUnavailableFilter
        ?.split(' ')
        .slice(1)
        .join(' ');
      return getProductsPaginated(page, direction, limit, null, [
        { name: 'productFilter', value: productFilter },
        {
          name: 'documentTypeUnavailable',
          value: documentTypeUnavailableFilterValue,
        },
        {
          name: 'imagesUnavailable',
          value: imageUnavailableFilter,
        },
      ]);
    },
    [documentTypeUnavailableFilter, imageUnavailableFilter, productFilter],
  );

  const {
    sortField,
    currentPage,
    inputRef,
    pagination,
    isSearchFetching,
    isSearch,
    isLoadMoreFetching,
    endPageRef,
    isSearchInputDirty,
    itemsShown,
    itemsLength,
    itemsShownLength: productsShownLength,
    resetLoadMoreCurrentPage,
    refetchSearch,
    handleCancelSearch,
    handleEnterClickSearchInput,
    handleSearch,
    handleSortTable,
    handleSearchDatabase,
  } = useHandleTable(
    0,
    [],
    null,
    null,
    productSearchBaseEndpoint,
    loadMore,
    null,
    doSearch as Search,
  );

  const productsShown = itemsShown as ProductClean[];

  useEffect(() => {
    resetLoadMoreCurrentPage();
    refetchSearch();
  }, [
    refetchSearch,
    productFilter,
    documentTypeUnavailableFilter,
    imageUnavailableFilter,
    resetLoadMoreCurrentPage,
  ]);

  // useEffect(() => {
  //   handleSearch();
  // }, [handleSearch, itemsShown]);

  const productsLength = itemsLength;

  const currentParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  const deleteQueryParams = useCallback(() => {
    currentParams.delete('productFilter');
    currentParams.delete('documentTypeUnavailable');
    currentParams.delete('imageUnavailable');
  }, [currentParams]);

  const addFilterInUrl = useCallback(
    (filterName: string) => {
      deleteQueryParams();

      if (filterName.startsWith('documentTypeUnavailable')) {
        currentParams.set('documentTypeUnavailable', filterName.toString());
        return;
      }

      if (filterName.startsWith('imageUnavailable')) {
        currentParams.set('imageUnavailable', 'true');
        return;
      }

      if (filterName === 'Todos') return;
      currentParams.set('productFilter', filterName.toString());
    },
    [currentParams, deleteQueryParams],
  );

  const handleFilter = useCallback(
    (filterName: string) => {
      if (inputRef.current) {
        previousInputValue.current = inputRef.current.value;
        inputRef.current.value = '';
      }
      addFilterInUrl(filterName);
      navigate({ search: currentParams.toString() }, { replace: true });
    },
    [addFilterInUrl, currentParams, inputRef, navigate],
  );

  const { errorMessage, clearError, setErrorMessage } = useError();

  const getColumnStyle = useCallback(() => {
    const maxWidth = itemsShown.reduce((maxWidth, row) => {
      const width = row.name?.toString().length || 0;
      return width > maxWidth ? width : maxWidth;
    }, 0);
    setNameWidth(maxWidth > 32 ? maxWidth + 2 : 32);
  }, [itemsShown]);

  useEffect(() => {
    getColumnStyle();
  }, [getColumnStyle, itemsShown]);

  const handleGeneratePdf = () => {
    const productFilterShow: Record<ProductFilters, ProductFiltersShow> = {
      new: 'Novos',
      outOfLine: 'Fora de linha',
      reviewedApproved: 'Revisados e aprovados',
      Todos: 'Todos',
      unavailableImage: 'Imagem indisponível',
    };

    generateProductsPdf(
      productsShown,
      productFilterShow[productFilter] ?? 'Todos',
      {
        itemsShownNumber: productsShownLength,
        pageNumber: currentPage + 1,
        pagination: pagination === 100 ? 'Paginado' : 'Todas',
        totalItemsNumber: productsLength,
      },
      inputRef.current?.value,
      setErrorMessage,
    );
  };

  const isFetching = isSearchFetching;

  const isMobile = useMemo(() => verifyScreenIsLessThan(760), []);

  const auth = useMemo(() => getAuth(), []);

  const isAdmin = useMemo(
    () => auth && auth.user.role.type === Role.ADMIN,
    [auth],
  );

  const filters = useMemo(() => {
    if (isAdmin)
      return [
        {
          name: 'Todos',
          filter: 'Todos',
          isCurrent:
            (!productFilter &&
              !documentTypeUnavailableFilter &&
              !imageUnavailableFilter) ||
            productFilter === 'Todos',
        },
        {
          name: 'Revis. aprov.',
          filter: 'reviewedApproved',
          isCurrent: productFilter === 'reviewedApproved',
        },
        {
          name: 'Novos',
          filter: 'new',
          isCurrent: productFilter === 'new',
        },
        {
          name: 'Fora de linha',
          filter: 'outOfLine',
          isCurrent: productFilter === 'outOfLine',
        },
        {
          name: 'Imagem indisponível',
          filter: 'imageUnavailable',
          isCurrent: imageUnavailableFilter === 'true',
        },
        ...(documentTypes?.items ?? []).map((type) => ({
          name: `${type.name} indisponível`,
          filter: `documentTypeUnavailable ${type.name}`,
          isCurrent:
            documentTypeUnavailableFilter ===
            `documentTypeUnavailable ${type.name}`,
        })),
      ];

    return [
      {
        name: 'Todos',
        filter: 'Todos',
        isCurrent:
          (!productFilter &&
            !documentTypeUnavailableFilter &&
            !imageUnavailableFilter) ||
          productFilter === 'Todos',
      },
      {
        name: 'Novos',
        filter: 'new',
        isCurrent: productFilter === 'new',
      },
      {
        name: 'Fora de linha',
        filter: 'outOfLine',
        isCurrent: productFilter === 'outOfLine',
      },
    ];
  }, [
    documentTypeUnavailableFilter,
    documentTypes?.items,
    imageUnavailableFilter,
    isAdmin,
    productFilter,
  ]);

  return {
    theme,
    sortField,
    isFetching,
    productsShown,
    inputRef,
    productsLength,
    productsShownLength,
    isSearchInputDirty,
    isSearch,
    isMobile,
    nameWidth,
    endPageRef,
    isLoadMoreFetching,
    errorMessage,
    isAdmin,
    documentTypes,
    filters,
    clearError,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch,
    handleSortTable,
    handleFilter,
    handleCancelSearch,
    handleGeneratePdf,
  };
};
