import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { SubjectFormData } from '../../../types/subject';
import Modal from '../../Modal';
import HeaderModalManageItem from '../../HeaderModalManageItem';
import Input from '../../Input';

type SubjectFormDataBoolean = {
  [K in keyof SubjectFormData]: boolean;
};

type SubjectManageModalProps = {
  isMenageModalOpen: boolean;
  currentSubjectId: number | null;
  isShowEditButton: boolean;
  errors: FieldErrors<SubjectFormData>;
  dirtyFields: Partial<Readonly<SubjectFormDataBoolean>>;
  handleOpenSelectModal: () => void;
  register: UseFormRegister<SubjectFormData>;
  handleCloseMenageModal: () => void;
  handleDeleteButtonClick: () => void;
  onOkClick: () => void;
  handleEdit: () => void;
  handleClearSubject: () => void;
  name?: string;
};

export default function SubjectManageModal({
  isMenageModalOpen,
  currentSubjectId,
  isShowEditButton,
  errors,
  dirtyFields,
  name,
  handleOpenSelectModal,
  register,
  handleEdit,
  handleCloseMenageModal,
  handleDeleteButtonClick,
  onOkClick,
  handleClearSubject,
}: SubjectManageModalProps) {

  return (
    <>
      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseMenageModal}
        cancelIcon={false}
      >
        <HeaderModalManageItem
          showDeleteButton={!!currentSubjectId}
          deleteButtonContent="Apagar assunto"
          onDeleteButtonClick={handleDeleteButtonClick}
          isEditable={!isShowEditButton}
          title={currentSubjectId ? 'Assunto' : 'Novo assunto'}
          mainButtonContent={isShowEditButton ? 'Editar' : 'Ok'}
          onCancelClick={handleCloseMenageModal}
          onOkClick={!isShowEditButton ? onOkClick : undefined}
          onEditClick={isShowEditButton ? handleEdit : undefined}
        />
        <div className="mr-24 my-10 flex w-full">
          <Input
            type="text"
            label="Assunto"
            disabled={isShowEditButton}
            helperText={errors.name?.message}
            isDirty={!!dirtyFields.name}
            onCancelIconClick={handleClearSubject}
            {...register('name')}
            value={name}
          />
        </div>
      </Modal>
    </>
  );
}
