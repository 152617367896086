import React from 'react';
import Header from '../../components/Header';
import BackgroundPerguntasFrequentes from '../../assets/images/backgrounds/background-perguntas-frequentes.jpg';
import Footer from '../../components/Footer';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { accordion, accordionSumary } from './styles';
import { UseFaq } from './faq';

export default function Faq() {
  const { expanded, handleChange, question } = UseFaq();

  return (
    <div>
      <Header />
      <div>
        <div className="min-h-[calc(100vh-10rem)]">
          <div>
            <img
              width={1000}
              height={200}
              src={BackgroundPerguntasFrequentes}
              alt="Background Buffalo Produtos"
              className="h-20 w-full md:h-full"
            />
          </div>
          <div className="mb-10 mt-10">
            <div className="mx-11 flex flex-row justify-between">
              <div className="flex flex-col gap-4 pb-6">
                <div className="pb-4 text-4xl font-medium">
                  <span className="text-4xl text-yellow-500">&gt;</span>
                  Perguntas
                </div>
                <div className="text-base text-zinc-400">
                  Encontre aqui as respostas para as dúvidas mais comuns.
                </div>
              </div>
              {/* <div className="flex flex-col gap-4 pb-6">
                <Link
                  to={`/produtosbuffalos/`}
                  className={`flex h-full items-center justify-center rounded-xl border-2 border-zinc-300 bg-white p-6 transition hover:bg-black/85 hover:text-white`}
                >
                  <div className="flex items-center justify-center gap-3 text-center text-lg font-medium">
                    <div>
                      <img src={SettingIcon} alt="Catálogo de Peças" />
                    </div>
                    <span>Catálogo de Peças</span>
                  </div>
                </Link>
              </div> */}
            </div>
            <div className="mx-11">
              {question?.items.map((faq, index) => (
                <Accordion
                  key={index}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  sx={accordion}
                >
                  <AccordionSummary
                    sx={{
                      ...accordionSumary,
                      backgroundColor:
                        expanded === `panel${index}`
                          ? '#949494 !important'
                          : '#f6f6f6',
                      color: expanded === `panel${index}` ? '#fff' : '#333',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography>{faq.pergunta}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div dangerouslySetInnerHTML={{ __html: faq.resposta }} />
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
