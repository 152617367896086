import { ComponentProps, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { getAuth } from '../../utils/auth';
import { Role } from '../../types/userType';

type SideNavbarItemsProps = ComponentProps<'li'> & {
  to: string;
  content: ReactNode;
  icon: ReactNode;
  accessRole?: Role[];
};

export default function SideNavbarItems({
  to,
  content,
  icon,
  className,
  accessRole,
  ...rest
}: SideNavbarItemsProps) {
  const auth = getAuth();
  return (
    <li
      data-access={
        (accessRole && auth && accessRole?.includes(auth.user.role.type)) ||
        !accessRole
      }
      className="transition-fast data-[access=false]:hidden"
      {...rest}
    >
      <Link to={to} className="hover flex gap-2 text-zinc-200 hover:text-white">
        <div className="size-5">{icon}</div>
        {content}
      </Link>
    </li>
  );
}
