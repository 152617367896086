import Barcode from 'react-barcode';
import BarCodeIcon from '../../assets/barCodeIcon';
import { ItemSelected } from '../../types/productBarCode';
import Alert from '../Alert';
import Main from '../Main';
import Table from '../Table';
import { useBarCodeSelecteds } from './useBarCodeSelecteds';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from '../Loading';

type BarCodeSelectedsProps = {
  blankLabelsAmount: number;
  totalPages: number;
  itemsSelected: ItemSelected[];
  firstCheckedIndex: number;
  onRemoveItemSelectedClick: (itemSelected: ItemSelected) => void;
  onRemoveAllItemsClick: () => void;
};

export default function BarCodeSelecteds({
  blankLabelsAmount,
  totalPages,
  itemsSelected,
  firstCheckedIndex,
  onRemoveAllItemsClick,
  onRemoveItemSelectedClick,
}: BarCodeSelectedsProps) {
  const {
    isGenerateLabelDisabled,
    errorMessage,
    isLabelsGenerationLoading,
    clearError,
    handleGeneratePdf,
  } = useBarCodeSelecteds(blankLabelsAmount, itemsSelected, firstCheckedIndex);

  return (
    <section className="flex flex-col gap-4 pr-8 pt-4 text-sm">
      <div>
        {itemsSelected.map((itemSelected) => (
          <div
            key={itemSelected.id}
            id={itemSelected.id.toString()}
            className="-mt-96 w-[608px]"
          >
            <Barcode
              height={25}
              format="EAN13"
              value={itemSelected.barCode.toString()}
            />
          </div>
        ))}
      </div>

      <div className="flex h-44 flex-col gap-4">
        <h3 className="font-medium">Informações</h3>
        <div className="flex flex-col gap-4">
          <div className="flex w-56 justify-between">
            <span>Etiquetas em branco</span>
            <span>{blankLabelsAmount}</span>
          </div>
          <div className="flex items-center gap-12">
            <div className="flex w-56 justify-between">
              <span>Total de páginas</span>
              <span>{totalPages}</span>
            </div>
            <button
              disabled={isGenerateLabelDisabled}
              onClick={handleGeneratePdf}
              className="flex items-center gap-2 rounded border border-zinc-300 bg-gradient-to-b from-zinc-100 to-zinc-200 p-2 disabled:opacity-50"
            >
              <div className="size-5">
                <BarCodeIcon />
              </div>
              Gerar etiquetas
            </button>
            {isLabelsGenerationLoading && (
              <Loading className="mt-0 size-[38px]" />
            )}
          </div>
        </div>
        <h2 className="font-medium">Produtos/peças selecionados</h2>
      </div>
      <div>
        <div>
          <Table.Head>
            <div className="flex w-full">
              <Table.Cell items={itemsSelected} className="w-20">
                Código
              </Table.Cell>
              <Table.Cell items={itemsSelected} className="flex-1">
                Nome
              </Table.Cell>
              <Table.Cell items={itemsSelected} className="w-24">
                Quantidade
              </Table.Cell>
              <Table.Cell items={itemsSelected}>
                <DeleteIcon fontSize="small" onClick={onRemoveAllItemsClick} />
              </Table.Cell>
            </div>
          </Table.Head>
        </div>

        <Main className="px-0 pb-0">
          <Table.Root
            itemType="Produtos/peças"
            itemsLength={itemsSelected.length}
            className="max-h-[calc(100vh-16rem)] overflow-y-auto"
          >
            {itemsSelected.map((itemLabeled) => (
              <Table.Row key={itemLabeled.id}>
                <div className="flex w-full">
                  <Table.Cell items={itemsSelected} className="w-20">
                    {itemLabeled.id}
                  </Table.Cell>
                  <Table.Cell items={itemsSelected} className="flex-1">
                    {itemLabeled.name}
                  </Table.Cell>
                  <Table.Cell items={itemsSelected} className="w-24">
                    {itemLabeled.amount}
                  </Table.Cell>
                  <Table.Cell items={itemsSelected}>
                    <DeleteIcon
                      fontSize="small"
                      onClick={() => onRemoveItemSelectedClick(itemLabeled)}
                    />
                  </Table.Cell>
                </div>
              </Table.Row>
            ))}
          </Table.Root>
        </Main>

        <Alert
          content={errorMessage}
          isOpen={!!errorMessage}
          onClose={clearError}
        />
      </div>
    </section>
  );
}
