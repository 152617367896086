import { ItemInUse } from '../../types/inUse';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import ListingHeaderModal from '../ListingHeaderModal';
import Loading from '../Loading';
import Main from '../Main';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { useUserRoleContent } from './useUserRoleContent';
import CheckIcon from '@mui/icons-material/Check';

type UserRoleContentProps = {
  isModal?: boolean;
  itemsInList?: ItemInUse[];
  onTableRowClick: (roleId: number, roleName: string) => void;
  onAddUserTypesClick?: () => void;
};

export default function UserRoleContent({
  isModal,
  itemsInList,
  onTableRowClick,
  onAddUserTypesClick,
}: UserRoleContentProps) {
  const {
    inputRef,
    isFetching,
    isSearchInputDirty,
    sortField,
    userTypes,
    userTypesLength,
    userTypesShown,
    handleSearch,
    handleCancelSearch,
    handleSortTable,
  } = useUserRoleContent();

  return (
    <>
      {isModal && (
        <ListingHeaderModal
          title="Tipos de usuário"
          buttonContent="Cancelar"
          inputRef={inputRef}
          isSearchInputDirty={isSearchInputDirty}
          onCancelSearch={handleCancelSearch}
          onSearch={handleSearch}
          onButtonClick={handleCancelSearch}
        >
          <Table.Head>
            <Table.Cell
              items={userTypesShown}
              type="type"
              onSort={handleSortTable}
              isSortActive={sortField === 'type'}
            >
              Tipo
            </Table.Cell>
          </Table.Head>
        </ListingHeaderModal>
      )}

      {!isModal && (
        <ListingHeader
          add
          onAddIconClick={onAddUserTypesClick}
          pdfTop="3rem"
          pdf={false}
        >
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">Tipos de usuário</h1>
            <SearchInput
              ref={inputRef}
              onSearch={handleSearch}
              // onButtonClick={handleCancelSearch}
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
            />
          </div>
          <Table.Head>
            <Table.Cell
              items={userTypesShown}
              type="type"
              onSort={handleSortTable}
              isSortActive={sortField === 'type'}
            >
              Tipo de usuário
            </Table.Cell>
          </Table.Head>
        </ListingHeader>
      )}

      <Main isModal={isModal}>
        {isFetching && <Loading />}
        {!isFetching && userTypes && (
          <Table.Root
            itemType="tipos de usuários"
            itemsLength={userTypesLength}
            className={`${isModal ? 'max-h-[60vh]' : ''}`}
            paddingTop="0"
          >
            {userTypesShown.map((userType) => {
              const isInList = itemsInList?.some(
                (item) => item.id === userType.id,
              );

              return (
                <Table.Row
                  key={userType.id}
                  onClick={() =>
                    !(isModal && isInList) &&
                    onTableRowClick(userType.id, userType.type)
                  }
                  className={isInList ? 'cursor-default opacity-50' : ''}
                >
                  <Table.Cell items={userTypesShown} type="type">
                    <span>{userType.type}</span>
                  </Table.Cell>
                  {isModal && isInList && (
                    <CheckIcon className="text-primary" />
                  )}
                </Table.Row>
              );
            })}
          </Table.Root>
        )}
      </Main>

      <Footer
        current={userTypesShown.length}
        total={userTypesLength}
        itemType="Tipos de usuários"
        data-modal={isModal}
        className={
          isModal ? 'absolute mx-[1px] mb-[1px] mt-[-10vh] rounded-b-3xl' : ''
        }
      />
    </>
  );
}
