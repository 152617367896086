import {
  listAll,
  ListResult,
  ref,
  uploadBytes,
  deleteObject,
  uploadBytesResumable,
  UploadResult,
} from 'firebase/storage';
import { storage } from '../libs/firebase';
import { ImageTypes } from '../enums/imageTypes';
import { Image as ImageT, ImagesResult } from '../types/images';
import { getImageFromApi } from '../utils/getImageUrl';
import { validateImageExtension } from '../utils/imageValidationExtension';

export type ImageSizes = Record<'width' | 'height', number>;

export const getImageSizesFromFile = (file: File): Promise<ImageSizes> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const img = new Image();

    // Ler o conteúdo do arquivo como uma URL de dados base64
    reader.onload = (event) => {
      if (event.target && typeof event.target.result === 'string') {
        img.src = event.target.result; // Definir o src da imagem para o conteúdo lido
      }
    };

    // Carregar a imagem para obter suas dimensões
    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };

    // Tratar erros de carregamento
    img.onerror = () => {
      reject(new Error('Erro ao carregar a imagem'));
    };

    // Ler o arquivo como Data URL
    reader.readAsDataURL(file);
  });
};

export const getImageSizes = (
  imageNane: string,
  imageType: string,
): Promise<ImageSizes> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = getImageFromApi(imageNane, imageType);

    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };
  });
};

const processImagesPaginatedResponse = (
  response: ListResult[],
  types: ImageTypes[],
): ImagesResult => {
  const imagesNameList = response.map((res, resIndex) =>
    res.items.map((item) => {
      // const imageSize = getImageSizes(item, types[resIndex]);
      return { name: item.name, type: types[resIndex] };
    }),
  );

  return {
    items: imagesNameList.flat(),
    meta: {
      totalItems: imagesNameList.length,
    },
  };
};

const processSingleFolderImagesPaginatedResponse = async (
  response: ListResult,
  type: ImageTypes,
): Promise<ImagesResult> => {
  const sizes = await Promise.all(
    response.items.map((item) => getImageSizes(item.name, type)),
  );

  const imagesNameList: ImageT[] = response.items.map((item, index) => {
    return {
      name: item.name,
      type,
      width: sizes[index].width,
      height: sizes[index].height,
    };
  });

  return {
    items: imagesNameList,
    meta: {
      totalItems: imagesNameList.length,
    },
  };
};

export const uploadImage = (
  imageType: string,
  // imageType: ImageTypes,
  itemId: string,
  file: File,
) => {
  const storageRef = ref(storage, `${imageType}/${itemId}`);
  const response = uploadBytesResumable(storageRef, file);
  return response;
};

export const uploadImages = async (
  imageType: string,
  files: FileList,
  saveImages: () => void | Promise<void>,
  fileName?: string | null,
) => {
  try {
    const promises: Promise<UploadResult>[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const name = fileName ?? file.name;
      const nameWithExtension = validateImageExtension(name);
      const storageRef = ref(storage, `${imageType}/${nameWithExtension}`);
      const response = uploadBytes(storageRef, file);
      promises.push(response);
    }

    await Promise.all(promises);
    await saveImages();
  } catch (error) {
    // const promises: Promise<'Falha ao deletar imagem' | undefined>[] = [];

    // for (let i = 0; i < files.length; i++) {
    //   const file = files[i];
    //   const response = deleteImageStorage(file.name, imageType);
    //   promises.push(response);
    // }

    // await Promise.all(promises);

    throw error;
  }
};

export const getAllImages = async () => {
  const explodedViewRef = ref(storage, 'exploded-view');
  const productsRef = ref(storage, 'products');
  const partsRef = ref(storage, 'parts');
  const linesCarouselRef = ref(storage, 'lines/carousel');
  const linesMenuRef = ref(storage, 'lines/menu');
  const imgHighlightRef = ref(storage, 'highlight/imgHighlight');

  const response = await Promise.all([
    listAll(explodedViewRef),
    listAll(productsRef),
    listAll(partsRef),
    listAll(linesCarouselRef),
    listAll(linesMenuRef),
    listAll(imgHighlightRef),
  ]);

  const types = [
    ImageTypes.explodedView,
    ImageTypes.products,
    ImageTypes.parts,
    ImageTypes.linesCarousel,
    ImageTypes.linesMenu,
    ImageTypes.imgHighlight,
  ];

  const images = processImagesPaginatedResponse(response, types);

  return images;
};

export const getAllFolderImages = async (imageType: ImageTypes) => {
  const listRef = ref(storage, imageType);

  const response = await listAll(listRef);

  return processSingleFolderImagesPaginatedResponse(response, imageType);
};

export const deleteImageStorage = async (
  imageReference: string,
  imageType: string,
  // imageType: ImageTypes,
) => {
  const imageRef = ref(storage, `${imageType}/${imageReference}`);
  try {
    await deleteObject(imageRef);
  } catch (error) {
    return 'Falha ao deletar imagem';
  }
};
