import {
  PopUp,
  PopUpListInPageSort,
  PopUpSort,
  SavePopUpDto,
  UpdatePopUpDto,
} from '../types/popUp';
import { getCurrentDomain } from '../utils/domain';
import generatePaginatedUrl from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getAllPopUpListPaginated = async (
  page?: number,
  direction?: 'ASC' | 'DESC',
  limit?: number,
) => {
  const url = generatePaginatedUrl(`/pop-up/v1`, page, limit, direction);
  const response = await api.get<PopUpListInPageSort>(url);
  return response;
};

export const updatePopUp = async ({
  id,
  titleField,
  contentField,
  subtitleField,
  footerField,
}: UpdatePopUpDto) => {
  const body = {
    id,
    titleField,
    contentField,
    subtitleField,
    footerField,
  };
  const response = await api.put<PopUpSort>('/pop-up/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const savePopUp = async ({
  titleField,
  contentField,
  subtitleField,
  footerField,
}: SavePopUpDto) => {
  const body = {
    titleField,
    contentField,
    subtitleField,
    footerField,
  };

  const response = await api.post<PopUp>('/pop-up/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const deletePopUp = async (popUpId: number) => {
  const response = await api.delete<PopUp>(`/pop-up/v1/${popUpId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const getPopUpByDomainId = async (
  domainId: number = getCurrentDomain().id,
) => {
  const response = await api.get<PopUp>(`/pop-up/v1/${domainId}`);

  return response;
};
