import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useError } from '../../hooks/useError';
import { useMutation } from 'react-query';
import { mutationErrorHandling } from '../../utils/errorHandling';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Password, RecoverPasswordFormData } from '../../types/recoverPassword';
import { recoverPassword, verifyTokenDatabase } from '../../services/user';
import { recoverPasswordSchema } from '../../validations/schemas';
import { Token } from '../../types/token';

type AlertContent = {
  message: string | null;
  icon: ReactNode | null;
};

const alertContentInitialValue: AlertContent = {
  icon: null,
  message: null,
};

type UseContactProps = {
  successIcon: ReactNode;
  errorIcon: ReactNode;
};

export const useRecoverPassword = ({
  errorIcon,
  successIcon,
}: UseContactProps) => {
  const { token } = useParams();

  const navigate = useNavigate()

  const { errorMessage, clearError, setErrorMessage } = useError();

  const [alertContent, setAlertContent] = useState<AlertContent>(
    alertContentInitialValue,
  );


  const decodeToken = (token: string) => {
    try {
      const [, payload] = token.split('.'); // Pegamos a segunda parte do token (payload)
      const decoded = JSON.parse(atob(payload)); // Decodificamos de Base64 para JSON
      return decoded; // Retorna os dados do token
    } catch (error) {
      return null; // Retorna null se o token for inválido
    }
  };

  const { mutate:  VerifyToken} = useMutation({
    mutationFn: async ({ token }: Token) => {
      return (await verifyTokenDatabase({ token })).data;
    },

    onSuccess: () => {

    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Houve um erro, tente novamente',
        setErrorMessage,
      );
    },
  });

  useEffect(() => {
    if (!token) {
      navigate('/login'); // Redireciona se não houver token
      return;
    }

    const tokenData = decodeToken(token);

    if (!tokenData || !tokenData.exp) {
      navigate('/login'); // Redireciona se o token for inválido
      return;
    }

    const now = Math.floor(Date.now() / 1000); // Tempo atual em segundos
    if (tokenData.exp < now) {
      navigate('/login'); // Redireciona se o token estiver expirado
    }

    if(tokenData.exp > now){
      VerifyToken({token})
    }

  }, [token, navigate, VerifyToken]);



  const { mutate: RecoverPassword } = useMutation({
    mutationFn: async ({ password, token }: Password) => {
      return (await recoverPassword({ password, token })).data;
    },

    onSuccess: () => {
      setAlertContent({
        icon: successIcon,
        message: 'Senha alterada com sucesso',
      });
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Houve um erro ao alterar a senha, tente novamente',
        setErrorMessage,
      );
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
  } = useForm<RecoverPasswordFormData>({
    resolver: zodResolver(recoverPasswordSchema),
    defaultValues: {
      password: '',
      confirm_password: '',
    },
  });


  const onSubmit = async ({ password }: RecoverPasswordFormData) => {
    if (!token) {
      return;
    }
    RecoverPassword({ password, token });
  };

  const [showPassword, setSetshowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleIconEyeClick = () => {
    setSetshowPassword((prev) => !prev);
  };

  const handleIconEyeClickConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleRedirectButtonOkClick = () => {
    setAlertContent(alertContentInitialValue);
    clearError();
    navigate('/login');
  }

  return {
    dirtyFields,
    showConfirmPassword,
    showPassword,
    alertContent,
    errors,
    errorMessage,
    clearError,
    handleSubmit,
    handleIconEyeClick,
    onSubmit,
    register,
    handleIconEyeClickConfirmPassword,
    handleRedirectButtonOkClick,
  };
};
