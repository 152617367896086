import {
  domainSearchBaseEndpoint,
  emailTriggerSearchBaseEndpoint,
} from './endpoints';

// Legacy
export const linesCache = 'lines';
export const categoriesCache = 'category';
export const productsLineCategoryCache = 'productsLineCategory';
export const singleProductCache = 'singleProduct';
export const productsLineCache = 'productsLine';
export const productDescriptionCache = 'productDescriptions';
export const partsGroupCache = 'groupParts';
export const partsGroupsCoordinateCache = 'groupPartsCoordinate';
export const productsPartGroupCompatibleCache = 'productsPartGroupCompatible';
export const allPartsCache = 'allParts';
export const partsPaginatedCache = 'partsPaginated';
export const productPartsCache = 'productParts';
export const productsCache = 'products';
export const productsByLineCache = 'productsByLineCache';

// Current API
export const linesApiCache = 'linesApi';
export const categoriesApiCache = 'categoriesApi';
export const productsApiCache = 'prodcutsApi';
export const descriptionTypesApiCache = 'descriptionTypesApi';
export const descriptionApiCache = 'descriptionsApi';
export const productsPartsApiCache = 'productsPartsApi';
export const productsViculatedDescriptionApiCache =
  'productsViculatedDescriptionApi';
export const productDescriptionApiCache = 'productDescriptionApi';
export const partsGroupApiCache = 'partsGroupApi';
export const imagesApiCache = 'imagesApi';
export const productsByPartsGroupIdApiCache = 'productsByPartsGroupIdApi';
export const PartsGroupByProductIdApiCache = 'partsGroupByProductIdApi';
export const partsByProductIdPartsGroupIdApiCache =
  'partsByProductIdPartsGroupIdApi';
export const partsGroupItemsByProductIdApiCache =
  'partsGroupItemsByProductIdApi';
export const coordinatesByProductIdApiCache = 'coordinatesByProductIdApi';
export const searchCache = 'searchApi';
export const usersCache = 'usersApi';
export const userTypesCache = 'userTypesApi';
export const productBuffaloCache = 'productBuffaloCache';
export const domainCache = `${searchCache}${domainSearchBaseEndpoint}`;
export const documentTypesCache = 'documentTypesApi';
export const imageTypesCache = 'imageTypesApi';
export const linesImageCache = 'linesImageApi';
export const loadMoreCache = 'loadMoreApi';
export const documentsCache = 'documentsApi';
export const productDocumentsCache = 'productDocumentsApi';
export const vinculateDocumentInProductCache = 'vinculateDocumentInProductApi';
export const productBarCodeCache = 'productBarCodeApi';
export const productDocumentsBuffaloCache = 'productDocumentsBuffaloApi';
export const productLiveSearchBuffaloCache = 'productLiveSearchBuffaloApi';
export const liveSearchProductBuffaloCache = 'liveSearchProductBuffaloCache';
export const partsAndPartsGroupCache = 'partsAndPartsGroupCache';
// export const liveSearchDetailBuffaloCache = 'liveSearchDetailBuffaloCache';
// export const liveSearchPartsCatalogBuffaloCache =
//   'liveSearchPartsCatalogBuffaloCache';
export const contactsApiCache = 'contactsApi';
export const technicalAssistanceUfsApiCache = 'technicalAssistanceCitiesApi';
export const technicalAssistanceCitiesApiCache = 'technicalAssistanceCitiesApi';
export const technicalAssistancesApiCache = 'technicalAssistancesApi';
export const representativeUfApiCache = 'representativeUfApi';
export const representativeApiCache = 'representativeApi';
export const questionsCache = 'questionApi';
export const cronInfosCache = 'cronInfosCache';
export const cronLastSynchronizationsCache = 'cronLastSynchronizationsCache';
export const sizingCache = 'sizingCache';
export const newsTypesCache = 'newsTypesCache';
export const newsCache = 'newsCache';
export const imagesCache = 'imagesCache';
export const synchronizationTypeCache = 'synchronizationTypeCache';
export const emailSignatureCache = 'emailSignatureCache';
export const statesUfCache = 'statesUfCache';
export const citiesCache = 'citiesCache';
export const verifyTokenCache = 'verifyTokenCache';
export const addressByCepCache = 'addressByCepCache';
export const emailSignatureDomainByDomainIdCache =
  'emailSignatureDomainByDomainIdCache';
export const popUpCache = 'popUpCache';
export const imageExtensionCacheCache = 'imageExtensionCacheCache';
export const emailTriggerCache = `${searchCache}${emailTriggerSearchBaseEndpoint}`;
export const fieldsDomainsCoordinateCache = 'groupFieldsCoordinate';
export const fieldsDomainsCoordinateCacheCopy = 'groupFieldsCoordinateCopy';
export const fieldsByDomainIdApiCache = 'fieldsByDomainIdApiCache';
export const pagesByLoggedUserCache = 'pagesByLoggedUserCache';
export const pagesByUserTypeCache = 'pagesByUserTypeCache';
export const productsByDocumentIdCache = 'productsByDocumentIdCache';
export const profileCache = 'profileCache';
export const productDuimpCache = 'productDuimpCache';
export const errorsDuimpCache = 'errorsDuimpCache';
export const logsLaunchDuimpCache = 'logsLaunchDuimpCache';
export const linesWithCategoriesCache = 'linesWithCategoriesCache';
export const productsLineCategoryDomain = 'productsDomain';
export const labelNFCache = 'labelNFCache';
export const packingListCache = 'packingListCache';
export const carriersCache = 'carriersCache';
export const liveSearchPartsGroupItemBuffaloCache = 'liveSearchPartsGroupItemBuffaloCache';
export const subjectsCache = 'carriersCache';
