import { Product } from '../../types/products';
import { useMutation, useQuery } from 'react-query';
import { productDocumentsCache } from '../../constants/requestCacheName';
import { useMemo, useRef, useState } from 'react';
import { getAuth } from '../../utils/auth';
import { useModal } from '../../hooks/useModal';
import { InitialValue } from '../../components/Admin/ItemModal';
import { useTitle } from '../../hooks/useTitle';
import {
  getAllProductDocumentsByProductId,
  removeDocumentFromProduct,
  vinculateDocumentInProduct,
} from '../../services/productDocument';
import {
  FindAllProductDocuments,
  ProductDocument,
  VinculateDocumentInProductDto,
} from '../../types/productDocument';
import { useHandleTable } from '../../hooks/useHandleTable';
import { mutationErrorHandling } from '../../utils/errorHandling';
import { useError } from '../../hooks/useError';
import { useQueryCache } from '../../hooks/useQueryCache';
import { Document } from '../../types/document';
import { DocumentType } from '../../types/documentTypes';
import formatSearch from '../../utils/formatSearch';
import { useProductStore } from '../../store/product';
import { isAxiosError } from 'axios';
import { getTableColumnWidthByBiggestElement } from '../../utils/tableColumnStyles';
import { ProductClean } from '../../components/ProductsContent/useProductsContent';

export const useProducts = () => {
  useTitle('Produtos');

  const {
    state: { isUneditable },
  } = useProductStore();

  const currentProductId = useRef<number | null>(null);
  const currentDocument = useRef<Document | null>(null);
  const currentVinculationId = useRef<number | null>(null);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { addItemOnScreen, removeItemFromScreen } = useQueryCache();

  const [documentTypeColWidth, setDocumentTypeColWidth] = useState(0);

  const {
    data: productDocuments,
    isFetching: isFetchingProductDocuments,
    refetch: refetchProductDocuments,
  } = useQuery<FindAllProductDocuments>(
    productDocumentsCache,
    async () =>
      (
        await getAllProductDocumentsByProductId({
          productId: currentProductId.current!,
        })
      ).data,
    {
      onSuccess: (data) => {
        const width = getTableColumnWidthByBiggestElement<ProductDocument>(
          data.items,
          'document.documentType.name',
          20,
        );

        setDocumentTypeColWidth(width);
      },
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
    },
  );

  const { mutate: vinculateDocumentInProductMutate } = useMutation({
    mutationFn: async ({
      documentId,
    }: Pick<VinculateDocumentInProductDto, 'documentId'>) => {
      if (currentProductId.current) {
        return (
          await vinculateDocumentInProduct({
            productId: currentProductId.current,
            documentId,
          })
        ).data;
      }
    },

    onSuccess: (data) => {
      if (currentDocument.current && currentProductId.current) {
        addItemOnScreen<FindAllProductDocuments>(productDocumentsCache, {
          document: currentDocument.current,
          product: { id: currentProductId.current } as Product,
          id: data!.id,
        });
      }
      handleCloseModal();
    },

    onError: (error) => {
      if (
        isAxiosError(error) &&
        error.response?.status === 400 &&
        error.response?.data.message.includes('already has document with type')
      ) {
        mutationErrorHandling(
          '',
          `O produto "${productFieldsCurrentValue.name}" já possui um documento do tipo "${currentDocument.current?.documentType.name}"`,
          setErrorMessage,
        );
        return;
      }

      mutationErrorHandling(
        error,
        'Falha ao vincular ou desvincular produto à categoria',
        setErrorMessage,
      );
    },
  });

  const { mutate: removeDocumentFromProductMutate } = useMutation({
    mutationFn: async () => {
      if (currentVinculationId.current) {
        return await removeDocumentFromProduct(currentVinculationId.current);
      }
    },

    onSuccess: () => {
      if (currentVinculationId.current) {
        removeItemFromScreen<FindAllProductDocuments>(
          productDocumentsCache,
          currentVinculationId.current,
        );
      }
      handleCloseModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao vincular ou desvincular produto à categoria',
        setErrorMessage,
      );
    },
  });

  const {
    itemsShown,
    itemsLength: documentProductsLength,
    handleSortTable: handleSortProductDocumentsTable,
    sortField: sortFieldProductDocuments,
    inputRef: productDocumentsInputRef,
    handleCancelSearch: handleCancelProductDocumentsSearch,
    isSearchInputDirty: isProductDocumentsInputDirty,
    handleSearch: search,
  } = useHandleTable(
    productDocuments?.meta.totalItems ?? 0,
    productDocuments?.items ?? [],
  );
  const productDocumentsShown: ProductDocument[] = itemsShown;

  const [productFieldsCurrentValue, setProductFieldsCurrentValue] =
    useState<InitialValue>({
      code: '',
      name: '',
      isNew: false,
      outOfLine: false,
      reviewedApproved: false,
      description: '',
    });
  const [productClicked, setProductClicked] = useState<ProductClean | null>(
    null,
  );
  const [isProductDescriptionOpen, setIsProductDescriptionOpen] =
    useState(false);
  const {
    isModalOpen: isProductModalOpen,
    handleCloseModal: onCloseProductModal,
    handleOpenModal: handleOpenProductModal,
  } = useModal();

  const {
    isModalOpen: isDocumentModalOpen,
    handleCloseModal: handleCloseDocumentModal,
    handleOpenModal: handleOpenDocumentModal,
  } = useModal();

  const {
    isModalOpen: isConfirmRemoveVinculationModalOpen,
    handleCloseModal: handleCloseConfirmRemoveVinculationModal,
    handleOpenModal: handleOpenConfirmRemoveVinculationModal,
  } = useModal();

  const auth = useMemo(() => getAuth(), []);

  const documentsInUse = useMemo(
    () => productDocuments?.items.map(({ document }) => document.id),
    [productDocuments?.items],
  );

  const handleTableRowClick = (
    productId: number,
    productSelected: ProductClean | null,
  ) => {
    if (productSelected) {
      setProductClicked(productSelected);
    }
  };

  const handleCloseModal = () => {
    setProductClicked(null);
    currentProductId.current = null;
    currentDocument.current = null;
  };

  const handleOpenProductDescription = () => {
    setIsProductDescriptionOpen(true);
  };

  const handleCloseProductDescription = () => {
    setIsProductDescriptionOpen(false);
  };

  const handleTableProductEditClick = (
    productId: string,
    productName: string,
    isNew: boolean,
    outOfLine: boolean,
    reviewedApproved: boolean,
    description: string,
  ) => {
    currentProductId.current = +productId;
    refetchProductDocuments();
    handleOpenProductModal();
    setProductFieldsCurrentValue({
      code: productId,
      name: productName,
      isNew,
      outOfLine,
      reviewedApproved,
      description,
    });
  };

  const handleAddIconClick = () => {
    setProductFieldsCurrentValue({
      code: '',
      name: '',
      isNew: false,
      outOfLine: false,
      reviewedApproved: false,
      description: '',
    });
    handleOpenProductModal();
  };

  const handleCloseProductModal = () => {
    onCloseProductModal();
  };

  const handleVinculateButtonClick = () => {
    handleOpenDocumentModal();
  };

  const handleSelectDocumentVinculate = (
    documentId: number,
    documentName: string,
    documentType: string,
    documentUrl: string,
  ) => {
    currentDocument.current = {
      id: documentId,
      name: documentName,
      documentType: {
        name: documentType,
      } as DocumentType,
      url: documentUrl,
    };
    vinculateDocumentInProductMutate({ documentId });
    handleCloseDocumentModal();
  };

  const handleSearch = () => {
    search<ProductDocument>(
      (item, formatedSearch) =>
        item.document.id.toString().includes(formatedSearch) ||
        formatSearch(item.document.name).includes(formatedSearch),
    );
  };

  const handleProductDocumentsTableRowClick = (vinculationId: number) => {
    currentVinculationId.current = vinculationId;
    handleOpenConfirmRemoveVinculationModal();
  };

  const handleConfirmRemoveVinculation = () => {
    removeDocumentFromProductMutate();
    handleCloseConfirmRemoveVinculationModal();
  };

  return {
    productClicked,
    isProductDescriptionOpen,
    auth,
    isProductModalOpen,
    productFieldsCurrentValue,
    isFetchingProductDocuments,
    productDocumentsShown,
    documentProductsLength,
    sortFieldProductDocuments,
    productDocumentsInputRef,
    isProductDocumentsInputDirty,
    productDocuments,
    isDocumentModalOpen,
    errorMessage,
    documentsInUse,
    isConfirmRemoveVinculationModalOpen,
    isUneditable,
    currentProductId: currentProductId.current ?? -1,
    documentTypeColWidth,
    handleCloseConfirmRemoveVinculationModal,
    handleProductDocumentsTableRowClick,
    handleConfirmRemoveVinculation,
    clearError,
    handleSelectDocumentVinculate,
    handleVinculateButtonClick,
    handleCloseDocumentModal,
    handleSortProductDocumentsTable,
    handleCancelProductDocumentsSearch,
    handleSearch,
    handleTableRowClick,
    handleCloseModal,
    handleOpenProductDescription,
    handleCloseProductDescription,
    handleCloseProductModal,
    handleTableProductEditClick,
    handleAddIconClick,
  };
};
