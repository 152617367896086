import Footer from '../../components/Footer';
import Header from '../../components/Header';
import SettingIcon from '../../assets/images/icons/settings-01.png';
import Icon from '../../assets/images/icons/Icon.png';
import { Link } from 'react-router-dom';
import CorouselBuffalo from '../../components/CarouselBuffalo';
import { useDetails } from './useDetails';
import SearchBar from '../../components/SearchBar';
import { AutoComplete } from '../../components/AutoComplete';
import BreadCrumb from '../../components/BreadCrumb';
import { ClipLoader } from 'react-spinners';
import { AnimatedDropdown } from '../../../components/AnimatedDropdown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Card from '../../components/CardBuffalo';

export type Product = {
  order: number;
  id: number;
  name: string;
  price: number;
  percentIPI: number;
  percentST: Date | null;
  packagingQuantity: number | null;
  reviewedApproved: boolean;
  new: boolean;
  outOfLine: boolean;
  associatedProductId: number;
};

export type ProductDocument = {
  id: number;
  name: string;
  url: string;
  type: string;
};

export type ProductWithDocuments = {
  id: number;
  name: string;
  documents: ProductDocument[];
};

export default function Detalhes() {
  const {
    categoryId,
    mainImageFallback,
    mainImageSrc,
    productId,
    productDescriptionBuffaloList,
    productDocumentsBuffalo,
    productHTMLDescription,
    productName,
    inputRef,
    productsSearch,
    fileLoadingId,
    productDescriptionBuffalo,
    lineId,
    selectedIndex,
    loadFile,
    handleSearchInputChange,
    handleClearSearchAndSetFocus,
    handleClickOutAutoComplete,
    handleProductSearchClick,
  } = useDetails();

  const descriptionContent = productHTMLDescription ? (
    <div
      dangerouslySetInnerHTML={{
        __html: productHTMLDescription,
      }}
    />
  ) : null;

  return (
    <>
      <div className="sticky top-0 z-10">
        <Header onClick={handleClickOutAutoComplete} />
        <div className="flex flex-col h-32 md:h-20 justify-between bg-zinc-100/90 px-5 py-4 backdrop-blur-sm md:flex-row">
          <div className="mb-2 flex items-center md:mb-0">
            <Link
              to={{
                pathname: '/produtos',
                search: `line=${productDescriptionBuffalo?.product.line?.id}`,
              }}
              className="hover:text-yellow-500"
            >
              <AnimatedDropdown
                label={productDescriptionBuffalo?.product.line?.name ?? ''}
                items={[]}
                containerProps={{ className: 'px-0' }}
                itemsContainerProps={{ className: 'w-52' }}
                readOnly
              />
            </Link>

            <ChevronRightIcon fontSize="medium" className="text-zinc-600" />
            <Link
              to={{
                pathname: '/produtos',
                search: `line=${productDescriptionBuffalo?.product.line?.id}&category=${productDescriptionBuffalo?.product.category?.name}`,
              }}
              className="hover:text-yellow-500"
            >
              <AnimatedDropdown
                label={productDescriptionBuffalo?.product.category?.name ?? ''}
                items={[]}
                containerProps={{ className: 'px-0' }}
                itemsContainerProps={{ className: 'w-52' }}
                readOnly
              />
            </Link>
          </div>
          <div className="relative w-full md:w-[30rem]">
            <SearchBar
              ref={inputRef}
              onChange={handleSearchInputChange}
              onClick={handleSearchInputChange}
              onClear={handleClearSearchAndSetFocus}
            />
            {productsSearch && (
              <AutoComplete.Root
                items={productsSearch.items}
                onOutClick={handleClickOutAutoComplete}
                className="absolute w-full border-2 border-t-0"
              >
                {productsSearch.items.map((productLineCategory, index) => {
                  const isSelected = index === selectedIndex;

                  return (
                    <AutoComplete.Item
                      key={`${productLineCategory.product?.id}${productLineCategory.line?.id}${productLineCategory.category?.id}${productLineCategory.domain?.id}`}
                      onClick={() =>
                        handleProductSearchClick(productLineCategory)
                      }
                      className={`p-2 ${isSelected ? 'bg-zinc-300' : ''}`}
                      ref={(el) => {
                        if (isSelected && el) {
                          el.scrollIntoView({ block: 'nearest' });
                        }
                      }}
                    >
                      <div>
                        {productLineCategory.product?.id} -{' '}
                        {productLineCategory.product?.name}
                        <BreadCrumb
                          items={[
                            productLineCategory.line?.name,
                            productLineCategory.category?.name,
                          ]}
                        />
                      </div>
                    </AutoComplete.Item>
                  );
                })}
              </AutoComplete.Root>
            )}
          </div>
        </div>
      </div>
      <main className="adjust-content-bf">
        <div>
          <div className="mx-5 pb-10 pt-4">
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
              <div className="flex size-fit flex-col gap-4">
                <div className="pt-2">
                  <div className="text-2xl font-medium">{productName}</div>
                  <span className="text-lg text-zinc-500">{productId}</span>
                </div>

                <div className="my-24">
                  <Card.Image
                    src={mainImageSrc}
                    fallbackSrc={mainImageFallback}
                    alt={productName ?? 'Imagem do produto'}
                    fallbackAlt="Imagem não disponível"
                    className="!max-h-none !max-w-none md:h-[200px] md:w-[400px] 1.2xl:h-[300px] 1.2xl:w-[600px] 3xl:h-[400px] 3xl:w-[800px]"
                    fallbackStyle={{
                      height: '200px',
                      width: '260px',
                      alignSelf: 'center',
                    }}
                    containerStyle={{
                      height: '100%',
                      width: '100%',
                    }}
                  />
                </div>

                <div>{descriptionContent}</div>
                <div className={`flex flex-wrap gap-5 pt-6 md:grid-cols-2`}>
                  {productDocumentsBuffalo?.items.map((productDocument) => (
                    <button
                      onClick={() =>
                        loadFile(
                          productDocument.document.url,
                          productDocument?.document.name,
                          productDocument.document.id,
                        )
                      }
                      key={productDocument.id}
                      className={`flex h-full items-center justify-center rounded-xl border-2 border-zinc-300 bg-white p-6 transition hover:bg-black/85 hover:text-white`}
                    >
                      <div className="flex justify-center gap-3 text-center text-lg font-medium">
                        <div className="size-7">
                          {fileLoadingId === productDocument.document.id ? (
                            <ClipLoader
                              size={28}
                              className="mr-1"
                              color="#eab308"
                            />
                          ) : (
                            <img src={Icon} alt="Catálogo de Peças" />
                          )}
                        </div>
                        <span className="flex items-center">
                          {productDocument.document.documentType.name}
                        </span>
                      </div>
                    </button>
                  ))}

                  {Number(productId) > 50000 && (
                    <Link
                      to={`/catalogo/${productId}`}
                      className={`flex h-full items-center justify-center rounded-xl border-2 border-zinc-300 bg-white p-6 transition hover:bg-black/85 hover:text-white`}
                    >
                      <div className="flex items-center justify-center gap-3 text-center text-lg font-medium">
                        <div>
                          <img src={SettingIcon} alt="Catálogo de Peças" />
                        </div>
                        <span>Catálogo de Peças</span>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
              <div className=" mr-3 flex flex-col gap-4">
                <div className="text-2xl font-normal">
                  Ficha Técnica
                  <span className="text-4xl text-yellow-500">.</span>
                </div>
                <div className="rounded-xl border-2">
                  <table className="w-full table-auto">
                    <tbody>
                      {productDescriptionBuffaloList?.map(
                        ({ description, amount }, index) => (
                          <tr
                            key={description.id}
                            className="border-b last:border-b-0 hover:bg-yellow-500/50"
                          >
                            <td
                              className={`p-3 ${
                                index === 0
                                  ? 'rounded-tl-lg rounded-tr-none'
                                  : ''
                              } ${
                                index ===
                                productDescriptionBuffaloList.length - 1
                                  ? 'rounded-bl-lg'
                                  : ''
                              }`}
                            >
                              {description.name}
                            </td>
                            <td
                              className={`p-3 ${
                                index === 0
                                  ? 'rounded-tl-none rounded-tr-lg'
                                  : ''
                              } ${
                                index ===
                                productDescriptionBuffaloList.length - 1
                                  ? 'rounded-br-lg'
                                  : ''
                              }`}
                            >
                              {amount}
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="pl-2 pb-4 pt-6 text-2xl font-medium">
                Produtos Relacionados
                <span className="text-4xl text-yellow-500">.</span>
              </div>
              <CorouselBuffalo
                categoryId={+(categoryId ?? -1)}
                productId={+(productId ?? -1)}
                lineId={+(lineId ?? -1)}
              />
            </div>
          </div>
          <Footer />
        </div>
      </main>
    </>
  );
}
