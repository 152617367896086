import { useTheme } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { productsPartGroupCompatibleCache } from '../../constants/requestCacheName';
import { ProductPartsCompatibleFiltered } from '../../types/productsPartsCompatibleFiltered';
import { verifyScreenIsLessThan } from '../../utils/verifyScreenIsLessThan';
import { useHandleTable } from '../../hooks/useHandleTable';
import { pageTitleBase } from '../../constants/utils';
import generatePartsCompatibilitiesPdf from '../../pdf/partsCompatibilitiesPdf/generatePartsCompatibilitiesPdf';
import { ItemFullTablePdf, ItemInfosPage } from '../../types/item';
import {
  FindAllPartsGroupItem,
  FindAllPartsGroupItemPaginated,
  PartsGroupItem,
} from '../../types/partsGroupItem';
import {
  getAllPartsGroupItemsByPartId,
  getAllPartsGroupItemsByPartIdPaginated,
} from '../../services/partsGroupItem';
import { useError } from '../../hooks/useError';
import { getTableColumnWidthByBiggestElement } from '../../utils/tableColumnStyles';

export const usePartCompatibilities = () => {
  const { partId, partName } = useParams();
  const [partsGroupWidth, setPartsGroupWidth] = useState<number>();

  const theme = useTheme();

  const navigate = useNavigate();
  const isAllPartsCompatibilities = useRef(false);

  const {
    data: allProductsPartGroupCompatible,
    refetch: refetchAllProductsPartGroupCompatible,
  } = useQuery<FindAllPartsGroupItem>(
    productsPartGroupCompatibleCache,
    async () => (await getAllPartsGroupItemsByPartId(+partId!)).data,
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
    },
  );

  const {
    data: productsPartGroupCompatiblePaged,
    refetch: refetchProductsPartGroupCompatiblePaged,
  } = useQuery<FindAllPartsGroupItemPaginated>(
    productsPartGroupCompatibleCache,
    async () =>
      (await getAllPartsGroupItemsByPartIdPaginated(+partId!, currentPage))
        .data,
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,

      onSuccess: (data) => {
        const width = getTableColumnWidthByBiggestElement<PartsGroupItem>(
          data.items,
          'partsGroup.name',
          20,
        );

        setPartsGroupWidth(width);
      },
    },
  );

  const [isSearchInputDirty, setIsSearchInputDirty] = useState(false);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const originalProductsPartGroupCompatiblePaged = useMemo<
    ProductPartsCompatibleFiltered[]
  >(() => {
    if (
      !isAllPartsCompatibilities.current &&
      productsPartGroupCompatiblePaged &&
      productsPartGroupCompatiblePaged.items
    ) {
      const newPartsCompatibilities = [
        ...productsPartGroupCompatiblePaged.items,
      ];

      return newPartsCompatibilities.map((partGroupItem) => ({
        id: partGroupItem.product.id,
        name: partGroupItem.product.name?.trim().replace(/\s+/g, ' ') ?? '',
        partGroupName:
          partGroupItem.partsGroup.name?.trim().replace(/\s+/g, ' ') ?? '',
        isAvailable:
          !partGroupItem.product.outOfLine ||
          !!partGroupItem.product.reviewedApproved,
        partId: partGroupItem.part.id,
      }));
    }

    if (
      isAllPartsCompatibilities.current &&
      allProductsPartGroupCompatible &&
      allProductsPartGroupCompatible.items
    ) {
      return allProductsPartGroupCompatible.items.map((partGroupItem) => ({
        id: partGroupItem.product.id,
        name: partGroupItem.product.name?.trim().replace(/\s+/g, ' ') ?? '',
        partGroupName:
          partGroupItem.partsGroup.name?.trim().replace(/\s+/g, ' ') ?? '',
        isAvailable:
          !partGroupItem.product.outOfLine ||
          !!partGroupItem.product.reviewedApproved,
        partId: partGroupItem.part.id,
      }));
    }

    return [];
  }, [allProductsPartGroupCompatible, productsPartGroupCompatiblePaged]);

  const {
    currentPage,
    sortField,
    inputRef,
    pagination,
    itemsShown: partsCompatibilitiesShown,
    handleChangePage,
    handleChangePagination,
    handleSearch: search,
    handleSortTable,
    setItemsShown: setPartsShown,
  } = useHandleTable(
    Number(productsPartGroupCompatiblePaged?.meta.totalItems), // Na primeira posição vem a quantidade de produtos
    originalProductsPartGroupCompatiblePaged,
  );

  useEffect(() => {
    document.title = `Compatibilidades - ${pageTitleBase}`;
  }, []);

  useEffect(() => {
    refetchProductsPartGroupCompatiblePaged();
  }, [currentPage, refetchProductsPartGroupCompatiblePaged]);

  useEffect(() => {
    if (pagination === 100) {
      refetchProductsPartGroupCompatiblePaged();
      isAllPartsCompatibilities.current = false;
      return;
    }

    refetchAllProductsPartGroupCompatible();
    isAllPartsCompatibilities.current = true;
  }, [
    pagination,
    refetchAllProductsPartGroupCompatible,
    refetchProductsPartGroupCompatiblePaged,
  ]);

  useEffect(() => {
    setPartsShown(originalProductsPartGroupCompatiblePaged);
  }, [originalProductsPartGroupCompatiblePaged, setPartsShown]);

  const handleSearch = () => {
    search();
    if (inputRef.current?.value !== '') {
      setIsSearchInputDirty(true);
      return;
    }
    setIsSearchInputDirty(false);
  };

  const handleCancelSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setIsSearchInputDirty(false);
    }
    search();
  };

  const handleTableRowClick = (
    productId: string,
    partId: string,
    partGroupName: string,
    productName: string,
  ) => {
    navigate({
      pathname: `/parts-catalog/${productId}`,
      search: `partId=${partId}&partGroupName=${partGroupName}&product=${productName}`,
    });
  };

  const partNameDecoded = decodeURIComponent(partName ?? '');

  const isMobile = verifyScreenIsLessThan(760);

  const partsCompatibilitiesLength = Number(
    (pagination === 100
      ? productsPartGroupCompatiblePaged
      : allProductsPartGroupCompatible
    )?.meta.totalItems,
  );

  const partsCompatibilitiesShownLength = partsCompatibilitiesShown.length;

  const handleGeneratePdf = () => {
    const pageInfos: ItemInfosPage | number =
      pagination <= partsCompatibilitiesLength
        ? {
            itemsShownNumber: partsCompatibilitiesShownLength,
            pageNumber: currentPage + 1,
            pagination: pagination === 100 ? 'Paginado' : 'Todos',
            totalItemsNumber: partsCompatibilitiesLength,
          }
        : partsCompatibilitiesShownLength;

    const partsCompatibilitiesShownPdf: ItemFullTablePdf[] =
      partsCompatibilitiesShown.map(
        ({ id, name, isAvailable, partGroupName }) => ({
          id,
          name,
          isAvailable,
          lastColContent: partGroupName,
        }),
      );

    try {
      generatePartsCompatibilitiesPdf(
        partsCompatibilitiesShownPdf,
        partId,
        partName,
        pageInfos,
        'Conjunto de Peças',
        inputRef.current?.value,
        setErrorMessage,
      );
    } catch (error) {
      if (
        typeof error === 'string' &&
        error.toLowerCase().includes('permissões')
      ) {
        alert(
          'O navegador bloqueou o PDF. Desative a opção Bloquear Pop-ups e tente gerar novamente',
        );
      }
    }
  };

  return {
    theme,
    sortField,
    partId,
    isSearchInputDirty,
    partNameDecoded,
    inputRef,
    allProductsPartGroupCompatible,
    partsCompatibilitiesLength,
    partsCompatibilitiesShown,
    isMobile,
    currentPage,
    pagination,
    partsCompatibilitiesShownLength,
    errorMessage,
    partsGroupWidth,
    clearError,
    handleChangePage,
    handleChangePagination,
    handleCancelSearch,
    handleSearch,
    handleTableRowClick,
    handleSortTable,
    handleGeneratePdf,
  };
};
