import { SelectChangeEvent } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { verifyScreenIsLessThan } from '../../utils/verifyScreenIsLessThan';
import { useQueryClient } from 'react-query';
import { Lines } from '../../types/lines';
import { linesCache } from '../../constants/requestCacheName';
import { useSearchParams } from '../../hooks/useSearchParams';
import { getAuth } from '../../utils/auth';

export const useLinesNavbar = () => {
  const queryClient = useQueryClient();
  const lines = queryClient.getQueryData<Lines>(linesCache);

  const auth = useMemo(() => getAuth(), []);

  const [selectedLine, setSelectedLine] = useState('');

  const [currentLineId] = useSearchParams('lineId');
  const isMobile = verifyScreenIsLessThan(900);

  const handleSelectPartGroup = (event: SelectChangeEvent) => {
    setSelectedLine(event.target.value);
  };

  useEffect(() => {
    if (lines && lines.items.length > 0) {
      setSelectedLine(lines.items[0].name);
    }
  }, [lines]);

  const lineId = currentLineId ? +currentLineId : null;

  return {
    lines,
    isMobile,
    selectedLine,
    lineId,
    auth,
    handleSelectPartGroup,
  };
};
