import { Box, ListItem, Typography } from '@mui/material';
import { partsGroupContainerStyle, partsGroupItemStyle } from './styles';
import { usePartsGroup } from './usePartsGroup';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { api } from '../../../services/api';
import ToolTipCustom from '../ToolTip';

type PartsGroupProps = {
  canEdit: boolean;
  currentPartGroupId?: number;
  isMobile: boolean;
};

const getProductByProductId = async (productId: number) => {
  const url = `/product-description/v1/find-all-product-descriptions/product-id/${productId}`;
  const response = await api.get(url);
  return response.data.descriptions.items[0]?.product;
};

export default function PartsGroup({
  currentPartGroupId,
  isMobile,
}: PartsGroupProps) {
  const { productId } = useParams<{ productId: string }>();
  const { error } = useQuery(
    ['product', productId],
    () => getProductByProductId(Number(productId)),
    {
      enabled: !!productId,
    },
  );

  const {
    selectedPartGroup,
    partsGroup,
    handleClickPartsGroup,
    handleSelectPartGroup,
  } = usePartsGroup(currentPartGroupId);

  if (error) {
    console.error('Error fetching product data:', error);
    return <Typography>Error loading product data</Typography>;
  }

  return (
    <Box
      component="section"
      sx={{
        ...partsGroupContainerStyle,
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!isMobile && (
        <div className="mb-2 text-left font-medium text-black 2xl:text-2xl">
          Conjuntos de Peças
          <span className="text-2xl text-yellow-500 2xl:text-4xl">.</span>
        </div>
      )}

      {isMobile ? (
        <select
          value={selectedPartGroup}
          onChange={handleSelectPartGroup}
          className="w-full cursor-pointer rounded-lg border-2 p-2 font-bold uppercase tracking-widest text-black"
        >
          {partsGroup?.items.map((partGroup) => (
            <option
              value={partGroup.name}
              key={partGroup.id}
              className="w-full"
            >
              {partGroup.name}
            </option>
          ))}
        </select>
      ) : (
        <Box
          sx={{
            mb: 2,
            flex: 1,
            overflowY: 'auto',
            // marginRight: '2rem',
            maxHeight: 'calc(100vh - 17.5rem)',
            border: '2px solid #d1d5db',
            borderRadius: '0.7rem',
            marginBottom: 0,
            '@media (max-width: 1500px)': {
              marginRight: 0,
            },
          }}
        >
          <ul className="flex w-full flex-col max-xl:flex-1">
            {partsGroup?.items.map((partGroup, index) => (
              <ToolTipCustom title="Conjunto de peça do produto" placement="top">
                <li
                  key={partGroup.id}
                  // className={`flex items-center overflow-x-hidden border-none ${
                  //   index === 0 ? 'first-item' : ''
                  // } ${index === partsGroup.items.length - 1 ? 'last-item' : ''}`}
                  className="outline-none"
                >
                  <div className="flex flex-1">
                    <ListItem
                      onClick={() => handleClickPartsGroup(partGroup)}
                      sx={{
                        ...partsGroupItemStyle,
                        borderTop:
                          index === 0
                            ? 'none'
                            : (partsGroupItemStyle as { borderTop: string })
                                .borderTop,
                        backgroundColor:
                          partGroup.id === currentPartGroupId
                            ? '#eab308'
                            : 'inherit',
                        '&:hover': {
                          backgroundColor:
                            partGroup.id === currentPartGroupId
                              ? '#eab308'
                              : 'rgba(234, 179, 8, 0.5)',
                        },
                      }}
                      className={`ease flex-1 cursor-pointer ${
                        partGroup.id === currentPartGroupId
                          ? 'bg-yellow-500'
                          : ''
                      } ${index === 0 ? 'rounded-tl-lg' : ''} ${index === partsGroup.items.length - 1 ? 'rounded-bl-lg' : ''}`}
                    >
                      {partGroup.name}
                    </ListItem>
                  </div>
                </li>
              </ToolTipCustom>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
}
