import { useProducts } from './useProducts';
import Modal from '../../components/Modal';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import { getImageFromApi } from '../../utils/getImageUrl';
import ProductsContent from '../../components/ProductsContent';
import ItemModal from '../../components/Admin/ItemModal';
import { productSchema } from '../../validations/schemas';
import {
  deleteProduct,
  saveProduct,
  updateProduct,
} from '../../services/products';
import {
  searchCache,
  vinculateDocumentInProductCache,
} from '../../constants/requestCacheName';
import { productSearchBaseEndpoint } from '../../constants/endpoints';
import SearchInput from '../../components/SearchInput';
import Table from '../../components/Table';
import DocumentContent from '../../components/DocumentContent';
import Loading from '../../components/Loading';
import Alert from '../../components/Alert';
import ConfirmModal from '../../components/ConfirmModal';
import { getAllDocumentsPaginated } from '../../services/documents';

export default function Products() {
  const {
    productClicked,
    isProductDescriptionOpen,
    auth,
    isProductModalOpen,
    productFieldsCurrentValue,
    isFetchingProductDocuments,
    productDocumentsShown,
    documentProductsLength,
    documentsInUse,
    productDocuments,
    isProductDocumentsInputDirty,
    productDocumentsInputRef,
    sortFieldProductDocuments,
    isDocumentModalOpen,
    errorMessage,
    isConfirmRemoveVinculationModalOpen,
    isUneditable,
    documentTypeColWidth,
    handleCloseConfirmRemoveVinculationModal,
    handleConfirmRemoveVinculation,
    handleProductDocumentsTableRowClick,
    clearError,
    handleSelectDocumentVinculate,
    handleCloseDocumentModal,
    handleVinculateButtonClick,
    handleCancelProductDocumentsSearch,
    handleSearch,
    handleSortProductDocumentsTable,
    handleTableProductEditClick,
    handleCloseProductModal,
    handleTableRowClick,
    handleCloseModal,
    handleCloseProductDescription,
    handleOpenProductDescription,
    handleAddIconClick,
  } = useProducts();

  return (
    <>
      <ProductsContent
        onTableRowClick={handleTableRowClick}
        onEditButtonClick={handleTableProductEditClick}
        edit={!!auth}
        headerProps={{ add: !!auth, onAddIconClick: handleAddIconClick }}
      />

      <ItemModal
        width="80vw"
        height="90vh"
        editTitle="Produto"
        addTitle="Novo produto"
        isModalOpen={isProductModalOpen}
        onModalClose={handleCloseProductModal}
        type="product"
        queryKey={`${searchCache}${productSearchBaseEndpoint}`}
        validationSchema={productSchema}
        initialValue={productFieldsCurrentValue}
        saveService={saveProduct}
        updateService={updateProduct}
        deleteService={deleteProduct}
        productVinculations={{
          isFetching: isFetchingProductDocuments,
          onVinculateClick: handleVinculateButtonClick,
          vinculationContent: (
            <>
              {documentProductsLength > 0 ? documentProductsLength : 'Nenhum'}{' '}
              documento
              {documentProductsLength > 1
                ? 's estão vinculados'
                : ' está vinculado'}{' '}
              a esse produto
            </>
          ),
        }}
        productVinculationContent={
          <>
            <SearchInput
              ref={productDocumentsInputRef}
              onSearch={handleSearch}
              onButtonClick={handleCancelProductDocumentsSearch}
              showCancelOptions={isProductDocumentsInputDirty}
              onCancelSearch={handleCancelProductDocumentsSearch}
            />
            <Table.Head rowStyle="px-0 items-center">
              <div className="flex px-4">
                <Table.Cell
                  items={productDocumentsShown}
                  type="document.id"
                  onSort={handleSortProductDocumentsTable}
                  isSortActive={sortFieldProductDocuments === 'document.id'}
                  className="w-[5.5rem]"
                >
                  <span className="text-xs">Código</span>
                </Table.Cell>
                <Table.Cell
                  items={productDocumentsShown}
                  type="document.name"
                  onSort={handleSortProductDocumentsTable}
                  isSortActive={sortFieldProductDocuments === 'document.name'}
                >
                  <span className="text-xs">Nome</span>
                </Table.Cell>
              </div>
              <Table.Cell
                items={productDocumentsShown}
                type="document.documentType.name"
                onSort={handleSortProductDocumentsTable}
                style={{ width: `${documentTypeColWidth + 2.75}ch` }}
                isSortActive={
                  sortFieldProductDocuments === 'document.documentType.name'
                }
              >
                <span className="text-xs">Tipo</span>
              </Table.Cell>
            </Table.Head>

            <div>
              {isFetchingProductDocuments && <Loading className="pt-10" />}
              {!isFetchingProductDocuments && productDocuments && (
                <Table.Root
                  itemType="Documentos"
                  showNoContentMessage={false}
                  itemsLength={documentProductsLength}
                  noContentContainerClassName="h-fit"
                >
                  {productDocumentsShown.map(({ document, id }) => (
                    <Table.Row
                      className="px-0"
                      key={document.id}
                      onClick={() =>
                        !isUneditable && handleProductDocumentsTableRowClick(id)
                      }
                    >
                      <div className="flex px-4">
                        <Table.CellStatic
                          // items={productDocuments.items}
                          // type="document.id"
                          className="w-[5.5rem]"
                        >
                          {document?.id}
                        </Table.CellStatic>
                        <Table.CellStatic
                        // type="document.name"
                        // items={productDocuments.items}
                        // style={{ width: `${lineColWidth}ch` }}
                        >
                          {document.name}
                        </Table.CellStatic>
                      </div>
                      <Table.CellStatic
                        style={{ width: `${documentTypeColWidth}ch` }}
                      >
                        {document.documentType.name}
                      </Table.CellStatic>
                    </Table.Row>
                  ))}
                </Table.Root>
              )}
            </div>
          </>
        }
      />

      <Modal isOpen={!!productClicked} onClose={handleCloseModal}>
        {productClicked && (
          <Card.Root key={productClicked.id} sx={{ minHeight: '26rem' }}>
            <Card.Image
              src={getImageFromApi(productClicked.id, 'products')}
              alt={`Imagem do ${productClicked.name}`}
              fallbackSrc="/images/unavailable.png"
              fallbackAlt="Imagem de produto indisponível"
            />
            <Card.IsNew text={productClicked.new ? 'Novo' : ''} />
            <Card.Infos
              productId={productClicked.id}
              productName={productClicked.name}
            />
            <Card.Actions>
              <Card.Action
                text="Descrição"
                onClick={handleOpenProductDescription}
              />
              <Link
                to={{
                  pathname: `/parts-catalog/${productClicked.id}`,
                  search: `product=${productClicked.name}${productClicked.associatedProductId ? `&previus-version=${productClicked.associatedProductId}` : ''}`,
                }}
              >
                <Card.Action text="Catálogo de peças" />
              </Link>
            </Card.Actions>
            <Modal
              isOpen={isProductDescriptionOpen}
              onClose={handleCloseProductDescription}
            >
              <Card.Root>
                <h2 className="mb-8 text-center text-xl">Descrição</h2>
                <Card.Image
                  src={getImageFromApi(productClicked.id, 'products')}
                  alt={`Imagem do ${productClicked.name}`}
                  fallbackSrc="images/unavailable.png"
                  fallbackAlt="Imagem de produto indisponível"
                />
                <Card.IsNew text={productClicked.new ? 'Novo' : ''} />
                <Card.Infos
                  productId={productClicked.id}
                  productName={productClicked.name}
                />
                <Card.Table productId={productClicked.id} sx={{ mt: '2rem' }} />
              </Card.Root>
            </Modal>
          </Card.Root>
        )}
      </Modal>

      <Modal
        height="90vh"
        width="90vw"
        isOpen={isDocumentModalOpen}
        onClose={handleCloseDocumentModal}
      >
        <DocumentContent
          onTableRowClick={handleSelectDocumentVinculate}
          documentsInUse={documentsInUse}
          isModal
          queryKey={vinculateDocumentInProductCache}
          documentService={() => getAllDocumentsPaginated()}
        />
      </Modal>

      <ConfirmModal
        contentMessage={
          'O documento selecionado será desvinculado desse produto. Deseja prosseguir?'
        }
        isModalOpen={isConfirmRemoveVinculationModalOpen}
        onCloseModal={handleCloseConfirmRemoveVinculationModal}
        onNotConfirm={handleCloseConfirmRemoveVinculationModal}
        onConfirm={handleConfirmRemoveVinculation}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
