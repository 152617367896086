import ContactManageModal from '../../../components/Admin/ContactMenageModal';
import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import ContactUsContent from '../../../components/ContactUs';
import { useContact } from './useContact';

export default function ContactUsAdm() {
  const {
    isMenageModalOpen,
    currentContactId,
    isConfirmModalOpen,
    isShowAnswerButton,
    contactStatus,
    errors,
    dirtyFields,
    isUneditable,
    editor,
    errorMensageResposta,
    errorMessage,
    message,
    handleClearAnswer,
    handleConfirmArchive,
    handleCloseConfirmModal,
    handleEdit,
    handleButtonOkClick,
    register,
    handleSubmit,
    handleArchiveButtonClick,
    handleCloseMenageModal,
    handleTableRowClick,
    clearError,
  } = useContact();
  return (
    <>
      <ContactUsContent handleTableRowClick={handleTableRowClick} />

      <ContactManageModal
        message={message}
        currentContactId={currentContactId}
        dirtyFields={dirtyFields}
        errors={errors}
        handleClearAnswer={handleClearAnswer}
        handleCloseMenageModal={handleCloseMenageModal}
        handleArchiveButtonClick={handleArchiveButtonClick}
        handleEdit={handleEdit}
        isMenageModalOpen={isMenageModalOpen}
        onOkClick={handleSubmit(handleButtonOkClick)}
        register={register}
        isShowAnswerButton={isShowAnswerButton}
        contactStatus={contactStatus}
        isUneditable={isUneditable}
        editor={editor}
        errorMensageResposta={errorMensageResposta}
      />

      <ConfirmModal
        contentMessage="Deseja arquivar este chamado?"
        isModalOpen={isConfirmModalOpen}
        onCloseModal={handleCloseConfirmModal}
        onConfirm={handleConfirmArchive}
        onNotConfirm={handleCloseConfirmModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
