import { DotProps } from 'react-multi-carousel';

type CarouselDotBuffaloProps = DotProps & {};

export default function CarouselDotBuffalo({
  active,
  ...rest
}: CarouselDotBuffaloProps) {
  return (
    <button
      data-active={active}
      className="mx-1 size-3 rounded-full bg-zinc-200 data-[active=true]:bg-zinc-400"
      {...rest}
    />
  );
}
