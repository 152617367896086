import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { loginSchema } from '../../validations/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoginFormData } from '../../types/login';
import { login as authLogin } from '../../services/auth';
import { authenticate } from '../../utils/auth';
import { useMutation } from 'react-query';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { useError } from '../../hooks/useError';
import { mutationErrorHandling } from '../../utils/errorHandling';

export const useLogin = () => {
  const navigate = useNavigate();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: loginMutate } = useMutation({
    mutationFn: async ({ login, password }: LoginFormData) => {
      return (await authLogin(login, password)).data;
    },

    onSuccess: (data) => {
      if (data.user) {
        authenticate(data);
        navigate('/catalogo');
      }
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao realizar login',
        setErrorMessage,
        () => {
          if (isAxiosError(error) && error.response?.data.statusCode === 401) {
            setErrorMessage('Login ou senha inválidos');
            return true;
          }
        },
      );
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors, dirtyFields },
  } = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      login: '',
      password: '',
    },
  });

  const [showPassword, setSetshowPassword] = useState(false);

  const onSubmit = async ({ login, password }: LoginFormData) => {
    loginMutate({ login, password });
  };

  const handleIconEyeClick = () => {
    setSetshowPassword((prev) => !prev);
  };

  const sendMailerPasswordClick = () => {
    navigate('/send-mail')
  }

  return {
    errors,
    dirtyFields,
    showPassword,
    clearError,
    errorMessage,
    handleIconEyeClick,
    sendMailerPasswordClick,
    handleSubmit,
    onSubmit,
    register,
  };
};
