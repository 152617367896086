import { useMutation, useQuery } from 'react-query';
import { Categories, CategoryFormData } from '../../../types/categories';
import {
  categoriesApiCache,
  domainCache,
  productsApiCache,
  searchCache,
} from '../../../constants/requestCacheName';
import {
  deleteCategory,
  saveCategory,
  updateCategory,
} from '../../../services/categories';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { categorySchema } from '../../../validations/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  getProductSortByCategoryId,
  removeProductFromCategory,
  saveProductInCategory,
  verifyProductHasImageByProductId,
} from '../../../services/products';
import {
  DomainVinculation,
  ProductHasImage,
  FindAllProducts,
  ProductSort,
  ProductsSort,
} from '../../../types/products';
import { AxiosError, isAxiosError } from 'axios';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { useTitle } from '../../../hooks/useTitle';
import { DropResult } from '@hello-pangea/dnd';
import { reorderList } from '../../../utils/dragDrop';
import { reorderProductsByCategory } from '../../../services/productLineCategory';
import { ReorderProductDto } from '../../../types/productsLineCategory';
import { getDomainsPaginated } from '../../../services/domain';
import { FindAllDomainsPaginated } from '../../../types/domain';
import { updateProductDomainVinculation } from '../../../services/productLineCategoryDomain';
import { VinculateProductInDomain } from '../../../types/productLineCategoryDomain';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { useEdit } from '../../../hooks/useEdit';
import { getTableColumnWidthByBiggestElement } from '../../../utils/tableColumnStyles';
import { useModal } from '../../../hooks/useModal';
import { ProductsParts } from '../../../types/productsParts';
import { categorySearchBaseEndpoint } from '../../../constants/endpoints';

type CategoryUpdate = {
  categoryId: number;
  categoryName: string;
  lineName: string;
};

export const useCategories = () => {
  useTitle('Categorias');

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { data: domains } = useQuery<FindAllDomainsPaginated>(
    domainCache,
    async () => (await getDomainsPaginated()).data,
    {
      onSuccess: (data) => {
        const maxWidth = (domains?.items ?? []).reduce((maxWidth, row) => {
          const width = row.name?.toString().length || 0;
          return width > maxWidth ? width : maxWidth;
        }, 0);
        setDomainWidth((maxWidth > 32 ? maxWidth + 2 : 32) / 2);
      },
    },
  );

  const { mutate: updateProductDomainVinculationMutate } = useMutation({
    mutationFn: async () => {
      if (currentLineId.current && categoryIdUpdate.current) {
        return (
          await updateProductDomainVinculation(
            productsChaged.current,
            currentLineId.current,
            categoryIdUpdate.current,
          )
        ).data;
      }
    },

    onSuccess: () => {
      setShowEditContent(false);
      if (productsChaged.current) {
        productsChaged.current = [];
      }
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao vincular ou desvincular produto à categoria',
        setErrorMessage,
      );
    },
  });

  const [productsHasImage, setProductsWithoutImage] = useState<
    ProductHasImage[]
  >([]);

  const verifyProductWithoutImage = async (products: ProductSort[]) => {
    const productsHasImage: ProductHasImage[] = await Promise.all(
      products.map((product) => {
        const type = product.id < 50000 ? 'parts' : 'products';
        return verifyProductHasImageByProductId(product.id, type);
      }),
    );

    setProductsWithoutImage(productsHasImage);
  };

  const {
    data: categoryProducts,
    isFetching: isFetchingCategoryProducts,
    refetch: refetchCategoryProducts,
  } = useQuery<ProductsSort>(
    productsApiCache,
    async () =>
      (await getProductSortByCategoryId(categoryIdUpdate.current!)).data,
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,

      onSuccess: (data) => {
        const checkProducts: VinculateProductInDomain[][] = data?.items.map(
          (product) => {
            const checked: VinculateProductInDomain[] = product.domains?.map(
              (domain) => ({
                domainId: domain.id,
                productId: product.id,
                isCheck: true,
              }),
            );

            return checked;
          },
        );
        if (data?.items) {
          verifyProductWithoutImage(data.items);
        }

        if (checkProducts) {
          setCheckProductsDomains(checkProducts.flat());
        }
      },
    },
  );

  const {
    addItemOnScreen,
    updateItemOnScreen,
    removeItemFromScreen,
    clearCache,
  } = useQueryCache();

  const { mutate: save } = useMutation({
    mutationFn: async ({
      categoryName,
      lineName,
    }: Record<'categoryName' | 'lineName', string>) => {
      return (await saveCategory(categoryName, lineName)).data;
    },

    onSuccess: (data) => {
      addItemOnScreen<Categories>(
        `${searchCache}${categorySearchBaseEndpoint}`,
        data,
      );
      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar categoria',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Categoria já cadastrada');
            return true;
          }
        },
      );
    },
  });

  const { mutate: update } = useMutation({
    mutationFn: async ({
      categoryId,
      categoryName,
      lineName,
    }: CategoryUpdate) => {
      return (await updateCategory(categoryId, categoryName, lineName))?.data;
    },

    onSuccess: (data) => {
      updateItemOnScreen(`${searchCache}${categorySearchBaseEndpoint}`, data);
      setIsMenageModalOpen(true);
      setShowEditContent(false);
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar categoria',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Categoria já cadastrada');
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteCategoryMutate } = useMutation({
    mutationFn: async () => {
      if (categoryIdUpdate.current) {
        await deleteCategory(categoryIdUpdate.current);
        return;
      }

      setErrorMessage(
        'Falha ao deletar categoria. ID da categoria não foi encontrado',
      );
    },

    onSuccess: () => {
      if (categoryIdUpdate.current) {
        removeItemFromScreen<Categories>(
          `${searchCache}${categorySearchBaseEndpoint}`,
          categoryIdUpdate.current,
        );
      }

      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar categoria',
        setErrorMessage,
        () => {
          if (isAxiosError(error) && error.response?.status === 409) {
            setErrorMessage(
              'Esta categoria só pode ser apagada depois que não houver itens associados a ela',
            );
            return true;
          }
        },
      );
    },
  });

  const { mutate: reorderProductMutate } = useMutation({
    mutationFn: async (dto: ReorderProductDto[]) => {
      if (categoryIdUpdate.current) {
        await reorderProductsByCategory(dto);
        return;
      }

      setErrorMessage('Falha ao reordenar');
    },

    onError: (error) => {
      mutationErrorHandling(error, 'Falha ao reordenar', setErrorMessage);
    },
  });

  const { mutate: addProduct } = useMutation({
    mutationFn: async ({ productId }: Record<'productId', number>) => {
      if (categoryIdUpdate.current && currentLineId.current) {
        const response = await saveProductInCategory(
          productId,
          currentLineId.current,
          categoryIdUpdate.current,
        );
        return response.data;
      }

      setErrorMessage('Falha ao vincular produto');
    },

    onSuccess: (data) => {
      if (data) {
        const productsSort: ProductSort = {
          domains: [],
          ...data?.product,
          order: data.order!,
        };
        addItemOnScreen<ProductsSort>(productsApiCache, productsSort);
      }

      setIsConfirmRemoveProductModalOpen(false);
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao vincular produto',
        setErrorMessage,
      );
    },
  });

  const { mutate: removeProduct } = useMutation({
    mutationFn: async () => {
      if (
        productIdClicked.current &&
        currentLineId.current &&
        categoryIdUpdate.current
      ) {
        await removeProductFromCategory(
          productIdClicked.current,
          currentLineId.current,
          categoryIdUpdate.current,
        );
        return;
      }

      setErrorMessage('Falha ao desvincular produto');
    },

    onSuccess: () => {
      if (productIdClicked.current) {
        removeItemFromScreen<FindAllProducts>(
          productsApiCache,
          productIdClicked.current,
        );
      }

      setIsConfirmRemoveProductModalOpen(false);
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao desvincular produto',
        setErrorMessage,
      );
    },
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    reset,
    setError,
    watch,
  } = useForm<CategoryFormData>({
    resolver: zodResolver(categorySchema),
    defaultValues: { line: 'Nenhuma', category: '' },
  });

  const currentLine = watch('line');
  const category = watch('category');

  const {
    itemsShown: itemsCategoryProductsShown,
    handleSortTable: sortCategoryProductsTable,
    sortField: sortFieldCategoryProducts,
    inputRef: categoryProductsInputRef,
    handleReset,
    handleCancelSearch: handleCancelCategoryProductsSearch,
    isSearchInputDirty: isCategoryProductsInputDirty,
    handleSearch: handleCategoryProductsSearch,
    defineItemsShown,
  } = useHandleTable(
    categoryProducts?.meta.totalItems ?? 0,
    categoryProducts?.items ?? [],
  );
  const categoryProductsShown: ProductSort[] = itemsCategoryProductsShown;

  const nameColWidth = useMemo(
    () =>
      getTableColumnWidthByBiggestElement<ProductSort>(
        itemsCategoryProductsShown ?? [],
        'name',
        10,
      ) + 4,
    [itemsCategoryProductsShown],
  );

  const [isMenageModalOpen, setIsMenageModalOpen] = useState(false);
  const [showEditContent, setShowEditContent] = useState(false);
  const [isSelectProductModalOpen, setIsSelectProductModalOpen] =
    useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isConfirmRemoveProductModalOpen, setIsConfirmRemoveProductModalOpen] =
    useState(false);
  const [isLineModalOpen, setIsLineModalOpen] = useState(false);
  const [currentCategoryLineName, setCurrentCategoryLineName] = useState<
    string | null
  >(null);
  const [domainWidth, setDomainWidth] = useState<number>();
  const [checkProductsDomains, setCheckProductsDomains] = useState<
    VinculateProductInDomain[]
  >([]);

  const {
    isModalOpen: isConfirmUploadWithoutImageModalOpen,
    handleCloseModal: handleCloseConfirmUploadWithoutImageModal,
    handleOpenModal: handleOpenConfirmUploadWithoutImageModal,
  } = useModal();

  const currentLineId = useRef<number | null>(null);
  const categoryIdUpdate = useRef<number | null>(null);
  const currentCategoryName = useRef('');
  const productIdClicked = useRef<number | null>(null);
  const productsChaged = useRef<VinculateProductInDomain[]>([]);
  const categoryFormDataConfirm = useRef<CategoryFormData | null>(null);
  const reorderedTableUpdate = useRef<ProductSort[] | null>(null);

  const categoryFieldValue = watch('category');

  const {
    canEdit: canEditOrder,
    handleEdit: handleEditOrder,
    handleUnedit: handleUneditOrder,
  } = useEdit();

  const verifyDomainsPartialyChecked = (domainId: number) => {
    const checkedProductDomainsByDomainId = checkProductsDomains.filter(
      (productDomain) =>
        productDomain?.domainId === domainId && productDomain.isCheck,
    );

    return (
      checkedProductDomainsByDomainId.length > 0 &&
      checkedProductDomainsByDomainId.length <
        (categoryProducts?.items.length ?? 0)
    );
  };

  const verifyAllDomainsChecked = useCallback(
    (domainId: number) => {
      const checkedProductDomainsByDomainId = checkProductsDomains.filter(
        (productDomain) =>
          productDomain?.domainId === domainId && productDomain.isCheck,
      );

      if (
        checkedProductDomainsByDomainId.length ===
        (categoryProducts?.items.length ?? 0)
      ) {
        return true;
      }

      return false;
    },
    [categoryProducts?.items.length, checkProductsDomains],
  );

  const handleExecuteSaveUpdate = ({ category, line }: CategoryFormData) => {
    if (checkProductsDomains.length > 0) {
      updateProductDomainVinculationMutate();
    }

    if (categoryIdUpdate.current) {
      update({
        categoryId: categoryIdUpdate.current,
        categoryName: category,
        lineName: line,
      });

      if (
        categoryIdUpdate.current &&
        currentLineId.current &&
        reorderedTableUpdate.current
      ) {
        const reorderDto: ReorderProductDto[] =
          reorderedTableUpdate.current.map(({ id, order }) => ({
            categoryId: categoryIdUpdate.current!,
            order,
            productId: id,
            lineId: currentLineId.current!,
          }));

        reorderProductMutate(reorderDto);
      }

      return;
    }
    save({ categoryName: category, lineName: line });
  };

  const handleConfirmUploadProductsWithoutImage = () => {
    if (categoryFormDataConfirm.current) {
      handleExecuteSaveUpdate(categoryFormDataConfirm.current);
      handleCloseConfirmUploadWithoutImageModal();
    }
  };

  const handleButtonOkClick = async ({ category, line }: CategoryFormData) => {
    const isProductChangedWithoutImage = productsChaged.current.some(
      (product) =>
        product.isCheck &&
        productsHasImage.some(
          (productHasImage) =>
            product.productId === productHasImage.id &&
            !productHasImage.hasImage,
        ),
    );

    if (isProductChangedWithoutImage) {
      handleOpenConfirmUploadWithoutImageModal();
      categoryFormDataConfirm.current = { category, line };
      return;
    }

    handleExecuteSaveUpdate({ category, line });
  };

  const handleEditButtonClick = () => {
    setShowEditContent(true);
  };

  const handleDeleteButtonClick = () => {
    setIsConfirmModalOpen(true);
  };

  const handleAddCategoryClick = () => {
    clearCache(productsApiCache);
    setIsMenageModalOpen(true);
    setCurrentCategoryLineName(null);
  };

  const handleTableRowClick = (
    categoryId: number,
    categoryName: string,
    lineId: number,
    lineName: string,
  ) => {
    categoryIdUpdate.current = categoryId;
    currentLineId.current = lineId;
    currentCategoryName.current = categoryName;
    refetchCategoryProducts();
    setValue('category', categoryName);
    setValue('line', lineName);
    setIsMenageModalOpen(true);
    setCurrentCategoryLineName(lineName);
  };

  const handleCloseMenageModal = () => {
    categoryIdUpdate.current = null;
    categoryFormDataConfirm.current = null;
    reorderedTableUpdate.current = null;
    productsChaged.current = [];
    setCheckProductsDomains([]);
    reset();
    handleUneditOrder();
    setShowEditContent(false);
    setIsMenageModalOpen(false);
    setValue('line', 'Nenhuma');
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmDelete = () => {
    deleteCategoryMutate();
    setIsConfirmModalOpen(false);
  };

  const handleSelectLine = (lineId: number, lineName: string) => {
    setIsLineModalOpen(false);
    setValue('line', lineName);
    setError('line', {});
  };

  const handleOpenLines = () => {
    setIsLineModalOpen(true);
  };

  const handleCloseLineModal = () => {
    setIsLineModalOpen(false);
  };

  const handleRemoveProduct = (productId: number) => {
    productIdClicked.current = productId;
    setIsConfirmRemoveProductModalOpen(true);
  };

  const handleConfirmProductRemove = () => {
    removeProduct();
  };

  const handleCloseRemoveProductModal = () => {
    setIsConfirmRemoveProductModalOpen(false);
  };

  const handleLinkProductButtonClick = () => {
    setIsSelectProductModalOpen(true);
  };

  const handleCloseSelectProductModal = () => {
    setIsSelectProductModalOpen(false);
  };

  const handleLinkProductClick = (productPart: ProductsParts) => {
    addProduct({ productId: Number(productPart.id) });
    setIsSelectProductModalOpen(false);
  };

  const handleClearCategoryField = () => {
    setValue('category', '');
  };

  const handleSortCategoryProductsTable = (fieldNameSort: string) => {
    if (!canEditOrder) {
      sortCategoryProductsTable(fieldNameSort);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedTable = reorderList(
      categoryProductsShown,
      result.source.index,
      result.destination.index,
    );

    reorderedTable.forEach((categoryProducts, index) => {
      categoryProducts.order = index + 1;
    });

    reorderedTableUpdate.current = reorderedTable;

    defineItemsShown(reorderedTable);
  };

  const handleCheckDomain = (
    isCheck: boolean,
    productId: number,
    domainId: number,
    domains: DomainVinculation[],
  ) => {
    const filteredDomains = checkProductsDomains.filter(
      (domain) =>
        domain.domainId !== domainId || domain.productId !== productId,
    );
    const productsDomainsWithoutSelected = productsChaged.current.filter(
      (domain) =>
        domain.domainId !== domainId || domain.productId !== productId,
    );

    if (isCheck) {
      productsChaged.current = productsDomainsWithoutSelected;
      productsChaged.current.push({ domainId, productId, isCheck: true });
      filteredDomains.push({ domainId, productId, isCheck: true });
      setCheckProductsDomains(filteredDomains);
      return;
    }

    productsChaged.current = productsDomainsWithoutSelected;
    productsChaged.current.push({ domainId, productId, isCheck: false });
    filteredDomains.push({ domainId, productId, isCheck: false });
    setCheckProductsDomains(filteredDomains);
  };

  const handleToggleCheckDomains = (domainId: number, isCheck: boolean) => {
    const productDomainsChecked: VinculateProductInDomain[] = [];

    const productDomainWithotDomainIdSelected = checkProductsDomains.filter(
      (productDomain) => productDomain?.domainId !== domainId,
    );

    categoryProducts?.items.forEach((product) => {
      productDomainsChecked.push({ domainId, productId: product.id, isCheck });
    });

    productsChaged.current = [
      ...productDomainWithotDomainIdSelected,
      ...productDomainsChecked,
    ];

    setCheckProductsDomains([
      ...productDomainWithotDomainIdSelected,
      ...productDomainsChecked,
    ]);
  };

  const handleChangeEditOrderButtonClick = () => {
    if (canEditOrder) {
      handleUneditOrder();
      return;
    }

    handleReset();
    handleEditOrder();
  };

  const isShowEditButton = !!categoryIdUpdate.current && !showEditContent;

  const windowHeight = window.innerHeight;

  return {
    category,
    isConfirmRemoveProductModalOpen,
    isMenageModalOpen,
    showEditContent,
    categoryIdUpdate: categoryIdUpdate.current,
    isShowEditButton,
    errors,
    isConfirmModalOpen,
    categoryProductsShown,
    isFetchingCategoryProducts,
    categoryProducts,
    currentLine,
    currentCategoryLineName,
    sortFieldCategoryProducts,
    categoryProductsInputRef,
    isCategoryProductsInputDirty,
    isLineModalOpen,
    isSelectProductModalOpen,
    categoryFieldValue,
    categoryProductsLength: categoryProducts?.meta.totalItems ?? 0,
    domains,
    domainWidth,
    checkProductsDomains,
    errorMessage,
    windowHeight,
    canEditOrder,
    productsWithoutImage: productsHasImage,
    nameColWidth,
    isConfirmUploadWithoutImageModalOpen,
    handleConfirmUploadProductsWithoutImage,
    handleCloseConfirmUploadWithoutImageModal,
    verifyAllDomainsChecked,
    verifyDomainsPartialyChecked,
    handleToggleCheckDomains,
    handleChangeEditOrderButtonClick,
    clearError,
    handleCheckDomain,
    handleDragEnd,
    handleClearCategoryField,
    handleLinkProductClick,
    handleOpenLines,
    handleLinkProductButtonClick,
    handleCloseLineModal,
    handleCloseSelectProductModal,
    handleCloseRemoveProductModal,
    handleCategoryProductsSearch,
    handleConfirmProductRemove,
    handleCancelCategoryProductsSearch,
    handleSelectLine,
    handleRemoveProduct,
    register,
    handleAddCategoryClick,
    handleTableRowClick,
    handleCloseMenageModal,
    handleSubmit,
    handleButtonOkClick,
    handleEditButtonClick,
    handleDeleteButtonClick,
    handleCloseConfirmModal,
    handleConfirmDelete,
    handleSortCategoryProductsTable,
  };
};
