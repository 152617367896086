import { useRef } from 'react';
import { useModal } from '../../../hooks/useModal';
import { useEdit } from '../../../hooks/useEdit';
import {
  DocumentTypeFormData,
  DocumentTypePaginated,
  SaveDocumentTypeDto,
  UpdateDocumentTypeDto,
} from '../../../types/documentTypes';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { documentTypeSchema } from '../../../validations/schemas';
import { useMutation } from 'react-query';
import {
  deleteDocumentType,
  saveDocumentType,
  updateDocumentType,
} from '../../../services/documentTypes';
import { AxiosError, isAxiosError } from 'axios';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { documentTypesCache } from '../../../constants/requestCacheName';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';

export const useDocumentTypes = () => {
  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    reset,
    watch
  } = useForm<DocumentTypeFormData>({
    resolver: zodResolver(documentTypeSchema),
    defaultValues: {
      documentType: '',
    },
  });

  const documentType = watch('documentType')

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: saveDocumentTypeMutate } = useMutation({
    mutationFn: async ({ name }: SaveDocumentTypeDto) => {
      return (await saveDocumentType({ name })).data;
    },

    onSuccess: (data) => {
      addItemOnScreen<DocumentTypePaginated>(documentTypesCache, data);
      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar tipo de documento',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Tipo de documento já cadastrado');
            return true;
          }
        },
      );
    },
  });

  const { mutate: updateDocumentTypeMutate } = useMutation({
    mutationFn: async ({ id, name }: UpdateDocumentTypeDto) => {
      return (await updateDocumentType({ id, name })).data;
    },

    onSuccess: (data) => {
      updateItemOnScreen<DocumentTypePaginated>(documentTypesCache, data);
      // handleCloseMenageModal();
      handleUnedit()
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar tipo de documento',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Tipo de documento já cadastrado');
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteDocumentTypeMutate } = useMutation({
    mutationFn: async () => {
      if (documentTypesIdUpdate.current) {
        await deleteDocumentType(documentTypesIdUpdate.current);
        return;
      }

      setErrorMessage('Falha ao deletar tipo de documento');
    },

    onSuccess: () => {
      if (documentTypesIdUpdate.current) {
        removeItemFromScreen<DocumentTypePaginated>(
          documentTypesCache,
          documentTypesIdUpdate.current,
        );
      }

      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar tipo de documento',
        setErrorMessage,
        () => {
          if (isAxiosError(error) && error.response?.status === 409) {
            setErrorMessage(
              'Este tipo de documento só pode ser apagado depois que não houver itens associados a ele',
            );
            return true;
          }
        },
      );
    },
  });

  const documentTypesIdUpdate = useRef<number | null>(null);

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: closeMenageModal,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: handleCloseConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  const handleTableRowClick = (
    documentTypeId: number,
    documentTypeName: string,
  ) => {
    documentTypesIdUpdate.current = documentTypeId;
    setValue('documentType', documentTypeName);
    handleOpenMenageModal();
  };

  const handleAddDocumentTypeClick = () => {
    handleOpenMenageModal();
  };

  const handleCloseMenageModal = () => {
    documentTypesIdUpdate.current = null;
    reset();
    handleUnedit();
    closeMenageModal();
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmDeleteModal();
  };

  const handleButtonOkClick = ({ documentType }: DocumentTypeFormData) => {
    if (documentTypesIdUpdate.current) {
      updateDocumentTypeMutate({
        id: documentTypesIdUpdate.current,
        name: documentType,
      });
      return;
    }

    saveDocumentTypeMutate({ name: documentType });
  };

  const handleClearDocumentTypeField = () => {
    setValue('documentType', '', { shouldDirty: true });
  };

  const handleConfirmDelete = () => {
    deleteDocumentTypeMutate();
    handleCloseConfirmDeleteModal();
  };

  const isShowEditButton = !!documentTypesIdUpdate.current && !canEdit;

  return {
    documentTypesIdUpdate: documentTypesIdUpdate.current,
    isMenageModalOpen,
    isConfirmDeleteModalOpen,
    isShowEditButton,
    errors,
    dirtyFields,
    documentType,
    errorMessage,
    clearError,
    handleConfirmDelete,
    handleClearDocumentTypeField,
    register,
    handleSubmit,
    handleButtonOkClick,
    handleEdit,
    handleCloseConfirmDeleteModal,
    handleDeleteButtonClick,
    handleTableRowClick,
    handleAddDocumentTypeClick,
    handleCloseMenageModal,
  };
};
