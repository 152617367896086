import { useQuery } from 'react-query';
import {
  linesCache,
  newsTypesCache,
  popUpCache,
} from '../../../constants/requestCacheName';
import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { ResponsiveType } from 'react-multi-carousel';
import { Lines } from '../../../types/lines';
import { getAllImagesOfLines } from '../../../services/lines';
import { useModal } from '../../../hooks/useModal';
import { PopUp } from '../../../types/popUp';
import { getPopUpByDomainId } from '../../../services/pop-up';
import { useLocation, useNavigate } from 'react-router-dom';
import { NewsType } from '../../../types/newsType';
import { getAllNewsType } from '../../../services/news';
import { getCurrentDomain } from '../../../utils/domain';

export const useHome = () => {
  const [openPopUP, setOpenPopUP] = useState(false);

  useEffect(() => {
    if (openPopUP) {
      const isPopUpShown = sessionStorage.getItem('popUpShown');

      if (!isPopUpShown) {
        handleOpenMenageModal();
        sessionStorage.setItem('popUpShown', 'true');
      } 
    }
  }, [openPopUP]);

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: closeMenageModal,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const { data: linesShown } = useQuery<Lines>(
    linesCache,
    async () => (await getAllImagesOfLines()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: popUp } = useQuery<PopUp>(
    popUpCache,
    async () => (await getPopUpByDomainId()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          setOpenPopUP(true);
        }
      },
    },
  );

  const [isImageLoading, setIsImageLoading] = useState(true);

  const handleImageWasLoaded = () => {
    setIsImageLoading(false);
  };

  const handleLoadStart = () => {
    setIsImageLoading(true);
  };

  const [search, setSearch] = useState('');

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const responsive: ResponsiveType = {
    screen: {
      breakpoint: { min: 0, max: 5000 },
      items: 1,
    },
  };

  const location = useLocation();

  const navigate = useNavigate();
  const domain = getCurrentDomain();

  const { data: newsTypes } = useQuery<NewsType[]>(
    newsTypesCache,
    async () => (await getAllNewsType(domain.id)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleFilterByNewsType = (filterName: string) => {
    const currentParams = new URLSearchParams(location.search);

    const existingFilters = currentParams.getAll('newsType');
    if (!existingFilters.includes(filterName)) {
      currentParams.append('newsType', filterName);
    }

    navigate({
      pathname: '/buffaloNews',
      search: `?${currentParams.toString()}`,
    });
  };

  const handleEnterClickSearchInput = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        navigate({
          pathname: '/assistencia',
          search: `search=${search}`,
        });
      }
    },
    [navigate, search],
  );

  return {
    newsTypes,
    responsive,
    isImageLoading,
    linesShown: linesShown,
    search,
    isMenageModalOpen,
    popUp,
    handleFilterByNewsType,
    handleEnterClickSearchInput,
    closeMenageModal,
    handleOpenMenageModal,
    handleSearch,
    handleImageWasLoaded,
    handleLoadStart,
  };
};
