import { parseCookies, destroyCookie, setCookie } from 'nookies';
import { Login } from '../types/login';
import { tokenExpirationTimeInSeconds } from '../constants/auth';

export const authenticate = (login: Login) => {
  setCookie(null, 'login', JSON.stringify(login), {
    maxAge: tokenExpirationTimeInSeconds,
    path: '/',
  });
};

export const logout = () => {
  destroyCookie(null, 'login', {
    path: '/',
  });
};

export const getAuth = () => {
  const { login } = parseCookies();
  if (login) {
    const auth: Login = JSON.parse(login);
    return auth;
  }

  return null;
};
