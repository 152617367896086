import { Link } from 'react-router-dom';
import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import EmailSignatureContent from '../../../components/EmailSignatureContent';
import EmailSignatureItem from '../../../components/EmailSignatureItem';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import { phoneNumberMask } from '../../../utils/mask';
import { useEmailSignature } from './useEmailSignature';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function EmailSignature() {
  const {
    department,
    englishDepartament,
    email,
    name,
    phoneNumber,
    cellPhoneNumber,
    isManageModalOpen,
    emailSignatureIdUpdate,
    isShowEditButton,
    isConfirmDeleteModalOpen,
    dirtyFields,
    errors,
    errorMessage,
    currentEmailSignature,
    currentLinkCopied,
    openModal,
    success,
    openSuccess,
    handleCloseSuccessModal,
    handleConfirmSendImageMail,
    handleOpenModalSiganture,
    handleCloseConfirmModal,
    handleCopySignatureLink,
    clearError,
    setValue,
    combinedOnChange,
    handleClearField,
    register,
    handleEditButtonClick,
    handleButtonOkClick,
    handleConfirmDeleteSignature,
    handleCloseConfirmDeleteModal,
    handleDeleteButtonClick,
    handleAddEmailSignatureClick,
    handleTableRowClick,
    handleCloseManageModal,
  } = useEmailSignature();

  return (
    <>
      <EmailSignatureContent
        onTableRowClick={handleTableRowClick}
        onAddEmailSignatureClick={handleAddEmailSignatureClick}
      />

      <Modal
        isOpen={isManageModalOpen}
        onClose={handleCloseManageModal}
        cancelIcon={false}
        contentStyle="pr-1"
        width="43.5rem"
      >
        <div className="max-h-[85vh] overflow-y-auto pr-4">
          <HeaderModalManageItem
            showDeleteButton={!!emailSignatureIdUpdate}
            deleteButtonContent="Apagar assinatura"
            isEditable={!isShowEditButton}
            onDeleteButtonClick={handleDeleteButtonClick}
            title={emailSignatureIdUpdate ? 'Assinatura' : 'Nova assinatura'}
            mainButtonContent={
              emailSignatureIdUpdate && isShowEditButton ? 'Editar' : 'Ok'
            }
            onCancelClick={handleCloseManageModal}
            onOkClick={!isShowEditButton ? handleButtonOkClick : undefined}
            onEditClick={isShowEditButton ? handleEditButtonClick : undefined}
          />
          <div className="my-12 flex flex-col gap-4">
            <Input
              maxLength={150}
              type="text"
              placeholder="Nome"
              value={name}
              disabled={isShowEditButton}
              isDirty={!isShowEditButton && dirtyFields.name}
              onCancelIconClick={() => handleClearField('name')}
              {...register('name')}
              helperText={errors.name?.message}
            />
            <Input
              maxLength={250}
              type="text"
              placeholder="Departamento(potuguês)"
              disabled={isShowEditButton}
              isDirty={!isShowEditButton && dirtyFields.department}
              onCancelIconClick={() => handleClearField('department')}
              {...register('department')}
              helperText={errors.department?.message}
              value={department}
            />
            <Input
              maxLength={250}
              type="text"
              placeholder="Departamento(inglês)"
              disabled={isShowEditButton}
              isDirty={!isShowEditButton && dirtyFields.englishDepartament}
              onCancelIconClick={() => handleClearField('englishDepartament')}
              {...register('englishDepartament')}
              helperText={errors.englishDepartament?.message}
              value={englishDepartament ?? ''}
            />
            <Input
              type="text"
              maxLength={'none'}
              placeholder="Telefone"
              disabled={isShowEditButton}
              isDirty={!isShowEditButton && dirtyFields.phoneNumber}
              value={phoneNumber}
              required={true}
              onCancelIconClick={() => handleClearField('phoneNumber')}
              {...register('phoneNumber', {
                onChange: (e) => {
                  setValue(
                    'phoneNumber',
                    phoneNumberMask(e.target.value ?? '', 4),
                    {
                      shouldValidate: true,
                    },
                  );
                },
              })}
              helperText={errors.phoneNumber?.message}
            />

            <Input
              maxLength={19}
              type="text"
              placeholder="Celular"
              value={cellPhoneNumber}
              disabled={isShowEditButton}
              isDirty={!isShowEditButton && dirtyFields.cellPhoneNumber}
              onCancelIconClick={() => handleClearField('cellPhoneNumber')}
              {...register('cellPhoneNumber', {
                onChange: (e) => {
                  setValue(
                    'cellPhoneNumber',
                    phoneNumberMask(e.target.value ?? ''),
                    {
                      shouldValidate: true,
                    },
                  );
                },
              })}
              helperText={errors.cellPhoneNumber?.message}
            />
            <Input
              maxLength={250}
              type="email"
              placeholder="Email"
              value={email ? `${email.split('@')[0]}@buffalo.com.br` : '@buffalo.com.br'}
              disabled={isShowEditButton}
              isDirty={!isShowEditButton && dirtyFields.email}
              onCancelIconClick={() => handleClearField('email')}
              {...register('email')}
              helperText={errors.email?.message}
            />
          </div>

          {(currentEmailSignature?.signatureDomains.length ?? 0) > 0 && (
            <div>
              <h2 className="mb-4 text-lg">Pré-visualização das assinaturas</h2>
              {currentEmailSignature?.signatureDomains.map(({ domain, id }) => (
                <div key={id} className="mb-12">
                  <h3>{domain.name}</h3>
                  <EmailSignatureItem
                    domain={domain}
                    signature={currentEmailSignature}
                    id={domain.id.toString()}
                  />
                  <div className="mt-2 flex justify-between">
                    <Link
                      to={`${domain.id}/${currentEmailSignature.id}`}
                      className="text-sm text-primary"
                    >
                      Ir para a assinatura
                    </Link>
                    <button
                      className="text-sm text-primary"
                      disabled={
                        currentLinkCopied ===
                        `${domain.id}/${currentEmailSignature.id}`
                      }
                      onClick={() =>
                        handleCopySignatureLink(
                          `${domain.id}/${currentEmailSignature.id}`,
                        )
                      }
                    >
                      {currentLinkCopied ===
                      `${domain.id}/${currentEmailSignature.id}`
                        ? 'Link copiado'
                        : 'Copiar link'}
                    </button>
                    <button
                      className="text-sm text-primary"
                      onClick={() =>
                        handleOpenModalSiganture(domain.id.toString())
                      }
                    >
                      Enviar imagem no email
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal>

      <ConfirmModal
        contentMessage={`Deseja enviar a imagem de assinatura para o email ${currentEmailSignature?.email}?`}
        isModalOpen={openModal}
        onCloseModal={handleCloseConfirmModal}
        onConfirm={handleConfirmSendImageMail}
        onNotConfirm={handleCloseConfirmModal}
      />

      <ConfirmModal
        contentMessage="Deseja excluir essa assinatura?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDeleteSignature}
        onNotConfirm={handleCloseConfirmDeleteModal}
      />

      <Alert
        icon={<CheckCircleIcon />}
        content={success}
        isOpen={openSuccess}
        onClose={handleCloseSuccessModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
