import { imageExtensionSearchBaseEndpoint } from '../../constants/endpoints';
import { useHandleTable } from '../../hooks/useHandleTable';
import { ImageExtension } from '../../types/imageExtension';

export const useImageExtensionContent = () => {
  const {
    sortField,
    isSearchFetching: isFetching,
    inputRef,
    itemsLength: imageExtensionLength,
    itemsShown,
    itemsShownLength: imageExtensionsShownLength,
    searchItems,
    isSearchInputDirty,
    isSearchFetching,
    resetLoadMoreCurrentPage,
    handleCancelSearch,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch,
    handleSortTable,
  } = useHandleTable(0, [], null, null, imageExtensionSearchBaseEndpoint);
  const imageExtensionsShown = itemsShown as ImageExtension[];

  return {
    inputRef,
    isSearchInputDirty,
    imageExtensionsShown,
    sortField,
    isFetching: isSearchFetching,
    imageExtensionLength,
    imageExtensionsShownLength,
    handleSortTable,
    handleSearch,
    handleCancelSearch,
  };
};
