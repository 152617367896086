import axios, { isAxiosError } from 'axios';

export const api = axios.create({
  // baseURL: 'http://localhost:3332',
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'https://b7df-177-155-93-202.ngrok-free.app/api',
  withCredentials: true,
});

export const setupAxiosInterceptor = (showAlert: (message: string) => void) => {
  api.interceptors.response.use(null, (error) => {
    if (isAxiosError(error)) {
      if (error.code === 'ERR_NETWORK' && error.message === 'Network Error') {
        const isUnavailable = sessionStorage.getItem('unavailable system');
        if (isUnavailable !== 'true') {
          showAlert('Sistema temporariamente indisponível');
        }
        sessionStorage.setItem('unavailable system', 'true');
      }
      throw error;
    }
    throw error;
  });
};

//   // Interceptor para lidar com erros nas respostas
//   api.interceptors.response.use(null, (error) => {
//     if (isAxiosError(error)) {
//       if (error.code === 'ERR_NETWORK' && error.message === 'Network Error') {
//         const isUnavailable = sessionStorage.getItem('unavailable system');
//         if (isUnavailable !== 'true') {
//           showAlert('Sistema temporariamente indisponível');
//         }
//         sessionStorage.setItem('unavailable system', 'true');
//       }
//       throw error;
//     }
//     throw error;
//   });
// };

// api.interceptors.request.use(
//   (config) => {
//     const auth = getAuth();
//     if (auth) {
//       config.headers.Authorization = `Bearer ${auth.token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   },
// );

// Para utilizar com ngrok
// export const setupAxiosInterceptor = (showAlert: (message: string) => void) => {
//   api.interceptors.request.use((config) => {
//     config.headers['ngrok-skip-browser-warning'] = 'true';
//     return config;
//   });

//   api.interceptors.response.use(null, (error) => {
//     if (isAxiosError(error)) {
//       if (error.code === 'ERR_NETWORK' && error.message === 'Network Error') {
//         const isUnavailable = sessionStorage.getItem('unavailable system');
//         if (isUnavailable !== 'true') {
//           showAlert('Sistema temporariamente indisponível');
//         }
//         sessionStorage.setItem('unavailable system', 'true');
//       }
//       throw error;
//     }
//     throw error;
//   });
// };

export const cepApi = axios.create({
  baseURL: process.env.REACT_APP_CEP_API,
});
