import { useTheme } from '@mui/material';
import { FindAllParts, Part } from '../../types/parts';

import { useCallback, useMemo, useRef, useState } from 'react';
import { useHandleTable } from '../../hooks/useHandleTable';
import { useSearchParams } from '../../hooks/useSearchParams';
import { verifyScreenIsLessThan } from '../../utils/verifyScreenIsLessThan';
import generateProductPartsPdf from '../../pdf/productPartsPdf/generateProductPartsPdf';
import { useTitle } from '../../hooks/useTitle';
import { useError } from '../../hooks/useError';
import { useNavigate } from 'react-router-dom';
import { usePartsCatalogStore } from '../../store/partsCatalog';
import {
  PartsGroupItem,
} from '../../types/partsGroupItem';
import { searchPartsAndGroup } from '../../buffalos/services/partGroupItem';
import {
  PartsGroupItems,
} from '../../buffalos/types/partGroupItems';
import { partGroupItemSeatchBaseEndpoint } from '../../constants/endpoints';
import { search as searchDatabase, Search } from '../../services/search';
import { getAllPartsByProductId } from '../../services/partsGroupItem';
import { partsPaginatedCache, productsLineCategoryCache } from '../../constants/requestCacheName';
import { useQuery } from 'react-query';
import { getProductSortByCategoryId } from '../../services/products';
import { ProductsSort } from '../../types/products';

type PartClean = {
  id: number;
  name: string;
  isAvailable: boolean;
};

export const usePartsProduct = () => {
  useTitle('Peças');

  const theme = useTheme();
  const [productId, productName, partsCatalog] = useSearchParams(
    'productId',
    'productName',
    'partsCatalog',
  );
  const isAllParts = useRef(false);
  const navigate = useNavigate();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const { data: products, refetch: refetchProducts } = useQuery<ProductsSort>(
    productsLineCategoryCache,
    async () => (await getProductSortByCategoryId(+productId!)).data,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
    },
  );

  // const {
  //   data: productPartsPaginated,
  //   isFetching: isFetchingProductPartsPaginated,
  //   refetch: refetchProductPartsPaged,
  // } = useQuery<PartsPaginated>(
  //   partsPaginatedCache,
  //   async () =>
  //     (await getAllPartsByProductIdPaginated(+productId!, currentPage)).data,
  //   {
  //     retry: false,
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //   },
  // );

  const [isSearchInputDirty, setIsSearchInputDirty] = useState(false);
  const [partClicked, setPartClicked] = useState<Part | null>(null);
  const [isPartDescriptionOpen, setIsPartDescriptionOpen] = useState(false);

  // const isFetching = isFetchingProductParts || isFetchingProductPartsPaginated;

  // const {
  //   data: partsAndGroup,
  //   isFetching: isFetchingPartsAndGroup,
  //   refetch: refetchPartsAndGroup,
  // } = useQuery<GroupItemsPaginated>(
  //   partsAndPartsGroupCache,
  //   async () => {
  //     return (await searchPartsAndGroup(+productId!, currentPage)).data;
  //   },
  //   {
  //     retry: false,
  //     // enabled: true,
  //     refetchOnWindowFocus: false,
  //   },
  // );

  const loadMore = useCallback(
    async (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      return await searchPartsAndGroup(+productId!, page, limit);
    },
    [productId],
  );

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return searchDatabase<PartsGroupItems>(
        baseUrl,
        searchValue,
        page,
        null,
        null,
        [
          {
            name: 'productId',
            value: productId,
          },
        ],
      );
    },
    [productId],
  );

  const {
    pagination,
    inputRef,
    sortField,
    handleSearch: search,
    itemsShown: partsShown,
    itemsShownLength,
    endPageRef,
    isLoadMoreFetching,
    currentPage,
    itemsLength,
    handleChangePage,
    handleChangePagination,
    handleSortTable,
  } = useHandleTable(
    0,
    [],
    null,
    null,
    partGroupItemSeatchBaseEndpoint,
    loadMore,
    null,
    doSearch as Search,
  );

  const originalParts = useMemo<PartClean[]>(() => {
    if (!isAllParts.current && productId && partsShown) {
      const newProductParts = [...partsShown];
      const partsIds = new Set();

      return newProductParts
        .map((part) => {
          return {
            id: part.id,
            name: part.name.trim().replace(/\s+/g, ' '),
            isAvailable: !part.partOutOfLine && part.partReviewedApproved,
          };
        })
        .filter((part) => {
          const isReturnPart = !partsIds.has(part.id);
          partsIds.add(part.id);
          return isReturnPart;
        });
    }

    if (isAllParts.current && productId && partsShown) {
      const partsIds = new Set();

      return partsShown
        .map((part) => {
          return {
            id: part.id,
            name: part.name.trim().replace(/\s+/g, ' '),
            isAvailable: !part.partOutOfLine && part.partReviewedApproved,
          };
        })
        .filter((part) => {
          const isReturnPart = !partsIds.has(part.id);
          partsIds.add(part.id);
          return isReturnPart;
        });
    }

    return [];
  }, [productId, partsShown]);

  const getPartsLength = (() => {
    if (productId && isAllParts.current) return Number(originalParts?.length);

    if (productId && !isAllParts.current) return Number(itemsLength);
    return 0;
  })();

  const handleGeneratePdf = async () => {
    setIsPdfLoading(true);
    try {
      await generateProductPartsPdf(
        partsShown,
        productId!,
        productName ?? '',
        pagination <= getPartsLength
          ? {
              itemsShownNumber: itemsShownLength,
              pageNumber: currentPage + 1,
              pagination: pagination === 100 ? 'Paginado' : 'Todas',
              totalItemsNumber: itemsShownLength,
            }
          : itemsShownLength,
        inputRef.current?.value,
        setErrorMessage,
      );
    } catch (error) {
      if (
        typeof error === 'string' &&
        error
          .toLowerCase()
          .includes('open pdf in new window blocked by browser')
      ) {
        setErrorMessage(
          'O navegador bloqueou o PDF. Desative a opção Bloquear Pop-ups e tente gerar novamente',
        );
        return;
      }

      setErrorMessage('Ocorreu um erro ao gerar o PDF');
    } finally {
      setIsPdfLoading(false);
    }
  };

  // useEffect(() => {
  //   setPartsShown(originalParts);
  // }, [originalParts, setPartsShown]);

  // useEffect(() => {
  //   if (productId && pagination === 100) {
  //     refetchProductPartsPaged();
  //     isAllParts.current = false;
  //     return;
  //   }

  //   if (productId) {
  //     refetchProductParts();
  //     isAllParts.current = true;
  //     return;
  //   }
  //   isAllParts.current = true;
  // }, [pagination, productId, refetchProductParts, refetchProductPartsPaged]);

  const handleTableRowClick = (partId: number, partItems: PartsGroupItem) => {
  console.log("🚀 ~ handleTableRowClick ~ partItems:", partItems)

    const { part, partsGroup } = partItems;

    if (productId) {
      const part = partsShown?.find((part) => part.id === partId);
      if (part) {
        setPartClicked(part);
      }

    }
    setShouldShowFirstCurrentPartsGroup(true);
    setCurrentProductId(+productId!);
    setCurrentPartClicked(partItems);
    setCurrentPartIdHover(part.id);
    setCurrentPartsGroup({
      id: partsGroup?.id,
      name: partsGroup?.name || '',
      image: partsGroup?.image || '',
      totalItems: 0,
      description: {
        id: 0,
        name: '',
      },
    });
  };

  const [hasMultipleGroups, setHasMultipleGroups] = useState<boolean>(true);

  const handleCloseModal = () => {
    setPartClicked(null);
    setHasMultipleGroups(true);
  };

  const handleCancelSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setIsSearchInputDirty(false);
    }
    search();
  };

  const handleSearch = () => {
    search();
    if (inputRef.current?.value !== '') {
      setIsSearchInputDirty(true);
      return;
    }
    setIsSearchInputDirty(false);
  };

  const handleOpenDescription = () => {
    setIsPartDescriptionOpen(true);
  };

  const handleCloseDescription = () => {
    setIsPartDescriptionOpen(false);
  };

  const partsShownLength = partsShown.length;

  const isMobile = verifyScreenIsLessThan(760);

  const productNameDecoded = decodeURIComponent(productName ?? '');

  const backButtonPartsCatalog = () => {
    navigate(-1);
  };

  const {
    actions: {
      setShouldShowFirstCurrentPartsGroup,
      setCurrentProductId,
      setCurrentPartClicked,
      setCurrentPartIdHover,
      setCurrentPartsGroup,
    },
  } = usePartsCatalogStore();

  const handleClickViewPartsCatalog = () => {

    const product = products?.items.find(
      (product) => product.id === +productId!,
    );

    navigate(
      {
        pathname: `/parts-catalog/${productId}`,
        search: `?product=${encodeURIComponent(productName!)}&${product?.associatedProductId ? `previus-version=${product.associatedProductId}` : ''}`,
      },
      { replace: true }, // substitui a URL atual no histórico
    );
  };

  return {
    itemsShownLength,
    endPageRef,
    isLoadMoreFetching,
    theme,
    sortField,
    partsShown,
    partClicked,
    isMobile,
    inputRef,
    itemsLength,
    // partsLength: getPartsLength,
    partsShownLength,
    currentPage,
    pagination,
    // isFetching,
    isSearchInputDirty,
    isPartDescriptionOpen,
    productId,
    productNameDecoded,
    errorMessage,
    partsCatalog,
    hasMultipleGroups,
    setHasMultipleGroups,
    handleClickViewPartsCatalog,
    clearError,
    handleSearch,
    handleSortTable,
    handleCloseModal,
    handleTableRowClick,
    handleCancelSearch,
    handleChangePage,
    handleChangePagination,
    handleOpenDescription,
    handleCloseDescription,
    handleGeneratePdf,
    backButtonPartsCatalog,
    // isFetchingProductParts,
  };
};
