export default function ProductPartsDescriptionIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
      <image
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAABGQAAARkB8gmNbAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAIXSURBVDiN1ZQ9aFRBEMd/817OPKMiZ0S0MiApxFJEEETFr0IsVCKiCIHL2z0IsUkZwUb86MRCb99xTdIodpHLFWplo0E7tRHBdJLCA9HgcWTH5h5cLu/uItj4h4Hd2ZnfzizswD+WtG+MMTlVvRIEweYN5q+o6tMkSZqpI2iD7QRqQRDsVdV6hx1s2Ro/MKKq1cnJyeE1QGvtKLAI/AKWRSTfbsAQMJThXxaR381m860xZh/AQAt8AngBvFTVrNbmAbqczQGnROQk8CUFIiLvnXPPNvh2a2StzafrgV6BcRwfEpE5EfnZqnCLiFx3zr3rltMTGIaheO8jVc2lrn7V9gSWSqVFYKQfpF1B/5C/038GNMacMcY8ouNLphobG9sUx/E1gGKxuMsYcy4T6L1XVf0E3Ady1trLWcB8Pn9aRB7EcXzAe2+BO4VCYcc6YBiGC8BhEXkC3FPV6ampqcFOYJIkVRFZKpfLH733C8CbSqXyfR1QRFaAq6urqzVgBphtNBo3OoHW2otA1VprwjA8AixNTEzsz2r5lojczeVyDYB6vf4YuGCM2dMe7L3/oaofWtPmG/A5zUklxWJxRFVnnXPHxsfHB6Mo2l0qlb5aa8+r6llVfRgEwfGsN02lqkdF5LVzLglU9TZwE9Aoioa995cAnHPPgVFV3ZYxHzvn4nwYhjUAsda+ArZ3uXkrMJ0kSbVXhe36A76wEBuSoAtfAAAAAElFTkSuQmCC"
        height="100%"
        width="100%"
        style={{ filter: 'invert(1) brightness(2)' }}
      />
    </svg>
  );
}
