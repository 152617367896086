import { ComponentProps } from 'react';

type CardInfosProps = ComponentProps<'div'> & {
  productId?: string | number;
  productName: string;
};

export default function CardInfos({
  productId,
  productName,
  ...rest
}: CardInfosProps) {
  return (
    <div {...rest}>
      <h3 className="text-xl">{productName}</h3>
      <span className="text-secondaryText">{productId}</span>
    </div>
  );
}
