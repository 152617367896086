import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

export const headerStyles: SxProps<Theme> = {
  display: 'flex',
  padding: '2rem',
  justifyContent: 'center',
  alignItems: 'center',
};

export const backButtonStyle: SxProps<Theme> = {
  position: 'absolute',
  left: '2rem',

  '@media (max-width: 500px)': {
    left: '0.25rem',
  },
};

export const productStyle: SxProps<Theme> = {
  display: 'flex',
  gap: '1rem',
  justifyContent: 'center',
  minHeight: '4.5rem',
};

export const productImageContainerStyle: SxProps<Theme> = {};

export const cardImageStyle: CSSProperties = {
  maxHeight: '4rem',
  maxWidth: '5rem',
};

export const productInfosStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
};

export const itemNameStyle: SxProps<Theme> = {
  fontWeight: 'normal',

  '@media (max-width: 550px)': {
    fontSize: '0.9rem',
  },

  '@media (max-width: 350px)': {
    fontSize: '0.8rem',
  },
};

export const pdfText: SxProps<Theme> = {
  position: 'absolute',
  right: '2rem',
  cursor: 'pointer',

  '@media (max-width: 500px)': {
    right: '0.75rem',
  },
};
