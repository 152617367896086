import { twMerge } from 'tailwind-merge';
import { ProductsParts } from '../../types/productsParts';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import Loading from '../Loading';
import LoadMore from '../LoadMore';
import Main from '../Main';
import SerchInput from '../SearchInput';
import Table from '../Table';
import { useProductsPartsContent } from './useProductsPartsContent';
import { ItemInUse } from '../../types/inUse';
import CheckIcon from '@mui/icons-material/Check';

type ProductsPartsContentProps = {
  isModal?: boolean;
  title?: string;
  footerClassName?: string;
  itemsInUse?: ItemInUse[];
  handleTableRowClick: (productPart: ProductsParts) => void;
};

export default function ProductsPartsContent({
  isModal,
  title,
  itemsInUse,
  footerClassName,
  handleTableRowClick,
}: ProductsPartsContentProps) {
  const {
    inputRef,
    isFetchingProductsParts,
    isSearchInputDirty,
    endPageRef,
    isLoadMoreFetching,
    productsPartsLength,
    productsPartsShown,
    sortField,
    isSearch,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
  } = useProductsPartsContent();

  return (
    <>
      {!isModal && (
        <ListingHeader pdfTop="3rem" pdf={false}>
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">
              {title ?? 'Descrição de peças e produtos'}
            </h1>
            <SerchInput
              ref={inputRef}
              onSearch={handleSearch}
              showCancelOptions={isSearchInputDirty}
              buttonContent="Buscar"
              onCancelSearch={handleCancelSearch}
              onButtonClick={handleSearchDatabase}
              onKeyDown={handleEnterClickSearchInput}
            />
          </div>
          <Table.Head>
            <div className="flex">
              <Table.Cell
                items={productsPartsShown}
                type="id"
                onSort={handleSortTable}
                isSortActive={sortField === 'id'}
                className="w-[5.5rem]"
              >
                Código
              </Table.Cell>
              <Table.Cell
                items={productsPartsShown}
                type="name"
                onSort={handleSortTable}
                isSortActive={sortField === 'name'}
              >
                Nome
              </Table.Cell>
            </div>
          </Table.Head>
        </ListingHeader>
      )}

      {isModal && (
        <div>
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">{title ?? 'Descrições'}</h1>
            <SerchInput
              ref={inputRef}
              onSearch={handleSearch}
              showCancelOptions={isSearchInputDirty}
              buttonContent="Buscar"
              onCancelSearch={handleCancelSearch}
              onButtonClick={handleSearchDatabase}
              onKeyDown={handleEnterClickSearchInput}
            />
          </div>
          <Table.Head rowStyle="px-0">
            <div className="flex">
              <Table.Cell
                items={productsPartsShown}
                type="id"
                onSort={handleSortTable}
                isSortActive={sortField === 'id'}
                className="ml-4 w-[5.5rem]"
              >
                Código
              </Table.Cell>
              <Table.Cell
                items={productsPartsShown}
                type="name"
                onSort={handleSortTable}
                isSortActive={sortField === 'name'}
                className="mr-[4.5rem]"
              >
                Nome
              </Table.Cell>
            </div>
          </Table.Head>
        </div>
      )}

      <Main
        isModal={isModal}
        data-modal={isModal}
        className="data-[modal=true]:min-h-[70vh]"
      >
        {isFetchingProductsParts && <Loading loadMore={!isModal} />}
        {!isFetchingProductsParts && productsPartsShown && (
          <Table.Root
            itemType="Descrições"
            itemsLength={productsPartsLength}
            className={`${isModal ? 'max-h-[65vh]' : ''}`}
            paddingTop="0"
            showNoContentMessage={productsPartsShown.length <= 0 && isSearch}
            showSearchMessage={productsPartsShown.length <= 0 && !isSearch}
          >
            {productsPartsShown.map((productPart) => {
              const isInList = itemsInUse?.some(
                (item) => item.id.toString() === productPart.id.toString(),
              );

              return (
                <Table.Row
                  key={productPart.id}
                  onClick={() => !isInList && handleTableRowClick(productPart)}
                  className={
                    isInList ? 'cursor-default opacity-50' : 'opacity-100'
                  }
                >
                  <div className="flex">
                    <Table.CellStatic className="w-[5.5rem]">
                      {productPart.id}
                    </Table.CellStatic>
                    <Table.CellStatic>{productPart.name}</Table.CellStatic>
                  </div>
                  {isModal && isInList && (
                    <CheckIcon className="text-primary" />
                  )}
                </Table.Row>
              );
            })}
            <LoadMore
              isModal={!!isModal}
              isContentFetching={isFetchingProductsParts}
              isLoadMoreFetching={isLoadMoreFetching}
              ref={endPageRef}
            />
          </Table.Root>
        )}
      </Main>
      <Footer
        current={productsPartsShown.length}
        total={productsPartsLength}
        itemType="Peças ou produtos"
        className={twMerge(
          isModal ? 'mx-auto h-28 w-[50rem] rounded-b-2xl' : '',
          footerClassName,
        )}
        infosStyle={{ mt: isModal ? '-2rem' : '' }}
        // tableFooter={
        //   pagination <= productsPartsLength && (
        //     <TableFooter
        //       currentPage={currentPage}
        //       itemsLength={productsPartsLength}
        //       itemsShownLength={productsPartsShownLength}
        //       pagination={pagination}
        //       nonPaginationName="Todas"
        //       onChangePage={handleChangePage}
        //       onChangePagination={handleChangePagination}
        //     />
        //   )
        // }
      />
    </>
  );
}
