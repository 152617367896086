import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';

export const footerContainerStyle: SxProps<Theme> = {
  '@media (max-width: 1450px)': {
    height: '6rem',
  },

  '@media (max-width: 760px)': {
    paddingRight: 0,
  },
};

export const textStyle: SxProps<Theme> = {
  textAlign: 'center',
  fontSize: '1.25rem',
};
