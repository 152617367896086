import { api } from '../../services/api';

const ENDPOINT_URL = '/get-product-documents/v1';

export const fetchProductDocuments = async (productId: number) => {
  try {
    const params: any = {
      productId,
    };

    const response = await api.get(ENDPOINT_URL, { params });

    const documents = response.data.product[0]?.documents || [];

    return {
      list: documents,
    };
  } catch (error) {
    console.error('Erro ao buscar dados da API', error);
    throw error;
  }
};
