import { undefined, ZodType } from 'zod';
import HeaderModalManageItem from '../../HeaderModalManageItem';
import Input from '../../Input';
import Modal from '../../Modal';
import { AxiosResponse } from 'axios';
import { useItemModal } from './useItemModal';
import ConfirmModal from '../../ConfirmModal';
import Alert from '../../Alert';
import Editor from '../../Editor';
import EditorMenu from '../../Editor/EditorMenu';
import { ReactNode } from 'react';
import VinculationHeader from '../../VinculationHeader';
import Loading from '../../Loading';

type VinculationProps = {
  isFetching: boolean;
  onVinculateClick: () => void;
  vinculationContent: ReactNode;
};

export type InitialValue = {
  code: string;
  name: string;
  isNew: boolean;
  reviewedApproved: boolean;
  outOfLine: boolean;
  description?: string;
};

export type ServiceResponse = Promise<AxiosResponse>;

type ItemModalProps = {
  isModalOpen: boolean;
  width?: string;
  height?: string;
  editTitle: string;
  addTitle: string;
  validationSchema: ZodType;
  type: 'product' | 'part';
  queryKey: string;
  initialValue?: InitialValue;
  productVinculations?: VinculationProps;
  productVinculationContent?: ReactNode;
  saveService: (
    id: number,
    name: string,
    isNew: boolean,
    reviewedApproved: boolean,
    outOfLine: boolean,
    description?: string,
  ) => ServiceResponse;
  updateService: (
    id: number,
    name: string,
    isNew: boolean,
    reviewedApproved: boolean,
    outOfLine: boolean,
    description?: string,
  ) => ServiceResponse;
  deleteService: (id: number) => ServiceResponse;
  onModalClose: () => void;
};

export default function ItemModal({
  isModalOpen,
  editTitle,
  addTitle,
  validationSchema,
  type,
  queryKey,
  initialValue,
  width,
  height,
  productVinculations,
  productVinculationContent,
  saveService,
  updateService,
  deleteService,
  onModalClose,
}: ItemModalProps) {
  const {
    errors,
    isUneditable,
    isConfirmModalOpen,
    dirtyFields,
    editor,
    errorMessage,
    code,
    name,
    clearError,
    handleClearCodeInput,
    handleClearNameInput,
    handleCloseConfirmModal,
    handleSubmit,
    handleEditButtonClick,
    register,
    handleButtonOkClick,
    handleCancelButtonClick,
    handleDeleteButtonClick,
    handleConfirmDeleteItem,
  } = useItemModal(
    validationSchema,
    type,
    queryKey,
    onModalClose,
    saveService,
    updateService,
    deleteService,
    initialValue,
  );

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={onModalClose}
        cancelIcon={false}
        width={width}
        height={height}
        contentStyle="pr-0"
      >
        <div className="max-h-full overflow-auto pr-4">
          <HeaderModalManageItem
            deleteButtonContent={`Apagar ${type === 'product' ? 'produto' : 'peça'}`}
            isEditable={!isUneditable}
            onDeleteButtonClick={handleDeleteButtonClick}
            title={initialValue?.code ? editTitle : addTitle}
            showDeleteButton={!!initialValue?.code}
            mainButtonContent={isUneditable ? 'Editar' : 'Ok'}
            onCancelClick={handleCancelButtonClick}
            onOkClick={
              !isUneditable ? handleSubmit(handleButtonOkClick) : undefined
            }
            onEditClick={isUneditable ? handleEditButtonClick : undefined}
          />

          <div className="mt-8">
            <Input
              type="number"
              label="Código"
              value={code}
              helperText={errors.code?.message as string}
              disabled={!!initialValue?.code}
              isDirty={dirtyFields.code}
              min="1"
              onCancelIconClick={handleClearCodeInput}
              {...register('code')}
            />
            <Input
              type="text"
              label={type === 'product' ? 'Produto' : 'Peça'}
              value={name}
              classNameContainer="mt-4"
              helperText={errors.name?.message as string}
              disabled={isUneditable}
              isDirty={dirtyFields.name}
              onCancelIconClick={handleClearNameInput}
              {...register('name')}
            />
            {type === 'product' && (
              <div className="mt-4 flex flex-col gap-2">
                <div>
                  <label>Descrição</label>
                  <div className="mt-4">
                    <EditorMenu editor={editor} disabled={isUneditable} />
                    <Editor editor={editor} />
                  </div>
                </div>

                <div className="mb-8">
                  {productVinculations && !!initialValue?.code && (
                    <>
                      <VinculationHeader
                        isVinculationDisabled={isUneditable}
                        vinculationButtonContent="Vincular documento"
                        isFething={productVinculations.isFetching}
                        onVinculateClick={productVinculations.onVinculateClick}
                        vinculationContent={
                          productVinculations.vinculationContent
                        }
                      />
                      {productVinculations.isFetching && (
                        <Loading heightNormal />
                      )}

                      {!productVinculations.isFetching &&
                        productVinculationContent}
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="my-4 flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="reviewed-approved"
                  className="size-4"
                  disabled={isUneditable}
                  {...register('reviewedApproved')}
                />
                <label htmlFor="reviewed-approved">Revisado e aprovado</label>
              </div>

              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="new"
                  className="size-4"
                  disabled={isUneditable}
                  {...register('isNew')}
                />
                <label htmlFor="new">Novo</label>
              </div>

              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="out-of-line"
                  className="size-4"
                  disabled={isUneditable}
                  {...register('outOfLine')}
                />
                <label htmlFor="out-of-line">Fora de linha</label>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmModal
        contentMessage={'Deseja excluir este produto?'}
        isModalOpen={isConfirmModalOpen}
        onCloseModal={handleCloseConfirmModal}
        onNotConfirm={handleCloseConfirmModal}
        onConfirm={handleConfirmDeleteItem}
      />
      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
