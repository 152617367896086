import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHandleTable } from '../../hooks/useHandleTable';
import { getTableColumnWidthByBiggestElement } from '../../utils/tableColumnStyles';
import { useMutation, useQuery } from 'react-query';
import { domainCache, newsTypesCache } from '../../constants/requestCacheName';
import formatSearch from '../../utils/formatSearch';
import { useSearchParams } from '../../hooks/useSearchParams';
import { News, NewsListSort, NewsSort, ReorderNewsDto } from '../../types/news';
import { NewsTypePaginated } from '../../types/newsType';
import {
  getNewsTypesPaginated,
  reorderNewsByType,
} from '../../services/newsTypes';
import { useEdit } from '../../hooks/useEdit';
import { useNavigate } from 'react-router-dom';
import { mutationErrorHandling } from '../../utils/errorHandling';
import { useError } from '../../hooks/useError';
import { DropResult } from '@hello-pangea/dnd';
import { reorderList } from '../../utils/dragDrop';
import { FindAllDomainsPaginated } from '../../types/domain';
import { getDomainsPaginated } from '../../services/domain';
import { VinculateNewsInDomain } from '../../types/newsDomain';
import { DomainVinculation } from '../../types/products';
import { updateNewsDomainVinculation } from '../../services/news-domain';
import { getAllNewsListPaginated } from '../../services/news';
import { Search, search as searchDatabase } from '../../services/search';
import { newsSearchBaseEndpoint } from '../../constants/endpoints';
import { Pagination } from '../../types/pagination';

type NewsFilter = 'Todos' | string;
type DomainFilter = 'Todos' | string;

export const useNewsContent = (queryKey: string) => {
  const navigate = useNavigate();

  const [newsFilter] = useSearchParams('t') as [NewsFilter];

  const [domainFilter] = useSearchParams('d') as [DomainFilter];

  const reorderedTableUpdate = useRef<ReorderNewsDto[]>([]);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const [domainWidth, setDomainWidth] = useState<number>();

  const [checkNewsListDomains, setCheckNewsListDomains] = useState<
    VinculateNewsInDomain[]
  >([]);

  const [originalNewsListDomains, setOriginalNewsListDomains] = useState<
    VinculateNewsInDomain[]
  >([]);

  const newsListChanged = useRef<VinculateNewsInDomain[]>([]);

  const {
    canEdit: canEditOrder,
    handleEdit: handleEditOrder,
    handleUnedit: handleUneditOrder,
  } = useEdit();

  const { canEdit, handleEdit, handleUnedit } = useEdit();
  // const currentPage = useRef(1);

  // const {
  //   data: newsList,
  //   isFetching: isFetchingNewsList,
  //   refetch: refetchNewsList,
  // } = useQuery<NewsListSort>(
  //   queryKey,
  //   async () =>
  //     (
  //       await getAllNewsListPaginated({
  //         page: currentPage.current,
  //         type: newsFilter,
  //         domain: domainFilter,
  //       })
  //     ).data,
  //   {
  //     retry: false,
  //     refetchOnWindowFocus: false,

  //     onSuccess: (data) => {
  //       const checkNewsList: VinculateNewsInDomain[][] = data?.items.map(
  //         (news) => {
  //           const checked: VinculateNewsInDomain[] = news.domains?.map(
  //             (domain) => ({
  //               domainId: domain.id,
  //               newsId: news.id,
  //               isCheck: true,
  //             }),
  //           );

  //           return checked;
  //         },
  //       );

  //       if (checkNewsList) {
  //         setCheckNewsListDomains(checkNewsList.flat());
  //         setOriginalNewsListDomains(checkNewsList.flat());
  //       }

  //       defineItemsShown(data.items);
  //     },
  //   },
  // );

  const { data: newsTypes } = useQuery<NewsTypePaginated>(
    newsTypesCache,
    async () => (await getNewsTypesPaginated(1)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: domains } = useQuery<FindAllDomainsPaginated>(
    domainCache,
    async () => (await getDomainsPaginated()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const maxWidth = (domains?.items ?? []).reduce((maxWidth, row) => {
          const width = row.name?.toString().length || 0;
          return width > maxWidth ? width : maxWidth;
        }, 0);
        setDomainWidth((maxWidth > 32 ? maxWidth + 2 : 32) / 2);
      },
    },
  );

  const isSearch = useRef(false);
  const endPageRef = useRef<HTMLDivElement>(null);

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return searchDatabase<News>(
        baseUrl,
        searchValue,
        page,
        null,
        null,
        domainFilter || newsFilter
          ? [
              { name: 'd', value: domainFilter },
              { name: 't', value: newsFilter },
            ]
          : undefined,
      );
    },
    [domainFilter, newsFilter],
  );

  const loadMore = useCallback(
    async (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      return await getAllNewsListPaginated({
        domain: domainFilter,
        limit,
        page,
        type: newsFilter,
      });
    },
    [newsFilter, domainFilter],
  );

  const {
    sortField,
    inputRef,
    itemsLength: newsListLength,
    itemsShown,
    isLoadMoreFetching,
    searchItems,
    isSearchInputDirty,
    itemsShownLength: newsListShownLength,
    isSearchImageFetching,
    isSearchFetching: isFetchingNewsList,
    handleEnterClickSearchInput,
    handleSearchDatabase: handleSearchInAllNewsList,
    handleReset,
    refetchSearch: refetchNewsList,
    handleSearch: search,
    handleCancelSearch,
    handleSortTable: sortTable,
    defineItemsShown,
  } = useHandleTable(
    0,
    [],
    null,
    null,
    newsSearchBaseEndpoint,
    loadMore,
    null,
    doSearch as Search,
  );

  const newsListShown: NewsSort[] = itemsShown;
  const newsList = searchItems?.items as News[];

  useEffect(() => {
    if (searchItems) {
      const data = searchItems as Pagination<NewsSort>;
      const checkNewsList: VinculateNewsInDomain[][] = data.items.map(
        (news) => {
          const checked: VinculateNewsInDomain[] = news.domains?.map(
            (domain) => ({
              domainId: domain.id,
              newsId: news.id,
              isCheck: true,
            }),
          );

          return checked;
        },
      );

      if (checkNewsList) {
        setCheckNewsListDomains(checkNewsList.flat());
        setOriginalNewsListDomains(checkNewsList.flat());
      }

      defineItemsShown(data.items);
    }
  }, [defineItemsShown, searchItems]);

  const typeColWidth = useMemo(
    () =>
      getTableColumnWidthByBiggestElement<News>(
        newsList ?? [],
        'newsType.name',
        10,
      ) + 4,
    [newsList],
  );

  const handleSortTable = (fieldNameSort: string) => {
    if (canEditOrder) return;

    sortTable(fieldNameSort);
  };

  const handleSearch = () => {
    search<News>((item, formatedSearch) => {
      return (
        formatSearch(item.name).includes(formatedSearch) ||
        formatSearch(item.newsType.name).includes(formatedSearch)
      );
    });
  };

  const { mutate: reorderNewsMutate } = useMutation({
    mutationFn: async (dto: ReorderNewsDto[]) => {
      await reorderNewsByType(dto);
    },
    onError: (error) => {
      mutationErrorHandling(error, 'Falha ao reordenar', setErrorMessage);
    },
  });

  const currentParams = new URLSearchParams(window.location.search);

  const handleFilterByType = (filterName: string) => {
    if (filterName === 'Todos') {
      currentParams.delete('t');
      navigate({ search: currentParams.toString() });
      return;
    }

    currentParams.set('t', filterName);
    navigate({ search: currentParams.toString() });
  };

  const handleFilterByDomain = (filterName: string) => {
    if (filterName === 'Todos') {
      currentParams.delete('d');
      navigate({ search: currentParams.toString() });
      return;
    }
    currentParams.set('d', filterName.toString());
    navigate({ search: currentParams.toString() });
  };

  useEffect(() => {
    refetchNewsList();
  }, [refetchNewsList, newsFilter, domainFilter]);

  const handleChangeEditOrderButtonClick = () => {
    if (canEditOrder) {
      if (reorderedTableUpdate.current.length > 0) {
        reorderNewsMutate(reorderedTableUpdate.current);
      }

      handleUneditOrder();
      return;
    }

    handleReset();
    handleEditOrder();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedTable = reorderList(
      newsListShown,
      result.source.index,
      result.destination.index,
    );

    reorderedTable.forEach((reorderItem, index) => {
      reorderItem.order = index + 1;
    });

    reorderedTableUpdate.current = reorderedTable.map((reorderItem) => {
      return {
        newsId: reorderItem.id,
        order: reorderItem.order,
      };
    });

    defineItemsShown(reorderedTable);
  };

  const verifyAllDomainsChecked = useCallback(
    (domainId: number) => {
      const checkedNewsDomainsByDomainId = checkNewsListDomains.filter(
        (newsDomain) => newsDomain?.domainId === domainId && newsDomain.isCheck,
      );

      if (checkedNewsDomainsByDomainId.length === (newsList?.length ?? 0)) {
        return true;
      }

      return false;
    },
    [newsList?.length, checkNewsListDomains],
  );

  const handleToggleCheckDomains = (domainId: number, isCheck: boolean) => {
    const newsDomainsChecked: VinculateNewsInDomain[] = [];

    const newsDomainWithotDomainIdSelected = checkNewsListDomains.filter(
      (newsDomain) => newsDomain?.domainId !== domainId,
    );

    newsList.forEach((news) => {
      newsDomainsChecked.push({ newsId: news.id, domainId, isCheck });
    });

    newsListChanged.current = [
      ...newsDomainWithotDomainIdSelected,
      ...newsDomainsChecked,
    ];

    setCheckNewsListDomains([
      ...newsDomainWithotDomainIdSelected,
      ...newsDomainsChecked,
    ]);
  };

  const verifyDomainsPartialyChecked = (domainId: number) => {
    const checkedNewsDomainsByDomainId = checkNewsListDomains.filter(
      (newsDomain) => newsDomain?.domainId === domainId && newsDomain.isCheck,
    );

    return (
      checkedNewsDomainsByDomainId.length > 0 &&
      checkedNewsDomainsByDomainId.length < (newsList?.length ?? 0)
    );
  };

  const handleCheckDomain = (
    isCheck: boolean,
    newsId: number,
    domainId: number,
    domains: DomainVinculation[],
  ) => {
    const filteredDomains = checkNewsListDomains.filter(
      (domain) => domain?.domainId !== domainId || domain.newsId !== newsId,
    );
    const newsListDomainsWithoutSelected = newsListChanged.current.filter(
      (domain) => domain.domainId !== domainId || domain.newsId !== newsId,
    );

    if (isCheck) {
      newsListChanged.current = newsListDomainsWithoutSelected;
      newsListChanged.current.push({ domainId, newsId, isCheck: true });
      filteredDomains.push({ domainId, newsId, isCheck: true });
      setCheckNewsListDomains(filteredDomains);
      return;
    }

    newsListChanged.current = newsListDomainsWithoutSelected;
    newsListChanged.current.push({ domainId, newsId, isCheck: false });
    filteredDomains.push({ domainId, newsId, isCheck: false });
    setCheckNewsListDomains(filteredDomains);
  };

  const { mutate: updateNewsDomainVinculationMutate } = useMutation({
    mutationFn: async () => {
      if (newsListChanged.current.length > 0) {
        return (await updateNewsDomainVinculation(newsListChanged.current))
          .data;
      }
    },

    onSuccess: () => {
      if (newsListChanged.current) {
        newsListChanged.current = [];
      }
      setOriginalNewsListDomains([...checkNewsListDomains]);
      refetchNewsList();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao vincular ou desvincular produto à categoria',
        setErrorMessage,
      );
    },
  });

  const handleButtonOkClick = async () => {
    if (canEdit) {
      if (checkNewsListDomains.length > 0) {
        updateNewsDomainVinculationMutate();
      }
      handleUnedit();
      return;
    }
    handleEdit();
  };

  const handleButtonCancelEditClick = async () => {
    if (canEdit) {
      setCheckNewsListDomains([...originalNewsListDomains]);
      newsListChanged.current = [];
      handleUnedit();
    }

    if (canEditOrder) {
      handleReset();
      handleUneditOrder();
    }
  };

  const nameColWidth = useMemo(
    () =>
      getTableColumnWidthByBiggestElement<NewsSort>(
        newsListShown ?? [],
        'name',
        10,
      ) + 4,
    [newsListShown],
  );

  return {
    sortField,
    inputRef,
    isSearchInputDirty,
    newsListShown,
    newsListLength,
    newsListShownLength,
    isSearchImageFetching,
    typeColWidth,
    isSearch: isSearch.current,
    isLoadMoreFetching,
    endPageRef,
    newsTypes,
    filter: newsFilter,
    newsList,
    canEditOrder,
    canEdit,
    domainWidth,
    newsFilter,
    errorMessage,
    isFetchingNewsList,
    domains,
    domainFilter,
    checkNewsListDomains,
    nameColWidth,
    handleCheckDomain,
    verifyDomainsPartialyChecked,
    handleToggleCheckDomains,
    verifyAllDomainsChecked,
    clearError,
    handleFilterByType,
    handleFilterByDomain,
    handleEnterClickSearchInput,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
    handleSearchInAllNewsList,
    handleChangeEditOrderButtonClick,
    handleDragEnd,
    handleButtonCancelEditClick,
    handleButtonOkClick,
  };
};
