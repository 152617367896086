import { useBarCode } from './useBarCode';
import BarCodeListing from '../../../components/BarCodeListing';
import BarCodeSelecteds from '../../../components/BarCodeSelecteds';
import Modal from '../../../components/Modal';
import Barcode from 'react-barcode';
import ConfirmModal from '../../../components/ConfirmModal';
import Input from '../../../components/Input';
import Checkbox from '../../../components/Checkbox';

export default function BarCode() {
  const {
    currentLabelSize,
    isCodeBarModalOpen,
    currentProductBarCode,
    realLabelNumber,
    currentBlankLabel,
    totalPages,
    currentLabelsInPage,
    itemsSelected,
    isConfirmDeleteModalOpen,
    currentTotalPages,
    totalBlankLabels,
    isConfirmDeleteAllModalOpen,
    currentItemToDelete,
    itemsRemovedFromList,
    inputRef,
    maxLabelsToShow,
    handleCheckboxClick,
    handleChangeLabelNumber,
    visibleLabels,
    totalCheckboxes,
    checkedState,
    firstCheckboxIndexClicked,
    // errorMessage,
    // handleGeneratePdf,
    // clearError,
    handleIgnoreClick,
    handleConfirmDeleteAllLabels,
    handleCloseConfirmDeleteAllModal,
    handleRemoveAllLabelsClick,
    handleCloseConfirmDeleteModal,
    handleConfirmRemoveItemSelected,
    handleRemoveLabelClick,
    handleAddLabel,
    handleTableRowClick,
    handleCloseCodeBarModal,
  } = useBarCode();

  return (
    <>
      <main className="grid grid-cols-3">
        <BarCodeListing
          itemsRemovedFromList={itemsRemovedFromList}
          onTableRowClick={handleTableRowClick}
          totalItemsSelected={itemsSelected.length}
        />
        <BarCodeSelecteds
          blankLabelsAmount={totalBlankLabels}
          totalPages={totalPages}
          itemsSelected={itemsSelected}
          firstCheckedIndex={firstCheckboxIndexClicked ?? 0}
          onRemoveItemSelectedClick={handleRemoveLabelClick}
          onRemoveAllItemsClick={handleRemoveAllLabelsClick}
        />
      </main>

      <Modal
        isOpen={isCodeBarModalOpen}
        onClose={handleCloseCodeBarModal}
        iconContainerStyle={{ right: '1rem', top: '1rem' }}
        width="fit-content"
      >
        <div className="flex gap-8 px-4 py-8">
          <div className="flex w-80 flex-col justify-between">
            <div className="flex items-end gap-4">
              <div>
                <h2 className="text-center font-medium">Etiqueta</h2>
                <div className="h-36 border border-zinc-700 p-2">
                  <p className="text-xs leading-3">
                    {currentProductBarCode?.name} com 1{' '}
                    {currentProductBarCode?.unit}
                  </p>
                  {currentProductBarCode?.barCode && (
                    <div className="mx-auto w-fit">
                      <Barcode
                        width={1.2}
                        height={60}
                        format="EAN13"
                        value={currentProductBarCode.barCode.toString()}
                      />
                    </div>
                  )}
                </div>
              </div>
              <span className="flex h-32 items-center text-zinc-400">X</span>
              <div className="relative flex h-32 flex-col items-end justify-center">
                <Input
                  ref={inputRef}
                  type="number"
                  className="remove-arrows w-24 rounded border px-2 py-1 text-right outline-none"
                  min={0}
                  onChange={(event) => {
                    console.log(
                      '🚀 ~ BarCode ~ firstCheckboxIndexClicked:',
                      firstCheckboxIndexClicked,
                    );
                    // if (firstCheckboxIndexClicked !== null) {
                    handleChangeLabelNumber(+event.target.value);
                    // }
                  }}
                />
                {realLabelNumber > 0 && (
                  <span className="absolute bottom-0 px-2 text-zinc-400">
                    {realLabelNumber}
                  </span>
                )}
              </div>
            </div>
            <div className="w-full text-sm">
              <h2 className="mb-2 text-center">Informações</h2>
              <div className="flex flex-col gap-4">
                <div className="flex w-full justify-between">
                  <span>Etiquetas por página</span>
                  <span className="text-zinc-400">
                    {currentLabelSize.columns * currentLabelSize.row}
                  </span>
                </div>
                <div className="flex w-full justify-between">
                  <span>Etiquetas em branco</span>
                  <span className="text-zinc-400">{currentBlankLabel}</span>
                </div>
                <div className="flex w-full justify-between">
                  <span>Visualização da página</span>
                  <span className="text-zinc-400">{currentTotalPages}</span>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <button
                onClick={handleAddLabel}
                className="w-28 rounded border bg-gradient-to-b from-white to-zinc-200 px-4 py-1"
              >
                Adicionar
              </button>
              <button
                onClick={handleIgnoreClick}
                className="w-28 rounded border bg-gradient-to-b from-white to-zinc-200 px-4 py-1"
              >
                Ignorar
              </button>
            </div>
          </div>
          <div>
            <h2 className="text-center font-medium">Pré Visualização</h2>
            <div>
              <table className="border-collapse border border-gray-400">
                <tbody>
                  {Array.from({ length: currentLabelSize.row }).map(
                    (_, rowIndex) => (
                      <tr key={rowIndex}>
                        {Array.from({ length: currentLabelSize.columns }).map(
                          (_, columnsIndex) => {
                            const index =
                              rowIndex * currentLabelSize.columns +
                              columnsIndex;

                            // Labels vvv
                            const visibleItemCount =
                              checkedState.filter(Boolean).length;

                            const shouldShowItem =
                              checkedState[index] &&
                              visibleItemCount -
                                checkedState.slice(index + 1).filter(Boolean)
                                  .length <=
                                maxLabelsToShow;

                            const notIncluded =
                              totalCheckboxes - visibleItemCount;

                            const labelIndex = index - notIncluded;

                            return (
                              <td
                                key={`${rowIndex}${columnsIndex}`}
                                className="relative h-8 w-20 border-2 border-zinc-900"
                              >
                                <div className="absolute right-0 top-0 z-20">
                                  <div className="flex h-full items-start justify-end">
                                    <div className="w-4">
                                      <Checkbox
                                        className="size-4"
                                        checked={checkedState[index]}
                                        onChange={() =>
                                          handleCheckboxClick(index)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {shouldShowItem &&
                                  visibleLabels[labelIndex] && (
                                    <div className="mx-auto w-fit">
                                      <Barcode
                                        width={0.3}
                                        height={5}
                                        format="EAN13"
                                        value={visibleLabels[
                                          labelIndex
                                        ].barCode.toString()}
                                      />
                                    </div>
                                  )}
                              </td>
                            );
                          },
                        )}
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>

      <ConfirmModal
        contentMessage={`Deseja cancelar a impressão de ${currentItemToDelete?.amount} etiquetas do produto/peça ${currentItemToDelete?.id} - ${currentItemToDelete?.name}?`}
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handleCloseConfirmDeleteModal}
        onNotConfirm={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmRemoveItemSelected}
      />

      <ConfirmModal
        contentMessage={'Deseja excluir todos os itens da lista?'}
        isModalOpen={isConfirmDeleteAllModalOpen}
        onCloseModal={handleCloseConfirmDeleteAllModal}
        onNotConfirm={handleCloseConfirmDeleteAllModal}
        onConfirm={handleConfirmDeleteAllLabels}
      />

      {/* <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      /> */}
    </>
  );
}
