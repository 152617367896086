import { CronInfos, CronLastSynchronizations } from '../types/cron';
import { api } from './api';

export const getCronInfos = async () => {
  const response = await api.get<CronInfos>('/cron/v1/infos');
  return response;
};

export const getLastSynchronizations = async () => {
  const response = await api.get<CronLastSynchronizations>(
    '/cron/v1/last-synchronizations',
  );
  return response;
};
