import { VinculatePopUpInDomain } from '../types/popUpDomain';
import { api } from './api';

export const updatePopUpDomainVinculation = async (
  vinculations: VinculatePopUpInDomain[],
) => {
  const body = {
    vinculations,
  };

  const response = await api.put<void>(`/pop-up-domain/v1`, body);

  return response;
};
