import { useCallback } from 'react';
import { Service } from '.';
import { Subject } from '../../types/subject';
import { Search, search as searchDatabase } from '../../services/search';
import { useHandleTable } from '../../hooks/useHandleTable';
import formatSearch from '../../utils/formatSearch';

export const useSubjectContent = (baseUrl: string, service: Service) => {
  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return searchDatabase<Subject>(baseUrl, searchValue, page, null, null);
    },
    [],
  );

  const loadMore = useCallback(
    (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      return service(page, direction, limit, [
        {
          name: 's',
          value: inputRef.current?.value ?? '',
        },
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [service],
  );

  const {
    currentPage,
    sortField,
    inputRef,
    pagination,
    isSearchInputDirty,
    searchItems: subjects,
    itemsLength: subjectsLength,
    itemsShownLength: subjectsShownLength,
    itemsShown,
    isSearchFetching,
    isSearch,
    endPageRef,
    isLoadMoreFetching,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch: search,
    handleChangePage,
    handleChangePagination,
    handleSortTable,
    handleCancelSearch,
  } = useHandleTable(
    0,
    [],
    null,
    null,
    baseUrl,
    loadMore,
    null,
    doSearch as Search,
  );

  const subjectsShown: Subject[] = itemsShown;
  const isFetching = isSearchFetching;

  const handleSearch = () => {
    search<Subject>((item, formatedSearch) =>
      formatSearch(item.name).includes(formatedSearch),
    );
  };

  return {
    sortField,
    inputRef,
    isSearchInputDirty,
    subjectsLength,
    subjectsShownLength,
    isFetching,
    subjectsShown,
    subjects,
    pagination,
    currentPage,
    isSearch,
    endPageRef,
    isLoadMoreFetching,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch,
    handleChangePage,
    handleChangePagination,
    handleSortTable,
    handleCancelSearch,
  };
};
