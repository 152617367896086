import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

type AutoCompleteItemProps = ComponentProps<'li'>;

export default function AutoCompleteItem({
  children,
  className,
  ...rest
}: AutoCompleteItemProps) {
  return (
    <li
      className={twMerge(
        'cursor-pointer px-4 py-2 hover:bg-zinc-300',
        className,
      )}
      {...rest}
    >
      {children}
    </li>
  );
}
