import { Link } from 'react-router-dom';
import {
  getImageFromApi,
  verifyImageUrlExists,
} from '../../../utils/getImageUrl';
import { Product } from '../../../types/products';
import Card from '../CardBuffalo/index';

import { useHomeCard } from './useHomeCard';
import { useEffect, useRef, useState } from 'react';

type ItemCardProps = {
  product: Product;
};

export default function HomeCardBuffalo({ product }: ItemCardProps) {
  const { handleImageWasLoaded, handleLoadStart } = useHomeCard(product);
  const [imageSrc, setImageSrc] = useState('/images/unavailable.png');
  const [browser, setBrowser] = useState<string | undefined>(undefined); // Use useState for reactivity

  useEffect(() => {
    // Identifying the browser
    const userAgent = navigator.userAgent;
    if (userAgent.includes('Chrome') && !userAgent.includes('Edge')) {
      setBrowser('Google Chrome');
    } else if (userAgent.includes('Firefox')) {
      setBrowser('Mozilla Firefox');
    } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      setBrowser('Safari');
    } else if (userAgent.includes('Edge')) {
      setBrowser('Microsoft Edge');
    } else if (userAgent.includes('Trident')) {
      setBrowser('Internet Explorer');
    } else {
      setBrowser('Navegador desconhecido');
    }
  }, []);

  useEffect(() => {
    const url = getImageFromApi(
      product.idProduto,
      product.idProduto < 50000 ? 'parts' : 'products',
    );
    const verifyImageExists = async () => {
      const imageExists = await verifyImageUrlExists(url);
      if (imageExists) {
        setImageSrc(url);
        return;
      }

      setImageSrc('/images/unavailable.png');
    };

    verifyImageExists();
  }, [product.idProduto]);

  return (
    <>
      <Link
        to={{
          pathname: `/produto/${product.idProduto}/${encodeURIComponent(product.name)}/categoria/${product.idCategoria}/linha/${product.idLinha}`,
          // search: `categoryId=${product.idCategoria}`,
        }}
      >
        <Card.Root
          key={product.idProduto}
          border
          className="min-h-[21rem] w-[21rem]"
        >
          <div>
              <Card.Image
                // src={getImageFromApi(product.idProduto, 'parts')}
                src={getImageFromApi(
                  product.idProduto,
                  product.idProduto < 50000 ? 'parts' : 'products',
                )}
                alt={`Imagem do ${product.name}`}
                fallbackSrc="/images/unavailable.png"
                fallbackAlt="Imagem de produto indisponível"
                onLoadStart={handleLoadStart}
                onLoad={handleImageWasLoaded}
              />

            <div>
              <Card.IsNew text={product.new ? 'Novo' : ''} />
              <Card.Infos
                productId={product.idProduto}
                productName={product.name}
              />
            </div>

            <div className="flex h-10 justify-center">
              <Card.Actions className="w-1/2 rounded-lg border-2 border-yellow-400 text-center text-sm transition-all hover:bg-zinc-200">
                <Card.Action text="Mais detalhes" />
              </Card.Actions>
            </div>
          </div>
        </Card.Root>
      </Link>
    </>
  );
}
