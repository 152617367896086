import { useEffect, useRef, useState, useCallback } from 'react';
import { useModal } from '../../../hooks/useModal';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  ImageFormData,
  Images,
  ImagesPaginated,
  SaveImagesDto,
} from '../../../types/images';
import { uploadImageSchema } from '../../../validations/schemas';
import { ImageTypes } from '../../../enums/imageTypes';
import { useMutation, useQuery } from 'react-query';
import {
  imageTypesCache,
  searchCache,
} from '../../../constants/requestCacheName';
import { getImageTypesPaginated } from '../../../services/imageTypes';
import { ImageType, ImageTypesPaginated } from '../../../types/imageTypes';
import { useError } from '../../../hooks/useError';
import { getImageFromApi } from '../../../utils/getImageUrl';
import {
  deleteImageStorage,
  getImageSizesFromFile,
  ImageSizes,
  uploadImage,
  uploadImages as uploadImagesStorage,
} from '../../../services/imageStorage';
import {
  deleteImage,
  saveImage,
  saveImagesBatch,
  verifyImagesRepeatedByNameAndType,
} from '../../../services/images';
import { AxiosError } from 'axios';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { getFileNameByUrl } from '../../../utils/urlToFileName';
import { urlToFileList } from '../../../utils/urlToFileList';
import { validateImageExtension } from '../../../utils/imageValidationExtension';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { imagesSearchBaseEndpoint } from '../../../constants/endpoints';
import { getFileExtension } from '../../../utils/fileExtension';
import { regexExtension } from '../../../validations/regex';

export const useImages = () => {
  const canValidateImageUpload = useRef<string | null>(null);
  const imageIdUpdate = useRef<number | null>(null);
  const imageUrlUpdate = useRef<string | null>(null);
  const imageTypeUpdate = useRef<string | null>(null);
  const previousFileList = useRef<FileList | null>(null);
  const isManageModalOpening = useRef(false);
  const currentFormData = useRef<ImageFormData | null>(null);

  const [previewImage, setPreviewImage] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isImageDeleteLoading, setIsImageDeleteLoading] = useState(false);
  const [alreadyRegisteredFields, setAlreadyRegisteredFields] = useState<
    Images[]
  >([]);

  const { removeItemFromScreen, addItemOnScreen, updateItemOnScreen } =
    useQueryCache();

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    watch,
    setError,
    reset,
    setFocus,
    setValue,
  } = useForm<ImageFormData>({
    resolver: zodResolver(uploadImageSchema),
    defaultValues: {
      reference: '',
      type: ImageTypes.products,
    },
  });
  const files = watch('image');
  const currentImageType = watch('type');
  const reference = watch('reference');

  const { mutate: deleteImageMutate } = useMutation({
    mutationFn: async () => {
      if (imageIdUpdate.current) {
        await deleteImage(imageIdUpdate.current);
        return;
      }

      setErrorMessage('Falha ao deletar imagem');
    },

    onSuccess: () => {
      if (imageIdUpdate.current) {
        removeItemFromScreen<ImagesPaginated>(
          `${searchCache}${imagesSearchBaseEndpoint}`,
          imageIdUpdate.current,
        );
      }
      setIsImageDeleteLoading(false);
      handleCloseManageModal();
    },

    onError: (error) => {
      (async () => {
        const fileName = getFileNameByUrl(imageUrlUpdate.current ?? '');

        if (fileName && imageTypeUpdate.current) {
          uploadImage(
            imageTypeUpdate.current,
            fileName,
            previousFileList.current![0],
          );
        }
        setIsImageDeleteLoading(false);
        previousFileList.current = null;
      })();

      mutationErrorHandling(
        error,
        'Falha ao deletar imagem',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.statusCode === 409
          ) {
            setErrorMessage(
              'Esta imagem só pode ser apagado depois que não houver relações com outras tabelas',
            );
            return true;
          }
        },
      );
    },
  });

  // const uploadImages = useCallback(() => {}, []);

  // const { mutate: verifyImagesRepeatedByNameAndTypeMutate } = useMutation({
  //   mutationFn: async (dto: VerifyImagesDto) =>
  //     (await verifyImagesRepeatedByNameAndType(dto)).data,

  //   onSuccess: (data) => {
  //     setAlreadyRegisteredFields(data.items);

  //     if (data.meta.totalItems > 0) {
  //       setErrorMessage('Alguns produtos já estão cadastrados');
  //       return;
  //     }

  //     uploadImages();
  //   },

  //   onError: (error) => {},
  // });

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { data: imageTypes } = useQuery<ImageTypesPaginated>(
    imageTypesCache,
    async () => (await getImageTypesPaginated()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleCancelIconClick = () => {
    setValue('reference', '', { shouldDirty: true });
    setFocus('reference');
  };

  const validateImage = useCallback(
    (
      imageTypeSelected: ImageType | undefined,
      fileExtension: string | null | undefined,
    ) => {
      const fileSizeInMb = files?.[0]?.size / 1_000_000;

      const imageTypeSelectedMaxSize =
        imageTypeSelected?.imageTypeExtensions[0]?.maxSize;

      const imageTypeSelectedExtension =
        imageTypeSelected?.imageTypeExtensions[0]?.imageExtension?.extension;

      if (
        !(
          fileExtension === imageTypeSelectedExtension ||
          (imageTypeSelected?.imageTypeExtensions?.length ?? 0) <= 0
        )
      ) {
        const dataTransfer = new DataTransfer();
        setValue('image', dataTransfer.files);
        setPreviewImage('');

        setError('image', {
          message: `Somente arquivos ${imageTypeSelectedExtension} são permitidos para ${imageTypeSelected?.name}`,
        });
        return false;
      }

      if (imageTypeSelectedMaxSize && imageTypeSelectedMaxSize < fileSizeInMb) {
        const dataTransfer = new DataTransfer();
        setValue('image', dataTransfer.files);
        setPreviewImage('');

        setError('image', {
          message: `O tamanho máximo permitido para ${imageTypeSelected?.name} é de ${imageTypeSelectedMaxSize.toLocaleString()} MB`,
        });
        return false;
      }

      return true;
    },
    [files, setError, setValue],
  );

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: closeManageModal,
    handleOpenModal: handleOpenManageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: handleCloseConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  useEffect(() => {
    if (files?.length <= 0 || !isManageModalOpening.current) {
      return;
    }

    if (files?.length > 0) {
      setError('reference', {});
    }

    const imageTypeSelected = imageTypes?.items?.find(
      (imageType) => imageType.name === currentImageType,
    );

    if (files?.length > 1) {
      for (const file of Array.from(files)) {
        const fileExtension = file ? getFileExtension(file) : null;

        if (!validateImage(imageTypeSelected, fileExtension)) {
          return;
        }
      }

      const fileNames = Array.from(files)
        .map((file) => file.name)
        .join('; ');

      setPreviewImage('');
      setValue('reference', fileNames);
      setError('image', {});

      return;
    }

    const imageTypeSelectedExtension =
      imageTypeSelected?.imageTypeExtensions[0]?.imageExtension?.extension;

    const fileExtension = files?.[0] ? getFileExtension(files[0]) : null;

    if (!validateImage(imageTypeSelected, fileExtension)) {
      return;
    }

    if (files && files[0]) {
      if (
        fileExtension === imageTypeSelectedExtension ||
        (imageTypeSelected?.imageTypeExtensions?.length ?? 0) <= 0
      ) {
        const previewUrl = URL.createObjectURL(files[0]);
        setPreviewImage(previewUrl);
        setError('image', {});
        setValue('reference', files[0].name);
        return;
      }
    }
  }, [
    currentImageType,
    files,
    imageTypes?.items,
    isMenageModalOpen,
    setError,
    setValue,
    validateImage,
  ]);

  const clearFields = useCallback(() => {
    setPreviewImage('');
    reset();
  }, [reset]);

  const handleAddImageClick = () => {
    handleOpenManageModal();
  };

  const handleTableRowClick = (
    imageId: number,
    imageName: string,
    imageType: string,
    imageUrl: string,
  ) => {
    isManageModalOpening.current = false;
    canValidateImageUpload.current = imageName;
    imageIdUpdate.current = imageId;
    imageUrlUpdate.current = imageUrl;
    imageTypeUpdate.current = imageType;
    setValue('reference', imageName);
    setValue('type', imageType);
    setPreviewImage(imageUrl);
    handleOpenManageModal();
  };

  const handleCloseManageModal = useCallback(() => {
    canValidateImageUpload.current = null;
    isManageModalOpening.current = false;
    reset();
    setPreviewImage('');
    closeManageModal();
  }, [closeManageModal, reset]);

  const handleDeleteButtonClick = () => {
    handleOpenConfirmDeleteModal();
  };

  const upsertImagesOnScreen = useCallback(
    (image: Images, isCreate: boolean) => {
      if (isCreate) {
        addItemOnScreen<ImagesPaginated>(
          `${searchCache}${imagesSearchBaseEndpoint}`,
          image,
        );

        return;
      }

      updateItemOnScreen<ImagesPaginated>(
        `${searchCache}${imagesSearchBaseEndpoint}`,
        image,
      );
    },
    [addItemOnScreen, updateItemOnScreen],
  );

  const uploadImages = useCallback(
    async ({ reference, type, image }: ImageFormData, isCreate?: boolean) => {
      // for (let i = 0; i < image.length; i++) {
      //   const file = image[i];
      // }

      setIsImageLoading(true);

      const imagesDto: SaveImagesDto[] = [];

      const imageSizesPromises: Promise<ImageSizes>[] = [];

      for (let i = 0; i < image.length; i++) {
        const img = image[i];

        imageSizesPromises.push(getImageSizesFromFile(img));
      }

      const imageSizes = await Promise.all(imageSizesPromises);

      for (let i = 0; i < image.length; i++) {
        const img = image[i];
        const imageName =
          image.length === 1 && reference ? reference : img.name;

        const withoutExtension = imageName.includes('.')
          ? imageName.split('.').slice(0, -1).join('.')
          : imageName;

        const extension = img.name.substring(img.name.lastIndexOf('.') + 1);
        const imageNameWithCorrectExtension = `${withoutExtension}.${extension}`;
        imagesDto.push({
          name: imageNameWithCorrectExtension,
          typeName: type,
          url: getImageFromApi(imageNameWithCorrectExtension, type),
          width: imageSizes[i].width,
          height: imageSizes[i].height,
        });
      }

      try {
        await uploadImagesStorage(
          type,
          image,
          async () => {
            if (image.length === 1) {
              try {
                const savedImage = await saveImage(imagesDto[0]);
                upsertImagesOnScreen(savedImage.data, !!isCreate);

                clearFields();
                handleCloseManageModal();
              } catch (error) {
                if (
                  error instanceof AxiosError &&
                  error.response?.data.message.includes('already exists') &&
                  error.response?.data.statusCode === 400
                ) {
                  setErrorMessage('Produto já cadastrado');
                }
                // throw error;
              }
              return;
            }
            try {
              await saveImagesBatch(imagesDto);
              clearFields();
              handleCloseManageModal();
            } catch (error) {
              if (
                error instanceof AxiosError &&
                error.response?.data.message.includes('already exists') &&
                error.response?.data.statusCode === 400
              ) {
                const registeredFields =
                  error.response?.data.message.split(': ')[1];
                setErrorMessage('Algum item já está cadastrado');
                setAlreadyRegisteredFields(JSON.parse(registeredFields));
              }
              // throw error;
            }
          },
          image.length === 1 ? imagesDto[0].name : null,
        );
      } catch (error) {
        setErrorMessage(`Erro ao fazer upload das imagens`);
      } finally {
        setIsImageLoading(false);
        return;
      }
    },
    [
      clearFields,
      handleCloseManageModal,
      setErrorMessage,
      upsertImagesOnScreen,
    ],
  );

  const handleOverrideExistingImages = () => {
    if (!currentFormData.current) return;

    uploadImages(currentFormData.current);
    handleCloseAlreadyExistsModal();
  };

  const handleIgnoreExistingImages = () => {
    if (!currentFormData.current) return;

    const currentImagesToUpload = Array.from(currentFormData.current.image);

    const imagesWithoutRepeated = currentImagesToUpload.filter(
      (image) =>
        !alreadyRegisteredFields.some(
          (field) => field.name === (reference || image.name),
        ),
    );

    const dataTransfer = new DataTransfer();
    imagesWithoutRepeated.forEach((image) => {
      dataTransfer.items.add(image);
    });

    currentFormData.current.image = dataTransfer.files;

    uploadImages(currentFormData.current);
    handleCloseAlreadyExistsModal();
  };

  const handleButtonOkClick = handleSubmit(
    async ({ reference, type, image }: ImageFormData) => {
      const imageNames =
        image.length <= 1
          ? [reference ?? image[0].name]
          : Array.from(image).map((img) => img.name);

      const { data } = await verifyImagesRepeatedByNameAndType({
        imageNames,
        imageType: type,
      });

      setAlreadyRegisteredFields(data.items);

      if (data.meta.totalItems > 0) {
        setErrorMessage('Alguns produtos já estão cadastrados');
        currentFormData.current = { reference, type, image };
        return;
      }

      uploadImages({ image, reference, type });
      handleCloseAlreadyExistsModal();
    },
  );

  const handleUploadImageClick = () => {
    isManageModalOpening.current = true;
  };

  const handleConfirmDeleteImage = async () => {
    handleCloseConfirmDeleteModal();
    if (canValidateImageUpload.current && imageUrlUpdate.current) {
      previousFileList.current = await urlToFileList(
        imageUrlUpdate.current,
        canValidateImageUpload.current,
        'image/svg+xml',
      );

      const imageError = await deleteImageStorage(
        validateImageExtension(canValidateImageUpload.current),
        currentImageType,
      );

      // if (!imageError) {
      deleteImageMutate();
      handleCloseManageModal();
      // }
    }
  };

  const handleCloseAlreadyExistsModal = useCallback(() => {
    currentFormData.current = null;
    setAlreadyRegisteredFields([]);
    clearError();
  }, [clearError]);

  return {
    reference,
    imageNameUpdate: canValidateImageUpload.current,
    isMenageModalOpen,
    isConfirmDeleteModalOpen,
    isImageLoading,
    errors,
    dirtyFields,
    files: Array.from(files ?? []),
    imageTypes,
    previewImage,
    errorMessage,
    isImageDeleteLoading,
    alreadyRegisteredFields,
    handleIgnoreExistingImages,
    handleOverrideExistingImages,
    handleCloseAlreadyExistsModal,
    clearError,
    handleUploadImageClick,
    handleConfirmDeleteImage,
    handleCloseConfirmDeleteModal,
    handleCancelIconClick,
    register,
    handleButtonOkClick,
    handleDeleteButtonClick,
    handleCloseManageModal,
    handleAddImageClick,
    handleTableRowClick,
  };
};
