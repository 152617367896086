import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  liveSearchProductBuffaloCache,
  productBuffaloCache,
  productDocumentsBuffaloCache,
} from '../../../constants/requestCacheName';
import { getAllProductDocumentsByProductId } from '../../../services/productDocument';
import { FindAllProductDocuments } from '../../../types/productDocument';
import { FindAllProductDescriptionDetails } from '../../../types/productsDescription';
import { getImageFromApi } from '../../../utils/getImageUrl';
import { fetchProductDocuments } from '../../services/manuaisService';
import { getProductByProductId } from '../../services/produtosService';
import { getProductLineCategoriesDomainLiveSearchPaginated } from '../../../services/productLineCategoryDomain';
import {
  ProductsLineCategoryDomainLiveSearch,
  ProductsLineCategoryDomainLiveSearchPaginated,
} from '../../../types/productLineCategoryDomain';
import { useLiveSearch } from '../../hooks/useLiveSearch';
import { verifyScreenIsLessThan } from '../../../utils/verifyScreenIsLessThan';
import { getCurrentDomain } from '../../../utils/domain';
import { useOpenFileMaskUrl } from '../../hooks/useOpenFileMaskUrl';

export const useDetails = () => {
  const { productId, productName, categoryId, lineId } = useParams();

  const { data: productsSearch, refetch: refetchProductsSearch } =
    useQuery<ProductsLineCategoryDomainLiveSearchPaginated>(
      liveSearchProductBuffaloCache,
      async () =>
        (
          await getProductLineCategoriesDomainLiveSearchPaginated(
            inputRef.current?.value ?? null,
            getCurrentDomain().id,
          )
        ).data,
      {
        retry: false,
        enabled: false,
        refetchOnWindowFocus: false,
      },
    );

  const {
    inputRef,
    handleClickOutAutoComplete,
    handleSearchInputChange,
    handleClearSearchCache,
    handleClearSearchAndSetFocus,
  } = useLiveSearch({ refetch: refetchProductsSearch });

  const navigate = useNavigate();

  const handleProductSearchClick = async ({
    product,
    category,
    line,
  }: ProductsLineCategoryDomainLiveSearch) => {
    refetchProductDescription();
    handleClearSearchCache();
    navigate({
      pathname: `/produto/${product.id}/${encodeURIComponent(product.name)}/categoria/${category.id}/linha/${line.id}`,
    });
  };

  const {
    data: productDescriptionBuffalo,
    refetch: refetchProductDescription,
  } = useQuery<FindAllProductDescriptionDetails>(
    `${productBuffaloCache}${productId}`,
    async () => getProductByProductId(+productId!, +categoryId!, +lineId!),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const { data: productDocumentsBuffalo } = useQuery<FindAllProductDocuments>(
    `${productDocumentsBuffaloCache}${productId}`,
    async () => {
      return (
        await getAllProductDocumentsByProductId({ productId: +productId! })
      ).data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  useEffect(() => {
    if (!productId) {
      console.error('ID do produto não está disponível.');
      return;
    }

    const fetchDocuments = async () => {
      try {
        await fetchProductDocuments(+productId);
      } catch (error) {
        console.error('Erro ao carregar documentos:', error);
      }
    };

    fetchDocuments();
  }, [productId]);

  const productDescriptionBuffaloList =
    productDescriptionBuffalo?.descriptions.items;
  const productHTMLDescription =
    productDescriptionBuffalo?.descriptions.items[0]?.product.description;
  const mainImageSrc = getImageFromApi(
    productId ?? '',
    Number(productId) < 50000 ? 'parts' : 'products',
  );
  const mainImageFallback = '/images/unavailable.png';

  const handleOpenDocument = (documentUrl: string) => {
    window.open(documentUrl, '_blank');
  };

  const imageSize = useMemo(() => {
    if (!verifyScreenIsLessThan(1600)) {
      return {
        width: '800px',
        height: '400px',
      };
    }

    if (!verifyScreenIsLessThan(1330)) {
      return {
        width: '600px',
        height: '300px',
      };
    }

    if (!verifyScreenIsLessThan(1000)) {
      return {
        width: '400px',
        height: '200px',
      };
    }

    return {
      width: 'auto',
      height: 'auto',
    };
  }, []);

  const { fileLoadingId, loadFile } = useOpenFileMaskUrl();

  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!productsSearch || productsSearch.items.length === 0) return;

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        setSelectedIndex((prev) =>
          Math.min(prev + 1, productsSearch.items.length - 1),
        );
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        setSelectedIndex((prev) => Math.max(prev - 1, 0));
      }

      if (event.key === 'Enter' && selectedIndex !== -1) {
        event.preventDefault();
        handleProductSearchClick(productsSearch.items[selectedIndex]);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [productsSearch, selectedIndex]);

  return {
    productDocumentsBuffalo,
    productName,
    categoryId,
    productDescriptionBuffaloList,
    productHTMLDescription,
    mainImageSrc,
    mainImageFallback,
    productId,
    inputRef,
    productsSearch,
    imageSize,
    fileLoadingId,
    productDescriptionBuffalo,
    lineId,
    selectedIndex,
    handleClearSearchAndSetFocus,
    loadFile,
    handleSearchInputChange,
    handleClickOutAutoComplete,
    handleProductSearchClick,
    handleOpenDocument,
  };
};
