import React, {
  ComponentProps,
  forwardRef,
  ReactNode,
  Ref,
  useState,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/BuffaloMotoresAcompladosLogoAmarela.svg';
import LogoRental from '../../assets/images/BuffaloMotoresAcompladosLogoRental.png';
import { twMerge } from 'tailwind-merge';
import { ClickAwayListener } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuMobile from '../menuMobile';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import SearchBar from '../SearchBar';
import ToolTipCustom from '../ToolTip';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ChevronRightIcon } from 'lucide-react';

type HeaderProps = ComponentProps<'header'> & {
  onCLickSearchIcon?: () => void;
  menuIcon?: boolean;
  searchBar?: boolean;
  productCatalog?: boolean;
  productsDetail?: ReactNode;
  searchParts?: ReactNode;
  title?: string;
  openSearch?: boolean;
  handleSearchInList?: () => void;
  handleBackButtonClick?: () => void;
};

const Header = forwardRef<HTMLInputElement, HeaderProps>(
  (
    {
      menuIcon,
      openSearch,
      searchBar = false,
      productCatalog = false,
      searchParts,
      productsDetail,
      className,
      title,
      onCLickSearchIcon,
      handleSearchInList,
      handleBackButtonClick,
      ...rest
    },
    ref,
  ) => {
    const [isSelectDomainOpen, setIsSelectDomainOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleTooltipClose = () => {
      setIsSelectDomainOpen(false);
    };

    const handleTooltipOpen = () => {
      setIsSelectDomainOpen(true);
    };
    const location = useLocation();

    const isCatalogoPecasBuffalo = location.pathname.startsWith('/catalogo/');

    const isDetalhes = location.pathname.startsWith('/produto/');

    const isCompatibilidade = location.pathname.startsWith('/compatibilidade/');

    const toggleMenu = () => {
      setMenuOpen((prev) => !prev); // Altera o estado de menuOpen
    };

    const navigate = useNavigate();

    const handleReturnPage = () => {
      navigate(-1);
    };

    return (
      <>
        <div
          className={`absolute ${productCatalog ? 'max-xl:left-[calc(50%-10rem) left-1' : 'left-1 xl:left-[calc(50%-11rem)]'}`}
        >
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <div
                data-open={isSelectDomainOpen}
                className={`transition-medium fixed top-3 z-50 flex w-[10.4rem] cursor-pointer flex-col rounded-lg border-none text-center text-base font-medium tracking-widest text-black opacity-0 outline-none data-[open=true]:top-[5.1rem] data-[open=true]:opacity-100 lg:w-[12.25rem] xl:left-0`}
                onMouseEnter={handleTooltipOpen}
                onMouseLeave={handleTooltipClose}
              >
                <Link
                  to={
                    isSelectDomainOpen
                      ? process.env.REACT_APP_BUFFALO_RENTAL_BASE_URL ?? ''
                      : '#'
                  }
                  className="z-50 rounded-lg hover:text-zinc-100"
                >
                  <img
                    src={LogoRental}
                    alt="Logo"
                    className="z-50 -mt-4 box-border h-[4.2rem] w-52 lg:h-[4.75rem]"
                  />
                </Link>
              </div>
            </div>
          </ClickAwayListener>
        </div>
        <header
          className={twMerge(
            'sticky top-0 z-40 h-[5.5rem] border-b-4 border-yellow-500 bg-white/90 backdrop-blur-sm lg:h-24',
            className,
          )}
          {...rest}
        >
          <div>
            <div
              className={`mx-3 flex xl:ml-1 ${productCatalog ? 'flex-row' : 'flex-row'}`}
            >
              <div>
                <div
                  className={`box-border flex w-[9.8rem] items-center lg:w-[11.5rem] xl:w-[12.5rem] ${productCatalog ? 'justify-start' : 'justify-center'} xl:justify-start xl:pr-3`}
                  id="logo"
                  onMouseEnter={handleTooltipOpen}
                  onMouseLeave={handleTooltipClose}
                >
                  <Link to="/home" onClick={handleTooltipOpen}>
                    <img
                      src={Logo}
                      alt="Logo"
                      onMouseEnter={handleTooltipOpen}
                      onMouseLeave={handleTooltipClose}
                      className={`box-border h-[5.7rem]`}
                    />
                  </Link>
                  {/* <ArrowDropDownIcon
                  className="-ml-2 text-zinc-800 opacity-0"
                  fontSize="large"
                /> */}
                </div>
              </div>

              <div className="ml-2 flex w-full sm:w-auto items-center justify-between sm:justify-center gap-2">
                {productCatalog && (
                  <>
                    <ToolTipCustom title="Voltar">
                      <div
                        onClick={
                          handleBackButtonClick
                            ? handleBackButtonClick
                            : handleReturnPage
                        }
                      >
                        <ArrowBackIosIcon
                          className="hover:text-yellow-500"
                          fontSize="medium"
                        />
                      </div>
                    </ToolTipCustom>
                    {menuIcon && (
                      <ToolTipCustom title="Menu">
                        <div onClick={toggleMenu}>
                          <MenuIcon
                            fontSize="large"
                            className="hover:text-[#eab308]"
                          />
                        </div>
                      </ToolTipCustom>
                    )}
                    <div
                      className="block lg:hidden"
                      onClick={onCLickSearchIcon}
                    >
                      {openSearch ? (
                        <SearchOffIcon
                          style={{
                            fontSize: '2rem',
                          }}
                          className="hover:text-[#eab308]"
                        />
                      ) : (
                        <SearchIcon
                          style={{
                            fontSize: '2rem',
                          }}
                          className="hover:text-[#eab308]"
                        />
                      )}
                    </div>
                  </>
                )}
              </div>

              {title && (
                <div
                  className={`ml-3 mr-3 hidden items-center justify-center font-semibold sm:flex sm:text-lg md:text-2xl`}
                >
                  {title}
                  <span className="text-yellow-500">.</span>
                </div>
              )}

              <div
                className={`flex w-full items-center justify-end sm:pb-4 md:justify-evenly ${productCatalog ? 'hidden sm:flex' : 'xl:hidden'}`}
              >
                {productCatalog && (
                  <div
                    className={`hidden w-full items-center justify-center pt-1 sm:flex sm:flex-row sm:items-center md:justify-center md:gap-3 xl:justify-center xl:gap-0`}
                  >
                    <div className="flex items-start justify-center gap-4">
                      {productsDetail}
                    </div>
                  </div>
                )}
                <div
                  className={`absolute right-8 top-8 flex items-center justify-center ${productCatalog ? 'hidden' : 'xl:hidden'}`}
                  data-isOpen={menuOpen}
                >
                  <MenuIcon
                    onClick={toggleMenu}
                    fontSize="large"
                    className="hover:text-[#eab308]"
                  />
                </div>
              </div>

              <div
                className={`w-5/6 flex-col items-start justify-center ${productCatalog ? 'hidden' : 'hidden xl:flex'}`}
              >
                <div
                  className={`lg:w-22 ${searchBar ? 'items-start' : 'items-center'} mb-2 mt-2 box-border w-full flex-col justify-start xl:flex xl:flex-row 2xl:w-full`}
                >
                  <nav className="box-border xl:pr-5 3xl:ml-10">
                    <ul className="box-border flex flex-col items-center justify-between space-y-4 xl:flex-row xl:space-y-0">
                      <li className="box-border">
                        <Link
                          to="/sobre"
                          className={`${
                            location.pathname === '/sobre'
                              ? 'link-header link-header-select'
                              : 'link-header link-header-not-select'
                          }`}
                        >
                          Sobre
                        </Link>
                      </li>
                      <li className="box-border">
                        <Link
                          to="/produtos"
                          className={`${
                            location.pathname === '/produtos' ||
                            isCatalogoPecasBuffalo ||
                            isDetalhes ||
                            isCompatibilidade
                              ? 'link-header link-header-select'
                              : 'link-header link-header-not-select'
                          }`}
                        >
                          Produtos
                        </Link>
                      </li>
                      <li className="box-border">
                        <Link
                          to="/buffaloNews"
                          className={`${
                            location.pathname === '/buffaloNews'
                              ? 'link-header link-header-select'
                              : 'link-header link-header-not-select'
                          }`}
                        >
                          Buffalo News
                        </Link>
                      </li>
                      <li className="box-border">
                        <Link
                          to="/assistencia"
                          className={`${
                            location.pathname === '/assistencia'
                              ? 'link-header link-header-select'
                              : 'link-header link-header-not-select'
                          }`}
                        >
                          Assistência
                        </Link>
                      </li>
                      <li className="box-border">
                        <Link
                          to="/representantes"
                          className={`${
                            location.pathname === '/representantes'
                              ? 'link-header link-header-select'
                              : 'link-header link-header-not-select'
                          }`}
                        >
                          Representantes
                        </Link>
                      </li>
                      <li className="box-border">
                        <Link
                          to="/contato"
                          className={`${
                            location.pathname === '/contato'
                              ? 'link-header link-header-select'
                              : 'link-header link-header-not-select'
                          }`}
                        >
                          Fale Conosco
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              {/* {searchBar && (
                <div
                  data-isOpen={openSearch}
                  className="transition-medium fixed left-0 -z-20 w-full pl-2 opacity-100 data-[isOpen=false]:top-12 data-[isOpen=true]:top-[6.4rem] data-[isOpen=false]:opacity-0 lg:relative lg:z-50 lg:max-w-[30rem] data-[isOpen=false]:lg:top-5 data-[isOpen=true]:lg:top-5 data-[isOpen=false]:lg:opacity-100"
                >
                  <SearchBar
                    ref={ref}
                    searchUseHandleTable={handleSearchInList}
                    placeholderInput="Encontre sua peça"
                  />
                </div>
              )} */}
              {searchParts && (
                <div
                  data-isOpen={openSearch}
                  className={`transition-medium fixed left-0 top-[6rem] -z-20 w-full pl-2 pr-2 data-[isOpen=false]:-left-[70rem] data-[isOpen=true]:left-0 data-[isOpen=false]:opacity-0 lg:relative lg:top-5 lg:z-20 lg:max-w-[30rem] lg:pr-2 data-[isOpen=false]:lg:left-auto data-[isOpen=true]:lg:left-auto data-[isOpen=true]:lg:right-0 data-[isOpen=false]:lg:opacity-100 data-[isOpen=true]:lg:opacity-100`}
                >
                  {searchParts}
                </div>
              )}
            </div>
          </div>
        </header>
        <div
          className={`transition-medium fixed top-[5.4rem] z-30 data-[isOpen=false]:-top-56  lg:top-[5.7rem] sm:data-[isOpen=false]:-top-56 ${productCatalog ? 'xl:block' : 'xl:hidden'} `}
          data-isOpen={menuOpen}
        >
          <MenuMobile
            closeMenu={toggleMenu}
            locationPathname={`${location.pathname}`}
            isCatalogoPecasBuffalo={isCatalogoPecasBuffalo}
            isCompatibilidade={isCompatibilidade}
            isDetalhes={isDetalhes}
          />
        </div>
      </>
    );
  },
);

export default Header;
