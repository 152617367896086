import { useMutation, useQuery } from 'react-query';
import { domainCache } from '../../constants/requestCacheName';
import { updateEmailTriggerDomainVinculation } from '../../services/emailTrigger';
import {
  EmailTrigger,
  VinculateEmailTriggerInDomain,
} from '../../types/emailTrigger';
import { useHandleTable } from '../../hooks/useHandleTable';
import { getDomainsPaginated } from '../../services/domain';
import { Domain, FindAllDomainsPaginated } from '../../types/domain';
import { useEffect, useState } from 'react';
import { useEdit } from '../../hooks/useEdit';
import { mutationErrorHandling } from '../../utils/errorHandling';
import { useError } from '../../hooks/useError';
import { emailTriggerSearchBaseEndpoint } from '../../constants/endpoints';

export const useEmailTriggerContent = () => {
  //Marcações antigas
  const [originalEmailListDomains, setOriginalEmailListDomains] = useState<
    VinculateEmailTriggerInDomain[]
  >([]);

  //Marcações novas
  const [newCheckedState, setNewCheckedState] = useState<
    VinculateEmailTriggerInDomain[]
  >([]);

  //Junção de ambas
  const [checkEmailDomainState, setCheckEmailDomainState] = useState<
    VinculateEmailTriggerInDomain[]
  >([]);

  const { canEdit, handleEdit, handleUnedit } = useEdit();
  const { errorMessage, clearError, setErrorMessage } = useError();
  const [domainWidth, setDomainWidth] = useState<number>();

  // const { data: emailTriggerList, isFetching: isEmailTriggerFetching } =
  //   useQuery<FindAllEmailTriggerList>(
  //     emailTriggerCache,
  //     async () => (await getAllEmailTriggerList()).data,
  //     {
  //       retry: false,
  //       refetchOnWindowFocus: false,

  //       onSuccess: (data) => {
  //         const emailListChecked: VinculateEmailTriggerInDomain[][] =
  //           data?.items.map((email) => {
  //             const checked: VinculateEmailTriggerInDomain[] =
  //               email.domains?.map((domain) => ({
  //                 domainId: domain.id,
  //                 emailId: email.id,
  //                 isCheck: true,
  //               })) || [];

  //             return checked;
  //           });

  //         if (emailListChecked) {
  //           setNewCheckedState(emailListChecked.flat());
  //           setOriginalEmailListDomains(emailListChecked.flat());
  //         }
  //       },
  //     },
  //   );

  const { data: domains } = useQuery<FindAllDomainsPaginated>(
    domainCache,
    async () => (await getDomainsPaginated()).data,
    {
      onSuccess: () => {
        const maxWidth = (domains?.items ?? []).reduce((maxWidth, row) => {
          const width = row.name?.toString().length || 0;
          return width > maxWidth ? width : maxWidth;
        }, 0);
        setDomainWidth((maxWidth > 32 ? maxWidth + 2 : 32) / 2);
      },

      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const {
    itemsShown,
    isSearchInputDirty,
    inputRef,
    sortField,
    itemsShownLength: emailTriggerListShownLength,
    itemsLength: emailTriggerListLength,
    searchItems,
    isSearchFetching: isEmailTriggerFetching,
    handleSearchDatabase,
    handleEnterClickSearchInput,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
  } = useHandleTable(0, [], null, null, emailTriggerSearchBaseEndpoint);

  const emailTriggerListShown: EmailTrigger[] = itemsShown;
  const emailTriggerList = searchItems;

  useEffect(() => {
    const emailListChecked: VinculateEmailTriggerInDomain[][] =
    emailTriggerList?.items.map((email) => {
              const checked: VinculateEmailTriggerInDomain[] =
                email.domains?.map((domain: Domain) => ({
                  domainId: domain.id,
                  emailId: email.id,
                  isCheck: true,
                })) || [];

              return checked;
            }) || [];

          if (emailListChecked.length > 0) {
            setNewCheckedState(emailListChecked.flat());
            setOriginalEmailListDomains(emailListChecked.flat());
          }
  }, [emailTriggerList])



  const handleCheckDomain = (
    isCheck: boolean,
    emailId: number,
    domainId: number,
  ) => {
    setNewCheckedState((prevState) => {
      const updatedState = prevState
        .filter((item) => item.domainId !== domainId)
        .concat(isCheck ? { domainId, emailId, isCheck: true } : []);

      return updatedState;
    });
  };

  const verifyCheckedStatesOfEmailDomains = () => {
    const changedOldStates = originalEmailListDomains
      ?.filter(
        (oldState) =>
          !newCheckedState?.some(
            (newState) => oldState.domainId === newState.domainId,
          ),
      )
      .map((filteredState) => ({
        ...filteredState,
        isCheck: false,
      }));

    const combinedStates = [...changedOldStates, ...newCheckedState];
    setCheckEmailDomainState(combinedStates);
    return combinedStates;
  };

  const [isStateUpdated, setIsStateUpdated] = useState(false);

  const handleButtonOkClick = () => {
    if (canEdit) {
      const checkedAndUncheckedItens = verifyCheckedStatesOfEmailDomains();
      setCheckEmailDomainState(checkedAndUncheckedItens);
      setIsStateUpdated(true);
      return;
    }
    handleEdit();
  };

  useEffect(() => {
    if (isStateUpdated) {
      if (checkEmailDomainState.length > 0) {
        updateEmailTriggerDomainVinculationMutate();
        handleUnedit();
      }
      setIsStateUpdated(false);
    }
  }, [isStateUpdated, checkEmailDomainState]);

  const { mutate: updateEmailTriggerDomainVinculationMutate } = useMutation({
    mutationFn: async () => {
      const areEqual = checkIfAreEqual();
      if (!areEqual) {
        return (
          await updateEmailTriggerDomainVinculation(checkEmailDomainState)
        ).data;
      }
    },

    onSuccess: () => {
      setOriginalEmailListDomains([...checkEmailDomainState]);
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao vincular ou desvincular email ao domínio',
        setErrorMessage,
      );
    },
  });

  const checkIfAreEqual = () => {
    const isEqual = checkEmailDomainState?.every((item) =>
      originalEmailListDomains?.some(
        (otherItem) =>
          otherItem.emailId === item.emailId &&
          otherItem.domainId === item.domainId &&
          otherItem.isCheck === item.isCheck,
      ),
    );

    return isEqual;
  };

  const handleButtonCancelEditClick = () => {
    if (canEdit) {
      setNewCheckedState([...originalEmailListDomains]);
      handleUnedit();
    }
  };

  return {
    domainWidth,
    emailTriggerList,
    isFetching: isEmailTriggerFetching,
    inputRef,
    isSearchInputDirty,
    emailTriggerListShown,
    sortField,
    domains,
    emailTriggerListLength,
    emailTriggerListShownLength,
    newCheckedState,
    canEdit,
    errorMessage,
    handleSearchDatabase,
    handleEnterClickSearchInput,
    handleButtonCancelEditClick,
    clearError,
    handleSortTable,
    handleSearch,
    handleCancelSearch,
    handleCheckDomain,
    handleButtonOkClick,
  };
};
