import React, { useState, useRef, useEffect, ComponentProps } from 'react';
import { Link, To } from 'react-router-dom';
import { cn } from '../../libs/utils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export type DropdownItem = {
  label: string;
  to: To;
};

export type AnimatedDropdownProps = ComponentProps<'div'> & {
  label: string;
  items: DropdownItem[];
  readOnly?: boolean;
  labelProps?: ComponentProps<'span'>;
  containerProps?: ComponentProps<'div'>;
  itemsContainerProps?: ComponentProps<'div'>;
  onItemClick?: (item: DropdownItem) => void;
};

export function AnimatedDropdown({
  label,
  items,
  className,
  labelProps,
  containerProps,
  readOnly,
  children,
  itemsContainerProps,
  onItemClick,
  ...rest
}: AnimatedDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!readOnly) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [readOnly]);

  return (
    <div
      className={cn('relative z-30', className)}
      ref={dropdownRef}
      onMouseLeave={() => !readOnly && setIsOpen(false)}
      {...rest}
    >
      <div
        {...containerProps}
        className={cn(
          'flex h-full items-center px-4 py-2',
          containerProps?.className,
        )}
      >
        <span
          {...labelProps}
          className={cn(
            'cursor-pointer rounded transition-colors duration-200',
            labelProps?.className,
          )}
          onMouseEnter={() => !readOnly && setIsOpen(true)}
        >
          {label}
        </span>
        {/* <ArrowDropDownIcon
          onMouseEnter={() => !readOnly && setIsOpen(true)}
          className="cursor-pointer"
        /> */}
      </div>

      <div
        {...itemsContainerProps}
        className={cn(
          `absolute left-0 mx-auto w-full rounded bg-zinc-100`,
          `${isOpen ? 'visible opacity-100' : 'invisible opacity-0'} `,
          itemsContainerProps?.className,
        )}
        // className={cn(
        //   `transition-fast absolute left-0 mx-auto mt-1 w-full rounded-md bg-zinc-100 shadow-lg ring-1 ring-black ring-opacity-5`,
        //   `${isOpen ? 'visible translate-y-0 opacity-100' : 'invisible -translate-y-2 opacity-0'} `,
        // )}
        onMouseEnter={() => !readOnly && setIsOpen(true)}
      >
        <div
          className="py-1"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {items.map((item, index) => (
            <Link
              onClick={() => !readOnly && onItemClick && onItemClick(item)}
              key={index}
              to={item.to}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 hover:text-gray-900"
            >
              {item.label}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
