import { Box, SxProps, Theme } from '@mui/material';
import { useState, ComponentProps, SyntheticEvent, useEffect } from 'react';
import { cardImageStyle, imageContainerStyle } from './styles';

type CardImageProps = ComponentProps<'img'> & {
  src: string;
  fallbackSrc: string;
  alt?: string;
  fallbackAlt?: string;
  containerStyle?: SxProps<Theme>;
  style?: React.CSSProperties;
  fallbackStyle?: React.CSSProperties;
};

export default function CardImg({
  src,
  fallbackSrc,
  alt,
  fallbackAlt,
  containerStyle = {},
  style,
  fallbackStyle,
  className,
  ...rest
}: CardImageProps) {
  const [imageSrc, setImageSrc] = useState(src);

  const handleImageError = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    setImageSrc(fallbackSrc);
  };

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  const appliedStyle = imageSrc === fallbackSrc ? fallbackStyle : style;

  return (
    <Box sx={{ ...imageContainerStyle, ...containerStyle } as SxProps<Theme>}>
      <img
        src={imageSrc}
        alt={imageSrc === fallbackSrc ? fallbackAlt : alt}
        onError={handleImageError}
        style={{
          ...cardImageStyle,
          ...appliedStyle,
        }}
        className={className}
        {...rest}
      />
    </Box>
  );
}
