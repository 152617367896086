import { useQuery } from 'react-query';
import {
  contactsApiCache,
  domainCache,
  subjectsCache,
} from '../../constants/requestCacheName';
import { ContactDemandPaginated, ContactUs } from '../../types/contactUs';
import { useHandleTable } from '../../hooks/useHandleTable';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getContactDemandsPaginated } from '../../services/contactUs';
import { contactsSearchBaseEndpoint } from '../../constants/endpoints';
import { Search, search } from '../../services/search';
import { applySortAfterFilter } from '../../utils/sort';
import { useSearchParams } from '../../hooks/useSearchParams';
import { ContactStatus } from '../../enums/contactStatus';
import { FindAllDomainsPaginated } from '../../types/domain';
import { getDomainsPaginated } from '../../services/domain';
import { FindAllSubjects } from '../../types/subject';
import { getAllSubjects } from '../../services/subject';

export const useContactContent = () => {
  const [contactFilter] = useSearchParams('status') as [ContactStatus];
  const [domainFilter] = useSearchParams('domain');
  const [subjectFilter] = useSearchParams('subject');

  const navigate = useNavigate();

  const [domainWidth, setDomainWidth] = useState<number>();

  const { data: subjects } = useQuery<FindAllSubjects>(
    subjectsCache,
    async () => (await getAllSubjects()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: domains } = useQuery<FindAllDomainsPaginated>(
    domainCache,
    async () => (await getDomainsPaginated()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const maxWidth = (domains?.items ?? []).reduce((maxWidth, row) => {
          const width = row.name?.toString().length || 0;
          return width > maxWidth ? width : maxWidth;
        }, 0);
        setDomainWidth((maxWidth > 32 ? maxWidth + 2 : 32) / 2);
      },
    },
  );

  const {
    data: contacts,
    isFetching: isFetchingContacts,
    refetch: refetchContactsPaginated,
  } = useQuery<ContactDemandPaginated>(
    contactsApiCache,
    async () =>
      (
        await getContactDemandsPaginated(
          currentPage,
          'DESC',
          null,
          contactFilter,
          domainFilter,
          subjectFilter,
        )
      ).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data) => {
        applySortAfterFilter(data.items, currentItemsSort, sortField);
        handleCancelSearch();
      },
    },
  );

  const loadMore = useCallback(
    async (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      const response = await getContactDemandsPaginated(
        page,
        'DESC',
        limit,
        contactFilter,
        domainFilter,
        subjectFilter,
      );
      return response;
    },
    [contactFilter, domainFilter, subjectFilter],
  );

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return search<ContactUs>(baseUrl, searchValue, page, 'DESC', null, [
        { name: 'status', value: contactFilter },
        { name: 'domain', value: domainFilter },
        { name: 'subject', value: subjectFilter },
      ]);
    },
    [contactFilter, domainFilter, subjectFilter],
  );

  const {
    sortField,
    currentPage,
    inputRef,
    itemsLength: contactsLength,
    itemsShown,
    itemsShownLength: contactsShownLength,
    isLoadMoreFetching,
    endPageRef,
    isSearchInputDirty,
    currentItemsSort,
    defineItemsShown,
    handleCancelSearch,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch,
    handleSortTable,
  } = useHandleTable(
    contacts?.meta.totalItems ?? 0,
    contacts?.items ?? [],
    refetchContactsPaginated,
    null,
    contactsSearchBaseEndpoint,
    loadMore,
    null,
    doSearch as Search,
  );

  const contactsShown: ContactUs[] = itemsShown;
  useEffect(() => {
    refetchContactsPaginated();
  }, [contactFilter, refetchContactsPaginated, domainFilter, subjectFilter]);

  useEffect(() => {
    if (!contactFilter) {
      navigate({ search: `status=PENDENTE` }, { replace: true });
    }
  }, [contactFilter, navigate]);

  // useEffect(() => {
  //   if (contacts?.items) {
  //     defineItemsShown(contacts.items);
  //   }
  // }, [defineItemsShown, contacts?.items]);

  const currentParams = new URLSearchParams(window.location.search);

  const handleFilterByType = (filterName: string) => {
    currentParams.set('status', filterName);
    navigate({ search: currentParams.toString() }, { replace: true });
  };

  const handleFilterByDomain = (filterName: string) => {
    if (filterName === 'Todos') {
      currentParams.delete('domain');
      navigate({ search: currentParams.toString() }, { replace: true });
      return;
    }

    currentParams.set('domain', filterName.toString());
    navigate({ search: currentParams.toString() }, { replace: true });
  };

  const handleFilterBySubject = (filterName: string) => {
    if (filterName === 'Todos') {
      currentParams.delete('subject');
      navigate({ search: currentParams.toString() }, { replace: true });
      return;
    }

    currentParams.set('subject', filterName.toString());
    navigate({ search: currentParams.toString() }, { replace: true });
  };

  return {
    sortField,
    inputRef,
    contactsShownLength,
    isLoadMoreFetching,
    endPageRef,
    domainFilter,
    isSearchInputDirty,
    contactsShown,
    domains,
    isFetchingContacts,
    contactFilter,
    contactsLength,
    contacts,
    subjects,
    subjectFilter,
    handleFilterBySubject,
    handleSearchDatabase,
    handleFilterByDomain,
    handleCancelSearch,
    handleFilterByType,
    handleEnterClickSearchInput,
    handleSearch,
    handleSortTable,
  };
};
