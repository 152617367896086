import {
  FindAllPartsGroup,
  PartsGroupPaginated,
  SavePartsGroupDto,
  UpdatePartsGroupDto,
} from '../types/partsGroup';
import generatePaginatedUrl from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getAllPartsGroup = async () => {
  const response = await api.get<FindAllPartsGroup>('/parts-group/v1/find-all');
  return response;
};

export const getPartsGroupPaginated = async (
  page: number,
  direction?: 'ASC' | 'DESC',
  limit?: number,
) => {
  const url = generatePaginatedUrl('/parts-group/v1', page, limit, direction);
  const response = await api.get<PartsGroupPaginated>(url);
  return response;
};

export const savePartsGoup = async ({
  descriptionId,
  image,
  name,
  totalParts,
}: SavePartsGroupDto) => {
  const body = {
    descriptionId,
    image,
    name,
    totalParts: totalParts ?? null,
  };

  const response = await api.post('/parts-group/v1', body);
  return response;
};

export const updatePartsGoup = async ({
  id,
  descriptionId,
  image,
  name,
  totalParts,
}: UpdatePartsGroupDto) => {
  const body = {
    id,
    descriptionId,
    image,
    name,
    totalParts: totalParts ?? null,
  };

  const response = await api.put('/parts-group/v1', body);
  return response;
};

export const deletePartsGroupById = async (partsGroupId: number) => {
  const response = await api.delete(`/parts-group/v1/${partsGroupId}`);
  return response;
};
