import { ComponentProps } from 'react';
import { Domain } from '../../types/domain';
import { EmailSignature } from '../../types/emailSignature';
import { twMerge } from 'tailwind-merge';
import { QRCodeSVG } from 'qrcode.react';
import { useEmailSignatureItem } from './useEmailSignatureItem';

export type SetIsLoading = (isLoading: boolean) => void;

type EmailSignatureItemProps = ComponentProps<'div'> & {
  domain: Domain;
  signature: EmailSignature;
  width?: number;
  height?: number;
  setIsLoading?: SetIsLoading;
};

export default function EmailSignatureItem({
  domain,
  signature,
  width = 640,
  height = 209,
  setIsLoading,
  className,
  ...rest
}: EmailSignatureItemProps) {
  const { vCard, fieldsDomainCoordinates, signatureMapper, qrCodeAlignMapper } =
    useEmailSignatureItem(signature, domain, setIsLoading);

  return (
    <div
      className={twMerge(`relative`, className)}
      style={{ width: `${640}px`, height: `${209}px` }}
      {...rest}
    >
      <img
        src={domain.emailSignatureImage}
        alt="Imagem de fundo do domínio"
        width={width}
        height={height}
      />
      {fieldsDomainCoordinates.map((coordinate) => (
        <div
          key={coordinate.field.id}
          className="absolute "
          style={{
            textAlign: coordinate.textAlign,
            width: coordinate.width,
            height: coordinate.height,
            left: coordinate.x,
            top: coordinate.y,
          }}
        >
          <span
            style={{
              color: coordinate?.color,
              fontSize: coordinate?.fontSize,
              fontStyle: coordinate?.fontStyle,
              fontWeight: coordinate?.bold,
              textDecoration: coordinate?.textDecoration,
              justifyContent: qrCodeAlignMapper(coordinate.textAlign),
            }}
            className="flex"
          >
            {signatureMapper(
              coordinate.field.id,
              <QRCodeSVG value={vCard} size={coordinate?.fontSize} />,
            )}
          </span>
        </div>
      ))}
      {/* <div className="flex">
        <div className={`leading-[calc(0.6rem)]`}>
          <h2
            className={`absolute left-[36%] top-[36%] w-[24%] text-[calc(12px)] font-bold leading-3`}
          >
            {signature.fullName}
          </h2>
          <span
            className={`absolute left-[36%] top-[48%] w-[24%] text-[calc(8px)]`}
          >
            {signature.department}
          </span>
        </div>
        <div className={`text-[calc(8px)]`}>
          <div className="flex flex-col">
            <span className="absolute left-[65%] top-[31%] w-[17%]">
              {formatPhoneNumber(signature.cellPhoneNumber)}
            </span>
            <span className="absolute left-[65%] top-[36%] w-[17%]">
              {formatPhoneNumber(signature.phoneNumber)}
            </span>
          </div>
          <span className="absolute left-[65%] top-[46%] w-[17%]">
            {signature.email}
          </span>
          <span className="absolute left-[65%] top-[58%] w-[29%]">
            {domain.street}, nº {domain.number} - {domain.neighborhood},{' '}
            {domain.city} - {domain.uf}, {formatCep(domain.cep ?? '')}
          </span>
          <span className="absolute left-[40%] top-[70%] w-[29%]">
            <QRCodeSVG value={vCard} size={50} />
          </span>
        </div>
      </div> */}
    </div>
  );
}
