import {
  ReorderSizingDto,
  Sizing,
  SizingListSort,
  SizingSort,
} from '../../types/sizing';
import { useMutation, useQuery } from 'react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getTableColumnWidthByBiggestElement } from '../../utils/tableColumnStyles';
import formatSearch from '../../utils/formatSearch';
import { LoadMore, useHandleTable } from '../../hooks/useHandleTable';
import { VinculateSizingInDomain } from '../../types/sizingDomain';
import { FindAllDomainsPaginated } from '../../types/domain';
import { domainCache } from '../../constants/requestCacheName';
import { getDomainsPaginated } from '../../services/domain';
import { useEdit } from '../../hooks/useEdit';
import { DomainVinculation } from '../../types/products';
import { DropResult } from '@hello-pangea/dnd';
import { reorderList } from '../../utils/dragDrop';
import { mutationErrorHandling } from '../../utils/errorHandling';
import { useError } from '../../hooks/useError';
import { getAllSizingsPaginated, reorderSizing } from '../../services/sizings';
import { useSearchParams } from '../../hooks/useSearchParams';
import { useNavigate } from 'react-router-dom';
import { updateSizingDomainVinculation } from '../../services/sizings-domain';
import { sizingSearchBaseEndpoint } from '../../constants/endpoints';
import { Search, search as searchDatabase } from '../../services/search';

type DomainFilter = 'Todos' | string;

export const useSizingContent = () => {
  const [domainFilter] = useSearchParams('f') as [DomainFilter];
  const navigate = useNavigate();
  // const { data: sizings, refetch: refetchSizings } = useQuery<SizingListSort>(
  //   sizingCache,
  //   async () => (await getAllSizingsPaginated()).data,
  //   {
  //     retry: true,
  //     refetchOnWindowFocus: false,

  //     onSuccess: (data) => {
  //       const checkSizingList: VinculateSizingInDomain[][] = data?.items.map(
  //         (sizing) => {
  //           const checked: VinculateSizingInDomain[] = sizing.domains?.map(
  //             (domain) => ({
  //               domainId: domain.id,
  //               sizingId: sizing.id,
  //               isCheck: true,
  //             }),
  //           );

  //           return checked;
  //         },
  //       );

  //       if (checkSizingList) {
  //         setCheckSizingListDomains(checkSizingList.flat());
  //         setOriginalSizingListDomains(checkSizingList.flat());
  //       }
  //     },
  //   },
  // );

  const { data: domains } = useQuery<FindAllDomainsPaginated>(
    domainCache,
    async () => (await getDomainsPaginated()).data,
    {
      onSuccess: (data) => {
        const maxWidth = (domains?.items ?? []).reduce((maxWidth, row) => {
          const width = row.name?.toString().length || 0;
          return width > maxWidth ? width : maxWidth;
        }, 0);
        setDomainWidth((maxWidth > 32 ? maxWidth + 2 : 32) / 2);
      },
    },
  );

  const isSearch = useRef(false);
  const endPageRef = useRef<HTMLDivElement>(null);
  const [checkSizingListDomains, setCheckSizingListDomains] = useState<
    VinculateSizingInDomain[]
  >([]);

  const [originalSizingListDomains, setOriginalSizingListDomains] = useState<
    VinculateSizingInDomain[]
  >([]);

  const sizingListChanged = useRef<VinculateSizingInDomain[]>([]);

  const [domainWidth, setDomainWidth] = useState<number>();
  const [showEditContent] = useState(false);
  const sizingIdUpdate = useRef<number | null>(null);
  const reorderedTableUpdate = useRef<ReorderSizingDto[] | null>(null);
  const { setErrorMessage } = useError();

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return searchDatabase<Sizing>(
        baseUrl,
        searchValue,
        page,
        null,
        null,
        domainFilter ? [{ name: 'domain', value: domainFilter }] : undefined,
      );
    },
    [domainFilter],
  );

  const loadMore = useCallback(
    async (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      return await getAllSizingsPaginated(page, direction, limit, domainFilter);
    },
    [domainFilter],
  );

  const {
    sortField,
    inputRef,
    isSearchInputDirty,
    itemsLength: sizingsLength,
    itemsShownLength: sizingsShownLength,
    itemsShown,
    isSearchImageFetching,
    isLoadMoreFetching,
    searchItems,
    refetchSearch: refetchSizings,
    handleSearch: search,
    handleFilter: filter,
    handleSortTable,
    handleEnterClickSearchInput,
    handleCancelSearch,
    handleSearchDatabase: handleSearchInAllSizings,
    defineItemsShown,
    handleReset,
  } = useHandleTable(
    0,
    [],
    null,
    null,
    sizingSearchBaseEndpoint,
    loadMore as LoadMore,
    null,
    doSearch as Search,
  );

  const sizings = searchItems?.items as SizingSort[];

  useEffect(() => {
    refetchSizings();
  }, [domainFilter, refetchSizings]);

  useEffect(() => {
    const checkSizingList: VinculateSizingInDomain[][] = sizings?.map(
      (sizing) => {
        const checked: VinculateSizingInDomain[] = sizing.domains?.map(
          (domain) => ({
            domainId: domain.id,
            sizingId: sizing.id,
            isCheck: true,
          }),
        );

        return checked;
      },
    );

    if (checkSizingList) {
      setCheckSizingListDomains(checkSizingList.flat());
      setOriginalSizingListDomains(checkSizingList.flat());
    }
  }, [sizings]);

  const {
    canEdit: canEditOrder,
    handleEdit: handleEditOrder,
    handleUnedit: handleUneditOrder,
  } = useEdit();

  const typeColWidth = useMemo(
    () =>
      getTableColumnWidthByBiggestElement<Sizing>(sizings ?? [], 'type', 10),
    [sizings],
  );

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const sizingsShown: SizingSort[] = itemsShown;

  const handleSearch = () => {
    search<Sizing>((item, formatedSearch) => {
      return (
        formatSearch(item.name).includes(formatedSearch) ||
        formatSearch(item.id.toString()).includes(formatedSearch)
      );
    });
  };

  const verifyDomainsPartialyChecked = (domainId: number) => {
    const checkedSizingDomainsByDomainId = checkSizingListDomains.filter(
      (sizingDomain) =>
        sizingDomain?.domainId === domainId && sizingDomain.isCheck,
    );

    return (
      checkedSizingDomainsByDomainId.length > 0 &&
      checkedSizingDomainsByDomainId.length < (sizings?.length ?? 0)
    );
  };

  const handleToggleCheckDomains = (domainId: number, isCheck: boolean) => {
    const sizingDomainsChecked: VinculateSizingInDomain[] = [];

    const sizingDomainWithotDomainIdSelected = checkSizingListDomains.filter(
      (sizingDomain) => sizingDomain?.domainId !== domainId,
    );

    sizings.forEach((sizing) => {
      sizingDomainsChecked.push({ sizingId: sizing.id, domainId, isCheck });
    });

    sizingListChanged.current = [
      ...sizingDomainWithotDomainIdSelected,
      ...sizingDomainsChecked,
    ];

    setCheckSizingListDomains([
      ...sizingDomainWithotDomainIdSelected,
      ...sizingDomainsChecked,
    ]);
  };

  const verifyAllDomainsChecked = useCallback(
    (domainId: number) => {
      const checkedSizingDomainsByDomainId = checkSizingListDomains.filter(
        (sizingDomain) =>
          sizingDomain?.domainId === domainId && sizingDomain.isCheck,
      );

      if (checkedSizingDomainsByDomainId.length === (sizings?.length ?? 0)) {
        return true;
      }

      return false;
    },
    [sizings?.length, checkSizingListDomains],
  );

  const nameColWidth = useMemo(
    () =>
      getTableColumnWidthByBiggestElement<SizingSort>(
        sizingsShown ?? [],
        'name',
        10,
      ) + 4,
    [sizingsShown],
  );

  const isShowEditButton = !!sizingIdUpdate.current && !showEditContent;

  const handleCheckDomain = (
    isCheck: boolean,
    sizingId: number,
    domainId: number,
    domains: DomainVinculation[],
  ) => {
    const filteredDomains = checkSizingListDomains.filter(
      (domain) => domain?.domainId !== domainId || domain.sizingId !== sizingId,
    );
    const sizingListDomainsWithoutSelected = sizingListChanged.current.filter(
      (domain) => domain.domainId !== domainId || domain.sizingId !== sizingId,
    );

    if (isCheck) {
      sizingListChanged.current = sizingListDomainsWithoutSelected;
      sizingListChanged.current.push({ domainId, sizingId, isCheck: true });
      filteredDomains.push({ domainId, sizingId, isCheck: true });
      setCheckSizingListDomains(filteredDomains);
      return;
    }

    sizingListChanged.current = sizingListDomainsWithoutSelected;
    sizingListChanged.current.push({ domainId, sizingId, isCheck: false });
    filteredDomains.push({ domainId, sizingId, isCheck: false });
    setCheckSizingListDomains(filteredDomains);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedTable = reorderList(
      sizingsShown,
      result.source.index,
      result.destination.index,
    );

    reorderedTable.forEach((sizingList, index) => {
      sizingList.order = index + 1;
    });

    reorderedTableUpdate.current = reorderedTable.map((reorderItem) => {
      return {
        sizingId: reorderItem.id,
        order: reorderItem.order,
      };
    });

    defineItemsShown(reorderedTable);
  };

  const { mutate: reorderProductMutate } = useMutation({
    mutationFn: async (dto: ReorderSizingDto[]) => await reorderSizing(dto),

    onError: (error) => {
      mutationErrorHandling(error, 'Falha ao reordenar', setErrorMessage);
    },
  });

  const { mutate: updateSizingDomainVinculationMutate } = useMutation({
    mutationFn: async () => {
      if (sizingListChanged.current.length > 0) {
        return (await updateSizingDomainVinculation(sizingListChanged.current))
          .data;
      }
    },

    onSuccess: () => {
      if (sizingListChanged.current) {
        sizingListChanged.current = [];
      }
      setOriginalSizingListDomains([...checkSizingListDomains]);
      refetchSizings();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao vincular ou desvincular dimensionamento ao domínio',
        setErrorMessage,
      );
    },
  });

  const handleChangeEditOrderButtonClick = () => {
    if (canEditOrder) {
      if (
        reorderedTableUpdate.current &&
        reorderedTableUpdate.current.length > 0
      ) {
        reorderProductMutate(reorderedTableUpdate.current);
      }

      handleUneditOrder();
      return;
    }

    handleReset();
    handleEditOrder();
  };

  const handleButtonOkClick = async () => {
    if (canEdit) {
      if (checkSizingListDomains.length > 0) {
        updateSizingDomainVinculationMutate();
      }
      handleUnedit();
      return;
    }
    handleEdit();
  };

  const handleFilter = (filterName: string) => {
    handleUneditOrder();
    navigate({ search: filterName !== 'Todos' ? `f=${filterName}` : '' });
    filter(filterName);
  };

  const handleButtonCancelEditClick = async () => {
    if (canEdit) {
      setCheckSizingListDomains([...originalSizingListDomains]);
      sizingListChanged.current = [];
      handleUnedit();
    }

    if (canEditOrder) {
      handleReset();
      handleUneditOrder();
    }
  };

  return {
    sortField,
    inputRef,
    isSearchInputDirty,
    sizingsShown,
    sizingsLength,
    sizingsShownLength,
    isSearchImageFetching,
    typeColWidth,
    isSearch: isSearch.current,
    isLoadMoreFetching,
    endPageRef,
    domainWidth,
    domains,
    canEditOrder,
    canEdit,
    showEditContent,
    sizingIdUpdate,
    isShowEditButton,
    nameColWidth,
    checkSizingListDomains,
    domainFilter,
    handleEnterClickSearchInput,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
    handleSearchInAllSizings,
    verifyDomainsPartialyChecked,
    handleToggleCheckDomains,
    verifyAllDomainsChecked,
    handleCheckDomain,
    handleDragEnd,
    handleChangeEditOrderButtonClick,
    handleButtonOkClick,
    handleFilter,
    handleButtonCancelEditClick,
  };
};
