import { useMutation, useQuery } from 'react-query';
import { syncData } from '../../../services/synchronization';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { ReactNode, useEffect, useRef } from 'react';
import { useModal } from '../../../hooks/useModal';
import { socket } from '../../../libs/socketIo';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { getCronInfos } from '../../../services/cron';
import {
  cronInfosCache,
  synchronizationTypeCache,
} from '../../../constants/requestCacheName';
import { CronInfos } from '../../../types/cron';
import { useSynchronizationStore } from '../../../store/synchronization';
import { findAllSynchronizationTypes } from '../../../services/synchronizationType';
import {
  FindAllSynchronizationTypes,
  SynchronizationStatus,
  SynchronizationType,
} from '../../../types/synchronizationType';
import { useQueryCache } from '../../../hooks/useQueryCache';

export const useSynchronization = () => {
  const currentSyncType = useRef<SynchronizationType | null>(null);

  const {
    state: { currentSynchronization },
  } = useSynchronizationStore();

  const { updateItemOnScreen } = useQueryCache();

  const { data: cronInfos } = useQuery<CronInfos>(
    cronInfosCache,
    async () => (await getCronInfos()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: cronSyncData, refetch: refetchCronSyncData } =
    useQuery<FindAllSynchronizationTypes>(
      synchronizationTypeCache,
      async () => (await findAllSynchronizationTypes()).data,
      {
        retry: false,
        refetchOnWindowFocus: false,
      },
    );

  useEffect(() => {
    refetchCronSyncData();
  }, [currentSynchronization, refetchCronSyncData]);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: synchronize } = useMutation({
    mutationFn: (target: string) => syncData(target, socket.id ?? ''),

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao sincronizar os dados',
        setErrorMessage,
      );
    },
  });

  const {
    isModalOpen: isConfirmSyncModalOpen,
    handleCloseModal: closeConfirmSyncModal,
    handleOpenModal: handleOpenConfirmSyncModal,
  } = useModal();

  const {
    sortField,
    inputRef,
    isSearchInputDirty,
    itemsShown,
    itemsLength: tableSyncDataLength,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
  } = useHandleTable(
    cronSyncData?.meta.totalItems ?? 0,
    cronSyncData?.items ?? [],
  );
  const tableSyncDataShown: SynchronizationType[] = itemsShown;

  const handleSynchronize = (syncType: SynchronizationType) => {
    currentSyncType.current = syncType;

    handleOpenConfirmSyncModal();
  };

  const handleConfirmSync = () => {
    if (!currentSyncType.current) return;

    synchronize(currentSyncType.current.baseEndpoint);
    currentSyncType.current.lastSynchronizationStatus = 'PENDENTE';
    updateItemOnScreen<FindAllSynchronizationTypes>(
      synchronizationTypeCache,
      currentSyncType.current,
    );
    handleCloseConfirmSyncModal();
  };

  const handleCloseConfirmSyncModal = () => {
    currentSyncType.current = null;

    closeConfirmSyncModal();
  };

  const getSyncStatus = (
    status: SynchronizationStatus,
    SuccessIcon: ReactNode,
    PendingIcon: ReactNode,
    ErrorIcon: ReactNode,
  ) => {
    const statusMapper: Record<SynchronizationStatus, ReactNode> = {
      ERRO: ErrorIcon,
      PENDENTE: PendingIcon,
      SUCESSO: SuccessIcon,
    };

    return statusMapper[status] ?? 'Não atualizado';
  };

  return {
    sortField,
    inputRef,
    isSearchInputDirty,
    tablesSyncData: tableSyncDataShown,
    tableSyncDataShown,
    tableSyncDataLength,
    isConfirmSyncModalOpen,
    currentTableName: currentSyncType.current?.table,
    errorMessage,
    cronInfos,
    getSyncStatus,
    clearError,
    handleCloseConfirmSyncModal,
    handleConfirmSync,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
    handleSynchronize,
  };
};
