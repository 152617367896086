import { z } from 'zod';
import { regexEmail } from './regex';

const commonFieldSchema = (requiredMessage: string) =>
  z.string({ required_error: requiredMessage }).trim().min(1, requiredMessage);

const subjectSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const contactUsSchema = z.object({
  name: commonFieldSchema('O nome é obrigatório.'),
  email: commonFieldSchema('O email é obrigatório.').regex(
    regexEmail,
    'Email inválido.',
  ),
  phone: z.string().optional(),
  cpf: z.string().refine((cpf) => !cpf || cpf.length === 14, {
    message: 'CPF inválido.',
  }),
  company: z.string().optional(),
  state: z.string().optional(),
  city: z.string().optional(),
  message: commonFieldSchema('A mensagem é obrigatória').min(
    3,
    'A mensagem deve conter ao menos três caracteres.',
  ),
  subject: z
    .array(subjectSchema)
    .min(1, 'É necessário selecionar pelo menos um assunto.'),
});
