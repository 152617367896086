import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHandleTable } from '../../hooks/useHandleTable';
import { getTableColumnWidthByBiggestElement } from '../../utils/tableColumnStyles';
import { useQuery } from 'react-query';
import { documentTypesCache } from '../../constants/requestCacheName';
import { Document } from '../../types/document';
import { Search, search as searchDatabase } from '../../services/search';

import formatSearch from '../../utils/formatSearch';
import { getDocumentTypesPaginated } from '../../services/documentTypes';
import { DocumentTypePaginated } from '../../types/documentTypes';
import { useSearchParams } from '../../hooks/useSearchParams';
import { useNavigate } from 'react-router-dom';
import { DocumentService } from '.';
import { documentsSearchBaseEndpoint } from '../../constants/endpoints';
import { getAllDocumentsPaginated } from '../../services/documents';

type DocumentFilter = 'Todos' | string;

export const useDocumentContent = (
  documentService: DocumentService,
  queryKey: string,
) => {
  const [documentFilter, productFilter, typeDocument] = useSearchParams(
    'documentFilter',
    'f',
    'typeDocument',
  ) as [DocumentFilter, string, string];

  const navigate = useNavigate();

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return searchDatabase<Document>(
        baseUrl,
        searchValue,
        page,
        null,
        null,
        typeDocument
          ? [{ name: 'typeDocument', value: typeDocument }]
          : undefined,
      );
    },
    [typeDocument],
  );

  const { data: documentTypes } = useQuery<DocumentTypePaginated>(
    documentTypesCache,
    async () => (await getDocumentTypesPaginated(1)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const isSearch = useRef(false);
  const endPageRef = useRef<HTMLDivElement>(null);

  const loadMore = useCallback(
    async (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      return await getAllDocumentsPaginated(
        page,
        direction,
        limit,
        typeDocument,
      );
    },
    [typeDocument],
  );

  const {
    sortField,
    inputRef,
    isSearchInputDirty,
    itemsLength: documentsLength,
    itemsShownLength: documentsShownLength,
    itemsShown,
    isSearchImageFetching,
    isLoadMoreFetching,
    refetchSearch,
    handleSearch: search,
    handleSortTable,
    handleEnterClickSearchInput,
    handleCancelSearch,
    handleSearchDatabase: handleSearchInAllDocuments,
  } = useHandleTable(
    0,
    [],
    null,
    null,
    documentsSearchBaseEndpoint,
    loadMore,
    null,
    doSearch as Search,
  );
  const documentsShown: Document[] = itemsShown;

  const typeColWidth = useMemo(
    () =>
      getTableColumnWidthByBiggestElement<Document>(
        itemsShown ?? [],
        'documentType.name',
        10,
      ) + 2,
    [itemsShown],
  );

  const handleFilter = useCallback(
    (filterName: DocumentFilter) => {
      navigate(
        { search: `f=${productFilter}&documentFilter=${filterName}` },
        { replace: true },
      );
    },
    [navigate, productFilter],
  );

  useEffect(() => {
    refetchSearch();
    // return () => {
    //   navigate({ search: `f=${productFilter}`}, { replace: true });
    // };
  }, [refetchSearch, typeDocument]);

  const handleSearch = () => {
    search<Document>((item, formatedSearch) => {
      return (
        formatSearch(item.name).includes(formatedSearch) ||
        formatSearch(item.documentType.name).includes(formatedSearch)
      );
    });
  };

  const currentParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  const handleFilterByTypeDocument = useCallback(
    (filterName: string) => {
      if (filterName === 'todos') {
        currentParams.delete('typeDocument');
        navigate({ search: currentParams.toString() }, { replace: true });
        return;
      }
      currentParams.set('typeDocument', filterName.toString());
      navigate({ search: currentParams.toString() }, { replace: true });
    },
    [currentParams, navigate],
  );

  return {
    sortField,
    inputRef,
    isSearchInputDirty,
    documentsShown,
    documentsLength,
    documentsShownLength,
    isSearchImageFetching,
    typeColWidth,
    isSearch: isSearch.current,
    isLoadMoreFetching,
    endPageRef,
    documentTypes,
    typeDocument,
    filter: documentFilter,
    handleFilter,
    handleEnterClickSearchInput,
    handleSearch,
    handleFilterByTypeDocument,
    handleSortTable,
    handleCancelSearch,
    handleSearchInAllDocuments,
  };
};
