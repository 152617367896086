import { PartsGroupSelected } from '../../types/partsGroup';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import ListingHeaderModal from '../ListingHeaderModal';
import Loading from '../Loading';
import LoadMore from '../LoadMore';
import Main from '../Main';
import SerchInput from '../SearchInput';
import Table from '../Table';
import { usePartsGroupContent } from './usePartsGroupContent';
import CheckIcon from '@mui/icons-material/Check';

type PartsGroupContentProps = {
  isModal?: boolean;
  alreadyInUseList?: string[];
  onTableRowClick: (partsGroupFormData: PartsGroupSelected) => void;
  handleAddPartsGroupClick?: () => void;
};

//TODO Componentizar o footer para reaproveitar os estilos no modal (criar contantes para guardar estilos)

export default function PartsGroupContent({
  isModal,
  alreadyInUseList,
  onTableRowClick,
  handleAddPartsGroupClick,
}: PartsGroupContentProps) {
  const {
    sortField,
    inputRef,
    isSearchInputDirty,
    partsGroupLength,
    partsGroupShown,
    isFetching,
    isSearch,
    endPageRef,
    isLoadMoreFetching,
    lineColWidth,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
  } = usePartsGroupContent();

  return (
    <>
      {isModal && (
        <ListingHeaderModal
          title="Conjunto de peças"
          inputRef={inputRef}
          isSearchInputDirty={isSearchInputDirty}
          onCancelSearch={handleCancelSearch}
          onSearch={handleSearch}
          onButtonClick={handleSearchDatabase}
          onKeyDown={handleEnterClickSearchInput}
        >
          <Table.Head>
            <Table.Cell
              items={partsGroupShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
            >
              Nome
            </Table.Cell>
            <Table.Cell
              items={partsGroupShown}
              type="description.name"
              onSort={handleSortTable}
              isSortActive={sortField === 'description.name'}
              style={{ width: `${lineColWidth}ch` }}
            >
              <span className={`${isModal ? 'pr-14' : ''} w-full`}>
                Descrição
              </span>
            </Table.Cell>
          </Table.Head>
        </ListingHeaderModal>
      )}
      {!isModal && (
        <ListingHeader
          add
          onAddIconClick={handleAddPartsGroupClick}
          pdfTop="3rem"
          pdf={false}
        >
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">Conjunto de peças</h1>
            <SerchInput
              ref={inputRef}
              onSearch={handleSearch}
              showCancelOptions={isSearchInputDirty}
              buttonContent="Buscar"
              onCancelSearch={handleCancelSearch}
              onButtonClick={handleSearchDatabase}
              onKeyDown={handleEnterClickSearchInput}
            />
          </div>
          <Table.Head>
            <Table.Cell
              items={partsGroupShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
            >
              Nome
            </Table.Cell>
            <Table.Cell
              items={partsGroupShown}
              type="description.name"
              onSort={handleSortTable}
              isSortActive={sortField === 'description.name'}
              style={{ width: `${lineColWidth}ch` }}
            >
              Descrição
            </Table.Cell>
          </Table.Head>
        </ListingHeader>
      )}
      <Main isModal={isModal}>
        {isFetching && <Loading loadMore={!isModal} />}
        {!isFetching && partsGroupShown && (
          <Table.Root
            itemType="Conjuntos de peças"
            itemsLength={partsGroupLength}
            paddingTop="0"
            className={`${isModal ? 'mb-32 max-h-[60vh]' : ''}`}
            showNoContentMessage={partsGroupShown.length <= 0 && isSearch}
            showSearchMessage={partsGroupShown.length <= 0 && !isSearch}
          >
            {partsGroupShown.map(
              ({ description, id, image, name, totalItems }) => {
                const isInUse = alreadyInUseList?.some((item) => item === name);

                return (
                  <Table.Row
                    key={id}
                    onClick={() =>
                      !isInUse &&
                      onTableRowClick({
                        id,
                        description: description?.name,
                        image,
                        name,
                        totalParts: totalItems.toString(),
                        descriptionId: description.id,
                      })
                    }
                    className={isInUse ? 'cursor-default opacity-50' : ''}
                  >
                    <Table.Cell items={partsGroupShown} type="name">
                      {name}
                    </Table.Cell>
                    <div className="flex items-center gap-4">
                      <div className="flex items-center gap-4">
                        <Table.Cell
                          items={partsGroupShown}
                          type="desc"
                          style={{ width: `${lineColWidth}ch` }}
                        >
                          {description?.name}
                        </Table.Cell>
                      </div>
                      {isModal && (
                        <Table.Cell
                          items={partsGroupShown}
                          type="desc"
                          className="w-6"
                        >
                          {isInUse && <CheckIcon className="text-primary" />}
                        </Table.Cell>
                      )}
                    </div>
                  </Table.Row>
                );
              },
            )}
            <LoadMore
              isModal={!!isModal}
              isContentFetching={isFetching}
              isLoadMoreFetching={isLoadMoreFetching}
              ref={endPageRef}
            />
          </Table.Root>
        )}
      </Main>

      <Footer
        current={partsGroupShown.length}
        total={partsGroupLength}
        itemType="Conjuntos de peças"
        className={
          isModal
            ? 'absolute mx-[1px] mb-[1px] mt-[-10vh] h-28 rounded-b-3xl'
            : ''
        }
        infosStyle={{ mt: isModal ? '-2rem' : '' }}
        // tableFooter={
        //   pagination <= partsGroupLength && (
        //     <TableFooter
        //       currentPage={currentPage}
        //       itemsLength={partsGroupLength}
        //       itemsShownLength={partsGroupShownLength}
        //       pagination={pagination}
        //       nonPaginationName="Todas"
        //       onChangePage={handleChangePage}
        //       onChangePagination={handleChangePagination}
        //     />
        //   )
        // }
      />
    </>
  );
}
