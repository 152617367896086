import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import Footer from '../../../components/Footer';
import ListingHeaderModal from '../../../components/ListingHeaderModal';
import Modal from '../../../components/Modal';
import PdfContent from '../../../components/PdfContent';
import Table from '../../../components/Table';
import { formatDate } from '../../../utils/formatDate';
import { usePdf } from './usePdf';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export default function Pdf() {
  const {
    linesShown,
    isManageModalOpen,
    inputRef,
    isSearchInputDirty,
    sortField,
    linesLength,
    errorMessage,
    currentLine,
    isConfirmGeneratePdfModalOpen,
    itemsShownLength,
    handleCloseConfirmGeneratePdfModal,
    handleConfirmGeneratePdf,
    clearError,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
    handleGenerateLinePdf,
    handleTableRowClick,
    handleCloseManageModal,
  } = usePdf();

  return (
    <>
      <PdfContent onTableRowClick={handleTableRowClick} />

      <Modal
        isOpen={isManageModalOpen}
        onClose={handleCloseManageModal}
        contentStyle="pt-16"
        width="50rem"
      >
        <ListingHeaderModal
          title="Pdf de linhas"
          inputRef={inputRef}
          isSearchInputDirty={isSearchInputDirty}
          onCancelSearch={handleCancelSearch}
          onSearch={handleSearch}
          onButtonClick={handleCancelSearch}
          buttonContent="Cancelar"
        >
          <Table.Head>
            <Table.Cell
              items={linesShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
            >
              Linhas
            </Table.Cell>
            <div className="flex">
              <Table.CellStatic className="w-64 justify-center text-secondaryText">
                Status da última modificação
              </Table.CellStatic>
              <Table.CellStatic className="w-36 text-secondaryText">
                Última modificação
              </Table.CellStatic>
              <div className="w-28"></div>
            </div>
          </Table.Head>
        </ListingHeaderModal>
        <Table.Root
          itemType="Pdf de linhas"
          className="h-[38rem]"
          itemsLength={linesLength}
        >
          {linesShown?.map((line) => (
            <Table.Row key={line.name}>
              <Table.CellStatic>{line.name}</Table.CellStatic>

              <div className="flex">
                <Table.CellStatic className="w-64 justify-center">
                  {line.pdfStatus === 'SUCESSO' && (
                    <CheckCircleIcon className="text-success" />
                  )}
                  {line.pdfStatus === 'ERRO' && (
                    <CancelIcon className="text-errorText" />
                  )}
                  {!line.pdfStatus && 'Sem PDF disponível'}
                </Table.CellStatic>
                <Table.CellStatic className="w-44">
                  {line.pdfDate
                    ? formatDate(line.pdfDate)
                    : 'Sem PDF disponível'}
                </Table.CellStatic>
                <Table.CellStatic className="w-20">
                  {line.pdfStatus !== 'PENDENTE' && (
                    <button
                      onClick={() => handleGenerateLinePdf(line)}
                      className="text-right text-sm text-primary"
                    >
                      Gerar PDF
                    </button>
                  )}
                  {line.pdfStatus === 'PENDENTE' && <span>Gerando...</span>}
                </Table.CellStatic>
              </div>
            </Table.Row>
          ))}
        </Table.Root>
        <Footer
          current={itemsShownLength}
          total={linesLength}
          itemType="Linhas"
          className="absolute mx-[1px] mb-[1px] mt-[-10vh] h-28 rounded-b-xl"
        />
      </Modal>

      <ConfirmModal
        contentMessage={`Deseja gerar o PDF de a linha ${currentLine?.name}?`}
        isModalOpen={isConfirmGeneratePdfModalOpen}
        onCloseModal={handleCloseConfirmGeneratePdfModal}
        onNotConfirm={handleCloseConfirmGeneratePdfModal}
        onConfirm={handleConfirmGeneratePdf}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
