import { useMutation, useQuery } from 'react-query';
import { linesApiCache } from '../../constants/requestCacheName';
import {
  getCategoriesPaginated,
  reorderCategory,
} from '../../services/categories';
import { Category, ReorderCategoryDto } from '../../types/categories';
import { useHandleTable } from '../../hooks/useHandleTable';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from '../../hooks/useSearchParams';
import { useNavigate } from 'react-router-dom';
import { getTableColumnWidthByBiggestElement } from '../../utils/tableColumnStyles';
import { getAllLines } from '../../services/lines';
import { Lines } from '../../types/lines';
import { useEdit } from '../../hooks/useEdit';
import { DropResult } from '@hello-pangea/dnd';
import { reorderList } from '../../utils/dragDrop';
import { mutationErrorHandling } from '../../utils/errorHandling';
import { useError } from '../../hooks/useError';
import { categorySearchBaseEndpoint } from '../../constants/endpoints';
import { Search, search as searchDatabase } from '../../services/search';

type CategoryFilter = 'Todos' | string;

export const useCategoryContent = () => {
  const [categoryFilter] = useSearchParams('f') as [CategoryFilter];

  const navigate = useNavigate();

  const {
    canEdit: canEditOrder,
    handleEdit: handleEditOrder,
    handleUnedit: handleUneditOrder,
  } = useEdit();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: reorderProductMutate } = useMutation({
    mutationFn: async (dto: ReorderCategoryDto[]) => await reorderCategory(dto),

    onError: (error) => {
      mutationErrorHandling(error, 'Falha ao reordenar', setErrorMessage);
    },
  });

  const reorderedTableUpdate = useRef<ReorderCategoryDto[]>([]);

  const { data: lines } = useQuery<Lines>(
    linesApiCache,
    async () => (await getAllLines()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return searchDatabase<Category>(
        baseUrl,
        searchValue,
        page,
        null,
        null,
        categoryFilter ? [{ name: 'line', value: categoryFilter }] : undefined,
      );
    },
    [categoryFilter],
  );

  const loadMore = useCallback(
    async (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      return await getCategoriesPaginated(
        page,
        direction,
        limit,
        categoryFilter,
      );
    },
    [categoryFilter],
  );

  const {
    itemsShown,
    isSearchInputDirty,
    sortField,
    inputRef,
    searchItems,
    itemsLength: categoriesLength,
    itemsShownLength: categoriesShownLength,
    isSearchFetching: isFetchingCategories,
    handleEnterClickSearchInput,
    refetchSearch,
    handleSearch,
    handleCancelSearch,
    handleSearchDatabase,
    handleSortTable: sortTable,
    defineItemsShown,
  } = useHandleTable(
    0,
    [],
    null,
    null,
    categorySearchBaseEndpoint,
    loadMore,
    null,
    doSearch as Search,
  );

  useEffect(() => {
    refetchSearch();
  }, [refetchSearch, categoryFilter]);

  const handleSortTable = (fieldNameSort: string) => {
    if (canEditOrder) return;

    sortTable(fieldNameSort);
  };

  const categories = searchItems?.items as Category[];
  const categoriesShown: Category[] = itemsShown;

  const handleFilter = (filterName: string) => {
    handleUneditOrder();
    navigate(
      { search: filterName !== 'Todos' ? `f=${filterName}` : '' },
      { replace: true },
    );
  };

  const lineColWidth = useMemo(
    () =>
      getTableColumnWidthByBiggestElement<Category>(
        categories ?? [],
        'line.name',
        10,
      ),
    [categories],
  );

  const handleChangeEditOrderButtonClick = () => {
    if (canEditOrder) {
      if (reorderedTableUpdate.current.length > 0) {
        reorderProductMutate(reorderedTableUpdate.current);
      }

      handleUneditOrder();
      return;
    }

    handleEditOrder();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedTable = reorderList(
      categoriesShown,
      result.source.index,
      result.destination.index,
    );

    reorderedTable.forEach((reorderItem, index) => {
      reorderItem.order = index + 1;
    });

    reorderedTableUpdate.current = reorderedTable.map((reorderItem) => {
      return {
        categoryId: reorderItem.id,
        order: reorderItem.order,
      };
    });

    defineItemsShown(reorderedTable);
  };

  const handleButtonCancelEditClick = useCallback(() => {
    reorderedTableUpdate.current = [];
    handleUneditOrder();
  }, [handleUneditOrder]);

  return {
    isSearchInputDirty,
    sortField,
    inputRef,
    lineColWidth,
    categoriesShown,
    lines,
    isFetchingCategories,
    categoryFilter,
    categories,
    canEditOrder,
    errorMessage,
    categoriesLength,
    categoriesShownLength,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleButtonCancelEditClick,
    defineItemsShown,
    clearError,
    handleDragEnd,
    handleChangeEditOrderButtonClick,
    handleFilter,
    handleSearch,
    handleCancelSearch,
    handleSortTable,
  };
};
