import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { common, grey } from '@mui/material/colors';

export const fieldsTableStyle: SxProps<Theme> = {
    marginLeft: '3.5rem',
  '@media (max-width: 800px)': {
    width: '30%',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
    padding: '0 1rem 0 1rem',
    display: 'flex',
    justifyContent: 'center',
    border: `2px solid ${grey['400']}`,
    borderRadius: '2rem',
  },
};

// export const selectPartsGroupStyle: SxProps<Theme> = {
//   height: '3rem',
//   margin: '1rem 0',
//   backgroundColor: common['white'],
//   fontSize: '0.8rem',
//   flex: 1,
// };

// export const tableContainerStyles: SxProps<Theme> = {
//   maxWidth: '32rem',
//   maxHeight: 'calc(100vh - 14rem)',
//   backgroundColor: common['white'],
//   mb: '0.5rem',

//   '@media(min-width: 1250px)': {
//     paddingTop: '3rem',
//   },

//   '@media (max-width: 1250px)': {
//     maxWidth: '100%',
//     maxHeight: '10rem',
//     borderRadius: '1rem',
//     margin: '0.5rem 0',
//     padding: '0 0.5rem',
//   },
// };

// export const refColumnContentStyle: SxProps<Theme> = {
//   border: `1px solid ${grey['400']}`,
//   borderRadius: 2,
//   p: '0.5rem',
//   mr: '1rem',
//   height: '2.5rem',
// };

// export const refCellStyle: SxProps<Theme> = {
//   border: 'none',
//   p: 0,
// };
