import { forwardRef } from 'react';
import Loading from '../Loading';
import { twMerge } from 'tailwind-merge';

type LoadMoreProps = {
  isModal: boolean;
  isLoadMoreFetching: boolean;
  isContentFetching: boolean;
};

const LoadMore = forwardRef<HTMLDivElement, LoadMoreProps>(
  ({ isModal, isLoadMoreFetching, isContentFetching }, ref) => {
    return (
      <>
        {isLoadMoreFetching && !isContentFetching && (
          <Loading data-modal={isModal} className="mt-0 pt-4" />
        )}
        <div
          data-modal={isModal}
          ref={ref}
          className={twMerge()
          // 'pb-12 opacity-0 data-[modal=false]:bg-backgroundPrimary',
          // 'h-4 w-full bg-red-500 pb-12',
          }
        />
        {/* <div ref={ref} className="h-4 w-full bg-red-500 pb-12" /> */}
      </>
    );
  },
);

export default LoadMore;
