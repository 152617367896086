import Modal from '../../../components/Modal';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import ConfirmModal from '../../../components/ConfirmModal';
import Alert from '../../../components/Alert';
import Switch from '@mui/material/Switch';
import QuestionContent from '../../../components/QuestionContent';
import EditorMenu from '../../../components/Editor/EditorMenu';
import Editor from '../../../components/Editor';
import { undefined } from 'zod';
import { useQuestions } from './useQuestions';

export default function Questions() {
  const {
    isMenageModalOpen,
    isShowEditButton,
    pergunta,
    questionIdUpdate,
    dirtyFields,
    errors,
    isConfirmDeleteModalOpen,
    errorMessage,
    editor,
    status,
    clearError,
    handleConfirmDeleteUser,
    handleCloseConfirmDeleteModal,
    handleClearField,
    handleSubmit,
    handleEditButtonClick,
    register,
    handleCloseMenageModal,
    handleTableRowClick,
    handleAddUserTypesClick,
    handleButtonOkClick,
    handleDeleteButtonClick,
  } = useQuestions();

  return (
    <>
      <QuestionContent
        onAddUserTypesClick={handleAddUserTypesClick}
        onTableRowClick={handleTableRowClick}
      />
      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseMenageModal}
        cancelIcon={false}
      >
        <HeaderModalManageItem
          showDeleteButton={!!questionIdUpdate}
          deleteButtonContent="Apagar Pergunta"
          isEditable={!isShowEditButton}
          onDeleteButtonClick={handleDeleteButtonClick}
          title={questionIdUpdate ? 'Pergunta' : 'Nova Pergunta'}
          mainButtonContent={
            questionIdUpdate && isShowEditButton ? 'Editar' : 'Ok'
          }
          onCancelClick={handleCloseMenageModal}
          onOkClick={
            !isShowEditButton ? handleSubmit(handleButtonOkClick) : undefined
          }
          onEditClick={isShowEditButton ? handleEditButtonClick : undefined}
        />
        <div className="mb-5 mt-5">
          <Input
            maxLength="none"
            label="Pergunta"
            type="text"
            placeholder="Pergunta"
            disabled={isShowEditButton}
            isDirty={!isShowEditButton && dirtyFields.pergunta}
            onCancelIconClick={() => handleClearField('pergunta')}
            {...register('pergunta')}
            helperText={errors.pergunta?.message as string}
            value={pergunta}
          />
        </div>
        {/* <div className="mb-5 mt-5">
          <Input
            label="Ordem"
            type="number"
            placeholder="Ordem"
            disabled={isShowEditButton}
            isDirty={!isShowEditButton && dirtyFields.ordem}
            onCancelIconClick={() => handleResetOrderField('ordem')}
            {...register('ordem')}
            helperText={errors.ordem?.message as string}
          />
        </div> */}
        <div className="mb-5 mt-5">
          <label>Desativado</label>
          <Switch
            {...register('status')}
            defaultChecked={status}
            disabled={isShowEditButton}
          />
          <label>Ativo</label>
        </div>
        <div className="mb-5 mt-5">
          <label>Resposta</label>
          <EditorMenu editor={editor} disabled={isShowEditButton}/>
          <Editor
            editor={editor}
            helperText={errors.resposta?.message as string}
          />
        </div>
      </Modal>

      <ConfirmModal
        contentMessage="Deseja excluir essa pergunta?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDeleteUser}
        onNotConfirm={handleCloseConfirmDeleteModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
