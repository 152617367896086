import CardAction from './CardAction';
import CardActions from './CardActions';
import CardImage from './CardImage';
import CardInfos from './CardInfos';
import CardIsNew from './CardIsNew';
import CardRoot from './CardRoot';
import CardTable from './CardTable';
import CardTitle from './CardTitle';

const Card = {
  Root: CardRoot,
  Image: CardImage,
  Title: CardTitle,
  IsNew: CardIsNew,
  Infos: CardInfos,
  Actions: CardActions,
  Action: CardAction,
  Table: CardTable,
};

export default Card;
