import { useMutation, useQueryClient } from 'react-query';
import { useEdit } from '../../../hooks/useEdit';
import { productDescriptionApiCache } from '../../../constants/requestCacheName';
import {
  DeleteDto,
  FindAllProductDescription,
  ProductDescription,
  SaveUpdateDto,
} from '../../../types/productsDescription';
import { ChangeEvent, useRef, useState } from 'react';
import { DropResult } from '@hello-pangea/dnd';
import { useModal } from '../../../hooks/useModal';
import {
  deleteAllByProductId,
  deleteProductsDescriptions,
  getAllProductDescriptionsByProductId,
  saveUpdateProductDescription,
} from '../../../services/productDescription';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { ProductsParts } from '../../../types/productsParts';
import { reorderList } from '../../../utils/dragDrop';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { ItemInUse } from '../../../types/inUse';
import { useScreenHeight } from '../../../hooks/useHeight';
import { getDescriptionsByTypeIdPaginated } from '../../../services/description';

export const useLinkProductsPartsDescriptionModal = (
  productId: number | undefined,
  typeId: number,
  onModalClose: () => void,
) => {
  const queryClient = useQueryClient();

  const { overrideCache, clearCache } = useQueryCache();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: saveUpdate } = useMutation({
    mutationFn: async (dto: SaveUpdateDto[]) => {
      return (await saveUpdateProductDescription(dto)).data;
    },

    onSuccess: (data) => {
      isModified.current = false;
      handleUnedit();
      overrideCache<FindAllProductDescription>(productDescriptionApiCache, {
        items: productsPartsDescriptions,
        meta: {
          totalItems: data.meta.totalItems,
        },
      });
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao realizar as atualizações linha',
        setErrorMessage,
      );
    },
  });

  const { mutate: deleteMutate } = useMutation({
    mutationFn: async (dto: DeleteDto[]) => {
      deleteProductsDescriptions(dto);
    },

    onSuccess: () => {
      itemsDelete.current = [];
      isModified.current = false;
      handleUnedit();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao realizar as deleções',
        setErrorMessage,
      );
    },
  });

  const { mutate: deleteAll } = useMutation({
    mutationFn: async () => {
      if (productId) {
        deleteAllByProductId(productId);
        return;
      }

      throw new Error();
    },

    onSuccess: () => {
      clearCache(productDescriptionApiCache);
      handleUnedit();
      isModified.current = false;
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar todas as descrições',
        setErrorMessage,
      );
    },
  });

  const [productsPartsDescriptions, setProductsPartsDescriptions] = useState<
    ProductDescription[]
  >([]);

  const productPartsDescriptionsInUse: ItemInUse[] =
    productsPartsDescriptions.map((productPartDescription) => ({
      id: productPartDescription.description.id,
    }));

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const {
    isModalOpen: isDescripionModalOpen,
    handleCloseModal: handleCloseDescripionModal,
    handleOpenModal: handleOpenDescripionModal,
  } = useModal();

  const {
    isModalOpen: isConfirmModalOpen,
    handleCloseModal: handleCloseConfirmModal,
    handleOpenModal: handleOpenConfirmModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteAllModalOpen,
    handleCloseModal: handleCloseConfirmDeleteAllModal,
    handleOpenModal: handleOpenConfirmDeleteAllModal,
  } = useModal();

  const {
    isModalOpen: isCopyDescriptionModalOpen,
    handleCloseModal: handleCloseCopyDescriptionModal,
    handleOpenModal: handleOpenCopyDescriptionModal,
  } = useModal();

  const currentDescriptionClicked = useRef<number | null>(null);
  const isModified = useRef(false);
  const itemsDelete = useRef<DeleteDto[]>([]);

  const [currentDescriptionToDeleteId, setCurrentDescriptionToDeleteId] =
    useState<number | null>(null);

  const handleEditButtonClick = () => {
    handleEdit();
    const productsPartsDescriptionQuery =
      queryClient.getQueryData<FindAllProductDescription>(
        productDescriptionApiCache,
      );
    setProductsPartsDescriptions(productsPartsDescriptionQuery?.items ?? []);
  };

  const handleCancelButtonClick = () => {
    if (!canEdit) {
      onModalClose();
      return;
    }

    if (isModified.current) {
      handleOpenConfirmModal();
      return;
    }

    handleConfirmCancel();
  };

  const handleConfirmCancel = () => {
    isModified.current = false;
    itemsDelete.current = [];
    setCurrentDescriptionToDeleteId(null);
    handleCloseConfirmModal();
    handleUnedit();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    isModified.current = true;

    const reorderedTable = reorderList(
      productsPartsDescriptions,
      result.source.index,
      result.destination.index,
    );

    reorderedTable.forEach((description, index) => {
      description.order = index + 1;
    });

    setProductsPartsDescriptions(reorderedTable);
  };

  const handleRemoveIconClick = (descriptionId: number) => {
    if (descriptionId === currentDescriptionToDeleteId) {
      setCurrentDescriptionToDeleteId(null);
      return;
    }

    setCurrentDescriptionToDeleteId(descriptionId);
  };

  const handleTableRowDragStart = () => {
    setCurrentDescriptionToDeleteId(null);
  };

  const handleAddDescriptionClick = () => {
    handleOpenDescripionModal();
  };

  const handleChangeOrderInput = (
    descriptionId: number,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    isModified.current = true;

    setProductsPartsDescriptions((prev) =>
      prev.map((productDescription) => {
        if (productDescription.description.id === descriptionId) {
          productDescription.amount = event.target.value;
        }
        return productDescription;
      }),
    );
  };

  const generateNewDescription = (
    prev: ProductDescription[],
    descriptionId: number,
    descriptionName: string,
    descriptionTypeId: number,
    descriptionTypeName: string,
  ): ProductDescription => {
    const product = prev[0]?.product ?? { id: productId };

    return {
      amount: '',
      order: (prev.at(-1)?.order ?? 0) + 1,
      product: product,
      description: {
        id: descriptionId,
        name: descriptionName,
        descriptionType: {
          id: descriptionTypeId,
          name: descriptionTypeName,
        },
      },
    };
  };

  const handleSelectDescription = (
    descriptionId: number,
    descriptionName: string,
    descriptionTypeId: number,
    descriptionTypeName: string,
  ) => {
    if (
      !productsPartsDescriptions.some(
        (productDescription) =>
          productDescription.description.id === descriptionId,
      )
    ) {
      isModified.current = true;

      if (currentDescriptionClicked.current) {
        setProductsPartsDescriptions((prev) =>
          prev.map((description) => {
            if (
              description.description.id === currentDescriptionClicked.current
            ) {
              description.previousDescriptionId =
                currentDescriptionClicked.current;
              description.description = {
                id: descriptionId,
                name: descriptionName,
                descriptionType: {
                  id: descriptionTypeId,
                  name: descriptionTypeName,
                },
              };
            }

            return description;
          }),
        );

        currentDescriptionClicked.current = null;
        handleCloseDescripionModal();
        return;
      }

      setProductsPartsDescriptions((prev) => {
        return [
          ...prev,
          generateNewDescription(
            prev,
            descriptionId,
            descriptionName,
            descriptionTypeId,
            descriptionTypeName,
          ),
        ];
      });

      handleCloseDescripionModal();
    }
  };

  const handleDescriptionClick = (escriptionId: number) => {
    currentDescriptionClicked.current = escriptionId;
    handleOpenDescripionModal();
  };

  const handleOkButtonClick = () => {
    let isError = false;

    const dto: SaveUpdateDto[] = productsPartsDescriptions.map(
      ({ amount, description, order, product, previousDescriptionId }) => {
        if (amount === '') {
          setErrorMessage(`Todos os valores devem estar preenchidos`);
          isError = true;
        }
        return {
          amount: amount,
          descriptionId: description.id,
          order: order,
          productId: product.id,
          previousDescriptionId,
        };
      },
    );

    if (isError) {
      return;
    }

    saveUpdate(dto);

    if (itemsDelete.current.length > 0) {
      deleteMutate(itemsDelete.current);
    }
  };

  const handleDeleteButtonClick = ({ descriptionId, productId }: DeleteDto) => {
    isModified.current = true;
    setProductsPartsDescriptions((prev) =>
      prev.filter(
        (description) => description.description.id !== descriptionId,
      ),
    );

    itemsDelete.current.push({ descriptionId, productId });
  };

  const handleButtonDeleteAllClick = () => {
    handleOpenConfirmDeleteAllModal();
  };

  const handleConfirmDeleteAll = () => {
    deleteAll();
    setProductsPartsDescriptions([]);
    handleCloseConfirmDeleteAllModal();
  };

  const handleCopyDescriptionsButtonClick = () => {
    handleOpenCopyDescriptionModal();
  };

  const handleSelectProductToCopyDescription = async (
    productPart: ProductsParts,
  ) => {
    setCurrentDescriptionToDeleteId(null);
    if (productId) {
      const descriptionsResponse = await getAllProductDescriptionsByProductId(
        productPart.id,
      );
      const descriptions = descriptionsResponse.data.items;

      descriptions.forEach(
        (description) => (description.product.id = productId),
      );

      isModified.current = true;
      setProductsPartsDescriptions(descriptions);
      handleCloseCopyDescriptionModal();
      return;
    }

    setErrorMessage('Erro ao copiar descrições. ID do produto não encontrado');
  };

  const { screenHeight } = useScreenHeight();

  const descriptionService = (currentPage: number) => {
    return getDescriptionsByTypeIdPaginated(typeId, currentPage);
  };

  return {
    canEdit,
    screenHeight,
    productsPartsDescriptions,
    currentDescriptionToDeleteId,
    isDescripionModalOpen,
    isConfirmModalOpen,
    isConfirmDeleteAllModalOpen,
    isCopyDescriptionModalOpen,
    errorMessage,
    productPartsDescriptionsInUse,
    descriptionService,
    clearError,
    handleSelectProductToCopyDescription,
    handleCopyDescriptionsButtonClick,
    handleCloseCopyDescriptionModal,
    handleCloseConfirmDeleteAllModal,
    handleConfirmCancel,
    handleCloseConfirmModal,
    handleCloseDescripionModal,
    handleRemoveIconClick,
    handleEditButtonClick,
    handleCancelButtonClick,
    handleDragEnd,
    handleTableRowDragStart,
    handleAddDescriptionClick,
    handleSelectDescription,
    handleChangeOrderInput,
    handleOkButtonClick,
    handleDescriptionClick,
    handleDeleteButtonClick,
    handleButtonDeleteAllClick,
    handleConfirmDeleteAll,
  };
};
