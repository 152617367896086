import { useTheme } from '@mui/material';
import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { productsPartGroupCompatibleCache } from '../../../constants/requestCacheName';
import { ProductPartsCompatibleFiltered } from '../../../types/productsPartsCompatibleFiltered';
import { verifyScreenIsLessThan } from '../../../utils/verifyScreenIsLessThan';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { pageTitleBase } from '../../../constants/utils';
import generatePartsCompatibilitiesPdf from '../../../pdf/partsCompatibilitiesPdf/generatePartsCompatibilitiesPdf';
import { ItemFullTablePdf, ItemInfosPage } from '../../../types/item';
import {
  FindAllPartsGroupItem,
  FindAllPartsGroupItemPaginated,
  PartsGroupItem,
} from '../../../types/partsGroupItem';
import {
  getAllPartsGroupItemsByPartId,
  getAllPartsGroupItemsByPartIdPaginated,
} from '../../../services/partsGroupItem';
import { checkProductExistsInDomain } from '../../services/produtosService';
import { useError } from '../../../hooks/useError';
import { getTableColumnWidthByBiggestElement } from '../../../utils/tableColumnStyles';
import { usePartsCatalogStore } from '../../../store/partsCatalog';

export const usePartCompatibilities = () => {
  const { partId, partName } = useParams();
  const [partsGroupWidth, setPartsGroupWidth] = useState<number>();
  const [currentPartId, setCurrentPartId] = useState<string | null>(
    partId ?? null,
  );
  const mobileMaxWidth = 1023;

  const [openSearch, setOpenSearch] = useState<boolean>(false);

  const {
    state: { currentPartClicked, currentPartsGroup },
  } = usePartsCatalogStore();

  const theme = useTheme();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isAllPartsCompatibilities = useRef(false);

  const [filteredProducts, setFilteredProducts] = useState<
    ProductPartsCompatibleFiltered[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);

  const {
    currentPage,
    sortField,
    inputRef,
    pagination,
    itemsShown: partsCompatibilitiesShown,
    handleChangePage,
    handleChangePagination,
    handleSearch: search,
    handleSortTable,
    setItemsShown: setPartsShown,
  } = useHandleTable(0, filteredProducts);

  const {
    data: productsPartGroupCompatiblePaged,
    refetch: refetchProductsPartGroupCompatiblePaged,
  } = useQuery<FindAllPartsGroupItemPaginated>(
    [productsPartGroupCompatibleCache, currentPartId, currentPage],
    async () =>
      (
        await getAllPartsGroupItemsByPartIdPaginated(
          +currentPartId!,
          currentPage,
        )
      ).data,
    {
      refetchOnWindowFocus: false,
      enabled: !!currentPartId,
      retry: false,
      onSuccess: (data) => {
        const width = getTableColumnWidthByBiggestElement<PartsGroupItem>(
          data.items,
          'partsGroup.name',
          18,
        );
        setPartsGroupWidth(width);
        setPartsShown((prevItems) => [...prevItems, ...data.items]);
        setCanLoadMore(data.items.length > 0);
      },
    },
  );
  const {
    data: allProductsPartGroupCompatible,
    refetch: refetchAllProductsPartGroupCompatible,
  } = useQuery<FindAllPartsGroupItem>(
    [productsPartGroupCompatibleCache, currentPartId],
    async () => (await getAllPartsGroupItemsByPartId(+currentPartId!)).data,
    {
      refetchOnWindowFocus: false,
      enabled: !!currentPartId,
      retry: false,
    },
  );

  const [isSearchInputDirty, setIsSearchInputDirty] = useState(false);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const originalProductsPartGroupCompatiblePaged = useMemo<
    ProductPartsCompatibleFiltered[]
  >(() => {
    if (
      !isAllPartsCompatibilities.current &&
      productsPartGroupCompatiblePaged &&
      productsPartGroupCompatiblePaged.items
    ) {
      const newPartsCompatibilities = [
        ...productsPartGroupCompatiblePaged.items,
      ];
      return newPartsCompatibilities.map((partGroupItem) => ({
        id: partGroupItem.product.id,
        name: partGroupItem.product.name?.trim().replace(/\s+/g, ' ') ?? '',
        partGroupName:
          partGroupItem.partsGroup.name?.trim().replace(/\s+/g, ' ') ?? '',
        isAvailable:
          !partGroupItem.product.outOfLine ||
          !!partGroupItem.product.reviewedApproved,
        partId: partGroupItem.part.id,
      }));
    }

    if (
      isAllPartsCompatibilities.current &&
      allProductsPartGroupCompatible &&
      allProductsPartGroupCompatible.items
    ) {
      return allProductsPartGroupCompatible.items.map((partGroupItem) => ({
        id: partGroupItem.product.id,
        name: partGroupItem.product.name?.trim().replace(/\s+/g, ' ') ?? '',
        partGroupName:
          partGroupItem.partsGroup.name?.trim().replace(/\s+/g, ' ') ?? '',
        isAvailable:
          !partGroupItem.product.outOfLine ||
          !!partGroupItem.product.reviewedApproved,
        partId: partGroupItem.part.id,
      }));
    }

    return [];
  }, [allProductsPartGroupCompatible, productsPartGroupCompatiblePaged]);

  useEffect(() => {
    document.title = `Compatibilidades - ${pageTitleBase}`;
  }, []);

  useEffect(() => {
    const fetchProductExistence = async () => {
      setIsLoading(true);
      const filtered = [];
      for (const product of originalProductsPartGroupCompatiblePaged) {
        const exists = await checkProductExistsInDomain(product.id, 1);
        if (exists) {
          filtered.push(product);
        }
      }
      setFilteredProducts(filtered);
      setPartsShown(filtered);
      setIsLoading(false);
    };

    fetchProductExistence();
  }, [originalProductsPartGroupCompatiblePaged, setPartsShown]);

  useEffect(() => {
    refetchProductsPartGroupCompatiblePaged();
  }, [currentPage, refetchProductsPartGroupCompatiblePaged]);

  useEffect(() => {
    if (pagination === 100) {
      refetchProductsPartGroupCompatiblePaged();
      isAllPartsCompatibilities.current = false;
      return;
    }

    refetchAllProductsPartGroupCompatible();
    isAllPartsCompatibilities.current = true;
  }, [
    pagination,
    refetchAllProductsPartGroupCompatible,
    refetchProductsPartGroupCompatiblePaged,
  ]);

  useEffect(() => {
    setCurrentPartId(partId ?? null);
    queryClient.invalidateQueries(productsPartGroupCompatibleCache);
  }, [partId, queryClient]);

  const handleSearch = () => {
    search();
    if (inputRef.current?.value !== '') {
      setIsSearchInputDirty(true);
      return;
    }
    setIsSearchInputDirty(false);
  };

  const handleCancelSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setIsSearchInputDirty(false);
    }
    search();
  };

  const handleTableRowClick = (
    productId: string,
    partId: string,
    partGroupName: string,
    productName: string,
  ) => {
    setFilteredProducts([]);
    setCurrentPartId(partId);
    navigate({
      pathname: `/catalogo/${productId}`,
      search: `partId=${partId}&partGroupName=${partGroupName}&product=${productName}`,
    });
  };

  const partNameDecoded = decodeURIComponent(partName ?? '');

  const isMobile = verifyScreenIsLessThan(760);

  const partsCompatibilitiesLength = Number(
    (pagination === 20
      ? productsPartGroupCompatiblePaged
      : allProductsPartGroupCompatible
    )?.meta.totalItems,
  );

  const partsCompatibilitiesShownLength = partsCompatibilitiesShown.length;

  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const handleGeneratePdf = async () => {
    setIsPdfLoading(true);

    const pageInfos: ItemInfosPage | number =
      pagination <= partsCompatibilitiesLength
        ? {
            itemsShownNumber: partsCompatibilitiesShownLength,
            pageNumber: currentPage + 1,
            pagination: pagination === 20 ? 'Paginado' : 'Todos',
            totalItemsNumber: partsCompatibilitiesLength,
          }
        : partsCompatibilitiesShownLength;

    const partsCompatibilitiesShownPdf: ItemFullTablePdf[] =
      partsCompatibilitiesShown.map(
        ({ id, name, isAvailable, partGroupName }) => ({
          id,
          name,
          isAvailable,
          lastColContent: partGroupName,
        }),
      );

    try {
      await generatePartsCompatibilitiesPdf(
        partsCompatibilitiesShownPdf,
        partId,
        partName,
        pageInfos,
        'Conjunto de Peças',
        inputRef.current?.value,
        setErrorMessage,
      );
    } catch (error) {
      if (
        typeof error === 'string' &&
        error.toLowerCase().includes('permissões')
      ) {
        alert(
          'O navegador bloqueou o PDF. Desative a opção Bloquear Pop-ups e tente gerar novamente',
        );
      }
    } finally {
      setIsPdfLoading(false);
    }
  };

  const endOfListRef = useRef<HTMLDivElement>(null);

  const handleLoadMore = useCallback(() => {
    if (canLoadMore) {
      handleChangePage(null, currentPage + 1);
    }
  }, [canLoadMore, handleChangePage, currentPage]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        handleLoadMore();
      }
    });

    if (endOfListRef.current) {
      intersectionObserver.observe(endOfListRef.current);
    }

    return () => {
      if (intersectionObserver) {
        intersectionObserver.disconnect();
      }
    };
  }, [handleLoadMore]);

  // const handleBackButtonClick = () => {
  //   const params = new URLSearchParams();

  //   if (currentPartsGroup?.name)
  //     params.append('partGroupName', currentPartsGroup.name);

  //   if (currentPartClicked?.part.id)
  //     params.append('partId', currentPartClicked.part.id.toString());

  //   navigate({
  //     pathname: `/catalogo/${currentPartClicked?.product.id}`,
  //     search: params.toString(),
  //   });
  // };

  const currentPart = {
    id: +partId!,
    name: partNameDecoded,
  };

  const handleOpenSearch = () => {
    setOpenSearch((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= mobileMaxWidth) {
        setOpenSearch(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    currentPart,
    theme,
    sortField,
    partId,
    isSearchInputDirty,
    partNameDecoded,
    inputRef,
    allProductsPartGroupCompatible,
    partsCompatibilitiesLength,
    partsCompatibilitiesShown,
    isMobile,
    currentPage,
    pagination,
    partsCompatibilitiesShownLength,
    errorMessage,
    partsGroupWidth,
    productExistsInDomain: !isLoading,
    isLoading,
    isPdfLoading,
    endOfListRef,
    canLoadMore,
    openSearch,
    handleOpenSearch,
    // handleBackButtonClick,
    clearError,
    handleChangePage,
    handleChangePagination,
    handleCancelSearch,
    handleSearch,
    handleTableRowClick,
    handleSortTable,
    handleGeneratePdf,
  };
};
