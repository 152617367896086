import { AxiosResponse } from 'axios';
import { NewsListInPageSort } from '../../types/news';
import { useNewsContent } from './useNewsContent';
import ListingHeader from '../ListingHeader';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { twMerge } from 'tailwind-merge';
import { imageTypesText } from '../../constants/imageTypes';
import { ImageTypes } from '../../enums/imageTypes';
import Footer from '../Footer';
import Loading from '../Loading';
import Main from '../Main';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import Alert from '../Alert';
import LoadMore from '../LoadMore';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export type NewsService = () => Promise<AxiosResponse<NewsListInPageSort, any>>;

type NewsContentProps = {
  isModal?: boolean;
  newsListInUse?: number[];
  onAddNewsClick?: () => void;
  queryKey: string;
  onTableRowClick: (
    newsId: number,
    newsName: string,
    newsType: string,
    newsUrl: string,
    newsFileTypeUrl: string,
    date: string | undefined,
    pdfBook: string,
  ) => void;
};

export default function NewsContent({
  isModal,
  queryKey,
  onTableRowClick,
  onAddNewsClick,
}: NewsContentProps) {
  const {
    newsListShown,
    newsListShownLength,
    newsListLength,
    inputRef,
    isFetchingNewsList,
    typeColWidth,
    isSearchInputDirty,
    sortField,
    isSearch,
    errorMessage,
    newsTypes,
    newsFilter,
    canEditOrder,
    endPageRef,
    isLoadMoreFetching,
    canEdit,
    domains,
    domainWidth,
    checkNewsListDomains,
    domainFilter,
    handleEnterClickSearchInput,
    handleSearchInAllNewsList,
    clearError,
    handleCheckDomain,
    verifyAllDomainsChecked,
    handleToggleCheckDomains,
    verifyDomainsPartialyChecked,
    handleChangeEditOrderButtonClick,
    handleFilterByType,
    handleFilterByDomain,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
    handleDragEnd,
    handleButtonCancelEditClick,
    handleButtonOkClick,
  } = useNewsContent(queryKey);

  return (
    <>
      {!isModal && (
        <ListingHeader
          add
          onAddIconClick={onAddNewsClick}
          pdfTop="3rem"
          pdf={false}
        >
          <div className="mt-4">
            <div className="flex justify-between">
              <h1 className="mb-4 text-2xl">Notícias</h1>
              <div className="flex items-center gap-2">
                {!canEdit && (
                  <button
                    onClick={handleChangeEditOrderButtonClick}
                    className="text-sm text-primary disabled:opacity-60"
                    disabled={
                      canEdit ||
                      !newsFilter ||
                      newsFilter === 'Todos' ||
                      !domainFilter ||
                      domainFilter === 'Todos'
                    }
                  >
                    {canEditOrder ? 'Salvar ordem' : 'Editar ordem'}
                  </button>
                )}
                {!canEdit && !canEditOrder && <span>|</span>}

                {!canEditOrder && (
                  <button
                    onClick={handleButtonOkClick}
                    className="text-sm text-primary"
                    disabled={canEditOrder}
                  >
                    {canEdit ? 'Salvar domínio' : 'Editar domínio'}
                  </button>
                )}

                {(canEdit || canEditOrder) && (
                  <>
                    <span>|</span>
                    <button
                      onClick={() => handleButtonCancelEditClick()}
                      className="text-sm text-errorText"
                    >
                      Cancelar {canEdit ? 'seleção' : 'ordenação'}
                    </button>
                  </>
                )}
              </div>
            </div>

            <h2>Tipos de Notícia</h2>
            <div
              className="mb-4 flex flex-wrap gap-2 rounded-lg bg-[#e0e0e0] px-2 py-2"
              style={{
                pointerEvents: canEdit || canEditOrder ? 'none' : 'auto',
                opacity: canEdit || canEditOrder ? 0.5 : 1,
              }}
            >
              <span
                onClick={() => handleFilterByType('Todos')}
                className={twMerge(
                  'flex w-fit cursor-pointer items-center justify-center rounded-lg px-4 max-md:py-1',
                  !newsFilter || newsFilter === 'Todos'
                    ? 'border border-primary bg-backgroundPaper text-primary'
                    : 'text-primaryText',
                )}
              >
                Todos
              </span>
              {newsTypes?.items?.map((newsType) => (
                <span
                  key={newsType.id}
                  onClick={() => handleFilterByType(newsType.name)}
                  className={twMerge(
                    'flex w-fit cursor-pointer items-center justify-center rounded-lg px-4 max-md:py-1',
                    newsFilter === newsType.name
                      ? 'border border-primary bg-backgroundPaper text-primary'
                      : 'text-primaryText',
                  )}
                >
                  {newsType.name}
                </span>
              ))}
            </div>

            <h2>Domínios</h2>

            <div
              className="mb-4 flex flex-wrap gap-2 rounded-lg bg-[#e0e0e0] px-2 py-2"
              style={{
                pointerEvents: canEdit || canEditOrder ? 'none' : 'auto',
                opacity: canEdit || canEditOrder ? 0.5 : 1,
              }}
            >
              <span
                onClick={() => handleFilterByDomain('Todos')}
                className={twMerge(
                  'flex w-fit cursor-pointer items-center justify-center rounded-lg px-4 max-md:py-1',
                  !domainFilter || domainFilter === 'Todos'
                    ? 'border border-primary bg-backgroundPaper text-primary'
                    : 'text-primaryText',
                )}
              >
                Todos
              </span>
              {domains?.items?.map((domain) => (
                <span
                  key={domain.id}
                  onClick={() => handleFilterByDomain(domain.name)}
                  className={twMerge(
                    'flex w-fit cursor-pointer items-center justify-center rounded-lg px-4 max-md:py-1',
                    domainFilter === domain.name
                      ? 'border border-primary bg-backgroundPaper text-primary'
                      : 'text-primaryText',
                  )}
                >
                  {domain.name}
                </span>
              ))}
            </div>
            <SearchInput
              ref={inputRef}
              onSearch={handleSearch}
              onButtonClick={handleSearchInAllNewsList}
              onKeyDown={handleEnterClickSearchInput}
              buttonContent="Buscar"
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
              disabled={canEditOrder}
            />
          </div>
          <Table.Head rowStyle="px-0 items-center">
            <div className="ml-4 flex">
              <Table.Cell
                items={newsListShown}
                type="name"
                onSort={handleSortTable}
                isSortActive={sortField === 'name'}
                className="mr-8 w-[50.5rem]"
              >
                Notícia
              </Table.Cell>
              <Table.Cell
                items={newsListShown}
                type="newsType.name"
                onSort={handleSortTable}
                isSortActive={sortField === 'newsType.name'}
                style={{ width: `${typeColWidth}ch` }}
                className="mr-8"
              >
                Tipo
              </Table.Cell>
              <Table.CellStatic
                // items={newsListShown}
                // onSort={handleSortTable}
                // type="date"
                // isSortActive={sortField === 'date'}
                className="w-20 text-zinc-400"
              >
                Data
              </Table.CellStatic>
            </div>

            <div
              className={twMerge(
                'flex gap-4',
                canEditOrder ? `mr-[8.4rem]` : `mr-[5.1rem]`,
              )}
            >
              {domains?.items?.map((domain) => (
                <Table.CellStatic
                  key={`label-${domain.id}`}
                  style={{ width: `${domainWidth}ch` }}
                  className="items-center"
                >
                  <div className="flex w-full items-center gap-2">
                    <div className="relative flex items-center">
                      {verifyDomainsPartialyChecked(domain.id) && (
                        <div className="pointer-events-none absolute -left-[2px] flex items-center">
                          <IndeterminateCheckBoxIcon
                            className="text-[#0075ff]"
                            fontSize="small"
                          />
                        </div>
                      )}
                      <input
                        onChange={(event) =>
                          handleToggleCheckDomains(
                            domain.id,
                            event.target.checked,
                          )
                        }
                        checked={verifyAllDomainsChecked(domain.id)}
                        type="checkbox"
                        id={`domain${domain.id}`}
                        className="size-4"
                        disabled={!canEdit}
                      />
                    </div>
                    <span className="text-center text-sm">{domain.name}</span>
                  </div>
                </Table.CellStatic>
              ))}
            </div>
          </Table.Head>
        </ListingHeader>
      )}

      <Main>
        {isFetchingNewsList && <Loading className="pt-10" />}
        {!isFetchingNewsList && (
          <Table.Root
            itemType="Notícias"
            itemsLength={newsListLength}
            style={{ backgroundColor: 'white' }}
            paddingTop="0"
            showNoContentMessage={newsListShown.length <= 0 && isSearch}
            showSearchMessage={newsListShown.length <= 0 && !isSearch}
          >
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                isDropDisabled={!canEditOrder}
                droppableId="news"
                type="list"
                direction="vertical"
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {newsListShown?.map((news, index) => {
                      return (
                        <Table.Row key={`${news.id}`} className="ml-4 px-0">
                          <Draggable
                            isDragDisabled={!canEditOrder}
                            draggableId={news.id?.toString()}
                            index={index}
                            key={news.id}
                          >
                            {(provided) => (
                              <div
                                className="flex w-full justify-between bg-backgroundPaper"
                                onClick={() =>
                                  onTableRowClick(
                                    news.id,
                                    news.name,
                                    news.newsType.name,
                                    news.url,
                                    news.fileType,
                                    news.date?.toString(),
                                    news.pdfBook ? 'pdf_book' : 'pdf_normal',
                                  )
                                }
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="flex">
                                  <Table.Cell className="mr-8 w-[50.5rem]">
                                    {news.name}
                                  </Table.Cell>
                                  <div className="flex items-center">
                                    <Table.Cell
                                      style={{ width: `${typeColWidth}ch` }}
                                      className="mr-8"
                                    >
                                      {imageTypesText[
                                        news.newsType.name as ImageTypes
                                      ] ?? news.newsType.name}
                                    </Table.Cell>
                                    {isModal && (
                                      <Table.Cell className="absolute right-6"></Table.Cell>
                                    )}
                                  </div>
                                  <Table.CellStatic className="w-20">
                                    {news.date
                                      ? format(news.date, 'dd/MM/yyyy', {
                                          locale: ptBR,
                                        })
                                      : ''}
                                  </Table.CellStatic>
                                </div>
                                <div className="mr-20 flex gap-4">
                                  {domains?.items?.map((domain) => (
                                    <Table.CellStatic
                                      className="flex justify-between gap-2"
                                      key={`checkbox-${domain.id}`}
                                      style={{
                                        width: `${domainWidth}ch`,
                                      }}
                                    >
                                      <span className="w-full">
                                        <input
                                          checked={checkNewsListDomains.some(
                                            (checkNewsDomain) =>
                                              domain.id ===
                                                checkNewsDomain?.domainId &&
                                              checkNewsDomain?.newsId ===
                                                news.id &&
                                              checkNewsDomain?.isCheck,
                                          )}
                                          onChange={(event) => {
                                            handleCheckDomain(
                                              event.target.checked,
                                              news?.id,
                                              domain?.id,
                                              news?.domains,
                                            );
                                          }}
                                          onClick={(event) => {
                                            // Impede que o clique no checkbox propague para a div principal
                                            event.stopPropagation();
                                          }}
                                          type="checkbox"
                                          id="news-domain"
                                          className="size-4"
                                          disabled={!canEdit}
                                        />
                                      </span>
                                    </Table.CellStatic>
                                  ))}
                                  {canEditOrder && (
                                    <img
                                      src="/icons/drag.png"
                                      alt="Ícone para arrastar Descrição"
                                      className="mr-4 size-5 cursor-grab"
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </Table.Row>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <LoadMore
              isModal={!!isModal}
              isContentFetching={false}
              isLoadMoreFetching={isLoadMoreFetching}
              ref={endPageRef}
            />
          </Table.Root>
        )}
      </Main>

      <Footer
        current={newsListShownLength}
        total={newsListLength}
        itemType="Notícias"
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
