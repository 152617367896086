import { Card, CardProps, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { productCardStyle } from './styles';
import { twMerge } from 'tailwind-merge';

type CardRootProps = CardProps & {
  children: ReactNode;
  border?: boolean;
  line?: boolean;
  contentClassName?: string;
};

export default function CardRoot({
  border = false,
  children,
  line,
  contentClassName,
  sx,
  ...rest
}: CardRootProps) {
  return (
    <Card
      sx={
        {
          ...productCardStyle,
          ...sx,
          border: border ? '2px solid #E9E9E9' : 'none',
        } as SxProps<Theme>
      }
      {...rest}
    >
      <div
        data-line={line}
        className={twMerge(
          'mx-4 mt-4 flex flex-col data-[line=true]:flex-row',
          contentClassName,
        )}
      >
        {children}
      </div>
    </Card>
  );
}
