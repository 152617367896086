import { Box, BoxProps, SxProps, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  backButtonStyle,
  cardImageStyle,
  headerStyles,
  itemNameStyle,
  productImageContainerStyle,
  productInfosStyle,
  productStyle,
} from './styles';
import { getImageFromApi, verifyImageUrlExists } from '../../utils/getImageUrl';
import BackButton from '../BackButton';
import { getAuth } from '../../utils/auth';
import { verifyScreenIsLessThan } from '../../utils/verifyScreenIsLessThan';
import { Role } from '../../types/userType';
import SearchBar from '../SearchBar';
import { AutoComplete } from '../AutoComplete';
import { useSecondaryHeader } from './useSecondaryHeader';
import BreadCrumb from '../BreadCrumb';
import { Link } from 'react-router-dom';
import { PartsGroupItemDomainLiveSearch } from '../../types/partsGroupItem';
import { ClipLoader } from 'react-spinners';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { DownloadIcon } from 'lucide-react';

type SecondaryHeaderProps = BoxProps & {
  itemId?: string;
  isPdfLoading?: boolean;
  itemName?: string;
  itemType?: 'Produtos' | 'Peças';
  pdf?: boolean;
  showImage?: boolean;
  separator?: boolean;
  backButton?: boolean;
  canEdit?: boolean;
  isEditable?: boolean;
  showSearchInput?: boolean;
  previusVersion?: number;
  search?: boolean;
  partsCatalogo?: boolean;
  onEditButtonClick?: () => void;
  onOkButtonClick?: () => void;
  onPdfClick?: () => void;
  onBackButtonClick?: () => void;
};

export default function SecondaryHeader({
  partsCatalogo = false,
  previusVersion,
  isPdfLoading,
  search,
  itemId = '',
  itemName = '',
  showSearchInput = false,
  pdf = false,
  separator = false,
  canEdit,
  isEditable = true,
  onEditButtonClick,
  onOkButtonClick,
  onPdfClick,
  onBackButtonClick,
  sx,
  backButton = true,
  itemType,
  showImage = true,
  ...rest
}: SecondaryHeaderProps) {
  const [isImageError, setIsImageError] = useState(true);
  const auth = getAuth();
  const isMobile = verifyScreenIsLessThan(760);

  const handleImageError = () => {
    setIsImageError(true);
  };

  useEffect(() => {
    const verifyImageExists = async () => {
      const isImageExists = await verifyImageUrlExists(
        getImageFromApi(itemId, itemType === 'Produtos' ? 'products' : 'parts'),
      );

      setIsImageError(!isImageExists);
    };

    verifyImageExists();
  }, [itemId, itemType]);

  const {
    theme,
    inputRef,
    partsSearch,
    selectedIndex,
    inputWidth,
    handleClickOutAutoComplete,
    handleSearchInputChange,
    handleProductSearchClick,
    handleClearSearchAndSetFocus,
  } = useSecondaryHeader(itemId);

  return (
    <Box
      component="header"
      sx={
        {
          ...headerStyles,
          borderBottom: separator ? '2px solid #cdcdcd' : 'none',
          '@media(max-width:1024px)': {
            justifyContent: 'space-between',
          },
          ...sx,
        } as SxProps<Theme>
      }
      onClick={handleClickOutAutoComplete}
      {...rest}
      className="flex items-center"
    >
      {backButton && (
        <BackButton sx={backButtonStyle} onClick={onBackButtonClick} />
      )}
      {showImage && (
        <Box
          sx={{
            ...productStyle,
            '@media(max-width:600px)': {
              width: pdf ? '60%' : '100%',
            },
          }}
          className="ml-16 md:ml-32 lg:ml-0"
        >
          <Box sx={{ ...productImageContainerStyle }}>
            {!isImageError ? (
              <iframe
                src={getImageFromApi(
                  itemId,
                  itemType === 'Produtos' ? 'products' : 'parts',
                )}
                title={`Imagem do ${itemName}`}
                onError={handleImageError}
                style={cardImageStyle}
              />
            ) : (
              <img
                src="/images/unavailable.png"
                alt="Imagem de produto indisponível"
                style={cardImageStyle}
              />
            )}
          </Box>
          <Box sx={productInfosStyle}>
            <Box component="h3" color="text.primary" sx={itemNameStyle}>
              {itemName}
            </Box>
            <Box
              component="span"
              color="text.secondary"
              className="flex w-full min-w-32 justify-between"
            >
              <span>{itemId}</span>
              {previusVersion! > 0 && (
                <Link
                  to={{
                    pathname: `/parts-catalog/${previusVersion}`,
                    search: `product=${itemName}`,
                  }}
                  className="animate-blink text-blue-500"
                >
                  Versão anterior
                </Link>
              )}
              <div className="flex items-center justify-center gap-2">
                {partsCatalogo && (
                  <Link
                    to={{
                      pathname: '/parts-product',
                      search: `productId=${itemId}&productName=${itemName}&partsCatalog=partsCatalog`,
                    }}
                    style={{ color: theme.palette.secondary.main }}
                    className="text-base"
                  >
                    <FormatListBulletedIcon />
                  </Link>
                )}
                {pdf && (!previusVersion || previusVersion! === 0) && (
                  <div className="flex items-center">
                    {isPdfLoading &&
                      (!previusVersion || previusVersion! === 0) && (
                        <ClipLoader
                          size={25}
                          color="#42a0dd"
                          className="mr-1"
                        />
                      )}
                    <span
                      className="cursor-pointer text-primary"
                      onClick={onPdfClick}
                    >
                      <DownloadIcon className="text-blue-500" />
                    </span>
                  </div>
                )}
              </div>
            </Box>
          </Box>
        </Box>
      )}

      <div
        className={`absolute max-sm:right-3 ${auth && auth.user.role.type !== Role.ADMIN ? 'right-1 flex flex-col items-center' : 'right-8 flex flex-col items-start gap-2'}`}
      >
        {search && (
          <div className="relative w-[20rem] xl:w-[27rem]">
            <SearchBar
              ref={inputRef}
              onChange={handleSearchInputChange}
              className="z-[10]"
              placeholderInput="Encontre sua peça"
              onClick={handleSearchInputChange}
              onClear={handleClearSearchAndSetFocus}
            />
            {partsSearch && (
              <AutoComplete.Root
                items={partsSearch.items}
                onOutClick={handleClickOutAutoComplete}
                className={`absolute border-2 border-t-0`}
                style={{ width: `${inputWidth}px` }}
                placeholderInput="Sua pesquisa não encontrou nenhuma peça correspondente."
              >
                {partsSearch.items.map(
                  (
                    {
                      id,
                      domain,
                      partsGroupItem,
                    }: PartsGroupItemDomainLiveSearch,
                    index,
                  ) => {
                    const { part, partsGroup } = partsGroupItem;
                    const isSelected = index === selectedIndex;

                    return (
                      <AutoComplete.Item
                        key={id}
                        onClick={() =>
                          handleProductSearchClick({
                            id,
                            domain,
                            partsGroupItem,
                          })
                        }
                        className={`p-2 ${isSelected ? 'bg-zinc-300' : ''}`}
                        ref={(el) => {
                          if (isSelected && el) {
                            el.scrollIntoView({ block: 'nearest' });
                          }
                        }}
                      >
                        <>
                          {part.id} - {part.name}
                          <BreadCrumb
                            items={[
                              partsGroup.name,
                              `Qtd. ${+partsGroupItem?.amount}`,
                            ]}
                          />
                        </>
                      </AutoComplete.Item>
                    );
                  },
                )}
              </AutoComplete.Root>
            )}
          </div>
        )}
        <div className="mx-1 flex items-center gap-3">
          {isPdfLoading && (previusVersion || previusVersion! > 0) && (
            <ClipLoader size={40} color="#42a0dd" className="mr-1" />
          )}
          {pdf && (previusVersion || previusVersion! > 0) && (
            <span
              className="cursor-pointer px-3 text-primary"
              onClick={onPdfClick}
            >
              <DownloadIcon className="text-blue-500" />
            </span>
          )}
          {auth &&
            auth.user.role.type === Role.ADMIN &&
            isEditable &&
            !isMobile && (
              <span
                className="cursor-pointer px-3 text-primary"
                onClick={canEdit ? onOkButtonClick : onEditButtonClick}
              >
                {canEdit ? 'Ok' : 'Editar'}
              </span>
            )}
        </div>
      </div>
    </Box>
  );
}
