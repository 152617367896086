import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { ComponentProps, forwardRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

type SearchBarProps = ComponentProps<'div'> & {
  placeholderInput?: string;
  onClear?: () => void;
  searchUseHandleTable?: () => void;
};

const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  (
    {
      placeholderInput = 'Encontre seu produto',
      onClear,
      className,
      searchUseHandleTable,
      ...rest
    },
    ref,
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    };

    const handleClear = () => {
      setSearchValue('');
      setIsFocused(false);
      if (ref && typeof ref !== 'function' && ref.current) {
        ref.current.value = '';
      }

      if (onClear) onClear();
    };

    return (
      <div
        className={twMerge(
          'relative box-border flex h-11 w-full items-center',
          className,
        )}
        {...rest}
      >
        <button className="absolute z-20 h-11 rounded-l-lg pl-[1.125rem] pr-2.5 text-zinc-50">
          <SearchIcon className="text-zinc-400" />
        </button>
        <input
          onChange={searchUseHandleTable ?? handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          type="text"
          name="query"
          ref={ref}
          autoComplete="off"
          placeholder={placeholderInput}
          className={`z-10 box-border h-12 flex-grow ${isFocused && searchValue ? 'rounded-t-lg rounded-b-none border-b-0' : 'rounded-lg'} border-2 border-[#d4d4d8] px-2.5 pl-12 outline-none`}
        />

        {searchValue && (
          <button
            onClick={handleClear}
            className="absolute right-3 z-20 text-zinc-400 hover:text-zinc-600"
          >
            <CloseIcon />
          </button>
        )}
      </div>
    );
  },
);

export default SearchBar;
