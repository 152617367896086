import { FindAllDescriptionTypes } from '../types/descriptionTypes';
import { api } from './api';

export const getAllDescriptionTypes = async () => {
  const response = await api.get<FindAllDescriptionTypes>(
    '/description-type/v1/find-all',
  );
  return response;
};

export const saveDescriptionType = async (descriptionTypeName: string) => {
  const body = {
    name: descriptionTypeName,
  };

  const response = await api.post('/description-type/v1', body);
  return response;
};

export const updateDescriptionType = async (
  descriptionTypeId: number,
  descriptionTypeName: string,
) => {
  const body = {
    id: descriptionTypeId,
    name: descriptionTypeName,
  };

  const response = await api.put('/description-type/v1', body);
  return response;
};

export const deleteDescriptionType = async (descriptionTypeId: number) => {
  const response = await api.delete(
    `/description-type/v1/${descriptionTypeId}`,
  );
  return response;
};
