import { DownloadIcon } from 'lucide-react';
import { PdfType } from '../../types/pdf';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import Main from '../Main';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { usePdfContent } from './usePdfContent';

type PdfContentProps = {
  onTableRowClick: (pdfType: PdfType) => void;
};

export default function PdfContent({ onTableRowClick }: PdfContentProps) {
  const {
    handleCancelSearch,
    handleSearch,
    handleSortTable,
    pdfTypesShownLength,
    inputRef,
    isSearchInputDirty,
    pdfTypesShown,
    pdfTypesLength,
    sortField,
  } = usePdfContent();

  return (
    <>
      <ListingHeader pdfTop="3rem" pdf={false}>
        <div className="mt-4">
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-2xl">
              <DownloadIcon className="text-blue-500" />
            </h1>
          </div>
          <SearchInput
            ref={inputRef}
            onSearch={handleSearch}
            // onButtonClick={handleCancelSearch}
            showCancelOptions={isSearchInputDirty}
            onCancelSearch={handleCancelSearch}
          />
        </div>
        <Table.Head>
          <Table.Cell
            items={pdfTypesShown}
            type="name"
            onSort={handleSortTable}
            isSortActive={sortField === 'name'}
          >
            PDFs
          </Table.Cell>
        </Table.Head>
      </ListingHeader>
      <Main>
        <Table.Root
          itemType="tipos de usuários"
          itemsLength={pdfTypesLength}
          paddingTop="0"
        >
          {pdfTypesShown.map((pdfType) => {
            return (
              <Table.Row
                key={pdfType.name}
                onClick={() => onTableRowClick(pdfType)}
              >
                <Table.CellStatic>{pdfType.name}</Table.CellStatic>
              </Table.Row>
            );
          })}
        </Table.Root>
      </Main>
      <Footer
        current={pdfTypesShownLength}
        total={pdfTypesLength}
        itemType="PDF"
      />
    </>
  );
}
