import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ mt: 8, textAlign: 'center' }}
    >
      <Typography variant="h1" color="error" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" gutterBottom>
        Página Não Encontrada
      </Typography>
      <Typography variant="body1" gutterBottom>
        Desculpe, a página que você está procurando não existe.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoBack}
        sx={{ mt: 2 }}
      >
        VOLTAR PARA A PÁGINA INICIAL
      </Button>
    </Container>
  );
}
