import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { getImageStorage } from '../../utils/getImageUrl';
import header from './productPartsCatalogHeader';
import commonContent from '../commonContent';
import commonFooter from '../commonFooter';
import { getAllPartsGroupItemsByProductId } from '../../services/partsGroupItem';
import { urlToPureSvg } from '../../utils/urlToPureSvg';
import { verifyOpenPdfPermission } from '../verifyPdfPermissions';

const generateProductPartsCatalogPdf = async (
  productId: number,
  onError: (errorMessage: string) => void,
  // admin?:'admin',
) => {
  verifyOpenPdfPermission(onError);

  pdfMake.vfs = pdfFonts.vfs;

  const partsGroupItems = (await getAllPartsGroupItemsByProductId(productId))
    .data.items;
  const productName = partsGroupItems[0].product.name ?? '';
  const isProductImageOk = (
    await fetch(getImageStorage('products', productId), { method: 'HEAD' })
  ).ok;

  const productImage = await urlToPureSvg(
    isProductImageOk
      ? getImageStorage('products', productId)
      : getImageStorage('utils', 'unavailable'),
  );
  const logoImage = await urlToPureSvg(
    getImageStorage('utils', 'logo-buffalo.svg'),
  );

  const content = await commonContent(partsGroupItems);
  const pdfDefinitions: TDocumentDefinitions = {
    pageSize: 'A4',
    pageMargins: [32, 90, 32, 64],
    header: (currentPage) =>
      header(currentPage, productName, productId, productImage, logoImage),
    content: content,
    footer: (currentPage, totalPages, context) => ({
      stack: [commonFooter(currentPage, totalPages, context)],
      relativePosition: { y: 32 },
    }),
  };
  // pdfMake.createPdf(pdfDefinitions).open();
  pdfMake.createPdf(pdfDefinitions).getBlob((blob) => {
    const url = URL.createObjectURL(blob);
    // if (admin) {
    //   const pdf = window.open(url, '_blank');
    //   if (!pdf) {
    //     onError(
    //       'Falha ao gerar PDF. Desative a opção Bloquear Pop-ups e tente novamente',
    //     );
    //   }
    //   return
    // }
    const a = document.createElement('a');
    a.href = url;
    a.download = `catalogo_produto_${productName}.pdf`; // Nome do arquivo
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    if (!a) {
      onError(
        'Falha ao gerar PDF. Desative a opção Bloquear Pop-ups e tente novamente',
      );
    }
  });
};

export default generateProductPartsCatalogPdf;
