import { useState, useEffect, ChangeEvent } from 'react';
import {
  Product,
  FindAllProducts,
  UpdateProductStatusDto,
} from '../../types/products';
import { useMutation } from 'react-query';
import { updateProductStatus } from '../../services/products';
import { useQueryCache } from '../../hooks/useQueryCache';
import { productsLineCategoryCache } from '../../constants/requestCacheName';
import { getAuth } from '../../utils/auth';

export const useHomeCard = (product: Product) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  const { updateItemOnScreen } = useQueryCache();

  const { mutate: updateProductStatusMutate } = useMutation({
    mutationFn: async (dto: UpdateProductStatusDto) => {
      await updateProductStatus(dto);
      return dto;
    },

    onSuccess: (data: UpdateProductStatusDto) => {
      product[data.field] = data.value;
      updateItemOnScreen<FindAllProducts>(productsLineCategoryCache, product);
    },

    onError: () => {
      alert('Falha ao atualizar o status do produto');
    },
  });

  useEffect(() => {
    setIsImageLoading(true);

    const timeout = setTimeout(() => {
      setIsImageLoading(false);
    }, 1000);

    return () => {
      setIsImageLoading(false);
      clearTimeout(timeout);
    };
  }, [product]);

  const handleImageWasLoaded = () => {
    setIsImageLoading(false);
  };

  const handleLoadStart = () => {
    setIsImageLoading(true);
  };

  const handleCheckField = (
    event: ChangeEvent<HTMLInputElement>,
    productId: number,
  ) => {
    const field = event.target.name;
    if (
      field === 'reviewedApproved' ||
      field === 'new' ||
      field === 'outOfLine'
    ) {
      updateProductStatusMutate({
        productId,
        field,
        value: event.target.checked,
      });
    }
  };

  const auth = getAuth();

  return {
    isImageLoading,
    auth,
    handleImageWasLoaded,
    handleLoadStart,
    handleCheckField,
  };
};
