import { AxiosResponse } from 'axios';
import { Pagination } from '../types/pagination';
import { Param } from '../types/params';
import { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';
import { api } from './api';

export type Search = <Item>(
  baseUrl: string,
  search: string,
  page: number,
  direction?: 'ASC' | 'DESC' | null,
  limit?: number | null,
  params?: Param[],
) => Promise<AxiosResponse<Pagination<Item>, any>>;

export const search: Search = async <Item>(
  baseUrl: string,
  search: string,
  page: number,
  direction: 'ASC' | 'DESC' | null = 'ASC',
  limit?: number | null,
  params?: Param[],
) => {
  const url = addSearchParamsInUrl(
    // TODO Remover o /search e implementar pesquisa nos endpoints normais
    `${baseUrl}/search`,
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    ...(params ?? []),
  ).concat(`&s=${search}`);

  const response = await api.get<Pagination<Item>>(url);
  console.log("🚀 ~ response:", response)
  return response;
};
