import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import EditorMenuButton from './EditorMenuButton';
import { Editor } from '@tiptap/react';
import { useEditorMenu } from './useEditorMenu';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';

type EditorMenuProps = {
  editor: Editor | null;
  disabled?: boolean;
  showColorBox?: boolean;
  showBold?: boolean;
  showTextDecoration?: boolean;
  showTextAlign?: boolean;
  showFontStyle?: boolean;
  onButtonClick?: OnButtonClick;
};

export type ButtonEvent = React.MouseEvent<HTMLLIElement, MouseEvent>;

export type MenuButtonProps = {
  type: EditorMenuButtonType;
  color?: string;
};

export type OnButtonClick = (props: MenuButtonProps) => void;

export type EditorMenuButtonType =
  | 'bold'
  | 'italic'
  | 'color'
  | 'underline'
  | 'alignLeft'
  | 'alignCenter'
  | 'alignRight';

export default function EditorMenu({
  editor,
  disabled,
  showColorBox = false,
  showTextAlign = false,
  showBold = true,
  showTextDecoration = true,
  showFontStyle = true,
  onButtonClick,
}: EditorMenuProps) {
  const {
    handleBold,
    handleItalic,
    handleUnderline,
    handleColor,
    handleTextAlignLeft,
    handleTextAlignCenter,
    handleTextAlignRight,
  } = useEditorMenu(editor, !disabled, onButtonClick);

  return (
    <ul className="flex items-center gap-2 border p-2">
      <EditorMenuButton
        className={showBold ? '' : 'hidden'}
        content={<FormatBoldIcon />}
        onClick={handleBold}
        disabled={disabled}
      />
      <EditorMenuButton
        className={showFontStyle ? '' : 'hidden'}
        content={<FormatItalicIcon />}
        onClick={handleItalic}
        disabled={disabled}
      />
      <EditorMenuButton
        className={showTextDecoration ? '' : 'hidden'}
        disabled={disabled}
        content={<FormatUnderlinedIcon />}
        onClick={handleUnderline}
      />
      <EditorMenuButton
        className={showColorBox ? '' : 'hidden'}
        disabled={disabled}
        content={
          <input
            type="color"
            onInput={(event) => handleColor(event)}
            value={editor?.getAttributes('textStyle').color || '#000000'}
            data-testid="setColor"
            disabled={disabled}
          />
        }
      />
      <EditorMenuButton
        disabled={disabled}
        className={showTextAlign ? '' : 'hidden'}
        content={<FormatAlignLeftIcon />}
        onClick={handleTextAlignLeft}
      />
      <EditorMenuButton
        disabled={disabled}
        className={showTextAlign ? '' : 'hidden'}
        content={<FormatAlignCenterIcon />}
        onClick={handleTextAlignCenter}
      />
      <EditorMenuButton
        disabled={disabled}
        className={showTextAlign ? '' : 'hidden'}
        content={<FormatAlignRightIcon />}
        onClick={handleTextAlignRight}
      />
    </ul>
  );
}
