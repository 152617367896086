import { ContactUsFormData } from '../buffalos/types/contactUs';
import { ContactStatus } from '../enums/contactStatus';
import { City } from '../types/city';
import { ContactDemandPaginated, ContactUs } from '../types/contactUs';
import { Subject } from '../types/subject';
import { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const saveContact = async ({
  cpf,
  email,
  message,
  name,
  city,
  company,
  phone,
  state,
  domainId,
  subjects,
}: Omit<ContactUsFormData, 'city'> & {
  city: City | null;
  domainId: string;
  subjects: Subject[];
}) => {
  const body = {
    nome: name,
    email: email,
    telefone: phone || null,
    cpf: cpf || null,
    empresa: company || null,
    cidade: city,
    stateUF: state || null,
    mensagem: message,
    domainId,
    subjects,
  };

  const response = await api.post<ContactUs>('/contact/v1', body);
  return response;
};

export const getContactDemandsPaginated = async (
  page: number,
  direction?: 'ASC' | 'DESC',
  limit?: number | null,
  status: ContactStatus | string = ContactStatus.PENDENTE,
  domain?: string | null,
  subject?: string | null,
) => {
  const url = addSearchParamsInUrl(
    '/contact/v1',
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    { name: 'status', value: status },
    { name: 'domain', value: domain },
    { name: 'subject', value: subject },
  );
  const response = await api.get<ContactDemandPaginated>(url);
  return response;
};

export const updateContact = async (
  contactId: number,
  resposta: string,
  status: ContactStatus,
  domainId: number,
) => {
  const body = {
    id: contactId,
    resposta: resposta,
    status: status,
    domainId: domainId,
  };
  const response = await api.put('/contact/v1/conclude', body);
  return response;
};

export const archiveContact = async (contactId: number) => {
  const response = await api.put(`/contact/v1/${contactId}`);
  return response;
};

// export const deleteContact = async (contactId: number) => {
//   const response = await api.delete(`/contact/v1/${contactId}`);
//   return response;
// };
