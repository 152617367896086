import { useCallback, useRef } from 'react';
import { useModal } from '../../../hooks/useModal';
import { useEdit } from '../../../hooks/useEdit';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  CreateImageExtensionDto,
  FindAllImageExtensions,
  ImageExtension,
  ImageExtensionFormData,
  UpdateImageExtensionDto,
} from '../../../types/imageExtension';
import { imageExtensionSchema } from '../../../validations/schemas';
import { useMutation } from 'react-query';
import { imageExtensionSearchBaseEndpoint } from '../../../constants/endpoints';
import { searchCache } from '../../../constants/requestCacheName';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { useQueryCache } from '../../../hooks/useQueryCache';
import {
  deleteImageExtension,
  saveImageExtension,
  updateImageExtension,
} from '../../../services/imageExtension';
import { useError } from '../../../hooks/useError';
import { AxiosError } from 'axios';

export const useImageExtension = () => {
  const imageExtensionIdUpdate = useRef<number | null>(null);

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<ImageExtensionFormData>({
    resolver: zodResolver(imageExtensionSchema),
    defaultValues: {
      name: '',
      extension: '',
      fileType: 'Imagem',
    },
  });

  const name = watch('name');
  const extension = watch('extension');

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: saveImageExtensionMutate } = useMutation({
    mutationFn: async (dto: CreateImageExtensionDto) =>
      (await saveImageExtension(dto)).data,

    onSuccess: (data: ImageExtension) => {
      handleCloseManageModal();
      addItemOnScreen<FindAllImageExtensions>(
        `${searchCache}${imageExtensionSearchBaseEndpoint}`,
        {
          id: data.id,
          name: data.name,
          extension: data.extension,
          fileType: data.fileType,
        },
      );
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar extensão',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Extensão já cadastrada');
            return true;
          }
        },
      );
    },
  });

  const { mutate: updateImageExtensionMutate } = useMutation({
    mutationFn: async (dto: UpdateImageExtensionDto) =>
      (await updateImageExtension(dto)).data,

    onSuccess: (data: ImageExtension) => {
      // handleCloseManageModal();
      handleUnedit();
      updateItemOnScreen<FindAllImageExtensions>(
        `${searchCache}${imageExtensionSearchBaseEndpoint}`,
        {
          id: data.id,
          name: data.name,
          extension: data.extension,
          fileType: data.fileType,
        },
      );
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar extensão',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Extensão já cadastrada');
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteImageExtensionMutate } = useMutation({
    mutationFn: async () => {
      if (imageExtensionIdUpdate.current) {
        await deleteImageExtension(imageExtensionIdUpdate.current);
        return;
      }

      setErrorMessage('Falha ao deletar extensão');
    },

    onSuccess: () => {
      if (imageExtensionIdUpdate.current) {
        removeItemFromScreen<FindAllImageExtensions>(
          `${searchCache}${imageExtensionSearchBaseEndpoint}`,
          imageExtensionIdUpdate.current,
        );
      }

      handleCloseManageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar extensão',
        setErrorMessage,
      );
    },
  });

  const {
    isModalOpen: isManageModalOpen,
    handleCloseModal: closeManageModal,
    handleOpenModal: handleOpenManageModal,
  } = useModal();

  const handleTableRowClick = useCallback(
    (imageExtension: ImageExtension) => {
      imageExtensionIdUpdate.current = imageExtension.id;

      setValue('name', imageExtension.name);
      setValue('extension', imageExtension.extension);
      setValue('fileType', imageExtension.fileType);

      handleOpenManageModal();
    },
    [handleOpenManageModal, setValue],
  );

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: handleCloseConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  const handleDeleteButtonClick = useCallback(() => {
    handleOpenConfirmDeleteModal();
  }, [handleOpenConfirmDeleteModal]);

  const handleConfirmDeleteSignature = useCallback(() => {
    deleteImageExtensionMutate();
    handleCloseConfirmDeleteModal();
  }, [deleteImageExtensionMutate, handleCloseConfirmDeleteModal]);

  const handleAddImageExtensionClick = useCallback(() => {
    handleOpenManageModal();
  }, [handleOpenManageModal]);

  const handleCloseManageModal = useCallback(() => {
    imageExtensionIdUpdate.current = null;
    reset();
    handleUnedit();
    closeManageModal();
  }, [closeManageModal, handleUnedit, reset]);

  const handleButtonOkClick = handleSubmit(
    ({ name, extension, fileType }: ImageExtensionFormData) => {
      if (imageExtensionIdUpdate.current) {
        updateImageExtensionMutate({
          id: imageExtensionIdUpdate.current,
          name,
          extension,
          fileType,
        });
        return;
      }

      saveImageExtensionMutate({ name, extension, fileType });
    },
  );

  const handleEditButtonClick = useCallback(() => {
    handleEdit();
  }, [handleEdit]);

  const handleClearField = (fieldName: keyof ImageExtensionFormData) => {
    setValue(fieldName, '', { shouldDirty: true });
  };

  const handleFileTypeClick = (fileTypeName: string) => {
    setValue('fileType', fileTypeName, { shouldDirty: true });
  };

  const isShowEditButton = !!imageExtensionIdUpdate.current && !canEdit;

  return {
    extension,
    name,
    isManageModalOpen,
    imageExtensionIdUpdate: imageExtensionIdUpdate.current,
    isShowEditButton,
    errors,
    dirtyFields,
    errorMessage,
    isConfirmDeleteModalOpen,
    handleFileTypeClick,
    handleConfirmDeleteSignature,
    handleCloseConfirmDeleteModal,
    clearError,
    handleClearField,
    register,
    handleEditButtonClick,
    handleButtonOkClick,
    handleDeleteButtonClick,
    handleTableRowClick,
    handleAddImageExtensionClick,
    handleCloseManageModal,
  };
};
