import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import Loading from '../Loading';
import Main from '../Main';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { useImagesContent } from './useImagesContent';
import { imageTypesText } from '../../constants/imageTypes';
import { ImageTypes } from '../../enums/imageTypes';
import LoadMore from '../LoadMore';
import ListingHeaderModal from '../ListingHeaderModal';
import { ItemInUse } from '../../types/inUse';
import CheckIcon from '@mui/icons-material/Check';
import Filter from '../Filter';

export type ImagesContentProps = {
  isModal?: boolean;
  itemsInUse?: (ItemInUse | Record<'id', string>)[];
  imageType?: ImageTypes | null;
  onAddImageClick?: () => void;
  onTableRowClick: (
    imageId: number,
    imageName: string,
    imageType: string,
    imageUrl: string,
  ) => void;
};

export default function ImagesContent({
  isModal,
  itemsInUse,
  imageType,
  onTableRowClick,
  onAddImageClick,
}: ImagesContentProps) {
  const {
    inputRef,
    isSearchInputDirty,
    imagesShown,
    sortField,
    typeImage,
    typeImageActive,
    imagesLength,
    isFetchingImages,
    imagesShownLength,
    tableColumnNameWidth,
    typeColWidth,
    endPageRef,
    isLoadMoreFetching,
    handleFilterByTypeImage,
    handleSortTable,
    handleCancelSearch,
    handleEnterClickSearchInput,
    handleSearch,
    handleSearchDatabase,
  } = useImagesContent({ isModal, imageType});

  return (
    <>
      {isModal && (
        <ListingHeaderModal
          title="Imagens"
          inputRef={inputRef}
          isSearchInputDirty={isSearchInputDirty}
          onCancelSearch={handleCancelSearch}
          onSearch={handleSearch}
          onButtonClick={handleSearchDatabase}
          onKeyDown={handleEnterClickSearchInput}
        >
          <Table.Head rowStyle="px-8">
            <div className="flex gap-4">
              <Table.Cell
                items={imagesShown}
                type="name"
                onSort={handleSortTable}
                isSortActive={sortField === 'name'}
                style={{
                  // width: `${!isModal ? tableColumnNameWidth : '30'}ch`,
                  width: `${tableColumnNameWidth}ch`,
                }}
              >
                Imagem
              </Table.Cell>
              <Table.CellStatic className="w-16 text-center">
                <span className="w-full text-center">Largura</span>
              </Table.CellStatic>
              <Table.CellStatic className="w-16">
                <span className="w-full text-center">Altura</span>
              </Table.CellStatic>
            </div>
            <Table.Cell
              items={imagesShown}
              type="type"
              onSort={handleSortTable}
              isSortActive={sortField === 'type'}
              style={{ width: `${typeColWidth}ch` }}
            >
              Tipo
            </Table.Cell>
          </Table.Head>
        </ListingHeaderModal>
      )}
      {!isModal && (
        <ListingHeader
          add
          onAddIconClick={onAddImageClick}
          pdfTop="3rem"
          pdf={false}
        >
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">Imagem</h1>
            <Filter
              title="Tipo imagem"
              filters={[
                {
                  filter: 'todos',
                  isCurrent: !typeImageActive || typeImageActive === 'todos',
                  name: 'Todos',
                },
                ...(typeImage?.items ?? []).map((imageType) => ({
                  filter: imageType.name,
                  isCurrent: typeImageActive === imageType.name,
                  name:
                    imageTypesText[imageType.name as ImageTypes] ??
                    imageType.name,
                })),
              ]}
              onFilter={handleFilterByTypeImage}
            />
            <SearchInput
              ref={inputRef}
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
              onSearch={handleSearch}
              onButtonClick={handleSearchDatabase}
              onKeyDown={handleEnterClickSearchInput}
              buttonContent="Buscar"
            />
          </div>
          <Table.Head>
            <div className="flex gap-4">
              <Table.Cell
                items={imagesShown}
                type="name"
                onSort={handleSortTable}
                isSortActive={sortField === 'name'}
                style={{
                  // width: `${!isModal ? tableColumnNameWidth : '30'}ch`,
                  width: `${tableColumnNameWidth}ch`,
                }}
              >
                Imagem
              </Table.Cell>
              <Table.Cell items={imagesShown} className="w-16 text-center">
                <span className="w-full text-center">Largura</span>
              </Table.Cell>
              <Table.Cell items={imagesShown} className="w-16">
                <span className="w-full text-center">Altura</span>
              </Table.Cell>
            </div>
            <Table.Cell
              items={imagesShown}
              type="type"
              onSort={handleSortTable}
              isSortActive={sortField === 'type'}
              style={{ width: `${typeColWidth}ch` }}
            >
              Tipo
            </Table.Cell>
          </Table.Head>
        </ListingHeader>
      )}

      <Main
        isModal={isModal}
        data-modal={isModal}
        className="pb-20 data-[modal=true]:px-0"
      >
        {isFetchingImages && <Loading loadMore={!isModal} />}
        {!isFetchingImages && imagesShown && (
          <Table.Root
            itemType="imagens"
            itemsLength={imagesShownLength}
            className={`${isModal ? 'max-h-[60vh]' : ''}`}
          >
            {imagesShown.map((images) => {
              const isInUse = itemsInUse?.some((item) => item.id === images.id);

              return (
                <div key={images.id}>
                  <Table.Row
                    onClick={() =>
                      !isInUse &&
                      onTableRowClick(
                        images.id,
                        images.name,
                        images.type.name,
                        images.url,
                      )
                    }
                    className={isInUse ? 'cursor-default opacity-50' : ''}
                  >
                    <div className="flex gap-4">
                      <Table.CellStatic
                        style={{
                          width: `${tableColumnNameWidth - 9}ch`,
                        }}
                      >
                        {images.name}
                      </Table.CellStatic>
                      <Table.CellStatic className="w-16">
                        <span className="w-full text-center">
                          {images.width}
                        </span>
                      </Table.CellStatic>
                      <Table.CellStatic className="w-16">
                        <span className="w-full text-center">
                          {images.height}
                        </span>
                      </Table.CellStatic>
                    </div>

                    <Table.CellStatic
                      style={{ width: `${typeColWidth - 2.25}ch` }}
                    >
                      {imageTypesText[images.type.name as ImageTypes] ??
                        images.type.name}
                    </Table.CellStatic>
                    {isInUse && (
                      <CheckIcon className="absolute right-4 text-primary" />
                    )}
                  </Table.Row>
                </div>
              );
            })}
            <LoadMore
              isModal={!!isModal}
              isLoadMoreFetching={isLoadMoreFetching}
              isContentFetching={isFetchingImages}
              ref={endPageRef}
            />
          </Table.Root>
        )}
      </Main>

      <Footer
        current={imagesShown.length}
        total={imagesLength}
        itemType="Imagens"
        data-modal={isModal}
        className={
          isModal
            ? 'absolute mx-[1px] mb-[1px] mt-[-10vh] h-28 rounded-b-3xl'
            : ''
        }
      />
    </>
  );
}
