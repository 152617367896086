import { pagesSearchBaseEndpoint } from '../../constants/endpoints';
import { useHandleTable } from '../../hooks/useHandleTable';
import { Page } from '../../types/pages';

export const usePagesContent = () => {
  const {
    sortField,
    inputRef,
    isSearchInputDirty,
    itemsLength: pagesLength,
    itemsShownLength: pagesShownLength,
    itemsShown,
    isSearchFetching,
    isSearch,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
  } = useHandleTable(0, [], null, null, pagesSearchBaseEndpoint);
  const pagesShown: Page[] = itemsShown;

  return {
    inputRef,
    pagesShown,
    isSearchInputDirty,
    pagesLength,
    pagesShownLength,
    sortField,
    isFetching: isSearchFetching,
    isSearch,
    handleSortTable,
    handleSearch,
    handleCancelSearch,
  };
};
