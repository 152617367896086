
export const DefaultValuesCoordinate ={
  bold: 'normal',
  fontStyle: 'normal',
  textDecoration: 'normal',
  color: '#000',
  fontSize: 10,
  textAlign: 'left',
  isVisible: true,
} as const
