import { api } from '../../services/api';
import { addSearchParamsInUrl } from '../../utils/generatedPaginatedUrl';
import { GroupItemsPaginated } from '../types/partGroupItems';

export const searchPartsAndGroup = async (
  productId: number,
  page?: number,
  limit?: number | null,
  search?: string,
) => {
  const url = addSearchParamsInUrl(
    `/parts-group-item/v1/search`,
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'search', value: search },
    { name: 'productId', value: productId },
  );

  const response = await api.get<GroupItemsPaginated>(url);

  return response;
};
