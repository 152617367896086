import { useQuery } from 'react-query';
import { fieldsDomainsCoordinateCache } from '../../constants/requestCacheName';
import { ReactNode, useEffect, useState } from 'react';
import { getAllCoordinatesByDomainId } from '../../services/signatureDomain';
import {
  Coordinate,
  FindAllCoordinates,
  TextAlignValues,
} from '../../types/domainImageCoordinate';
import { EmailSignature } from '../../types/emailSignature';
import { Domain } from '../../types/domain';
import { formatCep, formatPhoneNumber } from '../../utils/format';
import { SetIsLoading } from '.';

export const useEmailSignatureItem = (
  signature: EmailSignature,
  domain: Domain,
  setIsLoading?: SetIsLoading,
) => {
  const formatAddress = (domain: Domain, join?: string) => {
    const addressParts = [
      domain.street && `${domain.street}`,
      domain.number && `, nº ${domain.number}`,
      domain.neighborhood && ` - ${domain.neighborhood}`,
      domain.city && `, ${domain.city}`,
      domain.uf && ` - ${domain.uf}`,
      domain.cep && `, ${formatCep(domain.cep)}`,
    ];

    return addressParts.filter(Boolean).join(join ?? '');
  };

  const vCard = [
    'BEGIN:VCARD',
    'VERSION:3.0',
    `FN:${signature.fullName}`,
    `ADR:;;${formatAddress(domain, '')};`,
    signature.cellPhoneNumber
      ? `TEL:${signature.cellPhoneNumber.slice(-11)}`
      : '',
    signature.phoneNumber
      ? `TEL;TYPE=home:${signature.phoneNumber.slice(-10)}`
      : '',
    `EMAIL:${signature.email}`,
    `TITLE:${signature.department}`,
    'END:VCARD',
  ].join('\n');

  const qrCodeAlignMapper = (align: TextAlignValues | undefined) => {
    const mapper: Record<
      TextAlignValues,
      'flex-start' | 'center' | 'flex-end'
    > = {
      center: 'center',
      left: 'flex-start',
      right: 'flex-end',
    };

    return mapper[align ?? 'left'];
  };

  const signatureMapper = (fieldId: number, qrCode: ReactNode) => {
    const mapper: Record<number, string | ReactNode> = {
      1: signature.fullName,
      2: signature.department,
      3: formatPhoneNumber(signature.phoneNumber),
      4: formatPhoneNumber(signature.cellPhoneNumber),
      5: signature.email,
      // 6: `${domain.street}, nº ${domain.number} - ${domain.neighborhood},${' '}
      // ${domain.city} - ${domain.uf}, ${formatCep(domain.cep ?? '')}`,
      6: formatAddress(domain),
      7: qrCode,
      8: signature.englishDepartament,
    };

    return mapper[fieldId];
  };

  const [fieldsDomainCoordinates, setFieldsDomainCoordinates] = useState<
    Coordinate[]
  >([]);

  const { isFetching } = useQuery<FindAllCoordinates>(
    `${fieldsDomainsCoordinateCache}${domain.id}`,
    async () => {
      return (await getAllCoordinatesByDomainId(domain.id)).data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const dataWithoutHidden = data.items.filter(
          (coordinate) => coordinate.isVisible,
        );
        setFieldsDomainCoordinates(dataWithoutHidden);
      },
    },
  );

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(isFetching);
    }
  }, [isFetching, setIsLoading]);

  return {
    vCard,
    fieldsDomainCoordinates,
    qrCodeAlignMapper,
    signatureMapper,
  };
};
