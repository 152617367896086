import {
  deleteObject,
  listAll,
  ref,
  uploadBytes,
  uploadBytesResumable,
  UploadResult,
} from 'firebase/storage';
import { storage } from '../libs/firebase';
import {
  Document,
  DocumentsPaginated,
  SaveDocumentsDto,
  UpdateDocumentDto,
} from '../types/document';
import generatePaginatedUrl, {
  addSearchParamsInUrl,
} from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getAllDocumentsPaginated = async (
  page?: number | null,
  direction?: 'ASC' | 'DESC' | null,
  limit?: number | null,
  typeDocument?: string,
) => {
  const url = addSearchParamsInUrl(
    '/document/v1/search',
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    { name: 'typeDocument', value: typeDocument },
  );
  const response = await api.get<DocumentsPaginated>(url);
  return response;
};

export const getAllDocumentsVinculatablePaginated = async (
  productId: number,
  page?: number,
  direction?: 'ASC' | 'DESC',
  limit?: number,
) => {
  const url = generatePaginatedUrl(
    `/document/v1/vinculatable/product-id/${productId}`,
    page,
    limit,
    direction,
  );
  const response = await api.get<DocumentsPaginated>(url);
  return response;
};

export const getAllDocuments = async (documentsList: string[]) => {
  const documentsResponse = await Promise.all(
    documentsList.map((document) => {
      const documentRef = ref(storage, `documents/${document}`);
      return listAll(documentRef);
    }),
  );

  const documentsNameList = documentsResponse.map((res, index) =>
    res.items.map((item) => {
      return { name: item.name, type: documentsList[index] };
    }),
  );

  return {
    items: documentsNameList.flat(),
    meta: {
      totalItems: documentsNameList.length,
    },
  };
};

export const uploadDocumentStorage = (
  documentType: string,
  itemId: string,
  file: File,
) => {
  const storageRef = ref(
    storage,
    `documents/${decodeURIComponent(documentType)}/${decodeURIComponent(itemId)}`,
  );
  const response = uploadBytesResumable(storageRef, file);
  return response;
};

export const uploadDocumentsStorage = async (
  documentType: string,
  files: FileList,
  saveDocument: () => void | Promise<void>,
  fileName?: string | null,
) => {
  try {
    const promises: Promise<UploadResult>[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const storageRef = ref(
        storage,
        `documents/${decodeURIComponent(documentType)}/${decodeURIComponent(fileName ?? file.name)}`,
      );
      const response = uploadBytes(storageRef, file);
      promises.push(response);
    }

    await Promise.all(promises);
    await saveDocument();
  } catch (error) {
    const promises: Promise<string | undefined>[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const response = deleteDocumentStorage(
        fileName ?? file.name,
        documentType,
      );
      promises.push(response);
    }

    await Promise.all(promises);

    throw error;
  }
};

export const saveDocument = async ({ name, type, url }: SaveDocumentsDto) => {
  const body = { name, type, url };

  const response = await api.post<Document>('/document/v1', body);
  return response;
};

export const saveDocuments = async (dto: SaveDocumentsDto[]) => {
  const body = { documents: dto };

  const response = await api.post<void>('/document/v1/batch', body);
  return response;
};

export const updateDocument = async ({
  id,
  name,
  type,
  url,
}: UpdateDocumentDto) => {
  const body = { id, name, type, url };

  const response = await api.put<Document>('/document/v1', body);
  return response;
};

export const deleteDocumentStorage = async (
  documentReference: string,
  documentType: string,
) => {
  const documentRef = ref(
    storage,
    `documents/${documentType}/${decodeURIComponent(documentReference)}`,
  );
  try {
    await deleteObject(documentRef);
  } catch (error) {
    return `Falha ao deletar documento. ${JSON.stringify(error)}`;
  }
};

export const deleteDocument = async (documentId: number) => {
  const response = await api.delete(`/document/v1/${documentId}`);
  return response;
};
