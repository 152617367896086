import { useModal } from '../../../hooks/useModal';
import { useTitle } from '../../../hooks/useTitle';
import { useEdit } from '../../../hooks/useEdit';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { questionsSchema } from '../../../validations/schemas';
import {
  questionsCache,
  searchCache,
} from '../../../constants/requestCacheName';
import { useMutation } from 'react-query';
import { AxiosError, isAxiosError } from 'axios';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import {
  FindAllQuestions,
  QuestionsFormData,
  SaveQuestion,
  UpdateQuestion,
} from '../../../types/questions';
import {
  deleteQuestion,
  saveQuestion,
  updateQuestion,
} from '../../../services/questions';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import { useRef } from 'react';
import { questionSearchBaseEndpoint } from '../../../constants/endpoints';

export const useQuestions = () => {
  useTitle('Perguntas frequentes');

  const questionIdUpdate = useRef<number | null>(null);

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<QuestionsFormData>({
    resolver: zodResolver(questionsSchema),
    defaultValues: {
      pergunta: '',
      resposta: '',
      status: false,
      ordem: null,
    },
  });


  const pergunta = watch('pergunta')

  const status = watch('status') ?? false;

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const isShowEditButton = !!questionIdUpdate.current && !canEdit;

  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content: '',
    editorProps: {
      // editable: () => !isShowEditButton,
      attributes: {
        class: 'border p-2 outline-none flex-1 min-h-80',
      },
    },
  });

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: saveQuestionMutate } = useMutation({
    mutationFn: async ({ pergunta, resposta, status, ordem }: SaveQuestion) => {
      return (await saveQuestion({ pergunta, resposta, status, ordem })).data;
    },

    onSuccess: (data) => {
      addItemOnScreen<FindAllQuestions>(
        `${searchCache}${questionSearchBaseEndpoint}`,
        data,
      );
      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar pergunta',
        setErrorMessage,
        () => {
          if (
            isAxiosError(error) &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Pergunta já cadastrada');
            return true;
          }
        },
      );
    },
  });

  const { mutate: updateQuestionMutate } = useMutation({
    mutationFn: async ({
      id,
      pergunta,
      resposta,
      status,
      ordem,
    }: UpdateQuestion) => {
      return (await updateQuestion({ id, pergunta, resposta, status, ordem }))
        .data;
    },

    onSuccess: (data) => {
      updateItemOnScreen<FindAllQuestions>(
        `${searchCache}${questionSearchBaseEndpoint}`,
        data,
      );
      // handleCloseMenageModal();
      handleUnedit()
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar pergunta',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Pergunta já cadastrada');
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteUserMutate } = useMutation({
    mutationFn: async () => {
      await deleteQuestion({ questionId: questionIdUpdate.current! });
    },

    onSuccess: () => {
      removeItemFromScreen<FindAllQuestions>(
        `${searchCache}${questionSearchBaseEndpoint}`,
        questionIdUpdate.current!,
      );
      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar pergunta',
        setErrorMessage,
        () => {
          if (isAxiosError(error) && error.response?.data.statusCode === 409) {
            setErrorMessage(
              'Essa pergunta só pode ser apagado depois que não houver associações a ela',
            );
            return true;
          }
        },
      );
    },
  });

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: closeMenageModal,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: handleCloseConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  const handleAddUserTypesClick = () => {
    handleOpenMenageModal();
  };

  const handleTableRowClick = (
    questionId: number,
    pergunta: string,
    resposta: string,
    status: boolean,
    ordem: number,
  ) => {
    questionIdUpdate.current = questionId;
    setValue('pergunta', pergunta);
    setValue('resposta', resposta);
    setValue('status', status);
    setValue('ordem', ordem);

    if (editor) {
      editor.commands.setContent(resposta);
    }

    handleOpenMenageModal();
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmDeleteModal();
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('status', event.target.checked, { shouldDirty: true });
  };

  const handleButtonOkClick = ({
    pergunta,
    status,
    ordem,
  }: QuestionsFormData) => {
    const resposta = editor?.getHTML() ?? '';

    if (questionIdUpdate.current) {
      updateQuestionMutate({
        id: questionIdUpdate.current,
        pergunta: pergunta,
        resposta: resposta,
        status: status,
        ordem: Number(ordem),
      });
      return;
    }

    saveQuestionMutate({
      pergunta: pergunta,
      resposta: resposta,
      status: status,
      ordem: Number(ordem),
    });
  };

  const handleEditButtonClick = () => {
    handleEdit();
  };

  const handleCloseMenageModal = () => {
    questionIdUpdate.current = null;
    editor?.commands.setContent('');
    reset();
    handleUnedit();
    closeMenageModal();
  };

  const handleConfirmDeleteUser = () => {
    deleteUserMutate();
    handleCloseConfirmDeleteModal();
  };

  const handleClearField = (fieldName: 'pergunta' | 'resposta') => {
    setValue(fieldName, '', { shouldDirty: true });
  };

  const handleFalseField = (fieldName: 'status') => {
    setValue(fieldName, false, { shouldDirty: true });
  };

  const handleResetOrderField = (fieldName: 'ordem') => {
    setValue(fieldName, 0, { shouldDirty: true });
  };

  return {
    isMenageModalOpen,
    isShowEditButton,
    questionIdUpdate: questionIdUpdate.current,
    errors,
    dirtyFields,
    isConfirmDeleteModalOpen,
    errorMessage,
    pergunta,
    status,
    clearError,
    handleConfirmDeleteUser,
    handleCloseConfirmDeleteModal,
    handleClearField,
    handleFalseField,
    handleResetOrderField,
    handleEditButtonClick,
    register,
    handleSubmit,
    handleButtonOkClick,
    handleDeleteButtonClick,
    handleTableRowClick,
    handleAddUserTypesClick,
    handleCloseMenageModal,
    handleStatusChange,
    editor,
  };
};
