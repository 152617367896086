import { LabelSizes } from '../types/productBarCode';

export const labelSizes: LabelSizes = {
  p: {
    columns: 7,
    row: 18,
    width: 71,
    height: 17,
    fontSize: 4,
    pageMarginTop: 43,
    pageMarginBottom: 27,
    marginLeft: 3,
    gap: 2.2,
    appleMarginLeftToIncrease: -14,
    applePageMarginTopToIncrease: -13.5,
    applePageMarginBottomToIncrease: -17,
    appleGapToIncrease: 0.4,
    appleFontSizeToIncrease: 0.5,
    appleWidthToIncrease: 3.6,
  },
  m: {
    columns: 5,
    row: 13,
    width: 107,
    height: 30,
    fontSize: 6,
    pageMarginTop: 30,
    pageMarginBottom: 19,
    marginLeft: -8,
    gap: 2.1,
    appleMarginLeftToIncrease: -14,
    applePageMarginTopToIncrease: -13.2,
    applePageMarginBottomToIncrease: -19,
    appleGapToIncrease: 1.3,
    appleFontSizeToIncrease: 0.5,
    appleWidthToIncrease: 4.5,
  },
  g: {
    columns: 3,
    row: 9,
    width: 178,
    height: 50,
    fontSize: 8,
    pageMarginTop: 30,
    pageMarginBottom: 9,
    marginLeft: 0,
    gap: 5,
    appleMarginLeftToIncrease: -10,
    applePageMarginTopToIncrease: -13.2,
    applePageMarginBottomToIncrease: -9,
    appleGapToIncrease: 2.2,
    appleFontSizeToIncrease: 0.5,
    appleWidthToIncrease: 10,
  },
  cc: {
    columns: 5,
    row: 13,
    width: 107,
    height: 30,
    fontSize: 6,
    pageMarginTop: 30,
    pageMarginBottom: 19,
    marginLeft: -8,
    gap: 2.1,
    appleMarginLeftToIncrease: -14,
    applePageMarginTopToIncrease: -13.2,
    applePageMarginBottomToIncrease: -19,
    appleGapToIncrease: 1.3,
    appleFontSizeToIncrease: 0.5,
    appleWidthToIncrease: 4.5,
  },
};
