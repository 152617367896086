import { useQuery } from 'react-query';
import { productsApiCache } from '../../../constants/requestCacheName';
import { getRelatedProductByCategoryIdAndDomainId } from '../../../services/products';
import {
  FindAllProducts,
  Product,
  ProductSort,
  ProductsSort,
} from '../../../types/products';
import { useMemo, useState } from 'react';
import { ResponsiveType } from 'react-multi-carousel';
import { getCurrentDomain } from '../../../utils/domain';

export const useCarouselBuffalo = (categoryId: number, productId: number) => {
  const [carouselProductsShown, setCarouselProductsShown] = useState<Product[]>(
    [],
  );

  useQuery<FindAllProducts>(
    `${productsApiCache}${productId}`,
    async () =>
      (
        await getRelatedProductByCategoryIdAndDomainId(
          categoryId,
          getCurrentDomain().id,
          { excludeProductId: productId, reviewedApproved: true },
        )
      ).data,
    {
      cacheTime: 0,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const shuffledProducts = data.items.sort(() => Math.random() - 0.5);
        setCarouselProductsShown(shuffledProducts);
      },
    },
  );

  const [isImageLoading, setIsImageLoading] = useState(true);

  const handleImageWasLoaded = () => {
    setIsImageLoading(false);
  };

  const handleLoadStart = () => {
    setIsImageLoading(true);
  };

  const cardsOnScreen = useMemo(() => {
    const screenWidthInPx = window.innerWidth;
    const spacingTotalWidthInPx = 100;
    const cardWidthInPx = 350;
    const maxCardsOnScreen = Math.floor(
      (screenWidthInPx - spacingTotalWidthInPx) / cardWidthInPx,
    );

    const spacingsBetweenCards = 16 * 2 * maxCardsOnScreen;
    const cardsOnScreen = Math.floor(
      (screenWidthInPx - (spacingTotalWidthInPx + spacingsBetweenCards)) /
        cardWidthInPx,
    );

    return cardsOnScreen > 1 ? cardsOnScreen : 1;
  }, []);

  const responsive: ResponsiveType = {
    screen: {
      breakpoint: { min: 0, max: 5000 },
      items: cardsOnScreen,
      slidesToSlide: cardsOnScreen,
    },
  };

  return {
    responsive,
    isImageLoading,
    carouselProductsShown: carouselProductsShown,
    carouselProductsShownLength: carouselProductsShown.length,
    handleImageWasLoaded,
    handleLoadStart,
  };
};
