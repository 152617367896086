import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { common, grey } from '@mui/material/colors';

export const partsCatalogTableStyle: SxProps<Theme> = {
  '@media (max-width: 1100px)': {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    padding: '0 0.5rem 0 4.5rem',
  },
};

export const selectPartsGroupStyle: SxProps<Theme> = {
  height: '3rem',
  margin: '1rem 0',
  backgroundColor: common['white'],
  fontSize: '0.8rem',
  flex: 1,
};

export const tableContainerStyles: SxProps<Theme> = {
  maxWidth: '32rem',
  maxHeight: 'calc(100vh - 14rem)',
  backgroundColor: common['white'],
  mb: '0.5rem',

  '@media(min-width: 1100px)': {
    paddingTop: '3rem',
  },

  '@media (max-width: 1250px)': {
    maxWidth: '100%',
    maxHeight: '10rem',
    borderRadius: '1rem',
    margin: '0.5rem 0',
    padding: '0 0.5rem',
  },
};

export const refColumnContentStyle: SxProps<Theme> = {
  border: `1px solid ${grey['400']}`,
  borderRadius: 2,
  p: '0.5rem',
  mr: '1rem',
  height: '2.5rem',
};

export const refCellStyle: SxProps<Theme> = {
  border: 'none',
  p: 0,
};
