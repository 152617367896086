import { useForm } from 'react-hook-form';
import { useEdit } from '../../../hooks/useEdit';
import { useModal } from '../../../hooks/useModal';
import { useTitle } from '../../../hooks/useTitle';
import { SubjectFormData } from '../../../types/subject';
import { zodResolver } from '@hookform/resolvers/zod';
import { subjectSchema } from '../../../validations/schemas';
import { useCallback, useRef } from 'react';
import { useSubjectFetch } from './useSubjectFetch';
import { subjectSearchBaseEndpoint } from '../../../constants/endpoints';

export const useSubject = () => {
  useTitle('Assunto');

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: handleClose,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmModalOpen,
    handleCloseModal: handleCloseConfirmModal,
    handleOpenModal: handleOpenConfirmModal,
  } = useModal();

  const {
    isModalOpen: isSelectModalOpen,
    handleCloseModal: handleCloseSelectModal,
    handleOpenModal: handleOpenSelectModal,
  } = useModal();

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
  } = useForm<SubjectFormData>({
    resolver: zodResolver(subjectSchema),
    defaultValues: {
      name: '',
    },
  });

  const nameOfSubject = watch('name');
  const currentSubjectId = useRef<number | null>(null);

  const handleCloseMenageModal = useCallback(() => {
    currentSubjectId.current = null;

    setValue('name', '', { shouldDirty: true });
    setError('name', {});

    handleUnedit();
    handleClose();
  }, [handleClose, handleUnedit, setError, setValue]);

  const { save, update, deleteSubjectMutate, clearError, errorMessage } =
    useSubjectFetch(
      subjectSearchBaseEndpoint,
      currentSubjectId.current,
      handleCloseMenageModal,
      handleCloseMenageModal,
      handleCloseConfirmModal,
    );

  const handleTableRowClick = (subjectId: number, subjectName: string) => {
    currentSubjectId.current = subjectId;
    setValue('name', subjectName);
    handleOpenMenageModal();
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmModal();
  };

  const handleConfirmDelete = () => {
    deleteSubjectMutate();
  };

  const handleButtonOkClick = ({ name }: SubjectFormData) => {
    if (currentSubjectId.current) {
      update({
        id: currentSubjectId.current,
        name: name,
      });
      handleUnedit();
      return;
    }
    save({ name: name });
  };

  const handleAddSubjectClick = () => {
    handleOpenMenageModal();
  };

  const handleClearSubject = () => {
    setValue('name', '', { shouldDirty: true });
  };

  const isShowEditButton = !!currentSubjectId.current && !canEdit;

  return {
    isMenageModalOpen,
    currentSubjectId: currentSubjectId.current,
    isConfirmModalOpen,
    nameOfSubject,
    isShowEditButton,
    errors,
    dirtyFields,
    isSelectModalOpen,
    errorMessage,
    clearError,
    // handleSelectTableRowClick,
    handleCloseSelectModal,
    handleOpenSelectModal,
    handleClearSubject,
    handleAddSubjectClick,
    handleConfirmDelete,
    handleCloseConfirmModal,
    handleEdit,
    handleButtonOkClick,
    register,
    handleSubmit,
    handleDeleteButtonClick,
    handleCloseMenageModal,
    handleTableRowClick,
  };
};
