import { create } from 'zustand';

type SynchronizationStoreProps = {
  state: {
    currentSynchronization: string;
  };
  actions: {
    setCurrentSynchronization: (currentSynchronization: string) => void;
  };
};

export const useSynchronizationStore = create<SynchronizationStoreProps>(
  (set) => {
    const initialState = {
      currentSynchronization: '',
    };

    return {
      state: initialState,
      actions: {
        setCurrentSynchronization: (currentSynchronization) => {
          set((state) => ({
            state: {
              ...state.state,
              currentSynchronization: currentSynchronization,
            },
          }));
        },
      },
    };
  },
);
