import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

type AutoCompleteRootProps = ComponentProps<'div'> & {
  placeholderInput?: string;
  onOutClick?: () => void;
  items: unknown[];
};

export default function AutoCompleteRoot({
  placeholderInput = 'Sua pesquisa não encontrou nenhum produto correspondente.',
  children,
  items,
  className,
  onOutClick,
  ...rest
}: AutoCompleteRootProps) {
  return (
    <div>
      <div
        className={twMerge(
          'z-20 w-full rounded rounded-b-lg border-[#d4d4d8] bg-zinc-100',
          className,
        )}
        {...rest}
      >
        <div
          className={`bg-white px-4 py-2 ${items.length <= 0 ? 'rounded-b-lg' : ''}`}
        >
          {items.length <= 0 && <span>{placeholderInput}</span>}
        </div>
        {items.length > 0 && <ul className="text-sm">{children}</ul>}
      </div>
      <div
        className="fixed bottom-0 left-0 right-0 top-0 z-10"
        onClick={onOutClick}
      />
    </div>
  );
}
