import { useQuery } from 'react-query';
import { productsPartsApiCache } from '../../constants/requestCacheName';
import { useHandleTable } from '../../hooks/useHandleTable';
import {
  getAllProductsParts,
  getProductsPartsPaginated,
} from '../../services/productsParts';
import {
  FindAllProductsParts,
  ProductsParts,
  ProductsPartsPaginated,
} from '../../types/productsParts';
import { productPartsSearchBaseEndpoint } from '../../constants/endpoints';

export const useProductsPartsContent = () => {
  const {
    data: productsParts,
    isFetching: isFetchingPaginated,
    refetch: refetchProductsPartsPaginated,
  } = useQuery<ProductsPartsPaginated>(
    productsPartsApiCache,
    async () => (await getProductsPartsPaginated(currentPage + 1)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const {
    isFetching: isFetchingAllPaginated,
    refetch: refetchAllProductsPartsPaginated,
  } = useQuery<FindAllProductsParts>(
    productsPartsApiCache,
    async () => (await getAllProductsParts()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const {
    currentPage,
    sortField,
    inputRef,
    pagination,
    isSearchInputDirty,
    itemsShown,
    itemsLength: productsPartsLength,
    itemsShownLength: productsPartsShownLength,
    isSearchFetching,
    isSearch,
    endPageRef,
    isLoadMoreFetching,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch,
    handleChangePage,
    handleChangePagination,
    handleSortTable,
    handleCancelSearch,
  } = useHandleTable(
    productsParts?.meta.totalItems ?? 0,
    productsParts?.items ?? [],
    refetchProductsPartsPaginated,
    refetchAllProductsPartsPaginated,
    productPartsSearchBaseEndpoint,
    getProductsPartsPaginated,
  );
  const productsPartsShown: ProductsParts[] = itemsShown;

  const isFetchingProductsParts =
    isFetchingPaginated || isSearchFetching || isFetchingAllPaginated;

  return {
    sortField,
    inputRef,
    pagination,
    isSearchInputDirty,
    productsPartsLength,
    productsPartsShownLength,
    productsPartsShown,
    isFetchingProductsParts,
    productsParts,
    currentPage,
    isSearch,
    endPageRef,
    isLoadMoreFetching,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch,
    handleChangePage,
    handleChangePagination,
    handleSortTable,
    handleCancelSearch,
  };
};
