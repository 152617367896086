import { Editor } from '@tiptap/react';
import { useEffect } from 'react';
import { ButtonEvent, OnButtonClick } from './EditorMenu';

export const useEditorMenu = (
  editor: Editor | null,
  isEditable = false,
  onButtonClick?: OnButtonClick,
) => {
  useEffect(() => {
    editor?.setEditable(isEditable);
  }, [editor, isEditable]);

  const handleBold = (event: ButtonEvent) => {
    if (onButtonClick) {
      onButtonClick({ type: 'bold' });
      return;
    }

    editor?.chain().focus().toggleBold().run();
  };

  const handleItalic = (event: ButtonEvent) => {
    if (onButtonClick) {
      onButtonClick({ type: 'italic' });
      return;
    }

    editor?.chain().focus().toggleItalic().run();
  };

  const handleUnderline = (event: ButtonEvent) => {
    if (onButtonClick) {
      onButtonClick({ type: 'underline' });
      return;
    }

    editor?.chain().focus().toggleUnderline().run();
  };

  const handleColor = (event: React.FormEvent<HTMLInputElement>) => {
    const color = event.currentTarget.value;
    if (onButtonClick) {
      onButtonClick({ type: 'color', color });
      return;
    }

    editor
      ?.chain()
      .focus()
      .command(({ commands }) => {
        return commands.setMark('textStyle', { color });
      })
      .run();
  };

  const handleTextAlignLeft = () => {
    if (onButtonClick) {
      onButtonClick({ type: 'alignLeft' });
      return;
    }

    editor?.chain().focus().setTextAlign('left').run();
  };

  const handleTextAlignCenter = () => {
    if (onButtonClick) {
      onButtonClick({ type: 'alignCenter' });
      return;
    }

    editor?.chain().focus().setTextAlign('center').run();
  };

  const handleTextAlignRight = () => {
    if (onButtonClick) {
      onButtonClick({ type: 'alignRight' });
      return;
    }

    editor?.chain().focus().setTextAlign('right').run();
  };

  return {
    handleBold,
    handleItalic,
    handleUnderline,
    handleColor,
    handleTextAlignLeft,
    handleTextAlignCenter,
    handleTextAlignRight,
  };
};
