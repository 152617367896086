import { Typography, useTheme } from '@mui/material';
import TableMui from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer, {
  TableContainerProps,
} from '@mui/material/TableContainer';
import { ReactNode } from 'react';
import { tableStyle } from './styles';
import { twMerge } from 'tailwind-merge';

type TableRootProps = TableContainerProps & {
  itemsLength: number;
  itemType: string;
  footer?: ReactNode;
  paddingTop?: string;
  tableContentStyle?: string;
  showNoContentMessage?: boolean;
  showSearchMessage?: boolean;
};

export default function TableRoot({
  children,
  className,
  itemsLength,
  itemType,
  footer,
  sx,
  tableContentStyle,
  paddingTop = '',
  showNoContentMessage = true,
  showSearchMessage = false,
  ...rest
}: TableRootProps) {
  const theme = useTheme();

  return (
    <TableContainer
      sx={{
        ...sx,
      }}
      className={twMerge(paddingTop, className)}
      {...rest}
    >
      <TableMui sx={tableStyle} className={tableContentStyle}>
        <TableBody
          sx={{
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {itemsLength <= 0 && showNoContentMessage && (
            <Typography className="h-[calc(100vh-15rem)] pt-16 text-center text-2xl">
              Sem {itemType} disponíveis
            </Typography>
          )}
          {/* {showSearchMessage && (
            <p className="h-[calc(100vh-15rem)] pt-24 text-center">
              Faça a pesquisa para trazer os resultados
            </p>
          )} */}
          {itemsLength > 0 && children}
        </TableBody>
        {footer}
      </TableMui>
    </TableContainer>
  );
}
