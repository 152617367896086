import {
  FindAllProductDocuments,
  VinculateDocumentInProductDto,
} from '../types/productDocument';
import { api } from './api';

export const getAllProductDocumentsByProductId = async ({
  productId,
}: Record<'productId', number>) => {
  const response = await api.get<FindAllProductDocuments>(
    `/product-document/v1/product-id/${productId}`,
  );

  return response;
};

export const vinculateDocumentInProduct = async ({
  documentId,
  productId,
}: VinculateDocumentInProductDto) => {
  const body = {
    documentId,
    productId,
  };

  const response = await api.post<Record<'id', number>>(
    `/product-document/v1/vinculate`,
    body,
  );

  return response;
};

export const removeDocumentFromProduct = async (vinculationId: number) => {
  const response = await api.delete<void>(
    `/product-document/v1/${vinculationId}`,
  );
  return response;
};
