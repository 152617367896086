import { useEffect, useRef, useState } from 'react';
import { useTitle } from '../../../hooks/useTitle';
import { useError } from '../../../hooks/useError';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { SaveSizingsDto, SizingFormData, SizingListSortPaginated, SizingsPaginated, UpdateSizingDto } from '../../../types/sizing';
import { useMutation } from 'react-query';
import { deleteSizing, deleteSizingStorage, saveSizing, saveSizings, updateSizing, uploadSizingsStorage, uploadSizingStorage } from '../../../services/sizings';
import { sizingCache } from '../../../constants/requestCacheName';
import { getFileNameByUrl } from '../../../utils/urlToFileName';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { AxiosError } from 'axios';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useEdit } from '../../../hooks/useEdit';
import { useModal } from '../../../hooks/useModal';
import { getImageFromApi } from '../../../utils/getImageUrl';
import { urlToFileList } from '../../../utils/urlToFileList';
import { sizingDocSchema } from '../../../validations/schemas';
import { useScreenHeight } from '../../../hooks/useHeight';

export const useUploadSizing = () => {
  useTitle('Dimensionamentos');
    const { screenHeight } = useScreenHeight();

  const sizingNameUpdate = useRef<string | null>(null);
  const sizingIdUpdate = useRef<number | null>(null);
  const sizingUrlUpdate = useRef('');

  const previousFileList = useRef<FileList | null>(null);

  const [isSizingLoading, setIsSizingLoading] = useState(false);
  const [isSizingDeleteLoading, setIsSizingDeleteLoading] = useState(false);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

    const { mutate: updateSizingMutate } = useMutation({
      mutationFn: async ({ id, name, url }: UpdateSizingDto) => {
        return (await updateSizing({ id, name, url })).data;
      },

      onSuccess: (data) => {
        if (true) {
          updateItemOnScreen<SizingListSortPaginated>(sizingCache, {
            id: data.id,
            name: data.name,
            url: data.url,
            order: data.order,
            domains: data.domains,
          });
        }
        handleCloseMenageModal();
      },

      onError: (error, variables) => {
        (async () => {
          const fileNameWithCurrentUrl = getFileNameByUrl(variables.url);
          if (fileNameWithCurrentUrl) {
            await deleteSizingStorage(fileNameWithCurrentUrl);

            const fileNameWithPreviousUrl = getFileNameByUrl(
              sizingUrlUpdate.current,
            );

            if (fileNameWithPreviousUrl) {
              uploadSizingStorage(
                fileNameWithPreviousUrl,
                previousFileList.current![0],
              );
            }
            previousFileList.current = null;
          }
        })();

        mutationErrorHandling(
          error,
          'Falha ao atualizar dimensionamento',
          setErrorMessage,
          () => {
            if (
              error instanceof AxiosError &&
              error.response?.data.message.includes('already exists') &&
              error.response?.data.statusCode === 400
            ) {
              setErrorMessage(`Já foi cadastrado um dimensionamento com este nome`);
              return true;
            }
          },
        );
      },
    });

    const { mutate: deleteSizingMutate } = useMutation({
      mutationFn: async () => {
        if (sizingIdUpdate.current) {
          await deleteSizing(sizingIdUpdate.current);
          return;
        }

        setErrorMessage('Falha ao deletar dimensionamento');
      },

      onSuccess: () => {
        if (sizingIdUpdate.current) {
          removeItemFromScreen<SizingsPaginated>(
            sizingCache,
            sizingIdUpdate.current,
          );
        }
        setIsSizingDeleteLoading(false);
        handleCloseMenageModal();
      },

      onError: (error) => {
        (async () => {
          const fileName = getFileNameByUrl(sizingUrlUpdate.current);

          if (fileName) {
            uploadSizingStorage(
              fileName,
              previousFileList.current![0],
            );
          }
          setIsSizingDeleteLoading(false);
          previousFileList.current = null;
        })();

        mutationErrorHandling(
          error,
          'Falha ao deletar dimensionamento',
          setErrorMessage,
        );
      },
    });

    const { canEdit, handleEdit, handleUnedit } = useEdit();

    const {
      formState: { errors, dirtyFields },
      register,
      handleSubmit,
      watch,
      setError,
      reset,
      setFocus,
      setValue,
    } = useForm<SizingFormData>({
      resolver: zodResolver(sizingDocSchema),
      defaultValues: {
        reference: '',
      },
    });
    const files = watch('sizing');
    const reference = watch('reference')

    useEffect(() => {
      if (files?.length > 0) {
        setError('reference', {});
      }

      if (files?.length > 1) {
        setPreviewSizing('');
        setValue('reference', '');
        return;
      }

      if (files && files[0]) {
        if (files[0].type === 'application/pdf') {
          const previewUrl = URL.createObjectURL(files[0]);
          setPreviewSizing(previewUrl);
          setValue('reference', files[0].name);
          return;
        }
        setError('sizing', { message: 'Somente arquivos PDF são permitidos.' });
      }
    }, [files, setError, setValue]);

    const {
      isModalOpen: isMenageModalOpen,
      handleCloseModal: closeMenageModal,
      handleOpenModal: handleOpenMenageModal,
    } = useModal();

    const {
      isModalOpen: isConfirmDeleteModalOpen,
      handleCloseModal: handleCloseConfirmDeleteModal,
      handleOpenModal: handleOpenConfirmDeleteModal,
    } = useModal();

    const [previewSizing, setPreviewSizing] = useState<string>('');

    const handleCancelIconClick = () => {
      setValue('reference', '', { shouldDirty: true });
      setFocus('reference');
    };

    const handleCloseMenageModal = () => {
      sizingIdUpdate.current = null;
      sizingNameUpdate.current = null;
      sizingUrlUpdate.current = '';
      previousFileList.current = null;
      reset();
      handleUnedit();
      setPreviewSizing('');
      closeMenageModal();
    };

    const handleAddImageClick = () => {
      handleOpenMenageModal();
    };

    const handleTableRowClick = async (
      sizingId: number,
      sizingName: string,
      sizingUrl: string,
    ) => {
      sizingIdUpdate.current = sizingId;
      sizingNameUpdate.current = sizingName;
      sizingUrlUpdate.current = sizingUrl;
      setValue('reference', sizingName);
      setPreviewSizing(sizingUrl);
      handleOpenMenageModal();

      const fileList = await urlToFileList(sizingUrl, sizingName);
      if (fileList) {
        setValue('sizing', fileList);
      }
    };

    const handleDeleteButtonClick = () => {
      handleOpenConfirmDeleteModal();
    };

    const clearFields = () => {
      setPreviewSizing('');
      reset();
    };

    const handleReferenceError = () => {
      setErrorMessage(`O campo referência não está preenchido corretamente.`);
      setIsSizingLoading(false);
    };

    const addSizing = async (
      reference: string | undefined,
      sizing: FileList,
    ) => {
      const sizingsDto: SaveSizingsDto[] = [];

      for (let i = 0; i < sizing.length; i++) {
        const doc = sizing[i];
        const sizingName =
          sizing.length === 1 && reference ? reference : doc.name;

          sizingsDto.push({
          name: sizingName,
          url: getImageFromApi(sizingName, `documents/dimensionamentos`, false),
        });
      }

      try {
        await uploadSizingsStorage(
          sizing,
          async () => {
            if (sizingsDto.length > 1) {
              await saveSizings(sizingsDto);
              return;
            }

            const sizingDto = sizingsDto[0];
            const { data } = await saveSizing({
              name: sizingDto.name,
              url: sizingDto.url,
            });

            addItemOnScreen<SizingsPaginated>(sizingCache, {
              id: data.id,
              name: data.name,
              url: data.url,
              order: data.order,
            });
          },
          sizing.length === 1 ? sizingsDto[0].name : null,
        );

        clearFields();
        handleCloseMenageModal();
      } catch (error) {
        setErrorMessage(`Erro ao fazer upload dos dimensionamentos.`);
      } finally {
        setIsSizingLoading(false);
        return;
      }
    };

    const handleButtonOkClick = async ({
      reference,
      sizing,
    }: SizingFormData) => {
      setIsSizingLoading(true);
      if (
        sizingIdUpdate.current &&
        sizingNameUpdate.current
      ) {
        if (!reference) {
          handleReferenceError();
          return;
        }

        const sizingUrl = getImageFromApi(
          reference,
          `documents/dimensionamentos`,
          false,
        );
        const isSizingNameExits = await fetch(sizingUrl, { method: 'HEAD' });

        if (isSizingNameExits.ok) {
          setErrorMessage('Já foi cadastrado um dimensionamento com este nome');
          setIsSizingLoading(false);
          return;
        }

        const fileName = getFileNameByUrl(sizingUrlUpdate.current);
        if (fileName) {
          previousFileList.current = await urlToFileList(
            sizingUrlUpdate.current,
            sizingNameUpdate.current,
          );

          const error = await deleteSizingStorage(
            fileName,
          );

          if (!error || !previousFileList.current) {
            const upload = uploadSizingStorage(reference, sizing[0]);
            upload.on(
              'state_changed',
              null,
              async (error) => {
                setErrorMessage(
                  `Erro ao fazer upload do dimensionamento. ${error.message}`,
                );
                if (previousFileList.current) {
                  uploadSizingStorage(
                    fileName,
                    previousFileList.current[0],
                  );
                }
                setIsSizingLoading(false);
              },
              () => {
                updateSizingMutate({
                  id: sizingIdUpdate.current!,
                  name: reference,
                  url: getImageFromApi(reference, `documents/dimensionamentos`, false),
                });
                clearFields();
                setIsSizingLoading(false);
              },
            );

            return;
          }

          setErrorMessage(error);
          setIsSizingLoading(false);
        }
        return;
      }

      addSizing(reference, sizing);
    };

    const handleConfirmDeleteSizing = async () => {
      setIsSizingDeleteLoading(true);
      handleCloseConfirmDeleteModal();
      const fileName = getFileNameByUrl(sizingUrlUpdate.current);
      if (sizingNameUpdate.current && fileName) {
        previousFileList.current = await urlToFileList(
          sizingUrlUpdate.current,
          sizingNameUpdate.current,
        );

        const error = await deleteSizingStorage(
          fileName,
        );

        if (!error) {
          deleteSizingMutate();
          handleCloseConfirmDeleteModal();
          return;
        }

        handleCloseConfirmDeleteModal();
        setIsSizingDeleteLoading(false);
        setErrorMessage(error);
      }
    };

    const isShowEditButton = !!sizingIdUpdate.current && !canEdit;

    return {
      isMenageModalOpen,
      sizingNameUpdate: sizingNameUpdate.current,
      isConfirmDeleteModalOpen,
      previewSizing,
      reference,
      errors,
      screenHeight,
      dirtyFields,
      errorMessage,
      isSizingLoading,
      isSizingDeleteLoading,
      isShowEditButton,
      files: Array.from(files ?? []),
      sizingIdUpdate: sizingIdUpdate.current,
      handleEdit,
      handleConfirmDeleteSizing,
      handleCloseConfirmDeleteModal,
      clearError,
      handleCancelIconClick,
      register,
      handleSubmit,
      handleDeleteButtonClick,
      handleButtonOkClick,
      handleTableRowClick,
      handleAddImageClick,
      handleCloseMenageModal,
    };
};
