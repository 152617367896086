import { useQuery } from 'react-query';
import { NewsType } from '../../../types/newsType';
import { newsTypesCache } from '../../../constants/requestCacheName';
import { News } from '../../../types/news';
import { getAllNewsType } from '../../../services/news';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { useNavigate } from 'react-router-dom';
import { useHandleTable } from '../../../hooks/useHandleTable';
import {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Search, search as searchDatabase } from '../../../services/search';
import { getCurrentDomain } from '../../../utils/domain';
import { newsSearchBaseEndpoint } from '../../../constants/endpoints';
import { useOpenFileMaskUrl } from '../../hooks/useOpenFileMaskUrl';

export type NewsFilter = 'Todos' | string;

export const useNews = () => {
  const [newsFilter] = useSearchParams('newsType') as [NewsFilter];
  const navigate = useNavigate();

  const [newsCardWidthInPx, setNewsCardWidthInPx] = useState(400);

  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  const handlePlay = (id: number) => {
    videoRefs.current.forEach((video) => {
      if (video && video.id !== id.toString()) {
        video.pause();
      }
    });
  };

  const previousNewsFilter = useRef<string | null>(null);
  const isFilter = useRef(false);

  const domain = getCurrentDomain();

  const { data: newsTypes } = useQuery<NewsType[]>(
    newsTypesCache,
    async () => (await getAllNewsType(domain.id)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  //Notícias
  // const { data: newsList, refetch: refetchNewsList } = useQuery<NewsListSort>(
  //   newsCache,
  //   async () =>
  //     (
  //       await getAllNewsListPaginated({
  //         page: currentPage.current,
  //         type: newsFilter,
  //         domain: 'Buffalo',
  //       })
  //     ).data,
  //   {
  //     enabled: false,
  //     retry: false,
  //     refetchOnWindowFocus: false,
  //   },
  // );

  const currentParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  const handleFilterByType = useCallback(
    (filterName: string) => {
      previousNewsFilter.current = null;
      isFilter.current = true;
      currentParams.set('newsType', filterName);
      navigate({ search: currentParams.toString() });
      // handleSearch();
    },
    [currentParams, navigate],
  );

  // const doFilter = useCallback(
  //   (previousItems: News[] | undefined) => {
  //     const newsListActive = previousItems?.filter(
  //       (news) => news.newsType.name === newsFilter,
  //     );

  //     if (newsListActive) {
  //       orderItems(newsListActive);
  //     }

  //     return newsListActive;
  //   },
  //   [newsFilter],
  // );

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return searchDatabase<News>(
        baseUrl,
        !isFilter.current ? searchValue : '',
        page,
        'DESC',
        null,
        [
          { name: 'd', value: 'Buffalo' },
          { name: 't', value: previousNewsFilter.current ? '' : newsFilter },
        ],
      );
    },
    [newsFilter],
  );

  const {
    itemsShown,
    inputRef,
    searchItems: newsList,
    refetchSearch: refetchNewsList,
    handleSearch: search,
    handleSearchDatabase: searchDb,
    handleCancelSearch: cancelSearch,
    isSearchInputDirty,
  } = useHandleTable(
    0,
    [],
    null,
    null,
    newsSearchBaseEndpoint,
    null,
    null,
    // {
    //   handleFilter: doFilter,
    //   name: newsFilter,
    // },
    doSearch as Search,
  );

  const newsListShown: News[] = itemsShown;

  // const cardWidthInPx = useMemo(() => 500, []);

  const applyPreviousFilter = useCallback(() => {
    if (!inputRef.current?.value && !newsFilter && previousNewsFilter.current) {
      handleFilterByType(previousNewsFilter.current);
      // handleSearch()
      // previousNewsFilter.current = null;
    }
  }, [handleFilterByType, inputRef, newsFilter]);

  const handleSearch = useCallback(() => {
    applyPreviousFilter();

    search();
    // search<News>((item, formatedSearch) => {
    //   return (
    //     formatSearch(item.name).includes(formatedSearch) ||
    //     formatSearch(item.newsType.name).includes(formatedSearch)
    //   );
    // });
  }, [applyPreviousFilter, search]);

  const { loadFile } = useOpenFileMaskUrl();

  useEffect(() => {
    if (newsFilter) {
      const timeout = setTimeout(() => {
        refetchNewsList();
      }, 100);
      // isFilter.current = false;

      return () => clearTimeout(timeout);
    }
  }, [refetchNewsList, newsFilter]);

  useEffect(() => {
    if (newsTypes && !newsFilter && !previousNewsFilter.current) {
      handleFilterByType(newsTypes[0]?.name);
    }
  }, [handleFilterByType, newsFilter, newsTypes]);

  useEffect(() => {
    if (inputRef.current?.value) {
      search();
      // search<News>((item, formatedSearch) => {
      //   return (
      //     formatSearch(item.name).includes(formatedSearch) ||
      //     formatSearch(item.newsType.name).includes(formatedSearch)
      //   );
      // });
    }
  }, [search, newsList, inputRef]);

  const handleCardClick = async (newsUrl: string, newsName?: string) => {
    await loadFile(newsUrl,newsName);
  };

  const handleSearchDatabase = useCallback(() => {
    if (newsFilter) {
      previousNewsFilter.current = newsFilter;
    }
    currentParams.delete('newsType');
    navigate({ search: currentParams.toString() });
    searchDb();
  }, [currentParams, navigate, newsFilter, searchDb]);

  const handleCancelSearch = useCallback(() => {
    cancelSearch();
    applyPreviousFilter();
  }, [applyPreviousFilter, cancelSearch]);

  const handleEnterClickSearchInput = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleSearchDatabase();
      }
    },
    [handleSearchDatabase],
  );

  return {
    newsTypes,
    newsList,
    newsFilter,
    newsListShown,
    inputRef,
    isSearchInputDirty,
    newsCardWidthInPx,
    videoRefs,
    handlePlay,
    setNewsCardWidthInPx,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleCancelSearch,
    handleSearch,
    handleFilterByType,
    handleCardClick,
  };
};
