import { Domain } from '../types/domain';

export const setDomain = (domain: Domain) => {};

export const getCurrentDomain = (): Record<'id', number> => {
  return {
    id: 1,
    // name: 'buffalo',
  };
};
