import { Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import ListingHeader, { ListingHeaderProps } from '../ListingHeader';
import Loading from '../Loading';
import SerchInput from '../SearchInput';
import { titleStyle, tableLoadingContainerStyle } from './styles';
import Table from '../Table';
import { ProductClean, useProductsContent } from './useProductsContent';
import CheckIcon from '@mui/icons-material/Check';
import {
  FindAllProducts,
  Product,
  ProductsPaginated,
} from '../../types/products';
import LoadMore from '../LoadMore';
import Alert from '../Alert';
import Main from '../Main';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { AxiosResponse } from 'axios';
import { Search } from '../../services/search';
import Filter from '../Filter';

export type Service = (
  currenPage: number,
) => Promise<AxiosResponse<ProductsPaginated | FindAllProducts>>;

type ProductsContentProps = {
  isModal?: boolean;
  itemsInList?: Record<'id', number>[];
  headerProps?: ListingHeaderProps;
  edit?: boolean;
  shouldFilter?: boolean;
  service?: Service;
  search?: Search;
  onTableRowClick: (
    productId: number,
    productSelected: ProductClean | null,
  ) => void;
  onEditButtonClick?: (
    productId: string,
    productName: string,
    isNew: boolean,
    outOfLine: boolean,
    reviewedApproved: boolean,
    description: string,
  ) => void;
};

export default function ProductsContent({
  isModal = false,
  itemsInList,
  headerProps,
  edit = false,
  shouldFilter = true,
  search,
  service,
  onTableRowClick,
  onEditButtonClick,
}: ProductsContentProps) {
  const {
    theme,
    sortField,
    inputRef,
    productsShown,
    productsLength,
    productsShownLength,
    isFetching,
    filters,
    isSearchInputDirty,
    isSearch,
    isMobile,
    endPageRef,
    isLoadMoreFetching,
    errorMessage,
    nameWidth,
    documentTypes,
    isAdmin,
    clearError,
    handleEnterClickSearchInput,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
    handleFilter,
    handleGeneratePdf,
    handleSearchDatabase,
  } = useProductsContent(isModal, shouldFilter, service, search);

  return (
    <>
      <ListingHeader
        showBackground={!isModal}
        onPdfClick={handleGeneratePdf}
        backButton={!isModal}
        pdf={!isModal}
        className={`${isModal ? 'static rounded-t-2xl' : 'sticky'}`}
        {...headerProps}
        // Por enquanto liberado apenas para admin
        add={!!(isAdmin && !isModal)}
      >
        <Typography component="h1" sx={titleStyle}>
          Produtos
        </Typography>
        {shouldFilter && <Filter filters={filters} onFilter={handleFilter} />}
        <SerchInput
          ref={inputRef}
          onSearch={handleSearch}
          showCancelOptions={isSearchInputDirty}
          onButtonClick={handleSearchDatabase}
          onCancelSearch={handleCancelSearch}
          buttonContent="Buscar"
          onKeyDown={handleEnterClickSearchInput}
        />
        <Table.Head>
          <div className="flex w-full bg-white">
            <Table.Cell
              items={productsShown}
              type="id"
              onSort={handleSortTable}
              isSortActive={sortField === 'id'}
            >
              Código
            </Table.Cell>
            <Table.Cell
              items={productsShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
              className={`mr-2 max-sm:w-52`}
              style={{
                width: `${nameWidth}ch`,
              }}
            >
              Nome
            </Table.Cell>
            {isAdmin && (
              <>
                <div className="flex gap-8">
                  <Table.CellStatic className="w-24 max-w-36 flex-1 text-center">
                    Revis. aprov.
                  </Table.CellStatic>
                  <Table.CellStatic className="max-w-10 flex-1">
                    Novo
                  </Table.CellStatic>
                  <Table.CellStatic className="flex-1">
                    Fora de linha
                  </Table.CellStatic>
                </div>

                <Table.CellStatic className="ml-8">
                  <div className="w-40 text-center">Imagem indisponível</div>
                </Table.CellStatic>

                {documentTypes?.items.map((documentType) => (
                  <Table.CellStatic
                    key={documentType.id}
                    // style={{ width: `${domainWidth}ch` }}
                    className="ml-8 w-40 justify-center"
                  >
                    {documentType.name}
                  </Table.CellStatic>
                ))}
              </>
            )}
          </div>
        </Table.Head>
      </ListingHeader>
      <Main
        className={`${isModal ? `${shouldFilter ? 'h-[calc(100vh-17rem)]' : 'h-[calc(100vh-13.3rem)]'} overflow-scroll bg-backgroundPrimary pl-8 pr-4` : ''}`}
      >
        <Box sx={tableLoadingContainerStyle}>
          {isFetching && (
            <Loading
              data-modal={isModal}
              className="data-[modal=false]:pt-16"
              loadMore
            />
          )}
          {!isFetching && (
            <Table.Root
              itemsLength={productsShown.length}
              itemType="Produtos"
              data-modal={isModal}
              showNoContentMessage={productsShown.length <= 0 && isSearch}
              showSearchMessage={productsShown.length <= 0 && !isSearch}
              className={`${isModal ? 'max-h-[calc(90vh-18.5rem)' : ''}`}
            >
              {productsShown.map((product) => {
                const isInList = itemsInList?.some(
                  (item) => item.id.toString() === product.id.toString(),
                );



                return (
                  <Table.Row
                    key={product.id}
                    className={
                      isInList ? 'cursor-default opacity-50' : 'opacity-100'
                    }
                  >
                    <div
                      className="flex flex-1"
                      onClick={
                        !isInList
                          ? () => onTableRowClick(product.id, product)
                          : () => {}
                      }
                    >
                      <Table.CellStatic className="max-w-24 flex-1">
                        {product.id}
                      </Table.CellStatic>
                      <Table.CellStatic
                        style={{
                          width: `${nameWidth - 4}ch`,
                        }}
                      >
                        {product.name}
                        {product.associatedProductId}
                      </Table.CellStatic>
                      {isAdmin && (
                        <>
                          <div className="flex gap-8">
                            <Table.CellStatic className="w-[5.5rem] flex-1 items-center justify-center">
                              <div className="w-full text-center">
                                <input
                                  readOnly
                                  type="checkbox"
                                  className="size-4"
                                  checked={product.reviewedApproved}
                                />
                              </div>
                            </Table.CellStatic>
                            <Table.CellStatic className="w-9 flex-1 items-center justify-center">
                              <div className="w-full text-center">
                                <input
                                  readOnly
                                  type="checkbox"
                                  className="size-4"
                                  checked={product.new}
                                />
                              </div>
                            </Table.CellStatic>
                            <Table.CellStatic className="w-[5.75rem] flex-1 items-center justify-center">
                              <div className="w-full text-center">
                                <input
                                  readOnly
                                  type="checkbox"
                                  className="size-4"
                                  checked={product.outOfLine}
                                />
                              </div>
                            </Table.CellStatic>
                          </div>

                          <Table.CellStatic className="ml-8 w-40">
                            <div className="w-40 text-center">
                              {!product.hasImage && (
                                <ImageNotSupportedIcon
                                  fontSize="small"
                                  className="text-zinc-400"
                                />
                              )}
                            </div>
                          </Table.CellStatic>

                          {documentTypes?.items.map((documentType) => (
                            <Table.CellStatic
                              key={documentType.id}
                              // style={{ width: `${domainWidth}ch` }}
                              className="ml-8 w-40 justify-center"
                            >
                              {!product.productDocument?.some(
                                (productDocument) =>
                                  productDocument.document.documentType.id ===
                                  documentType.id,
                              ) && (
                                <ImageNotSupportedIcon
                                  fontSize="small"
                                  className="text-zinc-400"
                                />
                              )}
                            </Table.CellStatic>
                          ))}
                        </>
                      )}
                    </div>
                    <div className="flex items-center gap-8">
                      {!isModal && !isMobile && (
                        <Link
                          to={{
                            pathname: `/parts-catalog/${product.id}`,
                            search: `product=${encodeURIComponent(product.name)}${product.associatedProductId ? `&previus-version=${product.associatedProductId}` : ''}`,
                          }}
                          className="text-primary"
                        >
                          Catálogo de Peças
                        </Link>
                      )}

                      {edit && !isMobile && isAdmin && (
                        <button
                          className="text-primary"
                          type="button"
                          onClick={() =>
                            onEditButtonClick &&
                            onEditButtonClick(
                              product.id.toString(),
                              product.name,
                              product.new,
                              product.outOfLine,
                              product.reviewedApproved,
                              product.description,
                            )
                          }
                        >
                          Editar
                        </button>
                      )}
                      {!isModal ? (
                        <Table.CellStatic className="flex-1">
                          <Link
                            to={{
                              pathname: '/parts-product',
                              search: `productId=${product.id}&productName=${product.name}`,
                            }}
                            style={{ color: theme.palette.secondary.main }}
                            className="text-base"
                          >
                            Peças
                          </Link>
                        </Table.CellStatic>
                      ) : (
                        <div className="w-12"></div>
                      )}
                      {isModal && isInList && (
                        <CheckIcon className="text-primary" />
                      )}
                    </div>
                  </Table.Row>
                );
              })}
              <LoadMore
                isModal={isModal}
                isLoadMoreFetching={isLoadMoreFetching}
                isContentFetching={isFetching}
                ref={endPageRef}
              />
            </Table.Root>
          )}
        </Box>
      </Main>

      <Footer
        className={`${isModal ? 'mb-1 ml-[5vw] w-[90vw]' : ''}`}
        current={productsShownLength}
        total={productsLength}
        itemType="Produtos"
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
