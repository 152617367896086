import {
  UpdateDomainQuestionDto,
  UpdateDomainQuestionsArrayDto,
} from '../types/questionsDomain';
import { api } from './api';

export const updateQuestionDomain = async (dto: UpdateDomainQuestionDto[]) => {
  const body: UpdateDomainQuestionsArrayDto = {
    questionDomains: dto,
  };

  const response = await api.put<void>('/faq-domain/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
};
