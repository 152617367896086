import { useRef, useEffect, useState } from 'react';

export const useOpenFileMaskUrl = () => {
  const createdUrl = useRef('');
  const [fileLoadingId, setFileLoadingId] = useState<number | null>(null);

  const loadFile = async (
    storageUrl: string,
    name?: string,
    fileId?: number,
  ) => {
    try {
      setFileLoadingId(fileId ?? null);
      const response = await fetch(storageUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      createdUrl.current = url;
      // window.open(url, '_blank', 'noopener,noreferrer');
      const a = document.createElement('a');
      a.href = url;
      a.download = `${name}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao carregar o arquivo:', error);
    } finally {
      setFileLoadingId(null);
    }
  };

  useEffect(() => {
    return () => URL.revokeObjectURL(createdUrl.current);
  }, []);

  return { fileLoadingId, loadFile };
};
