export const urlToPureSvg = async (url: string) => {
  let response = await fetch(url);

  if (!response.ok) {
    response = await fetch(
      `${process.env.REACT_APP_FIREBASE_IMAGES_STORAGE_BASE_URL}/utils%2Funavailable.svg?alt=media`,
    );
  }

  const svg = await response.text();
  return svg;
};
