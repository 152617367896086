import { ItemClean, ItemInfosPage } from '../../types/item';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import listingCommonContent from '../listingCommonContent';
import listingCommonHeader from '../listingCommonHeader';
import { getImageFromApi } from '../../utils/getImageUrl';
import listingCommonFooter from '../listingCommonFooter';
import { verifyOpenPdfPermission } from '../verifyPdfPermissions';

const generatePartsPdf = async (
  items: ItemClean[],
  itemInfosPage: ItemInfosPage,
  search = '',
  onError: (errorMessage: string) => void,
) => {
  verifyOpenPdfPermission(onError);
  pdfMake.vfs = pdfFonts.vfs;

  const pdfDefinitions: TDocumentDefinitions = {
    pageSize: 'A4',
    pageMargins: [32, 110, 32, 64],
    images: {
      buffaloLogo: { url: getImageFromApi('logo-buffalo', 'utils') },
      search: { url: getImageFromApi('search', 'utils') },
    },

    header: await listingCommonHeader('Peças', search),
    content: [listingCommonContent(items)],
    footer: (currentPage, totalPages, context) =>
      listingCommonFooter(
        currentPage,
        totalPages,
        context,
        'Peças',
        itemInfosPage,
      ),
  };

  pdfMake.createPdf(pdfDefinitions).getBlob((blob) => {
    const url = URL.createObjectURL(blob);

    // const pdf = window.open(url, '_blank');

     const a = document.createElement('a');
     a.href = url;
     a.download = `peças.pdf`; // Nome do arquivo
     document.body.appendChild(a);
     a.click();
     document.body.removeChild(a);
     URL.revokeObjectURL(url);


    if (!a) {
      onError(
        'O navegador bloqueou o PDF. Desative a opção Bloquear Pop-ups e tente gerar novamente',
      );
    }
  });
};

export default generatePartsPdf;
