import { useQuery } from 'react-query';
import {
  // CoordinatesModified,
  // CoordinateToDelete,
  FindAllCoordinates,
} from '../../../types/partsGroupCoordinate';
import { useParams } from 'react-router-dom';
import { usePartsCatalogStore } from '../../../store/partsCatalog';
import { useEffect, useState } from 'react';
import { partsGroupsCoordinateCache } from '../../../constants/requestCacheName';
import { getAllCoordinatesByProductIdPartsGroupIdPartId } from '../../../services/partsGroupItemCoordinate';
import { verifyScreenIsLessThan } from '../../../utils/verifyScreenIsLessThan';
import { KonvaEventObject } from 'konva/lib/Node';
import { useScreenHeight } from '../../../hooks/useHeight';

// type MouseCoordinates = Record<'x' | 'y', number>;

// type Rectangle = CoordinatesModified & {
//   isNew: boolean;
// };

export const useExplodedView = (
  onPartMouseEnter: (partId: number) => void,
  onPartMouseOut: () => void,
) => {
  const { productId } = useParams();
  const { screenHeight } = useScreenHeight();

  const {
    state: {
      currentPartsGroup,
      currentProductId,
      currentPartClicked,
      // coordinatesModified,
      // coordinatesToDelete,
    },
    actions: {
      // addCoordinateDelete,
      // setCoordinateModified,
      setCurrentPartClicked,
    },
  } = usePartsCatalogStore();

  // const coordinateUpdated = useRef<Rectangle | null>(null);
  // const isCoordinateInList = useRef(false);

  const { data: partsGroupImagesCoordinates, refetch: refetchCoordinates } =
    useQuery<FindAllCoordinates>(
      `${partsGroupsCoordinateCache}${currentPartsGroup?.id}`,
      async () => {
        return (
          await getAllCoordinatesByProductIdPartsGroupIdPartId({
            productId: currentProductId!,
            partsGroupId: currentPartsGroup!.id,
          })
        ).data;
      },
      {
        retry: false,
        enabled: false,
      },
    );

  const [currentCoordinateIdToDelete, setCurrentCoordinateIdToDelete] =
    useState('');

  useEffect(() => {
    if (currentPartsGroup) {
      refetchCoordinates();
    }
  }, [currentPartsGroup, refetchCoordinates]);

  // const compareModifiedDelete = useCallback(
  //   (coordinate: CoordinatesModified, coordinateDelete: CoordinateToDelete) => {
  //     return (
  //       coordinateDelete.partGroupId === coordinate.partGroupId &&
  //       coordinateDelete.partId === coordinate.partId &&
  //       coordinateDelete.x === coordinate.x &&
  //       coordinateDelete.y === coordinate.y
  //     );
  //   },
  //   [],
  // );

  // const converterCoordinatesToRectangles = useCallback(() => {
  //   const rectanglesList: Rectangle[] =
  //     partsGroupImagesCoordinates?.items.map((coordinate) => ({
  //       id: `${coordinate.part.id}${coordinate.x}${coordinate.y}`,
  //       partId: coordinate.part.id,
  //       partGroupId: coordinate.partsGroup.id,
  //       height: coordinate.height,
  //       width: coordinate.width,
  //       x: coordinate.x,
  //       y: coordinate.y,
  //       isNew: false,
  //     })) ?? [];
  //   return rectanglesList;
  // }, [partsGroupImagesCoordinates?.items]);

  // useEffect(() => {
  //   if (partsGroupImagesCoordinates) {
  //     const rectanglesList: Rectangle[] = converterCoordinatesToRectangles();

  //     if (currentPartClicked?.part.id) {
  //       // const rectanglesFiltered = rectanglesList.filter(
  //       //   (coordinate) =>
  //       //     coordinate.partId === currentPartClicked?.part.id &&
  //       //     !coordinatesToDelete.some((coordinateDelete) =>
  //       //       compareModifiedDelete(coordinate, coordinateDelete),
  //       //     ),
  //       // );

  //       // const updatedRectanglesFiltered: Rectangle[] = coordinatesModified
  //       //   .filter(
  //       //     (coordinateModified) =>
  //       //       !coordinatesToDelete.some((coordinateDelete) =>
  //       //         compareModifiedDelete(coordinateModified, coordinateDelete),
  //       //       ) && coordinateModified.partId === currentPartClicked?.part.id,
  //       //   )
  //       //   .map((coordinateModified) => ({
  //       //     ...coordinateModified,
  //       //     isNew: true,
  //       //   }));

  //       // const rectanglesUpdated = rectanglesFiltered.filter((rectangle) => {
  //       //   return !updatedRectanglesFiltered.some((updatedRectangles) => {
  //       //     return (
  //       //       updatedRectangles.partGroupId === rectangle.partGroupId &&
  //       //       updatedRectangles.partId === rectangle.partId &&
  //       //       updatedRectangles.previousX === rectangle.x &&
  //       //       updatedRectangles.previousY === rectangle.y
  //       //     );
  //       //   });
  //       // });

  //       // setRectangles([...rectanglesUpdated, ...updatedRectanglesFiltered]);
  //       return;
  //     }

  //     // setRectangles(rectanglesList);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   compareModifiedDelete,
  //   currentPartClicked?.part.id,
  //   partsGroupImagesCoordinates,
  // ]);

  // useEffect(() => {
  //   setCurrentPartClicked(null);
  //   // setRectangles([]);
  // }, [productId, setCurrentPartClicked]);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const isResponsive = verifyScreenIsLessThan(900);
  const isMobile = verifyScreenIsLessThan(640);

  const explodedViewReduction = isMobile ? 0.5 : 1;

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleRemoveCoordinate = (coordinateId: string) => {
    if (currentCoordinateIdToDelete === coordinateId) {
      setCurrentCoordinateIdToDelete('');
      return;
    }

    setCurrentCoordinateIdToDelete(coordinateId);
  };

  const handlePartMouseEnter = (
    event: KonvaEventObject<MouseEvent>,
    partId: number,
  ) => {
    onPartMouseEnter(partId);
    const container = event.target.getStage()?.container();
    if (container) {
      container.style.cursor = 'pointer';
      container.style.cursor = 'pointer';
    }
  };

  const handlePartMouseOut = (event: KonvaEventObject<MouseEvent>) => {
    onPartMouseOut();
    const container = event.target.getStage()?.container();
    if (container) {
      container.style.cursor = 'default';
    }
  };

  return {
    isOpenModal,
    screenHeight,
    isResponsive,
    explodedViewReduction,
    partsGroupImagesCoordinates,
    currentPartClicked,
    handlePartMouseOut,
    handlePartMouseEnter,
    handleRemoveCoordinate,
    handleOpenModal,
    handleCloseModal,
  };
};
