import { Checkbox } from '@mui/material';
import ListingHeader from '../ListingHeader';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { useImageExtensionContent } from './useImageExtensionContent';
import Main from '../Main';
import Loading from '../Loading';
import { ImageExtension } from '../../types/imageExtension';

type ImageExtensionContentProps = {
  onTableRowClick: (imageExtension: ImageExtension) => void;
  onAddImageExtensionClick?: () => void;
};

export default function ImageExtensionContent({
  onTableRowClick,
  onAddImageExtensionClick,
}: ImageExtensionContentProps) {
  const {
    imageExtensionLength,
    imageExtensionsShownLength,
    isFetching,
    inputRef,
    isSearchInputDirty,
    imageExtensionsShown,
    sortField,
    handleSearch,
    handleCancelSearch,
    handleSortTable,
  } = useImageExtensionContent();

  return (
    <>
      <ListingHeader
        add
        onAddIconClick={onAddImageExtensionClick}
        pdfTop="3rem"
        pdf={false}
      >
        <h1 className="my-4 text-2xl">Extensão</h1>
        <SearchInput
          ref={inputRef}
          onSearch={handleSearch}
          // onButtonClick={handleCancelSearch}
          showCancelOptions={isSearchInputDirty}
          onCancelSearch={handleCancelSearch}
          // buttonContent="Buscar"
          // onKeyDown={handleEnterClickSearchInput}
        />
        <Table.Head>
          <div className="flex gap-2">
            <Table.Cell
              items={imageExtensionsShown}
              type="extension"
              onSort={handleSortTable}
              isSortActive={sortField === 'extension'}
              className="w-32"
            >
              Extensão
            </Table.Cell>
            <Table.Cell
              items={imageExtensionsShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
              className="flex-1"
            >
              Nome
            </Table.Cell>
          </div>
          <Table.Cell
            items={imageExtensionsShown}
            type="fileType"
            onSort={handleSortTable}
            isSortActive={sortField === 'fileType'}
            className="w-28"
          >
            Tipo de arquivo
          </Table.Cell>
        </Table.Head>

        <Main className="p-0">
          {isFetching && <Loading loadMore />}
          {!isFetching && (
            <Table.Root
              itemType="extensões"
              itemsLength={imageExtensionLength}
              paddingTop="0"
              className="!overflow-x-hidden"
            >
              {imageExtensionsShown.map((imageExtension) => {
                return (
                  <Table.Row
                    key={imageExtension.id}
                    onClick={() => onTableRowClick(imageExtension)}
                  >
                    <div className="flex gap-2">
                      <Table.CellStatic className="w-32">
                        {imageExtension.extension}
                      </Table.CellStatic>
                      <Table.CellStatic className="flex-1">
                        {imageExtension.name}
                      </Table.CellStatic>
                    </div>
                    <Table.CellStatic className="w-28">
                      {imageExtension.fileType}
                    </Table.CellStatic>
                  </Table.Row>
                );
              })}
            </Table.Root>
          )}
        </Main>
      </ListingHeader>
    </>
  );
}
