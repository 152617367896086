import { api } from './api';
import {
  FindAllQuestions,
  Question,
  QuestionsPaiginated,
  ReorderQuestionDto,
  ReorderQuestions,
  SaveQuestion,
  UpdateQuestion,
} from '../types/questions';
import { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';

export const getAllQuestionsPaginated = async (
  page: number,
  direction: 'ASC' | 'DESC' | null = 'ASC',
  limit?: number | null,
  filters?: Partial<Record<'status' | 'domain', string>>,
) => {
  const url = addSearchParamsInUrl(
    '/faq/v1/search',
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    { name: 'status', value: filters?.status },
    { name: 'domain', value: filters?.domain },
  );

  return await api.get<QuestionsPaiginated>(url);
};

export const saveQuestion = async ({
  pergunta,
  resposta,
  status,
  ordem,
}: SaveQuestion) => {
  const body = { pergunta, resposta, status, ordem };

  return await api.post<Question>('/faq/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const updateQuestion = async ({
  id,
  pergunta,
  resposta,
  status,
  ordem,
}: UpdateQuestion) => {
  const body = { id, pergunta, resposta, status, ordem };

  return await api.put<Question>('/faq/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const deleteQuestion = async ({
  questionId,
}: Record<'questionId', number>) => {
  return await api.delete<void>(`/faq/v1/${questionId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getAllActiveQuestions = async (domainId: number) => {
  return await api.get<FindAllQuestions>(
    `/faq/v1/active/domain-id/${domainId}`,
  );
};

export const reorderQuestions = async (dto: ReorderQuestionDto[]) => {
  const response = await api.put<ReorderQuestions>(`/faq/v1/reorder`, {
    faqs: dto,
  });
  return response;
};
