import { Domain } from '../../types/domain';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import Loading from '../Loading';
import Main from '../Main';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { useDomainContent } from './useDomainContent';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

type DomainContentProps = {
  isModal?: boolean;
  onTableRowClick: (domain: Domain) => void;
  handleAddDomainRowClick?: () => void;
};

export default function DomainContent({
  isModal,
  onTableRowClick,
  handleAddDomainRowClick,
}: DomainContentProps) {
  const {
    domains,
    domainsShown,
    inputRef,
    isFetching,
    isSearchInputDirty,
    sortField,
    domainsLength,
    domainsShownLength,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
  } = useDomainContent();

  return (
    <>
      {!isModal && (
        <ListingHeader
          add
          onAddIconClick={handleAddDomainRowClick}
          pdfTop="3rem"
          pdf={false}
        >
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">Domínio</h1>
            <SearchInput
              ref={inputRef}
              onSearch={handleSearch}
              onButtonClick={handleSearchDatabase}
              onKeyDown={handleEnterClickSearchInput}
              buttonContent="Buscar"
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
            />
          </div>

          <Table.Head>
            <Table.Cell
              items={domainsShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
            >
              Domínio
            </Table.Cell>
            <div className="flex gap-4">
              <Table.Cell items={domainsShown} className="w-36">
                <span className="w-36 text-center text-xs">
                  Destaque Indisponível
                </span>
              </Table.Cell>
              <Table.Cell items={domainsShown} className="w-52">
                <span className="w-52 text-center text-xs">
                  Assinatura de email Indisponível
                </span>
              </Table.Cell>
            </div>
          </Table.Head>
        </ListingHeader>
      )}

      <Main isModal={isModal}>
        {isFetching && <Loading />}
        {!isFetching && domains && (
          <Table.Root
            itemType="domínios"
            itemsLength={domainsLength}
            className={`${isModal ? 'max-h-[60vh]' : ''}`}
            paddingTop="0"
          >
            {domainsShown.map((domain) => {
              // const isInUse = imagesInUse?.some(
              //   (item) => item.image.id === image.id,
              // );

              return (
                <Table.Row
                  key={domain.id}
                  onClick={() => onTableRowClick(domain)}
                  // className={isInUse ? 'opacity-50 cursor-default' : ''}
                >
                  <Table.Cell items={domainsShown} type="name">
                    <span>{domain.name}</span>
                  </Table.Cell>
                  <div className="flex gap-4">
                    <Table.Cell items={domainsShown} className="w-36">
                      <div className="w-36 text-center">
                        {!domain.imgHighlight && (
                          <ImageNotSupportedIcon
                            fontSize="small"
                            className="text-zinc-400"
                          />
                        )}
                      </div>
                    </Table.Cell>
                    <Table.Cell items={domainsShown} className="w-52">
                      <div className="w-52 text-center">
                        {!domain.emailSignatureImage && (
                          <ImageNotSupportedIcon
                            fontSize="small"
                            className="text-zinc-400"
                          />
                        )}
                      </div>
                    </Table.Cell>
                  </div>
                </Table.Row>
              );
            })}
          </Table.Root>
        )}
      </Main>

      <Footer
        className={`${isModal ? 'mb-1 ml-[5vw] w-[90vw]' : ''}`}
        current={domainsShownLength}
        total={domainsLength}
        itemType="Domínios"
        // tableFooter={
        //   domainsLength > 100 && (
        //     <TableFooter
        //       currentPage={currentPage}
        //       itemsLength={domainsLength}
        //       itemsShownLength={domainsShownLength}
        //       pagination={pagination}
        //       onChangePage={handleChangePage}
        //       onChangePagination={handleChangePagination}
        //     />
        //   )
        // }
      />
    </>
  );
}
