import { useRef, useState } from 'react';
import { useModal } from '../../../hooks/useModal';
import { useTitle } from '../../../hooks/useTitle';
import {
  FindAllPopUpList,
  PopUp,
  PopUpFormData,
  PreviewPopUp,
  SavePopUpDto,
  UpdatePopUpDto,
} from '../../../types/popUp';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { popUpSchema } from '../../../validations/schemas';
import { useError } from '../../../hooks/useError';
import { useMutation } from 'react-query';
import { deletePopUp, savePopUp, updatePopUp } from '../../../services/pop-up';
import { popUpCache } from '../../../constants/requestCacheName';
import { useEdit } from '../../../hooks/useEdit';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { AxiosError, isAxiosError } from 'axios';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';

export const usePopUp = () => {
  useTitle('Pop-Ups');
  const popUpIdUpdate = useRef<number | null>(null);
  const popUpTitleUpdate = useRef<string | null>(null);
  const popUpContentUpdate = useRef<string | null>(null);
  const popUpSubtitleUpdate = useRef<string | null>(null);
  const popUpFooterUpdate = useRef<string | null>(null);

  const { errorMessage, clearError, setErrorMessage } = useError();
  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    reset,
  } = useForm<PopUpFormData>({
    resolver: zodResolver(popUpSchema),
    defaultValues: {
      titleField: '',
      contentField: '',
      subtitleField: '',
      footerField: '',
    },
  });

  const {
    isModalOpen: isManageModalOpen,
    handleCloseModal: closeManageModal,
    handleOpenModal: handleOpenManageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: handleCloseConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  const {
    isModalOpen: isPreviewModalOpen,
    handleCloseModal: closePreviewModal,
    handleOpenModal: handleOpenPreviewModal,
  } = useModal();

  const { canEdit, handleEdit, handleUnedit } = useEdit();
  const isShowEditButton = !!popUpIdUpdate.current && !canEdit;

  const createEditorConfig = () => ({
    extensions: [
      StarterKit,
      TextStyle,
      Color,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph', 'div'],
      }),
    ],
    content: '',
    editorProps: {
      attributes: {
        class: 'border p-2 outline-none flex-1 min-h-80',
      },
    },
  });

  const editorTitle = useEditor(createEditorConfig());
  const editorContent = useEditor(createEditorConfig());
  const editorSubTitle = useEditor(createEditorConfig());
  const editorFooter = useEditor(createEditorConfig());

  const handleTableRowClick = async ({
    id,
    titleField,
    contentField,
    subtitleField,
    footerField,
  }: PopUp) => {
    popUpIdUpdate.current = id;
    popUpTitleUpdate.current = titleField;
    popUpContentUpdate.current = contentField;
    popUpSubtitleUpdate.current = subtitleField;
    popUpFooterUpdate.current = footerField;
    setValue('titleField', titleField);
    setValue('contentField', contentField);
    setValue('subtitleField', subtitleField);
    setValue('footerField', footerField);

    if (editorTitle) {
      editorTitle.commands.setContent(titleField);
    }

    if (editorContent) {
      editorContent.commands.setContent(contentField);
    }

    if (editorSubTitle) {
      editorSubTitle.commands.setContent(subtitleField);
    }

    if (editorFooter) {
      editorFooter.commands.setContent(footerField);
    }

    handleOpenManageModal();
  };

  const handleAddPopUpClick = () => {
    handleOpenManageModal();
  };

  const [popUp, setPopUp] = useState<PopUp | null>(null);

  const handlePreviewPopUpClick = async (popup: PopUp) => {
    setPopUp(popup);
    handleOpenPreviewModal();
  };

  const handleClosePreviewModal = () => {
    setPopUp(null);
    closePreviewModal();
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmDeleteModal();
  };

  const handleConfirmDeletePopUp = () => {
    deletePopUpMutate();
    handleCloseConfirmDeleteModal();
  };

  const handleCloseManageModal = () => {
    popUpIdUpdate.current = null;
    editorTitle?.commands.setContent('');
    editorContent?.commands.setContent('');
    editorSubTitle?.commands.setContent('');
    editorFooter?.commands.setContent('');
    reset();
    handleUnedit();
    closeManageModal();
  };

  const handleButtonOkClick = () => {
    const titleField = editorTitle?.getHTML() ?? '';
    const contentField = editorContent?.getHTML() ?? '';
    const subtitleField = editorSubTitle?.getHTML() ?? '';
    const footerField = editorFooter?.getHTML() ?? '';

    const upsertDto = {
      titleField: titleField,
      contentField: contentField,
      subtitleField: subtitleField,
      footerField: footerField,
    };

    if (popUpIdUpdate.current) {
      if (
        upsertDto.titleField === '<p></p>' ||
        upsertDto.contentField === '<p></p>'
      ) {
        setErrorMessage('Preencha os campos de Título e de Conteúdo');
        return;
      }

      updatePopUpMutate({
        id: popUpIdUpdate.current,
        ...upsertDto,
      });
      return;
    }

    if (
      upsertDto.titleField === '<p></p>' ||
      upsertDto.contentField === '<p></p>'
    ) {
      setErrorMessage('Preencha os campos de Título e de Conteúdo');
      return;
    }

    savePopUpMutate(upsertDto);
  };

  const { mutate: deletePopUpMutate } = useMutation({
    mutationFn: async () => {
      if (popUpIdUpdate.current) {
        await deletePopUp(popUpIdUpdate.current);
        return;
      }

      setErrorMessage('Falha ao deletar PopUp');
    },

    onSuccess: () => {
      if (popUpIdUpdate.current) {
        removeItemFromScreen<FindAllPopUpList>(
          popUpCache,
          popUpIdUpdate.current,
        );
      }

      handleCloseManageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar domínio',
        setErrorMessage,
        () => {
          if (isAxiosError(error) && error.response?.status === 409) {
            setErrorMessage(
              'Este domínio só pode ser apagado depois que não houver itens associados a ele',
            );
            return true;
          }
        },
      );
    },
  });

  const { mutate: savePopUpMutate } = useMutation({
    mutationFn: async (dto: SavePopUpDto) => {
      return (await savePopUp(dto)).data;
    },

    onSuccess: (data) => {
      addItemOnScreen<FindAllPopUpList>(popUpCache, data);
      handleCloseManageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar PopUp',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('PopUp já cadastrado');
            return true;
          } else if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('Request failed') &&
            error.response?.data.statusCode === 500
          ) {
            setErrorMessage('Campo excede o número de caracteres permitido');
            return true;
          }
        },
      );
    },
  });

  const { mutate: updatePopUpMutate } = useMutation({
    mutationFn: async (dto: UpdatePopUpDto) => {
      return (await updatePopUp(dto)).data;
    },

    onSuccess: (data) => {
      updateItemOnScreen<FindAllPopUpList>(popUpCache, data);
      // handleCloseManageModal();
      handleUnedit()
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar PopUp',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('PopUp já cadastrado');
            return true;
          } else if (
            error instanceof AxiosError &&
            error.response?.data.statusCode === 500
          ) {
            setErrorMessage('Campo excede o número de caracteres permitido');
            return true;
          }
        },
      );
    },
  });

  return {
    popUpTitleUpdate,
    popUpIdUpdate: popUpIdUpdate.current,
    isShowEditButton,
    errorMessage,
    isManageModalOpen,
    isPreviewModalOpen,
    isConfirmDeleteModalOpen,
    errors,
    dirtyFields,
    editorTitle,
    editorContent,
    editorSubTitle,
    editorFooter,
    popUp,
    handleClosePreviewModal,
    register,
    clearError,
    handleDeleteButtonClick,
    handleCloseManageModal,
    handleEdit,
    handleButtonOkClick,
    handleSubmit,
    handleConfirmDeletePopUp,
    handleCloseConfirmDeleteModal,
    handleTableRowClick,
    handleAddPopUpClick,
    closeManageModal,
    handlePreviewPopUpClick,
  };
};
