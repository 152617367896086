import { CSSProperties } from 'react';

export const accordion: CSSProperties = {
  border: '1px solid #ddd',
  margin: '5px 0 0 0'
}
export const accordionSumary: CSSProperties = {
  border: '1px solid #ddd,',
}

export const link: CSSProperties = {
  fontSize: '24px',
  cursor: 'pointer',
}
