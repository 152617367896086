import { useForm } from 'react-hook-form';
import { useModal } from '../../../hooks/useModal';
import { ErrorDuimp } from '../../../types/errorDuimp';
import { useCallback } from 'react';

export const useErrorsDuimp = () => {
  const {
    isModalOpen: isManageModalOpen,
    handleCloseModal: handleCloseManageModal,
    handleOpenModal: handleOpenManageModal,
  } = useModal();

  const { register, setValue, watch } = useForm({
    defaultValues: {
      codigoProduto: 0,
      isDuimp: '',
      caracteristicas: '',
      ncm: '',
      errors: '',
    },
  });

  const codigoProduto = watch('codigoProduto');
  const isDuimp = watch('isDuimp');
  const caracteristicas = watch('caracteristicas');
  const ncm = watch('ncm');
  const errors = watch('errors');

  const handleTableRowClick = useCallback(
    (error: ErrorDuimp) => {
      setValue('codigoProduto', error.codigoProduto);
      setValue('isDuimp', error.isDuimp);
      setValue('ncm', error.ncm);
      setValue('caracteristicas', error.caracteristicas);
      setValue('errors', error.errors);

      handleOpenManageModal();
    },
    [handleOpenManageModal, setValue],
  );

  return {
    codigoProduto,
    isDuimp,
    caracteristicas,
    ncm,
    errors,
    isManageModalOpen,
    handleCloseManageModal,
    register,
    handleTableRowClick,
  };
};
