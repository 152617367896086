import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';

export const partsGroupContainerStyle: SxProps<Theme> = {
  width: '100%',
  '@media (max-width: 1400px)': {
    width: '100%',
  },
  '@media (max-width: 1250px)': {
    width: '100%',
    display: 'flex',
    // maxHeight: '4rem',
    gap: '1rem',
    // alignItems: 'center',
    overflow: 'hidden',
  },

  '@media (max-width: 1024px)': {
    flexDirection: 'column',
    // alignItems: 'center',
    gap: '1rem',
  },
};

export const partsGroupItemStyle: SxProps<Theme> = {
  padding: '0.7rem 1rem',
  // borderBottom: '1px solid #cdcdcd',
  borderTop: '2px solid #cdcdcd',
  justifyContent: 'center',
  cursor: 'pointer',
  textAlign: 'center',
};
