import { useMutation, useQuery } from 'react-query';
import { useTitle } from '../../../hooks/useTitle';
import {
  documentsCache,
  documentTypesCache,
  searchCache,
} from '../../../constants/requestCacheName';
import { DocumentFormData } from '../../../types/images';
import {
  deleteDocument,
  deleteDocumentStorage,
  saveDocument,
  saveDocuments,
  updateDocument,
  uploadDocumentsStorage,
  uploadDocumentStorage,
} from '../../../services/documents';
import { useModal } from '../../../hooks/useModal';
import { useEffect, useRef, useState } from 'react';
import { getDocumentTypesPaginated } from '../../../services/documentTypes';
import { DocumentTypePaginated } from '../../../types/documentTypes';
import { uploadDocumentSchema } from '../../../validations/schemas';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useError } from '../../../hooks/useError';
import {
  getImageFromApi,
  verifyImageUrlExists,
} from '../../../utils/getImageUrl';
import {
  DocumentsPaginated,
  SaveDocumentsDto,
  UpdateDocumentDto,
} from '../../../types/document';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import axios, { AxiosError } from 'axios';
import { useEdit } from '../../../hooks/useEdit';
import { getFileNameByUrl } from '../../../utils/urlToFileName';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { urlToFileList } from '../../../utils/urlToFileList';
import { documentsSearchBaseEndpoint } from '../../../constants/endpoints';

export const useUploadDocument = () => {
  useTitle('Documento');

  const documentNameUpdate = useRef<string | null>(null);
  const documentIdUpdate = useRef<number | null>(null);
  const documentUrlUpdate = useRef('');
  const documentTypeUpdate = useRef('');
  const previousFileList = useRef<FileList | null>(null);

  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [isDocumentDeleteLoading, setIsDocumentDeleteLoading] = useState(false);
  const [currentDocumentId, setCurrentDocumentId] = useState<number | null>(
    null,
  );

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { mutate: updateDocumentMutate } = useMutation({
    mutationFn: async ({ id, name, type, url }: UpdateDocumentDto) => {
      return (await updateDocument({ id, name, type, url })).data;
    },

    onSuccess: (data) => {
      updateItemOnScreen<DocumentsPaginated>(documentsCache, {
        documentType: data.documentType,
        id: data.id,
        name: data.name,
        url: data.url,
      });
      // handleCloseMenageModal();
      handleUnedit();
    },

    onError: (error, variables) => {
      (async () => {
        const fileNameWithCurrentUrl = getFileNameByUrl(variables.url);
        if (fileNameWithCurrentUrl) {
          await deleteDocumentStorage(fileNameWithCurrentUrl, variables.type);

          const fileNameWithPreviousUrl = getFileNameByUrl(
            documentUrlUpdate.current,
          );

          if (fileNameWithPreviousUrl) {
            uploadDocumentStorage(
              documentTypeUpdate.current,
              fileNameWithPreviousUrl,
              previousFileList.current![0],
            );
          }
          previousFileList.current = null;
        }
      })();

      mutationErrorHandling(
        error,
        'Falha ao atualizar documento',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage(`Já foi cadastrado um documento com este nome`);
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteDocumentMutate } = useMutation({
    mutationFn: async () => {
      if (documentIdUpdate.current) {
        await deleteDocument(documentIdUpdate.current);
        return;
      }

      setErrorMessage('Falha ao deletar documento');
    },

    onSuccess: () => {
      if (documentIdUpdate.current) {
        removeItemFromScreen(
          `${searchCache}${documentsSearchBaseEndpoint}`,
          documentIdUpdate.current,
        );
      }
      setIsDocumentDeleteLoading(false);
      handleCloseMenageModal();
    },

    onError: (error) => {
      (async () => {
        const fileName = getFileNameByUrl(documentUrlUpdate.current);

        if (fileName) {
          uploadDocumentStorage(
            documentTypeUpdate.current,
            fileName,
            previousFileList.current![0],
          );
        }
        setIsDocumentDeleteLoading(false);
        previousFileList.current = null;
      })();

      mutationErrorHandling(
        error,
        'Falha ao deletar documento',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.statusCode === 409
          ) {
            setErrorMessage(
              'Esta documento só pode ser apagado depois que não houver relações com outras tabelas',
            );
            return true;
          }
        },
      );
    },
  });
  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    watch,
    setError,
    reset,
    setFocus,
    setValue,
  } = useForm<DocumentFormData>({
    resolver: zodResolver(uploadDocumentSchema),
    defaultValues: {
      reference: '',
    },
  });
  const files = watch('document');
  const reference = watch('reference');

  useEffect(() => {
    if (files?.length > 0) {
      setError('reference', {});
    }

    if (files?.length > 1) {
      setPreviewDocument('');
      setValue('reference', '');
      return;
    }

    if (files && files[0]) {
      if (files[0].type === 'application/pdf') {
        const previewUrl = URL.createObjectURL(files[0]);
        setPreviewDocument(previewUrl);
        setValue('reference', files[0].name);
        return;
      }
      setError('document', { message: 'Somente arquivos PDF são permitidos.' });
    }
  }, [files, setError, setValue]);

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: closeMenageModal,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: handleCloseConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  const [previewDocument, setPreviewDocument] = useState<string>('');

  const { data: documentTypes } = useQuery<DocumentTypePaginated>(
    documentTypesCache,
    async () => (await getDocumentTypesPaginated()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleCancelIconClick = () => {
    setValue('reference', '', { shouldDirty: true });
    setFocus('reference');
  };

  const handleCloseMenageModal = () => {
    documentIdUpdate.current = null;
    documentNameUpdate.current = null;
    documentTypeUpdate.current = '';
    documentUrlUpdate.current = '';
    previousFileList.current = null;
    reset();
    handleUnedit();
    setPreviewDocument('');
    closeMenageModal();
  };

  const handleAddImageClick = () => {
    handleOpenMenageModal();
  };

  const handleTableRowClick = async (
    documentId: number,
    documentName: string,
    documentType: string,
    documentUrl: string,
  ) => {
    documentIdUpdate.current = documentId;
    documentNameUpdate.current = documentName;
    documentTypeUpdate.current = documentType;
    documentUrlUpdate.current = documentUrl;
    setCurrentDocumentId(documentId);
    setValue('reference', documentName);
    setValue('type', documentType);
    setPreviewDocument(documentUrl);
    handleOpenMenageModal();

    const fileList = await urlToFileList(documentUrl, documentName);
    if (fileList) {
      setValue('document', fileList);
    }
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmDeleteModal();
  };

  const clearFields = () => {
    setPreviewDocument('');
    reset();
  };

  const handleReferenceError = () => {
    setErrorMessage(`O campo referência não está preenchido corretamente.`);
    setIsDocumentLoading(false);
  };

  const addDocument = async (
    type: string,
    reference: string | undefined,
    document: FileList,
  ) => {
    // if (document.length > 1) {
    const documentsDto: SaveDocumentsDto[] = [];

    for (let i = 0; i < document.length; i++) {
      const doc = document[i];

      const documentName =
        document.length === 1 && reference ? reference : doc.name;

      const documentUrl = getImageFromApi(
        documentName,
        `documents/${type}`,
        false,
      );
      // const isDocumentNameExits = await fetch(documentUrl, { method: 'HEAD' });

      // if (isDocumentNameExits.ok) {
      //   setErrorMessage(
      //     `Documento com o nome ${documentName} já foi cadastrado`,
      //   );
      //   break;
      // }

      documentsDto.push({
        name: documentName,
        type,
        url: documentUrl,
      });
    }

    const documentsAlreadyRegisteredNames: string[] = [];

    await Promise.all(
      documentsDto.map(async (document) => {
        const documentUrl = getImageFromApi(
          document.name,
          `documents/${type}`,
          false,
        );
        const isDocumentNameExits = await fetch(documentUrl, {
          method: 'HEAD',
        });

        if (isDocumentNameExits.ok) {
          documentsAlreadyRegisteredNames.push(document.name);
        }
      }),
    );

    if (documentsAlreadyRegisteredNames.length > 0) {
      setErrorMessage(
        `O(s) seguinte(s) documento(s) já foi(ram) cadastrado(s) ${documentsAlreadyRegisteredNames.join(', ')}`,
      );
      setIsDocumentLoading(false);
      return;
    }

    try {
      await uploadDocumentsStorage(
        type,
        document,
        async () => {
          if (documentsDto.length > 1) {
            await saveDocuments(documentsDto);
            return;
          }

          const documentDto = documentsDto[0];
          const { data } = await saveDocument({
            name: documentDto.name,
            type: documentDto.type,
            url: documentDto.url,
          });

          addItemOnScreen<DocumentsPaginated>(
            `${searchCache}${documentsSearchBaseEndpoint}`,
            {
              documentType: data.documentType,
              id: data.id,
              name: data.name,
              url: data.url,
            },
          );
        },
        document.length === 1 ? documentsDto[0].name : null,
      );

      clearFields();
      // handleCloseMenageModal();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const status = error.response.status;
        const message = error.response.data.message;

        if (status === 400) {
          setErrorMessage(message);
          return;
        }
        setErrorMessage('Houve erro ao fazer upload dos documentos');
      }
    } finally {
      setIsDocumentLoading(false);
      return;
    }
  };

  const handleButtonOkClick = async ({
    reference,
    type,
    document,
  }: DocumentFormData) => {
    setIsDocumentLoading(true);
    if (
      documentIdUpdate.current &&
      documentTypeUpdate.current &&
      documentNameUpdate.current
    ) {
      if (!reference) {
        handleReferenceError();
        return;
      }

      const documentUrl = getImageFromApi(
        reference,
        `documents/${type}`,
        false,
      );
      const isDocumentNameExits = await fetch(documentUrl, { method: 'HEAD' });

      if (isDocumentNameExits.ok) {
        setErrorMessage('Já foi cadastrado um documento com este nome');
        setIsDocumentLoading(false);
        return;
      }

      const fileName = getFileNameByUrl(documentUrlUpdate.current);
      if (fileName) {
        previousFileList.current = await urlToFileList(
          documentUrlUpdate.current,
          documentNameUpdate.current,
        );

        const error = await deleteDocumentStorage(
          fileName,
          documentTypeUpdate.current,
        );

        if (!error || !previousFileList.current) {
          const upload = uploadDocumentStorage(type, reference, document[0]);
          upload.on(
            'state_changed',
            null,
            async (error) => {
              setErrorMessage(
                `Erro ao fazer upload do documento. ${error.message}`,
              );
              if (previousFileList.current) {
                uploadDocumentStorage(
                  documentTypeUpdate.current,
                  fileName,
                  previousFileList.current[0],
                );
              }
              setIsDocumentLoading(false);
            },
            () => {
              updateDocumentMutate({
                id: documentIdUpdate.current!,
                name: reference,
                type,
                url: getImageFromApi(reference, `documents/${type}`, false),
              });
              // clearFields();
              setIsDocumentLoading(false);
            },
          );

          return;
        }

        setErrorMessage(error);
        setIsDocumentLoading(false);
      }
      return;
    }

    addDocument(type, reference, document);
  };

  const handleConfirmDeleteDocument = async () => {
    setIsDocumentDeleteLoading(true);
    handleCloseConfirmDeleteModal();
    const fileName = getFileNameByUrl(documentUrlUpdate.current);
    if (documentNameUpdate.current && fileName) {
      previousFileList.current = await urlToFileList(
        documentUrlUpdate.current,
        documentNameUpdate.current,
      );

      const isUrlExists = await verifyImageUrlExists(documentUrlUpdate.current);

      if (isUrlExists) {
        const error = await deleteDocumentStorage(
          fileName,
          documentTypeUpdate.current,
        );

        if (!error) {
          deleteDocumentMutate();
          if (documentIdUpdate.current) {
            removeItemFromScreen(
              `${searchCache}${documentsSearchBaseEndpoint}`,
              documentIdUpdate.current,
            );
          }
          handleCloseConfirmDeleteModal();
          return;
        }

        handleCloseConfirmDeleteModal();
        setIsDocumentDeleteLoading(false);
        setErrorMessage(error);
        return;
      }

      deleteDocumentMutate();
      handleCloseConfirmDeleteModal();
      setIsDocumentDeleteLoading(false);
    }
  };

  const isShowEditButton = !!documentIdUpdate.current && !canEdit;

  return {
    isMenageModalOpen,
    reference,
    documentNameUpdate: documentNameUpdate.current,
    isConfirmDeleteModalOpen,
    documentTypes,
    previewDocument,
    errors,
    dirtyFields,
    errorMessage,
    isDocumentLoading,
    isDocumentDeleteLoading,
    isShowEditButton,
    files: Array.from(files ?? []),
    documentIdUpdate: documentIdUpdate.current,
    currentDocumentId,
    handleEdit,
    handleConfirmDeleteDocument,
    handleCloseConfirmDeleteModal,
    clearError,
    handleCancelIconClick,
    register,
    handleSubmit,
    handleDeleteButtonClick,
    handleButtonOkClick,
    handleTableRowClick,
    handleAddImageClick,
    handleCloseMenageModal,
  };
};
