import { useQueryClient } from 'react-query';
import { descriptionTypesApiCache } from '../../../constants/requestCacheName';
import { FindAllDescriptionTypes } from '../../../types/descriptionTypes';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { Description } from '../../../types/description';

export const useDescriptionSelectModal = () => {
  const queryClient = useQueryClient();

  const descriptionTypes: FindAllDescriptionTypes | undefined =
    queryClient.getQueryData(descriptionTypesApiCache);

  const {
    sortField,
    inputRef,
    isSearchInputDirty,
    handleSearch,
    itemsLength: descriptionTypesLength,
    itemsShownLength: descriptionTypesShownLength,
    itemsShown,
    handleSortTable,
    handleCancelSearch,
  } = useHandleTable(
    descriptionTypes?.meta.totalItems ?? 0,
    descriptionTypes?.items ?? [],
  );
  const descriptionTypesShown: Description[] = itemsShown;

  return {
    sortField,
    inputRef,
    isSearchInputDirty,
    handleSearch,
    descriptionTypes,
    descriptionTypesShown,
    descriptionTypesLength,
    descriptionTypesShownLength,
    handleSortTable,
    handleCancelSearch,
  };
};
