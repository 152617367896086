import { useNewsCard } from './useNewsCard';
import { News } from '../../types/news';
import CardSkeleton from '../../buffalos/components/CardSkeleton';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import DownloadIcon from '@mui/icons-material/Download';
import { formatDate } from '../../utils/formatDate';
import { Link } from 'react-router-dom';
import { forwardRef } from 'react';

pdfjs.GlobalWorkerOptions.workerSrc =
  window.location.origin + '/pdf.worker.min.mjs';

export type SetNewsCardWidthInPx = (value: number) => void;

type ItemCardProps = {
  news: News;
  index: number;
  onVideoPlay?: (newsId: number) => void;
  setNewsCardWidthInPx: SetNewsCardWidthInPx;
  onCardClick: (newsUrl: string, newsName?: string) => void;
};

const NewsCard = forwardRef<HTMLVideoElement, ItemCardProps>(
  ({ news, setNewsCardWidthInPx, onCardClick, onVideoPlay }, ref) => {
    const {
      isImageLoading,
      isValidLink,
      handleImageWasLoaded,
      handleDownload,
      handleLoadStart,
    } = useNewsCard(news, setNewsCardWidthInPx);

    if (!isValidLink) return null;

    return (
      <div
        key={news.id}
        // style={{ width: '500px' }}
        className="relative h-[570px] w-full rounded-2xl border-2"
      >
        {isImageLoading && (
          <div className="flex h-full items-center justify-center">
            <CardSkeleton mainSkeletonHeight="25rem" width="16rem" />
          </div>
        )}
        <div
          data-loading={isImageLoading}
          className="block h-[620px] w-[350px] data-[loading=true]:hidden"
        >
          <div className="absolute top-4 z-10 mx-[2rem] mb-4 flex w-[calc(100%-4rem)] items-start justify-between gap-4">
            <h3 className="text-xl">{news.name.split('.')[0]}</h3>
            {(news.url.includes('.pdf') || news.url.includes('.svg')) && (
              <button
                onClick={() => handleDownload(news.url, news.name)}
                className="cursor-pointer"
              >
                <DownloadIcon />
              </button>
            )}
          </div>
          <div className="absolute top-0 flex h-full w-full items-center justify-center">
            {news.url.includes('.pdf') ? (
              news.pdfBook ? (
                <div className="book">
                  <Link
                    to={`/image/${encodeURIComponent(news.newsType.name + '/' + news.name)}`}
                    target="_blank"
                  >
                    <Document
                      className="cursor-pointer"
                      file={news.url}
                      onLoadStart={handleLoadStart}
                      onLoad={handleImageWasLoaded}
                    >
                      <Page
                        pageNumber={1}
                        // width={300}
                        height={320}
                        renderTextLayer={false}
                      />
                    </Document>
                  </Link>
                </div>
              ) : (
                <div className="book">
                  <Document
                    className="cursor-pointer"
                    onClick={
                      !news.pdfBook ? () => onCardClick(news.url, news?.name) : undefined
                    }
                    file={news.url}
                    onLoadStart={handleLoadStart}
                    onLoad={handleImageWasLoaded}
                  >
                    <Page
                      pageNumber={1}
                      // width={300}
                      height={320}
                      renderTextLayer={false}
                    />
                  </Document>
                </div>
              )
            ) : news.url.includes('.mp4') ? (
              <video
                ref={ref}
                onPlay={() => onVideoPlay && onVideoPlay(news.id)}
                id={news.id.toString()}
                controls
                className="h-[200px]"
              >
                <source src={news.url} type="video/mp4" />
              </video>
            ) : (
              <div
                className="book h-[300px]"
                onClick={() => onCardClick(news.url)}
              >
                <iframe
                  src={news.url}
                  title="Media Preview"
                  className="h-[300px] max-w-[300px]"
                />
                <div className="absolute left-0 top-0 cursor-pointer bg-transparent"></div>
              </div>
            )}
          </div>
          <div className="absolute bottom-4 w-full text-center">
            <span>
              {news.date ? formatDate(news.date, { hour: undefined }) : ''}
            </span>
          </div>

          {/* {news.pdfBook ? (
          <Link
            to={`/image/${encodeURIComponent(news.newsType.name + '/' + news.name)}`}
            target="_blank"
            className="absolute top-20"
          >
            Acesse o pdf
          </Link>
        ) : (
          ''
        )} */}

          {/* <Modal
          isOpen={newsSelectedId === news.id}
          onClose={onCloseModal}
          height="85vh"
        >
          <Card.Root contentClassName="max-sm:mx-0 max-sm:mt-0">
            <div className="h-[80vh]">
              <h2 className="mb-8 text-center text-xl">Notícia</h2>
              <div
                className={`${news.fileType === 'image/svg+xml' ? 'w-50 h-72' : 'h-full w-full'}`}
              >
                <iframe
                  src={news.url}
                  title="File Preview"
                  className="h-full w-full"
                />
              </div>
            </div>
          </Card.Root>
        </Modal> */}
        </div>
      </div>
    );
  },
);

export default NewsCard;
