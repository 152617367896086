import {
  DescriptionsPaginated,
  FindAllDescriptions,
} from '../types/description';
import { Param } from '../types/params';
import generatePaginatedUrl, {
  addSearchParamsInUrl,
} from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getAllDescriptions = async () => {
  const response = await api.get<FindAllDescriptions>(
    '/description/v1/find-all',
  );
  return response;
};

export const getDescriptionsPaginated = async (
  page: number,
  direction?: 'ASC' | 'DESC',
  limit?: number,
  params?: Param[],
) => {
  const url = addSearchParamsInUrl(
    'description/v1/search',
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    ...(params ?? []),
  );
  const response = await api.get<DescriptionsPaginated>(url);
  return response;
};

export const getDescriptionsByTypeIdPaginated = async (
  typeId: number,
  page: number,
  direction?: 'ASC' | 'DESC',
  limit?: number,
) => {
  const url = generatePaginatedUrl(
    `/description/v1/type-id/${typeId}`,
    page,
    limit,
    direction,
  );
  const response = await api.get<DescriptionsPaginated>(url);
  return response;
};

export const saveDescription = async (
  descriptionName: string,
  descriptionType: string,
) => {
  const body = {
    name: descriptionName,
    type: descriptionType,
  };

  const response = await api.post('/description/v1', body);
  return response;
};

export const updateDescription = async (
  descriptionId: number,
  descriptionName: string,
  descriptionType: string,
) => {
  const body = {
    id: descriptionId,
    name: descriptionName,
    type: descriptionType,
  };

  const response = await api.put('/description/v1', body);
  return response;
};

export const deleteDescription = async (descriptionId: number) => {
  const response = await api.delete(`/description/v1/${descriptionId}`);
  return response;
};
