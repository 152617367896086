import { useMutation, useQuery } from 'react-query';
import { useHandleTable } from '../../hooks/useHandleTable';
import { PopUp, PopUpListSort, PopUpSort } from '../../types/popUp';
import { getAllPopUpListPaginated } from '../../services/pop-up';
import { VinculatePopUpInDomain } from '../../types/popUpDomain';
import { useMemo, useRef, useState } from 'react';
import { getTableColumnWidthByBiggestElement } from '../../utils/tableColumnStyles';
import { domainCache } from '../../constants/requestCacheName';
import { getDomainsPaginated } from '../../services/domain';
import { FindAllDomainsPaginated } from '../../types/domain';
import { DomainVinculation } from '../../types/products';
import { useEdit } from '../../hooks/useEdit';
import { mutationErrorHandling } from '../../utils/errorHandling';
import { useError } from '../../hooks/useError';
import { updatePopUpDomainVinculation } from '../../services/pop-up-domain';
import formatSearch from '../../utils/formatSearch';

export const usePopUpContent = (queryKey: string) => {
  const [checkPopUpListDomains, setCheckPopUpListDomains] = useState<
    VinculatePopUpInDomain[]
  >([]);

  const [originalPopUpListDomains, setOriginalPopUpListDomains] = useState<
    VinculatePopUpInDomain[]
  >([]);

  const popUpListChanged = useRef<VinculatePopUpInDomain[]>([]);

  const endPageRef = useRef<HTMLDivElement>(null);
  const [domainWidth, setDomainWidth] = useState<number>();

  const { canEdit, handleEdit, handleUnedit } = useEdit();
  const { setErrorMessage } = useError();

  const {
    data: popUpList,
    isFetching: isFetchingPopUpList,
    refetch: refetchPopUp,
  } = useQuery<PopUpListSort>(
    queryKey,
    async () => (await getAllPopUpListPaginated()).data,
    {
      retry: true,
      refetchOnWindowFocus: false,

      onSuccess: (data) => {
        const checkPopUpList: VinculatePopUpInDomain[][] = data?.items.map(
          (popUp) => {
            const checked: VinculatePopUpInDomain[] = popUp.domains?.map(
              (domain) => ({
                domainId: domain.id,
                popUpId: popUp.id,
                isCheck: true,
              }),
            );

            return checked;
          },
        );
        if (checkPopUpList) {
          setCheckPopUpListDomains(checkPopUpList.flat());
          setOriginalPopUpListDomains(checkPopUpList.flat());
        }
      },
    },
  );

  const { data: domains } = useQuery<FindAllDomainsPaginated>(
    domainCache,
    async () => (await getDomainsPaginated()).data,
    {
      onSuccess: () => {
        const maxWidth = (domains?.items ?? []).reduce((maxWidth, row) => {
          const width = row.name?.toString().length || 0;
          return width > maxWidth ? width : maxWidth;
        }, 0);
        setDomainWidth((maxWidth > 32 ? maxWidth + 2 : 32) / 2);
      },
    },
  );

  const {
    isSearchInputDirty,
    itemsShown,
    sortField,
    inputRef,
    handleSearch: search,
    handleCancelSearch,
    handleSortTable,
    isLoadMoreFetching,
    itemsLength: popUpListLength,
    itemsShownLength: popUpListShownLength,
  } = useHandleTable(
    popUpList?.meta.totalItems ?? 0,
    popUpList?.items ?? [],
    null,
    null,
    null,
    null,
  );

  const popUpListShown: PopUpSort[] = itemsShown;

  const nameColWidth = useMemo(
    () =>
      getTableColumnWidthByBiggestElement<PopUpSort>(
        popUpListShown ?? [],
        'titleField',
        10,
      ) + 4,
    [popUpListShown],
  );

  const handleSearch = () => {
    search<PopUp>((item, formatedSearch) => {
      return formatSearch(item.titleField).includes(formatedSearch);
    });
  };

  const handleCheckDomain = (
    isCheck: boolean,
    popUpId: number,
    domainId: number,
    domains: DomainVinculation[],
  ) => {
    const filteredDomains = checkPopUpListDomains.filter(
      (domain) => domain?.domainId !== domainId,
    );

    const popUpListDomainsWithoutSelected = popUpListChanged.current.filter(
      (domain) => domain.domainId !== domainId,
    );

    if (isCheck) {
      popUpListChanged.current = popUpListDomainsWithoutSelected;
      popUpListChanged.current.push({ domainId, popUpId, isCheck: true });
      filteredDomains.push({ domainId, popUpId, isCheck: true });
      setCheckPopUpListDomains(filteredDomains);
      return;
    }

    popUpListChanged.current = popUpListDomainsWithoutSelected;
    popUpListChanged.current.push({ domainId, popUpId, isCheck: false });
    filteredDomains.push({ domainId, popUpId, isCheck: false });
    setCheckPopUpListDomains(filteredDomains);
  };

  const handleButtonOkClick = async () => {
    if (canEdit) {
      if (checkPopUpListDomains.length > 0) {
        updatePopUpDomainVinculationMutate();
      }
      handleUnedit();
      return;
    }
    handleEdit();
  };

  const handleButtonCancelEditClick = async () => {
    if (canEdit) {
      setCheckPopUpListDomains([...originalPopUpListDomains]);
      popUpListChanged.current = [];
      handleUnedit();
    }
  };

  const { mutate: updatePopUpDomainVinculationMutate } = useMutation({
    mutationFn: async () => {
      if (popUpListChanged.current.length > 0) {
        const updatedList = [...popUpListChanged.current];
        popUpListChanged.current.forEach((popUp) => {
          originalPopUpListDomains.forEach((originalPopUp) => {
            if (
              originalPopUp?.domainId === popUp.domainId &&
              originalPopUp.popUpId !== popUp.popUpId
            ) {
              updatedList.push({
                domainId: originalPopUp.domainId,
                popUpId: originalPopUp.popUpId,
                isCheck: false,
              });
            }
          });
        });
        popUpListChanged.current = updatedList;

        return (await updatePopUpDomainVinculation(popUpListChanged.current))
          .data;
      }
    },

    onSuccess: () => {
      if (popUpListChanged.current) {
        popUpListChanged.current = [];
      }
      setOriginalPopUpListDomains([...checkPopUpListDomains]);
      refetchPopUp();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao vincular ou desvincular popUp ao domínio',
        setErrorMessage,
      );
    },
  });

  return {
    inputRef,
    isSearchInputDirty,
    isFetchingPopUpList,
    popUpListShown,
    nameColWidth,
    sortField,
    domainWidth,
    domains,
    popUpListShownLength,
    popUpListLength,
    checkPopUpListDomains,
    canEdit,
    isLoadMoreFetching,
    endPageRef,
    handleSortTable,
    handleSearch,
    handleCancelSearch,
    handleCheckDomain,
    handleButtonOkClick,
    handleButtonCancelEditClick,
  };
};
