import { Content, TableCell } from 'pdfmake/interfaces';
import { ItemFullTablePdf } from '../types/item';

const listingCommonContent = (
  items: ItemFullTablePdf[],
  lastTableHeadContent?: string,
): Content => {
  const tableBody = items.flatMap((item) => {
    if (item.partsGroups && item.partsGroups.length > 0) {
      return item.partsGroups.map((pg) => [
        {
          text: item.id,
          margin: [16, 3],
          fontSize: 10,
          border: [false, true, false, false],
        } as TableCell,
        {
          text: item.name,
          margin: [4, 3],
          fontSize: 10,
          border: [false, true, false, false],
        } as TableCell,
        {
          text: pg?.qtdInGroup.toString(),
          margin: [16, 3],
          alignment: 'left',
          fontSize: 10,
          border: [false, true, false, false],
        } as TableCell,
        {
          text: pg?.partsGroup?.name,
          margin: [16, 3],
          alignment: 'left',
          fontSize: 11,
          border: [false, true, false, false],
        } as TableCell,
      ]);
    }

    return [
      item.lastColContent
        ? [
            {
              text: item.id,
              margin: [16, 3],
              fontSize: 10,
              border: [false, true, false, false],
            } as TableCell,
            {
              text: item.name,
              margin: [4, 3],
              fontSize: 10,
              border: [false, true, false, false],
            } as TableCell,
            {
              text: item.lastColContent,
              margin: [16, 4],
              alignment: 'right',
              fontSize: 11,
              border: [false, true, false, false],
            } as TableCell,
          ]
        : [
              {
                text: item.id,
                margin: [16, 3],
                fontSize: 10,
                border: [false, true, false, false],
              } as TableCell,
              {
                text: item.name,
                margin: [4, 3],
                fontSize: 10,
                border: [false, true, false, false],
              } as TableCell,
            ]
    ];
  });

  return [
    {
      table: {
        widths: items.every((partsGroup) => !!partsGroup.partsGroups)
          ? [80, 200, 50, '*']
          : lastTableHeadContent
            ? [80, '*', 200]
            : [80, '*'],
        headerRows: 1,
        body: [
          items.every((partsGroup) => !!partsGroup.partsGroups)
            ? [
                {
                  text: 'Código',
                  fontSize: 12,
                  italics: true,
                  margin: [16, 5, 0, 4],
                  border: [false, false, false, false],
                },
                {
                  text: 'Nome',
                  fontSize: 12,
                  italics: true,
                  margin: [4, 5, 0, 4],
                  border: [false, false, false, false],
                },
                {
                  text: 'Qtd',
                  fontSize: 12,
                  italics: true,
                  margin: [10, 5, 0, 4],
                  border: [false, false, false, false],
                },
                {
                  text: 'Conjunto de peça',
                  fontSize: 12,
                  italics: true,
                  margin: [12, 5, 0, 4],
                  border: [false, false, false, false],
                },
              ]
            : lastTableHeadContent
              ? [
                  {
                    text: 'Código',
                    margin: [16, 5, 0, 8],
                    italics: true,
                    border: [false, false, false, false],
                  },
                  {
                    text: 'Nome',
                    margin: [4, 8, 0, 8],
                    italics: true,
                    border: [false, false, false, false],
                  },
                  {
                    text: lastTableHeadContent,
                    margin: [16, 8],
                    alignment: 'right',
                    italics: true,
                    border: [false, false, false, false],
                  },
                ]
              : [
                  {
                    text: 'Código',
                    fontSize: 12,
                    italics: true,
                    margin: [16, 5, 0, 4],
                    border: [false, false, false, false],
                  },
                  {
                    text: 'Nome',
                    fontSize: 12,
                    italics: true,
                    margin: [4, 5, 0, 4],
                    border: [false, false, false, false],
                  },
                ],
          ...tableBody,
        ],
      },
      layout: {
        hLineWidth: items.every((partsGroup) => !!partsGroup.partsGroups)
          ? () => 0
          : () => 0,
      },
    },
  ];
};

export default listingCommonContent;
