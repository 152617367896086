import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ArrowProps } from 'react-multi-carousel';

type CarouselLeftArrowBuffaloProps = ArrowProps & {};

export default function CarouselLeftArrowBuffalo({
  ...rest
}: CarouselLeftArrowBuffaloProps) {
  return (
    <ChevronLeftIcon
      className="absolute left-3 cursor-pointer text-5xl text-yellow-400"
      sx={{ fontSize: '3.5rem' }}
      {...rest}
    />
  );
}
