import { useCallback, useRef, useState } from 'react';
import { useSignatureDomainStore } from '../../../store/signatureDomain';
import queryClient from '../../../libs/queryClient';
import { FindAllCoordinates } from '../../../types/domainImageCoordinate';
import { useModal } from '../../../hooks/useModal';
import { useError } from '../../../hooks/useError';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuth } from '../../../utils/auth';
import { useMutation, useQuery } from 'react-query';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import {
  deleteCoordinates,
  getAllCoordinatesByDomainId,
  saveUpdateCoordinate,
} from '../../../services/signatureDomain';
import {
  domainCache,
  fieldsDomainsCoordinateCache,
  fieldsDomainsCoordinateCacheCopy,
} from '../../../constants/requestCacheName';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { getDomainsPaginated } from '../../../services/domain';
import { FindAllDomainsPaginated } from '../../../types/domain';
import { DefaultValuesCoordinate } from '../../../constants/defaultValuesCoordinates';

export const useDomainFieldsPosition = () => {
  const { errorMessage, clearError, setErrorMessage } = useError();
  const navigate = useNavigate();
  const { domainId } = useParams();

  const {
    isModalOpen: isConfirmModifiedModalOpen,
    handleCloseModal: handleCloseModifiedModal,
    handleOpenModal: handleOpenModifiedModal,
  } = useModal();

  const {
    state: {
      canEdit,
      coordinatesModified,
      coordinatesToDelete,
      currentFieldHoverId,
      isModified,
    },
    actions: {
      setCoordinateDelete,
      setCoordinateModified,
      setCurrentFieldHoverId,
      clear,
      handleEdit,
      handleUnedit,
    },
  } = useSignatureDomainStore();

  const { clearCache } = useQueryCache();

  // const explodedViewImageName = 'Buffalo.png';
  const auth = getAuth();

  const handleEditButtonClick = () => {
    handleEdit();
  };

  const handleConfirmBack = () => {
    clear();
    navigate(-1);
  };

  const handleFieldMouseEnter = useCallback(
    (fieldId: number) => {
      setCurrentFieldHoverId(fieldId);
    },
    [setCurrentFieldHoverId],
  );

  const handleFieldMouseOut = () => {
    setCurrentFieldHoverId(null);
  };

  const handleOkButtonClick = () => {
    let hasRequest = false;

    if (coordinatesModified.length > 0) {
      hasRequest = true;
      saveUpdateCoordinatesMutate();
    }

    if (coordinatesToDelete.length > 0) {
      hasRequest = true;
      deleteCoordinatesMutate();
    }

    if (!hasRequest) {
      handleUnedit();
    }
  };

  const { mutate: saveUpdateCoordinatesMutate } = useMutation({
    mutationFn: async () => {
      return (await saveUpdateCoordinate(coordinatesModified, +domainId!)).data;
    },

    onSuccess: () => {
      const fieldsImagesCoordinates =
        queryClient.getQueryData<FindAllCoordinates>(
          `${fieldsDomainsCoordinateCache}${domainId}`,
        );

      if (!fieldsImagesCoordinates) {
        console.warn('Cache not found for:', fieldsDomainsCoordinateCache);
        return;
      }

      const coordinatesFiltered = fieldsImagesCoordinates?.items.filter(
        (coordinate) =>
          !coordinatesModified.some(
            (coordinateModified) =>
              coordinateModified.id ===
              `${coordinate.field.id}${coordinate.x}${coordinate.y}`,
          ),
      );

      coordinatesModified.forEach((coordinate) => {
        coordinatesFiltered?.push({
          height: coordinate.height,
          width: coordinate.width,
          x: coordinate.x,
          y: coordinate.y,
          bold: coordinate.bold,
          color: coordinate.color,
          fontSize: coordinate.fontSize,
          fontStyle: coordinate.fontStyle,
          textDecoration: coordinate.textDecoration,
          isVisible: coordinate.isVisible,
          field: {
            id: coordinate.fieldId,
          },
          domain: {
            id: coordinate.domainId,
          },
        });
      });

      // Remove o cache das coordenadas copiadas
      clearCache(
        `${fieldsDomainsCoordinateCacheCopy}${selectedDomainId.current}`,
      );

      // overrideCache<FindAllCoordinates>(
      //   `${fieldsDomainsCoordinateCacheCopy}${selectedDomainId.current}`,
      //   {
      //     items: [],
      //     meta: { totalItems: 0 },
      //   },
      // );

      // Invalida a consulta para forçar o re-render
      queryClient.invalidateQueries(
        `${fieldsDomainsCoordinateCache}${domainId}`,
      );

      handleUnedit();
      setCoordinateModified([]);
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar as coordenadas',
        setErrorMessage,
      );
    },
  });

  const { mutate: deleteCoordinatesMutate } = useMutation({
    mutationFn: async () => {
      return (await deleteCoordinates(coordinatesToDelete)).data;
    },

    onSuccess: () => {
      setCoordinateDelete([]);
      handleUnedit();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar coordenadas',
        setErrorMessage,
      );
    },
  });

  const handleBackButtonClick = () => {
    if (isModified.current) {
      handleOpenModifiedModal();
      return;
    }
    handleConfirmBack();
  };

  const { isModalOpen, handleCloseModal, handleOpenModal } = useModal();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);

  const { data: domainShown } = useQuery<FindAllDomainsPaginated>(
    domainCache,
    async () => (await getDomainsPaginated()).data,
  );

  const selectedDomainId = useRef<number | null>(null);

  const domainIdNumber = Number(domainId);
  const {
    data: fieldsDomainCoordinatesCopy,
    refetch: fieldsDomainCoordinatesCopyRefetch,
  } = useQuery<FindAllCoordinates>(
    `${fieldsDomainsCoordinateCacheCopy}${selectedDomainId.current}`, // Usa o ID atualizado
    async () => {
      return (await getAllCoordinatesByDomainId(selectedDomainId.current!))
        .data;
    },
    {
      enabled: false, // A query não executa automaticamente, só quando chamamos refetch
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.items) {
          // Atualiza o estado com os novos valores da API
          setCoordinateModified(
            data.items.map((item) => ({
              id: Date.now().toString(),
              fieldId: item.field.id,
              domainId: domainIdNumber,
              x: item.x,
              y: item.y,
              width: item.width,
              height: item.height,
              color: item.color || DefaultValuesCoordinate.color, // Garantir valor default
              fontSize: item.fontSize ?? DefaultValuesCoordinate.fontSize, // Valor padrão de 14 para fontSize
              fontStyle: item.fontStyle || DefaultValuesCoordinate.fontStyle, // Garantir valor default
              textAlign: item.textAlign || DefaultValuesCoordinate.textAlign, // Valor padrão para textAlign
              bold: item.bold || DefaultValuesCoordinate.bold, // Garantir valor default
              textDecoration:
                item.textDecoration || DefaultValuesCoordinate.textDecoration, // Garantir valor default
              isVisible: item.isVisible ?? DefaultValuesCoordinate.isVisible, // Valor padrão true para visibilidade
            })),
          );
        }
      },
    },
  );

  const handleClickRowByDomain = () => {
    // Atualiza o ID dentro do useRef
    fieldsDomainCoordinatesCopyRefetch();
    setIsOpenConfirmModal(false);
    handleCloseModal();
  };

  const handleOpenConfirmModal = (domainId: number) => {
    selectedDomainId.current = domainId;
    setIsOpenConfirmModal(true);
  };

  const handleCancelConfirmModal = () => {
    setIsOpenConfirmModal(false);
  };

  const handleOpenDomainsModal = () => {
    handleOpenModal();
  };

  const handleCloseDomainModal = () => {
    handleCloseModal();
  };

  return {
    currentFieldHoverId,
    // explodedViewImageName,
    canEdit,
    isModalOpen,
    domainShown,
    auth,
    errorMessage,
    isConfirmModifiedModalOpen,
    fieldsDomainCoordinates: fieldsDomainCoordinatesCopy,
    domainIdNumber,
    domainId,
    isOpenConfirmModal,
    handleOpenConfirmModal,
    handleCancelConfirmModal,
    handleOpenDomainsModal,
    handleCloseDomainModal,
    handleClickRowByDomain,
    clearError,
    handleCloseModifiedModal,
    handleConfirmBack,
    handleFieldMouseEnter,
    handleFieldMouseOut,
    // handleClickField,
    handleOkButtonClick,
    handleBackButtonClick,
    handleEditButtonClick,
  };
};
