import { twMerge } from 'tailwind-merge';
import { FilterItem } from '../../types/filter';

type FilterProps = {
  filters: FilterItem[];
  title?: string;
  onFilter: (filter: string) => void;
  onDoubleClickFilter?: (filter: string) => void;
  style?: React.CSSProperties;
};

export default function Filter({
  title,
  filters,
  style,
  onFilter,
  onDoubleClickFilter,
}: FilterProps) {
  return (
    <>
      <h2>{title}</h2>
      <div className="mb-4 flex flex-wrap gap-4 rounded-lg bg-[#e0e0e0] px-2 py-2 max-md:flex-col" style={style}>
        {filters.map((filter) => (
          <span
            key={`${filter.name}${filter.filter}`}
            onClick={() => onFilter(filter.filter)}
            onDoubleClick={() =>
              onDoubleClickFilter && onDoubleClickFilter(filter.filter)
            }
            className={twMerge(
              'flex min-w-[100px] flex-grow cursor-pointer items-center justify-center overflow-hidden text-ellipsis whitespace-nowrap rounded-lg max-md:py-1',
              `${filter.isCurrent ? 'border border-primary bg-backgroundPaper text-primary' : 'text-primaryText'}`,
            )}
          >
            {filter.name}
          </span>
        ))}
      </div>
    </>
  );
}
