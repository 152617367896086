import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Modal from '../../../components/Modal';
import PopUpContent from '../../../components/PopUpContent';
import { popUpCache } from '../../../constants/requestCacheName';
import { usePopUp } from './usePopUp';
import EditorMenu from '../../../components/Editor/EditorMenu';
import Editor from '../../../components/Editor';
import Popup from '../../../components/PupUp';

export default function PopUp() {
  const {
    popUpIdUpdate,
    isShowEditButton,
    isManageModalOpen,
    isConfirmDeleteModalOpen,
    errorMessage,
    errors,
    editorTitle,
    editorContent,
    editorSubTitle,
    editorFooter,
    isPreviewModalOpen,
    popUp,
    handleClosePreviewModal,
    clearError,
    handleDeleteButtonClick,
    handleCloseManageModal,
    handleEdit,
    handleButtonOkClick,
    handleSubmit,
    handleConfirmDeletePopUp,
    handleCloseConfirmDeleteModal,
    handleTableRowClick,
    handleAddPopUpClick,
    handlePreviewPopUpClick,
  } = usePopUp();

  return (
    <>
      <PopUpContent
        onTableRowClick={handleTableRowClick}
        onAddPopUpClick={handleAddPopUpClick}
        onPreviewClick={handlePreviewPopUpClick}
        queryKey={popUpCache}
      />

      <Modal
        isOpen={isManageModalOpen}
        onClose={handleCloseManageModal}
        cancelIcon={false}
        contentStyle="overflow-auto"
      >
        <HeaderModalManageItem
          showDeleteButton={!!popUpIdUpdate}
          deleteButtonContent={'Apagar Pop-Up'}
          isEditable={!isShowEditButton}
          onDeleteButtonClick={handleDeleteButtonClick}
          title={popUpIdUpdate ? 'Pop-Up' : 'Novo Pop-Up'}
          mainButtonContent={isShowEditButton ? 'Editar' : 'Ok'}
          onCancelClick={handleCloseManageModal}
          onOkClick={
            !isShowEditButton ? handleSubmit(handleButtonOkClick) : undefined
          }
          onEditClick={isShowEditButton ? handleEdit : undefined}
        />
        <div className="flex flex-col gap-8">
          <div className="mt-5">
            <label>Título</label>
            <EditorMenu
              editor={editorTitle}
              disabled={isShowEditButton}
              showColorBox={true}
              showTextAlign={true}
            />
            <Editor
              editor={editorTitle}
              helperText={errors.titleField?.message as string}
            />
          </div>
          <div className="mb-5">
            <label>Subtítulo</label>
            <EditorMenu
              editor={editorSubTitle}
              disabled={isShowEditButton}
              showColorBox={true}
              showTextAlign={true}
            />
            <Editor
              editor={editorSubTitle}
              helperText={errors.subtitleField?.message as string}
            />
          </div>
          <div className="mb-5">
            <label>Conteúdo</label>
            <EditorMenu
              editor={editorContent}
              disabled={isShowEditButton}
              showColorBox={true}
              showTextAlign={true}
            />
            <Editor
              editor={editorContent}
              helperText={errors.contentField?.message as string}
            />
          </div>
          <div className="mb-5">
            <label>Rodapé</label>
            <EditorMenu
              editor={editorFooter}
              disabled={isShowEditButton}
              showColorBox={true}
              showTextAlign={true}
            />
            <Editor
              editor={editorFooter}
              helperText={errors.footerField?.message as string}
            />
          </div>
        </div>
      </Modal>

      <Popup
        isOpen={isPreviewModalOpen}
        onClose={handleClosePreviewModal}
        popUp={popUp}
      />

      {/* <Modal
        isOpen={isPreviewModalOpen}
        onClose={handleClosePreviewModal}
        contentStyle={'mx-5'}
      >
        <div className="mt-2 flex flex-col gap-4 break-words">
          <div
            dangerouslySetInnerHTML={{ __html: popUp?.titleField ?? '' }}
            className="w-11/12 text-3xl"
          />
          <div
            dangerouslySetInnerHTML={{ __html: popUp?.subtitleField ?? '' }}
            className="text-2xl"
          />
          <div
            dangerouslySetInnerHTML={{ __html: popUp?.contentField ?? '' }}
            className="text-xl"
          />
          <div
            dangerouslySetInnerHTML={{ __html: popUp?.footerField ?? '' }}
            className="text-xl"
          />
        </div>
      </Modal> */}

      <ConfirmModal
        contentMessage="Deseja excluir esse PopUp ?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDeletePopUp}
        onNotConfirm={handleCloseConfirmDeleteModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
