import TableRowMui, {
  TableRowProps as TableRowPropsMui,
} from '@mui/material/TableRow';
import { twMerge } from 'tailwind-merge';

type TableRowProps = TableRowPropsMui & {
  isHead?: boolean;
  isSelected?: boolean;
  isHovered?: boolean;
};

export default function TableRow({
  children,
  isHead = false,
  isSelected = false,
  isHovered = false,
  className,
  ...rest
}: TableRowProps) {
  return (
    <TableRowMui
      sx={{
        display: 'flex',
        backgroundColor: isSelected
          ? '#eab308'
          : isHovered
            ? 'rgba(234, 179, 8, 0.5)'
            : 'transparent',
        ':hover': !isHead
          ? {
              backgroundColor: isSelected
                ? '#eab308'
                : 'rgba(234, 179, 8, 0.5)',
            }
          : {},
      }}
      className={twMerge(
        `justify-between border-b px-8 py-2 ${!isHead ? 'cursor-pointer' : 'py-0'}`,
        className,
      )}
      {...rest}
    >
      {children}
    </TableRowMui>
  );
}
