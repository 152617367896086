import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTitle } from '../../../hooks/useTitle';
import { useError } from '../../../hooks/useError';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { useMutation, useQuery } from 'react-query';
import {
  NewsFormData,
  NewsListPaginated,
  SaveNewsListDto,
  UpdateNewsDto,
} from '../../../types/news';
import {
  deleteNews,
  deleteNewsStorage,
  saveNews,
  saveNewsList,
  updateNews,
  uploadNewsListStorage,
  uploadNewsStorage,
} from '../../../services/news';
import { newsCache, newsTypesCache } from '../../../constants/requestCacheName';
import { getFileNameByUrl } from '../../../utils/urlToFileName';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { AxiosError } from 'axios';
import { useEdit } from '../../../hooks/useEdit';
import { useForm } from 'react-hook-form';
import { uploadNewsSchema } from '../../../validations/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { useModal } from '../../../hooks/useModal';
import { NewsTypePaginated } from '../../../types/newsType';
import { getNewsTypesPaginated } from '../../../services/newsTypes';
import { urlToFileList } from '../../../utils/urlToFileList';
import { getImageFromApi } from '../../../utils/getImageUrl';
import { useNavigate } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
import { useScreenHeight } from '../../../hooks/useHeight';

export const useUploadNews = () => {
  useTitle('Notícia');

  const navigate = useNavigate();
  const [linkPdf, setLinkPdf] = useState<string>('');

  const { screenHeight } = useScreenHeight();

  const newsNameUpdate = useRef<string | null>(null);
  const newsIdUpdate = useRef<number | null>(null);
  const newsUrlUpdate = useRef('');
  const newsTypeUpdate = useRef('');
  const fileTypeUpdate = useRef('');
  const newsPdfBookUpdate = useRef<string>('');
  const previousFileList = useRef<FileList | null>(null);
  const [isValidUrl, setIsValidUrl] = useState<null | boolean>(null);
  const [isNewsLoading, setIsNewsLoading] = useState(false);
  const [isNewsDeleteLoading, setIsNewsDeleteLoading] = useState(false);
  const { errorMessage, clearError, setErrorMessage } = useError();
  // const [date, setDate] = useState<Date>();
  const [previewNews, setPreviewNews] = useState<string>('');

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { mutate: updateNewsMutate } = useMutation({
    mutationFn: async ({
      id,
      name,
      type,
      url,
      fileType,
      date,
      pdfBook,
    }: UpdateNewsDto) => {
      return (
        await updateNews({ id, name, type, url, fileType, date, pdfBook })
      ).data;
    },

    onSuccess: (data) => {
      if (true) {
        updateItemOnScreen<NewsListPaginated>(newsCache, {
          newsType: data.newsType,
          id: data.id,
          name: data.name,
          url: data.url,
          order: data.order,
          fileType: data.fileType,
          date: data.date,
          pdfBook: data.pdfBook,
        });
      }
      // handleCloseMenageModal();
      handleUnedit();
    },

    onError: (error, variables) => {
      (async () => {
        const fileNameWithCurrentUrl = getFileNameByUrl(variables.url);
        if (fileNameWithCurrentUrl) {
          await deleteNewsStorage(fileNameWithCurrentUrl, variables.type);

          const fileNameWithPreviousUrl = getFileNameByUrl(
            newsUrlUpdate.current,
          );

          if (fileNameWithPreviousUrl) {
            uploadNewsStorage(
              newsTypeUpdate.current,
              fileNameWithPreviousUrl,
              previousFileList.current![0],
            );
          }
          previousFileList.current = null;
        }
      })();

      mutationErrorHandling(
        error,
        'Falha ao atualizar notícia',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage(`Já foi cadastrado uma notícia com este nome`);
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteNewsMutate } = useMutation({
    mutationFn: async () => {
      if (newsIdUpdate.current) {
        await deleteNews(newsIdUpdate.current);
        return;
      }

      setErrorMessage('Falha ao deletar notícia');
    },

    onSuccess: () => {
      if (newsIdUpdate.current) {
        removeItemFromScreen<NewsListPaginated>(
          newsCache,
          newsIdUpdate.current,
        );
      }
      setIsNewsDeleteLoading(false);
      handleCloseMenageModal();
    },

    onError: (error) => {
      (async () => {
        const fileName = getFileNameByUrl(newsUrlUpdate.current);

        if (fileName) {
          uploadNewsStorage(
            newsTypeUpdate.current,
            fileName,
            previousFileList.current![0],
          );
        }
        setIsNewsDeleteLoading(false);
        previousFileList.current = null;
      })();

      mutationErrorHandling(
        error,
        'Falha ao deletar notícia',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.statusCode === 409
          ) {
            setErrorMessage(
              'Esta notícia só pode ser apagada depois que não houver relações com outras tabelas',
            );
            return true;
          }
        },
      );
    },
  });

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    watch,
    setError,
    reset,
    setFocus,
    setValue,
  } = useForm<NewsFormData>({
    resolver: zodResolver(uploadNewsSchema),
    defaultValues: {
      reference: '',
      fileType: '',
      date: '',
    },
  });
  const date = watch('date');
  const files = watch('news');
  const pdfBook = watch('pdfBook');
  const referenceValue = watch('reference');

  const [pages, setPages] = useState<string[]>([]);

  const loadPdf = async (file: File) => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const pdfData = new Uint8Array(fileReader.result as ArrayBuffer);
      const pdfDoc = await pdfjs.getDocument(pdfData).promise;
      const numPages = pdfDoc.numPages;
      const pages = [];
      for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
        const page = await pdfDoc.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 1 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (context) {
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          await page.render({ canvasContext: context, viewport }).promise;
          pages.push(canvas.toDataURL());
        } else {
          console.error(`Failed to get canvas context for page ${pageNumber}`);
        }
      }
      setPages(pages);
    };
    fileReader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (files && files[0] && files[0].type === 'application/pdf') {
      loadPdf(files[0]); // Carregar o PDF selecionado
    }
  }, [files]);

  useEffect(() => {
    if (files?.length > 0) {
      setError('reference', {});
    }

    if (files?.length > 1) {
      setPreviewNews('');
      setIsValidUrl(true);
      setValue('reference', '');
      return;
    }

    if (files && files[0]) {
      if (
        files[0].type === 'application/pdf' ||
        files[0].type === 'video/mp4' ||
        files[0].type === 'image/svg+xml'
      ) {
        const previewUrl = URL.createObjectURL(files[0]);
        setPreviewNews(previewUrl);
        setIsValidUrl(true);
        setValue('reference', files[0].name);
        setValue('fileType', files[0].type);
        return;
      }

      if (files[0]) {
        const file = files[0];

        if (
          file.type === 'application/pdf' ||
          file.type === 'video/mp4' ||
          file.type === 'image/svg+xml'
        ) {
          const previewUrl = URL.createObjectURL(file);
          setValue('reference', file.name);
          setPreviewNews(previewUrl);
        } else {
          setError('news', {
            message: 'Somente arquivos PDF ou vídeos em MP4 são permitidos.',
          });
        }
      }
    }
  }, [files, setValue, setError]);

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: closeMenageModal,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: handleCloseConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  const { data: newsTypes } = useQuery<NewsTypePaginated>(
    newsTypesCache,
    async () => (await getNewsTypesPaginated()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleCancelIconClick = (field: keyof NewsFormData) => {
    setValue(field, '', { shouldDirty: true });
    setFocus(field);
  };

  const handleCloseMenageModal = () => {
    newsIdUpdate.current = null;
    newsNameUpdate.current = null;
    newsTypeUpdate.current = '';
    newsUrlUpdate.current = '';
    fileTypeUpdate.current = '';
    previousFileList.current = null;
    reset();
    handleUnedit();
    setPreviewNews('');
    closeMenageModal();
  };

  const handleAddNewsClick = () => {
    handleOpenMenageModal();
    setValue('pdfBook', 'pdf_normal');
  };

  const handleTableRowClick = async (
    newsId: number,
    newsName: string,
    newsType: string,
    newsUrl: string,
    newsFileType: string,
    date: string | undefined,
    pdfBook: string,
  ) => {
    newsIdUpdate.current = newsId;
    newsNameUpdate.current = newsName;
    newsTypeUpdate.current = newsType;
    newsUrlUpdate.current = newsUrl;
    fileTypeUpdate.current = newsFileType;
    newsPdfBookUpdate.current = pdfBook;
    setPreviewNews(newsUrl);
    handleOpenMenageModal();

    // Erro abaixo
    setValue('reference', newsName);
    setValue('type', newsType);
    setValue('fileType', newsFileType);
    if (date) {
      setValue('date', date, { shouldDirty: true });
    }
    setValue('pdfBook', pdfBook);
    checkIfTheUrlIsValid(newsUrl);

    const fileList = await urlToFileList(newsUrl, newsName, newsFileType);

    if (fileList) {
      setValue('news', fileList);
      return;
    }
    setErrorMessage(
      'Houve um problema, a refêrencia do arquivo não foi encontrada',
    );
  };

  const checkIfTheUrlIsValid = (newsUrl: string) => {
    fetch(newsUrl, { method: 'HEAD' })
      .then((response) => {
        setIsValidUrl(response.ok);
      })
      .catch(() => {
        setIsValidUrl(false);
      });
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmDeleteModal();
  };

  const clearFields = () => {
    setPreviewNews('');
    reset();
  };

  const handleReferenceError = () => {
    setErrorMessage(`O campo referência não está preenchido corretamente.`);
    setIsNewsLoading(false);
  };

  const addNews = async (
    type: string,
    reference: string | undefined,
    news: FileList,
    date: Date | null,
    pdfBook: boolean,
  ) => {
    const newsListDto: SaveNewsListDto[] = [];

    for (let i = 0; i < news.length; i++) {
      const n = news[i];
      const newsName = news.length === 1 && reference ? reference : n.name;
      const fileType = n.type;

      newsListDto.push({
        name: newsName,
        type,
        url: getImageFromApi(newsName, `news/${type}`, false),
        fileType: fileType,
        date,
        pdfBook,
      });
    }

    try {
      await uploadNewsListStorage(
        type,
        news,
        async () => {
          if (newsListDto.length > 1) {
            await saveNewsList(newsListDto);
            return;
          }

          const newsDto = newsListDto[0];
          const { data } = await saveNews({
            name: newsDto.name,
            type: newsDto.type,
            url: newsDto.url,
            fileType: newsDto.fileType,
            date,
            pdfBook,
          });

          addItemOnScreen<NewsListPaginated>(newsCache, {
            newsType: data.newsType,
            id: data.id,
            name: data.name,
            url: data.url,
            order: data.order,
            fileType: data.fileType,
            pdfBook,
          });

          // handleFilterByTypeAfterAction(data.newsType.name);
          handleFilterByDomainTodos();
        },
        news.length === 1 ? newsListDto[0].name : null,
      );

      clearFields();
      handleCloseMenageModal();
    } catch (error) {
      mutationErrorHandling(
        error,
        'Erro ao fazer upload das notícias',
        setErrorMessage,
      );
    } finally {
      setIsNewsLoading(false);
      return;
    }
  };

  const handleButtonOkClick = async ({
    reference,
    type,
    news,
    date,
    pdfBook,
  }: NewsFormData) => {
    const validDate = date ? new Date(date) : null;
    // if (isNaN(validDate.getTime())) {
    //   setError('date', {
    //     message: 'Data inválida.',
    //   });
    //   return;
    // }

    setIsNewsLoading(true);
    if (
      newsIdUpdate.current &&
      newsTypeUpdate.current &&
      newsNameUpdate.current &&
      fileTypeUpdate.current
    ) {
      if (!reference) {
        handleReferenceError();
        return;
      }

      const fileName = getFileNameByUrl(newsUrlUpdate.current);
      if (fileName) {
        previousFileList.current = await urlToFileList(
          newsUrlUpdate.current,
          newsNameUpdate.current,
          fileTypeUpdate.current,
        );

        const error = await deleteNewsStorage(fileName, newsTypeUpdate.current);

        if (!error || !previousFileList.current) {
          const upload = uploadNewsStorage(type, reference, news[0]);
          upload.on(
            'state_changed',
            null,
            async (error) => {
              setErrorMessage(
                `Erro ao fazer upload da notícia. ${error.message}`,
              );
              if (previousFileList.current) {
                uploadNewsStorage(
                  newsTypeUpdate.current,
                  fileName,
                  previousFileList.current[0],
                );
              }
              setIsNewsLoading(false);
            },
            () => {
              updateNewsMutate({
                id: newsIdUpdate.current!,
                name: reference,
                type,
                url: getImageFromApi(reference, `news/${type}`, false),
                fileType: news[0].type,
                date: validDate,
                pdfBook: pdfBook === 'pdf_book',
              });
              setIsNewsLoading(false);
            },
          );

          return;
        }

        setErrorMessage(error);
        setIsNewsLoading(false);
      }
      return;
    }

    const extension = files[0].type?.includes('video/mp4')
      ? '.mp4'
      : files[0].type?.includes('application/pdf')
        ? '.pdf'
        : '.svg';
    const newsTreated = validateName(reference, extension);

    addNews(type, newsTreated, news, validDate, pdfBook === 'pdf_book');
  };

  function validateName(name: string | undefined, extension: string) {
    // const nameWithoutExtension = name?.replace(/\.[^/.]+$/, '');
    const nameWithoutExtension = name?.split('.')[0];
    return `${nameWithoutExtension}${extension}`;
  }

  const handleConfirmDeleteNews = async () => {
    setIsNewsDeleteLoading(true);
    handleCloseConfirmDeleteModal();
    const fileName = getFileNameByUrl(newsUrlUpdate.current);
    if (newsNameUpdate.current && fileName) {
      previousFileList.current = await urlToFileList(
        newsUrlUpdate.current,
        newsNameUpdate.current,
      );

      const error = await deleteNewsStorage(fileName, newsTypeUpdate.current);

      /**
       * Mesmo com erro do firebase, vai deletar da base de dados relacional */
      deleteNewsMutate();

      if (error) {
        if (error.includes(`"code":"storage/object-not-found"`)) {
          setIsNewsDeleteLoading(false);
          setErrorMessage(
            'Não foi encontrada a referência da imagem no Firebase, porém ela foi excluída da base de dados relacional',
          );
        } else {
          setErrorMessage(error);
          setIsNewsDeleteLoading(false);
        }
      }

      handleCloseConfirmDeleteModal();
    }
  };

  const isShowEditButton = !!newsIdUpdate.current && !canEdit;

  const currentParams = new URLSearchParams(window.location.search);

  // const handleFilterByTypeAfterAction = (filterName: string) => {
  //   currentParams.set('t', filterName);
  //   navigate({ search: currentParams.toString() });
  // };

  const handleFilterByDomainTodos = () => {
    currentParams.set('d', 'Todos');
    navigate({ search: currentParams.toString() });
  };

  const handleSelectDate = (selectedDate: Date | undefined) => {
    if (!selectedDate) return;

    setValue('date', selectedDate.toString(), { shouldDirty: true });
    setError('date', {});
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('date', event.target.value, { shouldDirty: true });
  };

  return {
    isMenageModalOpen,
    newsNameUpdate: newsNameUpdate.current,
    isConfirmDeleteModalOpen,
    newsTypes,
    screenHeight,
    previewNews,
    errors,
    dirtyFields,
    errorMessage,
    isNewsLoading,
    isNewsDeleteLoading,
    isShowEditButton,
    files: Array.from(files ?? []),
    newsIdUpdate: newsIdUpdate.current,
    date,
    pages,
    pdfBook,
    isValidUrl,
    referenceValue,
    newsPdfBookUpdate,
    canEdit,
    linkPdf,
    handleInputChange,
    handleSelectDate,
    setIsValidUrl,
    handleEdit,
    handleConfirmDeleteNews,
    handleCloseConfirmDeleteModal,
    clearError,
    handleCancelIconClick,
    register,
    handleSubmit,
    handleDeleteButtonClick,
    handleButtonOkClick,
    handleTableRowClick,
    handleAddNewsClick,
    handleCloseMenageModal,
  };
};
