import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { common, grey } from '@mui/material/colors';

export const partsGroupContainerStyle: SxProps<Theme> = {
  width: '18rem',
  '@media (max-width: 1400px)': {
    width: '15rem',
  },

  '@media (max-width: 1250px)': {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    padding: '0 0.5rem',
    maxHeight: '4rem',
    backgroundColor: grey['200'],
    overflow: 'hidden',
  },
};

export const selectPartsGroupStyle: SxProps<Theme> = {
  height: '3rem',
  margin: '1rem 0',
  backgroundColor: common['white'],
  fontSize: '0.8rem',
  flex: 1,
};

// export const partsGroupListStyle: SxProps<Theme> = {
//   display: 'flex',
//   flexDirection: 'column',
//   gap: '0.25rem',
//   overflow: 'auto',
//   height: 'calc(100vh - 14rem)',

//   '@media (min-width: 1250px)': {
//     pt: '3rem',
//   },

//   '@media (max-width: 1250px)': {
//     flex: 1,
//   },
// };

export const partsGroupItemStyle: SxProps<Theme> = {
  padding: '0.25rem 1rem',
  border: '1px solid #cdcdcd',
  borderRadius: '10px',
  justifyContent: 'center',
  cursor: 'pointer',
  textAlign: 'center',
};
