import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import {
  searchCache,
} from '../../../constants/requestCacheName';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { Description } from '../../../types/description';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { FindAllSubjects, Subject } from '../../../types/subject';
import { saveSubject, updateSubject, deleteSubject } from '../../../services/subject';

type MutationProps = {
  id: number;
  name: string;
};

export const useSubjectFetch = (
  baseUrl: string,
  currentSubjectId: number | null,
  handleCloseMenageModal: () => void,
  handleCloseModal: () => void,
  handleCloseConfirmModal: () => void,
) => {
  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: save } = useMutation({
    mutationFn: async ({ name }: Omit<MutationProps, 'id'>) => {
      return (await saveSubject(name)).data;
    },

    onSuccess: ({ id, name }: Subject) => {
      if (id && name ) {
        const data = {
          id,
          name,
        };
        addItemOnScreen<FindAllSubjects>(`${searchCache}${baseUrl}`, data);
      }
      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar assunto',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Assunto já cadastrada');
            return true;
          }
        },
      );
    },
  });

  const { mutate: update } = useMutation({
    mutationFn: async ({ id, name }: MutationProps) => {
      return (await updateSubject(id, name)).data;
    },

    onSuccess: ({ id, name }: Description) => {
      if (id && name) {
        const data = {
          id,
          name,
        };
        updateItemOnScreen<FindAllSubjects>(`${searchCache}${baseUrl}`, data);
      }
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar assunto',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage(`Já foi cadastrado um assunto com este nome`);
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteSubjectMutate } = useMutation({
    mutationFn: async () => {
      if (currentSubjectId) {
        return (await deleteSubject(currentSubjectId)).data;
      }

      setErrorMessage('Falha ao deletar assunto');
    },

    onSuccess: () => {
      if (currentSubjectId) {
        removeItemFromScreen<FindAllSubjects>(
          `${searchCache}${baseUrl}`,
          currentSubjectId,
        );
      }
      handleCloseMenageModal();
      handleCloseConfirmModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar assunto',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.statusCode === 409
          ) {
            setErrorMessage(
              'Este assunto só pode ser apagado depois que não houver conversas de produtos e/ou peças associadas a ele',
            );
            return true;
          }
        },
      );
    },
  });

  return {
    errorMessage,
    save,
    update,
    clearError,
    deleteSubjectMutate,
  };
};
