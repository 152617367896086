import { imageTypesText } from '../../constants/imageTypes';
import { ImageTypes } from '../../enums/imageTypes';
import { ImageType } from '../../types/imageTypes';
import Filter from '../Filter';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import Loading from '../Loading';
import Main from '../Main';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { useImageTypesContent } from './useImageTypesContent';

type ImageTypesContentProps = {
  isModal?: boolean;
  onTableRowClick: (imageType: ImageType) => void;
  onAddImageTypeClick?: () => void;
};

export default function ImageTypesContent({
  isModal,
  onTableRowClick,
  onAddImageTypeClick,
}: ImageTypesContentProps) {
  const {
    imageTypes,
    imageTypesLength,
    imageTypesShown,
    imageTypesShownLength,
    inputRef,
    isFetching,
    isSearchInputDirty,
    sortField,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
  } = useImageTypesContent();

  return (
    <>
      {!isModal && (
        <ListingHeader
          add
          onAddIconClick={onAddImageTypeClick}
          pdfTop="3rem"
          pdf={false}
        >
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">Tipo de imagem</h1>
            <SearchInput
              ref={inputRef}
              onSearch={handleSearch}
              // onButtonClick={handleCancelSearch}
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
            />
          </div>

          <Table.Head>
            <Table.Cell
              items={imageTypesShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
            >
              Tipo de imagem
            </Table.Cell>
          </Table.Head>
        </ListingHeader>
      )}

      <Main>
        {isFetching && <Loading />}
        {!isFetching && imageTypes && (
          <Table.Root
            itemType="tipos de imagens"
            itemsLength={imageTypesLength}
            className={`${isModal ? 'max-h-[60vh]' : ''}`}
            paddingTop="0"
          >
            {imageTypesShown.map((imageType) => {
              return (
                <Table.Row
                  key={imageType.id}
                  onClick={() => onTableRowClick(imageType)}
                >
                  <Table.Cell items={imageTypesShown} type="name">
                    <span>
                      {imageTypesText[imageType.name as ImageTypes] ??
                        imageType.name}
                    </span>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Root>
        )}
      </Main>

      <Footer
        className={`${isModal ? 'mb-1 ml-[5vw] w-[90vw]' : ''}`}
        current={imageTypesShownLength}
        total={imageTypesLength}
        itemType="Tipos de imagens"
      />
    </>
  );
}
