import { ComponentProps, ReactNode } from 'react';
import { cn } from '../../libs/utils';
import { useScreenHeight } from '../../hooks/useHeight';

type PreviewLabelProps = ComponentProps<'label'> & {
  children: ReactNode;
};

export default function PreviewLabel({
  children,
  className,
  ...rest
}: PreviewLabelProps) {
  const { screenHeight } = useScreenHeight();

  return (
    <label
      data-2xsmHeight={screenHeight < 719}
      data-xsmHeight={screenHeight < 790}
      data-smHeight={screenHeight < 825}
      data-2xllgHeight={screenHeight < 875}
      data-3xllgHeight={screenHeight < 950}
      data-lgHeight={screenHeight < 1080}
      data-7xsmHeight={screenHeight > 1080}
      className={cn(
        'flex h-[400px] w-full cursor-pointer justify-center rounded-lg',
        'data-[preview=false]:items-center data-[preview=false]:bg-backgroundPrimary',
        'data-[2xsmHeight=true]:h-[200px] data-[xsmHeight=true]:!h-[261px]',
        'data-[smHeight=true]:!h-[350px]  data-[2xllgHeight=true]:h-[370px]',
        'data-[3xllgHeight=true]:h-[420px] data-[lgHeight=true]:h-[450px]',
        'data-[7xsmHeight=true]:h-[500px] data-[8xsmHeight=true]:h-[680px]',
        className,
      )}
      {...rest}
    >
      {children}
    </label>
  );
}
