import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';
import s from './styles.module.css';

type MenuBurgerProps = ComponentProps<'div'> & {
  isOpen: boolean;
  isDark?: boolean;
};

export default function MenuBurger({
  isOpen,
  className,
  isDark = false,
  ...rest
}: MenuBurgerProps) {
  return (
    <div
      className={twMerge(
        'fixed z-50 w-6 cursor-pointer',
        isOpen && s.menu,
        className,
      )}
      {...rest}
    >
      <span
        data-dark={isDark}
        className="transition-medium mb-1 block h-1 w-full rounded bg-backgroundMedium data-[dark=true]:bg-zinc-800"
      />
      <span
        data-dark={isDark}
        className="transition-medium mb-1 block h-1 w-full rounded bg-backgroundMedium data-[dark=true]:bg-zinc-800"
      />
      <span
        data-dark={isDark}
        className="transition-medium block h-1 w-full rounded bg-backgroundMedium data-[dark=true]:bg-zinc-800"
      />
    </div>
  );
}
