import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

type MenuMobileProps = {
  locationPathname: string;
  isCatalogoPecasBuffalo: boolean;
  isDetalhes: boolean;
  isCompatibilidade: boolean;
  closeMenu: () => void;
};

export default function MenuMobile({
  locationPathname,
  isCatalogoPecasBuffalo,
  isDetalhes,
  isCompatibilidade,
  closeMenu,
}: MenuMobileProps) {
  return (
    <div
      className={`z-[999] flex w-screen flex-col items-center border-b border-[#dadada] bg-[#F5F5F6]/90 backdrop-blur-sm`}
    >
      <div className="absolute right-5 top-2 md:top-3">
        <CloseIcon
          fontSize="large"
          className="hover:text-[#eab308]"
          onClick={closeMenu}
        />
      </div>
      <nav className="box-border xl:ml-24 xl:pr-5">
        <ul className="box-border flex flex-col items-center justify-between space-y-4 py-4 md:flex-row md:space-y-0">
          <li className="box-border">
            <Link
              to="/sobre"
              className={`${
                locationPathname === '/sobre'
                  ? 'link-header link-header-select'
                  : 'link-header link-header-not-select'
              }`}
            >
              Sobre
            </Link>
          </li>
          <li className="box-border">
            <Link
              to="/produtos"
              className={`${
                locationPathname === '/produtos' ||
                isCatalogoPecasBuffalo ||
                isDetalhes ||
                isCompatibilidade
                  ? 'link-header link-header-select'
                  : 'link-header link-header-not-select'
              }`}
            >
              Produtos
            </Link>
          </li>
          <li className="box-border">
            <Link
              to="/buffaloNews"
              className={`${
                locationPathname === '/buffaloNews'
                  ? 'link-header link-header-select'
                  : 'link-header link-header-not-select'
              }`}
            >
              Buffalo News
            </Link>
          </li>
          <li className="box-border">
            <Link
              to="/assistencia"
              className={`${
                locationPathname === '/assistencia'
                  ? 'link-header link-header-select'
                  : 'link-header link-header-not-select'
              }`}
            >
              Assistência
            </Link>
          </li>
          <li className="box-border">
            <Link
              to="/representantes"
              className={`${
                locationPathname === '/representantes'
                  ? 'link-header link-header-select'
                  : 'link-header link-header-not-select'
              }`}
            >
              Representantes
            </Link>
          </li>
          <li className="box-border">
            <Link
              to="/contato"
              className={`${
                locationPathname === '/contato'
                  ? 'link-header link-header-select'
                  : 'link-header link-header-not-select'
              }`}
            >
              Fale Conosco
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
