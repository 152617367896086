import { useQuery } from 'react-query';
import { partsGroupsCoordinateCache } from '../../constants/requestCacheName';
import { getAllCoordinatesByProductIdPartsGroupIdPartId } from '../../services/partsGroupItemCoordinate';
import {
  CoordinatesModified,
  CoordinateToDelete,
  FindAllCoordinates,
} from '../../types/partsGroupCoordinate';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { verifyScreenIsLessThan } from '../../utils/verifyScreenIsLessThan';
import { usePartsCatalogStore } from '../../store/partsCatalog';
import { Stage } from 'konva/lib/Stage';
import { useEdit } from '../../hooks/useEdit';
import { useParams } from 'react-router-dom';
import { KonvaEventObject } from 'konva/lib/Node';
import { useQueryCache } from '../../hooks/useQueryCache';

type MouseCoordinates = Record<'x' | 'y', number>;

type Rectangle = CoordinatesModified & {
  isNew: boolean;
};

export const useExplodedView = (
  canEdit: boolean,
  onPartMouseEnter: (partId: number) => void,
  onPartMouseOut: () => void,
  imageName: string,
) => {
  const { productId } = useParams();

  const {
    state: {
      currentPartIdHover,
      currentPartsGroup,
      currentProductId,
      currentPartClicked,
      coordinatesModified,
      coordinatesToDelete,
    },
    actions: { addCoordinateDelete, setCoordinateModified },
  } = usePartsCatalogStore();

  const [isExplodedViewImageError, setIsExplodedViewImageError] =
    useState(false);

  const handleExplodedViewImageError = () => {
    setIsExplodedViewImageError(true);
  };

  const stageRef = useRef<Stage>(null);
  const isPainting = useRef<boolean>();
  const currentShapeId = useRef('');
  const coordinateUpdated = useRef<Rectangle | null>(null);
  const isCoordinateInList = useRef(false);
  const coordinatesWhenPointerDown = useRef({ x: 0, y: 0 });
  const {
    canEdit: canAddCoordinate,
    handleEdit: handleAddCoordinate,
    handleUnedit: handleNotAddCoordinate,
  } = useEdit();

  const { data: partsGroupImagesCoordinates, refetch: refetchCoordinates } =
    useQuery<FindAllCoordinates>(
      `${partsGroupsCoordinateCache}${currentPartsGroup?.id}`,
      async () => {
        return (
          await getAllCoordinatesByProductIdPartsGroupIdPartId({
            productId: currentProductId!,
            partsGroupId: currentPartsGroup!.id,
          })
        ).data;
      },
      {
        retry: false,
        enabled: false,
      },
    );

  const [rectangles, setRectangles] = useState<Rectangle[]>([]);
  const [currentRectangleIdHover, setCurrentRectangleIdHover] = useState('');
  const [currentCoordinateIdToDelete, setCurrentCoordinateIdToDelete] =
    useState('');
  const [currentMouseCoordinates, setCurrentMouseCoordinates] =
    useState<MouseCoordinates | null>(null);

  useEffect(() => {
    if (currentPartsGroup) {
      refetchCoordinates();
    }
  }, [currentPartsGroup, refetchCoordinates]);

  useEffect(() => {
    setIsExplodedViewImageError(false);
  }, [imageName]);

  const { overrideCache } = useQueryCache();

  const compareModifiedDelete = useCallback(
    (coordinate: CoordinatesModified, coordinateDelete: CoordinateToDelete) => {
      return (
        coordinateDelete.partGroupId === coordinate.partGroupId &&
        coordinateDelete.partId === coordinate.partId &&
        coordinateDelete.x === coordinate.x &&
        coordinateDelete.y === coordinate.y
      );
    },
    [],
  );

  const converterCoordinatesToRectangles = useCallback(() => {
    const rectanglesList: Rectangle[] =
      partsGroupImagesCoordinates?.items?.map((coordinate) => ({
        id: `${coordinate.part.id}${coordinate.x}${coordinate.y}`,
        partId: coordinate.part.id,
        partGroupId: coordinate.partsGroup.id,
        height: coordinate.height,
        width: coordinate.width,
        x: coordinate.x,
        y: coordinate.y,
        isNew: false,
      })) ?? [];
    return rectanglesList;
  }, [partsGroupImagesCoordinates?.items]);

  useEffect(() => {
    if (partsGroupImagesCoordinates) {
      const rectanglesList: Rectangle[] = converterCoordinatesToRectangles();

      if (currentPartClicked?.part.id) {
        const rectanglesFiltered = rectanglesList.filter(
          (coordinate) =>
            coordinate.partId === currentPartClicked?.part.id &&
            !coordinatesToDelete.some((coordinateDelete) =>
              compareModifiedDelete(coordinate, coordinateDelete),
            ),
        );

        const updatedRectanglesFiltered: Rectangle[] = coordinatesModified
          .filter(
            (coordinateModified) =>
              !coordinatesToDelete.some((coordinateDelete) =>
                compareModifiedDelete(coordinateModified, coordinateDelete),
              ) && coordinateModified.partId === currentPartClicked?.part.id,
          )
          .map((coordinateModified) => ({
            ...coordinateModified,
            isNew: true,
          }));

        const rectanglesUpdated = rectanglesFiltered.filter((rectangle) => {
          return !updatedRectanglesFiltered.some((updatedRectangles) => {
            return (
              updatedRectangles.partGroupId === rectangle.partGroupId &&
              updatedRectangles.partId === rectangle.partId &&
              updatedRectangles.previousX === rectangle.x &&
              updatedRectangles.previousY === rectangle.y
            );
          });
        });

        setRectangles([...rectanglesUpdated, ...updatedRectanglesFiltered]);
        return;
      }

      setRectangles(rectanglesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    compareModifiedDelete,
    currentPartClicked?.part.id,
    partsGroupImagesCoordinates,
  ]);

  useEffect(() => {
    if (!canAddCoordinate) {
      setCurrentMouseCoordinates(null);
    }
  }, [canAddCoordinate]);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const isResponsive = verifyScreenIsLessThan(900);
  const isMobile = verifyScreenIsLessThan(640);

  const explodedViewReduction = isMobile ? 0.5 : 1;

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handlePointerDown = () => {
    const stage = stageRef.current;
    if (stage && canAddCoordinate) {
      const { x, y } = stage.getPointerPosition()!;
      coordinatesWhenPointerDown.current = { x, y };

      const id = Date.now().toString();
      currentShapeId.current = id;
      isPainting.current = true;

      if (currentPartClicked && currentPartsGroup) {
        const rectangle: Rectangle = {
          id,
          x: Math.round(x),
          y: Math.round(y),
          width: 0,
          height: 0,
          partId: currentPartClicked.part.id,
          partGroupId: currentPartsGroup.id,
          isNew: true,
        };

        setRectangles((prev) => [...prev, rectangle]);
        setCoordinateModified([...coordinatesModified, rectangle]);
      }
    }
  };
  const handlePointerMove = () => {
    if (!isPainting.current && canAddCoordinate) {
      const stage = stageRef.current;
      if (stage) {
        const { x, y } = stage.getPointerPosition()!;
        setCurrentMouseCoordinates({ x: Math.round(x), y: Math.round(y) });
      }
    }

    if (isPainting.current && canAddCoordinate) {
      const stage = stageRef.current;
      if (stage) {
        const { x, y } = stage.getPointerPosition()!;

        setRectangles((prev) =>
          prev.map((rectangle) => {
            if (rectangle.id === currentShapeId.current) {
              const width = x - coordinatesWhenPointerDown.current.x;
              const height = y - coordinatesWhenPointerDown.current.y;
              const updatedRectangle: Rectangle = {
                ...rectangle,
                x: Math.round(
                  width < 0 ? x : coordinatesWhenPointerDown.current.x,
                ),
                y: Math.round(
                  height < 0 ? y : coordinatesWhenPointerDown.current.y,
                ),
                width: Math.round(width < 0 ? width * -1 : width),
                height: Math.round(height < 0 ? height * -1 : height),
              };

              coordinatesModified.forEach((rectangleModified, index) => {
                if (rectangleModified.id === currentShapeId.current) {
                  const { isNew, ...rest } = updatedRectangle;
                  coordinatesModified[index] = rest;
                }
              });

              return updatedRectangle;
            }

            return rectangle;
          }),
        );
      }
    }
  };

  const handlePointerUp = () => {
    isPainting.current = false;
    coordinatesWhenPointerDown.current = { x: 0, y: 0 };
    handleNotAddCoordinate();
  };

  const handleClickAddCoordinate = () => {
    handleAddCoordinate();
  };

  const validateInput = (name: string, value: string) => {
    const isInt = +value >= 0;
    let isLessMaxValue = false;

    if (['y', 'height'].includes(name) && +value <= 300) {
      isLessMaxValue = true;
    }

    if (['x', 'width'].includes(name) && +value <= 600) {
      isLessMaxValue = true;
    }

    const isValid = isInt && isLessMaxValue;

    return isValid;
  };

  const handleChangeCoordinateInput = (
    event: ChangeEvent<HTMLInputElement>,
    coordinateId: string,
  ) => {
    if (coordinateUpdated.current?.id !== coordinateId) {
      coordinateUpdated.current = null;
    }

    const [name, value] = [event.target.name, event.target.value];

    setRectangles((prev) =>
      prev.map((coordinate) => {
        if (coordinate.id === coordinateId && validateInput(name, value)) {
          coordinateUpdated.current = {
            ...coordinate,
            previousX: coordinateUpdated.current?.previousX ?? coordinate.x,
            previousY: coordinateUpdated.current?.previousY ?? coordinate.y,
          };

          return {
            ...coordinate,
            [name]: +value,
          };
        }

        return coordinate;
      }),
    );

    const coordinatesModifiedLength = coordinatesModified.length;

    partsGroupImagesCoordinates?.items.forEach(({ part, x, y }) => {
      if (
        coordinateUpdated.current?.partId === part.id &&
        coordinateUpdated.current.previousX === x &&
        coordinateUpdated.current.previousY === y
      ) {
        if (!coordinateUpdated.current.isNew && ['x', 'y'].includes(name)) {
          coordinateUpdated.current.previousX = x;
          coordinateUpdated.current.previousY = y;
        }

        coordinateUpdated.current = {
          ...coordinateUpdated.current,
          [name]: +value,
        };

        if (coordinatesModifiedLength <= 0) {
          setCoordinateModified([coordinateUpdated.current]);
        }
      }
    });

    if (coordinatesModifiedLength > 0) {
      coordinatesModified.forEach((coordinateModified, index) => {
        if (
          coordinateModified.id === coordinateId &&
          coordinateUpdated.current
        ) {
          isCoordinateInList.current = true;
          coordinatesModified[index] = coordinateUpdated.current;
        }
      });

      if (!isCoordinateInList.current && coordinateUpdated.current) {
        coordinatesModified.push(coordinateUpdated.current);
      }

      isCoordinateInList.current = false;
    }
  };

  const handleInputMouseEnter = (rectangleId: string) => {
    setCurrentRectangleIdHover(rectangleId);
  };

  const handleRemoveCoordinate = (coordinateId: string) => {
    if (currentCoordinateIdToDelete === coordinateId) {
      setCurrentCoordinateIdToDelete('');
      return;
    }

    setCurrentCoordinateIdToDelete(coordinateId);
  };

  const handleDeleteCoordinate = (coordinateId: string) => {
    if (productId) {
      setRectangles((prev) =>
        prev.filter(({ id, partGroupId, partId, x, y, isNew }) => {
          if (id === coordinateId && !isNew) {
            const rectangleToDelete = {
              productId: +productId,
              partGroupId,
              partId,
              x,
              y,
            };
            addCoordinateDelete(rectangleToDelete);
          }

          return id !== coordinateId;
        }),
      );

      setCoordinateModified(
        coordinatesModified.filter(({ id }) => id !== coordinateId),
      );

      const filteredCoordinates = partsGroupImagesCoordinates?.items.filter(
        (coordinate) =>
          `${coordinate.part.id}${coordinate.x}${coordinate.y}` !==
          coordinateId,
      );

      // TODO Instável: Remove a coordenada deletada da vista explodida
      overrideCache<FindAllCoordinates>(
        `${partsGroupsCoordinateCache}${currentPartsGroup?.id}`,
        {
          items: filteredCoordinates ?? [],
          meta: {
            totalItems: partsGroupImagesCoordinates?.meta.totalItems ?? 0,
          },
        },
      );
      // Instável ^^^
    }
  };

  const handlePartMouseEnter = (
    event: KonvaEventObject<MouseEvent>,
    partId: number,
  ) => {
    onPartMouseEnter(partId);
    const container = event.target.getStage()?.container();
    if (container) {
      container.style.cursor = 'pointer';
      container.style.cursor = 'pointer';
    }
  };

  const handlePartMouseOut = (event: KonvaEventObject<MouseEvent>) => {
    onPartMouseOut();
    const container = event.target.getStage()?.container();
    if (container) {
      container.style.cursor = 'default';
    }
  };

  const rectanglesWithPartsHover = useMemo(() => {
    const coordinatesRectangles = converterCoordinatesToRectangles();
    let coordinatesFiltered = coordinatesRectangles.filter(
      (rectangle) =>
        !rectangles.some((coordinate) => coordinate.id === rectangle.id),
    );
    const updatedRectanglesFiltered: Rectangle[] = coordinatesModified.map(
      (coordinateModified) => ({
        ...coordinateModified,
        isNew: true,
      }),
    );

    if (canAddCoordinate) {
      return rectangles;
    }

    // Instável, em caso de bug, tentar descomentar o código abaixo

    // if (
    //   currentPartIdHover !== currentPartClicked?.part.id &&
    //   currentPartIdHover
    // ) {
    coordinatesFiltered = [
      ...coordinatesFiltered,
      ...updatedRectanglesFiltered,
    ];
    // }

    return [...rectangles, ...coordinatesFiltered];
  }, [
    converterCoordinatesToRectangles,
    coordinatesModified,
    canAddCoordinate,
    rectangles,
  ]);

  return {
    isOpenModal,
    isResponsive,
    explodedViewReduction,
    partsGroupImagesCoordinates,
    currentPartClicked,
    stageRef,
    rectanglesWithPartsHover,
    rectangles,
    canAddCoordinate,
    currentMouseCoordinates,
    currentRectangleIdHover,
    currentCoordinateIdToDelete,
    isExplodedViewImageError,
    handleExplodedViewImageError,
    handlePartMouseOut,
    handlePartMouseEnter,
    handleDeleteCoordinate,
    handleRemoveCoordinate,
    handleInputMouseEnter,
    handleChangeCoordinateInput,
    handleClickAddCoordinate,
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    handleOpenModal,
    handleCloseModal,
  };
};
