import { useQuery } from 'react-query';
import { FindAllLogsLaunchDuimp, LogLaunch } from '../../types/productDuimp';
import { logsLaunchDuimpCache } from '../../constants/requestCacheName';
import { getAllLogsLaunchDuimp } from '../../services/duimp';
import { useHandleTable } from '../../hooks/useHandleTable';
import { useNavigate } from 'react-router-dom';

export const useLogsDuimpContent = () => {
  const navigate = useNavigate();
  const {
    data: logsLaunchListDuimp,
    isFetching: isLogsLaunchListDuimpFetching,
  } = useQuery<FindAllLogsLaunchDuimp>(
    logsLaunchDuimpCache,
    async () => (await getAllLogsLaunchDuimp()).data,
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const isFetching = isLogsLaunchListDuimpFetching;

  const {
    itemsShown,
    itemsShownLength: logsLaunchListDuimpShownLength,
    itemsLength: logsLaunchListDuimpLength,
  } = useHandleTable(
    logsLaunchListDuimp?.length ?? 0,
    logsLaunchListDuimp ?? [],
  );
  const logsLaunchListDuimpShown: LogLaunch[] = itemsShown;

  return {
    navigate,
    isFetching,
    logsLaunchListDuimp,
    logsLaunchListDuimpShownLength,
    logsLaunchListDuimpLength,
    logsLaunchListDuimpShown,
  };
};
