import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type HeaderModalManageItemProps = {
  mainButtonContent: ReactNode;
  title: string;
  isEditable: boolean;
  deleteButtonContent?: ReactNode;
  deleteButtonClassName?: string;
  showDeleteButton?: boolean;
  onCancelClick: () => void;
  onOkClick?: () => void;
  onEditClick?: () => void;
  onDeleteButtonClick?: () => void;
};

export default function HeaderModalManageItem({
  mainButtonContent,
  isEditable,
  title,
  deleteButtonContent,
  deleteButtonClassName,
  showDeleteButton = false,
  onCancelClick,
  onOkClick,
  onEditClick,
  onDeleteButtonClick,
}: HeaderModalManageItemProps) {
  return (
    <div className="sticky top-0 z-10 bg-white">
      <div className="flex items-center justify-between pt-8">
        <button
          onClick={onCancelClick}
          className="ml-1 w-20 text-primary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-400 focus-visible:ring-offset-2"
        >
          Cancelar
        </button>
        <h1 className="text-2xl">{title}</h1>
        <button
          onClick={isEditable ? onOkClick : onEditClick}
          tabIndex={0}
          className="mr-1 w-fit text-right text-primary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-400 focus-visible:ring-offset-2"
        >
          {mainButtonContent}
        </button>
      </div>
      {showDeleteButton && (
        <div className="mt-8 w-full text-right">
          <button
            className={twMerge(
              'text-errorText disabled:opacity-50',
              'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-400 focus-visible:ring-offset-2',
              deleteButtonClassName,
            )}
            onClick={onDeleteButtonClick}
            disabled={!isEditable}
          >
            {deleteButtonContent}
          </button>
        </div>
      )}
    </div>
  );
}
