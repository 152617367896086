import { DescriptionFormData } from '../../../types/description';
import { useCallback, useRef, useState } from 'react';
import { useTitle } from '../../../hooks/useTitle';
import { useModal } from '../../../hooks/useModal';
import { useEdit } from '../../../hooks/useEdit';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { descriptionSchema } from '../../../validations/schemas';
import { useDescriptionFetch } from './useDescriptionFetch';

export const useDescription = () => {
  useTitle('Descrição');

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: handleClose,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmModalOpen,
    handleCloseModal: handleCloseConfirmModal,
    handleOpenModal: handleOpenConfirmModal,
  } = useModal();

  const {
    isModalOpen: isSelectModalOpen,
    handleCloseModal: handleCloseSelectModal,
    handleOpenModal: handleOpenSelectModal,
  } = useModal();

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
  } = useForm<DescriptionFormData>({
    resolver: zodResolver(descriptionSchema),
    defaultValues: {
      description: '',
      descriptionType: '',
    },
  });

  const currentDescriptionType = watch('descriptionType');
  const description = watch('description')

  const currentDescriptionId = useRef<number | null>(null);

  const [initialDescriptionTypeName, setinItialDescriptionTypeName] =
    useState('');

  const handleCloseMenageModal = useCallback(() => {
    currentDescriptionId.current = null;

    setValue('description', '', { shouldDirty: true });
    setValue('descriptionType', '', { shouldDirty: true });
    setError('description', {});
    setError('descriptionType', {});

    handleUnedit();
    handleClose();
  }, [handleClose, handleUnedit, setError, setValue]);

  const { save, update, deleteDescriptionMutate, clearError, errorMessage } =
    useDescriptionFetch(
      currentDescriptionId.current,
      handleCloseMenageModal,
      handleCloseMenageModal,
      handleCloseConfirmModal,
    );

  const handleTableRowClick = (
    descriptionId: number,
    descriptionName: string,
    descriptionTypeId: number,
    descriptionTypeName: string,
  ) => {
    currentDescriptionId.current = descriptionId;
    setValue('description', descriptionName);
    setValue('descriptionType', descriptionTypeName);
    setinItialDescriptionTypeName(descriptionTypeName);
    handleOpenMenageModal();
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmModal();
  };

  const handleConfirmDelete = () => {
    deleteDescriptionMutate();
  };

  const handleButtonOkClick = ({
    description,
    descriptionType,
  }: DescriptionFormData) => {
    if (currentDescriptionId.current) {
      update({
        id: currentDescriptionId.current,
        name: description,
        type: descriptionType,
      });
      // Deixar comentado para o modal continuar aberto ao clicar no ok de concluir edição
      // currentDescriptionId.current = null;
      // setValue('descriptionType', '');
      // setValue('description', '');
      handleUnedit()
      return;
    }
    save({ name: description, type: descriptionType });
  };

  const handleAddDescriptionClick = () => {
    setinItialDescriptionTypeName('');
    handleOpenMenageModal();
  };

  const handleClearDescription = () => {
    setValue('description', '', { shouldDirty: true });
  };

  const handleSelectTableRowClick = (
    descriptionTypeId: number,
    descriptionTypeName: string,
  ) => {
    setValue('descriptionType', descriptionTypeName);
    setError('descriptionType', {});
    handleCloseSelectModal();
  };

  const isShowEditButton = !!currentDescriptionId.current && !canEdit;

  return {
    isMenageModalOpen,
    currentDescriptionId: currentDescriptionId.current,
    isConfirmModalOpen,
    description,
    isShowEditButton,
    errors,
    dirtyFields,
    isSelectModalOpen,
    currentDescriptionType,
    initialDescriptionTypeName,
    errorMessage,
    clearError,
    handleSelectTableRowClick,
    handleCloseSelectModal,
    handleOpenSelectModal,
    handleClearDescription,
    handleAddDescriptionClick,
    handleConfirmDelete,
    handleCloseConfirmModal,
    handleEdit,
    handleButtonOkClick,
    register,
    handleSubmit,
    handleDeleteButtonClick,
    handleCloseMenageModal,
    handleTableRowClick,
  };
};
