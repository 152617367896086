import { PartsGroupSelected } from '../../../types/partsGroup';
import { useModal } from '../../../hooks/useModal';
import { useState } from 'react';
import { useTitle } from '../../../hooks/useTitle';

export const usePartsGroup = () => {
  useTitle('Conjunto de peças');

  // // Faz a requisição que será usada no modal
  // useQuery<ImagesResult>(
  //   imagesApiCache,
  //   async () => await getAllFolderImages(ImageTypes.explodedView),
  //   {
  //     retry: false,
  //     refetchOnWindowFocus: false,
  //   },
  // );

  const [selectedDescriptionId, setSelectedDescriptionId] = useState<
    number | null
  >(null);

  const [partGroupSelected, setPartGroupSelected] =
    useState<PartsGroupSelected | null>(null);

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const handleCloseMenageModal = () => {
    setSelectedDescriptionId(null);
    setPartGroupSelected(null);
    handleCloseModal();
  };

  const handleAddPartsGroupClick = () => {
    handleOpenMenageModal();
  };

  const handleTableRowClick = (partsGroupFormData: PartsGroupSelected) => {
    setSelectedDescriptionId(partsGroupFormData.descriptionId);
    setPartGroupSelected(partsGroupFormData);
    handleOpenMenageModal();
  };

  return {
    selectedDescriptionId: selectedDescriptionId ?? 0,
    isMenageModalOpen,
    partGroupSelected,
    handleCloseMenageModal,
    handleTableRowClick,
    handleAddPartsGroupClick,
  };
};
