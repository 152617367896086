import { useState, useEffect, useRef, ReactNode } from 'react';
import { useQuery } from 'react-query';
import { useError } from '../../../hooks/useError';
import { saveContact } from '../../../services/contactUs';
import { City } from '../../../types/city';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { api } from '../../../services/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { contactUsSchema } from '../../validations/schemas';
import { useForm } from 'react-hook-form';
import { ContactUsFormData } from '../../types/contactUs';
import { getCurrentDomain } from '../../../utils/domain';
import { FindAllSubjects, Subject } from '../../../types/subject';
import { subjectsCache } from '../../../constants/requestCacheName';
import { getAllSubjects } from '../../../services/subject';

type AlertContent = {
  message: string | null;
  icon: ReactNode | null;
};

const alertContentInitialValue: AlertContent = {
  icon: null,
  message: null,
};

const fetchStates = async () => {
  const url = `/statesUf/v1/find-all-ufs`;
  const response = await api.get(url);
  return response.data;
};

const fetchCitiesByState = async (stateId: number) => {
  const url = `/cities/v1/${stateId}`;
  const response = await api.get(url);
  return response.data;
};

type UseContactProps = {
  successIcon: ReactNode;
  errorIcon: ReactNode;
};

export const useContactUs = ({ errorIcon, successIcon }: UseContactProps) => {
  const selectedCity = useRef<City | null>(null);

  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    watch,
  } = useForm<ContactUsFormData>({
    resolver: zodResolver(contactUsSchema),
    defaultValues: {
      city: '',
      state: '',
      company: '',
      name: '',
      email: '',
      message: '',
      phone: '',
      cpf: '',
      subject: [],
    },
  });

  const [alertContent, setAlertContent] = useState<AlertContent>(
    alertContentInitialValue,
  );

  const { errorMessage, clearError, setErrorMessage } = useError();

  useEffect(() => {
    setAlertContent({
      icon: errorIcon,
      message: errorMessage,
    });
  }, [errorIcon, errorMessage]);

  const handleCloseAlert = () => {
    setAlertContent(alertContentInitialValue);
    clearError();
  };

  const selectedState = useRef<number | null>(null);

  const handleSendContact = handleSubmit(
    async (formValues: ContactUsFormData) => {
      try {
        await saveContact({
          ...formValues,
          city: selectedCity.current,
          domainId: String(getCurrentDomain().id),
          subjects: selectedSubjects,
        });
        setAlertContent({
          icon: successIcon,
          message: 'Formulário enviado com sucesso',
        });
        reset();
      } catch (error) {
        mutationErrorHandling(
          error,
          'Houve um erro ao enviar contato, tente novamente',
          setErrorMessage,
        );
      } finally {
        clearErrors();
      }
    },
  );

  const { data: states = [] } = useQuery(['states'], fetchStates, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const { data: cities, refetch: fetchCities } = useQuery(
    `cities${selectedState.current}`,
    async () => {
      if (selectedState.current) {
        const cidades = await fetchCitiesByState(selectedState.current);
        return cidades;
      }
    },
  );

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const stateId = e.target.value;
    selectedState.current = +stateId;

    if (stateId) {
      fetchCities();
    }
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const cityId = e.target.value;
    const city = cities.find(
      (city: { id: number }) => city.id === parseInt(cityId, 10),
    );
    selectedCity.current = city;
  };

  const { data: subjects } = useQuery<FindAllSubjects>(
    subjectsCache,
    async () => (await getAllSubjects()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const selectedSubjects: Subject[] = watch('subject') || [];

  const handleSelect = (subject: Subject) => {
    const updatedSubjects = selectedSubjects.some((s) => s.id === subject.id)
      ? selectedSubjects.filter((s) => s.id !== subject.id)
      : [...selectedSubjects, subject];

    setValue('subject', updatedSubjects, { shouldValidate: true });
  };

  return {
    alertContent,
    errors,
    subjects,
    selectedSubjects,
    selectedState,
    states,
    cities,
    handleSelect,
    setValue,
    handleSendContact,
    register,
    handleStateChange,
    handleCloseAlert,
    handleSubmit,
    handleCityChange,
  };
};
