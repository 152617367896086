import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { ItemSelected, LabelSizeTypes } from '../../types/productBarCode';
import html2canvas from 'html2canvas';
import { labelSizes } from '../../constants/barCode';

const generateBarCodeApplePdf = async (
  itemsSelected: ItemSelected[],
  size: LabelSizeTypes,
  startIndex: number,
  onError: (errorMessage: string) => void,
) => {
  // verifyOpenPdfPermission(onError);
  pdfMake.vfs = pdfFonts.vfs;
  const labelSize = labelSizes[size];

  const chunkArray = (array: Content[], size: number) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const totalSpacingYInBarCodeInPx = 10;

  const barcodeImages = await Promise.all(
    itemsSelected.map(async (itemSelected) => {
      const element = document.getElementById(itemSelected.id.toString())!;

      const canvas = await html2canvas(element);
      const imageData = canvas.toDataURL('image/png');

      return {
        image: imageData,
        width: labelSize.width * 2.3,
        height: labelSize.height + totalSpacingYInBarCodeInPx,
      } as Content;
    }),
  );

  const fontSize = labelSize.fontSize + labelSize.appleFontSizeToIncrease;

  const totalItemsSelectedInArray = itemsSelected.flatMap(
    (itemSelected, index) =>
      Array.from({ length: itemSelected.amount }, () => ({
        ...itemSelected,
        index,
      })),
  );

  const totalLabelsInArray = totalItemsSelectedInArray.map(
    (itemSelected, index) =>
      [
        {
          columns: [
            {
              text: `${itemSelected.id}-${itemSelected.name}`,
              fontSize,
              alignment: 'center',
              marginTop:
                index < labelSize.columns && startIndex <= 0
                  ? 1
                  : labelSize.gap + labelSize.appleGapToIncrease,
              marginLeft: labelSize.marginLeft,
              relativePosition: {
                y: ['g', 'p'].includes(size)
                  ? index < labelSize.columns && startIndex <= 0
                    ? size === 'g'
                      ? 2
                      : 0
                    : size === 'g'
                      ? 7
                      : 3
                  : 0,
                x: ['p', 'g'].includes(size) ? labelSize.marginLeft + 4 : 0,
              },
              width: labelSize.width / 1.1,
            },
          ],
        },
        {
          text: `fake text`,
          fontSize,
          alignment: 'center',
          marginTop:
            index < labelSize.columns && startIndex <= 0
              ? 1
              : labelSize.gap + labelSize.appleGapToIncrease,
          marginLeft: labelSize.marginLeft,
          opacity: 0,
        },
        {
          columns: [
            {
              text: `Com ${itemSelected.unitsPerPackage} ${itemSelected.unit}`,
              fontSize,
              alignment: 'center',
              // opacity:
              //   itemSelected.unitsPerPackage && itemSelected.unit ? 1 : 0,
            },
            {
              text: `${itemSelected.packagingCode ?? ''}  ${itemSelected.stockLocation ?? ''}`,
              fontSize,
              alignment: 'center',
            },
          ],
          opacity: 0,
          marginLeft: labelSize.marginLeft,
        },
        {
          ...(barcodeImages[itemSelected.index] as any),
          marginLeft: labelSize.marginLeft,
        },
        {
          columns: [
            {
              text: `Com ${itemSelected.unitsPerPackage} ${itemSelected.unit}`,
              fontSize,
              alignment: 'center',
              opacity:
                itemSelected.unitsPerPackage && itemSelected.unit ? 1 : 0,
            },
            {
              text: `${itemSelected.packagingCode ?? ''}  ${itemSelected.stockLocation ?? ''}`,
              fontSize,
              alignment: 'center',
            },
          ],
          marginLeft: labelSize.marginLeft,
          relativePosition: {
            y: -(labelSize.height + totalSpacingYInBarCodeInPx + 1),
            x: -5,
          },
        },
      ] as Content[],
  );

  for (let i = 0; i < startIndex; i++) {
    // Textos falsos, somente para ocupar o lugar dos invisíveis
    totalLabelsInArray.unshift([
      {
        text: `text`,
        fontSize,
        alignment: 'center',
        marginTop: i < labelSize.columns ? 1 : labelSize.gap,
        marginLeft: labelSize.marginLeft,
        opacity: 0,
      },
      {
        columns: [
          {
            text: `Com text`,
            fontSize,
            alignment: 'center',
          },
          {
            text: `text`,
            fontSize,
            alignment: 'center',
          },
        ],
        marginLeft: labelSize.marginLeft,
        opacity: 0,
      },
      {
        ...(barcodeImages[0] as any),
        marginLeft: labelSize.marginLeft,
        opacity: 0,
      },
    ] as Content[]);
  }

  const totalItemsPerPage = labelSize.columns * labelSize.row;

  let blankSpacesAfterLabels = totalItemsPerPage - totalLabelsInArray.length;

  if (totalItemsPerPage < totalLabelsInArray.length) {
    const amountInLastPage = totalLabelsInArray.length % totalItemsPerPage;
    blankSpacesAfterLabels = totalItemsPerPage - amountInLastPage;
  }

  if (blankSpacesAfterLabels > 0) {
    for (let i = 0; i < blankSpacesAfterLabels; i++) {
      totalLabelsInArray.push([
        {
          text: '',
          margin: [0, 0, 0, 0],
        },
      ]);
    }
  }

  const dividedArrays = chunkArray(totalLabelsInArray, labelSize.columns);

  const pdfDefinitions: TDocumentDefinitions = {
    // pageSize: {
    //   width: 630,
    //   height: 842,
    // },
    pageSize: 'A4',
    pageMargins: [
      23 + labelSize.appleMarginLeftToIncrease,
      labelSize.pageMarginTop + labelSize.applePageMarginTopToIncrease,
      0,
      labelSize.pageMarginBottom + labelSize.applePageMarginBottomToIncrease,
    ],

    content: [
      {
        table: {
          widths: Array.from(
            { length: labelSize.columns },
            () => labelSize.width + labelSize.appleWidthToIncrease,
          ),
          body: dividedArrays,
        },
        layout: 'noBorders',
      },
    ],
  };

  pdfMake.createPdf(pdfDefinitions).getBlob((blob) => {
    const url = URL.createObjectURL(blob);

    const pdf = window.open(url, '_blank');

    if (!pdf) {
      onError(
        'O navegador bloqueou o PDF. Desative a opção Bloquear Pop-ups e tente gerar novamente',
      );
    }
  });
};

export default generateBarCodeApplePdf;
