import { Box, SxProps, Theme } from '@mui/material';
import { useState, ComponentProps, useEffect } from 'react';
import { cardImageStyle, imageContainerStyle } from './styles';
import { verifyImageUrlExists } from '../../utils/getImageUrl';

type CardImageProps = ComponentProps<'div'> & {
  src: string;
  fallbackSrc: string;
  alt?: string;
  fallbackAlt?: string;
  containerStyle?: SxProps<Theme>;
  width?: number;
  height?: number;
};

export default function CardImage({
  src,
  fallbackSrc,
  alt,
  fallbackAlt,
  containerStyle = {},
  style,
  width,
  height,
  ...rest
}: CardImageProps) {
  const [isImageNotExists, setIsImageNotExists] = useState(true);

  const handleImageError = () => {
    setIsImageNotExists(true);
  };

  useEffect(() => {
    const verifyImageExists = async () => {
      const imageExists = await verifyImageUrlExists(src);
      setIsImageNotExists(imageExists);
    };

    verifyImageExists();
  }, [fallbackSrc, src]);

  return (
    <Box sx={{ ...imageContainerStyle, ...containerStyle } as SxProps<Theme>}>
      <div {...rest}>
        {isImageNotExists ? (
          <iframe
            src={src}
            title={alt}
            onError={handleImageError}
            style={{ ...cardImageStyle, ...style }}
            width={width}
            height={height}
          />
        ) : (
          <img
            src={fallbackSrc}
            alt={fallbackAlt}
            style={{ ...cardImageStyle, ...style }}
            width={width}
            height={height}
          />
        )}
      </div>
    </Box>
  );
}
