import { useForm } from 'react-hook-form';
import { useModal } from '../../hooks/useModal';
import { useCallback } from 'react';
import { LogLaunch } from '../../types/productDuimp';

export const useLogsDuimp = () => {
  const {
    isModalOpen: isManageModalOpen,
    handleCloseModal: handleCloseManageModal,
    handleOpenModal: handleOpenManageModal,
  } = useModal();

  const { register, setValue, watch } = useForm({
    defaultValues: {
      id: 0,
      totalProdutosComSucesso: '',
      totalProdutosComErro: '',
      totalFabricantesComSucesso: '',
      totalFabricantesComErro: '',
      dtLancamento: '',
    },
  });

  const id = watch('id');
  const totalProdutosComSucesso = watch('totalProdutosComSucesso');
  const totalProdutosComErro = watch('totalProdutosComErro');
  const totalFabricantesComSucesso = watch('totalFabricantesComSucesso');
  const totalFabricantesComErro = watch('totalFabricantesComErro');
  const dtLancamento = watch('dtLancamento');

  const handleTableRowClick = useCallback(
    (log: LogLaunch) => {
      setValue('id', log.id);
      setValue('totalProdutosComSucesso', log.totalProdutosComSucesso);
      setValue('totalProdutosComErro', log.totalProdutosComErro);
      setValue('totalFabricantesComSucesso', log.totalFabricantesComSucesso);
      setValue('totalFabricantesComErro', log.totalFabricantesComErro);
      setValue('dtLancamento', log.dtLancamento);

      handleOpenManageModal();
    },
    [handleOpenManageModal, setValue],
  );

  return {
    id,
    totalProdutosComSucesso,
    totalProdutosComErro,
    totalFabricantesComSucesso,
    totalFabricantesComErro,
    dtLancamento,
    isManageModalOpen,
    handleTableRowClick,
    handleCloseManageModal,
    register,
  };
};
