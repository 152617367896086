import { ReactNode, RefObject } from 'react';
import SerchInput from '../SearchInput';
import { InputBaseProps } from '@mui/material';

type ListingHeaderModalProps = InputBaseProps & {
  title: string;
  inputRef: RefObject<HTMLInputElement>;
  children: ReactNode;
  isSearchInputDirty: boolean;
  buttonContent?: string;
  onSearch: () => void;
  onCancelSearch: () => void;
  onButtonClick: () => void;
};

export default function ListingHeaderModal({
  title,
  inputRef,
  children,
  isSearchInputDirty,
  buttonContent = 'Buscar',
  onSearch,
  onCancelSearch,
  onButtonClick,
  ref,
  ...rest
}: ListingHeaderModalProps) {
  return (
    <div>
      <div className="mt-4">
        <h1 className="mb-4 text-2xl">{title}</h1>
        <SerchInput
          ref={inputRef}
          onSearch={onSearch}
          buttonContent={buttonContent}
          showCancelOptions={isSearchInputDirty}
          onCancelSearch={onCancelSearch}
          onButtonClick={onButtonClick}
          {...rest}
        />
      </div>
      {children}
    </div>
  );
}
