import { LabelItem } from '../../types/labelNF';
import { decodeHtml } from '../../utils/format';

type LabelNFLabelProps = {
  label: LabelItem;
};

export default function LabelNFLabel({ label }: LabelNFLabelProps) {
  if (!label) return null;

  return (
    <div className="h-[275px] w-[475px] rounded-xl bg-white px-4 pt-4 text-[11px]">
      <h4 className="text-[12px] font-bold">Destinatário</h4>
      <div className="ml-4">
        <p className="font-semibold">{decodeHtml(label.dest.xNome._text)}</p>
        <p>
          {decodeHtml(
            `${label.dest.enderDest.xLgr._text}, ${label.dest.enderDest.nro._text} - ${label.dest.enderDest.xBairro._text}`,
          )}
        </p>
        <p>
          {decodeHtml(
            `${label.dest.enderDest.CEP._text} ${label.dest.enderDest.xMun._text} - ${label.dest.enderDest.UF._text}`,
          )}
        </p>
      </div>
      <div className="mt-1 flex items-center gap-4 font-semibold">
        <h4 className="text-[12px]">Transp.:</h4>
        <span>{label.transp.transporta?.xNome._text ?? 'Cliente Retira'}</span>
      </div>
      <div className="mt-8 grid grid-cols-3 gap-8">
        <div className="col-span-2 flex flex-col items-center">
          <img src="/images/LogoBuffalo.png" alt="" width={150} height={150} />
          <p className="mt-2 flex flex-col text-center text-[10px] leading-3">
            <span>Rua Maria Fontes Machado, 420</span>{' '}
            <span>Costeira - São José dos Pinhais - PR</span>{' '}
            <span>CEP 83015-482 Fone (41) 3091-5600 - Fax (41) 3091-5606</span>
            <span>CNPJ: 07.040.941/0001-06 Insc. Est: 9032092249</span>
            <span>www.buffalo.com.br - buffalo@buffalo.com.br</span>
          </p>
        </div>
        <div className="col-span-1 -ml-12 flex flex-col gap-3 font-semibold">
          <div className="flex">
            <div className="flex w-32 flex-col gap-2">
              <span>NOTA FISCAL: </span>
              <span>Nº DE VOLUMES: </span>
            </div>
            <div className="flex flex-col gap-2">
              <span>{decodeHtml(label.ide.nNF._text)}</span>
              <span>{decodeHtml(label.transp.vol.qVol._text)}</span>
            </div>
          </div>
          <div className="ml-8 mt-4 flex flex-col text-[12px]">
            <span>Responsável</span>
            <span className="ml-4 font-normal">
              {decodeHtml(label.infRespTec.xContato._text)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
