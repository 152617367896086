import {
  TableSortLabel,
  useTheme,
  TableCell as TableCellMui,
  TableCellProps as TableCellPropsMui,
} from '@mui/material';
import { tableHeadCellStyle } from './styles';
import { useState } from 'react';
import verifyObjectsArraySort from '../../utils/sort';
import Sort from '../../enums/sort';
import { twMerge } from 'tailwind-merge';

type Column = string;

type Direction = 'asc' | 'desc' | null;

type TableCellProps = TableCellPropsMui & {
  type?: Column;
  items?: Record<string, any>[];
  isSortActive?: boolean;
  leftWidth?: string;
  onSort?: (fieldNameSort: string) => void;
};

export default function TableCell({
  items,
  isSortActive = false,
  type,
  onSort,
  children,
  className,
  ...rest
}: TableCellProps) {
  const theme = useTheme();
  const [sort, setSort] = useState<Direction>(null);

  const verifySort = () => (isSortActive && sort === 'desc' ? 'desc' : 'asc');

  const handleSort = (column: Column) => {
    if (items) {
      const itemsSort = verifyObjectsArraySort(items, column);
      let isAllColumnEqual = items.every(
        (item) => item[column] === items[0][column],
      );

      const columns = column.split('.');

      if (columns.length === 2) {
        const [first, second] = columns;
        isAllColumnEqual = items.every(
          (item) => item[first][second] === items[0][first][second],
        );
      }

      if (isAllColumnEqual) {
        setSort('asc');
        return;
      }

      if (onSort) {
        onSort(column);
      }

      if (itemsSort === Sort.ascending) {
        setSort('desc');
        return;
      }

      setSort((prev) => (isSortActive && prev === 'asc' ? 'desc' : 'asc'));
    }
  };


  return (
    <TableCellMui
      sx={{
        ...tableHeadCellStyle,
        backgroundColor: theme.palette.background.paper,
      }}
      className={twMerge(
        type === 'id' ? 'w-24 cursor-pointer' : 'cursor-pointer',
        'relative max-w-[40vw] text-left',
        className,
      )}
      {...rest}
    >
      <TableSortLabel
        active={isSortActive}
        direction={verifySort()}
        onClick={() => (type ? handleSort(type) : '')}
        sx={{
          display: isSortActive ? 'flex' : 'none',
          position: 'absolute',
          left: '-1.5rem',
        }}
      />
      <span
        onClick={() => (type ? handleSort(type) : '')}
        className={`${type === 'id' ? 'text-zinc-400' : ''} flex w-full`}
      >
        {children}
      </span>
    </TableCellMui>
  );
}
