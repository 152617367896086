import { Link } from 'react-router-dom';
import { MenuItem, Select } from '@mui/material';
import { useLinesNavbar } from './useLinesNavbar';
import BackButton from '../BackButton';

type LinesNavbarProps = {
  onLineClick: (lineId: number) => void;
};

export default function LinesNavbar({ onLineClick }: LinesNavbarProps) {
  const { lines, isMobile, lineId, selectedLine, auth, handleSelectPartGroup } =
    useLinesNavbar();

  return (
    <div className="flex items-center bg-headerPrimary">
      {auth && (
        <BackButton className="relative left-4" textClassName="text-blue-300" />
      )}
      {isMobile ? (
        <nav className="flex w-full flex-1 items-center gap-4 px-4 py-2 pl-[3.25rem] pr-4 pt-2">
          <label className="text-secondary">Linha</label>
          <Select
            value={selectedLine}
            onChange={handleSelectPartGroup}
            className="my-4 h-12 flex-1 bg-zinc-100"
          >
            {lines?.items.map((line) => (
              <MenuItem
                value={line.name}
                key={line.id}
                onClick={() => onLineClick(line.id)}
                sx={{ width: '100vw' }}
              >
                {line.name}
              </MenuItem>
            ))}
          </Select>
        </nav>
      ) : (
        <nav className="flex-1 bg-headerPrimary px-4 py-2">
          <ul className="flex flex-wrap justify-center">
            {lines?.items.map((line, index) => (
              <li key={line.id} className="mx-8 my-4 text-center">
                <Link
                  to={{ search: `lineId=${line.id}` }}
                  onClick={() => onLineClick(line.id)}
                >
                  <span
                    className={`${lineId === line.id || (index === 0 && !lineId) ? 'text-secondary' : 'text-zinc-200'} delay-50 transition-all ease-linear hover:text-secondary`}
                  >
                    {line.name}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </div>
  );
}
