import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { common, grey } from '@mui/material/colors';

export const headerStyle: SxProps<Theme> = {
  position: 'fixed',
  width: '100%',
  zIndex: 3,
  backgroundColor: common.white,
  overflow: 'none',

  '@media (max-width: 800px)': {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: grey['50'],
    zIndex: 2,
    borderBottom: 'none',
  },
};

export const mainStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'flex-start',
  // padding: '1rem',
  gap: '45rem',
  paddingTop: '6.5rem',
  paddingBottom: '2rem',
  overflow: 'none',

  '@media (max-width: 1920px)': {
    gap: '30rem',
  },

  '@media (max-width: 1367px)': {
    gap: '15rem',
  },

  '@media (max-width: 800px)': {
    paddingTop: 0,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
  },
};

// export const partsGroupStyle: SxProps<Theme> = {
//   '@media (max-width: 1250px)': {
//     width: '100%',
//     position: 'fixed',
//     top: '8.25rem',
//     zIndex: 2,
//   },
// };

export const explodedViewStyle: SxProps<Theme> = {
  '@media (max-width: 800px)': {
    marginTop: '1rem',
  },
};

export const fieldsCatalogTable: SxProps<Theme> = {
  '@media (max-width: 800px)': {
    width: '100%',
    marginTop: '5rem',
    // backgroundColor: grey['200'],
    // borderTop: `2px solid ${grey['400']}`,
    // borderBottom: `2px solid ${grey['400']}`,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
};
