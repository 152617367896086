import {
  CoordinatesModified,
  CoordinateToDelete,
  FindAllCoordinates,
  FindAllFieldDomainItem,
  PureCoordinatesModified,
  SaveUpdateCoordiante,
} from '../types/domainImageCoordinate';
import { api } from './api';

export const getAllCoordinatesByDomainId = async (domainId: number) => {
  const response = await api.get<FindAllCoordinates>(
    `/signature-field-coordinate/v1/${domainId}`,
  );

  return response;
};

export const getAllFields = async (domainId: number) => {
  const response = await api.get<FindAllFieldDomainItem>(
    `/field-signature/v1/${domainId}`,
  );

  return response;
};

export const saveUpdateCoordinate = async (
  coordinateDto: CoordinatesModified[],
  domainId: number,
) => {
  const dto: PureCoordinatesModified[] = coordinateDto.map(
    ({
      height,
      domainId,
      fieldId,
      width,
      x,
      y,
      bold,
      color,
      fontSize,
      fontStyle,
      textDecoration,
      textAlign,
      isVisible,
    }) => ({
      domainId,
      height,
      fieldId,
      width,
      x,
      y,
      bold,
      color,
      fontSize,
      fontStyle,
      textDecoration,
      textAlign,
      isVisible,
    }),
  );

  const body = {
    items: dto,
  };

  const response = await api.put<SaveUpdateCoordiante>(
    `/signature-field-coordinate/v1`,
    body,
  );

  return response;
};

export const deleteCoordinates = async (
  coordinateDeleteDto: CoordinateToDelete[],
) => {
  const dto = {
    items: coordinateDeleteDto,
  };

  const response = await api.delete<void>(`signature-field-coordinate/v1`, {
    data: dto,
  });

  return response;
};

export const deleteAllCoordinatesByDomainId = async (domainId: number) => {
  const response = await api.delete<void>(
    `signature-field-coordinate/v1/domain/${domainId}`,
  );

  return response;
};
