import { FindAllParts, PartsPaginated } from '../types/parts';
import { FindAllPartsGroup } from '../types/partsGroup';
import { PureCoordinatesModified } from '../types/partsGroupCoordinate';
import {
  FindAllPartsGroupItem,
  FindAllPartsGroupItemPaginated,
  FindAllPartsGroupItemsByLine,
  PartsGroupItemDomainLiveSearchPaginated,
  PartsGroupItemsDelete,
  PartsGroupItemsModified,
  SaveUpdatePartsGroupItem,
} from '../types/partsGroupItem';
import { FindAllProducts } from '../types/products';
import generatePaginatedUrl, { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getAllProductsByPartsGroupId = async (partsGroupId: number) => {
  const response = await api.get<FindAllProducts>(
    `/parts-group-item/v1/find-all-products/parts-group-id/${partsGroupId}`,
  );
  return response;
};

export const getAllPartsByProductId = async (productId: number) => {
  const response = await api.get<FindAllParts>(
    `/parts-group-item/v1/find-all-parts/product-id/${productId}`,
  );

  return response;
};

export const getAllPartsByProductIdPaginated = async (
  productId: number,
  page: number,
  direction: 'ASC' | 'DESC' = 'ASC',
  limit?: number,
) => {
  const url = generatePaginatedUrl(
    `/parts-group-item/v1/parts/product-id/${productId}`,
    page,
    limit,
    direction,
  );

  const response = await api.get<PartsPaginated>(url);
  return response;
};

export const getAllPartsGroupByProductId = async (productId: number) => {
  const response = await api.get<FindAllPartsGroup>(
    `/parts-group-item/v1/find-all-parts-group/products-id/${productId}`,
  );
  return response;
};

export const getAllPartsGroupItemByPartsGroupIdAndProductId = async (
  partsGroupId: number,
  productId: number,
) => {
  const response = await api.get<FindAllPartsGroupItem>(
    `/parts-group-item/v1/parts-group-id/${partsGroupId}/product-id/${productId}`,
  );
  return response;
};

export const getAllPartsGroupItemsByProductId = async (productId: number) => {
  const response = await api.get<FindAllPartsGroupItem>(
    `/parts-group-item/v1/product-id/${productId}`,
  );
  return response;
};

export const getAllPartsGroupItemsByLineId = async (lineId: number) => {
  const response = await api.get<FindAllPartsGroupItemsByLine>(
    `/parts-group-item/v1/line-id/${lineId}`,
  );
  return response;
};

export const getAllPartsGroupItemsByPartId = async (partId: number) => {
  const response = await api.get<FindAllPartsGroupItem>(
    `/parts-group-item/v1/find-all/part-id/${partId}`,
  );

  return response;
};

export const getAllPartsGroupItemsByPartIdPaginated = async (
  partId: number,
  page: number,
  direction: 'ASC' | 'DESC' = 'ASC',
  limit?: number,
) => {
  const url = generatePaginatedUrl(
    `/parts-group-item/v1/part-id/${partId}`,
    page,
    limit,
    direction,
  );
  const response = await api.get<FindAllPartsGroupItemPaginated>(url);
  return response;
};

export const saveUpdatePartsGroupItem = async (
  partsGroupItemsDto: PartsGroupItemsModified[],
  coordinateDto: PureCoordinatesModified[],
) => {
  const body = {
    partsGroupItems: partsGroupItemsDto.map(({ partName, ...dto }) => dto),
    coordinates: coordinateDto,
  };

  const response = await api.put<SaveUpdatePartsGroupItem>(
    `/parts-group-item/v1/create-update-parts-group-item`,
    body,
  );

  return response;
};

export const deletePartsGroupItem = async (dto: PartsGroupItemsDelete) => {
  const response = await api.delete<void>(
    `/parts-group-item/v1/delete-parts-and-parts-group`,
    { data: dto },
  );

  return response;
};

export const deletePartsGroupItemByProductId = async (productId: number) => {
  const response = await api.delete<void>(
    `/parts-group-item/v1/delete-by-product-id/${productId}`,
  );

  return response;
};

export const deleteAllParts = async (
  productId: number,
  partsGroupId: number,
) => {
  const response = await api.delete<void>(
    `/parts-group-item/v1/parts-group-id/${partsGroupId}/product-id/${productId}`,
  );

  return response;
};

// export const getOnePartsGroupItemByAllIds = async ({
//   partsGroupId,
//   productId,
//   partId,
// }: Record<'partsGroupId' | 'productId' | 'partId', number>) => {
//   const response = await api.get<PartsGroupItem>(
//     `parts-group-item/v1/parts-group-id/${partsGroupId}/product-id/${productId}/part-id/${partId}`,
//   );
//   return response;
// };


export const getPartsGroupItemDomainLiveSearchPaginated = async (
  idProduto: string | undefined,
  search: string | null,
  domainId: number | null,
  page: number = 1,
  direction: 'ASC' | 'DESC' | null = 'ASC',
  limit?: number | null,
) => {
  const url = addSearchParamsInUrl(
    `parts-group-item/v1/live-search/${idProduto}`,
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'domainId', value: domainId },
    { name: 'direction', value: direction },
    search ? { name: 'query', value: search } : {},
  );

  const response =
    await api.get<PartsGroupItemDomainLiveSearchPaginated>(url);
  return response;
};
