import { api } from '../../services/api';
import { FindAllProductDescriptionDetails } from '../../types/productsDescription';
import { getCurrentDomain } from '../../utils/domain';
import { ProductResponse } from '../types/products';

const ENDPOINT_URL = '/get-product-in-domain/v1';

export const fetchProdutos = async (
  lineId: string,
  page: number = 1,
  limit: number = 20,
  categoryId?: string,
) => {
  try {
    const params: any = {
      lineId,
      domainId: getCurrentDomain().id,
      page,
      limit,
    };
    if (categoryId) {
      params.categoryId = categoryId;
    }

    const response = await api.get<ProductResponse>(ENDPOINT_URL, { params });

    return {
      list: response.data.data,
      total: response.data.totalItems,
    };
  } catch (error) {
    console.error('Erro ao buscar dados da API', error);
    throw error;
  }
};

export const getProductByProductId = async (productId: number, categoryId?: number, lineId?: number) => {
  const response = await api.get<FindAllProductDescriptionDetails>(
    `product-description/v1/find-all-product-descriptions/product-id/${productId}?categoryId=${categoryId}&lineId=${lineId}`,
  );
  return response.data;
};

export const checkProductExistsInDomain = async (
  productId: number,
  domainId: number,
) => {
  try {
    const response = await api.get(`/get-product-in-domain/v1/product-exists`, {
      params: { productId, domainId },
    });
    return response.data.exists;
  } catch (error) {
    console.error(
      `Erro ao verificar se o produto ${productId} existe no domínio ${domainId}:`,
      error,
    );
    return false;
  }
};
