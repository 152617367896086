import { Link } from 'react-router-dom';
import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import DomainContent from '../../../components/DomainContent';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import { ImageTypes } from '../../../enums/imageTypes';
import { cepMask } from '../../../utils/mask';
import { useDomain } from './useDomain';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImagesContent from '../../../components/ImagesContent';
import Table from '../../../components/Table';
import ListingHeaderModal from '../../../components/ListingHeaderModal';
import LoadMore from '../../../components/LoadMore';
import Loading from '../../../components/Loading';
import Main from '../../../components/Main';
import Footer from '../../../components/Footer';

export default function Domain() {
  const {
    currentProductName,
    cep,
    inputRef,
    street,
    currentImageHighlight,
    number,
    neighborhood,
    domain,
    isMenageModalOpen,
    domainIdUpdate,
    isShowEditButton,
    dirtyFields,
    errors,
    isConfirmDeleteModalOpen,
    errorMessage,
    previewImages,
    states,
    errorProductImagehighlight,
    cities,
    imagesInUse,
    isSearchInputDirty,
    selectImageModalType,
    ProductDomainShown,
    isFetchingProductsDomain,
    endPageRef,
    isLoadMoreFetching,
    itemsLength,
    isMenageModalProductsOpen,
    selectedProductImage,
    isShowEditProductImageHighlight,
    isShowEditImageHighlight,
    isShowCreateProductImageHighlight,
    handleClearProductImageHighlight,
    handleSelectImage,
    handleCloseSelectImageModal,
    handleStateClick,
    setValue,
    clearError,
    handleConfirmDelete,
    handlecloseConfirmDeleteModal,
    handleClearDomain,
    handleSubmit,
    register,
    handleEdit,
    handleButtonOkClick,
    handleDeleteButtonClick,
    handleAddDomainClick,
    handleCloseManageModal,
    handleTableRowClick,
    handleInputImageClick,
    handleOpenModalProducts,
    closeMenageProductsModal,
    handleSearchDatabase,
    handleSearch,
    handleCancelSearch,
    handleEnterClickSearchInput,
    handleSelectProduct,
  } = useDomain();

  return (
    <>
      <DomainContent
        onTableRowClick={handleTableRowClick}
        handleAddDomainRowClick={handleAddDomainClick}
      />

      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseManageModal}
        cancelIcon={false}
        height="85vh"
        contentStyle="pr-1 pt-1"
      >
        <div className="sticky top-0 max-h-[84vh] overflow-auto bg-backgroundPaper pr-4">
          <HeaderModalManageItem
            showDeleteButton={!!domainIdUpdate}
            deleteButtonContent="Apagar domínio"
            onDeleteButtonClick={handleDeleteButtonClick}
            isEditable={!isShowEditButton}
            title={domainIdUpdate ? 'Domínio' : 'Novo domínio'}
            mainButtonContent={isShowEditButton ? 'Editar' : 'Ok'}
            onCancelClick={handleCloseManageModal}
            onOkClick={
              !isShowEditButton ? handleSubmit(handleButtonOkClick) : undefined
            }
            onEditClick={isShowEditButton ? handleEdit : undefined}
          />
          <div className="mb-24">
            <div className="mb-8 mr-24 mt-24 flex w-full flex-col gap-4">
              <Input
                maxLength={250}
                type="text"
                label="Domínio"
                id={'Domínio'}
                disabled={isShowEditButton}
                helperText={errors.domain?.message}
                isDirty={dirtyFields.domain}
                onCancelIconClick={() => handleClearDomain('domain')}
                {...register('domain')}
                value={domain}
              />
              <Input
                maxLength={8}
                type="text"
                value={cep}
                label="CEP"
                id={'CEP'}
                disabled={isShowEditButton}
                helperText={errors.cep?.message}
                isDirty={dirtyFields.cep}
                onCancelIconClick={() => handleClearDomain('cep')}
                {...register('cep', {
                  onChange: (e) => {
                    setValue('cep', cepMask(e.target.value), {
                      shouldValidate: true,
                    });
                  },
                })}
              />
              <Input
                maxLength={250}
                type="text"
                label="Rua"
                id={'Rua'}
                value={street}
                disabled={isShowEditButton}
                helperText={errors.street?.message}
                isDirty={dirtyFields.street}
                onCancelIconClick={() => handleClearDomain('street')}
                {...register('street')}
              />
              <Input
                maxLength={250}
                type="text"
                label="Bairro"
                value={neighborhood}
                id={'Bairro'}
                disabled={isShowEditButton}
                helperText={errors.neighborhood?.message}
                isDirty={dirtyFields.neighborhood}
                onCancelIconClick={() => handleClearDomain('neighborhood')}
                {...register('neighborhood')}
              />
              <Input
                maxLength={10}
                type="text"
                label="Número"
                value={number}
                id={'Número'}
                disabled={isShowEditButton}
                helperText={errors.number?.message}
                isDirty={dirtyFields.number}
                onCancelIconClick={() => handleClearDomain('number')}
                {...register('number')}
              />

              <div>
                <span className="text-xs">Estado</span>
                <select
                  className="w-full border-b border-solid outline-none"
                  disabled={isShowEditButton}
                  {...register('state', {
                    onChange: (event) => handleStateClick(event.target.value),
                  })}
                >
                  {states?.map((state) => (
                    <option key={state.id} value={state.nome}>
                      {state.nome}
                    </option>
                  ))}
                </select>
                <span className="text-xs text-red-600">
                  {errors.state?.message}
                </span>
              </div>

              <div>
                <span className="text-xs">Cidade</span>
                <select
                  className="w-full border-b border-solid outline-none"
                  disabled={isShowEditButton}
                  {...register('city')}
                >
                  {cities?.map((city) => (
                    <option key={city.id} value={city.nome}>
                      {city.nome}
                    </option>
                  ))}
                </select>
                <span className="text-xs text-red-600">
                  {errors.state?.message}
                </span>
              </div>
            </div>
            <div>
              <div
                onClick={() => handleInputImageClick(ImageTypes.imgHighlight)}
                className="flex cursor-pointer items-center gap-6 border-b-[1px]"
              >
                <Input
                  readOnly
                  type="text"
                  disabled={
                    !isShowEditButton
                      ? isShowEditImageHighlight
                      : isShowEditButton
                  }
                  label="Imagem do Destaque"
                  className="cursor-pointer border-none p-0"
                  inputStyle="cursor-pointer"
                  isDirty={!!dirtyFields.imgHighlight}
                  {...register('imgHighlight')}
                  value={currentImageHighlight}
                />
                <ChevronRightIcon className="text-secondaryText" />
              </div>
            </div>

            {previewImages.imgHighlight && (
              <img
                src={previewImages.imgHighlight}
                alt="Preview da imagem selecionada de Destaque do domínio"
                width={600}
                height={220}
              />
            )}
            <div
              onClick={handleOpenModalProducts}
              className="mt-8 flex cursor-pointer flex-col items-center border-b-[1px]"
            >
              <div className="flex w-full cursor-pointer items-center gap-6 border-b-[1px]">
                <Input
                  readOnly
                  type="text"
                  disabled={
                    isShowEditProductImageHighlight
                      ? isShowEditProductImageHighlight
                      : isShowCreateProductImageHighlight
                  }
                  label="Produto vinculado a imagem em destaque"
                  className="cursor-pointer border-none p-0"
                  inputStyle="cursor-pointer"
                  isDirty={!!selectedProductImage}
                  onCancelIconClick={() => {
                    handleClearProductImageHighlight(); // Agora essa função não precisa do 'event'
                  }}
                  value={currentProductName.current!}
                />
                <ChevronRightIcon className="text-secondaryText" />
              </div>
              <span className="w-full text-left text-xs text-red-600">
                {errorProductImagehighlight}
              </span>
            </div>

            <div>
              <div
                onClick={() =>
                  handleInputImageClick(ImageTypes.emailSignatures)
                }
                className="mt-8 flex cursor-pointer items-center gap-6 border-b-[1px]"
              >
                <Input
                  readOnly
                  type="text"
                  disabled={isShowEditButton}
                  label="Imagem de assinatura de email"
                  className="cursor-pointer border-none p-0"
                  inputStyle="cursor-pointer"
                />
                <ChevronRightIcon className="text-secondaryText" />
              </div>
            </div>
            {previewImages.emailSignatures && (
              <>
                <img
                  src={previewImages.emailSignatures}
                  alt="Preview da imagem selecionada de assinatura de email"
                  className="mx-auto"
                  width={600}
                  height={220}
                />
                <Link
                  to={`/admin/domain/${domainIdUpdate}/edit`}
                  className="text-sm text-primary"
                >
                  Posicionar campos
                </Link>
              </>
            )}
          </div>
        </div>
      </Modal>

      <ConfirmModal
        contentMessage="Deseja excluir essa domínio?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handlecloseConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
        onNotConfirm={handlecloseConfirmDeleteModal}
      />

      <Modal
        isOpen={isMenageModalProductsOpen}
        onClose={closeMenageProductsModal}
        width="50rem"
        contentStyle="h-[100vh]"
      >
        <ListingHeaderModal
          title="Produtos"
          inputRef={inputRef}
          isSearchInputDirty={isSearchInputDirty}
          onCancelSearch={handleCancelSearch}
          onSearch={handleSearch}
          onButtonClick={handleSearchDatabase}
          onKeyDown={handleEnterClickSearchInput}
        >
          <Table.Head className="flex items-center justify-start">
            <Table.Cell>código</Table.Cell>
            <Table.Cell className="ml-9">nome</Table.Cell>
          </Table.Head>
        </ListingHeaderModal>
        <Main className="ml-0 pb-0  pl-0 pr-0">
          {isFetchingProductsDomain && <Loading loadMore />}
          <Table.Root
            itemType="Produtos"
            itemsLength={100}
            className="h-[71vh]"
          >
            {ProductDomainShown.map((product) => {
              return (
                <div key={product.id}>
                  <Table.Row
                    className="flex items-center justify-start gap-10"
                    onClick={() => handleSelectProduct(product)}
                  >
                    <Table.CellStatic>{product?.product?.id}</Table.CellStatic>
                    <Table.CellStatic>
                      {product?.product?.name}
                    </Table.CellStatic>
                  </Table.Row>
                </div>
              );
            })}
            <LoadMore
              isModal={false}
              isContentFetching={isFetchingProductsDomain}
              isLoadMoreFetching={isLoadMoreFetching}
              ref={endPageRef}
            />
          </Table.Root>
        </Main>
        <Footer
          current={ProductDomainShown.length}
          total={itemsLength}
          itemType="Produtos"
          className="absolute bottom-0 mr-0"
        />
      </Modal>

      <Modal
        isOpen={!!selectImageModalType}
        onClose={handleCloseSelectImageModal}
        width="50rem"
        contentStyle="h-[100vh]"
      >
        <ImagesContent
          onTableRowClick={handleSelectImage}
          isModal
          imageType={selectImageModalType}
          // canSearch
          itemsInUse={imagesInUse}
        />
      </Modal>

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
