import { ItemInUse } from '../../types/inUse';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import ListingHeaderModal from '../ListingHeaderModal';
import Loading from '../Loading';
import Main from '../Main';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { useQuestionContent } from './useQuestionContent';
import CheckIcon from '@mui/icons-material/Check';
import { removeHtmlTags } from '../../utils/removeHtmlTags';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import Checkbox from '../Checkbox';
import Filter from '../Filter';

type QuestionContentProps = {
  isModal?: boolean;
  itemsInList?: ItemInUse[];
  onTableRowClick: (
    id: number,
    question: string,
    reply: string,
    status: boolean,
    order: number,
  ) => void;
  onAddUserTypesClick?: () => void;
};

export default function QuestionContent({
  isModal,
  itemsInList,
  onTableRowClick,
  onAddUserTypesClick,
}: QuestionContentProps) {
  const {
    inputRef,
    isFetching,
    isSearchInputDirty,
    sortField,
    questionsLength,
    questionShown,
    canEditOrder,
    checkedDomainsStatus,
    domainWidth,
    domains,
    checkedItems,
    canEditDomain,
    statusActive,
    domainFilter,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleChangeEditDomainButtonClick,
    handleButtonCancelEditClick,
    handleChangeDomainCheck,
    handleChangeAllDomainsCheck,
    handleDragEnd,
    handleChangeEditOrderButtonClick,
    handleSearch,
    handleCancelSearch,
    handleSortTable,
    handleFilterByStatus,
    handleFilterByDomain,
  } = useQuestionContent();

  return (
    <>
      {isModal && (
        <ListingHeaderModal
          title="Perguntas Frequentes"
          buttonContent="Cancelar"
          inputRef={inputRef}
          isSearchInputDirty={isSearchInputDirty}
          onCancelSearch={handleCancelSearch}
          onSearch={handleSearch}
          onButtonClick={handleCancelSearch}
          disabled={canEditOrder}
        >
          <Table.Head>
            <Table.Cell
              items={questionShown}
              type="type"
              onSort={handleSortTable}
              isSortActive={sortField === 'type'}
            >
              Tipo
            </Table.Cell>
          </Table.Head>
        </ListingHeaderModal>
      )}

      {!isModal && (
        <ListingHeader
          add
          onAddIconClick={onAddUserTypesClick}
          pdfTop="3rem"
          pdf={false}
        >
          <div className="mt-4">
            <div className="mb-4 flex justify-between">
              <h1 className="text-2xl">Perguntas frequentes</h1>
              <div className="flex items-center gap-2">
                {!canEditDomain && (
                  <button
                    onClick={handleChangeEditOrderButtonClick}
                    className="text-sm text-primary"
                    disabled={canEditDomain}
                  >
                    {canEditOrder ? 'Salvar ordem' : 'Editar ordem'}
                  </button>
                )}

                {!canEditDomain && !canEditOrder && <span>|</span>}

                {!canEditOrder && (
                  <button
                    onClick={handleChangeEditDomainButtonClick}
                    className="text-sm text-primary"
                    disabled={canEditOrder}
                  >
                    {canEditDomain ? 'Salvar domínio' : 'Editar domínio'}
                  </button>
                )}

                {(canEditDomain || canEditOrder) && (
                  <>
                    <span>|</span>
                    <button
                      onClick={() => handleButtonCancelEditClick()}
                      className="text-sm text-errorText"
                    >
                      Cancelar {canEditDomain ? 'Seleção' : 'Ordenação'}
                    </button>
                  </>
                )}
              </div>
            </div>

            <Filter
              title="Status"
              filters={[
                {
                  filter: 'todos',
                  isCurrent: !statusActive || statusActive === 'todos',
                  name: 'Todos',
                },
                {
                  filter: 'ativo',
                  isCurrent: statusActive === 'ativo',
                  name: 'Ativo',
                },
                {
                  filter: 'desativado',
                  isCurrent: statusActive === 'desativado',
                  name: 'Inativo',
                },
              ]}
              onFilter={handleFilterByStatus}
              style={{
                pointerEvents: canEditDomain || canEditOrder ? 'none' : 'auto',
                opacity: canEditDomain || canEditOrder ? 0.5 : 1,
              }}
            />

            <Filter
              title="Domínios"
              filters={[
                {
                  filter: 'todos',
                  isCurrent: !domainFilter || domainFilter === 'todos',
                  name: 'Todos',
                },
                ...(domains?.items ?? []).map((domain) => ({
                  filter: domain.name,
                  isCurrent: domainFilter === domain.name,
                  name: domain.name,
                })),
              ]}
              onFilter={handleFilterByDomain}
              style={{
                pointerEvents: canEditDomain || canEditOrder ? 'none' : 'auto',
                opacity: canEditDomain || canEditOrder ? 0.5 : 1,
              }}
            />

            <SearchInput
              ref={inputRef}
              onSearch={handleSearch}
              onButtonClick={handleSearchDatabase}
              buttonContent="Buscar"
              onKeyDown={handleEnterClickSearchInput}
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
              disabled={canEditOrder}
            />
          </div>
          <Table.Head rowStyle={`${canEditOrder && 'pr-[4.25rem]'}`}>
            <div className="mr-32 flex flex-1 justify-between gap-2">
              <Table.Cell
                items={questionShown}
                type="pergunta"
                onSort={handleSortTable}
                isSortActive={sortField === 'pergunta'}
                className="flex-1"
              >
                Pergunta
              </Table.Cell>
              <Table.Cell
                items={questionShown}
                type="resposta"
                onSort={handleSortTable}
                isSortActive={sortField === 'resposta'}
                className="flex-1"
              >
                Resposta
              </Table.Cell>
              <Table.Cell
                items={questionShown}
                type="statusText"
                onSort={handleSortTable}
                isSortActive={sortField === 'statusText'}
                className="w-20"
              >
                Status
              </Table.Cell>
            </div>
            <div className="flex gap-4">
              {checkedDomainsStatus.map((domain) => (
                <Table.CellStatic
                  key={`label-${domain.domainId}`}
                  style={{ width: `${domainWidth}ch` }}
                  className="text-secondaryText"
                >
                  <Checkbox
                    label={domain.domainName}
                    status={domain.status}
                    onChange={(event) =>
                      handleChangeAllDomainsCheck(
                        { id: domain.domainId, name: domain.domainName },
                        event.target.checked,
                      )
                    }
                    checked={domain.status === 'all'}
                    id={`domain${domain.domainId}`}
                    className="size-4"
                    disabled={!canEditDomain}
                  />
                </Table.CellStatic>
              ))}
            </div>
          </Table.Head>
        </ListingHeader>
      )}

      <Main isModal={isModal}>
        {isFetching && <Loading />}
        {!isFetching && (
          <Table.Root
            itemType="Perguntas frequentes"
            itemsLength={questionsLength}
            className={`${isModal ? 'max-h-[60vh]' : ''}`}
            paddingTop="0"
          >
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                isDropDisabled={!canEditOrder}
                droppableId="questions"
                type="list"
                direction="vertical"
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {questionShown.map((quest, index) => {
                      const isInList = itemsInList?.some(
                        (item) => item.id === quest.id,
                      );

                      return (
                        <Table.Row
                          key={quest.id}
                          className={
                            isInList ? 'cursor-default opacity-50' : ''
                          }
                        >
                          <Draggable
                            isDragDisabled={!canEditOrder}
                            draggableId={quest.id.toString()}
                            index={index}
                            key={quest.id}
                          >
                            {(provided) => (
                              <div
                                className="flex w-full justify-between bg-backgroundPaper"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  onClick={() =>
                                    !(isModal && isInList) &&
                                    onTableRowClick(
                                      quest.id,
                                      quest.pergunta,
                                      quest.resposta,
                                      quest.status,
                                      quest.ordem,
                                    )
                                  }
                                  className="mr-32 flex flex-1 justify-between gap-2"
                                >
                                  <Table.Cell
                                    items={questionShown}
                                    type="type"
                                    className="flex-1"
                                  >
                                    <span>{quest.pergunta}</span>
                                  </Table.Cell>
                                  <Table.Cell
                                    items={questionShown}
                                    type="type"
                                    className="flex-1"
                                  >
                                    <span>
                                      {removeHtmlTags(quest.resposta)}
                                    </span>
                                  </Table.Cell>
                                  <Table.Cell
                                    items={questionShown}
                                    type="status"
                                    className="w-20"
                                  >
                                    <span>{quest.statusText}</span>
                                  </Table.Cell>
                                </div>
                                <div className="flex items-center gap-4">
                                  {domains?.items.map((domain) => {
                                    const initialQuestionDomain =
                                      quest.questionDomains?.find(
                                        (questionDomain) =>
                                          questionDomain.domain.id ===
                                          domain.id,
                                      );

                                    const questionDomain = checkedItems
                                      ?.find(
                                        (questionDomain) =>
                                          questionDomain.id === quest.id,
                                      )
                                      ?.questionDomains?.find(
                                        (questionDomain) =>
                                          questionDomain.domain.id ===
                                          domain.id,
                                      );
                                    return (
                                      <Table.CellStatic
                                        className="flex items-center justify-center gap-2"
                                        key={`checkbox-${domain.id}`}
                                        style={{
                                          width: `${domainWidth}ch`,
                                        }}
                                      >
                                        <Checkbox
                                          checked={
                                            questionDomain?.domain.id ===
                                            domain.id
                                          }
                                          onChange={(event) =>
                                            handleChangeDomainCheck(
                                              event.target.checked,
                                              (initialQuestionDomain?.id ?? 0) >
                                                0
                                                ? initialQuestionDomain?.id
                                                : undefined,
                                              domain,
                                              quest.id,
                                            )
                                          }
                                          className="size-4"
                                          disabled={!canEditDomain}
                                        />
                                      </Table.CellStatic>
                                    );
                                  })}
                                </div>

                                {isModal && isInList && (
                                  <CheckIcon className="text-primary" />
                                )}
                                {canEditOrder && (
                                  <img
                                    src="/icons/drag.png"
                                    alt="Ícone para arrastar Descrição"
                                    className="mr-4 size-5 cursor-grab"
                                  />
                                )}
                              </div>
                            )}
                          </Draggable>
                        </Table.Row>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Table.Root>
        )}
      </Main>

      <Footer
        current={questionShown.length}
        total={questionsLength}
        itemType="Perguntas frequentes"
        data-modal={isModal}
        className={
          isModal ? 'absolute mx-[1px] mb-[1px] mt-[-10vh] rounded-b-3xl' : ''
        }
      />
    </>
  );
}
