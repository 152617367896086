import { Typography } from '@mui/material';
import { isNewStyle } from './styles';

type CardIsNewProps = {
  text: string;
};

export default function CardIsNew({ text }: CardIsNewProps) {
  return (
    <Typography component="span" sx={{ ...isNewStyle }}>
      {text}
    </Typography>
  );
}
