import { create } from 'zustand';
import { PartsGroupOptionalDescriptionType } from '../../types/partsGroup';
import {
  PartsGroupItem,
  PartsGroupItemsModified,
} from '../../types/partsGroupItem';
import {
  CoordinatesModified,
  CoordinateToDelete,
} from '../../types/partsGroupCoordinate';

type PartsCatalogStoreProps = {
  state: {
    currentPartsGroup: PartsGroupOptionalDescriptionType | null;
    currentProductId: number | null;
    partsGroupItemModified: PartsGroupItemsModified[];
    currentPartIdHover: number | null;
    currentPartClicked: PartsGroupItem | null;
    coordinatesModified: CoordinatesModified[];
    coordinatesToDelete: CoordinateToDelete[];
    canEdit: boolean;
    isModified: { current: boolean };
    shouldShowFirstCurrentPartsGroup: boolean;
  };
  actions: {
    setCurrentPartsGroup: (
      partsGroup: PartsGroupOptionalDescriptionType | null,
    ) => void;

    setCurrentProductId: (currentProductId: number) => void;

    setCurrentPartClicked: (
      partsGroupItemClicked: PartsGroupItem | null,
    ) => void;

    setCurrentPartIdHover: (partId: number | null) => void;

    addPartsGroupItemsModified: (
      partsGroupItemsModified: PartsGroupItemsModified,
    ) => void;

    setPartsGroupItemsModified: (
      partsGroupItemsModified: PartsGroupItemsModified[],
    ) => void;

    updatePartsGroupItemsModified: (
      partsGroupItem: PartsGroupItemsModified,
      index: number,
    ) => void;

    setCoordinateModified: (coordinate: CoordinatesModified[]) => void;

    addCoordinateDelete: (coordinate: CoordinateToDelete) => void;

    setCoordinateDelete: (coordinates: CoordinateToDelete[]) => void;

    setShouldShowFirstCurrentPartsGroup: (shouldShowFirstCurrentPartsGroup: boolean) => void;

    handleEdit: () => void;

    handleUnedit: () => void;

    modify: () => void;

    clear: () => void;
  };
};

export const usePartsCatalogStore = create<PartsCatalogStoreProps>((set) => {
  const initialState = {
    currentPartsGroup: null,
    currentProductId: null,
    partsGroupItemModified: [],
    currentPartIdHover: null,
    currentPartClicked: null,
    coordinatesModified: [],
    coordinatesToDelete: [],
    canEdit: false,
    isModified: { current: false },
    shouldShowFirstCurrentPartsGroup: false,
  };

  return {
    state: initialState,
    actions: {
      setCurrentPartsGroup: (partsGroup) => {
        set((state) => ({
          ...state,
          state: { ...state.state, currentPartsGroup: partsGroup },
        }));
      },

      setCurrentProductId: (currentProductId) => {
        set((state) => ({
          ...state,
          state: { ...state.state, currentProductId },
        }));
      },

      setCurrentPartClicked: (partsGroupItemClicked) => {
        set((state) => ({
          ...state,
          state: { ...state.state, currentPartClicked: partsGroupItemClicked },
        }));
      },

      setCurrentPartIdHover: (partId) => {
        set((state) => ({
          ...state,
          state: { ...state.state, currentPartIdHover: partId },
        }));
      },

      addPartsGroupItemsModified: (partsGroupItemsModified) => {
        set((state) => ({
          ...state,
          state: {
            ...state.state,
            partsGroupItemModified: [
              ...state.state.partsGroupItemModified,
              partsGroupItemsModified,
            ],
          },
        }));
      },

      setPartsGroupItemsModified: (partsGroupItemsModified) => {
        set((state) => ({
          ...state,
          state: {
            ...state.state,
            partsGroupItemModified: partsGroupItemsModified,
          },
        }));
      },

      updatePartsGroupItemsModified: (partsGroupItemUpdate, indexUpdate) => {
        set((state) => ({
          ...state,
          state: {
            ...state.state,
            partsGroupItemModified: state.state.partsGroupItemModified.map(
              (partsGroupItem, index) => {
                if (indexUpdate === index) {
                  return partsGroupItemUpdate;
                }

                return partsGroupItem;
              },
            ),
          },
        }));
      },

      setCoordinateModified: (coordinatesModified) => {
        set((state) => ({
          ...state,
          state: {
            ...state.state,
            coordinatesModified,
          },
        }));
      },

      addCoordinateDelete: (coordinateDelete) => {
        set((state) => ({
          ...state,
          state: {
            ...state.state,
            coordinatesToDelete: [
              ...state.state.coordinatesToDelete,
              coordinateDelete,
            ],
          },
        }));
      },

      setCoordinateDelete: (coordinatesDelete) => {
        set((state) => ({
          ...state,
          state: {
            ...state.state,
            coordinatesToDelete: coordinatesDelete,
          },
        }));
      },

      setShouldShowFirstCurrentPartsGroup: (
        shouldShowFirstCurrentPartsGroup,
      ) => {
        set((state) => ({
          ...state,
          state: {
            ...state.state,
            shouldShowFirstCurrentPartsGroup: shouldShowFirstCurrentPartsGroup,
          },
        }));
      },

      handleEdit: () => {
        set((state) => ({
          ...state,
          state: {
            ...state.state,
            canEdit: true,
          },
        }));
      },

      handleUnedit: () => {
        set((state) => ({
          ...state,
          state: {
            ...state.state,
            canEdit: false,
          },
        }));
      },

      modify: () => {
        set((state) => ({
          ...state,
          state: {
            ...state.state,
            isModified: { current: true },
          },
        }));
      },

      clear: () => {
        set(() => ({
          state: initialState,
        }));
      },
    },
  };
});
