import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { ZodType } from 'zod';
import { InitialValue, ServiceResponse } from '.';
import { useEffect, useRef } from 'react';
import { useEdit } from '../../../hooks/useEdit';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { useMutation } from 'react-query';
import { AxiosError, isAxiosError } from 'axios';
import { useModal } from '../../../hooks/useModal';
import { useError } from '../../../hooks/useError';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { useProductStore } from '../../../store/product';

type FormData = {
  code: string;
  name: string;
  reviewedApproved: boolean;
  outOfLine: boolean;
  isNew: boolean;
};

type MutationProps = {
  id: number;
  name: string;
  reviewedApproved: boolean;
  outOfLine: boolean;
  isNew: boolean;
  description: string;
};

export const useItemModal = (
  validationSchema: ZodType,
  type: 'product' | 'part',
  queryKey: string,
  onModalClose: () => void,
  saveService: (
    id: number,
    name: string,
    isNew: boolean,
    reviewedApproved: boolean,
    outOfLine: boolean,
    description: string,
  ) => ServiceResponse,
  updateService: (
    id: number,
    name: string,
    isNew: boolean,
    reviewedApproved: boolean,
    outOfLine: boolean,
    description: string,
  ) => ServiceResponse,
  deleteService: (id: number) => ServiceResponse,
  initialValue?: InitialValue,
) => {
  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
  } = useForm<FormData>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      code: '',
      name: '',
    },
  });

  const code = watch('code');
  const name = watch('name');

  const initialValues = useRef(initialValue);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const {
    state: { isUneditable },
    actions: { edit, unedit },
  } = useProductStore();

  useEffect(() => {
    if (!!initialValue?.code && !canEdit) {
      edit();
      return;
    }
    unedit();
  }, [canEdit, edit, initialValue?.code, unedit]);

  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content: '',
    editorProps: {
      // editable: () => !isUneditable,
      attributes: {
        class: 'border p-2 outline-none flex-1 min-h-80',
      },
    },
  });

  const {
    isModalOpen: isConfirmModalOpen,
    handleOpenModal: handleOpenConfirmModal,
    handleCloseModal: handleCloseConfirmModal,
  } = useModal();

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { mutate: saveItem } = useMutation({
    mutationFn: async ({
      id,
      name,
      isNew,
      outOfLine,
      reviewedApproved,
      description,
    }: MutationProps) => {
      const savedItem = (
        await saveService(
          id,
          name,
          isNew,
          reviewedApproved,
          outOfLine,
          description,
        )
      ).data;

      return { ...savedItem, isNew: savedItem.new, description };
    },

    onSuccess: (
      data,
      {
        id,
        name,
        isNew,
        outOfLine,
        reviewedApproved,
        description,
      }: MutationProps,
    ) => {
      const dataCache = {
        id,
        name,
        new: isNew,
        outOfLine,
        reviewedApproved,
        description,
      };
      addItemOnScreen(queryKey, dataCache);
      initialValues.current = {
        code: id.toString(),
        name,
        isNew,
        outOfLine,
        reviewedApproved,
        description,
      };
      onModalClose();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar produto',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Produto já cadastrado');
            return true;
          }
        },
      );
    },
  });

  const { mutate: updateItem } = useMutation({
    mutationFn: async ({
      id,
      name,
      isNew,
      outOfLine,
      reviewedApproved,
      description,
    }: MutationProps) => {
      const updatedItem = (
        await updateService(
          id,
          name,
          isNew,
          reviewedApproved,
          outOfLine,
          description,
        )
      ).data;
      return {
        ...updatedItem,
        isNew: updatedItem.new,
        description: description,
      };
    },

    onSuccess: (
      data,
      { id, name, isNew, outOfLine, reviewedApproved, description },
    ) => {
      const dataCache = {
        id,
        name,
        new: isNew,
        outOfLine,
        reviewedApproved,
        description,
      };
      updateItemOnScreen(queryKey, dataCache);
      // handleCancelButtonClick();
      handleUnedit()
      return;
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar produto',
        setErrorMessage,
      );
    },
  });

  const { mutate: deleteItem } = useMutation({
    mutationFn: async () => {
      if (initialValue?.code) {
        return (await deleteService(+initialValue.code)).data;
      }
    },

    onSuccess: () => {
      if (initialValue?.code) {
        removeItemFromScreen(queryKey, +initialValue.code);
      }
      handleCancelButtonClick();
      handleCloseConfirmModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar produto',
        setErrorMessage,
        () => {
          if (isAxiosError(error) && error.response?.status === 409) {
            setErrorMessage(
              `Est${type === 'part' ? 'a peça' : 'e produto'} só pode ser apagado depois que não houver itens associados a ele`,
            );
            return true;
          }
        },
      );
    },
  });

  useEffect(() => {
    if (initialValue) {
      setValue('code', initialValue.code.toString());
      setValue('name', initialValue.name);
      setValue('isNew', initialValue.isNew);
      setValue('outOfLine', initialValue.outOfLine);
      setValue('reviewedApproved', initialValue.reviewedApproved);
      editor?.commands.setContent(initialValue.description ?? '');
      initialValues.current = initialValue;
    }
  }, [editor, initialValue, setValue, type]);

  const handleButtonOkClick = ({
    code,
    name,
    isNew,
    outOfLine,
    reviewedApproved,
  }: FormData) => {
    if (initialValues.current?.code) {
      updateItem({
        id: +code,
        name,
        isNew,
        outOfLine,
        reviewedApproved,
        description: editor?.getHTML() ?? '',
      });
      return;
    }

    saveItem({
      id: +code,
      name,
      isNew,
      outOfLine,
      reviewedApproved,
      description: editor?.getHTML() ?? '',
    });
  };

  const handleCancelButtonClick = () => {
    setError('code', {});
    setError('name', {});
    initialValues.current = {
      code: '',
      name: '',
      isNew: false,
      outOfLine: false,
      reviewedApproved: false,
    };
    handleUnedit();
    handleClearCodeInput();
    handleClearNameInput();
    onModalClose();
  };

  const handleEditButtonClick = () => {
    handleEdit();
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmModal();
  };

  const handleConfirmDeleteItem = () => {
    deleteItem();
  };

  const handleClearCodeInput = () => {
    setValue('code', '', { shouldDirty: true });
  };

  const handleClearNameInput = () => {
    setValue('name', '', { shouldDirty: true });
  };

  return {
    errors,
    isUneditable,
    isConfirmModalOpen,
    dirtyFields,
    errorMessage,
    editor,
    code,
    name,
    clearError,
    handleClearCodeInput,
    handleClearNameInput,
    handleCloseConfirmModal,
    register,
    handleEditButtonClick,
    handleSubmit,
    handleButtonOkClick,
    handleCancelButtonClick,
    handleDeleteButtonClick,
    handleConfirmDeleteItem,
  };
};
