import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';

export const partsCatalogTableStyle: SxProps<Theme> = {
  '@media (max-width: 1250px)': {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  '@media (max-width: 1024px)': {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
  },
};

export const refCellStyle: SxProps<Theme> = {
  border: 'none',
  height: '1.5rem',
  paddingX: '0.5rem',
  p: 0,
};

export const tableRowSelectedStyle: SxProps<Theme> = {
  backgroundColor: '#eab308',
};
