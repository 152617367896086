import {
  deleteObject,
  ref,
  uploadBytes,
  uploadBytesResumable,
  UploadResult,
} from 'firebase/storage';
import { storage } from '../libs/firebase';
import generatePaginatedUrl, {
  addSearchParamsInUrl,
} from '../utils/generatedPaginatedUrl';
import { api } from './api';
import {
  ReorderSizing,
  ReorderSizingDto,
  SaveSizingsDto,
  Sizing,
  SizingListInPageSort,
  SizingSort,
  UpdateSizingDto,
} from '../types/sizing';

export const getAllSizingsPaginated = async (
  page?: number,
  direction?: 'ASC' | 'DESC',
  limit?: number,
  domain?: string,
) => {
  const url = addSearchParamsInUrl(
    '/sizing/v1/search',
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    { name: 'domain', value: domain },
  );
  const response = await api.get<SizingListInPageSort>(url);
  return response;
};

export const uploadSizingStorage = (itemId: string, file: File) => {
  const storageRef = ref(
    storage,
    `documents/dimensionamentos/${decodeURIComponent(itemId)}`,
  );
  const response = uploadBytesResumable(storageRef, file);
  return response;
};

export const uploadSizingsStorage = async (
  files: FileList,
  saveSizing: () => void | Promise<void>,
  fileName?: string | null,
) => {
  try {
    const promises: Promise<UploadResult>[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const storageRef = ref(
        storage,
        `documents/dimensionamentos/${decodeURIComponent(fileName ?? file.name)}`,
      );
      const response = uploadBytes(storageRef, file);
      promises.push(response);
    }

    await Promise.all(promises);
    await saveSizing();
  } catch (error) {
    const promises: Promise<string | undefined>[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const response = deleteSizingStorage(fileName ?? file.name);
      promises.push(response);
    }

    await Promise.all(promises);

    throw error;
  }
};

export const saveSizing = async ({ name, url }: SaveSizingsDto) => {
  const body = { name, url };

  const response = await api.post<Sizing>('/sizing/v1', body);
  return response;
};

export const saveSizings = async (dto: SaveSizingsDto[]) => {
  const body = { sizings: dto };

  const response = await api.post<void>('/sizing/v1/batch', body);
  return response;
};

export const updateSizing = async ({ id, name, url }: UpdateSizingDto) => {
  const body = { id, name, url };

  const response = await api.put<SizingSort>('/sizing/v1', body);
  return response;
};

export const deleteSizingStorage = async (sizingReference: string) => {
  const sizingRef = ref(
    storage,
    `documents/dimensionamentos/${decodeURIComponent(sizingReference)}`,
  );
  try {
    await deleteObject(sizingRef);
  } catch (error) {
    return `Falha ao deletar dimensionamento. ${JSON.stringify(error)}`;
  }
};

export const deleteSizing = async (sizingId: number) => {
  const response = await api.delete(`/sizing/v1/${sizingId}`);
  return response;
};

export const reorderSizing = async (dto: ReorderSizingDto[]) => {
  const response = await api.put<ReorderSizing>(`/sizing/v1/reorder`, {
    sizingList: dto,
  });
  return response;
};
