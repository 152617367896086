import {
  CreateLineDto,
  FindAllLinesWithCategory,
  Line,
  Lines,
  ReorderLines,
  ReorderLinesDto,
  SortLineBy,
  UpdateLineDto,
} from '../types/lines';
import { getCurrentDomain } from '../utils/domain';
import { api } from './api';

export const getAllLines = async (
  sortBy: SortLineBy | null = { sortBy: 'name' },
) => {
  const response = await api.get<Lines>(`/lines/v1?sortBy=${sortBy?.sortBy}`);
  return response;
};

export const getAllLinesWithCategories = async (domainId: number) => {
  const response = await api.get<FindAllLinesWithCategory>(
    `lines/v1/line-category/${domainId}`,
  );
  return response;
};

export const saveLine = async ({
  name,
  carouselImageName,
  menuImageName,
}: CreateLineDto) => {
  const body = {
    name,
    carouselImageName,
    menuImageName,
  };

  const response = await api.post<Line>('/lines/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const updateLine = async ({
  id,
  name,
  carouselImageName,
  menuImageName,
}: UpdateLineDto) => {
  const body = {
    id,
    name,
    carouselImageName,
    menuImageName,
  };

  const response = await api.put<Line>('/lines/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const deleteLine = async (lineId: number) => {
  const response = await api.delete<Line>(`/lines/v1/${lineId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const getAllImagesOfLines = async (
  domainId: number = getCurrentDomain().id, // domínio fixo como 1 (buffalo) por enquanto
) => {
  const response = await api.get<Lines>(
    `/lines/v1/images?domainId=${domainId}`,
  );

  return response;
};

export const reorderLines = async (dto: ReorderLinesDto[]) => {
  const response = await api.put<ReorderLines>(`/lines/v1/reorder`, {
    lines: dto,
  });
  return response;
};
