import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import DocumentContent from '../../../components/DocumentContent';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import ProductsVinculatedInDocument from '../../../components/ProductsVinculatedInDocument';
import { documentsCache } from '../../../constants/requestCacheName';
import { getAllDocumentsPaginated } from '../../../services/documents';
import { useUploadDocument } from './useUploadDocument';
import FileUploadIcon from '@mui/icons-material/FileUpload';

export default function UploadDocument() {
  const {
    isMenageModalOpen,
    documentNameUpdate,
    isConfirmDeleteModalOpen,
    previewDocument,
    documentTypes,
    dirtyFields,
    errors,
    errorMessage,
    isDocumentLoading,
    isDocumentDeleteLoading,
    isShowEditButton,
    reference,
    documentIdUpdate,
    files,
    currentDocumentId,
    handleEdit,
    handleConfirmDeleteDocument,
    handleCloseConfirmDeleteModal,
    clearError,
    handleCancelIconClick,
    handleSubmit,
    register,
    handleButtonOkClick,
    handleDeleteButtonClick,
    handleCloseMenageModal,
    handleTableRowClick,
    handleAddImageClick,
  } = useUploadDocument();

  return (
    <>
      <DocumentContent
        queryKey={documentsCache}
        documentService={(typeDocument) =>
          getAllDocumentsPaginated(null, null, null, typeDocument)
        }
        onTableRowClick={handleTableRowClick}
        onAddDocumentClick={handleAddImageClick}
      />

      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseMenageModal}
        cancelIcon={false}
        height="100vh"
        contentStyle="overflow-y-auto pb-16"
      >
        <HeaderModalManageItem
          showDeleteButton={!!documentIdUpdate}
          deleteButtonContent={
            isDocumentDeleteLoading ? (
              <div>
                <Loading />
              </div>
            ) : (
              'Apagar documento'
            )
          }
          isEditable={!isShowEditButton}
          onDeleteButtonClick={
            !isDocumentDeleteLoading ? handleDeleteButtonClick : undefined
          }
          title={documentNameUpdate ? 'Documento' : 'Novo documento'}
          // deleteButtonClassName="relative bottom-[3.75rem]"
          mainButtonContent={
            isDocumentLoading ? (
              <div className="absolute bottom-6 right-4 top-3">
                <Loading />
              </div>
            ) : isShowEditButton ? (
              'Editar'
            ) : (
              'Ok'
            )
          }
          onEditClick={isShowEditButton ? handleEdit : undefined}
          onCancelClick={handleCloseMenageModal}
          onOkClick={
            !isDocumentLoading ? handleSubmit(handleButtonOkClick) : undefined
          }
        />

        <div className="bottom-14 mb-4 mt-14 flex flex-col gap-8">
          <Input
            isDirty={dirtyFields.reference}
            type="text"
            id="reference"
            label="Nome do documento"
            disabled={isShowEditButton || files?.length > 1}
            helperText={errors.reference?.message}
            onCancelIconClick={handleCancelIconClick}
            {...register('reference')}
            value={reference}
          />
          <div className="w-full">
            <label className='text-xs'>Tipo de documento</label>
            <select
              className="w-full border-b border-solid outline-none"
              disabled={isShowEditButton}
              {...register('type')}
            >
              {documentTypes?.items.map((imageType) => (
                <option key={imageType.id} value={imageType.name}>
                  {imageType.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              data-preview={!!previewDocument}
              className="flex h-[350px] w-full cursor-pointer justify-center rounded-lg data-[preview=false]:items-center data-[preview=false]:bg-backgroundPrimary"
              htmlFor="input-file"
            >
              {(files?.length <= 1 || !files) && (
                <>
                  {previewDocument ? (
                    <div className="h-full w-full">
                      <iframe
                        src={previewDocument}
                        title="PDF Preview"
                        className="h-full w-full"
                      />
                    </div>
                  ) : (
                    <FileUploadIcon
                      fontSize="large"
                      className="absolute cursor-pointer text-secondaryText"
                    />
                  )}
                </>
              )}

              {files?.length > 1 && (
                <ul className="flex size-full max-h-[300px] flex-col gap-4 overflow-auto px-4">
                  {files.map((file) => (
                    <li key={file.name}>{file.name}</li>
                  ))}
                </ul>
                // <CheckIcon className="text-green-500" fontSize="large" />
              )}

              <input
                type="file"
                id="input-file"
                accept="application/pdf"
                multiple
                {...register('document')}
                disabled={isShowEditButton}
                className="absolute flex cursor-pointer opacity-0 enabled:h-[350px] enabled:w-full"
              />
            </label>
            <span className="text-xs text-red-600">
              {errors.document?.message}
            </span>
          </div>
        </div>
        <ProductsVinculatedInDocument documentId={currentDocumentId} />
      </Modal>

      <ConfirmModal
        contentMessage="Deseja excluir esse documento?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDeleteDocument}
        onNotConfirm={handleCloseConfirmDeleteModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
