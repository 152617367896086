import { Line, Lines } from '../../../types/lines';
import { Link } from 'react-router-dom';
import { ImageTypes } from '../../../enums/imageTypes';
import {
  getImageFromApi,
  verifyImageUrlExists,
} from '../../../utils/getImageUrl';
import CardSkeleton from '../CardSkeleton';
import { useEffect, useState } from 'react';
import { useLinesNavbar } from '../LinesNavbarBuffalo/useLinesNavbar';

export type MenuImagesProps = {
  isImageLoading: boolean;
  linesShown: Lines | undefined;
  handleImageWasLoaded: () => void;
  handleLoadStart: () => void;
};

export default function MenuImages({
  linesShown,
  handleImageWasLoaded,
  isImageLoading,
  handleLoadStart,
}: MenuImagesProps) {
  const [linesShownWithMenuImage, setLinesShownWithMenuImage] = useState<
    Line[]
  >([]);

  const { lines } = useLinesNavbar();

  useEffect(() => {
    const fetchLinesWithValidImages = async () => {
      if (!linesShown?.items) return;

      const validLines = await Promise.all(
        linesShown.items.map(async (line) => {
          const isInNavbarLines = lines.some(
            (navbarLine) => navbarLine.id === line.id,
          );
          if (!isInNavbarLines) return null;

          const isValid =
            !!line.menuImage &&
            (await verifyImageUrlExists(
              getImageFromApi(line.menuImage, ImageTypes.linesMenu),
            ));
          return isValid ? line : null;
        }),
      );

      const filteredLines = validLines.filter(
        (line): line is Line => line !== null,
      );

      setLinesShownWithMenuImage(filteredLines);
      handleImageWasLoaded();
    };

    fetchLinesWithValidImages();
  }, [handleImageWasLoaded, lines, linesShown?.items]);

  return (
    <>
      {linesShownWithMenuImage?.length > 0 && (
        <div className="bg-backgroundPrimaryBuffalo">
          <div className="mx-11 pb-10 pt-4">
            <div className="flex flex-wrap">
              <h2 className="mb-5 text-2xl font-medium text-white">
                Conheça Nossa Linha de Produtos
                <span className="text-4xl text-yellow-500">.</span>
              </h2>
            </div>
            <div className="flex flex-wrap items-center justify-center gap-5">
              {linesShownWithMenuImage?.map((line, index) => (
                <Link
                  key={`${line.id}-${index}`}
                  to={{
                    pathname: `/produtos`,
                    search: `line=${line.id}`,
                  }}
                >
                  {isImageLoading && <CardSkeleton />}

                  <div className="flex h-[180px] w-[250px] flex-col items-center justify-center rounded-2xl bg-white p-4 text-center">
                    <div className="flex h-[78px] w-[110px] flex-1 items-center">
                      <img
                        src={getImageFromApi(
                          line.menuImage,
                          ImageTypes.linesMenu,
                        )}
                        alt={`Imagem do ${line.name}`}
                        onLoadStart={handleLoadStart}
                        onLoad={handleImageWasLoaded}
                        className="mx-auto max-h-[78px] max-w-[110px] md:block"
                        // width={110}
                        // height={78}
                      />
                    </div>

                    <p className="mt-2 text-xl font-semibold text-black">
                      {line.name}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
