import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CSSProperties } from 'react';

export const appBarStyle: CSSProperties = {
  backgroundColor: '#F2F2F2',
  boxShadow: '0',
  borderBottom: '1px solid #E9E9E9',
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 30,
};

export const uploadImageContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: '1rem',
};

export const uploadImageStyle: CSSProperties = {
  borderRadius: '100%',
  padding: '0.75rem',
  color: grey['200'],
  display: 'flex',
};

export const mainStyle: SxProps<Theme> = {
  padding: '1rem 2rem',
};

export const cardsList: SxProps<Theme> = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, 25rem)',
  justifyContent: 'center',
  gap: '1rem',
};

export const modalTitleStyle: SxProps<Theme> = {
  textAlign: 'center',
  marginBottom: '2rem',
};
