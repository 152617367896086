import { useMutation, useQueryClient } from 'react-query';
import {
  PartsGroupByProductIdApiCache,
  partsByProductIdPartsGroupIdApiCache,
  partsGroupsCoordinateCache,
} from '../../constants/requestCacheName';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Coordinate,
  FindAllCoordinates,
  PureCoordinatesModified,
} from '../../types/partsGroupCoordinate';
import { useSearchParams } from '../../hooks/useSearchParams';
import { useTitle } from '../../hooks/useTitle';
import {
  deletePartsGroupItem,
  saveUpdatePartsGroupItem,
} from '../../services/partsGroupItem';
import {
  FindAllPartsGroupItem,
  PartsGroupItem,
  PartsGroupItemsDelete,
  PartsGroupItemsModified,
} from '../../types/partsGroupItem';
import { useQueryCache } from '../../hooks/useQueryCache';
import { Part } from '../../types/parts';
import { usePartsCatalogStore } from '../../store/partsCatalog';
import {
  deleteCoordinates,
  saveUpdateCoordinate,
} from '../../services/partsGroupItemCoordinate';
import { useModal } from '../../hooks/useModal';
import generateProductPartsCatalogPdf from '../../pdf/productPartsCatalogPdf/generateProductPartsCatalogPdf';
import { getAuth } from '../../utils/auth';
import { useError } from '../../hooks/useError';
import { mutationErrorHandling } from '../../utils/errorHandling';

export const usePartsCatalag = () => {
  useTitle('Catalogo de peças');

  const { addItemOnScreen, overrideCache, removeItemFromScreen } =
    useQueryCache();

  const { productId } = useParams();
  const [partId, product, previusVersion] = useSearchParams(
    'partId',
    'product',
    'previus-version',
  );

  const navigate = useNavigate();

  const coordinatesModifiedPartsGroupItemsCopy = useRef<
    PureCoordinatesModified[]
  >([]);

  const initialPartsGroupItemsDelete = {
    parts: [],
    partsGroup: [],
  };
  const partsGroupItemsDelete = useRef<PartsGroupItemsDelete>(
    initialPartsGroupItemsDelete,
  );

  const {
    state: {
      currentPartsGroup,
      currentPartIdHover,
      partsGroupItemModified,
      coordinatesModified,
      coordinatesToDelete,
      canEdit,
      isModified,
    },
    actions: {
      setCoordinateModified,
      setCurrentProductId,
      setCurrentPartClicked,
      setCurrentPartIdHover,
      addPartsGroupItemsModified,
      updatePartsGroupItemsModified,
      setPartsGroupItemsModified,
      handleEdit,
      handleUnedit,
      setCoordinateDelete,
      clear,
    },
  } = usePartsCatalogStore();

  const queryClient = useQueryClient();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const getCurrentParts = useCallback(() => {
    const currentParts = queryClient.getQueryData<FindAllPartsGroupItem>(
      `${partsByProductIdPartsGroupIdApiCache}${currentPartsGroup?.id}`,
    );

    return currentParts;
  }, [currentPartsGroup?.id, queryClient]);

  const { mutate: saveUpdatePartsGroupItemMutate } = useMutation({
    mutationFn: async () => {
      return (
        await saveUpdatePartsGroupItem(
          partsGroupItemModified,
          coordinatesModifiedPartsGroupItemsCopy.current,
        )
      ).data;
    },

    onSuccess: () => {
      deletePartsGroupItemsMutate();
      setPartsGroupItemsModified([]);
      coordinatesModifiedPartsGroupItemsCopy.current = [];
      handleUnedit();
    },

    onError: (error) => {
      deletePartsGroupItemsMutate();
      mutationErrorHandling(
        error,
        'Falha ao realizar as atualizações',
        setErrorMessage,
      );
    },
  });

  const { mutate: deletePartsGroupItemsMutate } = useMutation({
    mutationFn: async () => {
      return (await deletePartsGroupItem(partsGroupItemsDelete.current)).data;
    },

    onSuccess: () => {
      partsGroupItemsDelete.current = initialPartsGroupItemsDelete;
      handleUnedit();
    },

    onError: (error) => {
      mutationErrorHandling(error, 'Falha ao deletar', setErrorMessage);
    },
  });

  const { mutate: saveUpdateCoordinatesMutate } = useMutation({
    mutationFn: async () => {
      return (await saveUpdateCoordinate(coordinatesModified, +productId!))
        .data;
    },

    onSuccess: () => {
      // TODO Instável: Atualiza as coordenadas modificadas ou adicionadas em tela
      const partsGroupImagesCoordinates =
        queryClient.getQueryData<FindAllCoordinates>(
          `${partsGroupsCoordinateCache}${currentPartsGroup?.id}`,
        );

      const coordinatesFiltered = partsGroupImagesCoordinates?.items.filter(
        (coordinate) => {
          return !coordinatesModified.some(
            (coordinateModified) =>
              coordinateModified.id ===
              `${coordinate.part.id}${coordinate.x}${coordinate.y}`,
          );
        },
      );

      coordinatesModified.forEach((coordinate) => {
        coordinatesFiltered?.push({
          height: coordinate.height,
          width: coordinate.width,
          x: coordinate.x,
          y: coordinate.y,
          part: {
            id: coordinate.partId,
          },
          product: {
            id: +(productId ?? 0),
          },
          partsGroup: {
            id: coordinate.partGroupId,
          },
        });

        overrideCache<FindAllCoordinates>(
          `${partsGroupsCoordinateCache}${currentPartsGroup?.id}`,
          {
            items: coordinatesFiltered ?? [],
            meta: {
              totalItems: 0,
            },
          },
        );
      });
      // Instável ^^^

      handleUnedit();
      setCoordinateModified([]);
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar as coordenadas',
        setErrorMessage,
      );
    },
  });

  const { mutate: deleteCoordinatesMutate } = useMutation({
    mutationFn: async () => {
      return (await deleteCoordinates(coordinatesToDelete)).data;
    },

    onSuccess: () => {
      setCoordinateDelete([]);
      handleUnedit();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar coordenadas',
        setErrorMessage,
      );
    },
  });

  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const {
    isModalOpen: isConfirmModifiedModalOpen,
    handleCloseModal: handleCloseModifiedModal,
    handleOpenModal: handleOpenModifiedModal,
  } = useModal();

  const handlePartMouseEnter = useCallback(
    (partId: number) => {
      setCurrentPartIdHover(partId);
    },
    [setCurrentPartIdHover],
  );

  const handleClickPart = useCallback(
    (partId: number) => {
      const currentParts = getCurrentParts();
      if (currentParts) {
        const part =
          currentParts?.items.find(({ part }) => part?.id === partId) ?? null;
        setCurrentPartClicked(part);
        handlePartMouseEnter(partId);
      }
    },
    [getCurrentParts, handlePartMouseEnter, setCurrentPartClicked],
  );

  const handleGeneratePdf = async () => {
    setIsPdfLoading(true);
    try {
      await generateProductPartsCatalogPdf(+productId!, setErrorMessage);
    } catch (error) {
      if (
        typeof error === 'string' &&
        error
          .toLowerCase()
          .includes('open pdf in new window blocked by browser')
      ) {
        setErrorMessage(
          'O navegador bloqueou o PDF. Desative a opção Bloquear Pop-ups e tente gerar novamente',
        );
        return;
      }

      setErrorMessage('Ocorreu um erro ao gerar o PDF');
    } finally {
      setIsPdfLoading(false);
    }
  };

  useEffect(() => {
    if (productId) {
      setCurrentProductId(+productId);
    }
  }, [productId, setCurrentProductId]);

  useEffect(() => {
    if (partId) {
      handleClickPart(+partId);
    }
  }, [currentPartsGroup, handleClickPart, partId]);

  const handlePartMouseOut = () => {
    setCurrentPartIdHover(null);
  };

  const handleBackButtonClick = () => {
    if (isModified.current) {
      handleOpenModifiedModal();
      return;
    }
    handleConfirmBack();
  };

  const handleConfirmBack = () => {
    clear();
      const previousUrl = sessionStorage.getItem('previousUrl');
      if (previousUrl) {
        navigate(previousUrl);
      } else {
        navigate(-1);
      }
  };

  const handleEditButtonClick = () => {
    handleEdit();
  };

  const handleOkButtonClick = () => {
    let hasRequest = false;
    let hasPartsGrouItem = false;

    if (partsGroupItemModified.length > 0) {
      hasRequest = true;
      hasPartsGrouItem = true;
      saveUpdatePartsGroupItemMutate();
    }

    const { parts, partsGroup } = partsGroupItemsDelete.current;

    if ((parts.length > 0 || partsGroup.length > 0) && !hasPartsGrouItem) {
      hasRequest = true;
      deletePartsGroupItemsMutate();
    }

    if (coordinatesModified.length > 0) {
      hasRequest = true;
      saveUpdateCoordinatesMutate();
    }

    if (coordinatesToDelete.length > 0) {
      hasRequest = true;
      deleteCoordinatesMutate();
    }

    if (!hasRequest) {
      handleUnedit();
    }
  };

  const handleSetCurrentProductId = (productId: number) => {
    setCurrentProductId(productId);
  };

  const handleSelectPart = (
    { id, name }: Part,
    previousPartsGroupItem?: PartsGroupItem | null,
  ) => {
    const currentParts = getCurrentParts();

    if (productId && currentPartsGroup?.id) {
      if (previousPartsGroupItem) {
        let partGroupIsInArray = false;
        partsGroupItemModified.forEach((partsGroupItem) => {
          if (partsGroupItem.partId === previousPartsGroupItem.part.id) {
            partsGroupItem.partId = id;
            partsGroupItem.previousPartId = previousPartsGroupItem.part.id;
            partGroupIsInArray = true;
          }
        });

        const { amount, order, tag, part } = previousPartsGroupItem;
        if (!partGroupIsInArray) {
          const partsGrouItem: PartsGroupItemsModified = {
            partId: id,
            productId: +productId,
            partsGroupId: currentPartsGroup.id,
            amount,
            partName: name,
            order,
            tag,
            previousPartId: part.id,
          };
          addPartsGroupItemsModified(partsGrouItem);
        }

        if (currentParts) {
          const cache: FindAllPartsGroupItem = {
            ...currentParts,
            items: currentParts.items.map((item) => {
              if (item.part.id === previousPartsGroupItem.part.id) {
                item.part = {
                  id,
                  name,
                };
              }

              return item;
            }),
          };

          overrideCache(
            `${partsByProductIdPartsGroupIdApiCache}${currentPartsGroup?.id}`,
            cache,
          );
        }

        return;
      }

      const partsGrouItem: PartsGroupItemsModified = {
        partId: id,
        productId: +productId,
        partsGroupId: currentPartsGroup.id,
        amount: 1,
        partName: name,
        order: (currentParts?.items.at(-1)?.order ?? 0) + 1,
        tag: '',
      };

      addPartsGroupItemsModified(partsGrouItem);

      const cache: PartsGroupItem = {
        ...partsGrouItem,
        part: { id, name },
        product: { id: +productId },
        partsGroup: { id: currentPartsGroup.id },
      };

      addItemOnScreen<FindAllPartsGroupItem>(
        `${partsByProductIdPartsGroupIdApiCache}${currentPartsGroup?.id}`,
        cache,
        true,
      );
    }
  };

  const handleEditPart = ({
    part,
    amount,
    order,
    partsGroup,
    product,
    tag,
  }: PartsGroupItem) => {
    let partGroupIsInArray = false;
    const updatedPartsGroupItem: PartsGroupItemsModified = {
      amount,
      order,
      partId: part.id,
      partsGroupId: partsGroup.id,
      partName: part.name ?? '',
      productId: product.id,
      tag,
    };

    partsGroupItemModified.forEach((partsGroupItem, index) => {
      if (
        partsGroupItem.partId === part.id &&
        partsGroupItem.partsGroupId === partsGroup.id
      ) {
        partGroupIsInArray = true;
        updatePartsGroupItemsModified(updatedPartsGroupItem, index);
      }
    });

    if (!partGroupIsInArray) {
      addPartsGroupItemsModified(updatedPartsGroupItem);
    }
  };

  const handleDragPartEnd = (partsGroupItems: PartsGroupItem[]) => {
    const filteredItemsModified = partsGroupItemModified.filter((modified) => {
      let isReturn = true;
      partsGroupItems.forEach(({ part, partsGroup }) => {
        if (
          part.id === modified.partId &&
          partsGroup.id === modified.partsGroupId
        ) {
          isReturn = false;
        }
      });

      return isReturn;
    });

    const partsGroupItemsToModified: PartsGroupItemsModified[] =
      partsGroupItems.map(
        ({ amount, order, part, partsGroup, product, tag }) => ({
          amount,
          order,
          partId: part.id,
          partsGroupId: partsGroup.id,
          partName: part.name ?? '',
          productId: product.id,
          tag,
        }),
      );

    setPartsGroupItemsModified([
      ...filteredItemsModified,
      ...partsGroupItemsToModified,
    ]);

    //olhar
    partsGroupItems.forEach(({ part, partsGroup, order }) => {
      partsGroupItemModified.forEach((modified, index) => {
        if (
          part.id === modified.partId &&
          partsGroup.id === modified.partsGroupId
        ) {
          partsGroupItemModified[index].order = order;
        }
      });
    });
  };

  const handleCopyAllPartsGroup = useCallback(
    (partsGroup: PartsGroupItem[], coordinates: Coordinate[]) => {
      setPartsGroupItemsModified(
        partsGroup.map(({ amount, order, part, partsGroup, tag }) => ({
          amount,
          order,
          tag,
          partName: part.name ?? '',
          partId: part.id,
          partsGroupId: partsGroup.id,
          productId: +productId!,
        })),
      );
      coordinatesModifiedPartsGroupItemsCopy.current = coordinates.map(
        ({ height, part, partsGroup, width, x, y }) => ({
          partId: part.id,
          partsGroupId: partsGroup.id,
          productId: +productId!,
          x,
          y,
          height,
          width,
        }),
      );
    },
    [productId, setPartsGroupItemsModified],
  );

  const handleDeletePartGroup = (partsGroupId: number) => {
    // TODO Instável trocado currentProductId por productId dentro dessa função,
    // pois currentProductId pode vir o ID do produto copiado

    if (productId) {
      partsGroupItemsDelete.current?.partsGroup.push({
        partsGroupId,
        productId: +productId,
      });
      removeItemFromScreen(
        `${PartsGroupByProductIdApiCache}${productId}`,
        partsGroupId,
      );
    }
  };

  const handleDeletePart = (partId: number) => {
    // TODO Instável trocado currentProductId por productId dentro dessa função,
    // pois currentProductId pode vir o ID do produto copiado

    const currentParts = getCurrentParts();

    if (productId && currentPartsGroup) {
      partsGroupItemsDelete.current?.parts.push({
        partId,
        partsGroupId: currentPartsGroup?.id,
        productId: +productId,
      });

      if (currentParts) {
        const filteredParts = currentParts.items.filter(
          (partsGroupItem) => partsGroupItem.part.id !== partId,
        );

        overrideCache<FindAllPartsGroupItem>(
          `${partsByProductIdPartsGroupIdApiCache}${currentPartsGroup.id}`,
          {
            ...currentParts,
            items: filteredParts,
          },
        );

        const currentCoordinates = queryClient.getQueryData<FindAllCoordinates>(
          `${partsGroupsCoordinateCache}${currentPartsGroup?.id}`,
        );

        const filteredCoordinates = currentCoordinates?.items.filter(
          (cooridinate) => cooridinate.part.id !== partId,
        );

        overrideCache<FindAllCoordinates>(
          `${partsGroupsCoordinateCache}${currentPartsGroup?.id}`,
          {
            items: filteredCoordinates ?? [],
            meta: {
              totalItems: currentCoordinates?.meta.totalItems ?? 0,
            },
          },
        );
      }
    }
  };

  const productName = product ?? 'Produto indisponível';

  const explodedViewImageName = currentPartsGroup?.image;

  const auth = getAuth();

  const [previusVersionmessage, setPreviusVersionMessage] = useState('');

  useEffect(() => {
    if (previusVersion !== null) {
      setPreviusVersionMessage(
        'Este produto possui versão anteriores com outras peças, por favor preste atenção!!',
      );
    }
  }, [previusVersion]);

  const clearPreviusMessage = () => {
    setPreviusVersionMessage('');
  };

  return {
    currentPartIdHover,
    previusVersion,
    currentParts: getCurrentParts(),
    canEdit,
    productId,
    currentPartsGroup,
    productName,
    explodedViewImageName,
    isPdfLoading,
    partsGroupItemsModified: partsGroupItemModified,
    isConfirmModifiedModalOpen,
    auth,
    errorMessage,
    previusVersionmessage,
    clearPreviusMessage,
    clearError,
    handleConfirmBack,
    handleCloseModifiedModal,
    handleDeletePart,
    handleDeletePartGroup,
    handleSetCurrentProductId,
    handleCopyAllPartsGroup,
    handleDragPartEnd,
    handleEditPart,
    handleSelectPart,
    handleEditButtonClick,
    handleOkButtonClick,
    handlePartMouseOut,
    handlePartMouseEnter,
    handleClickPart,
    handleGeneratePdf,
    handleBackButtonClick,
  };
};
