import SubjectManageModal from '../../../components/Admin/SubjectManageModal';
import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import SubjectContent from '../../../components/SubjectContent';
import { subjectSearchBaseEndpoint } from '../../../constants/endpoints';
import { useSubject } from './useSubject';

export default function Subject() {
  const {
    isMenageModalOpen,
    currentSubjectId,
    isConfirmModalOpen,
    nameOfSubject,
    isShowEditButton,
    errors,
    dirtyFields,
    errorMessage,
    clearError,
    handleOpenSelectModal,
    handleClearSubject,
    handleConfirmDelete,
    handleCloseConfirmModal,
    handleEdit,
    handleButtonOkClick,
    register,
    handleSubmit,
    handleDeleteButtonClick,
    handleCloseMenageModal,
    handleTableRowClick,
    handleAddSubjectClick,
  } = useSubject();

  return (
    <>
      <SubjectContent
        handleTableRowClick={handleTableRowClick}
        handleAddSubjectClick={handleAddSubjectClick}
        baseUrl={subjectSearchBaseEndpoint}
      />

      <SubjectManageModal
        currentSubjectId={currentSubjectId}
        dirtyFields={dirtyFields}
        errors={errors}
        handleClearSubject={handleClearSubject}
        handleCloseMenageModal={handleCloseMenageModal}
        handleDeleteButtonClick={handleDeleteButtonClick}
        handleEdit={handleEdit}
        handleOpenSelectModal={handleOpenSelectModal}
        isMenageModalOpen={isMenageModalOpen}
        isShowEditButton={isShowEditButton}
        onOkClick={handleSubmit(handleButtonOkClick)}
        register={register}
        name={nameOfSubject}
      />

      <ConfirmModal
        contentMessage="Deseja excluir este assunto ?"
        isModalOpen={isConfirmModalOpen}
        onCloseModal={handleCloseConfirmModal}
        onConfirm={handleConfirmDelete}
        onNotConfirm={handleCloseConfirmModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
