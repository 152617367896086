import { Box, BoxProps, ListItem, MenuItem, Select } from '@mui/material';
import { partsGroupContainerStyle, selectPartsGroupStyle } from './styles';
import { usePartsGroup } from './usePartsGroup';
import Modal from '../Modal';
import PartsGroupContent from '../PartsGroupContent';
import ProductsContent from '../ProductsContent';
import { PartsGroupItem } from '../../types/partsGroupItem';
import ConfirmModal from '../ConfirmModal';
import { Coordinate } from '../../types/partsGroupCoordinate';
import AddButton from '../AddButton';
import RemoveButton from '../RemoveButton';

type PartsGroupProps = BoxProps & {
  canEdit: boolean;
  currentPartGroupId?: number;
  onDeletePartsGroup: (partsGroupId: number) => void;
  onSetCurrentProductId: (productId: number) => void;
  handleCopyAllPartsGroup: (
    partsGroupItems: PartsGroupItem[],
    coordinates: Coordinate[],
  ) => void;
};

export default function PartsGroup({
  canEdit,
  currentPartGroupId,
  onDeletePartsGroup,
  onSetCurrentProductId,
  handleCopyAllPartsGroup,
}: PartsGroupProps) {
  const {
    isMobile,
    selectedPartGroup,
    currentPartsGroupToDeleteId,
    isSelectPartsGroupModalOpen,
    partsGroupInUse,
    isSelectProductModalOpen,
    isDeleteAllModalOpen,
    productName,
    partsGroup,
    productId,
    handleSelectPartsGroup,
    handleConfirmDeleteAllPartsGroups,
    handleCloseDeleteAllModal,
    handleButtonDeleteAllClick,
    handleClickPartsGroup,
    handleCopyFromProductButtonClick,
    handleCloseSelectProductModal,
    handleSelectProduct,
    handleAddPartsGroupClick,
    handleCloseSelectPartsGroupModal,
    handleRemoveIconClick,
    handleSelectPartGroup,
  } = usePartsGroup(
    handleCopyAllPartsGroup,
    onSetCurrentProductId,
    currentPartGroupId,
  );

  return (
    <Box component="section" sx={partsGroupContainerStyle}>
      <h3
        className={`text-zinc-500 ${!isMobile ? 'my-4 xl:fixed xl:z-10 xl:w-[600px]' : ''}`}
      >
        {isMobile ? 'Conj. de peças' : 'Conjuntos de Peças'}
      </h3>
      <div className="pt-14" />

      {canEdit && (
        <div className="mb-4">
          <div className="mb-4 rounded text-sm text-primary">
            <button
              onClick={handleCopyFromProductButtonClick}
              disabled={partsGroup && partsGroup.items.length > 0}
              className="cursor-pointer hover:underline disabled:cursor-default disabled:no-underline disabled:opacity-60"
            >
              Copiar de outro produto
            </button>
          </div>
          <div className="rounded text-sm text-errorText">
            <button
              onClick={handleButtonDeleteAllClick}
              disabled={partsGroup && partsGroup.items.length <= 0}
              className="cursor-pointer hover:underline disabled:cursor-default disabled:no-underline disabled:opacity-60"
            >
              Apagar todos
            </button>
          </div>
        </div>
      )}
      {isMobile ? (
        <Select
          value={selectedPartGroup}
          onChange={handleSelectPartGroup}
          sx={selectPartsGroupStyle}
        >
          {partsGroup?.items.map((partGroup) => (
            <MenuItem
              value={partGroup.name}
              key={partGroup.id}
              onClick={() => handleClickPartsGroup(partGroup)}
              sx={{ width: '200vw' }}
            >
              {partGroup.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <>
          <div className="mb-2 max-h-[calc(100vh-22rem)] overflow-auto">
            <ul className="flex flex-col gap-1 max-xl:flex-1">
              {partsGroup?.items.map((partGroup) => (
                <li
                  key={partGroup.id}
                  className="flex items-center overflow-x-hidden"
                >
                  {canEdit && (
                    <RemoveButton
                      className="mr-1"
                      onClick={() => handleRemoveIconClick(partGroup.id)}
                    />
                  )}
                  <div className="flex flex-1">
                    <ListItem
                      onClick={() => handleClickPartsGroup(partGroup)}
                      sx={{
                        justifyContent: 'center',
                        py: '0.25rem',
                        textAlign: 'center',
                      }}
                      className={`ease flex-1 cursor-pointer rounded-xl border-[1px] hover:border-primary ${partGroup.id === currentPartGroupId ? 'bg-primary text-zinc-100' : ''}`}
                    >
                      {partGroup.name}
                    </ListItem>
                    {canEdit && (
                      <button
                        onClick={() => onDeletePartsGroup(partGroup.id)}
                        className={`transition-slow border-[1px] border-errorText bg-errorText text-zinc-100 ${currentPartsGroupToDeleteId === partGroup.id ? 'w-20 py-1 opacity-100' : 'w-0 opacity-0'}`}
                      >
                        Apagar
                      </button>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {canEdit && (
            <AddButton
              content="Adicionar conjunto de peças"
              onClick={handleAddPartsGroupClick}
            />
          )}
        </>
      )}
      <Modal
        isOpen={isSelectPartsGroupModalOpen}
        onClose={handleCloseSelectPartsGroupModal}
        width="50rem"
        contentStyle="h-[100vh]"
      >
        <PartsGroupContent
          onTableRowClick={handleSelectPartsGroup}
          alreadyInUseList={partsGroupInUse}
          isModal
        />
      </Modal>
      <Modal
        isOpen={isSelectProductModalOpen}
        onClose={handleCloseSelectProductModal}
        width="91vw"
        contentStyle="p-0"
      >
        <div className="h-[99vh]">
          <ProductsContent
            onTableRowClick={handleSelectProduct}
            isModal
            itemsInList={productId ? [{ id: +productId }] : []}
            shouldFilter={false}
          />
        </div>
      </Modal>
      <ConfirmModal
        isModalOpen={isDeleteAllModalOpen}
        onCloseModal={handleCloseDeleteAllModal}
        contentMessage={`Deseja excluir todos todos os conjuntos de peças ${productName ? 'do' : 'desse'} produto ${productName ?? ''}? Ao aceitar, não será possível desfazer essa operação`}
        onNotConfirm={handleCloseDeleteAllModal}
        onConfirm={handleConfirmDeleteAllPartsGroups}
      />
    </Box>
  );
}
