import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

export const productCardStyle: SxProps<Theme> = {
  position: 'relative',
  borderRadius: '15px',
  boxShadow: 'none',
};

export const cardContentStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  // ':last-child': {
  //   padding: '1rem',
  // },
  padding: '1rem',
};

export const imageContainerStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '13rem',
};

export const cardImageStyle: CSSProperties = {
  marginBottom: '0.5rem',
  maxWidth: '19rem',
  maxHeight: '13rem',
};

export const isNewStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  color: '#DD9970',
  fontSize: '0.875rem',
  height: '1.5rem',
};

export const actionsStyle: SxProps<Theme> = {
  borderRadius: '8px',
  borderColor: '#eab308',
  padding: '12px',
};

export const tableContainerStyle: SxProps<Theme> = {
  maxHeight: '20rem',
};

export const titleStyle: SxProps<Theme> = {
  textAlign: 'center',
};
