import {
  ProductLineCategoryDomainPaginate,
  ProductsLineCategoryDomainLiveSearchPaginated,
  VinculateProductInDomain,
} from '../types/productLineCategoryDomain';
import { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const updateProductDomainVinculation = async (
  vinculations: VinculateProductInDomain[],
  lineId: number,
  categoryId: number,
) => {
  const body = {
    lineId,
    categoryId,
    vinculations,
  };

  const response = await api.put<void>(
    `/product-line-category-domain/v1`,
    body,
  );

  return response;
};

export const getProductLineCategoriesDomainLiveSearchPaginated = async (
  search: string | null,
  domainId: number | null,
  page: number = 1,
  direction: 'ASC' | 'DESC' | null = 'ASC',
  limit?: number | null,
) => {
  const url = addSearchParamsInUrl(
    'product-line-category-domain/v1/live-search',
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'domainId', value: domainId },
    { name: 'direction', value: direction },
    search ? { name: 'query', value: search } : {},
  );

  const response =
    await api.get<ProductsLineCategoryDomainLiveSearchPaginated>(url);
  return response;
};

export const findAllProductsByDomainId = async (
  page: number,
  direction: 'ASC' | 'DESC' | null = 'ASC',
  limit?: number | null,
  domainId?: number
) => {
  const url = addSearchParamsInUrl(
    'product-line-category-domain/v1/search',
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    { name: 'domainId', value: domainId },
    { name: 's', value: '' },
  );
  const response = await api.get<ProductLineCategoryDomainPaginate>(url);
  return response;
};
