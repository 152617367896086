import { Content, ContextPageSize } from 'pdfmake/interfaces';
import commonFooter from './commonFooter';
import { ItemInfosPage } from '../types/item';

const listingCommonFooter = (
  currentPage: number,
  totalPages: number,
  context: ContextPageSize,
  itemType: 'Peças' | 'Produtos',
  itemInfosPage?: ItemInfosPage | number,
) => {
  const pagesInfos = (): Content => {
    const defaultItemsByPage = 100;
    if (!itemInfosPage) return [];

    if (typeof itemInfosPage === 'number') {
      return {
        text: `${itemInfosPage} de ${itemInfosPage} ${itemType}`,
        alignment: 'center',
        marginTop: 32,
        marginBottom: -11,
      };
    }

    const currentMinItemsNumber =
      (itemInfosPage.pageNumber - 1) * defaultItemsByPage + 1;
    const currentMaxItemsNumber = itemInfosPage.pageNumber * defaultItemsByPage;

    const isPaginated = itemInfosPage.pagination === 'Paginado';

    return {
      columns: [
        {
          text: `${itemInfosPage.itemsShownNumber} de ${itemInfosPage.totalItemsNumber} ${itemType}`,
          alignment: 'center',
          fontSize: 11,
          relativePosition: {
            x: isPaginated ? 45 : 120,
            y: -1,
          },
        },
        {
          columns: [
            {
              text: itemInfosPage.pagination,
              fontSize: 10,
              alignment: 'center',
              width: 'auto',
            },
            {
              text: isPaginated ? `Página ${itemInfosPage.pageNumber}` : '',
              fontSize: 10,
              alignment: 'center',
              width: 'auto',
            },
            {
              text: isPaginated
                ? `${currentMinItemsNumber} - ${currentMaxItemsNumber} de ${itemInfosPage.totalItemsNumber}`
                : '',
              fontSize: 10,
              alignment: 'center',
              width: 'auto',
            },
          ],
          columnGap: 8,
          alignment: 'center',
          relativePosition: {
            x: isPaginated ? -40 : 32,
          },
        },
      ],
      marginTop: 32,
    };
  };

  const commonFooterContent =
    commonFooter(currentPage, totalPages, context, true) ?? [];
  const pagesInfosContent = pagesInfos();

  return [
    {
      stack: [
        {
          canvas: [
            { type: 'rect', h: 320, w: 532, x: 32, y: -8, color: '#fff' },
          ],
          absolutePosition: {},
        },
        pagesInfosContent,
        commonFooterContent,
      ],
    },
  ] as Content;
};

export default listingCommonFooter;
