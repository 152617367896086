import { useQuery } from 'react-query';
import { productsByDocumentIdCache } from '../../constants/requestCacheName';
import { getAllProductsByDocuementId } from '../../services/products';
import { FindAllProducts, Product } from '../../types/products';
import { useEffect } from 'react';
import { useHandleTable } from '../../hooks/useHandleTable';
import formatSearch from '../../utils/formatSearch';

export const useProductsVinculatedInDocument = (documentId: number | null) => {
  const {
    data: productsVinculatedInCurrentDocument,
    isFetching: isProductsVinculatedInCurrentDocumentFetching,
    refetch: refetchVinculatedProductsInCurrentDocument,
  } = useQuery<FindAllProducts | undefined>(
    productsByDocumentIdCache,
    async () => {
      if (!documentId) return;

      return (await getAllProductsByDocuementId(documentId)).data;
    },
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    itemsShown,
    itemsLength: productsVinculatedInCurrentDocumentLength,
    handleSortTable: handleSortProductDocumentsTable,
    sortField: sortFieldProductDocuments,
    inputRef: productDocumentsInputRef,
    handleCancelSearch: handleCancelProductDocumentsSearch,
    isSearchInputDirty: isProductDocumentsInputDirty,
    handleSearch: search,
  } = useHandleTable(
    productsVinculatedInCurrentDocument?.meta.totalItems ?? 0,
    productsVinculatedInCurrentDocument?.items ?? [],
  );
  const productsVinculatedInCurrentDocumentShown: Product[] = itemsShown;

  useEffect(() => {
    if (documentId) {
      refetchVinculatedProductsInCurrentDocument();
    }
  }, [documentId, refetchVinculatedProductsInCurrentDocument]);

  const handleSearch = () => {
    search<Product>((item, formatedSearch) => {
      return (
        formatSearch(item.name).includes(formatedSearch) ||
        formatSearch(item.id.toString()).includes(formatedSearch)
      );
    });
  };

  return {
    productsVinculatedInCurrentDocument,
    productsVinculatedInCurrentDocumentLength,
    sortFieldProductDocuments,
    productDocumentsInputRef,
    isProductDocumentsInputDirty,
    productsVinculatedInCurrentDocumentShown,
    isProductsVinculatedInCurrentDocumentFetching,
    handleSortProductDocumentsTable,
    handleCancelProductDocumentsSearch,
    handleSearch,
  };
};
