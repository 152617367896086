import { useTheme } from '@mui/material';
import TableMui from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer, {
  TableContainerProps,
} from '@mui/material/TableContainer';
import { ReactNode } from 'react';
import { tableStyle } from './styles';
import { twMerge } from 'tailwind-merge';
import { useAlertStore } from '../../store/alert';
import Alert from '../Alert';

type TableRootProps = TableContainerProps & {
  itemsLength: number;
  itemType: string;
  footer?: ReactNode;
  paddingTop?: string;
  tableContentStyle?: string;
  showNoContentMessage?: boolean;
  showSearchMessage?: boolean;
  noContentContainerClassName?: string;
};

export default function TableRoot({
  children,
  className,
  itemsLength,
  itemType,
  footer,
  sx,
  tableContentStyle,
  paddingTop = '',
  showNoContentMessage = true,
  showSearchMessage = false,
  noContentContainerClassName,
  ...rest
}: TableRootProps) {
  const theme = useTheme();
  // const {
  //   state: { message },
  //   actions: { handleClose },
  // } = useModalStore();

  return (
    <TableContainer
      sx={{
        ...sx,
      }}
      className={twMerge(paddingTop, className)}
      {...rest}
    >
      <TableMui sx={tableStyle} className={tableContentStyle}>
        <TableBody
          sx={{
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {itemsLength <= 0 && (
            <div
              className={twMerge(
                'h-[calc(100vh-15rem)] pt-16 text-center',
                noContentContainerClassName,
              )}
            >
              {itemsLength <= 0 && showNoContentMessage && (
                <span>Sem {itemType} disponíveis</span>
              )}
            </div>
          )}
          {itemsLength > 0 && children}
        </TableBody>
        {footer}
      </TableMui>
      {/* <Alert
        isOpen={!!message}
        onClose={handleClose}
        content="Sua pesquisa não encontrou nenhum item correspondente"
      /> */}
    </TableContainer>
  );
}
