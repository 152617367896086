import { useError } from '../../hooks/useError';
import { useMutation } from 'react-query';
import { EmailFormData } from '../../types/emailRecover';
import { ReactNode, useState } from 'react';
import { mutationErrorHandling } from '../../utils/errorHandling';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { emailSchema } from '../../validations/schemas';
import { sendMail } from '../../services/user';

type AlertContent = {
  message: string | null;
  icon: ReactNode | null;
};

const alertContentInitialValue: AlertContent = {
  icon: null,
  message: null,
};

type UseContactProps = {
  successIcon: ReactNode;
  errorIcon: ReactNode;
};

export const useSendMailRecoverPassword = ({
  errorIcon,
  successIcon,
}: UseContactProps) => {
  // const navigate = useNavigate();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const [alertContent, setAlertContent] = useState<AlertContent>(
    alertContentInitialValue,
  );

  const { mutate: SendMail, isLoading } = useMutation({
    mutationFn: async ({ email }: EmailFormData) => {
      return (await sendMail({ email })).data;
    },

    onSuccess: () => {
        setAlertContent({
          icon: successIcon,
          message: 'Email enviado com sucesso',
        });
        // navigate('/catalogo');
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Houve um erro ao enviar o email, tente novamente',
        setErrorMessage,
      );
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EmailFormData>({
    resolver: zodResolver(emailSchema),
    defaultValues: {
      email: '',
    },
  });


  const onSubmit = async ({ email }: EmailFormData) => {
    SendMail({ email });
  };

  const handleCloseAlert = () => {
    setAlertContent(alertContentInitialValue);
    clearError();
  };


  return {
    isLoading,
    alertContent,
    errors,
    errorMessage,
    clearError,
    handleSubmit,
    onSubmit,
    register,
    handleCloseAlert,
  };
};
