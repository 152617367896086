import {
  DocumentType,
  DocumentTypePaginated,
  SaveDocumentTypeDto,
  UpdateDocumentTypeDto,
} from '../types/documentTypes';
import generatePaginatedUrl from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getDocumentTypesPaginated = async (
  page: number = 1,
  direction: 'ASC' | 'DESC' = 'ASC',
  limit?: number,
) => {
  const url = generatePaginatedUrl('/document-type/v1', page, limit, direction);
  const response = await api.get<DocumentTypePaginated>(url);
  return response;
};

export const saveDocumentType = async ({ name }: SaveDocumentTypeDto) => {
  const body = {
    name,
  };

  const response = await api.post<DocumentType>('/document-type/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const updateDocumentType = async ({
  id,
  name,
}: UpdateDocumentTypeDto) => {
  const body = { id, name };

  const response = await api.put<DocumentType>('/document-type/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const deleteDocumentType = async (documentTypeId: number) => {
  const response = await api.delete<void>(
    `/document-type/v1/${documentTypeId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return response;
};
