export default function PartsGroupIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
      <image
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAJ3SURBVDhPjZTNaxNRFMVTRMH6EcEWUWsUXEopIoqgFQQ3FUEQstFAmo9JMgmppptudC3oouCiRXAh4qIUqhtBi9qFRaOtTasLK12IuPdv0N95uTOZJq32wGHuufe8O++9eW9i/0I+nx8uFAo/PM/7CictvXVks9lDyWRym8kYTcZpOkjT7bBuaQff949YuDGKxeIZGnyHcwxOlMvl3WpSqVT2q07+E837arXaTuIJ+Av6bnA7crncAYqrGgDPEi/BRRqWzKKGl+AcXIN5VrKD+lviIbOsQxeFWS3P9KZQIz01c8Y0MplMryu0Q7PDsGIyRDqd3sd2HDYZgtk9YcwVk53AEIcLhF2me3jBDHxD/IznBxoPqCaQe4w+Z7IF3jKMeRxDndiztPbseXQGDD6mF3IS9khTO4X+hu8hz1sw7owk9DEGg68paJkYXpsMgfcOja+ajKVSqV1qTH4G/3mXJFgINjqA9kwzNBmC3Ai8bjKAPuiDsCFiAlFXY3jBJQHxe5Z31KT7unjnmVGfNPFBPO94LsIpTcIZA1BMwFcmY6VS6aTM5G7zvAmXiPXiMeM0uZcRPcbLTthw17Abw5pJh2q1uld7hvEGdR3iWZ7TG5Ga9rF1PUlo6RmTHaD+At7F42ZDfC+II9Sxa150xE+7Tj3wKQMmYb8zADUkn9DxgQPEyzoNVnbAM2+hEz4mLasBh+BFuEqu2+q6x/1o7eMX2PyqEVBvNRR48+Xo3cTwSOdMMQ1+66uTO018DS7D5mE2dDRsBwNGoa6dztkfW2odvQKPmy3EVhrGMem3JX5EJ3T1tI9mWYf/NoyCZvcZ0OD5eTN6njf1F9WXYrduvegpAAAAAElFTkSuQmCC"
        height="100%"
        width="100%"
        style={{ filter: 'invert(1) brightness(2)' }}
      />
    </svg>
  );
}
