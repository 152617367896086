import { image } from 'html2canvas/dist/types/css/types/image';
import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import ImageTypesContent from '../../../components/ImageTypesContent';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import { useImageTypes } from './useImageTypes';

export default function ImageTypes() {
  const {
    imageTypesIdUpdate,
    isShowEditButton,
    isMenageModalOpen,
    dirtyFields,
    errors,
    isConfirmDeleteModalOpen,
    errorMessage,
    imageExtensions,
    maxSize,
    imageType,
    clearError,
    handleCloseConfirmDeleteModal,
    handleConfirmDelete,
    handleClearImageType,
    handleSubmit,
    register,
    handleButtonOkClick,
    handleEdit,
    handleTableRowClick,
    handleCloseMenageModal,
    handleAddImageTypeClick,
    handleDeleteButtonClick,
  } = useImageTypes();

  return (
    <>
      <ImageTypesContent
        onTableRowClick={handleTableRowClick}
        onAddImageTypeClick={handleAddImageTypeClick}
      />
      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseMenageModal}
        cancelIcon={false}
      >
        <HeaderModalManageItem
          showDeleteButton={!!imageTypesIdUpdate}
          deleteButtonContent="Apagar tipo de imagem"
          onDeleteButtonClick={handleDeleteButtonClick}
          isEditable={!isShowEditButton}
          title={imageTypesIdUpdate ? 'Tipo de imagem' : 'Novo tipo de imagem'}
          mainButtonContent={isShowEditButton ? 'Editar' : 'Ok'}
          onCancelClick={handleCloseMenageModal}
          onOkClick={
            !isShowEditButton ? handleSubmit(handleButtonOkClick) : undefined
          }
          onEditClick={isShowEditButton ? handleEdit : undefined}
        />

        <div className="mb-52 mr-24 mt-24 flex w-full flex-col gap-4">
          <Input
            maxLength={250}
            type="text"
            placeholder="Tipo de imagem"
            disabled={isShowEditButton}
            helperText={errors.imageType?.message}
            isDirty={dirtyFields.imageType}
            onCancelIconClick={() => handleClearImageType('imageType')}
            {...register('imageType')}
            value={imageType}
          />
          <Input
            type="number"
            placeholder="Tamanho máximo em MB"
            disabled={isShowEditButton}
            helperText={errors.maxSize?.message}
            isDirty={dirtyFields.maxSize}
            onCancelIconClick={() => handleClearImageType('maxSize')}
            {...register('maxSize')}
            value={maxSize}
          />
          <div>
            <span className="text-xs">Extensão</span>
            <select
              className="w-full border-b border-solid py-4 outline-none"
              disabled={isShowEditButton}
              {...register('extension')}
            >
              {imageExtensions?.items.map((imageExtension) => (
                <option
                  key={imageExtension.id}
                  value={imageExtension.id}
                  className="text-sm"
                >
                  {imageExtension.extension}
                </option>
              ))}
            </select>
            <span className="text-xs text-red-600">
              {errors.extension?.message}
            </span>
          </div>
        </div>
      </Modal>
      <ConfirmModal
        contentMessage="Deseja excluir esse tipo de imagem?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
        onNotConfirm={handleCloseConfirmDeleteModal}
      />
      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
