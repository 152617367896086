import {
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Sort from '../enums/sort';
import formatSearch from '../utils/formatSearch';
import verifyObjectsArraySort, { applySortAfterFilter } from '../utils/sort';
import { search } from '../services/search';
import { useQuery, useQueryClient } from 'react-query';
import {
  imagesApiCache,
  loadMoreCache,
  searchCache,
} from '../constants/requestCacheName';
import { Image, ImagesResult } from '../types/images';
import { useImagesSelectFiltered } from '../store/imagesSelectFiltered';
import { Pagination } from '../types/pagination';
import { AxiosResponse } from 'axios';
import { useSearchParams } from './useSearchParams';
import { ProductFilters } from '../types/productFilters';
import { useAlertStore } from '../store/alert';

type Filter = {
  name: string;
  handleFilter: (
    previousItems?: any[],
    currentFilter?: string,
  ) => any[] | null | undefined;
};

export type LoadMore = (
  page: number,
  direction?: 'ASC' | 'DESC',
  limit?: number,
) => Promise<AxiosResponse<Pagination<unknown>>>;

export const useHandleTable = (
  itemsLength: number,
  originalItems: any[],
  refetch?: (() => void) | null,
  refetchAll?: (() => void) | null,
  baseEndpointSearch?: string | null,
  loadMore?: LoadMore | null,
  filter?: Filter | null,
  doSearch = search,
  cacheComponet?: string | null,
) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const previousSortFieldName = useRef('');
  const currentItemsSort = useRef<Sort>(Sort.disordered);
  const isAllItems = useRef(false);
  const isSearchClicked = useRef(false);
  const imagesFiltered = useRef<Image[]>([]);
  const isPaginate = useRef(false);
  const inputValuePaginate = useRef('');
  const showDimensions = useRef(true);
  const loadMoreCurrentPage = useRef(1);
  const canShowNoContentModal = useRef(false);

  const [isSearchInputDirty, setIsSearchInputDirty] = useState(false);
  const [itemsShown, setItemsShown] = useState<any[]>([]);
  const [pagination, setPagination] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const [isSearchImageFetching, setIsSearchImageFetching] = useState(false);
  const [totalItems, setTotalItems] = useState(itemsLength);
  const [sortField, setSortField] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [originalSearchItems, setOriginalSearchItems] = useState<any[]>([]);
  const [filteredItems, setFilteredItems] = useState<any[] | null>(null);

  const [productFilter] = useSearchParams('f') as [ProductFilters];

  const {
    actions: { handleOpen },
  } = useAlertStore();

  const queryClient = useQueryClient();
  const {
    state: { isFetching: isImagesFetching },
    actions: { filterImages },
  } = useImagesSelectFiltered();

  useEffect(() => {
    setTotalItems(itemsLength);
  }, [itemsLength]);

  const {
    data: searchItems,
    refetch: refetchSearch,
    isFetching,
  } = useQuery<Pagination<Record<string, any>>>(
    // Adicionado cacheComponent, por que qunado precisar fazer uma pesquisa que utiliza o mesmo useQuery mas que passa parametros para buscar, ele atualiza o cache de acordo com o parametro.
    // `${searchCache}${baseEndpointSearch}${cacheComponet}`,
    `${searchCache}${baseEndpointSearch}`,
    async () => {
      return (
        await doSearch<Record<string, any>>(
          baseEndpointSearch ?? '',
          isPaginate.current
          ? inputValuePaginate.current
          : inputRef.current?.value ?? '',
          currentPage + 1,
        )
      ).data;
    },
    {
      cacheTime: 5,
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.meta.totalItems <= 0 && canShowNoContentModal.current) {
          handleOpen('Sua pesquisa não encontrou nenhum item correspondente');
          canShowNoContentModal.current = false;
        }
        isPaginate.current = false;
        if (sortField) {
          applySortAfterFilter(data.items, currentItemsSort.current, sortField);
        }
        setOriginalSearchItems(data.items);
        setItemsShown(data.items);
        setTotalItems(data.meta.totalItems);
      },
    },
  );

  const {
    data: itemsLoadMore,
    refetch: refetchLoadMore,
    isFetching: isFetchingLoadMore,
  } = useQuery<Pagination<unknown>>(
    loadMoreCache,
    async () => {
      if (loadMore) {
        return (
          inputValuePaginate.current
            ? await doSearch(
                baseEndpointSearch ?? '',
                inputValuePaginate.current,
                loadMoreCurrentPage.current,
              )
              : await loadMore(loadMoreCurrentPage.current)
            ).data;
      }

      return {
        items: [],
        meta: {
          totalItems: 0,
          currentPage: 0,
          itemCount: 0,
          itemsPerPage: 0,
          totalPages: 0,
        },
      };
    },

    {
      enabled: false,
      onSuccess: (data) => {
        isPaginate.current = false;
        setOriginalSearchItems((prev) => [...prev, ...data.items]);

        if (productFilter === 'Todos' || !productFilter) {
          setItemsShown((prev) => {
            const itemsLoaded = [...prev, ...data.items];

            if (sortField) {
              applySortAfterFilter(
                itemsLoaded,
                currentItemsSort.current,
                sortField,
              );
            }

            return itemsLoaded;
          });
        }
        setTotalItems(data.meta.totalItems);
      },
    },
  );


  const endPageRef = useRef<HTMLDivElement>(null);

  const handleLoadMore = useCallback(() => {
    if (
      itemsShown.length >= 100 &&
      inputValuePaginate.current &&
      itemsShown.length < totalItems
    ) {
      loadMoreCurrentPage.current++;
      refetchLoadMore();
      setCanLoadMore(false);
    }

    if (
      (baseEndpointSearch &&
        canLoadMore &&
        itemsShown.length < totalItems &&
        !inputRef.current?.value &&
        !inputValuePaginate.current) ||
      !baseEndpointSearch
    ) {
      loadMoreCurrentPage.current++;
      refetchLoadMore();
      setCanLoadMore(false);
    }
  }, [
    baseEndpointSearch,
    canLoadMore,
    // filter,
    itemsShown.length,
    refetchLoadMore,
    totalItems,
  ]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        handleLoadMore();
      }
    });

    if (endPageRef.current) {
      intersectionObserver.observe(endPageRef.current);
    }

    return () => {
      if (intersectionObserver) {
        intersectionObserver.disconnect();
      }
    };
  }, [handleLoadMore]);

  useEffect(() => {
    if (itemsShown.length > 0) {
      setTimeout(() => {
        setCanLoadMore(true);
      }, 2000);
    }
  }, [itemsShown]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    setCanLoadMore(false);
  }, [currentPage]);

  const handleSearchDatabase = useCallback(() => {
    if (baseEndpointSearch) {
      canShowNoContentModal.current = true;
      isSearchClicked.current = true;
      inputValuePaginate.current = inputRef.current?.value ?? '';
      setIsSearch(true);
      refetchSearch();
    }
  }, [baseEndpointSearch, refetchSearch]);

  const isSearchingInAllImages = useRef(false);

  const searchInAllImages = useCallback(() => {
    const inputValue = inputRef.current?.value ?? '';
    const allImages = queryClient.getQueryData<ImagesResult>(imagesApiCache);

    if (allImages?.items) {
      const imagesSearch = allImages?.items.filter((image) =>
        image.name.toLowerCase().includes(inputValue.toLowerCase()),
      );
      imagesFiltered.current = imagesSearch ?? [];
      const imagesResult: ImagesResult = {
        items: imagesSearch ?? [],
        meta: {
          totalItems: imagesSearch?.length ?? 0,
        },
      };
      filterImages(imagesResult, 1, showDimensions.current);
      setTotalItems(imagesSearch?.length ?? 0);
    }
    if (allImages) {
      isSearchingInAllImages.current = false;
      setIsSearchImageFetching(false);
    }
  }, [filterImages, queryClient]);

  useEffect(() => {
    if (isSearchImageFetching && isSearchingInAllImages.current) {
      searchInAllImages();
    }
  }, [
    filterImages,
    queryClient,
    isSearchImageFetching,
    isImagesFetching,
    searchInAllImages,
  ]);

  const handleSearchInAllImages = useCallback((isShowDimensions = true) => {
    showDimensions.current = isShowDimensions;
    isSearchingInAllImages.current = true;
    setIsSearchImageFetching(true);
  }, []);

  useEffect(() => {
    loadMoreCurrentPage.current = currentPage + 1;

    if (refetch && !baseEndpointSearch) {
      refetch();
      return;
    }

    if (isSearchClicked.current) {
      refetchSearch();
    }
  }, [baseEndpointSearch, refetch, refetchSearch, currentPage]);

  useEffect(() => {
    if (!baseEndpointSearch && pagination === 100 && refetch) {
      refetch();
      isAllItems.current = false;
    }
  }, [
    baseEndpointSearch,
    handleSearchDatabase,
    itemsShown.length,
    pagination,
    refetch,
  ]);

  const verifyIsInputDirty = (search: string) => {
    if (search) {
      setIsSearchInputDirty(true);
      return;
    }
    setIsSearchInputDirty(false);
  };

  const handleSearch = useCallback(
    <Item = any>(
      searchPredicate?: (item: Item, formatedSearch: string) => boolean,
    ) => {
      if (!inputRef.current?.value) {
        handleSearchDatabase();
        // return;
      }

      const formatedSearch = formatSearch(inputRef.current?.value ?? '');
      verifyIsInputDirty(formatedSearch);
      const itemsFiltered = (
        filteredItems
          ? filteredItems
          : baseEndpointSearch
            ? originalSearchItems
            : originalItems
      )?.filter((item) => {
        if (typeof searchPredicate === 'function') {
          return searchPredicate(item, formatedSearch);
        }

        return (
          formatSearch(item.name?.toString()).includes(formatedSearch) ||
          formatSearch(item.id?.toString()).includes(formatedSearch)
        );
      });

      if (sortField) {
        applySortAfterFilter(
          itemsFiltered,
          currentItemsSort.current,
          sortField,
        );
      }

      setItemsShown(itemsFiltered ?? []);
    },
    [
      baseEndpointSearch,
      filteredItems,
      originalItems,
      originalSearchItems,
      sortField,
    ],
  );

  useEffect(() => {
    if (!baseEndpointSearch && originalItems.length > 0) {
      setItemsShown(originalItems);
    }
  }, [baseEndpointSearch, originalItems]);

  const handleChangePage = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      isPaginate.current = true;
      setCurrentPage(page);
    },
    [],
  );

  const handleChangePagination = () => {
    setPagination((prev) => (prev === 100 ? totalItems : 100));
  };

  const handleSortTable = useCallback(
    (fieldNameSort: string) => {
      setSortField(fieldNameSort);

      // mudança de verificação, adicionado  e fieldNameSort.includes('qtdTotal')
      // para caso necessario passar um objeto alinhado para ordenação
      const newItems = [...itemsShown];
      newItems.sort((a, b) => {
        if (fieldNameSort.includes('qtdTotal') || fieldNameSort === 'id') {
          return Number(a[fieldNameSort]) - Number(b[fieldNameSort]);
        }
        if (
          fieldNameSort === 'partGroupName' &&
          a.partGroupName &&
          b.partGroupName
        ) {
          return a.partGroupName
            .toString()
            .trim()
            .localeCompare(b.partGroupName.toString().trim());
        }

        const fields = fieldNameSort.split('.');

        if (fields.length === 2) {
          const [first, second] = fields;

          return a[first][second]!.toString()
            .trim()
            .localeCompare(b[first][second]!.toString().trim());
        }

        return a[fieldNameSort]!.toString()
          .trim()
          .localeCompare(b[fieldNameSort]!.toString().trim());
      });

      if (previousSortFieldName.current === fieldNameSort) {
        if (currentItemsSort.current === Sort.ascending) {
          currentItemsSort.current = Sort.descending;
        } else if (
          currentItemsSort.current === Sort.descending ||
          currentItemsSort.current === Sort.disordered
        ) {
          currentItemsSort.current = Sort.ascending;
        }
      } else {
        if (
          verifyObjectsArraySort(itemsShown, fieldNameSort) === Sort.ascending
        ) {
          currentItemsSort.current = Sort.descending;
        } else {
          currentItemsSort.current = Sort.ascending;
        }
      }

      if (currentItemsSort.current === Sort.descending) {
        newItems.reverse();
      }

      previousSortFieldName.current = fieldNameSort;
      setItemsShown(newItems);
    },
    [itemsShown],
  );

  const handleReset = (filterName?: string) => {
    handleCancelSearch();
    setSortField('');

    if (filterName) {
      handleFilter(filterName);
      return;
    }

    setItemsShown(originalItems);
  };

  const handleCancelSearch = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    handleSearch();
    // handleSearchDatabase();
  }, [handleSearch]);

  const dirtySearchInput = useCallback(() => {
    setIsSearchInputDirty(true);
  }, []);

  const handleEnterClickSearchInput = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleSearchDatabase();
      }
    },
    [handleSearchDatabase],
  );

  const defineItemsShown = useCallback(
    (value: any[] | ((previousValue: any[]) => any[])) => {
      setItemsShown(value);
    },
    [],
  );

  const defineItemsLength = useCallback((value: number) => {
    setTotalItems(value);
  }, []);

  const memoizedHandleFilter = useMemo(() => filter?.handleFilter, [filter]);

  const handleFilter = useCallback(
    (filterName: string) => {
      if (inputRef.current?.value) {
        inputRef.current.value = '';
      }
      if (filterName !== 'Todos') {
        const itemsFiltered = memoizedHandleFilter
          ? memoizedHandleFilter(searchItems?.items, filter?.name) ?? []
          : [];

        applySortAfterFilter<Document>(
          itemsFiltered,
          currentItemsSort.current,
          sortField,
        );
        setItemsShown(itemsFiltered);
        setFilteredItems(itemsFiltered);
        return;
      }
      applySortAfterFilter(
        originalItems ?? [],
        currentItemsSort.current,
        sortField,
      );
      setFilteredItems(null);
      setItemsShown(originalItems);
    },
    // originalItems está causando renderização infinita, em caso de bug, ajustar
    [
      filter?.name,
      memoizedHandleFilter,
      // originalItems,
      searchItems?.items,
      sortField,
    ],
  );

  const resetLoadMoreCurrentPage = useCallback(() => {
    loadMoreCurrentPage.current = 1;
  }, []);

  useEffect(() => {
    if (filter?.name) {
      handleFilter(filter.name ?? 'Todos');
    }
  }, [filter?.name, handleFilter]);

  return {
    sortField,
    itemsShown,
    inputRef,
    currentPage,
    pagination,
    isSearchInputDirty,
    itemsLength: totalItems,
    itemsShownLength: itemsShown.length,
    isSearchFetching: isFetching,
    isSearchImageFetching,
    isSearch,
    imagesFiltered: imagesFiltered.current,
    searchItems,
    isImagesFetching,
    isLoadMoreFetching: isFetchingLoadMore,
    itemsLoadMore,
    endPageRef,
    loadMoreCurrentPage: loadMoreCurrentPage.current,
    currentItemsSort: currentItemsSort.current,
    originalSearchItems,
    resetLoadMoreCurrentPage,
    refetchSearch,
    handleFilter,
    handleReset,
    verifyIsInputDirty,
    defineItemsLength,
    defineItemsShown,
    handleEnterClickSearchInput,
    setItemsShown,
    handleSearch,
    handleSortTable,
    handleChangePage,
    handleChangePagination,
    handleCancelSearch,
    handleSearchDatabase,
    searchInAllImages: handleSearchInAllImages,
    dirtySearchInput,
  };
};
