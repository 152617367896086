import { useEffect, useRef, useState } from 'react';
import { useModal } from '../../../hooks/useModal';
import { useTitle } from '../../../hooks/useTitle';
import { useEdit } from '../../../hooks/useEdit';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  CreateEmailSignatureDto,
  EmailSignature,
  EmailSignatureFormData,
  EmailSignaturesPaginated,
  SendImageEmail,
  UpdateEmailSignatureDto,
} from '../../../types/emailSignature';
import { emailSignatureSchema } from '../../../validations/schemas';
import { useMutation, useQueryClient } from 'react-query';
import {
  deleteEmailSignature,
  saveEmailSignature,
  updateEmailSignature,
  sendImageEmail,
} from '../../../services/emailSignature';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { phoneNumberMask, removePhoneMask } from '../../../utils/mask';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { emailSignatureSearchBaseEndpoint } from '../../../constants/endpoints';
import { searchCache } from '../../../constants/requestCacheName';
import { useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';

export const useEmailSignature = () => {
  useTitle('Assinaturas');

  const emailSignatureIdUpdate = useRef<number | null>(null);

  const location = useLocation();

  const [currentEmailSignature, setCurrentEmailSignature] =
    useState<EmailSignature | null>(null);

  const [currentLinkCopied, setCurrentLinkCopied] = useState<string | null>(
    null,
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    if (currentLinkCopied) {
      const timeout = setTimeout(() => {
        setCurrentLinkCopied(null);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [currentLinkCopied]);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { mutate: saveEmailSignatureMutate } = useMutation({
    mutationFn: async (dto: CreateEmailSignatureDto) =>
      (await saveEmailSignature(dto)).data,

    onSuccess: (data: EmailSignature) => {
      handleCloseManageModal();
      addItemOnScreen<EmailSignaturesPaginated>(
        `${searchCache}${emailSignatureSearchBaseEndpoint}`,
        {
          cellPhoneNumber: data.cellPhoneNumber,
          department: data.department,
          englishDepartament: data.englishDepartament,
          email: data.email,
          fullName: data.fullName,
          phoneNumber: data.phoneNumber,
          id: data.id,
          signatureDomains: [],
        },
      );
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar assinatura de email',
        setErrorMessage,
      );
    },
  });

  const { mutate: updateEmailSignatureMutate } = useMutation({
    mutationFn: async (dto: UpdateEmailSignatureDto) =>
      (await updateEmailSignature(dto)).data,

    onSuccess: (data: EmailSignature) => {
      handleCloseManageModal();
      const currentData = queryClient.getQueryData<EmailSignaturesPaginated>(
        `${searchCache}${emailSignatureSearchBaseEndpoint}`,
      );
      const updatedData = currentData?.items.find(
        (current) => current.id === data.id,
      );

      updateItemOnScreen<EmailSignaturesPaginated>(
        `${searchCache}${emailSignatureSearchBaseEndpoint}`,
        {
          cellPhoneNumber: data.cellPhoneNumber,
          department: data.department,
          englishDepartament: data.englishDepartament,
          email: data.email,
          fullName: data.fullName,
          phoneNumber: data.phoneNumber,
          id: data.id,
          signatureDomains: updatedData?.signatureDomains ?? [],
        },
      );
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar assinatura de email',
        setErrorMessage,
      );
    },
  });

  const { mutate: deleteEmailSignatureMutate } = useMutation({
    mutationFn: async () => {
      if (emailSignatureIdUpdate.current) {
        await deleteEmailSignature(emailSignatureIdUpdate.current);
        return;
      }

      setErrorMessage('Falha ao deletar assinatura');
    },

    onSuccess: () => {
      if (emailSignatureIdUpdate.current) {
        removeItemFromScreen<EmailSignaturesPaginated>(
          `${searchCache}${emailSignatureSearchBaseEndpoint}`,
          emailSignatureIdUpdate.current,
        );
      }

      handleCloseManageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar assinatura',
        setErrorMessage,
      );
    },
  });

  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<EmailSignatureFormData>({
    resolver: zodResolver(emailSignatureSchema),
    defaultValues: {
      department: '',
      englishDepartament: '',
      email: '',
      name: '',
      phoneNumber: '',
      cellPhoneNumber: '',
    },
  });

  const department = watch('department');
  const englishDepartament = watch('englishDepartament');
  const email = watch('email');
  const name = watch('name');
  const phoneNumber = watch('phoneNumber');
  const cellPhoneNumber = watch('cellPhoneNumber');

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const {
    isModalOpen: isManageModalOpen,
    handleCloseModal: closeManageModal,
    handleOpenModal: handleOpenManageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: handleCloseConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  const handleDeleteButtonClick = () => {
    handleOpenConfirmDeleteModal();
  };

  const handleCloseManageModal = () => {
    emailSignatureIdUpdate.current = null;
    setCurrentEmailSignature(null);
    reset();
    handleUnedit();
    closeManageModal();
  };

  const handleAddEmailSignatureClick = () => {
    handleOpenManageModal();
  };

  const handleConfirmDeleteSignature = () => {
    deleteEmailSignatureMutate();
    handleCloseConfirmDeleteModal();
  };

  const handleTableRowClick = (emailSignature: EmailSignature) => {
    setCurrentEmailSignature(emailSignature);
    emailSignatureIdUpdate.current = emailSignature.id;
    setValue('department', emailSignature.department);
    setValue('englishDepartament', emailSignature.englishDepartament);
    setValue('email', emailSignature.email);

    if (emailSignature.cellPhoneNumber) {
      setValue(
        'cellPhoneNumber',
        phoneNumberMask(emailSignature.cellPhoneNumber.slice(2)),
      );
    }
    if (emailSignature.phoneNumber) {
      setValue(
        'phoneNumber',
        phoneNumberMask(emailSignature.phoneNumber.slice(2), 4),
      );
    }

    setValue('name', emailSignature.fullName);

    handleOpenManageModal();
  };

  const handleEditButtonClick = () => {
    handleEdit();
  };

  const handleButtonOkClick = handleSubmit(
    ({
      cellPhoneNumber,
      department,
      englishDepartament,
      email,
      name,
      phoneNumber,
    }: EmailSignatureFormData) => {
      if (emailSignatureIdUpdate.current) {
        updateEmailSignatureMutate({
          id: emailSignatureIdUpdate.current,
          cellPhoneNumber: cellPhoneNumber
            ? removePhoneMask(cellPhoneNumber)
            : null,
          department,
          englishDepartament:
            englishDepartament === '' ? null : englishDepartament,
          email,
          name,
          phoneNumber: phoneNumber ? removePhoneMask(phoneNumber) : null,
        });
        return;
      }

      saveEmailSignatureMutate({
        cellPhoneNumber: cellPhoneNumber
          ? removePhoneMask(cellPhoneNumber)
          : null,
        department,
        englishDepartament:
          englishDepartament === '' ? null : englishDepartament,
        email,
        name,
        phoneNumber: phoneNumber ? removePhoneMask(phoneNumber) : null,
      });
    },
  );

  const handleClearField = (fieldName: keyof EmailSignatureFormData) => {
    setValue(fieldName, '', { shouldDirty: true });
  };

  const handleCopySignatureLink = async (link: string) => {
    const copiedLink = `${window.location.origin}${location.pathname}/${link}`;

    await navigator.clipboard.writeText(copiedLink);

    setCurrentLinkCopied(link);
  };


  const isShowEditButton = !!emailSignatureIdUpdate.current && !canEdit;

  // ENVIAR IMAGEM POR EMAIL VIA BASE64

  const [signaturesImages, setSignaturesImages] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);

  const selectedDomainIdRef = useRef<string>('');

  useEffect(() => {
    const generateSignaturesImages = async () => {
      const element = document.getElementById(selectedDomainIdRef.current)!;

      const canvas = await html2canvas(element, {
        useCORS: true,
        height: 213,
        width: 640,
      });

      const imageData = canvas.toDataURL('image/png');
      setSignaturesImages(imageData);
    };

    if (openModal) {
      generateSignaturesImages();
    }
  }, [openModal]);

  const { mutate: sendImageSignatureEmail } = useMutation({
    mutationFn: async (dto: SendImageEmail) => (await sendImageEmail(dto)).data,

    onSuccess: (data: EmailSignature) => {
      setSuccess('Imagem enviada com sucesso');
      setOpenSuccess(true);
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar assinatura de email',
        setErrorMessage,
      );
    },
  });

  const handleConfirmSendImageMail = () => {
    if (currentEmailSignature?.id) {
      const signatureId = currentEmailSignature.id;
      sendImageSignatureEmail({ signatureId, base64: signaturesImages });
      setOpenModal((prev) => !prev);
      selectedDomainIdRef.current = '';
    }
  };

  const handleOpenModalSiganture = (id: string) => {
    setOpenModal((prev) => !prev);
    selectedDomainIdRef.current = id;
  };

  const handleCloseConfirmModal = () => {
    setOpenModal((prev) => !prev);
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccess(false);
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    // Verifica se não tem o '@' e, se não tiver, adiciona o domínio
    if (!value.includes('@')) {
      value = value + '@buffalo.com.br';
    }
  };

    const combinedOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e); // Executa a lógica de completar o domínio
      register('email').onChange(e); // Chama o onChange do react-hook-form
    };

  return {
    department,
    englishDepartament,
    email,
    name,
    phoneNumber,
    cellPhoneNumber,
    isManageModalOpen,
    emailSignatureIdUpdate: emailSignatureIdUpdate.current,
    isShowEditButton,
    isConfirmDeleteModalOpen,
    dirtyFields,
    errorMessage,
    currentEmailSignature,
    currentLinkCopied,
    success,
    openModal,
    errors,
    combinedOnChange,
    openSuccess,
    handleCloseSuccessModal,
    handleOpenModalSiganture,
    handleConfirmSendImageMail,
    handleCloseConfirmModal,
    handleCopySignatureLink,
    clearError,
    setValue,
    handleClearField,
    register,
    handleEditButtonClick,
    handleButtonOkClick,
    handleConfirmDeleteSignature,
    handleCloseConfirmDeleteModal,
    handleDeleteButtonClick,
    handleCloseManageModal,
    handleAddEmailSignatureClick,
    handleTableRowClick,
  };
};
