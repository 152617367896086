import { Box } from '@mui/material';
import { usePartsCatalag } from './usePartsCatalog';
import PartsGroup from '../../components/PartsGroup';
import {
  explodedViewStyle,
  headerStyle,
  mainStyle,
  partsCatalogTable,
  partsGroupStyle,
} from './styles';
import ExplodedView from '../../components/ExplodedView';
import PartsCatalogTable from '../../components/PartsCatalogTable';
import SecondaryHeader from '../../components/SecondaryHeader';
import Loading from '../../components/Loading';
import ConfirmModal from '../../components/ConfirmModal';
import Alert from '../../components/Alert';

export default function PartsCatalog() {
  const {
    previusVersion,
    currentPartIdHover,
    productId,
    productName,
    currentPartsGroup,
    explodedViewImageName,
    isPdfLoading,
    canEdit,
    isConfirmModifiedModalOpen,
    auth,
    errorMessage,
    previusVersionmessage,
    clearPreviusMessage,
    clearError,
    handleCloseModifiedModal,
    handleDeletePart,
    handleDeletePartGroup,
    handleSetCurrentProductId,
    handleCopyAllPartsGroup,
    handleDragPartEnd,
    handleEditPart,
    handleConfirmBack,
    handleEditButtonClick,
    handleOkButtonClick,
    handlePartMouseOut,
    handlePartMouseEnter,
    handleClickPart,
    handleGeneratePdf,
    handleBackButtonClick,
  } = usePartsCatalag();

  return (
    <Box sx={{ cursor: isPdfLoading ? 'progress' : 'initial' }}>
      <SecondaryHeader
        partsCatalogo
        search={true}
        previusVersion={+previusVersion!}
        isPdfLoading={isPdfLoading}
        itemId={productId}
        itemName={productName}
        itemType="Produtos"
        pdf
        showSearchInput={true}
        canEdit={canEdit}
        onOkButtonClick={handleOkButtonClick}
        onEditButtonClick={handleEditButtonClick}
        separator
        onPdfClick={handleGeneratePdf}
        onBackButtonClick={handleBackButtonClick}
        sx={headerStyle}
      />

      {/* {isPdfLoading && (
        <Loading
          className={`absolute top-4 z-50 ${auth ? 'right-40' : 'right-24'}`}
          heightNormal
        />
      )} */}
      <Box component="main" sx={mainStyle}>
        <Box sx={partsGroupStyle}>
          <PartsGroup
            onDeletePartsGroup={handleDeletePartGroup}
            onSetCurrentProductId={handleSetCurrentProductId}
            handleCopyAllPartsGroup={handleCopyAllPartsGroup}
            canEdit={canEdit}
            currentPartGroupId={currentPartsGroup?.id}
          />
        </Box>
        <Box sx={explodedViewStyle}>
          <ExplodedView
            canEdit={canEdit}
            currentPartHoverId={currentPartIdHover}
            imageName={explodedViewImageName}
            onPartMouseEnter={handlePartMouseEnter}
            onPartMouseOut={handlePartMouseOut}
            onPartClick={handleClickPart}
          />
        </Box>
        <Box sx={partsCatalogTable}>
          <PartsCatalogTable
            currentPartGroupName={currentPartsGroup?.name}
            onDeletePart={handleDeletePart}
            onDragPartEnd={handleDragPartEnd}
            onEditPart={handleEditPart}
            currentPartGroupId={currentPartsGroup?.id}
            canEdit={canEdit}
            currentPartHoverId={currentPartIdHover}
            onRowMouseEnter={handlePartMouseEnter}
            onRowMouseOut={handlePartMouseOut}
            onRowClick={handleClickPart}
          />
        </Box>
      </Box>
      <ConfirmModal
        isModalOpen={isConfirmModifiedModalOpen}
        contentMessage="As alterações feitas serão perdidas. Deseja prosseguir?"
        onCloseModal={handleCloseModifiedModal}
        onConfirm={handleConfirmBack}
        onNotConfirm={handleCloseModifiedModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />

      <Alert
        content={previusVersionmessage}
        isOpen={!!previusVersionmessage}
        onClose={clearPreviusMessage}
      />
    </Box>
  );
}
