export const descriptionSearchBaseEndpoint = '/description/v1';
export const descriptionSearchByTypeIdBaseEndpoint = '/description/v1/type-id';
export const partSearchBaseEndpoint = '/part/v1';
export const productSearchBaseEndpoint = '/product/v1';
export const productPartsSearchBaseEndpoint = '/product-parts/v1';
export const partsGroupSearchBaseEndpoint = '/parts-group/v1';
export const documentTypesSearchBaseEndpoint = '/document-type/v1';
export const productBarCodeSearchBaseEndpoint = '/product-bar-code/v1';
export const contactsSearchBaseEndpoint = '/contact/v1';
export const imagesSearchBaseEndpoint = '/images/v1';
export const emailSignatureSearchBaseEndpoint = '/signature/v1';
export const emailSignatureDomainSearchBaseEndpoint = '/signature-domain/v1';
export const questionSearchBaseEndpoint = '/faq/v1';
export const imageExtensionSearchBaseEndpoint = '/image-extension/v1';
export const pagesSearchBaseEndpoint = '/pages/v1';
export const documentsSearchBaseEndpoint = '/document/v1';
export const newsSearchBaseEndpoint = '/news/v1';
export const userSearchBaseEndpoint = '/user/v1';
export const categorySearchBaseEndpoint = '/category/v1';
export const sizingSearchBaseEndpoint = '/sizing/v1';
export const domainSearchBaseEndpoint = '/domain/v1';
export const emailTriggerSearchBaseEndpoint = '/email-trigger/v1';
export const productDomainSearchBaseEndpoint = 'product-line-category-domain/v1'
export const partGroupItemSeatchBaseEndpoint = 'parts-group-item/v1';
export const subjectSearchBaseEndpoint = '/subject/v1';
