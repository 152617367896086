import { ImageTypes } from '../enums/imageTypes';

export const imageTypesText: Record<ImageTypes, string> = {
  [ImageTypes.products]: 'Produtos',
  [ImageTypes.parts]: 'Peças',
  [ImageTypes.explodedView]: 'Vista explodida',
  [ImageTypes.linesCarousel]: 'Linhas carrossel',
  [ImageTypes.linesMenu]: 'Linhas menu',
  [ImageTypes.imgHighlight]: 'Imagem de destaque',
  [ImageTypes.emailSignatures]: 'Assinatura de email',
};
