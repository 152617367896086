import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useError } from '../../../hooks/useError';
import {
  partsAndPartsGroupCache,
  productBuffaloCache,
} from '../../../constants/requestCacheName';
import { FindAllProductDescriptionDetails } from '../../../types/productsDescription';
import { getProductByProductId } from '../../services/produtosService';
import { useQuery } from 'react-query';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { useTheme } from '@mui/material';
import {
  GroupItemsPaginated,
  PartsGroupItems,
} from '../../types/partGroupItems';
import { searchPartsAndGroup } from '../../services/partGroupItem';
import { search, Search } from '../../../services/search';
import { partGroupItemSeatchBaseEndpoint } from '../../../constants/endpoints';
import generateProductPartsPdf from '../../../pdf/productPartsPdf/generateProductPartsPdf';
import { usePartsCatalogStore } from '../../../store/partsCatalog';
import { PartsGroupItem } from '../../../types/partsGroupItem';
type PartClean = {
  id: number;
  name: string;
  isAvailable: boolean;
};

export const usePartsGroupProduct = () => {
  const theme = useTheme();
  const { productId } = useParams();
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const { errorMessage, setErrorMessage } = useError();
  const [currentProduct, setCurrentProduct] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const mobileMaxWidth = 1280;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileMaxWidth);
  const line = useRef<{ id: number; name: string } | null>(null);
  const categoryName = useRef<string>('');
  const [openSearch, setOpenSearch] = useState<boolean>(false);

  const navigate = useNavigate();

  const {
    data: partsAndGroup,
    isFetching: isFetchingPartsAndGroup,
    refetch: refetchPartsAndGroup,
  } = useQuery<GroupItemsPaginated>(
    partsAndPartsGroupCache,
    async () => {
      return (await searchPartsAndGroup(+productId!, currentPage)).data;
    },
    {
      retry: false,
      // enabled: true,
      refetchOnWindowFocus: false,
    },
  );

  const loadMore = useCallback(
    async (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      return await searchPartsAndGroup(+productId!, page, limit);
    },
    [productId],
  );

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return search<PartsGroupItems>(baseUrl, searchValue, page, null, null, [
        {
          name: 'productId',
          value: productId,
        },
      ]);
    },
    [productId],
  );

  const {
    pagination,
    inputRef,
    sortField,
    isSearchInputDirty,
    handleSearch,
    itemsShown,
    itemsShownLength,
    endPageRef,
    isLoadMoreFetching,
    currentPage,
    handleSortTable,
  } = useHandleTable(
    partsAndGroup?.meta.totalItems ?? 0,
    partsAndGroup?.items ?? [],
    refetchPartsAndGroup,
    null,
    partGroupItemSeatchBaseEndpoint,
    // null,
    loadMore,
    null,
    doSearch as Search,
    productId,
  );

  const isAllParts = useRef(false);

  const originalParts = useMemo<PartClean[]>(() => {
    if (
      !isAllParts.current &&
      productId &&
      partsAndGroup &&
      partsAndGroup.items
    ) {
      const newProductParts = [...partsAndGroup.items];
      const partsIds = new Set();

      return newProductParts
        .map((part) => {
          return {
            id: part.id,
            name: part.name.trim().replace(/\s+/g, ' '),
            isAvailable: !part.partOutOfLine && part.partReviewedApproved,
          };
        })
        .filter((part) => {
          const isReturnPart = !partsIds.has(part.id);
          partsIds.add(part.id);
          return isReturnPart;
        });
    }

    if (
      isAllParts.current &&
      productId &&
      partsAndGroup &&
      partsAndGroup.items
    ) {
      const partsIds = new Set();

      return partsAndGroup.items
        .map((part) => {
          return {
            id: part.id,
            name: part.name.trim().replace(/\s+/g, ' '),
            isAvailable: !part.partOutOfLine && part.partReviewedApproved,
          };
        })
        .filter((part) => {
          const isReturnPart = !partsIds.has(part.id);
          partsIds.add(part.id);
          return isReturnPart;
        });
    }

    return [];
  }, [productId, partsAndGroup]);

  const getPartsLength = (() => {
    if (productId && isAllParts.current) return Number(originalParts?.length);

    if (productId && !isAllParts.current)
      return Number(partsAndGroup?.meta.totalItems);
    return 0;
  })();

  const handleGeneratePdf = async () => {
    setIsPdfLoading(true);
    try {
      await generateProductPartsPdf(
        itemsShown,
        productId,
        currentProduct?.name ?? '',
        pagination <= getPartsLength
          ? {
              itemsShownNumber: itemsShownLength,
              pageNumber: currentPage + 1,
              pagination: pagination === 100 ? 'Paginado' : 'Todas',
              totalItemsNumber: itemsShownLength,
            }
          : itemsShownLength,
        inputRef.current?.value,
        setErrorMessage,
      );
    } catch (error) {
      if (
        typeof error === 'string' &&
        error
          .toLowerCase()
          .includes('open pdf in new window blocked by browser')
      ) {
        setErrorMessage(
          'O navegador bloqueou o PDF. Desative a opção Bloquear Pop-ups e tente gerar novamente',
        );
        return;
      }

      setErrorMessage('Ocorreu um erro ao gerar o PDF');
    } finally {
      setIsPdfLoading(false);
    }
  };

  const { data: productData } = useQuery<FindAllProductDescriptionDetails>(
    `${productBuffaloCache}${productId}`,
    () => getProductByProductId(Number(productId)),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const handleOpenSearch = () => {
    setOpenSearch((prev) => !prev);
  };

  useEffect(() => {
    if (productData) {
      setCurrentProduct({
        id: productData?.product?.id,
        name: productData?.product?.name,
      });
      line.current = {
        id: productData?.product?.line?.id,
        name: productData?.product?.line?.name,
      };
      categoryName.current = productData?.product?.category?.name;
    }
  }, [productData]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileMaxWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (productId && pagination === 100) {
      refetchPartsAndGroup();
      isAllParts.current = false;
      return;
    }

    isAllParts.current = true;
  }, [pagination, productId, refetchPartsAndGroup]);

  const handleBackButtonClick = () => {
    navigate(`/catalogo/${currentProduct?.id}`, { replace: true });
  };

  const {
    actions: {
      setShouldShowFirstCurrentPartsGroup,
      setCurrentPartClicked,
      setCurrentPartIdHover,
      setCurrentPartsGroup,
    },
  } = usePartsCatalogStore();

  const handleClickRow = (partGroupItemSelect: PartsGroupItem) => {
    const { part, partsGroup } = partGroupItemSelect;
    setShouldShowFirstCurrentPartsGroup(true);
    setCurrentPartIdHover(part.id);
    setCurrentPartClicked(partGroupItemSelect);
    setCurrentPartsGroup({
      id: partsGroup?.id,
      name: partsGroup?.name || '',
      image: partsGroup?.image || '',
      totalItems: 0,
      description: {
        id: 0,
        name: '',
      },
    });
    navigate({ pathname: `/catalogo/${productId}` });
  };

   const [expandedRow, setExpandedRow] = useState<number | null>(null);

   const handleExpandClick = (partId: number) => {
     setExpandedRow(expandedRow === partId ? null : partId);
   };

   const handleRetractRow = () => {
     setExpandedRow(null);
   };

   useEffect(() => {
     const value = inputRef.current?.value;
     if (value) {
       setExpandedRow(null);
     }
   }, [inputRef, inputRef?.current?.value]);

  return {
    isSearchInputDirty,
    itemsShown,
    theme,
    openSearch,
    isMobile,
    line,
    inputRef,
    sortField,
    categoryName,
    currentProduct,
    productId,
    isPdfLoading,
    errorMessage,
    isFetchingPartsAndGroup,
    isLoadMoreFetching,
    endPageRef,
    expandedRow,
    setExpandedRow,
    handleExpandClick,
    handleRetractRow,
    handleClickRow,
    handleBackButtonClick,
    handleGeneratePdf,
    handleSearch,
    handleOpenSearch,
    handleSortTable,
  };
};
