import AddButton from '../../../components/AddButton';
import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import PagesContent from '../../../components/PagesContent';
import RemoveButton from '../../../components/RemoveButton';
import Table from '../../../components/Table';
import UserRoleContent from '../../../components/UserRoleContent';
import { adminName } from '../../../constants/rules';
import { useUserTypes } from './useUserTypes';

export default function UserTypes() {
  const {
    isManageModalOpen,
    isShowEditButton,
    userIdUpdate,
    dirtyFields,
    errors,
    isConfirmDeleteModalOpen,
    errorMessage,
    userType,
    userTypePages,
    currentPageToRemoveId,
    isSelectModalOpen,
    pagesInUse,
    manageModalMainButtonContent,
    currentUserTypeName,
    handleSelectPage,
    handleCloseSelectPageModal,
    handleAddPageClick,
    handleRemovePageButtonClick,
    handleRemoveIconClick,
    clearError,
    handleConfirmDeleteUser,
    handleCloseConfirmDeleteModal,
    handleClearField,
    handleSubmit,
    handleEditButtonClick,
    register,
    handleCloseManageModal,
    handleTableRowClick,
    handleAddUserTypesClick,
    handleButtonOkClick,
    handleDeleteButtonClick,
  } = useUserTypes();

  return (
    <>
      <UserRoleContent
        onAddUserTypesClick={handleAddUserTypesClick}
        onTableRowClick={handleTableRowClick}
      />
      <Modal
        isOpen={isManageModalOpen}
        onClose={handleCloseManageModal}
        cancelIcon={false}
      >
        {currentUserTypeName === adminName && (
          <p className="mt-6 text-center text-sm">
            O usuário "{adminName}" não pode ser editado
          </p>
        )}
        <HeaderModalManageItem
          showDeleteButton={!!userIdUpdate && currentUserTypeName !== adminName}
          deleteButtonContent="Apagar tipo de usuário"
          isEditable={!isShowEditButton}
          onDeleteButtonClick={handleDeleteButtonClick}
          title={userIdUpdate ? 'Tipo de usuário' : 'Novo tipo de usuário'}
          mainButtonContent={manageModalMainButtonContent}
          onCancelClick={handleCloseManageModal}
          onOkClick={
            !isShowEditButton ? handleSubmit(handleButtonOkClick) : undefined
          }
          onEditClick={isShowEditButton ? handleEditButtonClick : undefined}
        />

        <div className="mb-8 mt-12">
          <Input
            type="text"
            placeholder="Tipo"
            disabled={isShowEditButton}
            isDirty={!isShowEditButton && dirtyFields.userType}
            onCancelIconClick={() => handleClearField('userType')}
            {...register('userType')}
            helperText={errors.userType?.message}
            value={userType}
          />
        </div>

        <div>
          {userIdUpdate && <h2 className="mb-6 text-lg">Páginas</h2>}
          {userTypePages && (
            <div className="max-h-[30vh] mb-8 overflow-y-auto">
              {userTypePages?.length > 0 && (
                <Table.Root
                  itemType="Descrições"
                  itemsLength={userTypePages?.length}
                  className="pr-2"
                  sx={{ overflow: 'hidden' }}
                  showNoContentMessage={false}
                >
                  {userTypePages && (
                    <div>
                      {userTypePages.map((userTypePage) => {
                        return (
                          <div className="flex w-full">
                            <Table.Row
                              key={`${userTypePage.id}${userTypePage.page.id}`}
                              className="w-full p-0 pr-4"
                            >
                              <Table.Cell
                                items={userTypePages}
                                type="name"
                                className={`transition-slow ${currentPageToRemoveId === userTypePage.page.id ? 'w-[calc(50%+6.1rem)]' : 'w-1/2'}`}
                                sx={{
                                  // borderRight: '1px solid #ccc',
                                  borderBottom: 'none',
                                  py: '0.5rem',
                                  px: 0,
                                }}
                              >
                                <div className="flex w-full justify-between">
                                  <div>
                                    {!isShowEditButton && (
                                      <RemoveButton
                                        onClick={() =>
                                          handleRemoveIconClick(
                                            userTypePage.page.id,
                                          )
                                        }
                                      />
                                    )}
                                    <span
                                      className="ml-1"
                                      onClick={
                                        () => {}
                                        //  handleDescriptionClick(
                                        //    description.id,
                                        //  )
                                      }
                                    >
                                      {userTypePage.page.name}
                                    </span>
                                  </div>
                                </div>
                              </Table.Cell>
                            </Table.Row>
                            <button
                              onClick={() =>
                                handleRemovePageButtonClick(
                                  userTypePage.page.id,
                                  userTypePage.id,
                                )
                              }
                              className={`transition-slow bg-errorText text-zinc-100 ${currentPageToRemoveId === userTypePage.page.id ? 'w-24 py-2 opacity-100' : 'w-0 opacity-0'}`}
                            >
                              Apagar
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Table.Root>
              )}
              {!isShowEditButton && userIdUpdate && (
                <AddButton
                  content="Adicionar página"
                  onClick={handleAddPageClick}
                  className="mb-2"
                />
              )}
              {/* {canEdit && (
             <>
               <Table.Row className={`px-1`}>
                 <AddButton
                   content="Adicionar descrição"
                   onClick={handleAddDescriptionClick}
                 />
               </Table.Row>
               <div className="pr-2">
                 <div className="my-4 rounded bg-backgroundPrimary px-2 py-4 text-sm text-primary">
                   <button
                     onClick={handleCopyDescriptionsButtonClick}
                     disabled={userTypePages.items.length > 0}
                     className="cursor-pointer hover:underline disabled:cursor-default disabled:no-underline disabled:opacity-60"
                   >
                     Copiar descrições de outro produto
                   </button>
                 </div>
                 <div className="rounded bg-backgroundPrimary px-2 py-4 text-sm text-errorText">
                   <button
                     onClick={handleButtonDeleteAllClick}
                     disabled={userTypePages.items.length <= 0}
                     className="cursor-pointer hover:underline disabled:cursor-default disabled:no-underline disabled:opacity-60"
                   >
                     Apagar todas as descrições
                   </button>
                 </div>
               </div>
             </>
           )} */}
            </div>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={isSelectModalOpen}
        onClose={handleCloseSelectPageModal}
        width="50rem"
        iconContainerStyle={{ top: '1.25rem' }}
      >
        <PagesContent
          onTableRowClick={handleSelectPage}
          isModal
          pagesInUse={pagesInUse}
          // descriptionsInUse={productPartsDescriptionsInUse}
          // baseUrl={descriptionSearchBaseEndpoint}
        />
      </Modal>

      <ConfirmModal
        contentMessage="Deseja excluir esse usuário?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDeleteUser}
        onNotConfirm={handleCloseConfirmDeleteModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
