import { useState, useEffect, useRef, ChangeEvent } from 'react';
import { partsByProductIdPartsGroupIdApiCache } from '../../../constants/requestCacheName';
import { useQuery } from 'react-query';
import {
  getAllPartsGroupItemByPartsGroupIdAndProductId,
  getAllProductsByPartsGroupId,
} from '../../../services/partsGroupItem';

import {
  FindAllPartsGroupItem,
  PartsGroupItem,
} from '../../../types/partsGroupItem';
import { usePartsCatalogStore } from '../../../store/partsCatalog';
import { useParams } from 'react-router-dom';
import { verifyScreenIsLessThan } from '../../../utils/verifyScreenIsLessThan';

export const usePartsCatalogTable = (
  currentPartGroupId: number | undefined,
  onRowClick?: (partId: number) => void,
) => {
  const { productId } = useParams();

  const {
    state: {
      currentPartsGroup,
      currentProductId,
      currentPartClicked,
      shouldShowFirstCurrentPartsGroup,
    },
    actions: {
      setCurrentPartClicked,
    }
  } = usePartsCatalogStore();

  const { data: parts, refetch: refetchParts } =
    useQuery<FindAllPartsGroupItem>(
      `${partsByProductIdPartsGroupIdApiCache}${currentPartsGroup?.id}`,
      async () =>
        (
          await getAllPartsGroupItemByPartsGroupIdAndProductId(
            currentPartsGroup!.id,
            currentProductId!,
          )
        ).data,
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
      },
    );

    useEffect(() => {
      return (
        setCurrentPartClicked(null)
      )
    }, [setCurrentPartClicked])

  const [selectedPart, setSelectedPart] = useState('Selecione a peça');
  const [partsInUse, setPartsInUse] = useState<number[]>([]);

  const tableRef = useRef<HTMLDivElement>(null);

  const partClickedIndex = useRef(0);
  const isTableClicked = useRef(false);

  const isMobile = verifyScreenIsLessThan(1250);

  const [currentPartToDeleteId, setCurrentPartToDeleteId] = useState<
    number | null
  >(null);

  useEffect(() => {
    if (currentPartsGroup || shouldShowFirstCurrentPartsGroup) {
      refetchParts();
    }
  }, [currentPartClicked, currentPartsGroup, refetchParts, shouldShowFirstCurrentPartsGroup]);



  useEffect(() => {
    if (isMobile) {
      if (currentPartClicked) {
        setSelectedPart(
          `${currentPartClicked?.part?.id} - ${currentPartClicked?.part?.name}`,
        );
        return;
      }

      setSelectedPart('Selecione a peça');
    }

    if (!isTableClicked.current) {
      const tableCellHeightInPx = 53;
      const partClickedPosition =
        tableCellHeightInPx * partClickedIndex.current;

      tableRef.current?.scrollTo({
        top: partClickedPosition,
      });
      return;
    }

    isTableClicked.current = false;
  }, [currentPartClicked, isMobile, shouldShowFirstCurrentPartsGroup]);

  useEffect(() => {
    const inUse: number[] = [];

    parts?.items.forEach(({ part }) => {
      if (part.name) inUse.push(part.id);
    });

    setPartsInUse(inUse);
  }, [parts?.items]);

  const handleSelectPartMobile = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedPart(event.target.value);
    const part = parts?.items.find(
      (part) => `${part.part.id} - ${part.part.name}` === event.target.value,
    );
    if (onRowClick) {
      onRowClick(part?.part.id ?? -1);
    }
  };

  const handleTableRowClick = ({ part }: PartsGroupItem) => {
    isTableClicked.current = true;
    if (onRowClick) {
      if (part) {
        onRowClick(part.id);
      }
    }
  };


  const selectProductsService = async () => {
    const data = await getAllProductsByPartsGroupId(currentPartGroupId!);
    return data.data;
  };

  return {
    selectedPart,
    isMobile,
    tableRef,
    partClickedIndex,
    currentPartToDeleteId,
    partsInUse,
    parts: parts?.items,

    currentPartClicked,
    selectProductsService,
    handleSelectPartMobile,

    handleTableRowClick,
  };
};
