import { AxiosError, isAxiosError } from 'axios';
import { useMutation } from 'react-query';
import {
  descriptionApiCache,
  searchCache,
} from '../../../constants/requestCacheName';
import { useQueryCache } from '../../../hooks/useQueryCache';
import {
  deleteDescription,
  saveDescription,
  updateDescription,
} from '../../../services/description';
import { FindAllDescriptions, Description } from '../../../types/description';
import { partsGroupSearchBaseEndpoint } from '../../../constants/endpoints';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';

type MutationProps = {
  id: number;
  name: string;
  type: string;
};

export const useDescriptionFetch = (
  currentDescriptionId: number | null,
  handleCloseMenageModal: () => void,
  handleCloseModal: () => void,
  handleCloseConfirmModal: () => void,
) => {
  const { addItemOnScreen, updateItemOnScreen, removeItemFromScreen } =
    useQueryCache();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: save } = useMutation({
    mutationFn: async ({ name, type }: Omit<MutationProps, 'id'>) => {
      return (await saveDescription(name, type)).data;
    },

    onSuccess: ({ id, name, descriptionType }: Description) => {
      if (id && name && descriptionType) {
        const data = {
          id,
          name,
          descriptionType,
        };
        addItemOnScreen<FindAllDescriptions>(descriptionApiCache, data);
      }
      handleCloseMenageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar descrição',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Descrição já cadastrada');
            return true;
          }
        },
      );
    },
  });

  const { mutate: update } = useMutation({
    mutationFn: async ({ id, name, type }: MutationProps) => {
      return (await updateDescription(id, name, type)).data;
    },

    onSuccess: ({ id, name, descriptionType }: Description) => {
      if (id && name && descriptionType) {
        const data = {
          id,
          name,
          descriptionType,
        };
        updateItemOnScreen<FindAllDescriptions>(descriptionApiCache, data);
      }
      // handleCloseModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar descição',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage(`Já foi cadastrada uma descrição com este nome`);
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteDescriptionMutate } = useMutation({
    mutationFn: async () => {
      if (currentDescriptionId) {
        return (await deleteDescription(currentDescriptionId)).data;
      }

      setErrorMessage('Falha ao deletar descrição');
    },

    onSuccess: () => {
      if (currentDescriptionId) {
        removeItemFromScreen<FindAllDescriptions>(
          `${searchCache}${partsGroupSearchBaseEndpoint}`,
          currentDescriptionId,
        );
      }
      handleCloseMenageModal();
      handleCloseConfirmModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar descrição',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.statusCode === 409
          ) {
            setErrorMessage(
              'Esta descrição só pode ser apagado depois que não houver descrições de produtos e/ou peças associadas a ele',
            );
            return true;
          }
        },
      );
    },
  });

  return {
    errorMessage,
    save,
    update,
    clearError,
    deleteDescriptionMutate,
  };
};
