import TableCell from './TableCell';
import TableCellStatic from './TableCellStatic';
import TableHead from './TableHead';
import TableRoot from './TableRoot';
import TableRow from './TableRow';

const Table = {
  Root: TableRoot,
  Head: TableHead,
  Row: TableRow,
  Cell: TableCell,
  CellStatic: TableCellStatic,
};

export default Table;
