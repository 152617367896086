import {
  FindAllImages,
  Images,
  ImagesPaginated,
  SaveImagesDto,
  VerifyImagesDto,
} from '../types/images';
import { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getImagesPaginated = async (
  page: number,
  direction: 'ASC' | 'DESC' | null = 'ASC',
  limit?: number | null,
  imageType?: string,
) => {
  const url = addSearchParamsInUrl(
    '/images/v1/search',
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    { name: 'imageType', value: imageType },
    { name: 's', value: '' },
  );
  const response = await api.get<ImagesPaginated>(url);
  return response;
};

export const verifyImagesRepeatedByNameAndType = async ({
  imageNames,
  imageType,
}: VerifyImagesDto) => {
  const body = {
    imageNames,
    imageType,
  };

  const response = await api.post<FindAllImages>(
    `/images/v1/verify-repeated`,
    body,
  );
  return response;
};

export const saveImage = async (image: SaveImagesDto) => {
  const response = await api.post<Images>('/images/v1', image);
  return response;
};

export const saveImagesBatch = async (dto: SaveImagesDto[]) => {
  const body = { images: dto };

  const response = await api.post<void>('/images/v1/batch', body);
  return response;
};

export const deleteImage = async (imageId: number) => {
  const response = await api.delete(`/images/v1/${imageId}`);
  return response;
};
