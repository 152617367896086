import HeaderModalManageItem from '../../HeaderModalManageItem';
import Modal from '../../Modal';
import { useLinkProductsPartsDescriptionModal } from './useLinkProductsPartsDescriptionModal';
import Card from '../../Card';
import Table from '../../Table';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import DescriptionContent from '../../DescriptionContent';
import ConfirmModal from '../../ConfirmModal';
import ProductsPartsContent from '../../ProductsPartsContent';
import AddButton from '../../AddButton';
import RemoveButton from '../../RemoveButton';
import Alert from '../../Alert';
import { descriptionSearchByTypeIdBaseEndpoint } from '../../../constants/endpoints';

type LinkProductsPartsDescriptionModalProps = {
  productId?: number;
  productName?: string;
  isModalOpen: boolean;
  typeId: number;
  onModalClose: () => void;
};

export default function LinkProductsPartsDescriptionModal({
  isModalOpen,
  productId,
  productName,
  typeId,
  onModalClose,
}: LinkProductsPartsDescriptionModalProps) {
  const {
    canEdit,
    productsPartsDescriptions,
    currentDescriptionToDeleteId,
    isDescripionModalOpen,
    isConfirmModalOpen,
    isConfirmDeleteAllModalOpen,
    isCopyDescriptionModalOpen,
    errorMessage,
    screenHeight,
    productPartsDescriptionsInUse,
    descriptionService,
    clearError,
    handleSelectProductToCopyDescription,
    handleCloseCopyDescriptionModal,
    handleCopyDescriptionsButtonClick,
    handleConfirmCancel,
    handleCloseConfirmModal,
    handleCloseDescripionModal,
    handleSelectDescription,
    handleRemoveIconClick,
    handleEditButtonClick,
    handleCancelButtonClick,
    handleDragEnd,
    handleTableRowDragStart,
    handleAddDescriptionClick,
    handleChangeOrderInput,
    handleOkButtonClick,
    handleDescriptionClick,
    handleDeleteButtonClick,
    handleButtonDeleteAllClick,
    handleCloseConfirmDeleteAllModal,
    handleConfirmDeleteAll,
  } = useLinkProductsPartsDescriptionModal(productId, typeId, onModalClose);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onModalClose}
      cancelIcon={false}
      width="60rem"
      contentStyle="p-8"
    >
      <HeaderModalManageItem
        isEditable={canEdit}
        title="Descrição"
        mainButtonContent={!canEdit ? 'Editar' : 'Ok'}
        onCancelClick={handleCancelButtonClick}
        onOkClick={canEdit ? handleOkButtonClick : undefined}
        onEditClick={handleEditButtonClick}
      />
      <Card.Root
        className="p-0"
        contentClassName="p-0"
        sx={{ borderRadius: 'none' }}
      >
        <Card.Infos
          productId={productId ?? ''}
          productName={productName ?? ''}
        />
        <Card.Table
          productId={productId ?? ''}
          className={`mt-8 ${canEdit ? 'hidden' : 'block'}`}
        />
        {canEdit && (
          <div
            data-smHeight={screenHeight < 850}
            data-2xsmHeight={screenHeight < 700}
            className="max-h-[70vh] overflow-x-auto data-[2xSmHeight=true]:h-[60vh] data-[SmHeight=true]:h-[65vh]"
          >
            <Table.Root
              itemType="Descrições"
              itemsLength={productsPartsDescriptions?.length}
              className="pr-2"
              sx={{ overflow: 'hidden' }}
              showNoContentMessage={false}
            >
              {productsPartsDescriptions &&
                productsPartsDescriptions?.length > 0 && (
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable
                      droppableId="descriptions"
                      type="list"
                      direction="vertical"
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {productsPartsDescriptions.map(
                            (
                              { description, amount, product, order },
                              index,
                            ) => {
                              return (
                                <Draggable
                                  draggableId={`${description.id}${product.id}`}
                                  index={index}
                                  key={`${description.id}${product.id}`}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="flex w-full"
                                      onDragStart={handleTableRowDragStart}
                                    >
                                      <Table.Row
                                        key={description.id}
                                        className="w-full p-0 pr-4"
                                      >
                                        <Table.Cell
                                          items={productsPartsDescriptions}
                                          type="name"
                                          className={`transition-slow ${currentDescriptionToDeleteId === description.id ? 'w-[calc(50%+6.1rem)]' : 'w-1/2'}`}
                                          sx={{
                                            borderRight: '1px solid #ccc',
                                            borderBottom: 'none',
                                            py: '0.5rem',
                                            px: '0.25rem',
                                          }}
                                        >
                                          <div className="flex w-full justify-between">
                                            <div>
                                              <RemoveButton
                                                onClick={() =>
                                                  handleRemoveIconClick(
                                                    description.id,
                                                  )
                                                }
                                              />
                                              <span
                                                className="ml-1 text-primary"
                                                onClick={() =>
                                                  handleDescriptionClick(
                                                    description.id,
                                                  )
                                                }
                                              >
                                                {description.name}
                                              </span>
                                            </div>
                                            <ChevronRightIcon
                                              onClick={() =>
                                                handleDescriptionClick(
                                                  description.id,
                                                )
                                              }
                                              className="text-gray-400"
                                            />
                                          </div>
                                        </Table.Cell>
                                        <Table.Cell
                                          items={productsPartsDescriptions}
                                          type="desc"
                                          className="w-1/2 justify-between"
                                        >
                                          <div className="flex w-full items-center justify-between gap-2">
                                            <input
                                              onChange={(e) =>
                                                handleChangeOrderInput(
                                                  description.id,
                                                  e,
                                                )
                                              }
                                              className="ml-2 flex-1 outline-none"
                                              value={amount}
                                            />
                                            <img
                                              src="/icons/drag.png"
                                              alt="Ícone para arrastar Descrição"
                                              className="size-5 cursor-grab"
                                            />
                                            {/* {order} */}
                                          </div>
                                        </Table.Cell>
                                      </Table.Row>
                                      <button
                                        onClick={() =>
                                          handleDeleteButtonClick({
                                            descriptionId: description.id,
                                            productId: product.id,
                                          })
                                        }
                                        className={`transition-slow bg-errorText text-zinc-100 ${currentDescriptionToDeleteId === description.id ? 'w-24 py-2 opacity-100' : 'w-0 opacity-0'}`}
                                      >
                                        Apagar
                                      </button>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            },
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              {/* {canEdit && (
              <Table.Row
                className={`px-1 ${productsPartsDescriptions?.length <= 0 ? '' : ''}`}
              >
                <div onClick={handleAddDescriptionClick}>
                  <AddCircleIcon className="text-green-500" />
                  <span className="text-sm ml-1 hover:text-primary hover:underline">
                    Adicionar descrição
                  </span>
                </div>
              </Table.Row>
            )} */}
            </Table.Root>
            {canEdit && (
              <>
                <Table.Row className={`px-1`}>
                  <AddButton
                    content="Adicionar descrição"
                    onClick={handleAddDescriptionClick}
                  />
                </Table.Row>
                <div className="pr-2">
                  <div className="my-4 rounded bg-backgroundPrimary px-2 py-4 text-sm text-primary">
                    <button
                      onClick={handleCopyDescriptionsButtonClick}
                      disabled={productsPartsDescriptions.length > 0}
                      className="cursor-pointer hover:underline disabled:cursor-default disabled:no-underline disabled:opacity-60"
                    >
                      Copiar descrições de outro produto
                    </button>
                  </div>
                  <div className="rounded bg-backgroundPrimary px-2 py-4 text-sm text-errorText">
                    <button
                      onClick={handleButtonDeleteAllClick}
                      disabled={productsPartsDescriptions.length <= 0}
                      className="cursor-pointer hover:underline disabled:cursor-default disabled:no-underline disabled:opacity-60"
                    >
                      Apagar todas as descrições
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </Card.Root>

      <Modal
        isOpen={isCopyDescriptionModalOpen}
        onClose={handleCloseCopyDescriptionModal}
        width="50rem"
      >
        <ProductsPartsContent
          handleTableRowClick={handleSelectProductToCopyDescription}
          isModal
        />
      </Modal>

      <Modal
        isOpen={isDescripionModalOpen}
        onClose={handleCloseDescripionModal}
        width="50rem"
        height="80vh"
        iconContainerStyle={{ top: '0' }}
      >
        <DescriptionContent
          handleTableRowClick={handleSelectDescription}
          isModal
          service={descriptionService}
          descriptionsInUse={productPartsDescriptionsInUse}
          baseUrl={`${descriptionSearchByTypeIdBaseEndpoint}/${typeId}`}
        />
      </Modal>

      <ConfirmModal
        isModalOpen={isConfirmModalOpen}
        contentMessage="As alterações feitas serão perdidas. Deseja prosseguir?"
        onCloseModal={handleCloseConfirmModal}
        onConfirm={handleConfirmCancel}
        onNotConfirm={handleCloseConfirmModal}
      />

      <ConfirmModal
        isModalOpen={isConfirmDeleteAllModalOpen}
        contentMessage="Deseja excluir todas as descrições desta peça/produto?"
        onCloseModal={handleCloseConfirmDeleteAllModal}
        onConfirm={handleConfirmDeleteAll}
        onNotConfirm={handleCloseConfirmDeleteAllModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </Modal>
  );
}
