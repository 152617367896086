import { Link } from 'react-router-dom';
import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import { DatePicker } from '../../../components/DatePicker';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import NewsContent from '../../../components/NewsContent';
import { newsCache } from '../../../constants/requestCacheName';
import { useUploadNews } from './useUploadNews';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import HTMLFlipBook from 'react-pageflip';
import PreviewLabel from '../../../components/PreviewImageLabel';

export default function UploadNews() {
  const {
    isMenageModalOpen,
    newsNameUpdate,
    pages,
    pdfBook,
    screenHeight,
    isConfirmDeleteModalOpen,
    previewNews,
    newsTypes,
    dirtyFields,
    errors,
    errorMessage,
    isNewsLoading,
    isNewsDeleteLoading,
    isShowEditButton,
    newsIdUpdate,
    files,
    isValidUrl,
    referenceValue,
    date,
    handleInputChange,
    handleSelectDate,
    handleEdit,
    handleConfirmDeleteNews,
    handleCloseConfirmDeleteModal,
    clearError,
    handleCancelIconClick,
    handleSubmit,
    register,
    handleButtonOkClick,
    handleDeleteButtonClick,
    handleCloseMenageModal,
    handleTableRowClick,
    handleAddNewsClick,
  } = useUploadNews();

  return (
    <>
      <NewsContent
        queryKey={newsCache}
        onTableRowClick={handleTableRowClick}
        onAddNewsClick={handleAddNewsClick}
      />

      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseMenageModal}
        cancelIcon={false}
        height="95vh"
        contentStyle="max-h-[900px]"
      >
        <HeaderModalManageItem
          showDeleteButton={!!newsIdUpdate}
          deleteButtonContent={
            isNewsDeleteLoading ? (
              <div>
                <Loading />
              </div>
            ) : (
              'Apagar notícia'
            )
          }
          isEditable={!isShowEditButton}
          onDeleteButtonClick={
            !isNewsDeleteLoading ? handleDeleteButtonClick : undefined
          }
          title={newsNameUpdate ? 'Notícia' : 'Nova Notícia'}
          mainButtonContent={
            isNewsLoading ? (
              <div className="absolute bottom-6 right-4 top-3">
                <Loading />
              </div>
            ) : isShowEditButton ? (
              'Editar'
            ) : (
              'Ok'
            )
          }
          onEditClick={isShowEditButton ? handleEdit : undefined}
          onCancelClick={handleCloseMenageModal}
          onOkClick={
            !isNewsLoading ? handleSubmit(handleButtonOkClick) : undefined
          }
        />

        <div className="bottom-14 mt-5 flex flex-col gap-5">
          <Input
            maxLength={90}
            isDirty={dirtyFields.reference}
            type="text"
            id="reference"
            label="Nome da notícia"
            disabled={isShowEditButton || files?.length > 1}
            helperText={errors.reference?.message}
            onCancelIconClick={() => handleCancelIconClick('reference')}
            value={referenceValue || ''}
            {...register('reference')}
          />
          <div className="w-full">
            <label className="text-sm">Tipo de notícia</label>
            <select
              className="w-full border-b border-solid outline-none"
              disabled={isShowEditButton}
              {...register('type')}
            >
              {newsTypes?.items.map((imageType) => (
                <option key={imageType.id} value={imageType.name}>
                  {imageType.name}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full">
            <DatePicker
              onInputChange={handleInputChange}
              isDirty={dirtyFields.date && !isShowEditButton}
              date={date}
              onDateSelect={handleSelectDate}
              label="Selecione uma data"
              onCancelIconClick={() => handleCancelIconClick('date')}
              disabled={isShowEditButton}
            />
            <span className="text-xs text-red-600">{errors.date?.message}</span>
          </div>
          {files[0]?.type?.includes('application/pdf') && (
            <div className={`flex w-[80%] items-center justify-start gap-3`}>
              <div className="flex items-center justify-center gap-2">
                <input
                  type="radio"
                  value="pdf_normal"
                  {...register('pdfBook')}
                  disabled={isShowEditButton}
                  style={{
                    width: '16px',
                    height: '16px',
                    cursor: 'pointer',
                  }}
                />
                <label>Pdf normal</label>
              </div>
              <div className="flex items-center justify-center gap-2">
                <input
                  type="radio"
                  value="pdf_book"
                  {...register('pdfBook')}
                  disabled={isShowEditButton}
                  style={{
                    width: '16px',
                    height: '16px',
                    cursor: 'pointer',
                  }}
                />
                <label>Pdf livro</label>
              </div>
            </div>
          )}

          <div>
            <PreviewLabel htmlFor="input-file">
              {(files?.length <= 1 || !files) && (
                <>
                  {previewNews ? (
                    <div className="h-full w-full">
                      {isValidUrl === null ? (
                        <p className="text-secondaryText">
                          Verificando link...
                        </p>
                      ) : isValidUrl ? (
                        files[0]?.type.includes('mp4') ? (
                          <video title="PDF Preview" controls>
                            <source
                              src={previewNews}
                              type="video/mp4"
                              height={100}
                            />
                          </video>
                        ) : pdfBook === 'pdf_book' ? (
                          <div
                            className={`flex h-full w-full items-center ${pages.length === 1 ? 'justify-end pl-40' : 'justify-center'} overflow-hidden bg-slate-400`}
                          >
                            <HTMLFlipBook
                              width={220}
                              height={284}
                              size={`fixed`}
                              minWidth={100}
                              maxWidth={350}
                              minHeight={100}
                              maxHeight={550}
                              startPage={0}
                              drawShadow={true}
                              flippingTime={1000}
                              usePortrait={true}
                              autoSize={true}
                              startZIndex={10}
                              maxShadowOpacity={0.5}
                              mobileScrollSupport={true}
                              clickEventForward={true}
                              useMouseEvents={false}
                              swipeDistance={50}
                              showPageCorners={true}
                              disableFlipByClick={false}
                              showCover={false}
                              style={{}}
                              className={`mb-6 rounded-lg`}
                            >
                              {pages.map((page, index) => (
                                <div key={index} className="shadow-lg">
                                  <img
                                    src={page}
                                    alt={`Page ${index + 1}`}
                                    className="rounded-lg shadow-lg"
                                  />
                                </div>
                              ))}
                            </HTMLFlipBook>
                          </div>
                        ) : (
                          <div className="h-full w-full">
                            <iframe
                              src={previewNews}
                              title="Media Preview"
                              className="h-full w-full"
                            />
                          </div>
                        )
                      ) : (
                        <>
                          <p className="text-secondaryText">
                            Não foi possível carregar a visualização. Porém é
                            possível vincular novo(s) arquivo(s).
                          </p>

                          <div className="mt-5 flex justify-center">
                            <FileUploadIcon
                              fontSize="large"
                              className="cursor-pointer text-secondaryText"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      <div className="flex items-center">
                        <div className="text-lg text-secondaryText">
                          Carregue o(s) arquivo(s)
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {files?.length > 1 && (
                <ul className="flex size-full max-h-[300px] flex-col gap-4 overflow-auto px-4">
                  {files.map((file) => (
                    <li key={file.name}>{file.name}</li>
                  ))}
                </ul>
              )}

              <input
                type="file"
                id="input-file"
                accept="application/pdf,video/mp4,image/svg+xml"
                multiple
                {...register('news')}
                disabled={isShowEditButton}
                className="absolute flex cursor-pointer opacity-0 enabled:h-[350px] enabled:w-full"
              />
            </PreviewLabel>
            <span className="text-xs text-red-600">{errors.news?.message}</span>

            {pdfBook === 'pdf_book' &&
              files[0]?.type?.includes('application/pdf') && (
                <div className="mt-3 w-full text-right text-[#42a0de]">
                  <Link
                    to={`/image/${encodeURIComponent(previewNews)}`}
                    target="_blank"
                  >
                    Pré-visualização
                  </Link>
                </div>
              )}
          </div>
        </div>
      </Modal>

      <ConfirmModal
        contentMessage="Deseja excluir essa notícia?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDeleteNews}
        onNotConfirm={handleCloseConfirmDeleteModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
