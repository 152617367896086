import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import ImageExtensionContent from '../../../components/ImageExtensionContent';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import { useImageExtension } from './useImageExtension';

export default function ImageExtension() {
  const {
    extension,
    name,
    isManageModalOpen,
    imageExtensionIdUpdate,
    isShowEditButton,
    dirtyFields,
    errors,
    isConfirmDeleteModalOpen,
    errorMessage,
    handleFileTypeClick,
    handleConfirmDeleteSignature,
    clearError,
    handleCloseConfirmDeleteModal,
    register,
    handleClearField,
    handleButtonOkClick,
    handleEditButtonClick,
    handleDeleteButtonClick,
    handleTableRowClick,
    handleAddImageExtensionClick,
    handleCloseManageModal,
  } = useImageExtension();

  return (
    <>
      <ImageExtensionContent
        onTableRowClick={handleTableRowClick}
        onAddImageExtensionClick={handleAddImageExtensionClick}
      />

      <Modal
        isOpen={isManageModalOpen}
        onClose={handleCloseManageModal}
        cancelIcon={false}
      >
        <HeaderModalManageItem
          showDeleteButton={!!imageExtensionIdUpdate}
          deleteButtonContent="Apagar extensão"
          isEditable={!isShowEditButton}
          onDeleteButtonClick={handleDeleteButtonClick}
          title={imageExtensionIdUpdate ? 'Extensão' : 'Nova extensão'}
          mainButtonContent={
            imageExtensionIdUpdate && isShowEditButton ? 'Editar' : 'Ok'
          }
          onCancelClick={handleCloseManageModal}
          onOkClick={!isShowEditButton ? handleButtonOkClick : undefined}
          onEditClick={isShowEditButton ? handleEditButtonClick : undefined}
        />

        <div className="my-12 flex flex-col gap-4">
          <Input
            type="text"
            placeholder="Nome"
            disabled={isShowEditButton}
            isDirty={!isShowEditButton && dirtyFields.name}
            onCancelIconClick={() => handleClearField('name')}
            {...register('name')}
            helperText={errors.name?.message}
            value={name}
          />
          <Input
            type="text"
            placeholder="Extensão"
            disabled={isShowEditButton}
            isDirty={!isShowEditButton && dirtyFields.extension}
            onCancelIconClick={() => handleClearField('extension')}
            {...register('extension')}
            helperText={errors.extension?.message}
            value={extension}
          />
          <div>
            <span className="text-xs text-gray-500 mb-2">Tipo de arquivo</span>
            <select
              className="w-full border-b border-solid outline-none"
              disabled={isShowEditButton}
              {...register('fileType', {
                onChange: (event) => handleFileTypeClick(event.target.value),
              })}
            >
              <option value="Imagem" className="text-sm">
                Imagem
              </option>
              <option value="Documento" className="text-sm">
                Documento
              </option>
            </select>
            <span className="text-xs text-red-600">
              {errors.fileType?.message}
            </span>
          </div>
        </div>
      </Modal>

      <ConfirmModal
        contentMessage="Deseja excluir essa extensão?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDeleteSignature}
        onNotConfirm={handleCloseConfirmDeleteModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
