import { useTheme } from '@emotion/react';
import { useRef, useState, useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  linesCache,
  categoriesCache,
  productsLineCategoryCache,
} from '../../../constants/requestCacheName';
import { useError } from '../../../hooks/useError';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { useTitle } from '../../../hooks/useTitle';
import generateLinePartsCatalogPdf from '../../../pdf/linePartsCatalogPdf/generateLinePartsCatalogPdf';
import { getAllCategoriesByLineId } from '../../../services/categories';
import { getAllLines } from '../../../services/lines';
import { getProductSortByCategoryId } from '../../../services/products';
import { Categories } from '../../../types/categories';
import { Lines } from '../../../types/lines';
import { ProductsSort } from '../../../types/products';
import { useSearchParams } from '../../../hooks/useSearchParams';

export const useProductsCatalog = () => {
  useTitle('Catálogo de produtos');

  const theme = useTheme();
  const navigate = useNavigate();

  const [lineId, categoryId] = useSearchParams('lineId', 'categoryId');

  const currentLineId = useRef(lineId ? +lineId : 1);
  const currentCategoryId = useRef<number>(categoryId ? +categoryId : 1);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { data: lines } = useQuery<Lines>(
    linesCache,
    async () => (await getAllLines({ sortBy: 'id' })).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: categories, refetch: refetchCategories } = useQuery<Categories>(
    `${categoriesCache}${currentLineId.current}`,
    async () => (await getAllCategoriesByLineId(currentLineId.current)).data,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
    },
  );

  const { clearCache } = useQueryCache();

  const { data: products, refetch: refetchProducts } = useQuery<ProductsSort>(
    productsLineCategoryCache,
    async () =>
      (await getProductSortByCategoryId(currentCategoryId.current)).data,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
    },
  );

  const [productDescriptionSelectedId, setProductDescriptionSelectedId] =
    useState<number | null>(null);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const handleFetchCategories = useCallback(
    async (lineId: number) => {
      if (lineId && lineId !== currentLineId.current) {
        currentLineId.current = Number(lineId);
        refetchCategories();
        return;
      }

      if (!lineId) {
        clearCache(`${categoriesCache}${currentLineId.current}`);
      }
    },
    [clearCache, refetchCategories],
  );

  useEffect(() => {
    if (!lineId && lines) {
      currentLineId.current = Number(lines?.items[0].id);
      navigate({ search: `lineId=${lines?.items[0].id}` });
    }
    refetchCategories();
  }, [lineId, lines, navigate, refetchCategories]);

  useEffect(() => {
    if ((categories?.items.length ?? 0) <= 0) {
      clearCache(productsLineCategoryCache);
      return;
    }

    if (!categoryId) {
      currentCategoryId.current = Number(categories?.items[0]?.id);
    }
    refetchProducts();
  }, [categories, categoryId, clearCache, refetchProducts]);

  const handleFetchProducts = async (categoryId: number) => {
    if (categoryId !== currentCategoryId.current && categoryId) {
      currentCategoryId.current = categoryId;
      refetchProducts();
      return;
    }
  };

  const handleGeneratePdf = async () => {
    try {
      setIsPdfLoading(true);
      const lineName = lines?.items.find(
        (line) => line.id === Number(lineId),
      )?.name;
      await generateLinePartsCatalogPdf(
        Number(lineId),
        lineName ?? '',
        setErrorMessage,
      );
      setIsPdfLoading(false);
    } catch (error) {
      setErrorMessage('Falha ao gerar PDF');
    } finally {
      setIsPdfLoading(false);
    }
  };

  const handleOpenModal = (productId: number) => {
    setProductDescriptionSelectedId(productId);
  };

  const handleCloseModal = () => {
    setProductDescriptionSelectedId(null);
  };

  return {
    theme,
    productDescriptionSelectedId,
    categories,
    products,
    lines,
    isPdfLoading,
    errorMessage,
    clearError,
    handleFetchCategories,
    handleFetchProducts,
    handleOpenModal,
    handleCloseModal,
    handleGeneratePdf,
  };
};
