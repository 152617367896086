import { useMutation, useQuery } from 'react-query';
import { productDuimpCache } from '../../constants/requestCacheName';
import { useHandleTable } from '../../hooks/useHandleTable';
import {
  FindAllProductsDuimp,
  LogVinculate,
  ProductDuimp,
  ResponseLaunchProduct,
} from '../../types/productDuimp';
import { getAllProductsToAdd, launchProducts, vinculateCompanies } from '../../services/duimp';
import { useModal } from '../../hooks/useModal';
import { mutationErrorHandling } from '../../utils/errorHandling';
import { useError } from '../../hooks/useError';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useDuimpContent = () => {
  const navigate = useNavigate();

  const {
    data: productListDuimp,
    isFetching: isProductDuimpFetching,
    refetch: refetchProductList,
  } = useQuery<FindAllProductsDuimp>(
    productDuimpCache,
    async () => (await getAllProductsToAdd()).data,
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const {
    itemsShown,
    isSearchInputDirty,
    inputRef,
    sortField,
    pagination,
    currentPage,
    handleChangePage,
    handleChangePagination,
    itemsShownLength: productListDuimpShownLength,
    itemsLength: productListDuimpLength,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
  } = useHandleTable(productListDuimp?.length ?? 0, productListDuimp ?? []);
  const productListDuimpShown: ProductDuimp[] = itemsShown;

  const isFetching = isProductDuimpFetching;

  const {
    isModalOpen: isConfirmLaunchModalOpen,
    handleCloseModal: closeConfirmLaunchModal,
    handleOpenModal: handleOpenConfirmLaunchModal,
  } = useModal();

  const {
    isModalOpen: isConfirmResponseModalOpen,
    handleCloseModal: closeConfirmResponseModal,
    handleOpenModal: handleOpenConfirmResponseModal,
  } = useModal();

  const {
    isModalOpen: isConfirmResponseVinculateModalOpen,
    handleCloseModal: closeConfirmResponseVinculateModal,
    handleOpenModal: handleOpenConfirmResponseVinculateModal,
  } = useModal();

  const {
    isModalOpen: isConfirmWaitModalOpen,
    handleCloseModal: closeConfirmWaitModal,
    handleOpenModal: handleOpenConfirmWaitModal,
  } = useModal();

  const {
    isModalOpen: isConfirmVinculateModalOpen,
    handleCloseModal: closeConfirmVinculateModal,
    handleOpenModal: handleOpenConfirmVinculateModal,
  } = useModal();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const handleLaunchProducts = () => {
    handleOpenConfirmLaunchModal();
  };

  const handleCloseConfirmLaunchModal = () => {
    closeConfirmLaunchModal();
  };

  const handleVinculateCompanyProduct = () => {
    handleOpenConfirmVinculateModal();
  }

  const handleCloseConfirmVinculateModal = () => {
    closeConfirmVinculateModal();
  }

  const handleCloseConfirmResponseModal = () => {
    closeConfirmResponseModal();
  };

  const handleCloseConfirmResponseVinculateModal = () => {
    closeConfirmResponseVinculateModal();
  }

  const handleCloseConfirmWaitModal = () => {
    closeConfirmWaitModal();
  };

  const handleConfirmLaunch = () => {
    if (productListDuimp && productListDuimp?.length <= 0) return;

    setIsLoading(true);
    launchProductsDuimp();
    handleCloseConfirmLaunchModal();
    handleOpenConfirmWaitModal();
  };

  const handleConfirmVinculate = () => {
    setIsLoading(true);
    vinculateCompaniesDuimp();
    handleCloseConfirmVinculateModal();
    handleOpenConfirmWaitModal();
  };

  const { mutate: vinculateCompaniesDuimp } = useMutation({
    mutationFn: async () => await vinculateCompanies(),

    onSuccess: (data) => {
      const responseDataVinculate: LogVinculate = {
        totalFabricantesComSucesso: data.data.totalFabricantesComSucesso,
        totalFabricantesComErro: data.data.totalFabricantesComErro,
        fabricantesComSucesso: data.data.fabricantesComSucesso,
        fabricantesComErro: data.data.fabricantesComErro,
      };
      setIsLoading(false);
      setResponseDataVinculate(responseDataVinculate);
      handleOpenConfirmResponseVinculateModal();
      handleCloseConfirmWaitModal();
    },
    onError: (error) => {
      mutationErrorHandling(error, 'Falha ao vincular os dados', setErrorMessage);
      setIsLoading(false);
      handleCloseConfirmWaitModal();
    },
  });

  const [responseDataVinculate, setResponseDataVinculate] = useState<LogVinculate>();
  const [responseData, setResponseData] = useState<ResponseLaunchProduct>();

  const { mutate: launchProductsDuimp } = useMutation({
    mutationFn: async () => await launchProducts(),

    onSuccess: (data) => {
      const responseData: ResponseLaunchProduct = {
        produtosComSucesso: data.data.produtosComSucesso,
        produtosComErro: data.data.produtosComErro,
        fabricantesComSucesso: data.data.fabricantesComSucesso,
        fabricantesComErro: data.data.fabricantesComErro,
        totalProdutosComSucesso: data.data.totalProdutosComSucesso,
        totalProdutosComErro: data.data.totalProdutosComErro,
        totalFabricantesComSucesso: data.data.totalFabricantesComSucesso,
        totalFabricantesComErro: data.data.totalFabricantesComErro,
      };
      refetchProductList();
      setIsLoading(false);
      setResponseData(responseData);
      handleOpenConfirmResponseModal();
      handleCloseConfirmWaitModal();
    },
    onError: (error) => {
      mutationErrorHandling(error, 'Falha ao lançar os dados', setErrorMessage);
      setIsLoading(false);
      handleCloseConfirmWaitModal();
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  return {
    isFetching,
    productListDuimp,
    productListDuimpShown,
    isSearchInputDirty,
    inputRef,
    sortField,
    productListDuimpLength,
    productListDuimpShownLength,
    pagination,
    currentPage,
    isConfirmLaunchModalOpen,
    errorMessage,
    isConfirmResponseModalOpen,
    responseData,
    isLoading,
    isConfirmWaitModalOpen,
    isConfirmVinculateModalOpen,
    responseDataVinculate,
    isConfirmResponseVinculateModalOpen,
    handleCloseConfirmResponseVinculateModal,
    handleCloseConfirmWaitModal,
    navigate,
    handleCloseConfirmResponseModal,
    clearError,
    handleChangePage,
    handleChangePagination,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
    handleLaunchProducts,
    handleCloseConfirmLaunchModal,
    handleConfirmLaunch,
    handleVinculateCompanyProduct,
    handleCloseConfirmVinculateModal,
    handleConfirmVinculate
  };
};
