import { create } from 'zustand';

type ProductStoreProps = {
  state: {
    isUneditable: boolean;
  };
  actions: {
    edit: () => void;
    unedit: () => void;
  };
};

export const useProductStore = create<ProductStoreProps>((set) => {
  const initialState = {
    isUneditable: false,
  };

  return {
    state: initialState,
    actions: {
      edit: () => {
        set((state) => ({
          state: {
            ...state.state,
            isUneditable: true,
          },
        }));
      },
      unedit: () => {
        set((state) => ({
          state: {
            ...state.state,
            isUneditable: false,
          },
        }));
      },
    },
  };
});
