import { useQuery } from 'react-query';
import { getImageTypesPaginated } from '../../services/imageTypes';
import { ImageType, ImageTypesPaginated } from '../../types/imageTypes';
import { imageTypesCache } from '../../constants/requestCacheName';
import { useHandleTable } from '../../hooks/useHandleTable';

export const useImageTypesContent = () => {
  const {
    data: imageTypes,
    isFetching: isImageTypesFetching,
    refetch: refetchImageTypes,
  } = useQuery<ImageTypesPaginated>(
    imageTypesCache,
    async () => (await getImageTypesPaginated()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    sortField,
    inputRef,
    itemsShown,
    handleSearch: search,
    handleSortTable,
    handleCancelSearch,
    itemsShownLength: imageTypesShownLength,
    isSearchInputDirty,
    itemsLength: imageTypesLength,
  } = useHandleTable(
    imageTypes?.meta.totalItems ?? 0,
    imageTypes?.items ?? [],
    refetchImageTypes,
  );
  const imageTypesShown: ImageType[] = itemsShown;

  const handleSearch = () => {
    search();
  };

  const isFetching = isImageTypesFetching;

  return {
    imageTypes,
    isFetching,
    imageTypesShown,
    sortField,
    inputRef,
    imageTypesShownLength,
    isSearchInputDirty,
    imageTypesLength,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
  };
};
