import { useQuery } from 'react-query';
import { userTypesCache } from '../../constants/requestCacheName';
import { getAllUserTypes } from '../../services/userType';
import { FindAllUserTypes, UserType } from '../../types/userType';
import { useHandleTable } from '../../hooks/useHandleTable';
import formatSearch from '../../utils/formatSearch';

export const useUserRoleContent = () => {
  const { data: userTypes, isFetching: isFetchingUsersTypes } =
    useQuery<FindAllUserTypes>(
      userTypesCache,
      async () => (await getAllUserTypes()).data,
      {
        retry: false,
      },
    );

  const {
    sortField,
    currentPage,
    inputRef,
    isSearchInputDirty,
    pagination,
    itemsShownLength: userTypesShownLength,
    itemsShown,
    handleSearch: search,
    handleSortTable,
    handleCancelSearch,
    dirtySearchInput,
    handleChangePage,
    handleChangePagination,
  } = useHandleTable(userTypes?.meta.totalItems ?? 0, userTypes?.items ?? []);
  const userTypesShown: UserType[] = itemsShown;

  const isFetching = isFetchingUsersTypes;

  const handleSearch = () => {
    search<UserType>((item, formatedSearch) => {
      return formatSearch(item.type?.toString()).includes(formatedSearch);
    });
  };

  return {
    sortField,
    currentPage,
    inputRef,
    isSearchInputDirty,
    pagination,
    userTypes,
    userTypesShown,
    userTypesLength: userTypes?.meta.totalItems ?? 0,
    userTypesShownLength,
    isFetching,
    handleChangePage,
    handleChangePagination,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
    dirtySearchInput,
  };
};
