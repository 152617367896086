import { useQuery } from 'react-query';
import { partsPaginatedCache } from '../../constants/requestCacheName';
import { useHandleTable } from '../../hooks/useHandleTable';
import { getAllParts, getPartsPaginated } from '../../services/parts';
import { FindAllParts, PartsPaginated } from '../../types/parts';
import { useEffect, useMemo, useRef, useState } from 'react';
import generatePartsPdf from '../../pdf/partsPdf/generatePartsPdf';
import { getAuth } from '../../utils/auth';
import { useTheme } from '@mui/material';
import { partSearchBaseEndpoint } from '../../constants/endpoints';
import { verifyScreenIsLessThan } from '../../utils/verifyScreenIsLessThan';
import { useError } from '../../hooks/useError';
import { verifyProductHasImageByProductId } from '../../services/products';
import { ProductHasImage } from '../../types/products';
import { Category } from '../../types/categories';
import { getTableColumnWidthByBiggestElement } from '../../utils/tableColumnStyles';
import { Role } from '../../types/userType';

type PartClean = {
  id: number | string;
  name: string;
  isAvailable: boolean;
  new: boolean;
  reviewedApproved: boolean;
  outOfLine: boolean;
};

export const usePartsContent = () => {
  const {
    data: parts,
    isFetching: isFetchingParts,
    refetch: refetchPartsPaginated,
  } = useQuery<PartsPaginated>(
    partsPaginatedCache,
    async () => (await getPartsPaginated(currentPage + 1)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const [partsHasImage, setPartsHasImage] = useState<ProductHasImage[]>([]);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const isAllParts = useRef(false);

  const auth = useMemo(() => getAuth(), []);

  const isAdmin = useMemo(
    () => auth && auth.user.role.type === Role.ADMIN,
    [auth],
  );

  const {
    data: allParts,
    isFetching: isFetchingAllParts,
    refetch: refetchAllParts,
  } = useQuery<FindAllParts>(
    partsPaginatedCache,
    async () => (await getAllParts()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,

      onSuccess: (data) => {
        defineItemsLength(data.meta.totalItems);
      },
    },
  );

  const originalParts = useMemo<PartClean[]>(() => {
    if (!isAllParts.current && parts) {
      return parts.items?.map((part) => ({
        id: part.id,
        name: part.name.trim().replace(/\s+/g, ' '),
        isAvailable: !part.outOfLine && part.reviewedApproved,
        new: part.new,
        outOfLine: part.outOfLine,
        reviewedApproved: part.reviewedApproved,
      }));
    }

    if (isAllParts.current && allParts) {
      return allParts.items.map((part) => ({
        id: part.id,
        name: part.name.trim().replace(/\s+/g, ' '),
        isAvailable: !part.outOfLine && part.reviewedApproved,
        new: part.new,
        outOfLine: part.outOfLine,
        reviewedApproved: part.reviewedApproved,
      }));
    }

    return [];
  }, [allParts, parts]);

  const getPartsLength = (() => {
    return Number(
      isAllParts.current ? allParts?.meta.totalItems : parts?.meta.totalItems,
    );
  })();

  const verifyProductWithoutImage = async (parts: any[]) => {
    const partsHasImage: ProductHasImage[] = await Promise.all(
      parts.map((part) => verifyProductHasImageByProductId(part.id, 'parts')),
    );

    setPartsHasImage(partsHasImage);
  };

  const {
    currentPage,
    sortField,
    inputRef,
    pagination,
    isSearch,
    isSearchFetching,
    itemsShown: partsShown,
    endPageRef,
    isLoadMoreFetching,
    handleEnterClickSearchInput,
    handleChangePage,
    handleChangePagination,
    handleSearch: search,
    handleSortTable,
    setItemsShown: setPartsShown,
    handleCancelSearch,
    handleSearchDatabase,
    defineItemsLength,
    itemsShownLength: partsShownLength,
    isSearchInputDirty,
    itemsLength: partsLength,
  } = useHandleTable(
    getPartsLength,
    originalParts,
    refetchPartsPaginated,
    refetchAllParts,
    partSearchBaseEndpoint,
    getPartsPaginated,
  );

  const nameColWidth = useMemo(
    () =>
      getTableColumnWidthByBiggestElement<Category>(
        partsShown ?? [],
        'name',
        10,
      ),
    [partsShown],
  );

  useEffect(() => {
    setPartsShown(originalParts);
  }, [originalParts, setPartsShown]);

  useEffect(() => {
    verifyProductWithoutImage(partsShown);
  }, [partsShown]);

  const handleGeneratePdf = () => {
    generatePartsPdf(
      partsShown,
      {
        itemsShownNumber: partsShownLength,
        pageNumber: currentPage + 1,
        pagination: pagination === 100 ? 'Paginado' : 'Todas',
        totalItemsNumber: getPartsLength,
      },
      inputRef.current?.value,
      setErrorMessage,

    );
  };

  const handleSearch = () => {
    search();
  };

  const theme = useTheme();

  const isFetching = isFetchingParts || isFetchingAllParts || isSearchFetching;

  const isMobile = verifyScreenIsLessThan(760);

  return {
    sortField,
    partsShown,
    inputRef,
    partsShownLength,
    currentPage,
    pagination,
    isFetching,
    isSearchInputDirty,
    auth,
    theme,
    partsLength,
    isSearch,
    isMobile,
    endPageRef,
    isLoadMoreFetching,
    errorMessage,
    partsHasImage,
    nameColWidth,
    isAdmin,
    clearError,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
    handleChangePage,
    handleChangePagination,
    handleGeneratePdf,
  };
};
