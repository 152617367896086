import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainerProps,
  SxProps,
  Theme,
} from '@mui/material';
import { FindAllProductDescription } from '../../../types/productsDescription';
import { tableContainerStyle } from './styles';
import { useQuery } from 'react-query';
import { productDescriptionApiCache } from '../../../constants/requestCacheName';
import { getAllProductDescriptionsByProductId } from '../../../services/productDescription';
import { memo } from 'react';

type CardTableProps = TableContainerProps & {
  productId: string | number;
};

function CardTable({ productId, sx, ...rest }: CardTableProps) {
  const { data: descriptions } = useQuery<FindAllProductDescription>(
    productDescriptionApiCache,
    async () => (await getAllProductDescriptionsByProductId(+productId)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const descriptionsList = descriptions?.items;

  return (
    <TableContainer
      component={Paper}
      sx={{ ...tableContainerStyle, ...sx } as SxProps<Theme>}
      {...rest}
    >
      <Table size="small">
        <TableBody>
          {descriptionsList?.map(({ description, amount }) => (
            <TableRow
              key={description.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="td" scope="description">
                {description.name}
              </TableCell>
              <TableCell component="td" scope="description" align="right">
                {amount}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(CardTable);
