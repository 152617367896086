import { Route, Routes } from 'react-router-dom';
import Unauthorized from '../pages/401';
import NotFound from '../pages/404';
import PartCompatibilities from '../pages/PartCompatibilities';
import PartsCatalog from '../pages/PartsCatalog';
import Products from '../pages/Products';
import Login from '../pages/Login';
import Home from '../pages/Home';
import Parts from '../pages/Parts';
import PartsProduct from '../pages/PartsProduct';

/**Buffalos */
import HomeBuffalos from '../buffalos/pages/Home';
import HomeInicial from '../buffalos/pages/HomeInicial';
import ProdutoBuffalos from '../buffalos/pages/Produtos';
import SobreBuffalos from '../buffalos/pages/Sobre';
import BuffaloNews from '../buffalos/pages/News';
import TechnicalAssistance from '../buffalos/pages/TechnicalAssistance';
import ContactUs from '../buffalos/pages/ContactUs';
import Representative from '../buffalos/pages/Representative';
import Detalhes from '../buffalos/pages/Detalhes';
import PartsCatalogBuffalo from '../buffalos/pages/PartsCatalog';
import PartCompatibilitiesBuffalo from '../buffalos/pages/PartCompatibilities';
import Faq from '../buffalos/pages/Faq';
import Dimensionamento from '../buffalos/pages/Dimensionamento';
import EmailSignaturesListing from '../pages/Admin/EmailSignaturesListing';
import { useQuery } from 'react-query';
import { pagesByLoggedUserCache } from '../constants/requestCacheName';
import { getAllPagesByLoggedUser } from '../services/userTypePages';
import { FindAllPages } from '../types/userTypePages';
import { pagesMapper } from '../constants/pages';
import DomainFieldsPosition from '../pages/Admin/DomainFieldsPosition';
import PdfToImage from '../buffalos/components/pdfBook';
import RecoverPassword from '../pages/recoverPassword';
import SendMailRecoverPassword from '../pages/SendMailRecoverPassword';
import { useAutoLogout } from '../hooks/useLogooutApp';
import LabelNF from '../pages/Admin/LabelNF';
import PartsGroupProduct from '../buffalos/pages/partsProduct';

export default function Router() {
  const { data: userPages } = useQuery<FindAllPages>(
    pagesByLoggedUserCache,
    async () => (await getAllPagesByLoggedUser()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  useAutoLogout();

  return (
    <Routes>
      {userPages?.items.map((userPage) => (
        <Route
          key={userPage.id}
          path={pagesMapper[userPage.page.name]?.path}
          element={pagesMapper[userPage.page.name]?.element}
        />
      ))}
      <Route path="/image/:pdfName" element={<PdfToImage />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/login" element={<Login />} />
      {/* Verificar para deixar essa rota autenticada */}
      <Route
        path="/admin/email-signature/:domainId"
        element={<EmailSignaturesListing />}
      />
      {/* Verificar para deixar essa rota autenticada */}
      <Route
        path="/admin/email-signature/:domainId/:signatureId"
        element={<EmailSignaturesListing />}
      />
      <Route
        path="/admin/domain/:domainId/edit"
        element={<DomainFieldsPosition />}
      />
      /
      <Route path="/catalogo" element={<Home />} />
      {/* <Route path="/admin/homeBuffalo" element={<HomeBuffalo />} />
      <Route path="/admin/homeRental" element={<HomeRental />} /> */}
      <Route path="/parts-catalog/:productId" element={<PartsCatalog />} />
      <Route path="/faq" element={<Faq />} />
      <Route
        path="/part-compatibilities/:partId/:partName"
        element={<PartCompatibilities />}
      />
      <Route path="/parts-product/:productId" element={<PartsGroupProduct />} />
      <Route path="/parts-product" element={<PartsProduct />} />
      <Route path="/parts" element={<Parts />} />
      <Route path="/products" element={<Products />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/home" element={<HomeBuffalos />} />
      <Route path="/" element={<HomeInicial />} />
      <Route path="/produtos" element={<ProdutoBuffalos />} />
      <Route
        path="/produto/:productId/:productName/categoria/:categoryId/linha/:lineId"
        element={<Detalhes />}
      />
      <Route path="/sobre" element={<SobreBuffalos />} />
      <Route path="/buffaloNews" element={<BuffaloNews />} />
      <Route path="/assistencia" element={<TechnicalAssistance />} />
      <Route path="/contato" element={<ContactUs />} />
      <Route path="/representantes" element={<Representative />} />
      <Route path="/catalogo/:productId" element={<PartsCatalogBuffalo />} />
      {/* <Route path="/admin/produto-teste" element={<ProdutosTeste />} />
      <Route path="/admin/produto-teste2" element={<ProdutosTeste2 />} />
      <Route path="/admin/produto-teste3" element={<ProdutosTeste3 />} /> */}
      <Route
        path="/compatibilidade/:partId/:partName"
        element={<PartCompatibilitiesBuffalo />}
      />
      <Route path="/dimensionamentoBuffalo" element={<Dimensionamento />} />
      <Route path="/recover-password/:token" element={<RecoverPassword />} />
      <Route path="/send-mail" element={<SendMailRecoverPassword />} />
      <Route path="/admin/label-nf" element={<LabelNF />} />
    </Routes>
  );
}
