import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useEdit } from '../../hooks/useEdit';
import {
  CoordinatesModified,
  CoordinateToDelete,
  FindAllCoordinates,
  FindAllFieldDomainItem,
  TextAlignValues,
} from '../../types/domainImageCoordinate';
import { Stage } from 'konva/lib/Stage';
import { verifyScreenIsLessThan } from '../../utils/verifyScreenIsLessThan';
import { useSignatureDomainStore } from '../../store/signatureDomain';
import { useQuery, useQueryClient } from 'react-query';
import {
  domainCache,
  fieldsByDomainIdApiCache,
  fieldsDomainsCoordinateCache,
  fieldsDomainsCoordinateCacheCopy,
} from '../../constants/requestCacheName';
import {
  getAllCoordinatesByDomainId,
  getAllFields,
} from '../../services/signatureDomain';
import { KonvaEventObject } from 'konva/lib/Node';
import { useParams } from 'react-router-dom';
import { useQueryCache } from '../../hooks/useQueryCache';
import { ChainedCommands, useEditor, UseEditorOptions } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Underline from '@tiptap/extension-underline';
import { EditorMenuButtonType, MenuButtonProps } from '../Editor/EditorMenu';
import TextAlign from '@tiptap/extension-text-align';
import { getDomain } from '../../services/domain';
import { Domain } from '../../types/domain';
import { DefaultValuesCoordinate } from '../../constants/defaultValuesCoordinates';

type MouseCoordinates = Record<'x' | 'y', number>;

type Rectangle = CoordinatesModified & {
  isNew: boolean;
  text?: string;
};

type FormatterValues = {
  color?: string;
  fontSize?: number;
  fontStyle?: string;
  textDecoration?: string;
  bold?: string;
  textAlign?: TextAlignValues;
};

type FieldValues = FormatterValues & {
  fieldId: number;
  text: string;
  domainId: number;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  isVisible?: boolean;
};

const defaultFormatterValues = {
  color: '#000',
  fontSize: 12,
  fontStyle: 'normal',
  textDecoration: 'normal',
  textAlign: 'left',
  bold: 'normal',
} as const;

export const useDomainImageView = (
  canEdit: boolean,
  // imageName: string,
  onFieldMouseEnter: (fieldId: number) => void,
  onFieldMouseOut: () => void,
) => {
  const { domainId } = useParams();
  const queryClient = useQueryClient();

  const domainIdNumber = Number(domainId);

  const { data: fields } = useQuery<FindAllFieldDomainItem>(
    `${fieldsByDomainIdApiCache}${domainIdNumber}`,
    async () => (await getAllFields(Number(domainId))).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: domain } = useQuery<Domain>(
    `${domainCache}${domainId}`,
    async () => (await getDomain(Number(domainId))).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  // config de onde o texto é digitado no Editor
  const defaultTextValues = useMemo<FieldValues[]>(
    () =>
      fields?.items.map(({ field }) => ({
        fieldId: field.id,
        text: field.nameField ?? 'Exemplo',
        domainId: domainIdNumber,
      })) ?? [],
    [domainIdNumber, fields?.items],
  );

  const [fieldValue, setFieldValue] = useState<FieldValues[]>(() => {
    return defaultTextValues.map((defaultField) => ({
      ...defaultField,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    }));
  });
  const [valueInput, setValueInput] = useState<string>('');
  const [size, setSize] = useState<number>(10);
  const currentSize = useRef(10);
  const isFirstEditorSet = useRef(true);

  const {
    state: { currentFieldClicked, coordinatesModified, coordinatesToDelete },
    actions: {
      addCoordinateDelete,
      setCoordinateModified,
      setCurrentFieldClicked,
    },
  } = useSignatureDomainStore();

  const createEditorConfig = (): UseEditorOptions => ({
    extensions: [
      StarterKit,
      TextStyle,
      Color,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph', 'div'],
      }),
    ],
    onUpdate: () => {
      const currentRectangle = rectangles.find(
        (rectangle) => rectangle.fieldId === currentFieldClicked?.field.id,
      );

      if (!currentRectangle) return;

      handleShowValueInPreview(currentRectangle);
    },
    content: '',
    editorProps: {
      attributes: {
        class:
          'border p-2 outline-none flex-1 min-h-10 max-h-80 w-full min-w-[24rem] border-black rounded-md',
      },
    },
  });

  const editorPreView = useEditor(createEditorConfig());

  const extractTextStyles = (html: string, atribute: string): boolean => {
    const doc = new DOMParser().parseFromString(html, 'text/html');

    const isChecked = doc.querySelector(atribute) !== null;

    return isChecked;
  };

  const extractInlineStyleValue = (html: string, property: string) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');

    // Seleciona todos os elementos com estilo inline
    const elementsWithStyle = doc.querySelectorAll('[style]');

    for (const el of Array.from(elementsWithStyle)) {
      const styles = el.getAttribute('style') || '';

      // Converte os estilos inline em pares de propriedade: valor
      const styleMap = styles.split(';').reduce(
        (acc, style) => {
          const [key, value] = style.split(':').map((s) => s.trim());
          if (key && value) acc[key] = value;
          return acc;
        },
        {} as Record<string, string>,
      );

      // Retorna o valor da propriedade, se existir
      if (property in styleMap) {
        return styleMap[property] as TextAlignValues;
      }
    }

    return null;
  };

  const getEditorAttribute = useCallback(
    (attribute: string, defaultValue: string | number) => {
      const value =
        editorPreView?.getAttributes('textStyle')?.[attribute] ?? defaultValue;
      return value;
    },
    [editorPreView],
  );

  const [rectangles, setRectangles] = useState<Rectangle[]>([]);

  const isResponsive = verifyScreenIsLessThan(900);

  const isMobile = verifyScreenIsLessThan(640);

  const stageRef = useRef<Stage>(null);

  const explodedViewReduction = isMobile ? 0.5 : 1;
  const currentShapeId = useRef('');

  const isPainting = useRef<boolean>();

  const coordinateUpdated = useRef<Rectangle | null>(null);

  const isCoordinateInList = useRef(false);

  const coordinatesWhenPointerDown = useRef({ x: 0, y: 0 });

  const [currentCoordinateIdToDelete, setCurrentCoordinateIdToDelete] =
    useState('');

  const [currentMouseCoordinates, setCurrentMouseCoordinates] =
    useState<MouseCoordinates | null>(null);

  const handleExplodedViewImageError = () => {
    setIsExplodedViewImageError(true);
  };
  const [isExplodedViewImageError, setIsExplodedViewImageError] =
    useState(false);

  const [currentRectangleIdHover, setCurrentRectangleIdHover] = useState('');

  const { overrideCache } = useQueryCache();

  const compareModifiedDelete = useCallback(
    (coordinate: CoordinatesModified, coordinateDelete: CoordinateToDelete) => {
      return (
        coordinateDelete.domainId === coordinate.domainId &&
        coordinateDelete.fieldId === coordinate.fieldId
      );
    },
    [],
  );

  const handleClickAddCoordinate = () => {
    handleAddCoordinate();
  };

  useEffect(() => {
    setIsExplodedViewImageError(false);
  }, []);

  const {
    canEdit: canAddCoordinate,
    handleEdit: handleAddCoordinate,
    handleUnedit: handleNotAddCoordinate,
  } = useEdit();

  useEffect(() => {
    if (!canAddCoordinate) {
      setCurrentMouseCoordinates(null);
    }
  }, [canAddCoordinate]);

  const handlePointerDown = () => {
    const stage = stageRef.current;
    if (stage && canAddCoordinate) {
      // Obtém a posição relativa do ponteiro no Stage
      const pointerPosition = stage.getPointerPosition();
      if (!pointerPosition) return;

      const x = Math.round(pointerPosition.x / explodedViewReduction); // Ajusta pelo fator de redução
      const y = Math.round(pointerPosition.y / explodedViewReduction);

      coordinatesWhenPointerDown.current = { x, y };

      const id = Date.now().toString();
      currentShapeId.current = id;
      isPainting.current = true;

      if (currentFieldClicked && domainId) {
        const rectangle: Rectangle = {
          id,
          x,
          y,
          width: 0,
          height: 0,
          fieldId: currentFieldClicked.field.id,
          domainId: Number(domainId),
          ...defaultFormatterValues,
          isNew: true,
          isVisible: true,
        };

        setRectangles((prev) => [...prev, rectangle]);
        setCoordinateModified([...coordinatesModified, rectangle]);
      }
    }
  };

  const handlePointerMove = () => {
    if (!isPainting.current && canAddCoordinate) {
      const stage = stageRef.current;
      if (stage) {
        const { x, y } = stage.getPointerPosition()!;
        setCurrentMouseCoordinates({ x: Math.round(x), y: Math.round(y) });
      }
    }

    if (isPainting.current && canAddCoordinate) {
      const stage = stageRef.current;
      if (stage) {
        const { x, y } = stage.getPointerPosition()!;

        setRectangles((prev) =>
          prev.map((rectangle) => {
            if (rectangle.id === currentShapeId.current) {
              const width = x - coordinatesWhenPointerDown.current.x;
              const height = y - coordinatesWhenPointerDown.current.y;
              const updatedRectangle: Rectangle = {
                ...rectangle,
                ...defaultFormatterValues,
                x: Math.round(
                  width < 0 ? x : coordinatesWhenPointerDown.current.x,
                ),
                y: Math.round(
                  height < 0 ? y : coordinatesWhenPointerDown.current.y,
                ),
                width: Math.round(width < 0 ? width * -1 : width),
                height: Math.round(height < 0 ? height * -1 : height),
              };

              coordinatesModified.forEach((rectangleModified, index) => {
                if (rectangleModified.id === currentShapeId.current) {
                  const { isNew, ...rest } = updatedRectangle;
                  coordinatesModified[index] = rest;
                }
              });

              return updatedRectangle;
            }

            return rectangle;
          }),
        );
      }
    }
  };

  const handlePointerUp = () => {
    const stage = stageRef.current;
    if (stage) {
      const pointerPosition = stage.getPointerPosition();
      const boundingRect = stage.container().getBoundingClientRect();

      if (pointerPosition) {
        const { x, y } = pointerPosition;

        // Verificar se o ponteiro está fora dos limites
        const isOutside =
          x < 0 || y < 0 || x > boundingRect.width || y > boundingRect.height;

        if (isOutside) {
          // Se fora, resetar estados e cancela operação
          isPainting.current = false;
          coordinatesWhenPointerDown.current = { x: 0, y: 0 };
          handleNotAddCoordinate();
          return;
        }
      }
    }

    isPainting.current = false;
    coordinatesWhenPointerDown.current = { x: 0, y: 0 };
    handleNotAddCoordinate();
  };

  const { data: fieldsDomainCoordinates } = useQuery<FindAllCoordinates>(
    `${fieldsDomainsCoordinateCache}${domainId}`,
    async () => {
      return (await getAllCoordinatesByDomainId(Number(domainId))).data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const copiedCoordinates = queryClient.getQueryData<FindAllCoordinates>(
    `${fieldsDomainsCoordinateCacheCopy}${1}`,
  );

  useEffect(() => {
    let res: FieldValues[] = [
      ...defaultTextValues.filter(
        (defaultValue) =>
          !fieldsDomainCoordinates?.items.some(
            (coordinate) => defaultValue.fieldId === coordinate.field.id,
          ),
      ),

      ...(fieldsDomainCoordinates?.items ?? []).map((fieldData) => ({
        domainId: fieldData.domain.id,
        fieldId: fieldData.field.id,
        text:
          defaultTextValues.find(
            (defaultValue) =>
              fieldData.field.id === defaultValue.fieldId &&
              fieldData.domain.id === defaultValue.domainId,
          )?.text ?? '',
        bold: fieldData.bold,
        color: fieldData.color,
        fontSize: fieldData.fontSize,
        fontStyle: fieldData.fontStyle,
        height: fieldData.height,
        textDecoration: fieldData.textDecoration,
        width: fieldData.width,
        textAlign: fieldData.textAlign,
        x: fieldData.x,
        y: fieldData.y,
        isVisible: fieldData.isVisible,
      })),
    ];

    // Se forem coordenadas copiadas, reatribui o res
    if ((copiedCoordinates?.items.length ?? 0) > 0) {
      res = [
        ...(copiedCoordinates?.items ?? []).map((fieldData) => ({
          domainId: domainIdNumber,
          fieldId: fieldData.field.id,
          text:
            defaultTextValues.find(
              (defaultValue) =>
                fieldData.field.id === defaultValue.fieldId &&
                domainIdNumber === defaultValue.domainId,
            )?.text ?? '',
          bold: fieldData.bold,
          color: fieldData.color,
          fontSize: fieldData.fontSize,
          fontStyle: fieldData.fontStyle,
          height: fieldData.height,
          textDecoration: fieldData.textDecoration,
          width: fieldData.width,
          textAlign: fieldData.textAlign,
          x: fieldData.x,
          y: fieldData.y,
          isVisible: fieldData.isVisible,
        })),
      ];
    }

    setFieldValue(res);
  }, [
    fieldsDomainCoordinates,
    defaultTextValues,
    // copiedCoordinates?.items.length,
    copiedCoordinates?.items,
    domainIdNumber,
  ]);

  const handlePartMouseEnter = (
    event: KonvaEventObject<MouseEvent>,
    fieldId: number,
  ) => {
    onFieldMouseEnter(fieldId);
    const container = event.target.getStage()?.container();
    if (container) {
      container.style.cursor = 'pointer';
      container.style.cursor = 'pointer';
    }
  };

  const handlePartMouseOut = (event: KonvaEventObject<MouseEvent>) => {
    onFieldMouseOut();
    const container = event.target.getStage()?.container();
    if (container) {
      container.style.cursor = 'default';
    }
  };

  const converterCoordinatesToRectangles = useCallback(() => {
    const rectanglesList: Rectangle[] =
      fieldsDomainCoordinates?.items?.map((coordinate) => ({
        id: `${coordinate.field.id}${coordinate.x}${coordinate.y}`,
        fieldId: coordinate.field.id,
        domainId: coordinate.domain.id,
        height: coordinate.height,
        width: coordinate.width,
        bold: coordinate.bold,
        textAlign: coordinate.textAlign ?? 'left',
        color: coordinate.color,
        fontSize: coordinate.fontSize,
        fontStyle: coordinate.fontStyle,
        textDecoration: coordinate.textDecoration,
        isVisible: coordinate.isVisible,
        x: coordinate.x,
        y: coordinate.y,
        isNew: false,
      })) ?? [];
    return rectanglesList;
  }, [fieldsDomainCoordinates?.items]);

  const [rectanglesWithFieldsHover, setRectanglesWithFieldsHover] = useState<
    CoordinatesModified[]
  >([]);

  useEffect(() => {
    const coordinatesRectangles = converterCoordinatesToRectangles();

    const coordinatesRectanglesWithoutModified = coordinatesRectangles.filter(
      (coordinate) =>
        !coordinatesModified.some(
          ({ fieldId }) => coordinate.fieldId === fieldId,
        ),
    );

    setRectanglesWithFieldsHover([
      ...coordinatesRectanglesWithoutModified,
      ...coordinatesModified,
    ]);
  }, [converterCoordinatesToRectangles, coordinatesModified, rectangles]);

  useEffect(() => {
    if (fieldsDomainCoordinates) {
      const fieldValueClicked = fieldValue.find(
        (value) =>
          value.fieldId === currentFieldClicked?.field.id &&
          value.domainId === currentFieldClicked.domain.id,
      );
      const alignMapper: Record<TextAlignValues, EditorMenuButtonType> = {
        left: 'alignLeft',
        center: 'alignCenter',
        right: 'alignRight',
      };

      editorPreView?.commands.setContent(fieldValueClicked?.text ?? '');
      handleChangeInputSize(fieldValueClicked?.fontSize?.toString() ?? '10');
      handleSomeEditorMenuButtonClick({
        type: 'color',
        color: fieldValueClicked?.color,
      });
      if (fieldValueClicked?.bold === 'bold') {
        handleSomeEditorMenuButtonClick({ type: 'bold' });
      }
      if (fieldValueClicked?.fontStyle === 'italic') {
        handleSomeEditorMenuButtonClick({ type: 'italic' });
      }
      if (fieldValueClicked?.textDecoration === 'underline') {
        handleSomeEditorMenuButtonClick({ type: 'underline' });
      }
      if (fieldValueClicked?.textAlign) {
        handleSomeEditorMenuButtonClick({
          type: alignMapper[fieldValueClicked.textAlign],
        });
      }

      const rectanglesList: Rectangle[] = converterCoordinatesToRectangles();

      if (currentFieldClicked?.field.id) {
        const rectanglesFiltered = rectanglesList.filter(
          (coordinate) =>
            coordinate.fieldId === currentFieldClicked?.field.id &&
            !coordinatesToDelete.some((coordinateDelete) =>
              compareModifiedDelete(coordinate, coordinateDelete),
            ),
        );

        const updatedRectanglesFiltered: Rectangle[] = coordinatesModified
          .filter(
            (coordinateModified) =>
              !coordinatesToDelete.some((coordinateDelete) =>
                compareModifiedDelete(coordinateModified, coordinateDelete),
              ) && coordinateModified.fieldId === currentFieldClicked?.field.id,
          )
          .map((coordinateModified) => ({
            ...coordinateModified,
            isNew: true,
          }));

        const rectanglesUpdated = rectanglesFiltered.filter((rectangle) => {
          return !updatedRectanglesFiltered.some((updatedRectangles) => {
            return (
              updatedRectangles.domainId === rectangle.domainId &&
              updatedRectangles.fieldId === rectangle.fieldId &&
              updatedRectangles.previousX === rectangle.x &&
              updatedRectangles.previousY === rectangle.y
            );
          });
        });
        setRectangles([...rectanglesUpdated, ...updatedRectanglesFiltered]);

        const rectanglesWithoutRepeated = [
          ...rectanglesUpdated,
          ...updatedRectanglesFiltered,
        ].find((rectangle) => !rectangle.text);

        if (rectanglesWithoutRepeated) {
          setRectangles([rectanglesWithoutRepeated]);
        }
        return;
      }

      setRectangles(rectanglesList);
    }
    //TIREI O FIELDCOODINATES
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareModifiedDelete, currentFieldClicked?.field.id]);

  const handleInputMouseEnter = (rectangleId: string) => {
    setCurrentRectangleIdHover(rectangleId);
  };

  const handleRemoveCoordinate = (coordinateId: string) => {
    if (currentCoordinateIdToDelete === coordinateId) {
      setCurrentCoordinateIdToDelete('');
      return;
    }

    setCurrentCoordinateIdToDelete(coordinateId);
  };

  const validateInput = (name: string, value: string) => {
    const isInt = +value >= 0;
    let isLessMaxValue = false;

    if (['y', 'height'].includes(name) && +value <= 209) {
      isLessMaxValue = true;
    }

    if (['x', 'width'].includes(name) && +value <= 640) {
      isLessMaxValue = true;
    }

    const isValid = isInt && isLessMaxValue;

    return isValid;
  };

  const handleChangeCoordinateInput = (
    event: ChangeEvent<HTMLInputElement>,
    coordinateId: string,
  ) => {
    if (coordinateUpdated.current?.id !== coordinateId) {
      coordinateUpdated.current = null;
    }

    const [name, value] = [event.target.name, event.target.value];

    setRectangles((prev) =>
      prev.map((coordinate) => {
        if (coordinate.id === coordinateId && validateInput(name, value)) {
          coordinateUpdated.current = {
            ...coordinate,
            width: coordinate.fieldId === 7 ? +value : coordinate.width,
            height: coordinate.fieldId === 7 ? +value : coordinate.height,
            fontSize:
              coordinate.fieldId === 7
                ? +value
                : coordinateUpdated.current?.fontSize ?? coordinate.fontSize,
            previousX: coordinateUpdated.current?.previousX ?? coordinate.x,
            previousY: coordinateUpdated.current?.previousY ?? coordinate.y,
            color: coordinateUpdated.current?.color ?? coordinate.color,
            bold: coordinateUpdated.current?.bold ?? coordinate.bold,
            textAlign:
              coordinateUpdated.current?.textAlign ?? coordinate.textAlign,
            fontStyle:
              coordinateUpdated.current?.fontStyle ?? coordinate.fontStyle,
            textDecoration:
              coordinateUpdated.current?.textDecoration ??
              coordinate.textDecoration,
          };

          if (coordinate.fieldId === 7) {
            // É o QRCode
            if (['width', 'height'].includes(name)) {
              const qrCodeRectangle = {
                ...coordinateUpdated.current,
                width: +value,
                height: +value,
                fontSize: +value,
              };
              // setSize(+value);
              // currentSize.current = +value;

              handleChangeInputSize(value);

              return qrCodeRectangle;
            }
          }

          return {
            ...coordinate,
            [name]: +value,
          };
        }

        return coordinate;
      }),
    );

    const coordinatesModifiedLength = coordinatesModified.length;

    fieldsDomainCoordinates?.items.forEach(
      ({
        field,
        x,
        y,
        bold,
        color,
        fontStyle,
        textDecoration,
        fontSize,
        textAlign,
      }) => {
        if (
          coordinateUpdated.current?.fieldId === field.id &&
          coordinateUpdated.current.previousX === x &&
          coordinateUpdated.current.previousY === y
        ) {
          if (!coordinateUpdated.current.isNew && ['x', 'y'].includes(name)) {
            coordinateUpdated.current.previousX = x;
            coordinateUpdated.current.previousY = y;
            coordinateUpdated.current.bold = bold;
            coordinateUpdated.current.color = color;
            coordinateUpdated.current.fontStyle = fontStyle;
            coordinateUpdated.current.textDecoration = textDecoration;
            coordinateUpdated.current.fontSize = fontSize;
            coordinateUpdated.current.textAlign = textAlign ?? 'left';
          }

          coordinateUpdated.current = {
            ...coordinateUpdated.current,
            [name]: +value,
          };

          if (coordinatesModifiedLength <= 0) {
            setCoordinateModified([coordinateUpdated.current]);
          }
        }
      },
    );

    if (coordinatesModifiedLength > 0) {
      coordinatesModified.forEach((coordinateModified, index) => {
        if (
          coordinateModified.id === coordinateId &&
          coordinateUpdated.current
        ) {
          isCoordinateInList.current = true;
          coordinatesModified[index] = coordinateUpdated.current;
        }
      });

      if (!isCoordinateInList.current && coordinateUpdated.current) {
        coordinatesModified.push(coordinateUpdated.current);
      }

      isCoordinateInList.current = false;
    }

    if (currentFieldClicked?.field.id === 7) {
      // const currentCoordinate = coordinatesModified.find(
      //   ({ id }) => coordinateId === id,
      // );

      if (coordinateUpdated.current) {
        const coordinatesWithoutCurrent = coordinatesModified.filter(
          (coordinatesModified) => coordinatesModified.id !== coordinateId,
        );
        setCoordinateModified([
          ...coordinatesWithoutCurrent,
          coordinateUpdated.current,
        ]);
      }
    }
  };

  const handleDeleteCoordinate = (coordinateId: string) => {
    if (domainId) {
      setRectangles((prev) =>
        prev.filter(({ id, fieldId, x, y, isNew }) => {
          if (id === coordinateId && !isNew) {
            const rectangleToDelete = {
              domainId: +domainId,
              fieldId,
              // x,
              // y,
            };
            addCoordinateDelete(rectangleToDelete);
          }

          return id !== coordinateId;
        }),
      );

      setCoordinateModified(
        coordinatesModified.filter(({ id }) => id !== coordinateId),
      );

      const filteredCoordinates = fieldsDomainCoordinates?.items.filter(
        (coordinate) =>
          `${coordinate.field.id}${coordinate.x}${coordinate.y}` !==
          coordinateId,
      );

      overrideCache<FindAllCoordinates>(
        `${fieldsDomainsCoordinateCache}${domainId}`,
        {
          items: filteredCoordinates ?? [],
          meta: {
            totalItems: fieldsDomainCoordinates?.meta.totalItems ?? 0,
          },
        },
      );
    }
  };

  // Função para remover as tags e deixar apenas o texto
  const htmlToPlainText = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || ''; // Retorna o texto sem as tags HTML
  };

  const handleChangeInputSize = (
    value: string,
    rectangleHeight?: number,
    rectangleWidth?: number,
  ) => {
    const sizeValueValidated = Number(value) ?? 10;

    setSize(sizeValueValidated);
    currentSize.current = sizeValueValidated;

    const currentRectangle = rectangles.find(
      (rectangle) => rectangle.fieldId === currentFieldClicked?.field.id,
    );
    if (!currentRectangle) return;

    const isRectangleAlreadyInModified = coordinatesModified.some(
      (coordinate) => coordinate.fieldId === currentRectangle.fieldId,
    );

    if (!isRectangleAlreadyInModified) {
      currentRectangle.fontSize = sizeValueValidated;
      coordinatesModified.push(currentRectangle);
    }

    handleShowValueInPreview(currentRectangle);
  };

  // Adiciona ou atualiza os valores
  const handleShowValueInPreview = (rectangle: {
    id: string;
    x: number;
    y: number;
    width: number;
    height: number;
    fieldId: number;
    domainId: number;
    isVisible: boolean;
  }) => {
    const currentCoordinateModified = fieldValue.find(
      (coordinate) => coordinate.fieldId === currentFieldClicked?.field.id,
    );

    if (!currentCoordinateModified) return;

    // Atualiza o valor do font size no context
    currentCoordinateModified.fontSize = currentSize.current;

    // Se tiver algum valor no editorPreview utiliza ele, se não utiliza o valueInput
    const defaultValue = defaultTextValues.find(
      (value) =>
        value.fieldId === rectangle.fieldId &&
        value.domainId === rectangle.domainId,
    );

    const content = editorPreView?.getHTML() ?? '';
    const textContent = htmlToPlainText(content) || (defaultValue?.text ?? '');

    const align = extractInlineStyleValue(content, 'text-align') ?? 'left';

    // Atualiza todos valores
    const newFieldValue: FieldValues = {
      fieldId: rectangle.fieldId,
      domainId: domainIdNumber,
      text: textContent,
      x: rectangle.x,
      y: rectangle.y,
      width: rectangle.fieldId === 7 ? currentSize.current : rectangle.width,
      height: rectangle.fieldId === 7 ? currentSize.current : rectangle.height,
      color: getEditorAttribute('color', DefaultValuesCoordinate.color),
      fontSize: currentSize.current || DefaultValuesCoordinate.fontSize,
      bold: extractTextStyles(content, 'strong')
        ? 'bold'
        : DefaultValuesCoordinate.bold,
      textAlign: align,
      textDecoration: extractTextStyles(content, 'u')
        ? 'underline'
        : DefaultValuesCoordinate.textDecoration,
      fontStyle: extractTextStyles(content, 'em')
        ? 'italic'
        : DefaultValuesCoordinate.fontStyle,
      isVisible: rectangle.isVisible || DefaultValuesCoordinate.isVisible,
    };

    // Recebe os valores anteriores e retorna um array com os novos valores do estado
    setFieldValue((prevValues) => {
      // Atualiza os valores onde o id do campo e o id com dominio forem identicos
      const updatedValues = prevValues.filter(
        (field) =>
          !(
            field.fieldId === rectangle.fieldId &&
            field.domainId === rectangle.domainId
          ),
      );

      // faz uma combinação dos valores que foram atualizados com um novo valor
      const newValues = [...updatedValues, newFieldValue];
      // sessionStorage.setItem(
      //   `fieldValues-${domainIdNumber}`,
      //   JSON.stringify(newValues),
      // ); // Salva no sessionStorage
      return newValues;
    });

    const modifiedValues = coordinatesModified.map((coordinate) => {
      fieldValue.forEach((value) => {
        if (
          coordinate.fieldId === value.fieldId &&
          coordinate.domainId === value.domainId
        ) {
          coordinate.bold = value.bold;
          coordinate.textAlign = value.textAlign ?? 'left';
          coordinate.textDecoration = value.textDecoration;
          coordinate.fontStyle = value.fontStyle;
          coordinate.color = value.color;
          coordinate.fontSize = value.fontSize;

          if (coordinate.fieldId === 7 && value.fontSize) {
            coordinate.height = value.fontSize;
            coordinate.width = value.fontSize;
          }
        }
      });

      return coordinate;
    });

    const currentCoordinate = coordinatesModified.find(
      (coordinateModified) => coordinateModified.fieldId === rectangle.fieldId,
    );

    if (!currentCoordinate && !isFirstEditorSet.current) {
      modifiedValues.push({
        ...rectangle,
        ...newFieldValue,
        textAlign: newFieldValue.textAlign!,
      });
    }

    setCoordinateModified(modifiedValues);
  };

  const handleSomeEditorMenuButtonClick = useCallback(
    ({ type, color }: MenuButtonProps) => {
      const command = editorPreView?.chain().selectAll();
      const currentCoordinateModified = fieldValue.find(
        (coordinate) => coordinate.fieldId === currentFieldClicked?.field.id,
      );

      if (!currentCoordinateModified) return;

      const actions: Record<
        EditorMenuButtonType,
        () => ChainedCommands | undefined
      > = {
        bold: () => {
          currentCoordinateModified!.bold = 'bold';
          return command?.toggleBold();
        },
        italic: () => {
          currentCoordinateModified!.fontStyle = 'italic';
          return command?.toggleItalic();
        },
        underline: () => {
          currentCoordinateModified!.textDecoration = 'underline';
          return command?.toggleUnderline();
        },
        color: () => {
          currentCoordinateModified!.color = color;
          return command?.command(({ commands }) => {
            return commands.setMark('textStyle', { color });
          });
        },
        alignLeft: () => {
          currentCoordinateModified!.textAlign = 'left';
          return command?.setTextAlign('left');
        },
        alignCenter: () => {
          currentCoordinateModified!.textAlign = 'center';
          return command?.setTextAlign('center');
        },
        alignRight: () => {
          currentCoordinateModified!.textAlign = 'right';
          return command?.setTextAlign('right');
        },
      };

      if (currentCoordinateModified) {
        actions[type]()?.run();
      }
    },
    [currentFieldClicked?.field.id, editorPreView, fieldValue],
  );

  const updateModifiedCheckbox = (
    fieldId: number,
    rectangle: Rectangle,
    isVisible: boolean,
  ) => {
    const currentCoordinate = coordinatesModified.find(
      (coordinateModified) => coordinateModified.fieldId === fieldId,
    );

    if (!currentCoordinate) {
      coordinatesModified.push({ ...rectangle, isVisible });
      return;
    }

    coordinatesModified.forEach((coordinateModified) => {
      if (coordinateModified.fieldId === fieldId) {
        coordinateModified.isVisible = isVisible;
      }
    });
  };

  const handleCheckboxClick = (fieldId: number) => {
    setRectangles((prevRectangles) =>
      prevRectangles.map((rectangle) => {
        if (rectangle.fieldId === fieldId) {
          updateModifiedCheckbox(fieldId, rectangle, !rectangle.isVisible);

          return { ...rectangle, isVisible: !rectangle.isVisible };
        }

        return rectangle;
      }),
    );
  };

  const handleClickField = (fieldId: number) => {
    const clickedField = fields?.items.find(
      ({ field }) => field.id === fieldId,
    );

    if (clickedField) {
      setCurrentFieldClicked(clickedField);
    }
  };

  return {
    size,
    editorPreView,
    domainIdNumber,
    fieldValue,
    valueInput,
    canAddCoordinate,
    isExplodedViewImageError,
    stageRef,
    explodedViewReduction,
    isResponsive,
    coordinatesModified,
    fieldsSignatureCoordinates: fieldsDomainCoordinates?.items,
    currentFieldClicked,
    rectanglesWithFieldsHover,
    currentRectangleIdHover,
    rectangles,
    currentMouseCoordinates,
    currentCoordinateIdToDelete,
    domain,
    isFirstEditorSet,
    handleClickField,
    handleCheckboxClick,
    handleChangeInputSize,
    handleSomeEditorMenuButtonClick,
    setValueInput,
    handleInputMouseEnter,
    handleExplodedViewImageError,
    handlePointerDown,
    handlePointerUp,
    handlePointerMove,
    handlePartMouseOut,
    handlePartMouseEnter,
    handleClickAddCoordinate,
    handleRemoveCoordinate,
    handleChangeCoordinateInput,
    handleDeleteCoordinate,
  };
};
