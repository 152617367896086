import { Box, Typography } from '@mui/material';
import {
  explodedViewContainerStyle,
  selectedPartCardTitleStyle,
  selectedPartStyle,
} from './styles';
import Card from '../Card';
import { Link } from 'react-router-dom';
import Modal from '../Modal';
import { getImageStorage, getImageFromApi } from '../../utils/getImageUrl';
import { ImageTypes } from '../../enums/imageTypes';
import { grey } from '@mui/material/colors';
import { useExplodedView } from './useExplodedView';
import AddButton from '../AddButton';
import RemoveButton from '../RemoveButton';
import { Layer, Rect, Stage } from 'react-konva';
import Input from '../Input';

type ExplodedViewProps = {
  canEdit: boolean;
  imageName?: string;
  currentPartHoverId: number | null;
  onPartMouseEnter: (partId: number) => void;
  onPartMouseOut: () => void;
  onPartClick: (partId: number) => void;
};

export default function ExplodedView({
  canEdit,
  imageName = '',
  currentPartHoverId,
  onPartMouseEnter,
  onPartMouseOut,
  onPartClick,
}: ExplodedViewProps) {
  const {
    explodedViewReduction,
    isOpenModal,
    isResponsive,
    currentPartClicked,
    stageRef,
    rectangles,
    rectanglesWithPartsHover,
    canAddCoordinate,
    currentMouseCoordinates,
    currentRectangleIdHover,
    currentCoordinateIdToDelete,
    partsGroupImagesCoordinates,
    isExplodedViewImageError,
    handleExplodedViewImageError,
    handlePartMouseEnter,
    handlePartMouseOut,
    handleDeleteCoordinate,
    handleRemoveCoordinate,
    handleInputMouseEnter,
    handleChangeCoordinateInput,
    handleClickAddCoordinate,
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    handleCloseModal,
    handleOpenModal,
  } = useExplodedView(canEdit, onPartMouseEnter, onPartMouseOut, imageName);

  return (
    <Box component="section" sx={explodedViewContainerStyle}>
      <h2 className="bg-wite mb-4 pt-4 text-zinc-500 xl:fixed xl:z-10 xl:w-[600px]">
        Vista explodida
      </h2>
      <div
        className={`relative mt-12 flex h-[300px] w-[600px] justify-center max-sm:h-[150px] max-sm:w-[300px]`}
      >
        {!isExplodedViewImageError && (
          <img
            src={getImageStorage(ImageTypes.explodedView, imageName)}
            alt={`Imagem da vista explodida`}
            onError={handleExplodedViewImageError}
            width="600px"
            height="300px"
            className={`${canAddCoordinate && 'border border-primary'}`}
          />
        )}

        {isExplodedViewImageError && (
          <div className="mt-4">
            <img
              src="/images/unavailable.png"
              alt="Imagem de produto indisponível"
              width="400px"
              height="300px"
            />
          </div>
        )}
        <Stage
          ref={stageRef}
          width={600 * explodedViewReduction}
          height={300 * explodedViewReduction}
          className={`max absolute left-0 top-0`}
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
          onPointerUp={handlePointerUp}
        >
          <Layer
            width={600 * explodedViewReduction}
            height={300 * explodedViewReduction}
          >
            {!canEdit &&
              partsGroupImagesCoordinates?.items.map((rectangle) => (
                // <div
                //   key={`${rectangle.part.id}${rectangle.x}${rectangle.y}`}
                //   className="absolute"
                //   // onMouseOut={handlePartMouseOut}
                //   // onMouseEnter={(e) => {
                //   //   handlePartMouseEnter(e, rectangle.part.id);
                //   // }}
                //   onClick={(e) => {
                //     onPartClick(rectangle.part.id);
                //   }}
                //   style={{
                //     backgroundColor: '#42a0dd',
                //     top: rectangle.y * explodedViewReduction,
                //     left: rectangle.x * explodedViewReduction,
                //     width: rectangle.width * explodedViewReduction,
                //     height: rectangle.height * explodedViewReduction,
                //     opacity:
                //       rectangle.part.id === currentPartHoverId ||
                //       rectangle.part.id === currentPartClicked?.part.id
                //         ? 0.6
                //         : 0,
                //   }}
                // />
                <Rect
                  key={`${rectangle.part.id}${rectangle.x}${rectangle.y}`}
                  x={rectangle.x * explodedViewReduction}
                  y={rectangle.y * explodedViewReduction}
                  width={rectangle.width * explodedViewReduction}
                  height={rectangle.height * explodedViewReduction}
                  fill={
                    rectangle.part.id === currentPartClicked?.part.id
                      ? '#42a0dd'
                      : rectangle.part.id === currentPartHoverId
                        ? '#42a0dd'
                        : 'transparent'
                  }
                  opacity={
                    rectangle.part.id === currentPartHoverId ||
                    rectangle.part.id === currentPartClicked?.part.id
                      ? 0.6
                      : 1
                  }
                  onMouseOut={handlePartMouseOut}
                  onMouseEnter={(e) => {
                    handlePartMouseEnter(e, rectangle.part.id);
                  }}
                  onPointerDown={() => {
                    onPartClick(rectangle.part.id);
                  }}
                  cornerRadius={5}
                  stroke="#71717a"
                  strokeEnabled={true}
                  strokeWidth={0.5}
                />
              ))}
            {canEdit &&
              rectanglesWithPartsHover.map((rectangle) => (
                <Rect
                  key={rectangle.id}
                  x={rectangle.x}
                  y={rectangle.y}
                  width={rectangle.width}
                  height={rectangle.height}
                  fill={`${currentRectangleIdHover === rectangle.id ? '#333' : '#42a0dd'}`}
                  opacity={
                    rectangle.partId === currentPartHoverId ||
                    rectangle.partId === currentPartClicked?.part.id
                      ? 0.6
                      : 1
                  }
                  onMouseOut={onPartMouseOut}
                  onMouseEnter={() => onPartMouseEnter(rectangle.partId)}
                  onClick={() => onPartClick(rectangle.partId)}
                />
              ))}
          </Layer>
        </Stage>
      </div>
      {currentPartClicked && !canEdit && (
        <Box sx={selectedPartStyle}>
          <Typography
            component="h3"
            color={grey['600']}
            className="flex items-center justify-start"
          >
            <div className="flex items-center justify-start">
              <span className="font-medium text-black">Peça selecionada</span>
              <span className="text-2xl text-primary">.</span>
            </div>
            {currentPartClicked.tag !== '' && (
              <div
                className={`ml-3 flex h-auto w-auto min-w-[3rem] ${currentPartClicked.tag.length > 3 ? 'px-2' : ''} items-center justify-center rounded-md border border-primary font-normal`}
              >
                {currentPartClicked.tag}
              </div>
            )}
          </Typography>
          <Card.Root
            border
            line
            contentClassName="justify-between h-[10rem]"
            sx={{
              p: '2rem 0',
              mt: '0.5rem',
            }}
          >
            <Card.Image
              src={getImageFromApi(currentPartClicked?.part.id ?? '')}
              alt={`Imagem de ${currentPartClicked?.part?.name}`}
              fallbackSrc="/images/unavailable.png"
              fallbackAlt="Imagem de produto indisponível"
              style={{ marginRight: '1rem' }}
              containerStyle={{ alignSelf: 'center' }}
              width={150}
            />
            <Box className="w-full">
              <Typography
                variant="h6"
                component="h6"
                sx={selectedPartCardTitleStyle}
              >
                {currentPartClicked?.part?.name}
              </Typography>
              <Box className="flex w-full max-w-[23rem] items-center justify-between">
                <span className="text-secondaryText">
                  {currentPartClicked?.part?.id}
                </span>

                <span className="font-normal text-black">
                  Qtd:{currentPartClicked.amount}
                </span>
              </Box>
              <Card.Actions positionStatic sx={{ alignSelf: 'flex-end', marginTop: '4rem'}}>
                <Card.Action
                  text="Descrição"
                  onClick={handleOpenModal}
                  className="text-primary hover:text-[#0074cc]"
                />
                <Link
                  to={`/part-compatibilities/${currentPartClicked?.part?.id}/${encodeURIComponent(
                    currentPartClicked?.part?.name ?? '',
                  )}`}
                >
                  <Card.Action
                    text="Compatibilidade"
                    className="text-primary hover:text-[#0074cc]"
                  />
                </Link>
              </Card.Actions>
            </Box>
          </Card.Root>
          <Modal isOpen={isOpenModal} onClose={handleCloseModal} width="30rem">
            <Card.Root>
              <h2 className="mb-8 text-center text-xl">Descrição</h2>
              <Card.Image
                src={getImageFromApi(currentPartClicked?.part?.id ?? '')}
                alt={`Imagem de ${currentPartClicked?.part?.name}`}
                fallbackSrc="/images/unavailable.png"
                fallbackAlt="Imagem de produto indisponível"
              />
              <Box>
                <Typography variant="h6" component="h6">
                  {currentPartClicked?.part?.name}
                </Typography>
                <span className="text-secondaryText">
                  {currentPartClicked?.part?.id}
                </span>
              </Box>
            </Card.Root>
          </Modal>
        </Box>
      )}

      {currentPartClicked && canEdit && (
        <div className="mt-4 w-full text-left">
          <div className="flex w-28 gap-4 text-left">
            <span className="text-sm">x: {currentMouseCoordinates?.x}</span>
            <span className="text-sm">y: {currentMouseCoordinates?.y}</span>
          </div>
          <div className="mt-8">
            <h2 className="mb-4 text-zinc-500">Coordenadas</h2>

            <div className="max-h-44 overflow-y-auto overflow-x-hidden pr-2">
              {rectangles.map((rectangle) => (
                <div
                  key={rectangle.id}
                  onMouseEnter={() => handleInputMouseEnter(rectangle.id)}
                  onMouseOut={() => handleInputMouseEnter('')}
                  className="group mb-1 flex cursor-pointer hover:border-primary"
                >
                  <RemoveButton
                    className="flex items-center"
                    onClick={() => handleRemoveCoordinate(rectangle.id)}
                  />
                  <div className="flex flex-1 justify-between border-b border-zinc-300 pb-1 group-hover:border-primary">
                    <div className="flex">
                      <div className="flex items-center">
                        <label htmlFor="x" className="mx-2 text-xs font-bold">
                          X
                        </label>
                        <Input
                          onChange={(event) =>
                            handleChangeCoordinateInput(event, rectangle.id)
                          }
                          type="number"
                          name="x"
                          className="w-14 rounded border border-zinc-400 pl-2"
                          value={rectangle.x}
                        />
                      </div>
                      <div className="flex items-center">
                        <label htmlFor="y" className="mx-2 text-xs font-bold">
                          Y
                        </label>
                        <Input
                          onChange={(event) =>
                            handleChangeCoordinateInput(event, rectangle.id)
                          }
                          type="number"
                          name="y"
                          className="w-14 rounded border border-zinc-400 pl-2"
                          value={rectangle.y}
                        />
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex items-center">
                        <label
                          htmlFor="height"
                          className="mx-2 text-xs font-bold"
                        >
                          Altura
                        </label>
                        <Input
                          onChange={(event) =>
                            handleChangeCoordinateInput(event, rectangle.id)
                          }
                          type="number"
                          name="height"
                          className="w-14 rounded border border-zinc-400 pl-2"
                          value={rectangle.height}
                        />
                      </div>
                      <div className="flex items-center">
                        <label
                          htmlFor="width"
                          className="mx-2 text-xs font-bold"
                        >
                          Largura
                        </label>
                        <Input
                          onChange={(event) =>
                            handleChangeCoordinateInput(event, rectangle.id)
                          }
                          type="number"
                          name="width"
                          className="w-14 rounded border border-zinc-400 pl-2"
                          value={rectangle.width}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => handleDeleteCoordinate(rectangle.id)}
                    className={`transition-slow border-[1px] border-errorText bg-errorText text-zinc-100 ${currentCoordinateIdToDelete === rectangle.id ? 'ml-2 w-20 opacity-100' : 'w-0 opacity-0'}`}
                  >
                    Apagar
                  </button>
                </div>
              ))}
            </div>
            <AddButton
              content="Adicionar coordenada"
              onClick={handleClickAddCoordinate}
              className="mt-2"
            />
          </div>
        </div>
      )}
    </Box>
  );
}
