import { SxProps, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';

export const titleStyle: SxProps<Theme> = {
  fontSize: '1.5rem',
  margin: '1rem 0',
  '@media (max-width: 760px)': {
    margin: '1rem 0.5rem',
  },
};

export const filtersStyle: SxProps<Theme> = {
  display: 'flex',
  backgroundColor: grey['300'],
  marginBottom: '1rem',
  padding: '0.5rem 1rem',
  borderRadius: 2,
  gap: '1rem',

  '@media (max-width: 760px)': {
    flexDirection: 'column',
  },
};

export const filterStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '0 1rem',
  flex: 1,
  borderRadius: '10px',

  '@media (max-width: 760px)': {
    py: '0.2rem',
  },
};

export const tableLoadingContainerStyle: SxProps<Theme> = {
  '@media (max-width: 1024px)': {
    // marginTop: '13rem',
  },

  '@media (max-width: 760px)': {
    // marginTop: '20rem',
  },
};

export const tableStyle: SxProps<Theme> = {
  '@media (max-width: 760px)': {
    width: '100vw',
    ml: '-1rem',
  },
};

export const tableHeadStyle: SxProps<Theme> = {
  position: 'fixed',
  top: '13.25rem',

  '@media (max-width: 760px)': {
    top: '19.65rem',
  },
};

export const footerStyle: SxProps<Theme> = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: grey['300'],
  padding: '1rem 2rem',
};

export const footerTextStyle: SxProps<Theme> = {
  textAlign: 'center',
  fontSize: '1.25rem',
};
