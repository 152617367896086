import { create } from 'zustand';
import { UpdateEmailSignatureDomainSignatureDto } from '../../types/emailSignature';

export type DomainSignature = UpdateEmailSignatureDomainSignatureDto & {
  signatureId: number;
};

type EmailSignatureStoreProps = {
  state: {
    changedEmailSignatures: DomainSignature[];
  };
};

export const useEmailSignatureStore = create<EmailSignatureStoreProps>(
  (set) => {
    const initialState = {
      changedEmailSignatures: [],
    };

    return {
      state: initialState,
    };
  },
);
