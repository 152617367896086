import { Modal as ModalMui, Box, SxProps, Theme } from '@mui/material';
import {
  modalStyle,
  cancelIconStyle,
  cancelIconContainerStyle,
} from './styles';
import { ReactNode } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { twMerge } from 'tailwind-merge';

type ModalProps = {
  width?: string | number;
  height?: string | number;
  sx?: SxProps<Theme>;
  isOpen: boolean;
  iconContainerStyle?: SxProps<Theme>;
  contentStyle?: string;
  onClose: () => void;
  children?: ReactNode;
  cancelIcon?: boolean;
};

export default function Modal({
  width = '40rem',
  height,
  sx,
  isOpen,
  iconContainerStyle,
  contentStyle,
  cancelIcon = true,
  children,
  onClose,
}: ModalProps) {
  // useEffect(() => {
  //   // if (isOpen) {
  //   //   document.body.style.overflow = 'hidden';
  //   //   return;
  //   // }

  //   document.body.style.overflow = 'auto';
  // }, [isOpen]);

  return (
    <ModalMui
      open={isOpen}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{ ...sx, ...modalStyle } as SxProps<Theme>}
    >
      <div className="my-3.5 rounded-2xl bg-backgroundPaper">
        <div
          className={twMerge(
            'relative box-border max-h-[90vh] rounded-2xl px-4',
            contentStyle,
          )}
          style={{ width, height }}
        >
          <Box
            sx={
              {
                ...cancelIconContainerStyle,
                ...iconContainerStyle,
              } as SxProps<Theme>
            }
          >
            {cancelIcon && (
              <CancelIcon
                alignmentBaseline="after-edge"
                sx={cancelIconStyle}
                onClick={onClose}
              />
            )}
          </Box>
          {children}
        </div>
      </div>
    </ModalMui>
  );
}
