import { useLabelNF } from './useLabelNF';

import LabelNFListing from '../../../components/LabelNFListing';
import LabelNFControl from '../../../components/LabelNFControl';
import Modal from '../../../components/Modal';
import Checkbox from '../../../components/Checkbox';
import { columns, rows, totalCheckboxes } from '../../../constants/labelNF';
import LabelNFLabel from '../../../components/LabelNFLabel';
import Alert from '../../../components/Alert';
import ListingHeader from '../../../components/ListingHeader';
import Table from '../../../components/Table';
import DeleteIcon from '@mui/icons-material/Delete';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';
import { MenuItem, Select } from '@mui/material';
import { DatePicker } from '../../../components/DatePicker';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ConfirmModal from '../../../components/ConfirmModal';

export default function LabelNF() {
  const {
    labels,
    lastLabel,
    accessKeyInputRef,
    isGeneratePdfModalOpen,
    checkedState,
    maxLabelsToShow,
    visibleLabels,
    errorMessage,
    isPackingListModalOpen,
    packingList,
    nfsCheckStatus,
    currentCarrier,
    carriers,
    selectedDate,
    isConfirmDeleteOneModalOpen,
    isConfirmDeleteAllModalOpen,
    handleCloseConfirmDeleteOneModal,
    handleConfirmDeleteAllPackingList,
    handleConfirmDeleteNFFromPackingList,
    handleCloseConfirmDeleteAllModal,
    handleDeleteAllPackingList,
    handleDeleteNFFromPackingList,
    handleSelectDate,
    handleGeneratePackingListButtonClick,
    handleSelectCarrier,
    handleChangeAllNfsCheck,
    handleChangeNfCheck,
    handleClosePackingListModal,
    handlePackingListClick,
    clearError,
    handleGenerateLabelNFPdf,
    handleCheckboxClick,
    handleCloseGeneratePdfModalModal,
    handleGeneratePdfButtonClick,
    handleChangeAccessKey,
    handleRemoveAllLabels,
    handleRemoveLabel,
    handleChangeLabelsAmount,
  } = useLabelNF();

  return (
    <main className="flex h-screen flex-col gap-10 overflow-hidden bg-backgroundPrimary px-4 2xl:flex-row">
      <LabelNFControl
        labels={labels}
        lastLabel={lastLabel}
        accessKeyInputRef={accessKeyInputRef}
        onGeneratePdfClick={handleGeneratePdfButtonClick}
        handleChangeAccessKey={handleChangeAccessKey}
        onPackingListClick={handlePackingListClick}
      />
      <LabelNFListing
        labels={labels}
        handleRemoveAllLabels={handleRemoveAllLabels}
        handleRemoveLabel={handleRemoveLabel}
        handleChangeLabelsAmount={handleChangeLabelsAmount}
      />
      <Modal
        isOpen={isGeneratePdfModalOpen}
        onClose={handleCloseGeneratePdfModalModal}
        iconContainerStyle={{ right: '1rem', top: '1rem' }}
        contentStyle="px-8 pt-12 pb-8"
        width="fit-content"
      >
        <div>
          <table className="border-separate border-spacing-x-1 rounded border-2 border-gray-200 px-2 py-4 shadow-[4px_4px_4px_rgba(0,0,0,0.2)]">
            <tbody>
              {Array.from({ length: rows }).map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {Array.from({ length: columns }).map((_, columnsIndex) => {
                    const index = rowIndex * columns + columnsIndex;

                    const visibleItemCount =
                      checkedState.filter(Boolean).length;
                    const shouldShowItem =
                      checkedState[index] &&
                      visibleItemCount -
                        checkedState.slice(index + 1).filter(Boolean).length <=
                        maxLabelsToShow;

                    const notIncluded = totalCheckboxes - visibleItemCount;

                    const labelIndex = index - notIncluded;

                    return (
                      <td
                        key={`${rowIndex}${columnsIndex}`}
                        className="relative h-[82.5px] w-[calc(142.5px+16px)] rounded border-2 border-gray-200 bg-zinc-50"
                      >
                        <div className="absolute right-0 top-0 z-20">
                          <div className="flex h-full items-start justify-end">
                            <div className="w-4">
                              <Checkbox
                                className="size-4"
                                checked={checkedState[index]}
                                onChange={() => handleCheckboxClick(index)}
                              />
                            </div>
                          </div>
                        </div>
                        {shouldShowItem && (
                          <div className="h-[82.5px] w-[142.5px] overflow-hidden">
                            <div className="inline-block origin-top-left [transform:scale(0.3)]">
                              <LabelNFLabel label={visibleLabels[labelIndex]} />
                            </div>
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 flex justify-center gap-2">
          <button
            onClick={handleGenerateLabelNFPdf}
            className="flex gap-1 rounded border border-zinc-300 bg-gradient-to-b from-zinc-100 to-zinc-200 px-2 py-1 disabled:opacity-60"
          >
            Ok
          </button>
          <button
            onClick={handleCloseGeneratePdfModalModal}
            className="flex gap-1 rounded border border-zinc-300 bg-gradient-to-b from-zinc-100 to-zinc-200 px-2 py-1 disabled:opacity-60"
          >
            Cancelar
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isPackingListModalOpen}
        onClose={handleClosePackingListModal}
        iconContainerStyle={{ right: '1rem', top: '1rem' }}
        contentStyle="px-8 pt-12 pb-8"
        width="fit-content"
      >
        <ListingHeader
          pdf={false}
          backButton={false}
          className="bg-white pl-0"
          showBackground={false}
        >
          <div className="mb-8 flex items-center gap-8">
            {currentCarrier && (
              <div>
                <span className="mr-4">Transportadora</span>
                <Select
                  value={currentCarrier?.id}
                  onChange={(event) => handleSelectCarrier(+event.target.value)}
                  sx={{
                    '& .MuiSelect-select': {
                      p: '0.5rem',
                    },
                  }}
                >
                  {carriers?.map((carrier) => (
                    <MenuItem value={carrier.id} key={carrier.id}>
                      {carrier.nome}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
            <DatePicker
              date={selectedDate}
              onDateSelect={handleSelectDate}
              label="Data de saída"
            />
            <button
              onClick={handleGeneratePackingListButtonClick}
              className="flex gap-1 rounded border border-zinc-300 bg-gradient-to-b from-zinc-100 to-zinc-200 px-2 py-1 disabled:opacity-60"
            >
              <PictureAsPdfIcon className="text-red-700" />
              Gerar Romaneio dos itens não selecionados
            </button>
          </div>
          <Table.Head rowStyle="pr-[3.25rem]">
            <div className="flex">
              <Table.CellStatic className="w-10">
                <Checkbox
                  onChange={(event) =>
                    handleChangeAllNfsCheck(event.target.checked)
                  }
                  className="size-4"
                  status={nfsCheckStatus}
                />
              </Table.CellStatic>
              <Table.Cell className="w-[25rem]">Transportadora</Table.Cell>
              <Table.Cell className="w-[25rem]">Destinatário</Table.Cell>
              <Table.Cell className="w-48">Cidade</Table.Cell>
              <Table.Cell className="w-10">UF</Table.Cell>
              <Table.Cell className="w-32">NF</Table.Cell>
              <Table.Cell className="w-44">Nº de volumes</Table.Cell>
              <Table.Cell className="w-44">Responsável</Table.Cell>
              <Table.Cell className="w-32">Data saída</Table.Cell>
              <Table.Cell className="w-10">
                <DeleteIcon
                  fontSize="small"
                  onClick={handleDeleteAllPackingList}
                />
              </Table.Cell>
            </div>
          </Table.Head>
        </ListingHeader>
        <div className="mr-8">
          <Table.Root
            itemType=""
            showNoContentMessage={false}
            itemsLength={packingList.length ?? 0}
            noContentContainerClassName="hidden"
            className="overflow-y-auto"
          >
            {packingList.map((nf) => {
              return (
                <div key={nf.id}>
                  <Table.Row>
                    <div className="flex w-full">
                      <Table.CellStatic className="w-10">
                        <Checkbox
                          checked={nf.check}
                          onChange={(event) =>
                            handleChangeNfCheck(nf.id, event.target.checked)
                          }
                          className="size-4"
                        />
                      </Table.CellStatic>
                      <Table.CellStatic className="w-[25rem]">
                        {nf.carrier.nome}
                      </Table.CellStatic>
                      <Table.CellStatic className="w-[25rem]">
                        {nf.recipient}
                      </Table.CellStatic>
                      <Table.CellStatic className="w-48">
                        {nf.city.nome}
                      </Table.CellStatic>
                      <Table.CellStatic className="w-10">
                        {nf.city.uf.sigla}
                      </Table.CellStatic>
                      <Table.CellStatic className="w-32">
                        {nf.nf}
                      </Table.CellStatic>
                      <Table.CellStatic className="w-44">
                        {nf.volumes}
                      </Table.CellStatic>
                      <Table.CellStatic className="w-44">
                        {nf.responsible.user}
                      </Table.CellStatic>
                      <Table.CellStatic className="w-32">
                        {format(nf.dtDeparture, 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </Table.CellStatic>
                      <Table.CellStatic className="w-10">
                        <DeleteIcon
                          fontSize="small"
                          onClick={() => handleDeleteNFFromPackingList(nf)}
                        />
                      </Table.CellStatic>
                    </div>
                  </Table.Row>
                </div>
              );
            })}
          </Table.Root>
        </div>
      </Modal>

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
      <ConfirmModal
        isModalOpen={isConfirmDeleteOneModalOpen}
        contentMessage="Deseja excluir esta nota fiscal?"
        onCloseModal={handleCloseConfirmDeleteOneModal}
        onConfirm={handleConfirmDeleteNFFromPackingList}
        onNotConfirm={handleCloseConfirmDeleteOneModal}
      />
      <ConfirmModal
        isModalOpen={isConfirmDeleteAllModalOpen}
        contentMessage="Deseja excluir todas as notas fiscais desse romaneio?"
        onCloseModal={handleCloseConfirmDeleteAllModal}
        onConfirm={handleConfirmDeleteAllPackingList}
        onNotConfirm={handleCloseConfirmDeleteAllModal}
      />

      <div className="fixed -bottom-[100rem]">
        {labels.map((label) => (
          <div
            key={label.labelAccessKey}
            id={label.labelAccessKey}
            className="h-[275px] w-[475px]"
          >
            <LabelNFLabel label={label} />
          </div>
        ))}
      </div>
    </main>
  );
}
