import PartsGroupManageModal from '../../../components/Admin/PartsGroupMenageModal';
import PartsGroupContent from '../../../components/PartsGroupContent';
import { usePartsGroup } from './usePartsGroup';

export default function PartsGroup() {
  const {
    isMenageModalOpen,
    partGroupSelected,
    selectedDescriptionId,
    handleCloseMenageModal,
    handleTableRowClick,
    handleAddPartsGroupClick,
  } = usePartsGroup();

  return (
    <>
      <PartsGroupContent
        handleAddPartsGroupClick={handleAddPartsGroupClick}
        onTableRowClick={handleTableRowClick}
      />

      <PartsGroupManageModal
        selectedDescriptionId={selectedDescriptionId}
        isMenageModalOpen={isMenageModalOpen}
        onCloseMenageModal={handleCloseMenageModal}
        currentPartsGroupSelected={partGroupSelected}
      />
    </>
  );
}
