import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  fieldsByDomainIdApiCache,
  fieldsDomainsCoordinateCache,
} from '../../constants/requestCacheName';
import { useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSignatureDomainStore } from '../../store/signatureDomain';
import {
  deleteAllCoordinatesByDomainId,
  getAllFields,
} from '../../services/signatureDomain';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  FieldDomainItem,
  FindAllCoordinates,
  FindAllFieldDomainItem,
} from '../../types/domainImageCoordinate';
import { useModal } from '../../hooks/useModal';
import { useQueryCache } from '../../hooks/useQueryCache';
import { mutationErrorHandling } from '../../utils/errorHandling';
import { useError } from '../../hooks/useError';

export const useFieldsTable = (
  onEditField: (fieldDomainItem: FieldDomainItem) => void,
) => {
  const theme = useTheme();

  const isTableClicked = useRef(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const fieldClickedIndex = useRef(0);

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { domainId } = useParams();
  const [currentFieldToDeleteId, setCurrentFieldToDeleteId] = useState<
    number | null
  >(null);

  const queryClient = useQueryClient();

  const {
    state: { currentFieldClicked, currentDomain },
    actions: { setCurrentFieldClicked },
  } = useSignatureDomainStore();

  const { clearCache } = useQueryCache();

  const {
    isModalOpen: isDeleteAllCoordinatesModalOpen,
    handleCloseModal: handleCloseAllCoordinatesModal,
    handleOpenModal: handleOpenAllCoordinatesModal,
  } = useModal();

  const { data: fields } = useQuery<FindAllFieldDomainItem>(
    `${fieldsByDomainIdApiCache}${currentDomain?.id}`,
    async () => (await getAllFields(Number(domainId))).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      // enabled: false,
    },
  );

  const { mutate: deleteAllCoordinates } = useMutation({
    mutationFn: async () => {
      return (await deleteAllCoordinatesByDomainId(+domainId!)).data;
    },
    onSuccess: () => {
      clearCache(`${fieldsDomainsCoordinateCache}${domainId}`);
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar as coordenadas',
        setErrorMessage,
      );
    },
  });

  useEffect(() => {
    if (!isTableClicked.current) {
      const tableCellHeightInPx = 53;
      const fieldClickedPosition =
        tableCellHeightInPx * fieldClickedIndex.current;

      tableRef.current?.scrollTo({
        top: fieldClickedPosition,
      });
      return;
    }

    isTableClicked.current = false;
  }, [currentFieldClicked]);

  const handleTableRowClick = (fieldClicked: FieldDomainItem) => {
    isTableClicked.current = true;

    setCurrentFieldClicked(fieldClicked);
  };

  // const handleRemoveIconClick = (fieldId: number) => {
  //   if (fieldId === currentFieldToDeleteId) {
  //     setCurrentFieldToDeleteId(null);
  //     return;
  //   }

  //   setCurrentFieldToDeleteId(fieldId);
  // };

  const handleDeleteAllCoordinatesButtonClick = () => {
    handleOpenAllCoordinatesModal();
  };

  const handleConfirmDeleteAllCoordinates = () => {
    deleteAllCoordinates();
    handleCloseAllCoordinatesModal();
  };

  const coordinates = queryClient.getQueryData<FindAllCoordinates>(
    `${fieldsDomainsCoordinateCache}${domainId}`,
  );

  return {
    theme,
    fields: fields?.items,
    tableRef,
    fieldClickedIndex,
    currentFieldClicked,
    isDeleteAllCoordinatesModalOpen,
    errorMessage,
    coordinates,
    clearError,
    handleConfirmDeleteAllCoordinates,
    handleCloseAllCoordinatesModal,
    handleDeleteAllCoordinatesButtonClick,
    handleTableRowClick,
  };
};
