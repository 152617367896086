import {
  ImageType,
  ImageTypesPaginated,
  SaveImageTypeDto,
  UpdateImageTypeDto,
} from '../types/imageTypes';
import { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getImageTypesPaginated = async (
  page: number = 1,
  direction: 'ASC' | 'DESC' | null = 'ASC',
  limit?: number | null,
  withoutDefault = true,
) => {
  const url = addSearchParamsInUrl(
    '/image-type/v1',
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    { name: 'withoutDefault', value: withoutDefault },
  );
  const response = await api.get<ImageTypesPaginated>(url);
  return response;
};

export const saveImageType = async ({
  name,
  extensionId,
  maxSize,
}: SaveImageTypeDto) => {
  const body: SaveImageTypeDto = {
    name,
    extensionId,
    maxSize,
  };

  const response = await api.post<ImageType>('/image-type/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const updateImageType = async ({
  id,
  name,
  extensionId,
  imageTypeExtensionId,
  maxSize,
}: UpdateImageTypeDto) => {
  const body: UpdateImageTypeDto = {
    id,
    name,
    extensionId,
    imageTypeExtensionId,
    maxSize,
  };

  const response = await api.put<ImageType>('/image-type/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const deleteImageType = async (imageTypeId: number) => {
  const response = await api.delete<void>(`/image-type/v1/${imageTypeId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};
