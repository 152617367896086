import { useCallback } from 'react';
import { useModal } from '../../../hooks/useModal';
import { ProductDuimp } from '../../../types/productDuimp';
import { useForm } from 'react-hook-form';

export const useDuimp = () => {
  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: handleCloseManageModal,
    handleOpenModal: handleOpenManageModal,
  } = useModal();

  const { register, setValue, watch } = useForm({
    defaultValues: {
      codigoInterno: '',
      denominacao: '',
      ncm: '',
      descricao: '',
    },
  });

  const codigoInterno = watch('codigoInterno');
  const denominacao = watch('denominacao');
  const ncm = watch('ncm');
  const descricao = watch('descricao');

  const handleTableRowClick = useCallback(
    (produto: ProductDuimp) => {
      setValue('codigoInterno', produto.codigosInterno?.[0]);
      setValue('denominacao', produto.denominacao);
      setValue('ncm', produto.ncm);
      setValue('descricao', produto.descricao);

      handleOpenManageModal();
    },
    [handleOpenManageModal, setValue],
  );

  return {
    isMenageModalOpen,
    denominacao,
    ncm,
    codigoInterno,
    descricao,
    register,
    handleTableRowClick,
    handleCloseManageModal,
  };
};
