import { useEffect, useState } from 'react';
import { useSearchParams } from '../../hooks/useSearchParams';
import { getAuth } from '../../utils/auth';
import { getImageFromApi, verifyImageUrlExists } from '../../utils/getImageUrl';
import { useQuery, useQueryClient } from 'react-query';
import {
  linesCache,
  liveSearchProductBuffaloCache,
} from '../../constants/requestCacheName';
import { Lines } from '../../types/lines';
import { useLiveSearch } from '../../buffalos/hooks/useLiveSearch';
import { useNavigate } from 'react-router-dom';
import {
  ProductsLineCategory,
  ProductsLineCategoryPaginated,
} from '../../types/productsLineCategory';
import { getProductLineCategoriesLiveSearchPaginated } from '../../services/productLineCategory';

export const useMenuCard = () => {
  const auth = getAuth();
  const [lineId] = useSearchParams('lineId');
  const [currentLineCatalogUrl, setCurrentLineCatalogUrl] = useState<
    string | null
  >(null);

  const [currentLine, setCurrentLine] = useState<string | null>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    const linesQuery = queryClient.getQueryData<Lines>(linesCache);
    if (linesQuery && lineId) {
      const line = linesQuery?.items?.find(
        (line) => line.id === Number(lineId),
      );

      setCurrentLine(line?.name || null);
    }
  }, [lineId, queryClient]);

  useEffect(() => {
    const verifyImageExists = async () => {
      const url = getImageFromApi(`${lineId}.pdf`, 'pdf/cache/line');
      const isImageExists = await verifyImageUrlExists(url);
      if (isImageExists) {
        setCurrentLineCatalogUrl(url);
        return;
      }

      setCurrentLineCatalogUrl(null);
    };
    verifyImageExists();
  }, [lineId]);

  const handlePdfCatalogButtonClick = async () => {
    if (currentLineCatalogUrl) {
      // window.open(currentLineCatalogUrl, '_blank', 'noopener,noreferrer');
       try {
         const response = await fetch(currentLineCatalogUrl);
         const blob = await response.blob();
         const url = window.URL.createObjectURL(blob);

         const link = document.createElement('a');
         link.href = url;
         link.download = `Catálogo_de_peças_linha_${currentLine}.pdf`;
         document.body.appendChild(link);
         link.click();

         // Limpa o objeto URL para evitar vazamento de memória
         window.URL.revokeObjectURL(url);
         document.body.removeChild(link);
       } catch (error) {
         console.error('Erro ao baixar o arquivo:', error);
       }
    }
  };

  const { data: productsSearch, refetch: refetchProductsSearch } =
    useQuery<ProductsLineCategoryPaginated>(
      liveSearchProductBuffaloCache,
      async () =>
        (
          await getProductLineCategoriesLiveSearchPaginated(
            inputRef.current?.value ?? null,
            null,
          )
        ).data,
      {
        retry: false,
        enabled: false,
        refetchOnWindowFocus: false,
      },
    );

  const {
    inputRef,
    handleClickOutAutoComplete,
    handleSearchInputChange,
    handleClearSearchCache,
    handleClearSearchAndSetFocus,
  } = useLiveSearch({ refetch: refetchProductsSearch });

  const handleProductSearchClick = async ({
    product,
  }: ProductsLineCategory) => {
    handleClearSearchCache();
    navigate(
      `/parts-catalog/${product.id}?product=${encodeURIComponent(product.name)}${product.associatedProductId ? `&previus-version=${product.associatedProductId}` : ''}`,
    );
  };
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!productsSearch || productsSearch.items.length === 0) return;

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        setSelectedIndex((prev) =>
          Math.min(prev + 1, productsSearch.items.length - 1),
        );
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        setSelectedIndex((prev) => Math.max(prev - 1, 0));
      }

      if (event.key === 'Enter' && selectedIndex !== -1) {
        event.preventDefault();
        handleProductSearchClick(productsSearch.items[selectedIndex]);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [productsSearch, selectedIndex]);

  return {
    auth,
    currentLineCatalogUrl,
    handlePdfCatalogButtonClick,
    currentLine,
    inputRef,
    handleSearchInputChange,
    handleClearSearchAndSetFocus,
    productsSearch,
    handleClickOutAutoComplete,
    selectedIndex,
    handleProductSearchClick,
  };
};
