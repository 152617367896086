import { Content } from 'pdfmake/interfaces';

const headerContent = (productName: string, productId: number): Content => [
  { text: productName, absolutePosition: { x: 50 + 32, y: 24 }, color: '#222' },
  {
    text: productId,
    absolutePosition: { x: 50 + 32, y: 24 + 16 },
    color: '#bababa',
  },
];

export default headerContent;
