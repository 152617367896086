import { twMerge } from 'tailwind-merge';
import { ItemInUse } from '../../types/inUse';
import { Page } from '../../types/pages';
import Loading from '../Loading';
import Main from '../Main';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { usePagesContent } from './usePagesContent';
import CheckIcon from '@mui/icons-material/Check';
import Footer from '../Footer';

type PagesContentProps = {
  isModal?: boolean;
  pagesInUse?: ItemInUse[];
  onTableRowClick: (page: Page) => void;
};

export default function PagesContent({
  isModal,
  pagesInUse,
  onTableRowClick,
}: PagesContentProps) {
  const {
    inputRef,
    isSearchInputDirty,
    pagesLength,
    pagesShown,
    pagesShownLength,
    isFetching,
    sortField,
    isSearch,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
  } = usePagesContent();

  return (
    <>
      {isModal && (
        <div>
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">Páginas</h1>
            <SearchInput
              ref={inputRef}
              onSearch={handleSearch}
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
              onButtonClick={handleCancelSearch}
            />
          </div>
          <Table.Head rowStyle="px-4">
            <Table.Cell
              items={pagesShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
            >
              Nome
            </Table.Cell>
          </Table.Head>
        </div>
      )}

      <Main isModal={isModal}>
        {isFetching && <Loading loadMore={!isModal} />}
        {!isFetching && pagesShown && (
          <Table.Root
            itemType="Descrições"
            itemsLength={pagesLength}
            className={`${isModal ? 'h-[60vh] pb-16' : ''}`}
            paddingTop="0"
            showNoContentMessage={pagesShown.length <= 0 && isSearch}
            showSearchMessage={pagesShown.length <= 0 && !isSearch}
          >
            {pagesShown.map((page) => {
              const isInUse = pagesInUse?.some((item) => item.id === page.id);

              return (
                <Table.Row
                  key={page.id}
                  onClick={() => !isInUse && onTableRowClick(page)}
                  className={twMerge(
                    isInUse ? 'cursor-default opacity-50' : '',
                    'px-4',
                  )}
                >
                  <Table.CellStatic>{page.name}</Table.CellStatic>
                  {isModal && (
                    <Table.CellStatic className="w-6">
                      {isInUse && <CheckIcon className="text-primary" />}
                    </Table.CellStatic>
                  )}
                </Table.Row>
              );
            })}
          </Table.Root>
        )}
      </Main>

      <Footer
        current={pagesShownLength}
        total={pagesLength}
        itemType="Páginas"
        data-modal={isModal}
        className={
          isModal ? 'absolute mx-[1px] mb-[1px] mt-[-10vh] rounded-b-xl' : ''
        }
        // infosStyle={{ mt: isModal ? '-2rem' : '' }}
      />
    </>
  );
}
