import { Box } from '@mui/material';
import { usePartsCatalag } from './usePartsCatalog';
import PartsGroup from '../../components/PartsGroup';
import ProductDetails from '../../components/ProductDetails';
import PdfButton from '../../components/PdfButton';
import {
  explodedViewStyle,
  partsCatalogTable,
  partsGroupStyle,
} from './styles';
import ExplodedView from '../../components/ExplodedView';
import PartsCatalogTable from '../../components/PartsCatalogTable';
import Header from '../../components/Header';
import SearchBar from '../../components/SearchBar';
import { AutoComplete } from '../../components/AutoComplete';
import Alert from '../../../components/Alert';
import BreadCrumb from '../../components/BreadCrumb';
import { PartsGroupItemDomainLiveSearch } from '../../../types/partsGroupItem';

export default function PartsCatalog() {
  const {
    currentPartIdHover,
    currentPartsGroup,
    currentProduct,
    explodedViewImageName,
    canEdit,
    partsSearch,
    inputRef,
    errorMessage,
    isPdfLoading,
    isMobile,
    openSearch,
    selectedIndex,
    handleOpenSearch,
    handleBackButtonClick,
    clearError,
    handleClickOutAutoComplete,
    handleSearchInputChange,
    handleClearSearchAndSetFocus,
    handleProductSearchClick,
    handlePartMouseOut,
    handlePartMouseEnter,
    handleClickPart,
    handleGeneratePdf,
  } = usePartsCatalag();
    console.log("🚀 ~ PartsCatalog ~ currentPartsGroup:", currentPartsGroup?.id)

  return (
    <>
      <Header
        menuIcon={false}
        handleBackButtonClick={handleBackButtonClick}
        openSearch={openSearch}
        onClick={handleClickOutAutoComplete}
        onCLickSearchIcon={handleOpenSearch}
        productCatalog={true}
        productsDetail={
          <ProductDetails
            partsList={true}
            product={currentProduct}
            isMobile={true}
            pdfButton={
              <PdfButton
                onPdfClick={handleGeneratePdf}
                isPdfLoading={isPdfLoading}
                fontSize={1}
              />
            }
          />
        }
        searchParts={
          <div className="relative z-50 w-full">
            <SearchBar
              ref={inputRef}
              className="w-full pr-2"
              onChange={handleSearchInputChange}
              onClick={handleSearchInputChange}
              onClear={handleClearSearchAndSetFocus}
              placeholderInput="Encontre sua peça"
            />
            {partsSearch && (
              <AutoComplete.Root
                items={partsSearch.items}
                onOutClick={handleClickOutAutoComplete}
                className="absolute w-[calc(100%-0.5rem)] border-2 border-t-0"
                placeholderInput="Sua pesquisa não encontrou nenhuma peça correspondente."
              >
                {partsSearch.items.map(
                  (
                    {
                      id,
                      domain,
                      partsGroupItem,
                    }: PartsGroupItemDomainLiveSearch,
                    index,
                  ) => {
                    const { part, partsGroup } = partsGroupItem;
                    const isSelected = index === selectedIndex;

                    return (
                      <AutoComplete.Item
                        key={id}
                        onClick={() =>
                          handleProductSearchClick({
                            id,
                            domain,
                            partsGroupItem,
                          })
                        }
                        className={`p-2 ${isSelected ? 'bg-zinc-300' : ''}`}
                        ref={(el) => {
                          if (isSelected && el) {
                            el.scrollIntoView({ block: 'nearest' });
                          }
                        }}
                      >
                        <>
                          {part.id} - {part.name}
                          <BreadCrumb
                            items={[
                              partsGroup.name,
                              `Qtd. ${+partsGroupItem?.amount}`,
                            ]}
                          />
                        </>
                      </AutoComplete.Item>
                    );
                  },
                )}
              </AutoComplete.Root>
            )}
          </div>
        }
      />
      <main
        data-isOpen={openSearch}
        className="transition-medium h-[88vh] overflow-hidden data-[isOpen=false]:mt-2 data-[isOpen=true]:mt-16 data-[isOpen=false]:sm:mt-1 data-[isOpen=true]:sm:mt-14 lg:transition-none data-[isOpen=false]:lg:mt-0 data-[isOpen=true]:lg:mt-0"
      >
        <div className="flex flex-col">
          <Box className="flex">
            {isMobile ? (
              <Box sx={{ width: '100%', padding: '0 1rem 0 1rem' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginLeft: '-0.5rem',
                    // marginTop: '1rem',
                    marginBottom: 0,
                  }}
                >
                  <div className="w-full flex-wrap justify-center sm:hidden">
                    <ProductDetails
                      partsList={true}
                      product={currentProduct}
                      isMobile={isMobile}
                      pdfButton={
                        <PdfButton
                          onPdfClick={handleGeneratePdf}
                          isPdfLoading={isPdfLoading}
                          fontSize={1.3}
                        />
                      }
                    />
                  </div>
                </Box>
                <h2 className="flex items-center text-lg font-bold">
                  Conjuntos de Peças
                  <span className="text-2xl text-yellow-500">.</span>
                </h2>
                <PartsGroup
                  canEdit={canEdit}
                  currentPartGroupId={currentPartsGroup?.id}
                  isMobile={isMobile}
                />

                <h2 className="mt-2 flex items-center text-lg font-bold">
                  Peças
                  <span className="text-2xl text-yellow-500">.</span>
                </h2>
                <div className="w-auto">
                  <PartsCatalogTable
                    currentPartGroupName={currentPartsGroup?.name}
                    currentPartGroupId={currentPartsGroup?.id}
                    currentPartHoverId={currentPartIdHover}
                    onRowMouseEnter={handlePartMouseEnter}
                    onRowMouseOut={handlePartMouseOut}
                    onRowClick={handleClickPart}
                    isMobile={isMobile}
                  />
                </div>
                <Box sx={{ ...explodedViewStyle }}>
                  <ExplodedView
                    canEdit={canEdit}
                    currentPartHoverId={currentPartIdHover}
                    imageName={explodedViewImageName}
                    onPartMouseEnter={handlePartMouseEnter}
                    onPartMouseOut={handlePartMouseOut}
                    onPartClick={handleClickPart}
                  />
                </Box>
              </Box>
            ) : (
              <>
                <Box
                  sx={partsGroupStyle}
                  className="-mt-10 ml-4 w-full xl:-mt-[5rem]"
                >
                  <div className="fixed top-[6rem] z-20 bg-backgroundPaper">
                    {/* <ProductDetails
                      product={currentProduct}
                      isMobile={!isMobile}
                    /> */}
                  </div>
                  <div className="mt-28 sm:mt-[5rem]">
                    <PartsGroup
                      canEdit={canEdit}
                      currentPartGroupId={currentPartsGroup?.id}
                      isMobile={isMobile}
                    />
                  </div>
                </Box>

                <Box
                  sx={explodedViewStyle}
                  className="flex w-full flex-grow items-start justify-center sm:mt-[2.5rem] md:w-auto xl:mt-0 2xl:mt-2"
                >
                  <ExplodedView
                    canEdit={canEdit}
                    currentPartHoverId={currentPartIdHover}
                    imageName={explodedViewImageName}
                    onPartMouseEnter={handlePartMouseEnter}
                    onPartMouseOut={handlePartMouseOut}
                    onPartClick={handleClickPart}
                  />
                </Box>
                {/* </Box> */}
                <Box
                  sx={{ ...partsCatalogTable, alignSelf: 'flex-start' }}
                  className="relative mr-4 mt-6 w-full md:w-auto"
                >
                  <div className="mt-3 xl:-mt-6">
                    <PartsCatalogTable
                      currentPartGroupName={currentPartsGroup?.name}
                      currentPartGroupId={currentPartsGroup?.id}
                      currentPartHoverId={currentPartIdHover}
                      onRowMouseEnter={handlePartMouseEnter}
                      onRowMouseOut={handlePartMouseOut}
                      onRowClick={handleClickPart}
                      isMobile={isMobile}
                    />
                  </div>
                </Box>
              </>
            )}
          </Box>
          {/* <Footer /> */}
        </div>
      </main>
      <Alert
        className="!w-[350px] sm:!w-auto"
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
