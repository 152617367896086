import { Domain } from '../../types/domain';
import { useHandleTable } from '../../hooks/useHandleTable';
import { domainSearchBaseEndpoint } from '../../constants/endpoints';

export const useDomainContent = () => {
  // const { data: domains, isFetching: isDomainFetching } =
  //   useQuery<FindAllDomains>(
  //     domainCache,
  //     async () => (await getAllDomains()).data,
  //   );

  const {
    itemsShown,
    isSearchInputDirty,
    inputRef,
    sortField,
    pagination,
    currentPage,
    isSearchFetching,
    searchItems,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleChangePage,
    handleChangePagination,
    itemsShownLength: domainsShownLength,
    itemsLength: domainsLength,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
  } = useHandleTable(0, [], null, null, domainSearchBaseEndpoint);

  const domains = searchItems?.items as Domain[];
  const domainsShown: Domain[] = itemsShown;

  const isFetching = isSearchFetching;

  return {
    isFetching,
    domains,
    domainsShown,
    isSearchInputDirty,
    inputRef,
    sortField,
    domainsLength,
    domainsShownLength,
    pagination,
    currentPage,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleChangePage,
    handleChangePagination,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
  };
};
