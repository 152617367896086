import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import Footer from '../../../components/Footer';
import ListingHeader from '../../../components/ListingHeader';
import Main from '../../../components/Main';
import SearchInput from '../../../components/SearchInput';
import Table from '../../../components/Table';
import { formatDate } from '../../../utils/formatDate';
import { useSynchronization } from './useSynchronization';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ScheduleIcon from '@mui/icons-material/Schedule';

export default function Synchronization() {
  const {
    tablesSyncData,
    tableSyncDataShown,
    tableSyncDataLength,
    isConfirmSyncModalOpen,
    inputRef,
    isSearchInputDirty,
    sortField,
    currentTableName,
    errorMessage,
    cronInfos,
    getSyncStatus,
    clearError,
    handleCloseConfirmSyncModal,
    handleConfirmSync,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
    handleSynchronize,
  } = useSynchronization();

  return (
    <>
      <ListingHeader pdfTop="3rem" pdf={false}>
        <div className="mt-4">
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-2xl">Sincronização</h1>
            <p className="text-sm">
              Dados sincronizados automaticamente todos os dias às{' '}
              {cronInfos?.cronTime}
            </p>
          </div>
          <SearchInput
            ref={inputRef}
            onSearch={handleSearch}
            // onButtonClick={handleCancelSearch}
            showCancelOptions={isSearchInputDirty}
            onCancelSearch={handleCancelSearch}
          />
        </div>
        <Table.Head>
          <Table.Cell
            items={tablesSyncData}
            type="name"
            onSort={handleSortTable}
            isSortActive={sortField === 'name'}
          >
            Tabela
          </Table.Cell>
          <div className="flex">
            <Table.Cell
              items={tablesSyncData}
              type="lastSynchronizationStatus"
              onSort={handleSortTable}
              isSortActive={sortField === 'lastSynchronizationStatus'}
              className="w-72"
            >
              <span className="w-full text-center">
                Status da última modificação
              </span>
            </Table.Cell>
            <Table.Cell
              items={tablesSyncData}
              type="lastSynchronization"
              onSort={handleSortTable}
              isSortActive={sortField === 'lastSynchronization'}
              className="w-48"
            >
              Última modificação
            </Table.Cell>
            <div className="w-28" />
          </div>
        </Table.Head>
      </ListingHeader>

      <Main>
        <Table.Root
          itemType="tipos de usuários"
          itemsLength={tableSyncDataLength}
          paddingTop="0"
        >
          {tableSyncDataShown.map((data) => {
            return (
              <Table.Row key={data.name}>
                <Table.CellStatic>{data.name}</Table.CellStatic>
                <div className="flex">
                  <div className="w-72">
                    <Table.CellStatic className="justify-center">
                      {getSyncStatus(
                        data.lastSynchronizationStatus,
                        <CheckCircleIcon className="text-success" />,
                        <ScheduleIcon className="text-yellow-400" />,
                        <CancelIcon className="text-errorText" />,
                      )}
                    </Table.CellStatic>
                  </div>
                  <div className="w-48">
                    <Table.CellStatic>
                      {data.lastSynchronization
                        ? formatDate(data.lastSynchronization)
                        : 'Não atualizado'}
                    </Table.CellStatic>
                  </div>
                  <Table.CellStatic>
                    <button
                      disabled={data.lastSynchronizationStatus === 'PENDENTE'}
                      onClick={() => handleSynchronize(data)}
                      className="w-28 text-right text-sm text-primary disabled:opacity-50"
                    >
                      Sincronizar
                    </button>
                  </Table.CellStatic>
                </div>
              </Table.Row>
            );
          })}
        </Table.Root>
      </Main>
      <Footer
        current={tableSyncDataShown.length}
        total={tableSyncDataLength}
        itemType="Tabelas"
      />

      <ConfirmModal
        contentMessage={`Deseja sincronizar os dados de ${currentTableName}?`}
        isModalOpen={isConfirmSyncModalOpen}
        onCloseModal={handleCloseConfirmSyncModal}
        onNotConfirm={handleCloseConfirmSyncModal}
        onConfirm={handleConfirmSync}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
