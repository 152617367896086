import { SxProps, Theme } from '@mui/material';

export const categoriesNavbarStyle: SxProps<Theme> = {
  alignItems: 'center',
  padding: '1.5rem 1rem',

  '@media (max-width: 900px)': {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '1rem',
  },
};

export const pagesListStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  gap: '2rem',
  flexWrap: 'wrap',
};

export const listItemsStyle: SxProps<Theme> = {
  '@media (max-width: 900px)': {
    backgroundColor: '#fff',
    flex: '1',
    height: '3rem',
    '& *': {
      border: 'none',
    },
  },
};
