import { useQuery } from 'react-query';
import { productsViculatedDescriptionApiCache } from '../../../constants/requestCacheName';
import { getAllProductsVinculatedIdDescription } from '../../../services/productDescription';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { FindAll } from '../../../types/pagination';
import { Product } from '../../../types/products';
import { useModal } from '../../../hooks/useModal';
import { useEffect, useMemo, useState } from 'react';
import { useScreenHeight } from '../../../hooks/useHeight';

export const useDescriptionMenageModal = (
  currentDescriptionId: number | null,
) => {
  const { data: allProducts, isFetching: isFetchingAllProducts } =
    useQuery<FindAll<Product> | null>(
      `${productsViculatedDescriptionApiCache}-${currentDescriptionId}`,
      async () => {
        if (currentDescriptionId) {
          const request =
            await getAllProductsVinculatedIdDescription(currentDescriptionId);
            return request.data;
          }

        return null;
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        cacheTime: 0,
      },
    );

  const {
    sortField,
    inputRef,
    isSearchInputDirty,
    handleSearch,
    itemsLength: productsLength,
    itemsShown,
    handleSortTable,
    handleCancelSearch,
  } = useHandleTable(
    allProducts?.meta.totalItems ?? 0,
    allProducts?.items ?? [],
  );
  const productsShown: Product[] = itemsShown;

  const { screenHeight } = useScreenHeight();

  const {
    isModalOpen: isDescriptionProductModalOpen,
    handleCloseModal: handleCloseDescriptionProductModal,
    handleOpenModal: handleOpenDescriptionProductModal,
  } = useModal();

  const [currentProduct, setCurrentProduct] = useState<Product | null>(null);

  const handleProductLineClick = (product: Product) => {
    setCurrentProduct(product);
    handleOpenDescriptionProductModal();
  };

  return {
    sortField,
    inputRef,
    isSearchInputDirty,
    productsLength,
    productsShown,
    isFetchingAllProducts,
    isDescriptionProductModalOpen,
    currentProduct,
    screenHeight,
    handleProductLineClick,
    handleCloseDescriptionProductModal,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
  };
};
