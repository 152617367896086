import {
  FindAllProductsParts,
  ProductsPartsPaginated,
} from '../types/productsParts';
import generatePaginatedUrl from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getAllProductsParts = async () => {
  const response = await api.get<FindAllProductsParts>(
    '/product-parts/v1/find-all',
  );
  return response;
};

export const getProductsPartsPaginated = async (
  page: number,
  direction: 'ASC' | 'DESC' = 'ASC',
  limit?: number,
) => {
  const url = generatePaginatedUrl('/product-parts/v1', page, limit, direction);
  const response = await api.get<ProductsPartsPaginated>(url);
  return response;
};
