import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export default function Unauthorized() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{ mt: 8, textAlign: 'center' }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <LockOutlinedIcon sx={{ fontSize: 60, color: 'error.main' }} />
        <Typography variant="h5" gutterBottom>
          Acesso Não Autorizado
        </Typography>
        <Typography variant="body1" gutterBottom>
          Você não tem permissão para acessar esta página. Por favor, verifique
          se você está logado com a conta correta ou contate o suporte para mais
          informações.
        </Typography>
        <Button
          variant="contained"
          onClick={handleGoBack}
          sx={{ mt: 3, mb: 2, py: 1.1 }}
        >
          Voltar à Página Inicial
        </Button>
      </Box>
    </Container>
  );
}
