import { HTMLInputTypeAttribute, useState } from 'react';

export const useInput = () => {
  const [showPassword, setSetshowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleIconEyeClick = () => {
    setSetshowPassword((prev) => !prev);
  };

  const defineInputType = (
    type: HTMLInputTypeAttribute | undefined,
    showEye: boolean,
  ): HTMLInputTypeAttribute | undefined => {
    if (showEye) {
      if (showPassword) {
        return 'text';
      }
      return 'password';
    }

    return type;
  };

  return {
    showPassword,
    handleIconEyeClick,
    defineInputType,
    isFocused,
    setIsFocused,
  };
};
