import { FindAll } from '../types/pagination';
import { Product } from '../types/products';
import {
  SaveUpdateProductDescription,
  ProductDescription,
  SaveUpdateDto,
  DeleteDto,
  FindAllProductDescriptionDetails,
} from '../types/productsDescription';
import { api } from './api';

export const getAllProductsVinculatedIdDescription = async (
  descriptionId: number,
) => {
  const response = await api.get<FindAll<Product>>(
    `product-description/v1/find-all-items/description-id/${descriptionId}`,
  );
  return response;
};

export const getAllProductDescriptionsByProductId = async (
  productId: number,
) => {
  const response = await api.get<FindAllProductDescriptionDetails>(
    `product-description/v1/find-all-product-descriptions/product-id/${productId}`,
  );
  return {
    data: response.data.descriptions,
  };
};

export const saveUpdateProductDescription = async (dto: SaveUpdateDto[]) => {
  const body = {
    items: dto,
  };

  const response = await api.post<SaveUpdateProductDescription>(
    `/product-description/v1/create-update-items-descriptions`,
    body,
  );

  return response;
};

export const deleteProductsDescriptions = async (dto: DeleteDto[]) => {
  const body = {
    items: dto.map(({ descriptionId, productId }) => ({
      descriptionId,
      productId,
    })),
  };

  const response = await api.delete<void>(`/product-description/v1`, {
    data: body,
  });

  return response;
};

export const deleteAllByProductId = async (productId: number) => {
  const response = await api.delete<void>(
    `/product-description/v1/${productId}`,
  );

  return response;
};
