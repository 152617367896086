import { useQuery } from 'react-query';
import { imagesCache, imageTypesCache } from '../../constants/requestCacheName';
import { getImagesPaginated } from '../../services/images';
import { Images, ImagesPaginated } from '../../types/images';
import { useHandleTable } from '../../hooks/useHandleTable';
import { ImagesContentProps } from '.';
import { useCallback, useEffect, useMemo } from 'react';
import { getTableColumnWidthByBiggestElement } from '../../utils/tableColumnStyles';
import { imagesSearchBaseEndpoint } from '../../constants/endpoints';
import formatSearch from '../../utils/formatSearch';
import { imageTypesText } from '../../constants/imageTypes';
import { ImageTypes } from '../../enums/imageTypes';
import { Search, search as searchDatabase } from '../../services/search';
import { useSearchParams } from '../../hooks/useSearchParams';
import { useNavigate } from 'react-router-dom';
import { getImageTypesPaginated } from '../../services/imageTypes';
import { ImageTypesPaginated } from '../../types/imageTypes';

type UseImageContent = Pick<ImagesContentProps, 'isModal' | 'imageType'>;

export const useImagesContent = ({ isModal, imageType}: UseImageContent) => {
  const [typeImageActive] = useSearchParams('imageType') as [string];
  const navigate = useNavigate();

  const {
    data: images,
    isFetching: isFetchingImages,
    refetch: refetchImages,
  } = useQuery<ImagesPaginated>(
    imagesCache,
    async () =>{
      const activeType = imageType || typeImageActive;
      return (await getImagesPaginated(currentPage, null, null, activeType)).data
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: typeImage, isFetching: isFetchingTypeImage } =
    useQuery<ImageTypesPaginated>(
      imageTypesCache,
      async () =>
        (await getImageTypesPaginated(currentPage, null, null, false)).data,
      {
        retry: false,
        refetchOnWindowFocus: false,
      },
    );

  const tableColumnNameWidth = isModal ? 50 : 80;

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return searchDatabase<Images>(
        baseUrl,
        searchValue,
        page,
        null,
        null,
        imageType || typeImageActive
          ? [{ name: 'imageType', value: imageType || typeImageActive }]
          : undefined
      );
    },
    [imageType, typeImageActive],
  );

  const loadMore = useCallback(
    async (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      const activeType = imageType || typeImageActive;
      return await getImagesPaginated(page, direction, limit, activeType);
    },
    [imageType, typeImageActive],
  );

  const {
    sortField,
    currentPage,
    inputRef,
    itemsLength: imagesLength,
    itemsShown,
    itemsShownLength: imagesShownLength,
    isLoadMoreFetching,
    endPageRef,
    isSearchInputDirty,
    handleCancelSearch,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch: searchScreen,
    handleSortTable,
    refetchSearch,
  } = useHandleTable(
    images?.meta.totalItems ?? 0,
    images?.items ?? [],
    refetchImages,
    null,
    imagesSearchBaseEndpoint,
    loadMore,
    null,
    doSearch as Search,
  );

  const handleSearch = () => {
    searchScreen<Images>((item, formatedSearch) => {
      return (
        formatSearch(item.name?.toString()).includes(formatedSearch) ||
        formatSearch(
          imageTypesText[item.type.name as ImageTypes] ?? item.type.name,
        ).includes(formatedSearch)
      );
    });
  };

  const typeColWidth = useMemo(
    () =>
      getTableColumnWidthByBiggestElement<Images>(
        images?.items ?? [],
        'type',
        10,
      ),
    [images],
  );
  const imagesShown = itemsShown as Images[];

  const currentParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  const handleFilterByTypeImage = useCallback(
    (filterName: string) => {
      if (filterName === 'todos') {
        currentParams.delete('imageType');
        navigate({ search: currentParams.toString() }, { replace: true });
        return;
      }
      currentParams.set('imageType', filterName.toString());
      navigate({ search: currentParams.toString() }, { replace: true });
    },
    [currentParams, navigate]
  );

  useEffect(() => {
    refetchSearch();
  }, [refetchSearch]);

  useEffect(() => {
    refetchSearch();
  }, [refetchSearch, typeImageActive]);

  return {
    inputRef,
    isSearchInputDirty,
    imagesShown,
    sortField,
    isFetchingImages,
    imagesShownLength,
    imagesLength,
    tableColumnNameWidth,
    typeColWidth,
    isLoadMoreFetching,
    typeImageActive,
    typeImage,
    endPageRef,
    isFetchingTypeImage,
    handleFilterByTypeImage,
    handleSortTable,
    handleCancelSearch,
    handleSearch,
    handleSearchDatabase,
    handleEnterClickSearchInput,
  };
};
