import React, { useMemo } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Alert from '../../components/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useRecoverPassword } from './useRecoverPassword';

export default function RecoverPassword() {
  const {
    dirtyFields,
    alertContent,
    showPassword,
    errors,
    showConfirmPassword,
    errorMessage,
    handleIconEyeClick,
    clearError,
    handleIconEyeClickConfirmPassword,
    handleRedirectButtonOkClick,
    handleSubmit,
    onSubmit,
    register,
  } = useRecoverPassword({
    successIcon: useMemo(
      () => <CheckCircleIcon className="text-success" fontSize="large" />,
      [],
    ),
    errorIcon: useMemo(
      () => <CancelIcon className="text-errorText" fontSize="large" />,
      [],
    ),
  });

  return (
    <div className="h-screen w-screen">
      <div className="flex h-full w-full items-center justify-center p-8 pt-16">
        <div className="mx-auto w-1/3 max-xl:w-1/2 max-md:w-full">
          <h1 className="text-4xl">Digite sua nova senha</h1>
          {/* <h2>A senha deve conter no minimo 8 catacteres</h2> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4 flex w-full items-center rounded-lg border border-solid border-zinc-300 pr-4 focus-within:outline focus-within:outline-2 focus-within:outline-sky-400">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Senha"
                className="mr-2 w-full rounded-lg px-4 py-4 outline-none"
                {...register('password')}
              />
              {dirtyFields.password && (
                <div onClick={handleIconEyeClick}>
                  {showPassword && (
                    <VisibilityOffIcon className="cursor-pointer" />
                  )}
                  {!showPassword && (
                    <VisibilityIcon className="cursor-pointer" />
                  )}
                </div>
              )}
            </div>
            {errors.password && (
              <span className="text-xs text-red-600">
                {errors.password?.message}
              </span>
            )}

            <div className="mt-4 flex w-full items-center rounded-lg border border-solid border-zinc-300 pr-4 focus-within:outline focus-within:outline-2 focus-within:outline-sky-400">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirmação de senha"
                className="mr-2 w-full rounded-lg px-4 py-4 outline-none"
                {...register('confirm_password')}
              />
              {dirtyFields.confirm_password && (
                <div onClick={handleIconEyeClickConfirmPassword}>
                  {showConfirmPassword && (
                    <VisibilityOffIcon className="cursor-pointer" />
                  )}
                  {!showConfirmPassword && (
                    <VisibilityIcon className="cursor-pointer" />
                  )}
                </div>
              )}
            </div>
            {errors.confirm_password && (
              <span className="text-xs text-red-600">
                {errors.confirm_password?.message}
              </span>
            )}
            <button
              type="submit"
              className="mt-16 w-full rounded-lg bg-gradient-to-b from-blue-400 to-blue-600 h-[4rem] text-center text-zinc-50 hover:to-blue-500"
            >
              Redefinir senha
            </button>
          </form>
        </div>
      </div>
      <Alert
        icon={alertContent.icon}
        content={alertContent.message}
        isOpen={!!alertContent.message}
        onClose={handleRedirectButtonOkClick}
      />
      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={handleRedirectButtonOkClick}
      />
    </div>
  );
}
