import { ComponentProps } from 'react';

type CardActionProps = ComponentProps<'span'> & {
  text: string;
};

export default function CardAction({ text, ...rest }: CardActionProps) {
  return (
    <span className="cursor-pointer text-sm text-primary" {...rest}>
      {text}
    </span>
  );
}
