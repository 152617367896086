import ConfirmModal from '../../../components/ConfirmModal';
import Footer from '../../../components/Footer';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import ListingHeader from '../../../components/ListingHeader';
import Modal from '../../../components/Modal';
import SearchInput from '../../../components/SearchInput';
import Table from '../../../components/Table';
import UserRoleContent from '../../../components/UserRoleContent';
import { useUsers } from './useUsers';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Alert from '../../../components/Alert';
import Main from '../../../components/Main';
import Filter from '../../../components/Filter';
import LoadMore from '../../../components/LoadMore';

export default function Users() {
  const {
    inputRef,
    isFetchingUsers,
    isSearchInputDirty,
    sortField,
    usersShown,
    usersType,
    usersShownLength,
    usersLength,
    isMenageModalOpen,
    isConfirmDeleteModalOpen,
    isShowEditButton,
    isUserRoleModalOpen,
    userIdUpdate,
    dirtyFields,
    email,
    errors,
    confirmPassword,
    user,
    typeUser,
    password,
    currentUserType,
    errorMessage,
    userTypeColWidth,
    currentUserTypes,
    isLoadMoreFetching,
    endPageRef,
    clearError,
    handleClearField,
    handleConfirmDeleteUser,
    handleFilterByTypeUser,
    handleMainTableRowClick,
    handleSubmit,
    register,
    handleButtonOkClick,
    handleCloseUserRoleModal,
    handleCloseConfirmDeleteModal,
    handleSelectUserRole,
    handleOpenUsersRole,
    handleEditButtonClick,
    handleDeleteButtonClick,
    handleAddUsersClick,
    handleCloseMenageModal,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
  } = useUsers();


  return (
    <>
      <ListingHeader
        add
        onAddIconClick={handleAddUsersClick}
        pdfTop="3rem"
        pdf={false}
      >
        <div className="mt-4">
          <h1 className="mb-4 text-2xl">Usuários</h1>
          <Filter
            title="Tipo usuário"
            filters={[
              {
                filter: 'todos',
                isCurrent: !typeUser || typeUser === 'todos',
                name: 'Todos',
              },
              ...(usersType?.items ?? []).map((userType) => ({
                filter: userType.type,
                isCurrent: typeUser === userType.type,
                name: userType.type,
              })),
            ]}
            onFilter={handleFilterByTypeUser}
          />
          <SearchInput
            ref={inputRef}
            onSearch={handleSearch}
            // onButtonClick={handleCancelSearch}
            showCancelOptions={isSearchInputDirty}
            onCancelSearch={handleCancelSearch}
          />
        </div>
        <Table.Head>
          <div className="flex">
            <Table.Cell
              items={usersShown}
              type="user"
              onSort={handleSortTable}
              isSortActive={sortField === 'user'}
              className="w-40"
            >
              Usuário
            </Table.Cell>
            <Table.Cell items={usersShown}>Ativo</Table.Cell>
          </div>
          <Table.Cell
            items={usersShown}
            type="role.type"
            onSort={handleSortTable}
            isSortActive={sortField === 'role.type'}
            style={{ width: `${userTypeColWidth}ch` }}
          >
            Tipo
          </Table.Cell>
        </Table.Head>
      </ListingHeader>
      <Main>
        {!isFetchingUsers && (
          <Table.Root itemType="usuários" itemsLength={usersShownLength}>
            {usersShown.map((user) => (
              <Table.Row
                key={user.id}
                onClick={() =>
                  handleMainTableRowClick(
                    user.id,
                    user.user,
                    user.email,
                    user.role.id,
                    user.role.type,
                    user.active,
                  )
                }
              >
                <div className="flex">
                  <Table.Cell items={usersShown} type="name" className="w-40">
                    {user.user}
                  </Table.Cell>
                  <Table.Cell
                    items={usersShown}
                    className="w-9 items-center justify-center"
                  >
                    <div className="w-full text-center">
                      <input
                        type="checkbox"
                        className="size-4"
                        checked={user.active}
                      />
                    </div>
                  </Table.Cell>
                </div>
                <Table.Cell
                  items={usersShown}
                  style={{ width: `${userTypeColWidth}ch` }}
                >
                  {user.role?.type}
                </Table.Cell>
              </Table.Row>
            ))}
            <LoadMore
              isModal={false}
              isContentFetching={false}
              isLoadMoreFetching={isLoadMoreFetching}
              ref={endPageRef}
            />
          </Table.Root>
        )}
      </Main>

      <Footer
        current={usersShownLength}
        total={usersLength}
        itemType="Usuários"
        // tableFooter={
        //   usersLength > 100 && (
        //     <TableFooter
        //       currentPage={currentPage}
        //       itemsLength={usersLength}
        //       itemsShownLength={usersShownLength}
        //       pagination={pagination}
        //       onChangePage={handleChangePage}
        //       onChangePagination={handleChangePagination}
        //     />
        //   )
        // }
      />

      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseMenageModal}
        cancelIcon={false}
      >
        <HeaderModalManageItem
          showDeleteButton={!!userIdUpdate}
          deleteButtonContent="Apagar usuário"
          isEditable={!isShowEditButton}
          onDeleteButtonClick={handleDeleteButtonClick}
          title={userIdUpdate ? 'Usuário' : 'Novo usuário'}
          mainButtonContent={userIdUpdate && isShowEditButton ? 'Editar' : 'Ok'}
          onCancelClick={handleCloseMenageModal}
          onOkClick={
            !isShowEditButton ? handleSubmit(handleButtonOkClick) : undefined
          }
          onEditClick={isShowEditButton ? handleEditButtonClick : undefined}
        />
        <div className="my-8 w-full">
          <Input
            maxLength={44}
            type="text"
            placeholder="Usuário"
            disabled={isShowEditButton}
            isDirty={!isShowEditButton && dirtyFields.user}
            onCancelIconClick={() => handleClearField('user')}
            {...register('user')}
            helperText={errors.user?.message}
            value={user}
          />
          <Input
            maxLength={44}
            type="email"
            placeholder="Email"
            disabled={isShowEditButton}
            isDirty={!isShowEditButton && dirtyFields.email}
            onCancelIconClick={() => handleClearField('email')}
            {...register('email')}
            helperText={errors.email?.message}
            value={email}
          />
          {!userIdUpdate && (
            <>
              <Input
                maxLength={44}
                type="password"
                placeholder="Senha"
                disabled={isShowEditButton}
                isDirty={!isShowEditButton && dirtyFields.password}
                className="mt-4"
                onCancelIconClick={() => handleClearField('password')}
                {...register('password')}
                showEye
                helperText={errors.password?.message}
                value={password}
              />
              <Input
                maxLength={44}
                type="password"
                placeholder="Confirmar senha"
                disabled={isShowEditButton}
                isDirty={!isShowEditButton && dirtyFields.confirm_password}
                onCancelIconClick={() => handleClearField('confirm_password')}
                className={`mt-4`}
                {...register('confirm_password')}
                showEye
                helperText={errors.confirm_password?.message}
                value={confirmPassword}
              />
            </>
          )}
          <div className="mt-4 flex cursor-pointer items-center gap-3 border-b-[1px]">
            {/* <span className="w-fit">Tipo</span> */}
            <Input
              readOnly
              placeholder="Tipo"
              value={currentUserType}
              onClick={isShowEditButton ? undefined : handleOpenUsersRole}
              className={`cursor-pointer border-none p-0 text-primary`}
              inputStyle="cursor-pointer"
              {...register('userType')}
            />
            <ChevronRightIcon
              className="text-secondaryText"
              // onClick={isShowEditButton ? undefined : handleOpenLines}
            />
          </div>
          {userIdUpdate && (
            <>
              <Input
                maxLength={44}
                type="password"
                placeholder="Senha"
                disabled={isShowEditButton}
                isDirty={!isShowEditButton && dirtyFields.password}
                onCancelIconClick={() => handleClearField('password')}
                className={`mt-4`}
                {...register('password')}
                showEye
                helperText={errors.password?.message}
                value={password}
              />
              <Input
                maxLength={44}
                type="password"
                placeholder="Confirmar senha"
                disabled={isShowEditButton}
                isDirty={!isShowEditButton && dirtyFields.confirm_password}
                onCancelIconClick={() => handleClearField('confirm_password')}
                className={`mt-4`}
                {...register('confirm_password')}
                showEye
                helperText={errors.confirm_password?.message}
                value={confirmPassword}
              />
            </>
          )}
          <span className="text-xs text-red-600">
            {errors.userType?.message}
          </span>
          {userIdUpdate && (
            <div  className="mt-4 flex items-center gap-2 ">
              <input
                type="checkbox"
                tabIndex={0}
                id="is-active"
                className="size-4 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-400 focus-visible:ring-offset-2"
                disabled={isShowEditButton}
                {...register('isActive')}
                // value={isActive}
              />
              <label htmlFor="is-active">Ativo</label>
            </div>
          )}
        </div>
      </Modal>

      <Modal isOpen={isUserRoleModalOpen} onClose={handleCloseUserRoleModal}>
        <div className="h-[85vh]">
          <UserRoleContent
            isModal
            onTableRowClick={handleSelectUserRole}
            itemsInList={currentUserTypes}
          />
        </div>
      </Modal>

      <ConfirmModal
        contentMessage="Deseja excluir esse usuário?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDeleteUser}
        onNotConfirm={handleCloseConfirmDeleteModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
