import { Param } from '../types/params';

const generatePaginatedUrl = (
  baseUrl: string,
  page?: number | null,
  limit?: number | null,
  direction?: 'ASC' | 'DESC' | null,
) => {
  const params = new URLSearchParams();

  if (page) {
    params.append('page', page.toString());
  }

  if (limit) {
    params.append('limit', limit.toString());
  }

  if (direction) {
    params.append('direction', direction.toString());
  }

  const url = params.toString() ? `${baseUrl}?${params.toString()}` : baseUrl;
  return url;
};

export const addSearchParamsInUrl = (baseUrl: string, ...params: Param[]) => {
  const urlParams = new URLSearchParams();

  params.forEach((param) => {
    if (param?.name && (param?.value || param.value === '')) {
      urlParams.append(param.name, param.value.toString());
    }
  });

  const url = urlParams.toString()
    ? `${baseUrl}?${urlParams.toString()}`
    : baseUrl;
  return url;
};

export default generatePaginatedUrl;
