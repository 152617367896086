import { create } from 'zustand';
import {
  CoordinatesModified,
  CoordinateToDelete,
  Domain,
  FieldDomainItem,
} from '../../types/domainImageCoordinate';

type SignatureDomainStoreProps = {
  state: {
    currentDomain: Domain | null;
    currentFieldHoverId: number | null;
    currentFieldClicked: FieldDomainItem | null;
    coordinatesModified: CoordinatesModified[];
    coordinatesToDelete: CoordinateToDelete[];
    canEdit: boolean;
    isModified: { current: boolean };
  };
  actions: {
    setCurrentDomain: (currentDomain: Domain | null) => void;

    setCurrentFieldClicked: (
      fieldDomainItemClicked: FieldDomainItem | null,
    ) => void;

    setCurrentFieldHoverId: (fieldId: number | null) => void;

    setCoordinateModified: (coordinate: CoordinatesModified[]) => void;

    addCoordinateDelete: (coordinate: CoordinateToDelete) => void;

    setCoordinateDelete: (coordinates: CoordinateToDelete[]) => void;

    handleEdit: () => void;

    handleUnedit: () => void;

    modify: () => void;

    clear: () => void;
  };
};

export const useSignatureDomainStore = create<SignatureDomainStoreProps>(
  (set) => {
    const initialState = {
      currentDomain: null,
      currentFieldHoverId: null,
      currentFieldClicked: null,
      coordinatesModified: [],
      coordinatesToDelete: [],
      canEdit: false,
      isModified: { current: false },
    };

    return {
      state: initialState,
      actions: {
        setCurrentDomain: (currentDomain) => {
          set((state) => ({
            ...state,
            state: { ...state.state, currentDomain },
          }));
        },

        setCurrentFieldClicked: (fieldDomainItem) => {
          set((state) => ({
            ...state,
            state: { ...state.state, currentFieldClicked: fieldDomainItem },
          }));
        },

        setCurrentFieldHoverId: (fieldId) => {
          set((state) => ({
            ...state,
            state: { ...state.state, currentFieldHoverId: fieldId },
          }));
        },

        setCoordinateModified: (coordinatesModified) => {
          set((state) => ({
            ...state,
            state: {
              ...state.state,
              coordinatesModified,
            },
          }));
        },

        addCoordinateDelete: (coordinateDelete) => {
          set((state) => ({
            ...state,
            state: {
              ...state.state,
              coordinatesToDelete: [
                ...state.state.coordinatesToDelete,
                coordinateDelete,
              ],
            },
          }));
        },

        setCoordinateDelete: (coordinatesDelete) => {
          set((state) => ({
            ...state,
            state: {
              ...state.state,
              coordinatesToDelete: coordinatesDelete,
            },
          }));
        },

        handleEdit: () => {
          set((state) => ({
            ...state,
            state: {
              ...state.state,
              canEdit: true,
            },
          }));
        },

        handleUnedit: () => {
          set((state) => ({
            ...state,
            state: {
              ...state.state,
              canEdit: false,
            },
          }));
        },

        modify: () => {
          set((state) => ({
            ...state,
            state: {
              ...state.state,
              isModified: { current: true },
            },
          }));
        },

        clear: () => {
          set(() => ({
            state: initialState,
          }));
        },
      },
    };
  },
);
