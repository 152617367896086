import { forwardRef, ComponentProps, ReactNode, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import './styles.css';
import { useInput } from './useInput';
import { styled, TextField as MuiTextField } from '@mui/material';
import { formatOnlyNumber } from '../../utils/format';

type InputProps = Omit<ComponentProps<'input'>, 'maxLength'> & {
  showEye?: boolean;
  isDirty?: boolean;
  helperText?: string;
  label?: ReactNode;
  classNameContainer?: string;
  forceLabelShrink?: boolean | 'no-force';
  inputStyle?: string;
  multiline?: boolean;
  onCancelIconClick?: () => void;
  maxLength?: number | 'none';
};

const TextField = styled(MuiTextField)({
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none', // Bottom border on focus
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: 'none', // Bottom border on hover
  },
  '& .MuiInputBase-root': {
    outline: 'none', // Remove the outer outline completely
  },
});

export default forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    maxLength = 200,
    showEye = false,
    type,
    isDirty,
    helperText,
    multiline = false,
    label,
    classNameContainer,
    forceLabelShrink = 'no-force',
    inputStyle,
    className,
    placeholder,
    min = 0,
    onCancelIconClick,
    id,
    value,
    ...rest
  },
  ref,
) {
  const {
    showPassword,
    isFocused,
    handleIconEyeClick,
    defineInputType,
    setIsFocused,
  } = useInput();

  const shrink = () => {
    if (forceLabelShrink === 'no-force') return !!value || isDirty || isFocused;

    return !!forceLabelShrink;
  };

  return (
    <div className={twMerge('w-full', classNameContainer)}>
      <div className={twMerge('flex items-center gap-4 border-b', className)}>
        <TextField
          label={label ?? placeholder}
          variant="standard"
          multiline={multiline}
          fullWidth
          type={defineInputType(type, showEye)}
          value={value}
          onFocus={() => setIsFocused(true)} // Marca o campo como focado
          onBlur={() => setIsFocused(false)} // Marca o campo como desfocado
          InputLabelProps={{ shrink: shrink() }}
          inputRef={ref}
          inputProps={{
            onInput: (event) => {
              if (type === 'number') {
                event.currentTarget.value = formatOnlyNumber(
                  event.currentTarget.value,
                );
              }
            },
            ...(maxLength !== 'none' && { maxLength }),
            onWheel: (e) => {
              e.currentTarget.blur();
            },
            min,
            className: twMerge(inputStyle, 'focus:outline-none'),
            ...rest,
          }}
          className={twMerge(inputStyle, 'focus:outline-none')}
        />
        {showEye && isDirty && (
          <div onClick={handleIconEyeClick}>
            {showPassword && <VisibilityOffIcon className="cursor-pointer" />}
            {!showPassword && <VisibilityIcon className="cursor-pointer" />}
          </div>
        )}
        {isDirty && (
          <CancelIcon
            fontSize="small"
            className="mr-2 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onCancelIconClick?.();
            }}
          />
        )}
      </div>
      <span className="text-xs text-red-600">{helperText}</span>
    </div>
  );
});
