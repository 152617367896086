import { useState, useRef, useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useQuery } from 'react-query';
import {
  representativeUfApiCache,
  representativeApiCache,
} from '../../../constants/requestCacheName';
import {
  getUfsRepresentative,
  getRepresentatives,
} from '../../services/representativeService';
import {
  FindAllRepresentativeUfs,
  FindAllRepresentative,
  Representative,
} from '../../types/representative';
import { getCurrentDomain } from '../../../utils/domain';

type FormattedRepresentatives = Omit<Representative, 'contacts'> & {
  contacts: {
    description: string;
    details: {
      email: string;
      id: number;
      phoneNumber: string;
    }[];
  }[];
};

export const useRepresentative = () => {
  const { data: states } = useQuery<FindAllRepresentativeUfs>(
    representativeUfApiCache,
    async () => (await getUfsRepresentative()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { refetch: refetchRepresentatives, isLoading: loading } =
    useQuery<FindAllRepresentative>(
      representativeApiCache,
      async () =>
        (
          await getRepresentatives({
            page: currentPage.current,
            uf: currentUf.current,
            domainId: getCurrentDomain().id,
          })
        ).data,
      {
        retry: false,
        refetchOnWindowFocus: false,

        onSuccess: (data) => {
          if (data.meta.currentPage >= data.meta.totalPages) {
            setCanLoadMore(false);
          }

          if (data.meta.currentPage < data.meta.totalPages) {
            setCanLoadMore(true);
          }
          const formattedData: FormattedRepresentatives[] = data.items.map(
            (items) => {
              const groupedContacts = items.contacts?.reduce(
                (acc: Record<string, any>, contact) => {
                  const { description, email, id, phoneNumber } = contact;

                  if (!acc[description]) {
                    acc[description] = {
                      description,
                      details: [{ email, id, phoneNumber }],
                    };
                  } else {
                    acc[description].details.push({ email, id, phoneNumber });
                  }

                  return acc;
                },
                {},
              );

              // Converter o objeto agrupado para um array
              const groupedArray = Object.values(groupedContacts ?? {});
              return {
                ...items,
                contacts: groupedArray,
              };
            },
          );

          if (shouldResetTechnicalAssistances.current) {
            setRepresentatives(formattedData);
            shouldResetTechnicalAssistances.current = false;
            return;
          }

          shouldResetTechnicalAssistances.current = false;
          setRepresentatives((prev) => [...prev, ...formattedData]);
        },
      },
    );

  const [representatives, setRepresentatives] = useState<
    FormattedRepresentatives[]
  >([]);

  const [canLoadMore, setCanLoadMore] = useState(true);

  const currentPage = useRef(1);
  const currentUf = useRef<string | null>(null);
  const shouldResetTechnicalAssistances = useRef(false);

  const [selectedState, setSelectedState] = useState<string>('');

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const loadMoreData = useCallback(() => {
    if (!canLoadMore) return;

    currentPage.current++;
    refetchRepresentatives();
  }, [canLoadMore, refetchRepresentatives]);

  useEffect(() => {
    if (inView && !loading) {
      loadMoreData();
    }
  }, [inView, loadMoreData, loading]);

  const handleSearch = () => {
    currentPage.current = 1;
    shouldResetTechnicalAssistances.current = true;
    refetchRepresentatives();
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleSearch();
  };

  const handleSelectUf = (state: string) => {
    currentPage.current = 1;
    shouldResetTechnicalAssistances.current = true;
    currentUf.current = state;
    refetchRepresentatives();
    setSelectedState(state);
  };

  return {
    representatives,
    states,
    selectedState,
    loading,
    ref,
    handleSubmit,
    handleSelectUf,
  };
};
