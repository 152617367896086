import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Modal from '../Modal';
import { ReactNode } from 'react';

type ConfirmModalProps = {
  isModalOpen: boolean;
  contentMessage: ReactNode;
  onCloseModal: () => void;
  onConfirm: () => void;
  onNotConfirm: () => void;
};

export default function ConfirmModal({
  isModalOpen,
  contentMessage,
  onCloseModal,
  onConfirm,
  onNotConfirm,
}: ConfirmModalProps) {
  return (
    <Modal isOpen={isModalOpen} onClose={onCloseModal} cancelIcon={false}>
      <div className="my-16">
        <div className="flex gap-4 justify-center items-center">
          <HelpOutlineIcon fontSize="large" className="text-primary" />
          <p>{contentMessage}</p>
        </div>
        <div className="flex gap-8 justify-center mt-8">
          <button
            onClick={onConfirm}
            className="border-primary px-12 py-1 hover:bg-primary hover:text-zinc-50 rounded border-[1px]"
          >
            Sim
          </button>
          <button
            onClick={onNotConfirm}
            className="border-primary px-12 py-1 hover:bg-primary hover:text-zinc-50 rounded border-[1px]"
          >
            Não
          </button>
        </div>
      </div>
    </Modal>
  );
}
