import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';

export const explodedViewContainerStyle: SxProps<Theme> = {
textAlign: 'center',
display: 'flex',
flexDirection: 'column',
alignItems: 'center',

'@media (max-width: 1250px)': {
    order: 1,
},
};

export const selectedPartStyle: SxProps<Theme> = {
textAlign: 'left',
marginTop: '2rem',
width: '100%',

'@media (max-width: 760px)': {
    width: '96vw',
},
};

export const selectedPartCardTitleStyle: SxProps<Theme> = {
'@media (max-width: 500px)': {
    fontSize: '1rem',
    
},
};
