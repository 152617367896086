import Sort from '../enums/sort';

const verifyObjectsArraySort = (
  array: Record<string, any>[],
  property: string,
) => {
  let crescente = true;
  let decrescente = true;

  const fields = property.split('.');

  if (fields.length === 2) {
    const [first, second] = fields;

    array.forEach((item, i) => {
      if (array[i + 1]) {
        if (item[first][second] > array[i + 1][first][second]) {
          crescente = false;
        }
        if (item[first][second] < array[i + 1][first][second]) {
          decrescente = false;
        }
      }
    });
  }

  if (fields.length < 2) {
    array.forEach((item, i) => {
      if (array[i + 1]) {
        if (item[property] > array[i + 1][property]) {
          crescente = false;
        }
        if (item[property] < array[i + 1][property]) {
          decrescente = false;
        }
      }
    });
  }

  if (crescente) {
    return Sort.ascending;
  } else if (decrescente) {
    return Sort.descending;
  } else {
    return Sort.disordered;
  }
};

export const applySortAfterFilter = <T extends Record<string, any>>(
  filteredItems: T[],
  currentItemsSort: Sort,
  sortField: string,
) => {
  const fields = sortField.split('.');
  if (currentItemsSort === Sort.ascending) {
    filteredItems.sort((a, b) => {
      if (fields.length === 2) {
        const [first, second] = fields;

        if (typeof a[first][second] === 'number') {
          return a[first][second] - b[first][second];
        }

        return a[first][second]?.trim().localeCompare(b[first][second]);
      }

      if (typeof a[sortField] === 'number') {
        return a[sortField] - b[sortField];
      }

      return a[sortField]?.trim().localeCompare(b[sortField]);
    });
  }

  if (currentItemsSort === Sort.descending) {
    if (fields.length === 2) {
      const [first, second] = fields;
      filteredItems.sort((a, b) => {
        if (typeof a[first][second] === 'number') {
          return b[first][second] - a[first][second];
        }

        return b[first][second]?.trim().localeCompare(a[first][second]);
      });
    }

    filteredItems.sort((a, b) => {
      if (typeof a[sortField] === 'number') {
        return b[sortField] - a[sortField];
      }

      return b[sortField]?.trim().localeCompare(a[sortField]);
    });
  }
};

type I = {
  order: number;
};

export const orderItems = <Item extends I>(items: Item[]) => {
  items.sort((a, b) => {
    if (a.order === null && b.order === null) {
      return 0;
    }
    if (a.order === null) {
      return 1;
    }
    if (b.order === null) {
      return -1;
    }
    return a.order - b.order;
  });
};

export default verifyObjectsArraySort;
