import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import light from './themes/light';
import { QueryClientProvider } from 'react-query';
import queryClient from './libs/queryClient';
import Router from './router';
import '../src/buffalos/assets/stylesBuffalo/stylesBuffalo.css';
import ScrollToTop from './buffalos/components/ScrollToTop/scrollToTop';
import WebsocketsSynchronization from './components/WebsocketsSynchronization';
import { useAlertStore } from './store/alert';
import Alert from './components/Alert';
import { useEffect } from 'react';
import { setupAxiosInterceptor } from './services/api';
import { clearAllCaches } from './utils/cache';

export default function App() {
  const {
    state: { message },
    actions: { handleClose, handleOpen },
  } = useAlertStore();

  useEffect(() => {
    setupAxiosInterceptor(handleOpen);
  }, [handleOpen]);

  useEffect(() => {
    const clearCaches = async () => {
      const isCacheClean = sessionStorage.getItem('cacheClean');
      if (isCacheClean) {
        return;
      }

      await clearAllCaches();
      sessionStorage.setItem('cacheClean', 'true');
    };
    clearCaches();
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={light}>
          <WebsocketsSynchronization />
          <Router />
          <Alert isOpen={!!message} onClose={handleClose} content={message} />
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}
