import { Skeleton } from '@mui/material';

export default function CardSkeleton() {
  return (
    <>
      <Skeleton variant="rounded" sx={{ height: '15rem', borderRadius: '0.5rem' }} />
      <Skeleton variant="rectangular" sx={{ mt: '2rem' }} />
      <Skeleton variant="rectangular" sx={{ mt: '1rem' }} />
    </>
  );
}
