import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles.css';

import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from 'swiper/modules';
import { Lines } from '../../../types/lines';
import { useMemo, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Card from '../CardBuffalo';
import { getImageFromApi } from '../../../utils/getImageUrl';
import { ImageTypes } from '../../../enums/imageTypes';

export type SwiperProps = {
  linesShown: Lines | undefined;
};

export default function SwiperCarousel({ linesShown }: SwiperProps) {
  const linesShownWithCarouselImage = useMemo(
    () => linesShown?.items.filter((line) => !!line.carouselImage),
    [linesShown?.items],
  );

  const progressBar = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<SwiperClass | null>(null);
  const location = useLocation(); // Para detectar mudanças na rota

  const onAutoplayTimeLeft = (
    swiper: SwiperClass,
    time: number,
    progress: number,
  ) => {
    if (progressBar.current) {
      progressBar.current.style.width = `${(1 - progress) * 100}%`;
    }
  };

  const handleSlideChange = (swiper: SwiperClass) => {
    if (swiperRef.current?.autoplay) {
      swiperRef.current.autoplay.start(); // Reinicia o autoplay
    }
  };

  // Usando useEffect para forçar atualização do Swiper e resetar o autoplay
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.update(); // Atualiza o Swiper quando o componente for montado ou a rota mudar
      swiperRef.current.slideTo(0, 0); // Força o swiper a começar do primeiro slide
    }
  }, [location, linesShownWithCarouselImage]); // Dependendo da mudança na rota ou das linhas

  return (
    <div>
      {linesShownWithCarouselImage && (
        <Swiper
          initialSlide={0}
          navigation={true}
          pagination={{ clickable: true }}
          mousewheel={true}
          keyboard={true}
          loop={linesShownWithCarouselImage.length > 1} // Ativa o loop se houver mais de 1 slide
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation, Pagination, Keyboard]}
          onAutoplayTimeLeft={onAutoplayTimeLeft}
          onSlideChange={handleSlideChange}
          onSwiper={(swiper: SwiperClass) => {
            swiperRef.current = swiper;
          }}
          observer={true}
          observeParents={true} // Observa mudanças nos elementos pais do Swiper
          className="mySwiper min-h-[200px] md:min-h-[300px] xl:min-h-[500px]"
        >
          {linesShownWithCarouselImage?.map((line) => (
            <SwiperSlide key={line.id}>
              <Link
                to={{
                  pathname: `/produtos`,
                  search: `?line=${line.id}`,
                }}
              >
                <div className="bg-white">
                  <div>
                    <Card.Img
                      src={getImageFromApi(
                        line.carouselImage,
                        ImageTypes.linesCarousel,
                      )}
                      alt={`Imagem do ${line.name}`}
                      fallbackSrc={`/images/unavailable.png`}
                      fallbackAlt="Imagem de produto indisponível"
                      style={{
                        maxWidth: 'none',
                        maxHeight: 'none',
                      }}
                      className="!h-auto"
                      containerStyle={{ height: '100%' }}
                      width={1800}
                      height={500}
                    />
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      <div className="autoplay-progress">
        <div ref={progressBar} className="progress-bar"></div>
      </div>
    </div>
  );
}
