import { AxiosResponse } from 'axios';
import { SizingsPaginated } from '../../types/sizing';
import { useSizingContent } from './useSizingContent';
import ListingHeader from '../ListingHeader';
import SearchInput from '../SearchInput';
import Table from '../Table';
import Main from '../Main';
import Loading from '../Loading';
import Footer from '../Footer';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { twMerge } from 'tailwind-merge';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

export type SizingService = () => Promise<AxiosResponse<SizingsPaginated, any>>;

type SizingContentProps = {
  isModal?: boolean;
  sizingsInUse?: number[];
  onAddSizingClick?: () => void;
  onTableRowClick: (
    sizingId: number,
    sizingName: string,
    sizingUrl: string,
  ) => void;
};

export default function SizingContent({
  isModal,
  onAddSizingClick,
  onTableRowClick,
}: SizingContentProps) {
  const {
    sortField,
    inputRef,
    isSearchInputDirty,
    sizingsShown,
    sizingsLength,
    sizingsShownLength,
    isSearchImageFetching,
    isSearch,
    isShowEditButton,
    checkSizingListDomains,
    canEditOrder,
    canEdit,
    domains,
    domainWidth,
    nameColWidth,
    domainFilter,
    handleSearchInAllSizings,
    verifyAllDomainsChecked,
    handleToggleCheckDomains,
    verifyDomainsPartialyChecked,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
    handleCheckDomain,
    handleDragEnd,
    handleEnterClickSearchInput,
    handleChangeEditOrderButtonClick,
    handleButtonOkClick,
    handleFilter,
    handleButtonCancelEditClick,
  } = useSizingContent();

  return (
    <>
      {!isModal && (
        <ListingHeader
          add
          onAddIconClick={onAddSizingClick}
          pdfTop="3rem"
          pdf={false}
        >
          <div className="mt-4">
            <div className="mb-4 flex justify-between">
              <h1 className="text-2xl">Dimensionamentos</h1>
              <div className="flex items-center gap-2">
                {!canEdit && (
                  <button
                    onClick={handleChangeEditOrderButtonClick}
                    className="text-sm text-primary disabled:opacity-60"
                    disabled={
                      canEdit || !domainFilter || domainFilter === 'Todos'
                    }
                  >
                    {canEditOrder ? 'Salvar ordem' : 'Editar ordem'}
                  </button>
                )}

                {!canEdit && !canEditOrder && <span>|</span>}

                {!canEditOrder && (
                  <button
                    onClick={handleButtonOkClick}
                    className="text-sm text-primary"
                    disabled={canEditOrder}
                  >
                    {canEdit ? 'Salvar domínio' : 'Editar domínio'}
                  </button>
                )}

                {(canEdit || canEditOrder) && (
                  <>
                    <span>|</span>
                    <button
                      onClick={() => handleButtonCancelEditClick()}
                      className="text-sm text-errorText"
                    >
                      Cancelar {canEdit ? 'Seleção' : 'Ordenação'}
                    </button>
                  </>
                )}
              </div>
            </div>

            <h2>Domínios</h2>
            <div
              className="mb-4 flex flex-wrap gap-2 rounded-lg bg-[#e0e0e0] px-2 py-2"
              style={{
                pointerEvents: canEdit || canEditOrder ? 'none' : 'auto',
                opacity: canEdit || canEditOrder ? 0.5 : 1,
              }}
            >
              <span
                onClick={() => handleFilter('Todos')}
                className={twMerge(
                  'flex w-fit cursor-pointer items-center justify-center rounded-lg px-4 max-md:py-1',
                  !domainFilter || domainFilter === 'Todos'
                    ? 'border border-primary bg-backgroundPaper text-primary'
                    : 'text-primaryText',
                )}
              >
                Todos
              </span>
              {domains?.items.map((domain) => (
                <span
                  key={domain.id}
                  onClick={() => handleFilter(domain.name)}
                  className={twMerge(
                    'flex w-fit cursor-pointer items-center justify-center rounded-lg px-4 max-md:py-1',
                    domainFilter === domain.name
                      ? 'border border-primary bg-backgroundPaper text-primary'
                      : 'text-primaryText',
                  )}
                >
                  {domain.name}
                </span>
              ))}
            </div>
            <SearchInput
              ref={inputRef}
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
              onSearch={handleSearch}
              buttonContent="Buscar"
              onKeyDown={handleEnterClickSearchInput}
              onButtonClick={handleSearchInAllSizings}
              disabled={canEditOrder}
            />
          </div>
          <Table.Head rowStyle="px-0 items-center">
            <div className="ml-4 flex">
              {/* <Table.Cell
                items={sizingsShown}
                type="id"
                onSort={handleSortTable}
                isSortActive={sortField === 'id'}
                className="w-[6.5rem]"
              >
                ID
              </Table.Cell> */}
              <Table.Cell
                items={sizingsShown}
                type="name"
                onSort={handleSortTable}
                isSortActive={sortField === 'name'}
                style={{ width: `${nameColWidth}ch` }}
              >
                Dimensionamento
              </Table.Cell>
            </div>
            <div
              className={twMerge(
                'flex gap-4',
                canEditOrder ? `mr-[7.4rem]` : `mr-[4.1rem]`,
              )}
            >
              {domains?.items.map((domain) => (
                <Table.Cell
                  items={sizingsShown}
                  key={`label-${domain.id}`}
                  style={{ width: `${domainWidth}ch` }}
                  className="items-center"
                >
                  <div className="flex w-full items-center gap-2">
                    <div className="relative flex items-center">
                      {verifyDomainsPartialyChecked(domain.id) && (
                        <div className="pointer-events-none absolute -left-[2px] flex items-center">
                          <IndeterminateCheckBoxIcon
                            className="text-[#0075ff]"
                            fontSize="small"
                          />
                        </div>
                      )}
                      <input
                        onChange={(event) =>
                          handleToggleCheckDomains(
                            domain.id,
                            event.target.checked,
                          )
                        }
                        checked={verifyAllDomainsChecked(domain.id)}
                        type="checkbox"
                        id={`domain${domain.id}`}
                        className="size-4"
                        disabled={!canEdit}
                      />
                    </div>
                    <span className="text-center text-sm">{domain.name}</span>
                  </div>
                </Table.Cell>
              ))}
            </div>
          </Table.Head>
        </ListingHeader>
      )}

      <Main
        isModal={isModal}
        data-modal={isModal}
        className="data-[modal=true]:px-0"
      >
        {isSearchImageFetching && <Loading className="pt-10" />}
        {!isSearchImageFetching && (
          <Table.Root
            itemType="Dimensionamentos"
            itemsLength={sizingsLength}
            className={`${isModal ? 'max-h-[60vh]' : ''}`}
            paddingTop="0"
            showNoContentMessage={sizingsShown.length <= 0 && isSearch}
          >
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                isDropDisabled={isShowEditButton || !canEditOrder}
                droppableId="sizings"
                type="list"
                direction="vertical"
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {sizingsShown?.map((sizingSort, index) => (
                      <Table.Row key={`${sizingSort.id}`} className="ml-4 px-0">
                        <Draggable
                          isDragDisabled={isShowEditButton || !canEditOrder}
                          draggableId={sizingSort.id?.toString()}
                          index={index}
                          key={sizingSort.id}
                        >
                          {(provided) => (
                            <div
                              className="flex w-full justify-between bg-backgroundPaper"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              onClick={() =>
                                onTableRowClick(
                                  sizingSort.id,
                                  sizingSort.name,
                                  sizingSort.url,
                                )
                              }
                            >
                              <div className="relative flex flex-1 items-center">
                                {/* <Table.Cell
                                  items={sizingsShown}
                                  type="id"
                                  className="w-[6.5rem]"
                                >
                                  {sizingSort.id}
                                </Table.Cell> */}
                                <Table.Cell
                                  items={sizingsShown}
                                  type="name"
                                  style={{ width: `${nameColWidth}ch` }}
                                >
                                  {sizingSort.name}
                                </Table.Cell>
                              </div>
                              <div className="flex items-center gap-4 mr-16">
                                {domains?.items.map((domain) => (
                                  <Table.Cell
                                    items={sizingsShown}
                                    className="flex items-center justify-center gap-2"
                                    key={`checkbox-${domain.id}`}
                                    style={{
                                      width: `${domainWidth}ch`,
                                    }}
                                  >
                                    <span className="w-full">
                                      <input
                                        checked={checkSizingListDomains.some(
                                          (checkSizingDomain) =>
                                            domain.id ===
                                              checkSizingDomain?.domainId &&
                                            checkSizingDomain?.sizingId ===
                                              sizingSort.id &&
                                            checkSizingDomain?.isCheck,
                                        )}
                                        onChange={(event) =>
                                          handleCheckDomain(
                                            event.target.checked,
                                            sizingSort?.id,
                                            domain?.id,
                                            sizingSort?.domains,
                                          )
                                        }
                                        onClick={(event) => {
                                          // Impede que o clique no checkbox propague para a div principal
                                          event.stopPropagation();
                                        }}
                                        type="checkbox"
                                        id="sizing-domain"
                                        className="size-4"
                                        disabled={!canEdit}
                                      />
                                    </span>
                                  </Table.Cell>
                                ))}
                                {!isShowEditButton && canEditOrder && (
                                  <img
                                    src="/icons/drag.png"
                                    alt="Ícone para arrastar Descrição"
                                    className="mr-4 size-5 cursor-grab"
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      </Table.Row>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Table.Root>
        )}
      </Main>

      <Footer
        current={sizingsShownLength}
        total={sizingsLength}
        itemType="Dimensionamentos"
        data-modal={isModal}
        className={
          isModal
            ? 'absolute mx-[1px] mb-[1px] mt-[-10vh] h-28 rounded-b-3xl'
            : ''
        }
        infosStyle={{ mt: isModal ? '-2rem' : '' }}
      />
    </>
  );
}
