import ListingHeader from '../ListingHeader';
import SearchInput from '../SearchInput';
import { useDescriptionContent } from './useDescriptionContent';
import Table from '../Table';
import Loading from '../Loading';
import Footer from '../Footer';
import { AxiosResponse } from 'axios';
import { getDescriptionsPaginated } from '../../services/description';
import LoadMore from '../LoadMore';
import Main from '../Main';
import Filter from '../Filter';
import { Param } from '../../types/params';

export type Service = (
  page: number,
  direction?: 'ASC' | 'DESC',
  limit?: number,
  params?: Param[],
) => Promise<AxiosResponse>;

type DescriptionContentProps = {
  isModal?: boolean;
  descriptionsInUse?: Record<'id', number>[];
  baseUrl: string;
  filter?: boolean;
  service?: Service;
  handleAddDescriptionClick?: () => void;
  handleTableRowClick: (
    descriptionId: number,
    descriptionName: string,
    descriptionTypeId: number,
    descriptionTypeName: string,
  ) => void;
};

export default function DescriptionContent({
  isModal = false,
  baseUrl,
  descriptionsInUse,
  filter = true,
  service = getDescriptionsPaginated,
  handleAddDescriptionClick,
  handleTableRowClick,
}: DescriptionContentProps) {
  const {
    descriptionsLength,
    descriptionsShown,
    inputRef,
    isFetching,
    isSearchInputDirty,
    sortField,
    isSearch,
    endPageRef,
    isLoadMoreFetching,
    descriptionTypeColWidth,
    descriptionTypes,
    descriptionTypeFilter,
    handleFilterByDescriptionType,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
  } = useDescriptionContent(baseUrl, service, !isModal && filter);

  return (
    <>
      {!isModal && (
        <ListingHeader
          add
          onAddIconClick={handleAddDescriptionClick}
          pdfTop="3rem"
          pdf={false}
        >
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">Descrições</h1>
            {filter && (
              <Filter
                title="Tipo de descrição"
                filters={[
                  {
                    filter: 'Todos',
                    isCurrent:
                      !descriptionTypeFilter ||
                      descriptionTypeFilter === 'Todos',
                    name: 'Todos',
                  },
                  ...(descriptionTypes?.items ?? []).map((type) => ({
                    filter: type.name,
                    isCurrent: descriptionTypeFilter === type.name,
                    name: type.name,
                  })),
                ]}
                onFilter={handleFilterByDescriptionType}
              />
            )}
            <SearchInput
              ref={inputRef}
              onSearch={handleSearch}
              showCancelOptions={isSearchInputDirty}
              buttonContent="Buscar"
              onCancelSearch={handleCancelSearch}
              onButtonClick={handleSearchDatabase}
              onKeyDown={handleEnterClickSearchInput}
            />
          </div>
          <Table.Head>
            <Table.Cell
              items={descriptionsShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
            >
              Nome
            </Table.Cell>
            <Table.Cell
              items={descriptionsShown}
              type="descriptionType.name"
              onSort={handleSortTable}
              isSortActive={sortField === 'descriptionType.name'}
              style={{ width: `${descriptionTypeColWidth}ch` }}
            >
              Tipo
            </Table.Cell>
          </Table.Head>
        </ListingHeader>
      )}

      {isModal && (
        <div>
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">Descrições</h1>
            <SearchInput
              ref={inputRef}
              onSearch={handleSearch}
              buttonContent="Buscar"
              onButtonClick={handleSearchDatabase}
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
              onKeyDown={handleEnterClickSearchInput}
            />
          </div>
          <Table.Head rowStyle="px-0">
            <Table.Cell
              items={descriptionsShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
              className="ml-4"
            >
              Nome
            </Table.Cell>
            {/* <Table.Cell
              items={descriptionsShown}
              type="descriptionType.name"
              onSort={handleSortTable}
              isSortActive={sortField === 'descriptionType.name'}
              className="mr-[4.5rem]"
              style={{ width: `${descriptionTypeColWidth}ch` }}
            >
              Tipo
            </Table.Cell> */}
          </Table.Head>
        </div>
      )}

      <Main isModal={isModal}>
        {isFetching && <Loading loadMore={!isModal} />}
        {!isFetching && descriptionsShown && (
          <Table.Root
            itemType="Descrições"
            itemsLength={descriptionsLength}
            className={`${isModal ? 'max-h-[68vh] pb-32' : ''}`}
            paddingTop="0"
            showNoContentMessage={descriptionsShown.length <= 0 && isSearch}
            showSearchMessage={descriptionsShown.length <= 0 && !isSearch}
          >
            {descriptionsShown.map((description) => {
              const isInUse = descriptionsInUse?.some(
                (item) => item.id === description.id,
              );

              return (
                <Table.Row
                  key={description.id}
                  onClick={() =>
                    !isInUse &&
                    handleTableRowClick(
                      description.id,
                      description.name,
                      description.descriptionType.id,
                      description.descriptionType.name,
                    )
                  }
                  className={`${isInUse ? 'cursor-default opacity-50' : ''} px-4`}
                >
                  <Table.CellStatic>{description.name}</Table.CellStatic>
                  {!isModal && (
                    <Table.CellStatic
                      style={{ width: `${descriptionTypeColWidth - 1.5}ch` }}
                    >
                      {description.descriptionType.name}
                    </Table.CellStatic>
                  )}
                  {/* <div className="flex items-center gap-4">
                    <Table.CellStatic
                      style={{ width: `${descriptionTypeColWidth - 1.5}ch` }}
                    >
                      {description.descriptionType.name}
                    </Table.CellStatic>
                    {isModal && (
                      <Table.CellStatic className="w-6">
                        {isInUse && <CheckIcon className="text-primary" />}
                      </Table.CellStatic>
                    )}
                  </div> */}
                </Table.Row>
              );
            })}
            <LoadMore
              isModal={isModal}
              isContentFetching={isFetching}
              isLoadMoreFetching={isLoadMoreFetching}
              ref={endPageRef}
            />
          </Table.Root>
        )}
      </Main>

      <Footer
        current={descriptionsShown.length}
        total={descriptionsLength}
        itemType="Descrições"
        data-modal={isModal}
        className={
          isModal
            ? 'absolute mx-[1px] mb-[1px] mt-[-10vh] h-16 rounded-b-2xl'
            : ''
        }
        // infosStyle={{ mt: isModal ? '-2rem' : '' }}
      />
    </>
  );
}
