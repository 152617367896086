import { imageTypesText } from '../../constants/imageTypes';
import { ImageTypes } from '../../enums/imageTypes';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import Loading from '../Loading';
import LoadMore from '../LoadMore';
import Main from '../Main';
import SearchInput from '../SearchInput';
import Table from '../Table';
import CheckIcon from '@mui/icons-material/Check';
import { useDocumentContent } from './useDocumentContent';
import { twMerge } from 'tailwind-merge';
import { AxiosResponse } from 'axios';
import { DocumentsPaginated } from '../../types/document';
import Filter from '../Filter';

export type DocumentService = (
  typeDocument: string,
) => Promise<AxiosResponse<DocumentsPaginated, any>>;

type DocumentContentProps = {
  isModal?: boolean;
  documentsInUse?: number[];
  documentService: DocumentService;
  onAddDocumentClick?: () => void;
  queryKey: string;
  onTableRowClick: (
    documentId: number,
    documentName: string,
    documentType: string,
    doucmentUrl: string,
  ) => void;
};

export default function DocumentContent({
  isModal,
  documentsInUse,
  queryKey,
  documentService,
  onTableRowClick,
  onAddDocumentClick,
}: DocumentContentProps) {
  const {
    documentsShown,
    documentsShownLength,
    isLoadMoreFetching,
    documentsLength,
    typeDocument,
    inputRef,
    isSearchImageFetching,
    typeColWidth,
    isSearchInputDirty,
    sortField,
    isSearch,
    endPageRef,
    documentTypes,
    filter,
    handleFilter,
    handleEnterClickSearchInput,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
    handleFilterByTypeDocument,
    handleSearchInAllDocuments,
  } = useDocumentContent(documentService, queryKey);

  return (
    <>
      {!isModal && (
        <ListingHeader
          add
          onAddIconClick={onAddDocumentClick}
          pdfTop="3rem"
          pdf={false}
        >
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">Documento</h1>
            <Filter
              title="Tipo documento"
              filters={[
                {
                  filter: 'todos',
                  isCurrent: !typeDocument || typeDocument === 'todos',
                  name: 'Todos',
                },
                ...(documentTypes?.items ?? []).map((type) => ({
                  filter: type.name,
                  isCurrent: typeDocument === type.name,
                  name: type.name,
                })),
              ]}
              onFilter={handleFilterByTypeDocument}
            />
            <SearchInput
              ref={inputRef}
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
              onSearch={handleSearch}
              onButtonClick={handleSearchInAllDocuments}
              onKeyDown={handleEnterClickSearchInput}
              buttonContent="Buscar"
            />
          </div>
          <Table.Head>
            <Table.Cell
              items={documentsShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
            >
              Documento
            </Table.Cell>
            <Table.Cell
              items={documentsShown}
              type="documentType.name"
              onSort={handleSortTable}
              isSortActive={sortField === 'documentType.name'}
              style={{ width: `${typeColWidth}ch` }}
            >
              Tipo
            </Table.Cell>
          </Table.Head>
        </ListingHeader>
      )}

      {isModal && (
        <div>
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">Documentos</h1>
            <div className="mb-4 flex gap-4 rounded-lg bg-[#e0e0e0] px-2 py-2 max-md:flex-col">
              <span
                onClick={() => handleFilter('Todos')}
                className={twMerge(
                  'flex flex-1 cursor-pointer items-center justify-center rounded-lg max-md:py-1',
                  !filter || filter === 'Todos'
                    ? 'border border-primary bg-backgroundPaper text-primary'
                    : 'text-primaryText',
                )}
              >
                Todos
              </span>
              {documentTypes?.items.map((documentType) => (
                <span
                  key={documentType.id}
                  onClick={() => handleFilter(documentType.name)}
                  className={twMerge(
                    'flex flex-1 cursor-pointer items-center justify-center rounded-lg max-md:py-1',
                    filter === documentType.name
                      ? 'border border-primary bg-backgroundPaper text-primary'
                      : 'text-primaryText',
                  )}
                >
                  {documentType.name}
                </span>
              ))}
            </div>
            <SearchInput
              ref={inputRef}
              onSearch={handleSearch}
              buttonContent="Buscar"
              onButtonClick={handleSearchInAllDocuments}
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
              onKeyDown={handleEnterClickSearchInput}
            />
          </div>
          <Table.Head>
            <Table.Cell
              items={documentsShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
            >
              Documento
            </Table.Cell>
            <Table.Cell
              items={documentsShown}
              type="documentType.name"
              onSort={handleSortTable}
              isSortActive={sortField === 'documentType.name'}
              style={{ width: `${typeColWidth}ch` }}
            >
              Tipo
            </Table.Cell>
          </Table.Head>
        </div>
      )}
      <Main
        isModal={isModal}
        data-modal={isModal}
        className="data-[modal=true]:px-0"
      >
        {isSearchImageFetching && <Loading loadMore={!isModal} />}
        {!isSearchImageFetching && (
          <Table.Root
            itemType="documentos"
            itemsLength={documentsLength}
            className={`${isModal ? 'max-h-[60vh]' : ''} pb-4`}
            paddingTop="0"
            showNoContentMessage={documentsShown.length <= 0 && isSearch}
            showSearchMessage={documentsShown.length <= 0 && !isSearch}
          >
            {documentsShown.map((document) => {
              const isInUse = documentsInUse?.some((id) => id === document.id);

              return (
                <Table.Row
                  key={document.id}
                  onClick={() =>
                    !isInUse &&
                    onTableRowClick(
                      document.id,
                      document.name,
                      document.documentType.name,
                      document.url,
                    )
                  }
                  className={isInUse ? 'cursor-default opacity-50' : ''}
                >
                  <Table.Cell items={documentsShown} type="name">
                    {document.name}
                  </Table.Cell>
                  <div className="flex items-center">
                    <Table.Cell
                      items={documentsShown}
                      type="documentType.name"
                      style={{ width: `${typeColWidth}ch` }}
                    >
                      {imageTypesText[
                        document.documentType.name as ImageTypes
                      ] ?? document.documentType.name}
                    </Table.Cell>
                    {isModal && (
                      <Table.Cell
                        items={documentsShown}
                        type="desc"
                        className="absolute right-6"
                      >
                        {isInUse && <CheckIcon className="text-primary" />}
                      </Table.Cell>
                    )}
                  </div>
                </Table.Row>
              );
            })}
            <LoadMore
              isModal={!!isModal}
              isContentFetching={false}
              isLoadMoreFetching={isLoadMoreFetching}
              ref={endPageRef}
            />
          </Table.Root>
        )}
      </Main>

      <Footer
        current={documentsShownLength}
        total={documentsLength}
        itemType="Documentos"
        data-modal={isModal}
        className={
          isModal
            ? 'absolute mx-[1px] mb-[1px] mt-[-10vh] h-28 rounded-b-3xl'
            : ''
        }
        infosStyle={{ mt: isModal ? '-2rem' : '' }}
      />
    </>
  );
}
