import { useState, useEffect, useRef } from 'react';
import { News } from '../../types/news';
import { SetNewsCardWidthInPx } from '.';

export const useNewsCard = (
  news: News,
  setNewsCardWidthInPx: SetNewsCardWidthInPx,
) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isValidLink, setIsValidLink] = useState(false);

  useEffect(() => {
    setIsImageLoading(true);

    const timeout = setTimeout(() => {
      setIsImageLoading(false);
    }, 1000);

    return () => {
      setIsImageLoading(false);
      clearTimeout(timeout);
    };
  }, [news]);

  useEffect(() => {
    const checkLinkExists = async () => {
      try {
        const response = await fetch(news.url, { method: 'HEAD' });
        setIsValidLink(response.ok);
      } catch {
        setIsValidLink(false);
      }
    };

    checkLinkExists();
  }, [news.url]);

  useEffect(() => {
    setTimeout(() => {
      const htmlElments = document.getElementsByClassName('book');
      const elements = Array.from(htmlElments) as HTMLDivElement[];

      const maxItem = elements.reduce((prev, current) => {
        return (current?.offsetWidth ?? 0) > (prev?.offsetWidth ?? 0)
          ? current
          : prev;
      }, elements[0]);

      if (maxItem?.offsetWidth > 355) {
        setNewsCardWidthInPx(maxItem.offsetWidth + 8);
      }
    }, 1500);
  }, [setNewsCardWidthInPx]);

  const handleImageWasLoaded = () => {
    setIsImageLoading(false);
  };

  const handleLoadStart = () => {
    setIsImageLoading(true);
  };

  const handleDownload = (url: string, name: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = name;
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error('Erro ao baixar o PDF:', error));
  };

  return {
    isImageLoading,
    isValidLink,
    handleDownload,
    handleImageWasLoaded,
    handleLoadStart,
  };
};
