import { ControlCameraOutlined } from '@mui/icons-material';
import { ImageTypes } from '../../../enums/imageTypes';
import { getImageFromApi } from '../../../utils/getImageUrl';
import CardSkeleton from '../CardSkeleton';
import { useHighlightBuffalo } from './useHighlightBuffalo';

export default function HighlightBuffalo() {
  const {
    domainShown,
    isDomainFetching,
    handleImageWasLoaded,
    handleRedirectImage,
    handleLoadStart,
  } = useHighlightBuffalo();


  return (
    <>
      {isDomainFetching && <CardSkeleton />}

      {domainShown?.imgHighlight && (
        <>
          <h2 className="mb-5 text-2xl font-semibold text-zinc-800">
            Destaque
            <span className="text-4xl text-yellow-500">.</span>
          </h2>

          <img
            onClick={handleRedirectImage}
            src={getImageFromApi(
              domainShown.imgHighlight,
              ImageTypes.imgHighlight,
            )}
            alt={`Imagem do ${domainShown.name}`}
            onLoadStart={handleLoadStart}
            onLoad={handleImageWasLoaded}
          />
        </>
      )}
    </>
  );
}
