import Loading from '../Loading';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { useProductsVinculatedInDocument } from './useProductsVinculatedInDocument';

interface ProductsVinculatedInDocumentProps {
  documentId: number | null;
}

export default function ProductsVinculatedInDocument({
  documentId,
}: ProductsVinculatedInDocumentProps) {
  const {
    isProductDocumentsInputDirty,
    productDocumentsInputRef,
    productsVinculatedInCurrentDocument,
    productsVinculatedInCurrentDocumentLength,
    isProductsVinculatedInCurrentDocumentFetching,
    productsVinculatedInCurrentDocumentShown,
    sortFieldProductDocuments,
    handleCancelProductDocumentsSearch,
    handleSearch,
    handleSortProductDocumentsTable,
  } = useProductsVinculatedInDocument(documentId);

  return (
    <div>
      <SearchInput
        ref={productDocumentsInputRef}
        onSearch={handleSearch}
        onButtonClick={handleCancelProductDocumentsSearch}
        showCancelOptions={isProductDocumentsInputDirty}
        onCancelSearch={handleCancelProductDocumentsSearch}
      />
      <Table.Head rowStyle="px-0 items-center">
        <div className="flex px-4">
          <Table.Cell
            items={productsVinculatedInCurrentDocumentShown}
            type="id"
            onSort={handleSortProductDocumentsTable}
            isSortActive={sortFieldProductDocuments === 'id'}
          >
            <span className="text-xs">Código</span>
          </Table.Cell>
          <Table.Cell
            items={productsVinculatedInCurrentDocumentShown}
            type="name"
            onSort={handleSortProductDocumentsTable}
            isSortActive={sortFieldProductDocuments === 'name'}
          >
            <span className="text-xs">Nome</span>
          </Table.Cell>
        </div>
      </Table.Head>

      <div>
        {isProductsVinculatedInCurrentDocumentFetching && (
          <Loading className="pt-10" />
        )}
        {!isProductsVinculatedInCurrentDocumentFetching &&
          productsVinculatedInCurrentDocument && (
            <Table.Root
              itemType="Produtos"
              itemsLength={productsVinculatedInCurrentDocumentLength}
              noContentContainerClassName="h-fit pt-4"
            >
              {productsVinculatedInCurrentDocumentShown.map((product) => (
                <Table.Row className="px-0" key={product.id}>
                  <div className="flex px-4">
                    <Table.CellStatic className="w-24">
                      {product.id}
                    </Table.CellStatic>
                    <Table.CellStatic>{product.name}</Table.CellStatic>
                  </div>
                </Table.Row>
              ))}
            </Table.Root>
          )}
      </div>
    </div>
  );
}
