import { api } from '../../services/api';
import { addSearchParamsInUrl } from '../../utils/generatedPaginatedUrl';
import {
  FindAllRepresentative,
  FindAllRepresentativeUfs,
  RepresentativeDto,
} from '../types/representative';

const ENDPOINT_URL = '/find-representative/v1';

export const getUfsRepresentative = async () => {
  const ufs = await api.get<FindAllRepresentativeUfs>('/representative/v1/uf');

  return ufs;
};

export const getRepresentatives = async (dto?: RepresentativeDto) => {
  const url = addSearchParamsInUrl(
    '/representative/v1',
    { name: 'page', value: dto?.page },
    { name: 'limit', value: dto?.limit },
    { name: 'uf', value: dto?.uf },
    { name: 'domainId', value: dto?.domainId },
  );

  const representatives = await api.get<FindAllRepresentative>(url);

  return representatives;
};

export const fetchRepresentantes = async (
  page = 1,
  pageSize = 20,
  uf?: string,
) => {
  try {
    const params: any = { page, pageSize };
    if (uf) {
      params.uf = uf;
    }
    const response = await api.get(ENDPOINT_URL, { params });
    return {
      list: response.data.data,
      pagination: response.data.pagination,
    };
  } catch (error) {
    console.error('Erro ao buscar dados da API', error);
    throw error;
  }
};
