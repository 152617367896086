import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ArrowProps } from 'react-multi-carousel';

type CarouselRightArrowBuffaloProps = ArrowProps & {};

export default function CarouselRightArrowBuffalo({
  ...rest
}: CarouselRightArrowBuffaloProps) {
  return (
    <ChevronRightIcon
      className="absolute right-3 cursor-pointer text-5xl text-yellow-400"
      sx={{ fontSize: '3.5rem' }}
      {...rest}
    />
  );
}
