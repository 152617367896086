import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useModal } from '../../../hooks/useModal';
import { useEdit } from '../../../hooks/useEdit';
import { useForm } from 'react-hook-form';
import {
  Domain,
  DomainFormData,
  FindAllDomainsPaginated,
  SaveDomainDto,
  UpdateDomainDto,
} from '../../../types/domain';
import { domainSchema } from '../../../validations/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from 'react-query';
import {
  deleteDomain,
  saveDomain,
  updateDomain,
} from '../../../services/domain';
import { useQueryCache } from '../../../hooks/useQueryCache';
import {
  addressByCepCache,
  citiesCache,
  domainCache,
  productsLineCategoryDomain,
  statesUfCache,
} from '../../../constants/requestCacheName';
import { AxiosError, isAxiosError } from 'axios';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { ImageTypes } from '../../../enums/imageTypes';
import { getImageFromApi } from '../../../utils/getImageUrl';
import { cepMask, removeCepMask } from '../../../utils/mask';
import { getAllStates } from '../../../services/stateUf';
import { StateUF } from '../../../types/stateUF';
import { City } from '../../../types/city';
import { getAllCitiesByStateId } from '../../../services/city';
import { getAddressByCep } from '../../../services/cep';
import { Cep } from '../../../types/cep';
import { statesMap } from '../../../buffalos/constants/statesUf';
import { capitalizeText } from '../../../utils/format';
import { findAllProductsByDomainId } from '../../../services/productLineCategoryDomain';
import {
  ProductLineCategoryDomain,
  ProductLineCategoryDomainPaginate,
} from '../../../types/productLineCategoryDomain';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { Search, search as searchDatabase } from '../../../services/search';
import { productDomainSearchBaseEndpoint } from '../../../constants/endpoints';
import { Product } from '../../../types/products';

type PreviewImages = Pick<DomainFormData, 'imgHighlight'> & {
  emailSignatures: string;
};

export const useDomain = () => {
  const {
    formState: { errors, dirtyFields },
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm<DomainFormData>({
    resolver: zodResolver(domainSchema),
    defaultValues: {
      domain: '',
      imgHighlight: '',
      cep: '',
      city: '',
      neighborhood: '',
      number: '',
      state: '',
      street: '',
    },
  });

  const currentImageType = useRef<ImageTypes | null>(null);
  const currentStateId = useRef<number | null>(null);
  const selectedCity = useRef<string | null>(null);
  const product = useRef<string | null>(null);
  const currentImageHighlight = watch('imgHighlight');
  const cep = watch('cep');
  const domain = watch('domain');
  const city = watch('city');
  const neighborhood = watch('neighborhood');
  const number = watch('number');
  const state = watch('state');
  const street = watch('street');

  const domainIdUpdate = useRef<number | null>(null);
  const [domainId, setDomainId] = useState<number | null>(null);
  const [selectedProductImage, setSelectedProductImage] = useState<Product| null>(null);
  const [errorProductImagehighlight, setErrorProductImagehighlight] =
    useState<string>('');
  const currentProductName = useRef<string | null>(null);
  currentProductName.current = selectedProductImage?.name ?? '';

  const {
    addItemOnScreen,
    updateItemOnScreen,
    removeItemFromScreen,
    clearCache,
  } = useQueryCache();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: saveDomainMutate } = useMutation({
    mutationFn: async (dto: SaveDomainDto) => {
      return (await saveDomain(dto)).data;
    },

    onSuccess: (data) => {
      addItemOnScreen<FindAllDomainsPaginated>(domainCache, data);
      handleCloseManageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao salvar domínio',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Domínio já cadastrado');
            return true;
          }
        },
      );
    },
  });

  const { mutate: updateDomainMutate } = useMutation({
    mutationFn: async (dto: UpdateDomainDto) => {
      return (await updateDomain(dto)).data;
    },

    onSuccess: (data) => {
      updateItemOnScreen<FindAllDomainsPaginated>(domainCache, data);
      // handleCloseManageModal();
      handleUnedit();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar domínio',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('already exists') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage('Domínio já cadastrado');
            return true;
          }
        },
      );
    },
  });

  const { mutate: deleteDomainMutate } = useMutation({
    mutationFn: async () => {
      if (domainIdUpdate.current) {
        await deleteDomain(domainIdUpdate.current);
        return;
      }

      setErrorMessage('Falha ao deletar domínio');
    },

    onSuccess: () => {
      if (domainIdUpdate.current) {
        removeItemFromScreen<FindAllDomainsPaginated>(
          domainCache,
          domainIdUpdate.current,
        );
      }

      handleCloseManageModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao deletar domínio',
        setErrorMessage,
        () => {
          if (isAxiosError(error) && error.response?.status === 409) {
            setErrorMessage(
              'Este domínio só pode ser apagado depois que não houver itens associados a ele',
            );
            return true;
          }
        },
      );
    },
  });

  const {
    isModalOpen: isMenageModalOpen,
    handleCloseModal: closeMenageModal,
    handleOpenModal: handleOpenMenageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmDeleteModalOpen,
    handleCloseModal: handlecloseConfirmDeleteModal,
    handleOpenModal: handleOpenConfirmDeleteModal,
  } = useModal();

  const { canEdit, handleEdit, handleUnedit } = useEdit();

  const handleAddDomainClick = () => {
    handleOpenMenageModal();
  };

  const handleCloseManageModal = () => {
    clearCache(`${productDomainSearchBaseEndpoint}${domainId}`);
    domainIdUpdate.current = null;
    currentStateId.current = null;
    selectedCity.current = null;
    setErrorProductImagehighlight('');
    setDomainId(null);
    // setSelectedProductImage(null);
    currentProductName.current = null

    reset();
    handleUnedit();
    closeMenageModal();
    setPreviewImages({ emailSignatures: '', imgHighlight: '' });
  };

  const handleClearDomain = (value: keyof DomainFormData) => {
    setValue(value, '', { shouldDirty: true });
  };

  const handleTableRowClick = (domain: Domain) => {
    domainIdUpdate.current = domain.id;
    setDomainId(domain.id);
    selectedCity.current = domain.city ?? null;
    if (domain.state) {
      const selectedState = states?.find(
        (state) => state.nome === domain.state,
      );
      currentStateId.current = selectedState?.id ?? null;
    }

    if (domain.product) {
      setSelectedProductImage(domain.product);
      currentProductName.current = domain.product.name
    }

    refetchCities();

    setValue('domain', domain.name);
    setValue('imgHighlight', domain.imgHighlight);

    setPreviewImages(() => ({
      imgHighlight: domain.imgHighlight
        ? getImageFromApi(domain.imgHighlight, ImageTypes.imgHighlight)
        : '',
      emailSignatures: domain.emailSignatureImage ?? '',
    }));

    setValue('neighborhood', domain.neighborhood ?? '');
    setValue('number', domain.number ?? '');
    setValue('state', domain.state ?? '');
    setValue('street', domain.street ?? '');
    setValue('cep', cepMask(domain.cep) ?? '');
    // setValue('productId', domain.productId);
    handleOpenMenageModal();
  };

  const handleDeleteButtonClick = () => {
    handleOpenConfirmDeleteModal();
  };

  const handleConfirmDelete = () => {
    deleteDomainMutate();
    handlecloseConfirmDeleteModal();
  };

  const handleButtonOkClick = ({
    domain,
    imgHighlight,
    cep,
    city,
    neighborhood,
    number,
    state,
    street,
  }: DomainFormData) => {
    const upsertDto = {
      name: domain,
      imgHighlight,
      cep: removeCepMask(cep),
      city,
      neighborhood,
      number,
      state,
      street,
      emailSignatureImage: previewImages.emailSignatures,
      product: selectedProductImage?.id,
    };

    if (domainIdUpdate.current) {
      updateDomainMutate({
        id: domainIdUpdate.current,
        ...upsertDto,
      });
      return;
    }

    saveDomainMutate(upsertDto);
  };

  const [selectImageModalType, setSelectImageModalType] =
    useState<ImageTypes | null>(null);

  const isShowEditButton = !!domainIdUpdate.current && !canEdit;

  const [imagesInUse, setImagesInUse] = useState<Record<'id', string>[]>([]);

  const [previewImages, setPreviewImages] = useState<PreviewImages>({
    imgHighlight: '',
    emailSignatures: '',
  });
  const isShowEditProductImageHighlight = previewImages.imgHighlight === '';
  const isShowCreateProductImageHighlight =
    previewImages.imgHighlight !== '' && !canEdit;

  const isShowEditImageHighlight = !domainIdUpdate.current && !canEdit;

  const handleInputImageClick = (imageType: ImageTypes) => {
    if (!isShowEditImageHighlight) {
      if (imageType === ImageTypes.imgHighlight) {
        setImagesInUse([{ id: `${currentImageHighlight}${imageType}` }]);
      }
      currentImageType.current = imageType;

      setSelectImageModalType(imageType);
    }
  };

  const { refetch: refetchAddress } = useQuery<Cep>(
    addressByCepCache,
    async () => (await getAddressByCep(cep)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: false,

      onSuccess: (data) => {
        const selectedState = states?.find(
          (state) => state.sigla === data.state,
        );

        setValue('street', data.street);
        setValue('neighborhood', data.neighborhood);
        setValue('state', capitalizeText(statesMap[data.state]));
        currentStateId.current = selectedState?.id ?? null;
        selectedCity.current = data.city;
        refetchCities();
      },
    },
  );

  const { data: cities, refetch: refetchCities } = useQuery<City[]>(
    citiesCache,
    async () => (await getAllCitiesByStateId(currentStateId.current!)).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,

      onSuccess: () => {
        setTimeout(() => {
          if (selectedCity.current) {
            setValue('city', selectedCity.current);
          }
        }, 500);
      },
    },
  );

  const { data: states } = useQuery<StateUF[]>(
    statesUfCache,
    async () => (await getAllStates()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (removeCepMask(cep).length === 8) {
      refetchAddress();
    }
  }, [cep, refetchAddress]);

  const handleSelectImage = (
    imageId: number,
    imageName: string,
    imageType: string,
  ) => {
    const types: Record<string, keyof DomainFormData> = {
      [ImageTypes.imgHighlight]: 'imgHighlight',
    };

    const previewImages: Record<string, string> = {
      ...types,
      [ImageTypes.emailSignatures]: 'emailSignatures',
    };

    if (imageType === ImageTypes.imgHighlight) {
      setValue(types[imageType], imageName);
    }

    setPreviewImages((prev) => ({
      ...prev,
      [previewImages[imageType]]: getImageFromApi(imageName, imageType),
    }));

    setSelectImageModalType(null);
    setErrorProductImagehighlight('');
  };

  const handleStateClick = (stateName: string) => {
    const selectedState = states?.find((state) => state.nome === stateName);
    currentStateId.current = selectedState?.id ?? null;
    refetchCities();
  };

  const handleCloseSelectImageModal = () => {
    setSelectImageModalType(null);
  };

  // ---------------------------- SELECT PRODUCT ID ---------------------------------------------------- //

  const {
    data: productsDomain,
    isFetching: isFetchingProductsDomain,
    refetch: refetchProductsDomain,
  } = useQuery<ProductLineCategoryDomainPaginate>(
    [productsLineCategoryDomain, domainIdUpdate.current!],
    async () => {
      return (
        await findAllProductsByDomainId(
          currentPage,
          null,
          null,
          domainIdUpdate.current!,
        )
      ).data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      // enabled: true,
    },
  );

  const {
    isModalOpen: isMenageModalProductsOpen,
    handleCloseModal: closeMenageProductsModal,
    handleOpenModal: handleOpenMenageProductsModal,
  } = useModal();

  const handleOpenModalProducts = () => {
    if (canEdit) {
      setErrorProductImagehighlight(
        'Para vincular um produto deve ter a imagem de destaque atribuida',
      );
    }
    if (canEdit && previewImages.imgHighlight !== '') {
      handleOpenMenageProductsModal();
      refetchProductsDomain();
    }
  };

  const handleClearProductImageHighlight = () => {
    setSelectedProductImage(null);
    // currentProductName.current = null
  }

  const handleSelectProduct = (product: ProductLineCategoryDomain) => {
    closeMenageProductsModal();
    setSelectedProductImage(product.product);
    setErrorProductImagehighlight('');
  };

  const loadMore = useCallback(
    async (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      return await findAllProductsByDomainId(page, direction, limit, domainIdUpdate.current!);
    },
    [],
  );

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      if (domainIdUpdate.current! !== null) {
        return searchDatabase<ProductLineCategoryDomain>(
          baseUrl,
          searchValue,
          page,
          null,
          null,
          inputRef?.current?.value || domainIdUpdate.current!
            ? [
                { name: 'domainId', value: domainIdUpdate.current! },
              ]
            : undefined,
        );
      }
    },
    [domainId],
  );

  const {
    inputRef,
    isSearchInputDirty,
    handleCancelSearch,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch,
    itemsShown,
    endPageRef,
    isLoadMoreFetching,
    itemsLength,
    itemsShownLength,
    currentPage,
  } = useHandleTable(
    productsDomain?.meta.totalItems ?? 0,
    productsDomain?.items ?? [],
    refetchProductsDomain,
    null,
    productDomainSearchBaseEndpoint,
    // null,
    loadMore,
    null,
    doSearch as Search,
    domainId?.toString(),
  );

  const ProductDomainShown = itemsShown as ProductLineCategoryDomain[];

  return {
    currentProductName,
    errorProductImagehighlight,
    product,
    currentImageHighlight,
    inputRef,
    cep,
    street,
    number,
    neighborhood,
    city,
    domain,
    isMenageModalProductsOpen,
    isMenageModalOpen,
    domainIdUpdate: domainIdUpdate.current,
    isShowEditButton,
    errors,
    dirtyFields,
    isConfirmDeleteModalOpen,
    errorMessage,
    previewImages,
    selectImageModalType,
    imagesInUse,
    states,
    cities,
    productsDomain,
    ProductDomainShown,
    isFetchingProductsDomain,
    endPageRef,
    isLoadMoreFetching,
    itemsLength,
    itemsShownLength,
    selectedProductImage,
    isShowEditProductImageHighlight,
    isShowEditImageHighlight,
    isShowCreateProductImageHighlight,
    handleClearProductImageHighlight,
    handleSelectProduct,
    findAllProductsByDomainId,
    handleOpenModalProducts,
    closeMenageProductsModal,
    handleStateClick,
    setValue,
    clearError,
    handleConfirmDelete,
    handlecloseConfirmDeleteModal,
    handleClearDomain,
    register,
    handleSubmit,
    handleEdit,
    handleButtonOkClick,
    handleDeleteButtonClick,
    handleTableRowClick,
    handleCloseManageModal,
    handleAddDomainClick,
    handleInputImageClick,
    handleCloseSelectImageModal,
    handleSelectImage,
    handleSearchDatabase,
    handleSearch,
    isSearchInputDirty,
    handleCancelSearch,
    handleEnterClickSearchInput,
  };
};
