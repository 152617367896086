import { ErrorDuimp } from '../../types/errorDuimp';
import Alert from '../Alert';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import Loading from '../Loading';
import Main from '../Main';
import Table from '../Table';
import { useErrorsDuimpContent } from './useErrorsDuimpContent';

type ErrosDuimpContentProps = {
  isModal?: boolean;
  onTableRowClick: (errorDuimp: ErrorDuimp) => void;
};

export default function ErrorsDuimpContent({
  isModal,
  onTableRowClick,
}: ErrosDuimpContentProps) {
  const {
    clearError,
    navigate,
    isFetching,
    errorsListDuimp,
    errorsListDuimpLength,
    errorsListDuimpShown,
    errorsListDuimpShownLength,
    errorMessage,
  } = useErrorsDuimpContent();

  return (
    <>
      {!isModal && (
        <ListingHeader pdfTop="3rem" pdf={false}>
          <div className="my-4">
            <div className="flex items-start justify-between">
              <h1 className="mb-4 text-2xl">Erros do Duimp</h1>
              <div className="flex flex-col items-start">
                <button
                  onClick={() => navigate('/admin/duimp')}
                  className="text-base text-primary"
                >
                  Retornar aos lançamentos
                </button>
              </div>
            </div>
          </div>

          <Table.Head>
            <Table.Cell
              items={errorsListDuimpShown}
              type="name"
              className="w-1/3"
            >
              Código do Produto
            </Table.Cell>
            <Table.Cell items={errorsListDuimpShown} className="w-4/5">
              Erros
            </Table.Cell>
            <Table.Cell items={errorsListDuimpShown} className="w-1/3">
              NCM
            </Table.Cell>
          </Table.Head>
        </ListingHeader>
      )}
      <Main isModal={isModal}>
        {isFetching && <Loading />}
        {!isFetching ? (
          errorsListDuimp ? (
            <Table.Root
              itemType="errorsDuimp"
              itemsLength={errorsListDuimpLength}
              className={`${isModal ? 'max-h-[60vh]' : ''}`}
              paddingTop="0"
            >
              {errorsListDuimpShown?.map((error, index) => {
                return (
                  <Table.Row
                    key={`${error.codigoProduto}-${index}`}
                    onClick={() => onTableRowClick(error)}
                  >
                    <Table.Cell
                      items={errorsListDuimpShown}
                      type="codProd"
                      className="w-1/3"
                    >
                      <span>{error.codigoProduto}</span>
                    </Table.Cell>

                    <Table.Cell
                      items={errorsListDuimpShown}
                      type="erros"
                      className="w-4/5"
                    >
                      <span>{error.errors}</span>
                    </Table.Cell>
                    <Table.Cell
                      items={errorsListDuimpShown}
                      type="ncm"
                      className="w-1/3"
                    >
                      <span>{error.ncm}</span>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Root>
          ) : (
            <div className="flex justify-center p-8">
              Nenhum erro foi encontrado
            </div>
          )
        ) : (
          <></>
        )}
      </Main>

      <Footer
        className={`${isModal ? 'mb-1 ml-[5vw] w-[90vw]' : ''}`}
        current={errorsListDuimpShownLength}
        total={errorsListDuimpLength}
        itemType="Erros"
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
