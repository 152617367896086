import { forwardRef } from 'react';
import {
  Box,
  InputBase,
  InputBaseProps,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import {
  cancelButtonStyle,
  cancelIconStyle,
  inputAreaStyle,
  inputContainerStyle,
  inputStyle,
} from './styles';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

type SerchInputProps = InputBaseProps & {
  onSearch: () => void;
  showCancelOptions?: boolean;
  cancelIcon?: boolean;
  buttonContent?: string;
  onButtonClick?: () => void;
  onCancelSearch?: () => void;
  containerSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
};

export default forwardRef<HTMLInputElement, SerchInputProps>(
  function SearchInput(
    {
      onSearch,

      showCancelOptions = false,
      buttonContent = 'Buscar',
      cancelIcon = false,
      onButtonClick,
      onCancelSearch,
      containerSx,
      sx,
      ...rest
    },
    ref,
  ) {
    return (
      <Box sx={inputAreaStyle}>
        <Box
          sx={
            {
              ...inputContainerStyle,
              ...containerSx,
              width: showCancelOptions ? 'calc(100% - 6rem)' : '100%',
              ...sx,
            } as SxProps<Theme>
          }
        >
          <SearchIcon className="text-zinc-400" />
          <InputBase
            onChange={onSearch}
            placeholder="Buscar"
            inputRef={ref}
            sx={inputStyle}
            {...rest}
          />
          <CancelIcon
            className="text-zinc-400"
            sx={{
              ...cancelIconStyle,
              display: showCancelOptions ? 'block' : cancelIcon ? 'block' : 'none',
            }}
            onClick={onCancelSearch}
          />
        </Box>
        <Typography
          color="secondary"
          onClick={onButtonClick}
          sx={{
            ...cancelButtonStyle,
            opacity: showCancelOptions ? 1 : 0,
            display: showCancelOptions ? 'block' : 'none' ,
            cursor: 'pointer'
          }}
        >
          {buttonContent}
        </Typography>
      </Box>
    );
  },
);
