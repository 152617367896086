import Footer from '../../components/Footer';
import Header from '../../components/Header';

import BackgroundSobre from '../../assets/images/backgrounds/BackgroundSobre2x.png';
import BackgroundGalpao from '../../assets/images/backgrounds/BackgroundBuffaloGalpao2x.png';
import BackgroundInterior from '../../assets/images/backgrounds/BackgroundInterior2x.png';

export default function SobreBuffalos() {
  return (
    <>
      <Header />
      <div className="adjust-content-bf">
        <main>
          <img
            src={BackgroundSobre}
            alt=""
            className="w-full"
            width={1000}
            height={144}
          />
          <div className="mb-6 mt-6 flex justify-center text-start md:text-justify">
            <div className="mx-4 flex flex-col gap-5 md:max-w-[1024px]">
              <div>
                <div className="pb-4 text-2xl font-medium">
                  Sobre
                  <span className="text-4xl text-yellow-500">.</span>
                </div>
                <div className="text-base text-zinc-400">
                  A BUFFALO é uma empresa que comercializa motores a combustão
                  interna e produtos acoplados em todo território nacional, com
                  grande conhecimento das reais necessidades de mercado. Possui
                  uma ampla gama de produtos, oferecendo as melhores soluções em
                  motores e acoplados para as mais diversas aplicações, desde a
                  agricultura familiar até projetos maiores de irrigação,
                  aviário, ordenha entre outros. Além da área agrícola a empresa
                  possui produtos para jardinagem, geração de energia local,
                  construção civil e lazer.
                </div>
              </div>
              <div className="flex flex-col items-start gap-5 md:flex-row">
                <div className="w-full md:w-1/2 2xl:w-full">
                  <img src={BackgroundGalpao} alt="" className="h-96 w-full" />
                </div>
                <div className="w-full md:w-1/2 2xl:w-full">
                  <div className="flex flex-col">
                    <div className="pb-4 text-2xl font-medium">
                      Equipe Qualificada e Suporte Técnico
                      <span className="text-4xl text-yellow-500">.</span>
                    </div>
                    <div className="text-base text-zinc-400">
                      A BUFFALO trabalha com uma equipe que reúne formação
                      técnica e experiência prática. São profissionais que atuam
                      lado a lado com os clientes, no desenvolvimento de solução
                      que traduz a filosofia de resultado onde todos os itens de
                      nossas linhas de produtos são com garantia. Quando você
                      adquire um equipamento Buffalo, conta com produtos 100%
                      testados o que garante qualidade e satisfação, além de ter
                      à disposição toda linha de pecas de reposição e o apoio da
                      ampla rede de pós-venda e assistência técnica em todo o
                      Brasil. É por tudo isso que a BUFFALO se destaca como
                      tendo a melhor estrutura tecnica para atendê-lo. Solicite
                      a visita de um representante BUFFALO mais perto de você e
                      conheça o que podemos oferecer para você e a sua empresa.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="pb-4 text-2xl font-medium">
                  Qualidade no Atendimento
                  <span className="text-4xl text-yellow-500">.</span>
                </div>
                <div className="text-base text-zinc-400">
                  Hoje podemos afirmar que o grande diferencial da Buffalo é o
                  atendimento ao cliente. Vamos até o extremo do país sem medir
                  esforços, para atendê-lo e proporcionar todo o apoio em
                  manutenção e pecas. Nosso atendimento não termina com a venda
                  do produto. Contamos com uma rede de 850 postos de serviços de
                  assistência técnica distribuídos por diversas regiões do
                  Brasil. Pessoas de suporte técnico atuam diretamente na sede
                  da empresa, e também no trabalho externo, participando de
                  eventos, feiras e exposições e realizando visitas técnicas aos
                  postos de assistência orientando e assessorando os
                  profissionais dos mesmos a respeito de nossos produtos.
                </div>
              </div>
              <div>
                <div className="pb-4 text-2xl font-medium">
                  Gestão da Qualidade
                  <span className="text-4xl text-yellow-500">.</span>
                </div>
                <div className="text-base text-zinc-400">
                  Com base no planejamento estratégico a empresa direciona os
                  investimentos na infra-estrutura, em novas linhas de produtos,
                  capacitação dos assistentes técnicos, profissionalização da
                  gestão e na qualificação de seus colaboradores para atender
                  com qualidade e confiança nossos clientes, garantindo um
                  produto 100% testado, peças de reposição e assistência técnica
                  em todas as áreas que atua.
                </div>
              </div>
              <div>
                <div className="pb-4 text-2xl font-medium">
                  Estrutura
                  <span className="text-4xl text-yellow-500">.</span>
                </div>
                <div className="text-base text-zinc-400">
                  A Buffalo acompanha a evolução do mercado com um crescimento
                  que supera as próprias expectativas. Atua de forma consolidada
                  em todo território nacional avançando a cada dia, sempre
                  focada em ampliar o seu negocio oferecendo produtos de
                  qualidade. O crescimento da estrutura da Buffalo acontece com
                  planejamento, cautela e responsabilidade, expandindo de acordo
                  com a necessidade, sem prejudicar a qualidade dos produtos,
                  serviços de pós-venda e de assistência técnica.
                </div>
              </div>
              <div className="flex flex-col items-start gap-5 md:flex-row">
                <div className="w-full md:w-1/2 2xl:w-full">
                  <div className="flex flex-col">
                    <div className="pb-4 text-2xl font-medium">
                      Compromisso Buffalo
                      <span className="text-4xl text-yellow-500">.</span>
                    </div>
                    <div className="text-base text-zinc-400">
                      A empresa cresce a cada dia prezando sempre pela qualidade
                      dos produtos oferecidos, assistência técnica e peças de
                      reposição. Investimos em processos, novos produtos, linhas
                      de teste para garantir produtos de alta qualidade. Esse
                      investimento constante é resultado de parceria com nossos
                      fornecedores que possuem equipamentos com tecnologia de
                      ponta, processos de produção integrados, funcionários bem
                      treinados e um rigoroso sistema de controle de qualidade.
                      O sucesso do negócio depende de clientes satisfeitos; •
                      Buscamos a qualidade e a excelência em tudo que fazemos;•
                      O desenvolvimento tecnológico é a arma para manter a
                      liderança de mercado;• Valorizamos nossos colaboradores;•
                      Temos como base a relação de transparência e confiança com
                      nosso revendedor.
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2">
                  <img
                    src={BackgroundInterior}
                    alt=""
                    className="h-80 w-full"
                  />
                </div>
              </div>
              <div>
                <div className="pb-4 text-2xl font-medium">
                  Crescimento e Novos Investimentos
                  <span className="text-4xl text-yellow-500">.</span>
                </div>
                <div className="text-base text-zinc-400">
                  O mercado de motores estacionários está em constante expansão,
                  o que pode ser comprovado pelo crescimento do número de
                  empresas do segmento. No ano 2000 havia apenas três destas no
                  Brasil e hoje já são mais de 20 que conseguem oferecer estes
                  produtos no mercado brasileiro. Um fator que colaborou para o
                  avanço deste mercado foi o acesso à informação, que
                  possibilitou, principalmente nas zonas rurais, a divulgação
                  mais ampla dos produtos, proporcionando ao homem do campo o
                  conhecimento tecnológico para o aprimoramento do seu trabalho.
                  Como exemplo temos os geradores, que se tornaram um item
                  indispensável para as pessoas e empresas que dependem da
                  energia elétrica para o desenvolvimento do seu trabalho, seja
                  no campo ou na cidade. Fatores sazonais como apagões e
                  períodos de estiagem geram receios de falta de energia o que
                  influencia a venda do produto. Hoje os geradores estão na
                  lista dos nossos produtos mais vendidos”, afirma o diretor
                  administrativo da Buffalo, Valdemiro Hach Lopes. A Buffalo
                  está atenta à evolução do mercado conseguindo crescer dentro
                  das expectativas. Com atuação consolidada nas regiões NORTE, SUL,
                  NORDESTE, SUDESTE e CENTRO-OESTE do BRASIL avançamos a cada dia,
                  sempre focado em ampliar o mix de produtos com atenção
                  especial na qualidade, no preço e nos serviços de pós-venda
                  oferecidos. Com base no planejamento estratégico deste ano,
                  direcionamos nossos investimentos em infra-estrutura, novas
                  linhas de produtos, capacitação dos assistentes técnicos,
                  profissionalização da equipe interna e na qualificação dos
                  nossos colaboradores para atendermos cada vez melhor nossos
                  clientes, garantindo um produto de qualidade, 100% testado,
                  peças de reposição e assistência pós-venda para todos os
                  produtos. “Nosso crescimento acontece com planejamento,
                  cautela e responsabilidade expandindo sem comprometer a
                  qualidade dos nossos produtos e nos serviços de assistência”,
                  finaliza Valdemiro.
                </div>
              </div>
              <div>
                <div className="pb-4 text-2xl font-medium">
                  Uma História de Compromisso com o Cliente
                  <span className="text-4xl text-yellow-500">.</span>
                </div>
                <div className="text-base text-zinc-400">
                  A Buffalo nasceu através de uma equipe experiente, com uma
                  ampla visão de mercado, além de um excelente planejamento
                  estratégico, o que possibilitou o seu rápido crescimento,
                  tornando-se uma das maiores empresa do segmento no país. Este
                  sucesso é resultado da união de importantes elementos:
                  profissionais capacitados, produtos de qualidade com peças
                  para reposição e uma rede de assistência técnica que
                  possibilita um atendimento diferenciado em todas as regiões do
                  Brasil. Para oferecer sempre um ótimo trabalho, nossa empresa
                  possui um aperfeiçoamento constante na equipe intercomercial e
                  pós-vendas, principalmente para os assistentes técnicos,
                  oferecendo cursos mensalmente nas dependências da Buffalo.
                  Também apoiamos internamente os funcionários interessados em
                  buscar maiores conhecimentos e especialização que venham
                  acrescentar melhorias no trabalho realizado. Cursos de
                  graduação, idiomas, estágios, entre outros, são incentivados
                  para o aprimoramento da equipe técnica e administrativa. Outro
                  fator importante é o comprometimento que mantemos com os
                  nossos fornecedores, os quais aceitam sugestões e nos garantem
                  um produto de qualidade. Mesmo assim, a Buffalo testa 100% dos
                  produtos que comercializa para assegurar a qualidade da marca.
                  Hoje podemos afirmar que o grande diferencial da Buffalo é o
                  atendimento ao cliente. Nosso atendimento não termina com a
                  venda do produto. Contamos com uma rede de 850 postos de
                  serviços de assistência técnica distribuídos por todas as
                  regiões do Brasil. Também contamos com um suporte técnico que
                  atua diretamente na sede da empresa, o qual se desloca para
                  campo quando necessário, participando de eventos e feiras e no
                  acompanhamento e orientação aos profissionais das assistências
                  técnicas. Uma empresa que cresce a cada dia, prezando sempre
                  pela qualidade e assistência ao cliente. Novos investimentos,
                  novas linhas de produtos, enfim, são muitas as novidades, que
                  a partir de agora serão compartilhadas com nossos clientes e
                  colaboradores por meio deste informativo, estreitando nossos
                  laços de comunicação.
                </div>
              </div>
              <div className="flex flex-col md:mb-6">
                <div className="font-semibold text-zinc-600">
                  Geraldo José Becchi
                </div>
                <div className="text-zinc-400">Diretor comercial</div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </>
  );
}
