import { Password, RecoverPasswordDto } from '../types/recoverPassword';
import { FindUsersPaginated, SaveUser, UpdateUser, User } from '../types/user';
import { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';
import { api } from './api';
import { Token } from '../types/token';
import { Email, SendMailRecoverPasswordDto } from '../types/emailRecover';

export const getUsersPaginated = async (
  page?: number | null,
  direction?: 'ASC' | 'DESC' | null,
  limit?: number | null,
  typeUser?: string,
) => {
  const url = addSearchParamsInUrl(
      '/user/v1/search',
      { name: 'page', value: page },
      { name: 'limit', value: limit },
      { name: 'direction', value: direction },
      { name: 'typeUser', value: typeUser },
    );
  const response = await api.get<FindUsersPaginated>(url);
  return response;
};

export const saveUser = async ({ password, role, user, email }: SaveUser) => {
  const body = { password, role, user, email };

  const response = await api.post<User>('/user/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const updateUser = async ({ id, role, user, isActive, password, email }: UpdateUser) => {
  const body = { id, role, user, isActive, password: password || null, email };

  const response = await api.put<User>('/user/v1', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response;
};

export const deleteUser = async ({ userId }: Record<'userId', number>) => {
  const response = await api.delete<void>(`/user/v1/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export const sendMail = async ({email}: SendMailRecoverPasswordDto) => {
  const body = {email};

  const response = await api.post<Email>('/user/v1/send-mailer', body);

  return response;
}

export const recoverPassword = async ({password, token}: RecoverPasswordDto) => {
  const body = {password, token};

  const response = await api.put<Password>(`/user/v1/recover-password`, body);

  return response;
}

export const verifyTokenDatabase = async ({token}: Token) => {
  const body = {token};

  const response = await api.post<Password>(`/user/v1/verify-token`, body);

  return response;
}

