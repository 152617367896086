import { ItemFullTablePdf, ItemInfosPage } from '../../types/item';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import listingCommonContent from '../listingCommonContent';
import listingCommonHeader from '../listingCommonHeader';
import { getImageFromApi } from '../../utils/getImageUrl';
import listingCommonFooter from '../listingCommonFooter';
import { urlToPureSvg } from '../../utils/urlToPureSvg';
import { verifyOpenPdfPermission } from '../verifyPdfPermissions';

const generateProductPartsPdf = async (
  items: ItemFullTablePdf[],
  imageId = '',
  imageName = '',
  itemInfosPage: ItemInfosPage | number,
  search = '',
  onError: (errorMessage: string) => void,
  // admin?: 'adimin',
) => {
  verifyOpenPdfPermission(onError);
  pdfMake.vfs = pdfFonts.vfs;

  const itemImageSvg = await urlToPureSvg(getImageFromApi(imageId, 'products'));

  const pdfDefinitions: TDocumentDefinitions = {
    pageSize: 'A4',
    pageMargins: [32, 120, 32, 64],

    header: await listingCommonHeader(
      'Peças',
      search,
      {
        id: imageId,
        name: imageName,
      },
      undefined,
      itemImageSvg,
    ),
    content: [listingCommonContent(items)],
    footer: (currentPage, totalPages, context) =>
      listingCommonFooter(
        currentPage,
        totalPages,
        context,
        'Produtos',
        itemInfosPage,
      ),
  };

  pdfMake.createPdf(pdfDefinitions).getBlob((blob) => {
    const url = URL.createObjectURL(blob);

    // if (admin) {
    //   const pdf = window.open(url, '_blank');
    //   if (!pdf) {
    //     onError(
    //       'O navegador bloqueou o PDF. Desative a opção Bloquear Pop-ups e tente gerar novamente',
    //     );
    //   }
    //   return
    // }

    const a = document.createElement('a');
    a.href = url;
    a.download = `peças_produto_${imageName}.pdf`; // Nome do arquivo
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    if (!a) {
      onError(
        'Falha ao gerar PDF. Desative a opção Bloquear Pop-ups e tente novamente',
      );
    }
  });
};

export default generateProductPartsPdf;
