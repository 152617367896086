import { useQuery } from 'react-query';
import { SizingListSort } from '../../../types/sizing';
import { sizingCache } from '../../../constants/requestCacheName';
import { getAllSizingsPaginated } from '../../../services/sizings';

export const useDimensionamento = () => {
  const { data: sizingsShown } = useQuery<SizingListSort>(
    sizingCache,
    async () => (await getAllSizingsPaginated()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleGeneratePdf = (urlToDownload?: string) => {
    const downloadLink = urlToDownload;
    window.open(downloadLink, '_blank');
  };

  return {
    sizingsShown,
    handleGeneratePdf,
  };
};
