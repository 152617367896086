import { Box } from '@mui/material';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ProductDetails from '../../components/ProductDetails';
import SearchBar from '../../components/SearchBar';
import { usePartsGroupProduct } from './usePartsGroupProduct';
import TableCell from '../../components/Table/TableCell';
import PdfButton from '../../components/PdfButton';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import Table from '../../../components/Table';
import LoadMore from '../../../components/LoadMore';
import TableHead from '../../components/Table/TableHead';
import { ClipLoader } from 'react-spinners';

export default function PartsGroupProduct() {
  const {
    isLoadMoreFetching,
    endPageRef,
    itemsShown,
    openSearch,
    sortField,
    currentProduct,
    productId,
    isPdfLoading,
    inputRef,
    isFetchingPartsAndGroup,
    expandedRow,
    setExpandedRow,
    handleExpandClick,
    handleRetractRow,
    handleClickRow,
    handleSearch,
    handleGeneratePdf,
    handleBackButtonClick,
    handleSortTable,
    handleOpenSearch,
  } = usePartsGroupProduct();


  return (
    <>
      <Header
        handleBackButtonClick={handleBackButtonClick}
        openSearch={openSearch}
        menuIcon={false}
        title="Peças"
        onCLickSearchIcon={handleOpenSearch}
        searchBar={false}
        productCatalog={true}
        productsDetail={
          <ProductDetails
            product={currentProduct}
            isMobile={true}
            pdfButton={
              <PdfButton
                onPdfClick={handleGeneratePdf}
                isPdfLoading={isPdfLoading}
                fontSize={1}
              />
            }
          />
        }
        searchParts={
          <div className="relative z-50 w-full">
            <SearchBar
              ref={inputRef}
              searchUseHandleTable={handleSearch}
              placeholderInput="Encontre sua peça"
            />
          </div>
        }
      />
      <main>
        <div className="medium sticky left-0 right-0 top-[5.5rem] z-10 flex h-[5.3rem] items-center justify-between gap-1 border-b border-slate-400 bg-white/80 p-2 shadow-sm shadow-black/50 backdrop-blur-sm sm:top-24 sm:hidden md:gap-4">
          <div className="flex flex-col items-end gap-4 rounded-md px-1">
            <div className="md:ml-4 lg:mr-48">
              <div className="mb-2 font-medium text-black md:text-2xl">
                Peças
                <span style={{ color: '#eab308', fontSize: '2.25rem' }}>.</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start gap-4 md:flex-row md:items-center">
            <ProductDetails
              product={currentProduct}
              isMobile={true}
              pdfButton={
                <PdfButton
                  // onPdfClick={handleGeneratePdf}
                  isPdfLoading={isPdfLoading}
                  fontSize={1}
                />
              }
            />
          </div>

          <div className="hidden w-[150px] md:block" />
        </div>

        <div className="fixed left-0 right-0 top-[10.7rem] z-10 w-full bg-white/70 shadow-sm shadow-black/50 backdrop-blur-sm sm:top-[5.4rem] lg:top-[5.8rem]">
          <TableHead rowStyle="px-2 gap-2 sm:pl-4 sm:px-8 border-t-2">
            <Box className="flex">
              <TableCell
                items={itemsShown}
                type="id"
                onSort={handleSortTable}
                isSortActive={sortField === 'id'}
                className="!w-16 0.5sm:!w-24"
                onClick={handleRetractRow}
              >
                Código
              </TableCell>
              <TableCell
                items={itemsShown}
                type="name"
                onSort={handleSortTable}
                isSortActive={sortField === 'name'}
                onClick={handleRetractRow}
              >
                Nome
              </TableCell>
            </Box>
            <Box className="flex w-full max-w-[22rem] justify-end gap-3 0.5sm:gap-5 md:justify-start md:gap-10">
              <Table.Cell
                items={itemsShown}
                type="qtdTotal"
                onSort={handleSortTable}
                isSortActive={sortField === 'qtdTotal'}
                className="!bg-transparent text-[#A5A5AE]"
                onClick={handleRetractRow}
              >
                Qtd. Total
              </Table.Cell>

              <TableCell
                items={itemsShown}
                type="Conjunto de peça"
                className="text-[#A5A5AE]"
              >
                Conjunto de peça
              </TableCell>
            </Box>
          </TableHead>
        </div>
        {isFetchingPartsAndGroup && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              marginTop: '1rem',
            }}
          >
            <ClipLoader
              color="#000"
              loading={isFetchingPartsAndGroup}
              size={50}
            />
            <div className="ml-4 text-base font-semibold text-zinc-600">
              Carregando peças compatíveis
            </div>
          </Box>
        )}
        {!isFetchingPartsAndGroup && (
          <Table.Root
            className="mt-7 min-h-[calc(100vh-(224px+100px))] w-full pb-6"
            itemsLength={itemsShown.length}
            itemType="Peças"
          >
            {itemsShown.map(({ id, name, qtdTotal, partsGroups, tag }) => {
              const hasMultipleGroups = partsGroups.length > 1 ;
              const isExpanded = expandedRow === id;

              console.log(isExpanded);

              return (
                <>
                  <Accordion
                    type="single"
                    collapsible
                    className="w-full"
                    value={expandedRow?.toString() ?? ''} // << isso é o mais importante
                    onValueChange={(value) => {
                      const valueAsNumber = Number(value);
                      setExpandedRow(
                        isNaN(valueAsNumber) ? null : valueAsNumber,
                      );
                    }}
                  >
                    <AccordionItem value={id.toString()}>
                      {/* Linha principal */}
                      <div
                        key={id}
                        className={`flex ${isExpanded && hasMultipleGroups ? 'bg-zinc-100' : ''} items-center border-b border-gray-300 px-4 hover:border-yellow-500 sm:px-8 ${hasMultipleGroups ? 'py-1' : 'py-2'}`}
                        onClick={
                          hasMultipleGroups
                            ? () => {}
                            : () =>
                                handleClickRow({
                                  product: {
                                    id: +productId!,
                                  },
                                  partsGroup: {
                                    id: partsGroups[0]?.partsGroup?.id,
                                    name: partsGroups[0]?.partsGroup?.name,
                                    image: partsGroups[0]?.partsGroup?.image,
                                  },
                                  part: {
                                    id,
                                    name,
                                  },
                                  order: 0,
                                  tag,
                                  amount: qtdTotal,
                                })
                        }
                      >
                        <Box className="flex w-full items-center gap-8 0.5sm:gap-16">
                          <Table.CellStatic className="w-full max-w-[2rem]">
                            {id}
                          </Table.CellStatic>
                          <Table.CellStatic className="flex w-full text-start">
                            {name}
                          </Table.CellStatic>
                        </Box>
                        <Box className="flex w-full max-w-[13rem] items-center gap-16 0.5sm:gap-20 md:max-w-full md:justify-end md:gap-24">
                          <Table.CellStatic className="text-center">
                            {qtdTotal}
                          </Table.CellStatic>
                          <TableCell
                            items={itemsShown}
                            className="w-auto text-start md:w-full md:max-w-[15rem]"
                          >
                            {!hasMultipleGroups ? (
                              <TableCell
                                items={itemsShown}
                                className="w-full text-center"
                              >
                                {partsGroups[0]?.partsGroup?.name}
                              </TableCell>
                            ) : (
                              <AccordionTrigger>
                                <TableCell
                                  items={itemsShown}
                                  className="w-10 text-center"
                                  onClick={() => handleExpandClick(id)}
                                >
                                  {hasMultipleGroups && (
                                    <button className="text-xl">
                                      {isExpanded ? '−' : '+'}
                                    </button>
                                  )}
                                </TableCell>
                              </AccordionTrigger>
                            )}
                          </TableCell>
                        </Box>
                      </div>
                      <AccordionContent className="overflow-hidden bg-zinc-100 text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                        {hasMultipleGroups &&
                          partsGroups.map(({ partsGroup, qtdInGroup }: any) => (
                            <div
                              key={partsGroup?.id}
                              className={`flex items-center justify-between border-b border-gray-300 px-4 py-3 hover:border-yellow-500`}
                              onClick={() =>
                                handleClickRow({
                                  product: {
                                    id: +productId!,
                                  },
                                  partsGroup: {
                                    id: partsGroup?.id,
                                    name: partsGroup?.name,
                                    image: partsGroup?.image,
                                  },
                                  part: {
                                    id,
                                    name,
                                  },
                                  order: 0,
                                  tag,
                                  amount: qtdInGroup,
                                })
                              }
                            >
                              <Box className="flex w-full items-center justify-end gap-12 bg-transparent px-0 sm:gap-16 md:max-w-full md:gap-24">
                                <TableCell items={itemsShown}>
                                  {qtdInGroup}
                                </TableCell>
                                <TableCell
                                  items={itemsShown}
                                  className="w-full max-w-[9.5rem] text-start md:max-w-[16rem]"
                                >
                                  {partsGroup?.name}
                                </TableCell>
                              </Box>
                            </div>
                          ))}
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </>
              );
            })}
            <LoadMore
              isModal={false}
              isLoadMoreFetching={isLoadMoreFetching}
              isContentFetching={isFetchingPartsAndGroup}
              ref={endPageRef}
            />
          </Table.Root>
        )}
      </main>
      <Footer />
    </>
  );
}
