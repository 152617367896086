import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { AxiosResponse } from 'axios';

export const useSelectModal = (
  isModalOpen: boolean,
  queryKey: string,
  service: () => Promise<AxiosResponse>,
) => {
  const {
    data: item,
    isFetching: isItemFetching,
    refetch: refetchItem,
  } = useQuery(queryKey, async () => (await service()).data, {
    enabled: false,
    retry: false,
  });

  useEffect(() => {
    refetchItem();
  }, [isModalOpen, refetchItem]);

  return {
    isItemFetching,
    item,
  };
};
