import { useModal } from '../../../hooks/useModal';

export const useFooter = () => {
  const {
    handleCloseModal: handleCloseInfosModal,
    handleOpenModal: handleOpenInfosModal,
    isModalOpen: isInfosModalOpen,
  } = useModal();

  const handleInfosIconClick = () => {
    handleOpenInfosModal();
  };

  return {
    isInfosModalOpen,
    handleCloseInfosModal,
    handleInfosIconClick,
  };
};
