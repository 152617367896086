import { pdfTypesList } from '../../constants/pdf';
import { useHandleTable } from '../../hooks/useHandleTable';
import { PdfType } from '../../types/pdf';

export const usePdfContent = () => {
  const pdfTypes = pdfTypesList;

  const {
    sortField,
    inputRef,
    isSearchInputDirty,
    itemsShown,
    itemsShownLength: pdfTypesShownLength,
    itemsLength: pdfTypesLength,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
  } = useHandleTable(pdfTypes.length, pdfTypes ?? []);

  const pdfTypesShown: PdfType[] = itemsShown;

  return {
    sortField,
    inputRef,
    isSearchInputDirty,
    pdfTypesLength,
    pdfTypes,
    pdfTypesShown,
    pdfTypesShownLength,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
  };
};
