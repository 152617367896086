import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Box,
  BoxProps,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import {
  partsCatalogTableStyle,
  refCellStyle,
  refColumnContentStyle,
  selectPartsGroupStyle,
} from './styles';
import { common, grey } from '@mui/material/colors';
import { PartsGroupItem } from '../../types/partsGroupItem';
import { usePartsCatalogTable } from './usePartsCatalogTable';
import Modal from '../Modal';
import PartsContent from '../PartsContent';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import ProductsContent from '../ProductsContent';
import ConfirmModal from '../ConfirmModal';
import AddButton from '../AddButton';
import RemoveButton from '../RemoveButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Input from '../Input';

type PartsCatalogTableProps = BoxProps & {
  canEdit: boolean;
  currentPartHoverId: number | null;
  currentPartGroupId: number | undefined;
  currentPartGroupName: string | undefined;
  onDeletePart: (partId: number) => void;
  onDragPartEnd: (partsGroupItem: PartsGroupItem[]) => void;
  onEditPart: (partGroupItem: PartsGroupItem) => void;
  onRowMouseEnter?: (partId: number) => void;
  onRowMouseOut?: () => void;
  onRowClick?: (partId: number) => void;
};

export default function PartsCatalogTable({
  canEdit,
  currentPartHoverId,
  currentPartGroupId,
  currentPartGroupName,
  onDeletePart,
  onDragPartEnd,
  onEditPart,
  onRowMouseEnter,
  onRowMouseOut,
  onRowClick,
  ...rest
}: PartsCatalogTableProps) {
  const {
    selectedPart,
    isMobile,
    theme,
    partClickedIndex,
    tableRef,
    currentPartToDeleteId,
    isSelectPartModalOpen,
    partsInUse,
    parts,
    isSelectProductModalOpen,
    isDeleteAllModalOpen,
    currentPartClicked,
    selectProductsService,
    handleSelectPartMobile,
    handleConfirmDeleteAllParts,
    handleCloseDeleteAllModal,
    handleButtonDeleteAllClick,
    handleSelectProduct,
    handleCloseSelectProductModal,
    handleCopyDescriptionsButtonClick,
    handleDragEnd,
    handleEditPart,
    handleChangePartClick,
    handleCloseSelectPartModal,
    handleSelectPartTableRowClick,
    handleAddPartClick,
    handleRemoveIconClick,
    handleTableRowClick,
  } = usePartsCatalogTable(
    currentPartGroupId,
    onEditPart,
    onDragPartEnd,
    onRowClick,
  );

  return (
    <Box component="section" {...rest} sx={partsCatalogTableStyle}>
      <Typography
        component="h3"
        color={grey['600']}
        marginBottom={!isMobile ? '1rem' : ''}
        sx={{
          '@media(min-width: 1250px)': {
            position: 'fixed',
            width: '100%',
            backgroundColor: common.white,
            pt: '1rem',
          },
        }}
      >
        Peças
      </Typography>

      {isMobile ? (
        <Select
          value={selectedPart}
          onChange={handleSelectPartMobile}
          sx={selectPartsGroupStyle}
        >
          <MenuItem
            value={'Selecione a peça'}
            onClick={() => (onRowClick ? onRowClick(-1) : '')}
          >
            Selecione a peça
          </MenuItem>
          {parts?.map(
            ({ part }) =>
              part && (
                <MenuItem
                  value={`${part.id} - ${part.name}`}
                  onClick={() => (onRowClick ? onRowClick(part.id) : '')}
                  key={part.name}
                  sx={{ width: '100vw' }}
                >
                  {part.id} - {part.name}
                </MenuItem>
              ),
          )}
        </Select>
      ) : (
        <>
          {canEdit && (
            <div className="mb-4 pt-14">
              <div className="mb-4 rounded text-sm text-primary">
                <button
                  onClick={handleCopyDescriptionsButtonClick}
                  disabled={(parts && parts.length > 0) || !currentPartGroupId}
                  className="cursor-pointer hover:underline disabled:cursor-default disabled:no-underline disabled:opacity-60"
                >
                  Copiar peças de outro produto
                </button>
              </div>
              <div className="rounded text-sm text-errorText">
                <button
                  onClick={handleButtonDeleteAllClick}
                  disabled={!parts || parts.length <= 0}
                  className="cursor-pointer hover:underline disabled:cursor-default disabled:no-underline disabled:opacity-60"
                >
                  Apagar todas as peças
                </button>
              </div>
            </div>
          )}
          <TableContainer
            sx={{
              overflowX: 'hidden',
              maxHeight: `calc(100vh - ${canEdit ? '25rem' : '14rem'})`,
            }}
            ref={tableRef}
            className={`mb-2 max-w-[32rem] rounded-2xl bg-white max-xl:my-2 max-xl:max-h-40 max-xl:max-w-full max-xl:px-2 ${!canEdit ? 'xl:pt-12' : ''}`}
          >
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                droppableId="parts"
                type="list"
                direction="vertical"
                isDropDisabled={!canEdit}
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {canEdit && <span />}
                          <TableCell sx={refCellStyle} align="center">
                            <Typography sx={refColumnContentStyle}>
                              Ref.
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ color: '#aaaaaa' }}>
                            Código
                          </TableCell>
                          <TableCell>Descrição</TableCell>
                          <TableCell>Qtd.Prd</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {parts?.map((partsGroupItem, index) => {
                          const { part } = partsGroupItem;
                          if (currentPartClicked?.part.id === part?.id) {
                            partClickedIndex.current = index;
                          }

                          return (
                            part && (
                              <Draggable
                                draggableId={part.id.toString()}
                                index={index}
                                key={part.id}
                                isDragDisabled={!canEdit}
                              >
                                {(provided) => (
                                  <TableRow
                                    key={part.id}
                                    className="cursor-pointer overflow-hidden"
                                    style={{
                                      overflow: 'hidden',
                                    }}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {canEdit && (
                                      <TableCell sx={{ border: 'none', p: 0 }}>
                                        <RemoveButton
                                          className="mr-1"
                                          onClick={() =>
                                            handleRemoveIconClick(part.id)
                                          }
                                        />
                                      </TableCell>
                                    )}
                                    <TableCell
                                      sx={refCellStyle}
                                      className="cursor-pointer"
                                      onMouseEnter={() => {
                                        if (onRowMouseEnter) {
                                          onRowMouseEnter(part.id);
                                        }
                                      }}
                                      onClick={() =>
                                        handleTableRowClick(partsGroupItem)
                                      }
                                    >
                                      <div
                                        onMouseEnter={() => {
                                          if (onRowMouseEnter) {
                                            onRowMouseEnter(part.id);
                                          }
                                        }}
                                        onClick={() =>
                                          handleTableRowClick(partsGroupItem)
                                        }
                                        className={`mr-4 cursor-pointer rounded-lg border border-solid ${currentPartHoverId === part.id ? 'border-primary' : 'border-[#cdcdcd]'}`}
                                      >
                                        <input
                                          onChange={(event) => {
                                            partsGroupItem.tag =
                                              event.target.value;
                                            handleEditPart(partsGroupItem);
                                          }}
                                          type="text"
                                          value={partsGroupItem.tag}
                                          readOnly={!canEdit}
                                          className={`remove-arrows h-10 w-12 rounded-lg text-center outline-none read-only:cursor-pointer ${currentPartClicked?.part.id === part.id ? 'bg-primary text-zinc-200' : 'bg-backgroundPaper'}`}
                                          onMouseOut={onRowMouseOut}
                                          onMouseEnter={() => {
                                            if (onRowMouseEnter) {
                                              onRowMouseEnter(part.id);
                                            }
                                          }}
                                          onClick={() =>
                                            handleTableRowClick(partsGroupItem)
                                          }
                                        />
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: '#aaaaaa',
                                        borderBottomColor:
                                          currentPartHoverId === part.id ||
                                          currentPartClicked?.part.id ===
                                            part.id
                                            ? theme.palette.secondary.main
                                            : '',
                                      }}
                                      onMouseOut={onRowMouseOut}
                                      onMouseEnter={() => {
                                        if (onRowMouseEnter) {
                                          onRowMouseEnter(part.id);
                                        }
                                      }}
                                      onClick={() =>
                                        canEdit
                                          ? handleChangePartClick(
                                              partsGroupItem,
                                            )
                                          : handleTableRowClick(partsGroupItem)
                                      }
                                    >
                                      {part.id}
                                    </TableCell>
                                    <TableCell
                                      onMouseEnter={() => {
                                        if (onRowMouseEnter) {
                                          onRowMouseEnter(part.id);
                                        }
                                      }}
                                      onMouseOut={onRowMouseOut}
                                      onClick={() =>
                                        canEdit
                                          ? handleChangePartClick(
                                              partsGroupItem,
                                            )
                                          : handleTableRowClick(partsGroupItem)
                                      }
                                      sx={{
                                        pr: canEdit ? 0 : '1rem',
                                        borderBottomColor:
                                          currentPartHoverId === part.id ||
                                          currentPartClicked?.part.id ===
                                            part.id
                                            ? theme.palette.secondary.main
                                            : '',
                                      }}
                                      data-canEdit={canEdit}
                                      className="data-[canEdit=true]:border-r"
                                    >
                                      {canEdit && (
                                        <div
                                          className="flex items-center justify-between"
                                          onMouseEnter={() => {
                                            if (onRowMouseEnter) {
                                              onRowMouseEnter(part.id);
                                            }
                                          }}
                                        >
                                          <span
                                            onMouseEnter={() => {
                                              if (onRowMouseEnter) {
                                                onRowMouseEnter(part.id);
                                              }
                                            }}
                                            onMouseOut={onRowMouseOut}
                                          >
                                            {part.name}
                                          </span>
                                          <ChevronRightIcon
                                            onMouseEnter={() => {
                                              if (onRowMouseEnter) {
                                                onRowMouseEnter(part.id);
                                              }
                                            }}
                                            onMouseOut={onRowMouseOut}
                                            className="ml-8 text-zinc-400"
                                          />
                                        </div>
                                      )}
                                      {!canEdit && part.name}
                                    </TableCell>
                                    <TableCell
                                      onMouseEnter={() => {
                                        if (onRowMouseEnter) {
                                          onRowMouseEnter(part.id);
                                        }
                                      }}
                                      onClick={() =>
                                        handleTableRowClick(partsGroupItem)
                                      }
                                      sx={{
                                        pr: '0.5rem',
                                        borderBottomColor:
                                          currentPartHoverId === part.id ||
                                          currentPartClicked?.part.id ===
                                            part.id
                                            ? theme.palette.secondary.main
                                            : '',
                                      }}
                                    >
                                      <div
                                        className="flex justify-between"
                                        onMouseEnter={() => {
                                          if (onRowMouseEnter) {
                                            onRowMouseEnter(part.id);
                                          }
                                        }}
                                      >
                                        <Input
                                          type="number"
                                          value={partsGroupItem.amount}
                                          readOnly={!canEdit}
                                          onChange={(event) => {
                                            partsGroupItem.amount = parseInt(
                                              event.target.value,
                                            );
                                            handleEditPart(partsGroupItem);
                                          }}
                                          onMouseOut={onRowMouseOut}
                                          onMouseEnter={() => {
                                            if (onRowMouseEnter) {
                                              onRowMouseEnter(part.id);
                                            }
                                          }}
                                          className="w-10 border-none bg-backgroundPaper outline-none read-only:cursor-pointer"
                                          inputStyle="read-only:cursor-pointer remove-arrows"
                                        />
                                        {canEdit && (
                                          <img
                                            src="/icons/drag.png"
                                            alt="Ícone para arrastar Descrição"
                                            className="mr-4 size-5 cursor-grab"
                                          />
                                        )}
                                      </div>
                                    </TableCell>
                                    {canEdit && (
                                      <TableCell sx={{ border: 'none', p: 0 }}>
                                        <button
                                          onClick={() => onDeletePart(part.id)}
                                          className={`transition-slow bg-errorText py-2 text-zinc-100 ${currentPartToDeleteId === part.id ? 'w-20 py-1 opacity-100' : 'w-0 opacity-0'}`}
                                        >
                                          Apagar
                                        </button>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                )}
                              </Draggable>
                            )
                          );
                        })}
                      </TableBody>
                    </Table>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </TableContainer>
          {canEdit && (
            <AddButton content="Adicionar peças" onClick={handleAddPartClick} />
          )}
        </>
      )}
      <Modal
        isOpen={isSelectPartModalOpen}
        onClose={handleCloseSelectPartModal}
        width="50rem"
        contentStyle="h-[100vh]"
      >
        <PartsContent
          onTableRowClick={handleSelectPartTableRowClick}
          alreadyInUseList={partsInUse}
          isModal
        />
      </Modal>
      <Modal
        isOpen={isSelectProductModalOpen}
        onClose={handleCloseSelectProductModal}
        width="91vw"
        contentStyle="p-0"
      >
        <div>
          <ProductsContent
            onTableRowClick={handleSelectProduct}
            isModal
            service={selectProductsService}
            shouldFilter={false}
          />
        </div>
      </Modal>
      <ConfirmModal
        isModalOpen={isDeleteAllModalOpen}
        onCloseModal={handleCloseDeleteAllModal}
        contentMessage={`Deseja apagar ${currentPartGroupName}? Ao aceitar, não será possível desfazer essa operação`}
        onNotConfirm={handleCloseDeleteAllModal}
        onConfirm={handleConfirmDeleteAllParts}
      />
    </Box>
  );
}
