import { ProductsBarCodePaginated } from '../types/productBarCode';
import { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';
import { api } from './api';

export const getProductsBarCodeOptionalyFilteredPaginated = async (
  page: number,
  direction: 'ASC' | 'DESC' | null = 'ASC',
  limit?: number | null,
  labelSize?: string,
) => {
  const url = addSearchParamsInUrl(
    '/product-bar-code/v1',
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    { name: 'labelSize', value: labelSize },
  );
  const response = await api.get<ProductsBarCodePaginated>(url);
  return response;
};
