export const clearAllCaches = async () => {
  // Verifica se o navegador suporta a API Cache Storage
  if ('caches' in window) {
    // Remove todos os caches armazenados
    const cacheNames = await caches.keys();
    cacheNames.forEach((cacheName) => {
      caches.delete(cacheName);
    });
  }
};
