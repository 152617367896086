import { Content } from 'pdfmake/interfaces';
import { getImageStorage } from '../utils/getImageUrl';
import headerImage from './linePartsCatalogPdf/headerImage';
import headerContent from './linePartsCatalogPdf/headerContent';
import { getAllProductDescriptionsByProductId } from '../services/productDescription';
import { PartsGroupItem } from '../types/partsGroupItem';
import { Part } from '../types/parts';
import { urlToPureSvg } from '../utils/urlToPureSvg';

type PartComplete = Part & {
  amount: number;
  tag: string;
};

type PartGroupItems = PartsGroupItem & {
  parts: PartComplete[];
};

const commonContent = async (
  partsGroupItems: PartsGroupItem[],
  complete: boolean = false,
  index: number = 0,
) => {
  const productId = partsGroupItems[0].product.id;
  const productName = partsGroupItems[0].product.name ?? '';
  const productDescriptions = (
    await getAllProductDescriptionsByProductId(productId)
  ).data;
  const headerImg = await headerImage(productName, productId);
  const partsGroupIds = new Set();
  const partsGroupNoRepeated: PartGroupItems[] = [];
  partsGroupItems.forEach((partGroup) => {
    const parts: PartComplete[] = [];
    partsGroupItems.forEach(({ part, partsGroup, amount, tag }) => {
      if (partGroup.partsGroup.id === partsGroup.id) {
        parts.push({
          id: part.id,
          name: part.name ?? '',
          new: !!part.new,
          outOfLine: !!part.outOfLine,
          percentIPI: part.percentIPI ?? 0,
          price: part.price ?? 0,
          reviewedApproved: !!part.reviewedApproved,
          amount: amount,
          tag: tag,
        });
      }
    });
    const partGroupId = partGroup.partsGroup.id;
    if (!partsGroupIds.has(partGroupId)) {
      const newPartGroupItems = {
        ...partGroup,
        parts: parts,
      };
      partsGroupNoRepeated.push(newPartGroupItems);
      partsGroupIds.add(partGroupId);
    }
  });
  partsGroupNoRepeated.sort((a, b) =>
    (a.partsGroup.name ?? '').localeCompare(b.partsGroup.name ?? ''),
  );
  partsGroupNoRepeated.forEach((parts) =>
    parts.parts.sort((a, b) => Number(a.tag) - Number(b.tag)),
  );
  const productDescriptionTableData = productDescriptions.items?.map(
    (productDescription) => [
      { text: productDescription.description.name },
      { text: productDescription.amount, alignment: 'right' },
    ],
  );
  const productImage = await urlToPureSvg(
    getImageStorage('products', productId),
  );

  const images = await Promise.all(
    partsGroupNoRepeated.map((partGroup) => {
      const res = urlToPureSvg(
        getImageStorage('exploded-view', partGroup.partsGroup.image ?? ''),
      );
      return res;
    }),
  );
  const content: Content[] = [
    complete
      ? {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: -16,
              x2: 540,
              y2: -16,
              lineColor: '#000',
              strokeOpacity: 0.3,
            },
          ],
          pageBreak: 'before',
        }
      : [],
    !complete
      ? {
          text: productName,
          fontSize: 24,
          alignment: 'center',
          color: '#757575',
        }
      : [],
    !complete
      ? {
          text: productId,
          fontSize: 18,
          alignment: 'center',
          color: '#cdcdcd',
        }
      : [],
    {
      svg: productImage,
      alignment: 'center',
      width: 200,
      height: 170,
      margin: [0, 16],
      absolutePosition: complete && index > 0 ? { x: 50, y: 150 } : undefined,
    },
    complete ? headerImg : [],
    complete ? headerContent(productName, productId) : [],
    {
      text: '',
      marginTop: complete && index > 0 ? 200 : undefined,
      pageBreak: !productDescriptionTableData ? 'after' : undefined,
    },
    productDescriptionTableData.length > 0
      ? {
          table: {
            widths: [200, 200],
            body: productDescriptionTableData,
          },
          fontSize: 9,
          margin: [64, 0],
          pageBreak: 'after',
          layout: {
            hLineWidth: (i) => (i === 0 ? 0 : 1),
            vLineWidth: () => 0,
            hLineColor: () => 'gray',
            vLineColor: () => 'gray',
          },
        }
      : [],
    partsGroupNoRepeated.map((partGroup, index) => {
      return [
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 540,
              y2: 0,
              lineColor: '#000',
              strokeOpacity: 0.3,
            },
          ],
          marginTop: 8,
        },
        {
          text: partGroup.partsGroup.name,
          marginBottom: 8,
          color: '#bababa',
          marginTop: 4,
        },
        {
          marginBottom: 8,
          svg: images[index],
          width: 200,
          height: 100,
          marginTop: 4,
        },
        complete ? headerImg : [],
        complete ? headerContent(productName, productId) : [],
        {
          table: {
            // headerRows: 1,
            widths: [32, 32, 150, 48],
            body: [
              [
                { text: 'Ref.', italics: true },
                { text: 'Cod.', italics: true },
                { text: 'Descrição', italics: true },
                { text: 'Qtd.', italics: true },
              ],
              ...partGroup.parts.map((part) => {
                return [
                  { text: part.tag ?? '' },
                  { text: part.id ?? '' },
                  { text: part.name ?? '' },
                  { text: part.amount ?? '' },
                ] as Content;
              }),
            ],
          },
          fontSize: 10,
          marginLeft: 240,
          marginTop: -100,
          alignment: 'left',
          layout: 'noBorders',
          marginBottom: partGroup.parts.length < 5 ? 60 : 4,
        },
        complete ? headerImg : [],
        complete ? headerContent(productName, productId) : [],
      ] as Content;
    }),
  ];
  return content;
};

export default commonContent;
