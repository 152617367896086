import { Box, Typography, BoxProps, Theme, SxProps } from '@mui/material';
import { footerContainerStyle, textStyle } from './styles';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type FooterProps = BoxProps & {
  total: number;
  current: number;
  isFetching?: boolean;
  itemType:
    | 'Domínios'
    | 'Produtos'
    | 'Peças'
    | 'Linhas'
    | 'Categorias'
    | 'Descrições'
    | 'Peças ou produtos'
    | 'Conjuntos de peças'
    | 'Imagens'
    | 'Tipos de descrição'
    | 'Usuários'
    | 'Tipos de usuários'
    | 'Tipos de documentos'
    | 'Tipos de imagens'
    | 'Documentos'
    | 'Contatos'
    | 'Perguntas frequentes'
    | 'Tabelas'
    | 'Dimensionamentos'
    | 'Tipos de notícias'
    | 'Notícias'
    | 'Assinaturas'
    | 'Emails'
    | 'PDF'
    | 'Páginas'
    | 'Produtos a lançar'
    | 'Erros'
    | 'Logs'
    | 'Campos'
    | 'Assuntos';

  tableFooter?: ReactNode;
  infosStyle?: SxProps<Theme>;
};

export default function Footer({
  total,
  isFetching = false,
  current,
  itemType,
  tableFooter,
  className,
  infosStyle,
  ...rest
}: FooterProps) {
  return (
    <Box
      component="footer"
      sx={{
        ...footerContainerStyle,
        '@media (max-width: 550px)': {
          height: tableFooter ? '8.5rem' : '5rem',
          paddingX: '0.5rem',
        },
      }}
      className={twMerge(
        'fixed bottom-0 left-0 right-0 flex items-center justify-center bg-backgroundMedium px-8 py-4',
        className,
      )}
      {...rest}
    >
      <Typography
        sx={
          {
            ...textStyle,
            '@media (max-width: 1450px)': {
              mb: tableFooter ? '2.5rem' : '',
            },
            '@media(max-width:550px)': {
              mb: 0,
              marginTop: tableFooter ? '-5rem' : 0,
            },
            ...infosStyle,
          } as SxProps<Theme>
        }
      >
        {current} de {Number.isNaN(total) ? '0' : total} {itemType}
      </Typography>
      {tableFooter}
    </Box>
  );
}
