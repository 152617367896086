import { Box } from '@mui/material';
import DomainImageView from '../../../components/DomainImageView';
import { useDomainFieldsPosition } from './useDomainFieldsPosition';
import {
  explodedViewStyle,
  fieldsCatalogTable,
  headerStyle,
  mainStyle,
} from './styles';
import FieldsTable from '../../../components/FieldsTable';
import ConfirmModal from '../../../components/ConfirmModal';
import Alert from '../../../components/Alert';
import SecondaryHeader from '../../../components/SecondaryHeader';
import SelectModal from '../../../components/Admin/SelectModalNew';
import { domainCache } from '../../../constants/requestCacheName';
import { getDomainsPaginated } from '../../../services/domain';
import Table from '../../../components/Table';
import Footer from '../../../components/Footer';
import { CheckIcon } from 'lucide-react';
import Modal from '../../../components/Modal';
import ProductsPartsContent from '../../../components/ProductsPartsContent';

export default function DomainFieldsPosition() {
  const {
    currentFieldHoverId,
    canEdit,
    domainId,
    errorMessage,
    domainIdNumber,
    domainShown,
    isModalOpen,
    isConfirmModifiedModalOpen,
    isOpenConfirmModal,
    handleOpenConfirmModal,
    handleCancelConfirmModal,
    handleClickRowByDomain,
    clearError,
    handleOpenDomainsModal,
    handleCloseDomainModal,
    handleCloseModifiedModal,
    handleConfirmBack,
    handleFieldMouseEnter,
    handleFieldMouseOut,
    // handleClickField,
    handleEditButtonClick,
    handleOkButtonClick,
    handleBackButtonClick,
  } = useDomainFieldsPosition();

  return (
    <>
      <Box>
        <SecondaryHeader
          itemId={domainId}
          // itemName={domainName}
          pdf={false}
          canEdit={canEdit}
          onOkButtonClick={handleOkButtonClick}
          onEditButtonClick={handleEditButtonClick}
          separator
          onBackButtonClick={handleBackButtonClick}
          sx={headerStyle}
          showImage={false}
        />
        <Box component="main" sx={mainStyle}>
          <Box sx={fieldsCatalogTable}>
            {/* <button onClick={handleOpenDomainsModal}>Copiar coordenadas</button> */}
            <FieldsTable
              onDeleteField={() => {}}
              onEditField={() => {}}
              canEdit={canEdit}
              currentFieldHoverId={currentFieldHoverId}
              onRowMouseEnter={handleFieldMouseEnter}
              onRowMouseOut={handleFieldMouseOut}
              onModalCopyCoordinates={handleOpenDomainsModal}
              disableButtonCoordinates={false}
              // onRowClick={handleClickField}
            />
          </Box>
          <Box sx={explodedViewStyle}>
            <DomainImageView
              canEdit={canEdit}
              currentFieldHoverId={currentFieldHoverId}
              // imageName={explodedViewImageName}
              onFieldMouseEnter={handleFieldMouseEnter}
              onFieldMouseOut={handleFieldMouseOut}
              // onFieldClick={handleClickField}
            />
          </Box>
        </Box>
        <SelectModal
          key={undefined}
          title="Coordenadas"
          isModalOpen={isModalOpen}
          onModalClose={handleCloseDomainModal}
          queryKey={domainCache}
          service={getDomainsPaginated}
          headContent={
            <div>
              <Table.Head rowStyle="px-4">
                <Table.Cell items={domainShown?.items} type="name">
                  Domínios
                </Table.Cell>
              </Table.Head>
            </div>
          }
          footerContent={
            <Footer
              current={domainIdNumber}
              total={Number(domainShown?.meta?.totalItems)}
              itemType="Domínios"
              className="static mb-[-1rem] ml-[-1rem] w-[calc(100%+2rem)] rounded-b-2xl"
            />
          }
        >
          <Table.Root
            itemType="domínios"
            // itemsLength={domainShown?.meta?.totalItems}
            itemsLength={Number(domainShown?.meta?.totalItems)}
            paddingTop="0"
          >
            {domainShown?.items?.map((domain) => (
              <Table.Row
                key={domain.id}
                // onClick={
                //   domainIdNumber !== domain.id
                //     ? () => handleClickRowByDomain(domain.id)
                //     : undefined
                // }
                // onClick={() => handleClickRowByDomain(domain.id)}
                onClick={() => handleOpenConfirmModal(domain.id)}
                className={`items-center px-4 ${domainIdNumber === domain.id ? 'cursor-default text-secondaryText opacity-50' : ''}`}
              >
                <Table.Cell items={domainShown.items} type="name">
                  {domain.name}
                </Table.Cell>
                {domainIdNumber === domain.id && (
                  <CheckIcon className="text-primary" />
                )}
              </Table.Row>
            ))}
          </Table.Root>
        </SelectModal>

        {/* <Modal
          width="90vw"
          // contentStyle="p-0 fixed"
          isOpen={isSelectProductModalOpen}
          onClose={handleCloseSelectProductModal}
        >
          <ProductsPartsContent
            handleTableRowClick={handleLinkProductClick}
            footerClassName="w-[90vw]"
            title="Produtos"
            itemsInUse={categoryProducts?.items}
            isModal
          />
        </Modal> */}

        <ConfirmModal
          contentMessage={'Deseja copiar as coordenadas deste domínio?'}
          isModalOpen={isOpenConfirmModal}
          onCloseModal={handleCancelConfirmModal}
          onNotConfirm={handleCancelConfirmModal}
          onConfirm={handleClickRowByDomain}
        />

        <ConfirmModal
          isModalOpen={isConfirmModifiedModalOpen}
          contentMessage="As alterações feitas serão perdidas. Deseja prosseguir?"
          onCloseModal={handleCloseModifiedModal}
          onConfirm={handleConfirmBack}
          onNotConfirm={handleCloseModifiedModal}
        />
        <Alert
          content={errorMessage}
          isOpen={!!errorMessage}
          onClose={clearError}
        />
      </Box>
    </>
  );
}
