import { LabelItem } from '../../types/labelNF';
import Input from '../Input';
import LabelNFLabel from '../LabelNFLabel';
import ListingHeader from '../ListingHeader';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

type LabelNFControlProps = {
  labels: LabelItem[];
  lastLabel: LabelItem;
  accessKeyInputRef: React.RefObject<HTMLInputElement>;
  handleChangeAccessKey: (value: string) => void;
  onGeneratePdfClick: () => void;
  onPackingListClick: () => void;
};

export default function LabelNFControl({
  labels,
  accessKeyInputRef,
  lastLabel,
  handleChangeAccessKey,
  onGeneratePdfClick,
  onPackingListClick,
}: LabelNFControlProps) {
  return (
    <section className="flex h-auto flex-col justify-start 2xl:w-[40rem]">
      <div className="flex 2xl:flex-col justify-start gap-10 lg:gap-20 2xl:mb-4 2xl:gap-0">
        <div className="w-full max-w-[30rem]">
          <ListingHeader pdf={false} className="pl-0">
            <div className="mt-4">
              <h1 className="mb-4 text-2xl">Etiquetas NF</h1>
            </div>
          </ListingHeader>
          <div className="mt-4 flex flex-col gap-4">
            <h2 className="text-sm font-bold">Informações da nota fiscal</h2>
            <div className="flex h-auto w-full max-w-[30rem] flex-col items-start justify-center">
              <div className="flex">
                <img
                  src="/icons/barCodeScanner.png"
                  alt="Scanner de código de barras"
                />
                <label htmlFor="" className="text-sm">
                  Chave de acesso
                </label>
              </div>
              <Input
                maxLength={44}
                ref={accessKeyInputRef}
                onChange={(event) => handleChangeAccessKey(event.target.value)}
                type="number"
                inputStyle="remove-arrows"
                className="w-full max-w-[30rem] rounded border bg-white px-2 outline-none"
              />
            </div>
          </div>
        </div>
        <div>
          {lastLabel && (
            <div className="">
              <h3 className="mb-1 mt-8 text-sm font-bold">Etiqueta</h3>
              <LabelNFLabel label={lastLabel} />
            </div>
          )}
          <div className="mt-1 flex w-full gap-8 2xl:mb-4 justify-center 2xl:justify-start">
            <button
              onClick={onGeneratePdfClick}
              disabled={labels.length <= 0}
              className="flex gap-1 rounded border border-zinc-300 bg-gradient-to-b from-zinc-100 to-zinc-200 px-2 py-1 disabled:opacity-60"
            >
              <PictureAsPdfIcon className="text-red-700" />
              Gerar PDF
            </button>
            <button
              onClick={onPackingListClick}
              className="rounded border border-zinc-300 bg-gradient-to-b from-zinc-100 to-zinc-200 px-2 py-1"
            >
              Romaneio
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
