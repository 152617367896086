import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import BackgroundMap from '../../assets/images/backgrounds/BackgroundMapa2x.png';
import MenuImages from '../../components/MenuImages';
import { useHome } from './useHome';
import HighlightBuffalo from '../../components/HighlightBuffalo';
import SwiperCarousel from '../../components/SwiperCarousel';
import Popup from '../../../components/PupUp';
import TawkToChat from '../../components/TawkToChat';

export default function Home() {
  const {
    newsTypes,
    linesShown,
    isImageLoading,
    search,
    isMenageModalOpen,
    popUp,
    handleEnterClickSearchInput,
    handleFilterByNewsType,
    closeMenageModal,
    handleSearch,
    handleImageWasLoaded,
    handleLoadStart,
  } = useHome();

  return (
    <>
      <Header />
      <div className="adjust-content-bf">
        <section>
          <article className="fullwidthbanner-container">
            <section className="fullwidthbanner banner-image">
              <SwiperCarousel linesShown={linesShown} />
            </section>
          </article>

          <MenuImages
            linesShown={linesShown}
            isImageLoading={isImageLoading}
            handleImageWasLoaded={handleImageWasLoaded}
            handleLoadStart={handleLoadStart}
          />

          <div className="bg-gray-300">
            <div className="mx-5 pt-4">
              <div className="w-full pb-10">
                <HighlightBuffalo />
              </div>

              <div className="w-full pb-10">
                <div className="flex">
                  <div
                    className="w-full rounded-xl bg-black bg-opacity-25 bg-center p-10"
                    style={{
                      backgroundImage: `url(${BackgroundMap})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className="mb-4 text-2xl font-medium text-white">
                      Assistência Técnica
                      <span className="text-4xl text-yellow-500">.</span>
                    </div>
                    <div className="flex w-full">
                      <input
                        type="text"
                        placeholder=""
                        name="busca"
                        onKeyDown={handleEnterClickSearchInput}
                        onChange={(event) => handleSearch(event.target.value)}
                        className="w-full rounded-l-lg border p-2 outline-none"
                      />
                      <Link
                        to={{
                          pathname: '/assistencia',
                          search: `search=${search}`,
                        }}
                        type="submit"
                        className="rounded-r-lg bg-yellow-500 p-3 font-medium tracking-wide text-black"
                      >
                        Procurar
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-5">
              <div className="sticky top-0 justify-between gap-6 pb-10 xl:flex">
                <div className="mt-4 xl:mt-0 xl:flex xl:w-[100%] xl:space-x-7">
                  <div className="w-full xl:mt-0 xl:w-2/2">
                    <div className="sticky top-0 mb-4 flex flex-col w-full">
                      <div className="pb-3 text-2xl font-medium">
                        News
                        <span className="text-4xl text-yellow-500">.</span>
                      </div>

                      <div className="scroll-news-home flex flex-col gap-2 pr-1 md:flex-row w-[100%]">
                        {newsTypes?.map((newsType) => (
                          <button
                            key={newsType.id}
                            onClick={() =>
                              handleFilterByNewsType(newsType.name)
                            }
                            className="w-full rounded-xl bg-white p-2 text-center text-lg font-medium"
                          >
                            {newsType.name}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </div>

      <Popup
        isOpen={isMenageModalOpen}
        onClose={closeMenageModal}
        popUp={popUp}
      />

      {/* <Modal
        iconContainerStyle={{ right: '0rem' }}
        isOpen={isMenageModalOpen}
        onClose={closeMenageModal}
        contentStyle={'mx-5 py-8'}
      >
        <div className="flex flex-col gap-4 break-words">
          <div
            dangerouslySetInnerHTML={{ __html: popUp?.titleField ?? '' }}
            className="w-11/12 text-3xl"
          />
          <div
            dangerouslySetInnerHTML={{ __html: popUp?.subtitleField ?? '' }}
            className="text-2xl"
          />
          <div
            dangerouslySetInnerHTML={{ __html: popUp?.contentField ?? '' }}
            className="text-xl"
          />
          <div
            dangerouslySetInnerHTML={{ __html: popUp?.footerField ?? '' }}
            className="text-xl"
          />
        </div>
      </Modal> */}
      {/* <TawkToChat /> */}
    </>
  );
}
