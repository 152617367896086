import { Content } from 'pdfmake/interfaces';
import { getImageStorage } from '../../utils/getImageUrl';
import { urlToPureSvg } from '../../utils/urlToPureSvg';

const headerImage = async (
  productName: string,
  productId: number,
): Promise<Content> => {
  const image = await urlToPureSvg(getImageStorage('products', productId));
  return [
    {
      svg: image,
      width: 50,
      height: 50,
      margin: 16,
      absolutePosition: { x: 16, y: 16 },
    },
  ];
};

export default headerImage;
