import { Box } from '@mui/material';
import { appBarStyle, mainStyle, cardsList } from './styles';
import { useProductsCatalogFullContent } from './useProductsCatalogFullContent';
import CategoriesNavbar from '../CategoriesNavbar';
import HomeCard from '../HomeCard';
import LinesNavbar from '../LinesNavbar';
import MenuCard from '../MenuCard';
import Alert from '../Alert';

export default function ProductsCatalogFullContent() {
  const {
    products,
    productDescriptionSelectedId,
    isPdfLoading,
    errorMessage,
    clearError,
    handleFetchCategories,
    handleFetchProducts,
    handleCloseModal,
    handleOpenModal,
  } = useProductsCatalogFullContent();

  return (
    <Box sx={{ cursor: isPdfLoading ? 'progress' : 'initial' }}>
      <header style={appBarStyle}>
        <LinesNavbar onLineClick={handleFetchCategories} />
        <CategoriesNavbar onCategoryClick={handleFetchProducts} />
      </header>

      <Box component="main" sx={mainStyle}>
        <Box sx={cardsList}>
          <MenuCard />

          {products?.items.map((product) => (
            <HomeCard
              key={product.id}
              product={product}
              onCloseModal={handleCloseModal}
              onOpenModal={handleOpenModal}
              productDescriptionSelectedId={productDescriptionSelectedId}
            />
          ))}
        </Box>
      </Box>

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </Box>
  );
}
