import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { common, grey } from '@mui/material/colors';

export const headerStyle: SxProps<Theme> = {
  position: 'fixed',
  width: '100%',
  zIndex: 3,
  backgroundColor: common.white,
  '@media (max-width: 1250px)': {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: grey['50'],
    zIndex: 2,
    borderBottom: 'none',
  },
};

export const mainStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1rem',
  gap: '0.5rem',
  paddingTop: '8.5rem',

  '@media (max-width: 1250px)': {
    paddingTop: 0,
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
  },
};

export const partsGroupStyle: SxProps<Theme> = {
  '@media (max-width: 1250px)': {
    width: '100%',
    position: 'fixed',
    top: '8.25rem',
    zIndex: 2,
  },
};

export const explodedViewStyle: SxProps<Theme> = {
  '@media (max-width: 1250px)': {
    marginTop: '18rem',
  },
};

export const partsCatalogTable: SxProps<Theme> = {
  '@media (max-width: 1250px)': {
    width: '100%',
    position: 'fixed',
    top: '12.35rem',
    backgroundColor: grey['200'],
    borderTop: `2px solid ${grey['400']}`,
    borderBottom: `2px solid ${grey['400']}`,
  },
};
