import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { common } from '@mui/material/colors';

export const mainStyle: SxProps<Theme> = {
  padding: ' 0 2rem 2rem 2rem',
  minHeight: '100vh',

  '@media (max-width: 760px)': {
    padding: 0,
  },
};

export const backButtonStyle: SxProps<Theme> = {
  flex: 1,
  textAlign: 'start',
  zIndex: 2,

  '@media (max-width: 760px)': {
    display: 'none',
  },
};

export const secondaryHeaderStyle: SxProps<Theme> = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  flex: 1,
  textAlign: 'left',
  justifyContent: 'center',
  paddingRight: '1rem',
  padding: '0.5rem',
  marginTop: '-0.35rem',
  backgroundColor: common.white,
  borderRadius: '0.5rem',

  '@media (max-width: 760px)': {
    position: 'static',
    left: 'auto',
    transform: 'none',
    marginRight: '0.75rem',
    marginTop: '0.75rem',
  },
};

export const inputStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',

  '@media (max-width: 760px)': {
    margin: '0 1rem',
  },
};

export const cancelButtonStyle: SxProps<Theme> = {
  cursor: 'pointer',
};

export const tableStyle: SxProps<Theme> = {
  paddingTop: '12rem',
  paddingBottom: '3rem',

  '@media (max-width: 760px)': {
    paddingTop: '12rem',
  },
};

export const tableHeadStyle: SxProps<Theme> = {
  position: 'fixed',
  top: '9.75rem',
  paddingTop: '0.25rem',

  '@media (max-width: 760px)': {
    top: '9.75rem',
  },
};
