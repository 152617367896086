import { Login } from '../types/login';

import { api } from './api';

import { logout as logoutFront, authenticate } from '../utils/auth';

export const login = async (login: string, password: string) => {
  const response = await api.post<Login>(
    '/auth/v1/login',
    { login, password },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  authenticate(response.data);

  return response;
};

export const logout = async () => {
  const response = await api.post<void>('/auth/v1/logout');
  logoutFront();
  return response;
};

export const verifyToken = async (token: string) => {
  const response = await api.get<{ success: boolean }>('/auth/v1/verify-token');
  return response;
};
