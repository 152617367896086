import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { FindAllQuestions } from '../../../types/questions';
import { questionsCache } from '../../../constants/requestCacheName';
import { getAllActiveQuestions } from '../../../services/questions';
import { getCurrentDomain } from '../../../utils/domain';

export const UseFaq = () => {
  const { data: question } = useQuery<FindAllQuestions>(
    questionsCache,
    async () => (await getAllActiveQuestions(getCurrentDomain().id)).data,
    {
      retry: false,
    },
  );

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return {
    expanded,
    handleChange,
    question,
  };
};
