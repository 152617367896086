// export type Service = (
//   page: number,
//   direction?: 'ASC' | 'DESC',
//   limit?: number,
//   params?: Param[],
// ) => Promise<AxiosResponse>;

import { AxiosResponse } from 'axios';
import { Param } from '../../types/params';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import Loading from '../Loading';
import LoadMore from '../LoadMore';
import Main from '../Main';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { useSubjectContent } from './useSubjectContent';
import { getSubjectsPaginated } from '../../services/subject';

export type Service = (
  page: number,
  direction?: 'ASC' | 'DESC',
  limit?: number,
  params?: Param[],
) => Promise<AxiosResponse>;

type SubjectContentProps = {
  isModal?: boolean;
  baseUrl: string;
  service?: Service;
  handleAddSubjectClick?: () => void;
  handleTableRowClick: (subjectId: number, subjectName: string) => void;
};

export default function SubjectContent({
  isModal = false,
  baseUrl,
  service = getSubjectsPaginated,
  handleAddSubjectClick,
  handleTableRowClick,
}: SubjectContentProps) {
  const {
    subjectsShown,
    subjectsLength,
    inputRef,
    handleSearch,
    isSearchInputDirty,
    handleCancelSearch,
    handleSearchDatabase,
    handleEnterClickSearchInput,
    handleSortTable,
    sortField,
    isFetching,
    isSearch,
    isLoadMoreFetching,
    endPageRef,
  } = useSubjectContent(baseUrl, service);

  return (
    <>
      {!isModal && (
        <ListingHeader
          add
          onAddIconClick={handleAddSubjectClick}
          pdfTop="3rem"
          pdf={false}
        >
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">Assuntos</h1>
            <SearchInput
              ref={inputRef}
              onSearch={handleSearch}
              showCancelOptions={isSearchInputDirty}
              buttonContent="Buscar"
              onCancelSearch={handleCancelSearch}
              onButtonClick={handleSearchDatabase}
              onKeyDown={handleEnterClickSearchInput}
            />
          </div>
          <Table.Head>
            <Table.Cell
              items={subjectsShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
            >
              Assunto
            </Table.Cell>
          </Table.Head>
        </ListingHeader>
      )}

      {isModal && (
        <div>
          <div className="mt-4">
            <h1 className="mb-4 text-2xl">Assuntos</h1>
            <SearchInput
              ref={inputRef}
              onSearch={handleSearch}
              buttonContent="Buscar"
              onButtonClick={handleSearchDatabase}
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
              onKeyDown={handleEnterClickSearchInput}
            />
          </div>
          <Table.Head rowStyle="px-0">
            <Table.Cell
              items={subjectsShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
              className="ml-4"
            >
              Assunto
            </Table.Cell>
          </Table.Head>
        </div>
      )}

      <Main isModal={isModal}>
        {isFetching && <Loading loadMore={!isModal} />}
        {!isFetching && subjectsShown && (
          <Table.Root
            itemType="Descrições"
            itemsLength={subjectsLength}
            className={`${isModal ? 'max-h-[68vh] pb-32' : ''}`}
            paddingTop="0"
            showNoContentMessage={subjectsShown.length <= 0 && isSearch}
            showSearchMessage={subjectsShown.length <= 0 && !isSearch}
          >
            {subjectsShown.map((subject) => {
              return (
                <Table.Row
                  key={subject.id}
                  onClick={() => handleTableRowClick(subject.id, subject.name)}
                >
                  <Table.CellStatic>{subject.name}</Table.CellStatic>
                </Table.Row>
              );
            })}
            <LoadMore
              isModal={isModal}
              isContentFetching={isFetching}
              isLoadMoreFetching={isLoadMoreFetching}
              ref={endPageRef}
            />
          </Table.Root>
        )}
      </Main>

      <Footer
        current={subjectsShown.length}
        total={subjectsLength}
        itemType="Assuntos"
        data-modal={isModal}
        className={
          isModal
            ? 'absolute mx-[1px] mb-[1px] mt-[-10vh] h-16 rounded-b-2xl'
            : ''
        }
      />
    </>
  );
}
