import { api } from '../../services/api';
import { addSearchParamsInUrl } from '../../utils/generatedPaginatedUrl';
import {
  FindAllTechnicalAssistanceCities,
  FindAllTechnicalAssistanceStates,
  TechnicalAssistanceDto,
  TechnicalAssistancePaginated,
} from '../types/technicalAssistance';

export const getStatesTechnicalAssistance = async () => {
  const states = await api.get<FindAllTechnicalAssistanceStates>(
    '/technical-assistance-contacts/v1/states',
  );

  return states;
};

export const getCitiesByState = async (state: string) => {
  const cities = await api.get<FindAllTechnicalAssistanceCities>(
    `/technical-assistance-contacts/v1/cities/state/${state}`,
  );

  return cities;
};

export const getTechnicalAssistances = async (dto?: TechnicalAssistanceDto) => {
  const url = addSearchParamsInUrl(
    '/technical-assistance/v1',
    { name: 'page', value: dto?.page },
    { name: 'limit', value: dto?.limit },
    { name: 'state', value: dto?.state },
    { name: 'city', value: dto?.city },
    { name: 'companyName', value: dto?.companyName },
    { name: 'code', value: dto?.code },
    { name: 'profile', value: dto?.profile },
    { name: 'search', value: dto?.search },
  );

  const technicalAssistances = await api.get<TechnicalAssistancePaginated>(url);

  return technicalAssistances;
};

// export const fetchAssistencias = async (
//   page = 1,
//   pageSize = 20,
//   uf?: string,
//   city?: string,
//   company?: string,
//   code?: string,
// ) => {
//   try {
//     const params: any = { page, pageSize };
//     if (uf) {
//       params.uf = uf;
//     }
//     if (city) {
//       params.city = city;
//     }
//     if (company) {
//       params.company = company;
//     }
//     if (code) {
//       params.code = code;
//     }
//     const response = await api.get(ENDPOINT_URL, { params });
//     return {
//       list: response.data.data,
//       pagination: response.data.pagination,
//     };
//   } catch (error) {
//     console.error('Erro ao buscar dados da API', error);
//     throw error;
//   }
// };
