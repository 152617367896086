import { SxProps, Theme } from '@mui/material';

export const modalStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const modalContentStyle: SxProps<Theme> = {
  borderRadius: '20px',
  position: 'relative',
};

export const cancelIconContainerStyle: SxProps<Theme> = {
  position: 'absolute',
  zIndex: 2,
  right: '1rem',
  top: '1rem',
};

export const cancelIconStyle: SxProps<Theme> = {
  fontSize: '1.75rem',
  cursor: 'pointer',
};

export const titleStyle: SxProps<Theme> = {
  textAlign: 'center',
};
