export const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const regexOnlyNumber = /^\d+$/;

export const regexNumbersWithExtension = /^\d+(\.\w+)?$/;

export const regexExtension = /\.\w+$/;

export const regexCep = /^[0-9]{5}-[0-9]{3}$/;
