import { LabelNF } from '../types/labelNF';
import { api } from './api';

export const getLabelNFByAccessKey = async (accessKey: string) => {
  const response = await api.post<LabelNF>(
    `/label-nf/v1/access-key/${accessKey}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return response;
  // const response = await fetch(
  //   `http://localhost:3333/api/label-nf/v1/access-key/${accessKey}`,
  // );

  // const res = await response.json();

  // return {
  //   data: res,
  // };
};
