import React from 'react';
import { useLogin } from './useLogin';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Alert from '../../components/Alert';
import { getAplicationInfos } from '../../utils/application';

export default function Login() {
  const {
    errors,
    dirtyFields,
    showPassword,
    errorMessage,
    clearError,
    handleIconEyeClick,
    sendMailerPasswordClick,
    handleSubmit,
    register,
    onSubmit,
  } = useLogin();

  return (
    <>
      <div className="p-8 pt-16">
        <div className="mx-auto w-1/3 max-xl:w-1/2 max-md:w-full">
          <h1 className="text-4xl">Iniciar sessão</h1>
          <form onSubmit={handleSubmit(onSubmit)} className='text-center'>
            <input
              type="text"
              placeholder="ID da Buffalo"
              className="mt-4 w-full rounded-lg border border-solid border-zinc-300 px-4 py-4 outline-sky-400"
              {...register('login')}
            />
            {errors.login && (
              <span className="text-xs text-red-600">
                {errors.login.message}
              </span>
            )}
            <div className="mt-4 flex w-full items-center rounded-lg border border-solid border-zinc-300 pr-4 focus-within:outline focus-within:outline-2 focus-within:outline-sky-400">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Senha"
                className="mr-2 w-full rounded-lg px-4 py-4 outline-none"
                {...register('password')}
              />
              {dirtyFields.password && (
                <div onClick={handleIconEyeClick}>
                  {showPassword && (
                    <VisibilityOffIcon className="cursor-pointer" />
                  )}
                  {!showPassword && (
                    <VisibilityIcon className="cursor-pointer" />
                  )}
                </div>
              )}
            </div>
            {errors.password && (
              <span className="text-xs text-red-600">
                {errors.password.message}
              </span>
            )}
            <div className='text-left'>
              <h2 className="mt-4 font-bold">ID da Buffalo</h2>
              <p className="pl-4 text-sm">
                Para colaboradores, digite 'nome.sobrenome'
              </p>
              <p className="pl-4 text-sm">
                Para representantes, digite o código de cadastro
              </p>
            </div>
            <button
              type="submit"
              className="mt-16 w-full rounded-lg bg-gradient-to-b from-blue-400 to-blue-600 p-4 text-center text-zinc-50 hover:to-blue-500"
            >
              Iniciar Sessão
            </button>
            <button onClick={sendMailerPasswordClick} className='rounded text-sm text-primary w-full mt-5'>Esqueceu sua senha?</button>
          </form>
          <div className="absolute bottom-0 left-0 flex w-screen flex-col items-center justify-center gap-4 border-t p-8">
            <div className="flex w-full items-center justify-center gap-8">
              <p className="text-sm text-zinc-500">
                Precisa de ajuda? Ligue para +55 (41) 3091-5600
              </p>
              <div className="flex items-center gap-1">
                <img
                  src="images/brasil_icon.png"
                  alt="Ícone da bandeira do Brasil"
                  className="w-6"
                />
                <span className="text-sm text-zinc-500">Brasil</span>
              </div>
            </div>
            <div className="w-screen bg-zinc-300 py-1 text-center text-sm">
              Versão {getAplicationInfos()?.version} de{' '}
              {getAplicationInfos()?.versionDate}
            </div>
          </div>
        </div>
      </div>
      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
