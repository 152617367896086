import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { searchCache } from '../../../constants/requestCacheName';
import { useQueryCache } from '../../../hooks/useQueryCache';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import {
  archiveContact,
  // deleteContact,
  updateContact,
} from '../../../services/contactUs';
import { ContactStatus } from '../../../enums/contactStatus';
import { ContactUs, FindAllContacts } from '../../../types/contactUs';
import { contactsSearchBaseEndpoint } from '../../../constants/endpoints';
import { useLocation, useNavigate } from 'react-router-dom';

type MutationProps = {
  id: number;
  resposta: string;
  status: ContactStatus;
  domainId: number;
};

export const useContactFetch = (
  currentContactId: number | null,
  handleCloseMenageModal: () => void,
  handleCloseModal: () => void,
  handleCloseConfirmModal: () => void,
) => {
  const navigate = useNavigate();

  const location = useLocation();

  // Obter os filtros da query string
  const queryParams = new URLSearchParams(location.search);
  const filterName = queryParams.get('domain') || ''; // Pega o filtro "name"

  const { updateItemOnScreen } = useQueryCache();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const { mutate: update } = useMutation({
    mutationFn: async ({ id, resposta, status, domainId }: MutationProps) => {
      return (await updateContact(id, resposta, status, domainId)).data;
    },

    onSuccess: ({
      id,
      nome,
      email,
      telefone,
      cpf,
      empresa,
      cidade,
      mensagem,
      status,
      resposta,
      dtCriacao,
      dtModificacao,
      dtConclusao,
      domain,
    }: ContactUs) => {
      if (id && resposta && status) {
        const data = {
          id,
          nome,
          email,
          telefone,
          cpf,
          empresa,
          cidade,
          mensagem,
          status,
          resposta,
          dtCriacao,
          dtModificacao,
          dtConclusao,
          domain,
        };
        navigate({ search: `status=${status}` }, { replace: true });
        updateItemOnScreen<FindAllContacts>(
          `${searchCache}${contactsSearchBaseEndpoint}`,
          data,
        );
        window.alert('Email enviado com sucesso!');
      }

      handleCloseModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao atualizar chamado',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.message.includes('Invalid login') &&
            error.response?.data.statusCode === 400
          ) {
            setErrorMessage(
              `Verifique o email disparador configurado no domínio`,
            );
            return true;
          }
        },
      );
    },
  });

  const { mutate: archiveContactMutate } = useMutation({
    mutationFn: async () => {
      if (currentContactId) {
        return (await archiveContact(currentContactId)).data;
      }

      setErrorMessage('Falha ao arquivar chamado');
    },

    onSuccess: ({
      id,
      nome,
      email,
      telefone,
      cpf,
      empresa,
      cidade,
      mensagem,
      status,
      dtCriacao,
      dtModificacao,
      dtConclusao,
      domain,
    }: ContactUs) => {
      if (id && status) {
        const data = {
          id,
          nome,
          email,
          telefone,
          cpf,
          empresa,
          cidade,
          mensagem,
          status,
          dtCriacao,
          dtModificacao,
          dtConclusao,
          domain,
        };

        navigate(
          { search: `status=${status}&domain=${filterName}` },
          { replace: true },
        );

        updateItemOnScreen<FindAllContacts>(
          `${searchCache}${contactsSearchBaseEndpoint}`,
          data,
        );
      }
      handleCloseModal();
      handleCloseConfirmModal();
    },

    onError: (error) => {
      mutationErrorHandling(
        error,
        'Falha ao arquivar chamado',
        setErrorMessage,
        () => {
          if (
            error instanceof AxiosError &&
            error.response?.data.statusCode === 409
          ) {
            setErrorMessage('Houve uma falha ao tentar arquivar chamado');
            return true;
          }
        },
      );
    },
  });

  // const { mutate: deleteContactMutate } = useMutation({
  //   mutationFn: async () => {
  //     if (currentContactId) {
  //       return (await deleteContact(currentContactId)).data;
  //     }

  //     setErrorMessage('Falha ao deletar chamado');
  //   },

  //   onError: (error) => {
  //     mutationErrorHandling(
  //       error,
  //       'Falha ao deletar chamado',
  //       setErrorMessage,
  //       () => {
  //         if (
  //           error instanceof AxiosError &&
  //           error.response?.data.statusCode === 409
  //         ) {
  //           setErrorMessage('Houve uma falha ao tentar deletar chamado');
  //           return true;
  //         }
  //       },
  //     );
  //   },
  // });

  return {
    errorMessage,
    update,
    clearError,
    archiveContactMutate,
    // deleteContactMutate,
  };
};
