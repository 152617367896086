import { ProductsLineCategory } from '../types/productsLineCategory';
import { api } from './api';
import {
  FindAllProducts,
  Product,
  ProductHasImage,
  ProductsInPageSort,
  ProductsPaginated,
  UpdateProductStatus,
  UpdateProductStatusDto,
} from '../types/products';
import generatePaginatedUrl, {
  addSearchParamsInUrl,
} from '../utils/generatedPaginatedUrl';
import { getImageFromApi } from '../utils/getImageUrl';
import { Param } from '../types/params';

export const getAllProducts = async () => {
  const products = await api.get('/product/v1/find-all');
  return products;
};

export const getProductsPaginated = async (
  page: number,
  direction: 'ASC' | 'DESC' | null = 'ASC',
  limit?: number | null,
  noOutOfLine?: boolean | null,
  filters: Param[] = [],
) => {
  const url = addSearchParamsInUrl(
    `/product/v1/search`,
    { name: 'page', value: page },
    { name: 'limit', value: limit },
    { name: 'direction', value: direction },
    { name: 'noOutOfLine', value: noOutOfLine },
    ...filters,
    { name: 's', value: '' },
  );
  const response = await api.get<ProductsPaginated>(url);
  return response;
};

export const getProductSortByCategoryId = async (
  categoryId: number,
  pagination?: {
    page?: number;
    direction?: 'ASC' | 'DESC' | null;
    limit?: number | null;
  } | null,
  exclude?: number | null,
  reviewedApproved?: boolean,
) => {
  const url = addSearchParamsInUrl(
    `/product-line-category/v1/products-by-category/${categoryId}`,
    { name: 'page', value: pagination?.page },
    { name: 'limit', value: pagination?.limit },
    { name: 'direction', value: pagination?.direction },
    { name: 'exclude', value: exclude },
    { name: 'reviewedApproved', value: reviewedApproved },
  );

  const response = await api.get<ProductsInPageSort>(url);
  return response;
};

export const getRelatedProductByCategoryIdAndDomainId = async (
  categoryId: number,
  domainId: number,
  options?: {
    excludeProductId?: number;
    reviewedApproved?: boolean;
  },
) => {
  const url = addSearchParamsInUrl(
    `/product-line-category-domain/v1/category/${categoryId}/domain/${domainId}`,
    { name: 'exclude', value: options?.excludeProductId },
    { name: 'reviewedApproved', value: options?.reviewedApproved },
  );

  const response = await api.get<FindAllProducts>(url);
  return response;
};

export const getAllProductsByDocuementId = async (documentId: number) => {
  const products = await api.get<FindAllProducts>(
    `/product/v1/document/${documentId}`,
  );
  return products;
};

export const saveProductInCategory = async (
  productId: number,
  lineId: number,
  categoryId: number,
) => {
  const response = await api.post<ProductsLineCategory>(
    `/product-line-category/v1/save-product`,
    { productId, lineId, categoryId },
  );

  return response;
};

export const removeProductFromCategory = async (
  productId: number,
  lineId: number,
  categoryId: number,
) => {
  const response = await api.delete<void>(
    `/product-line-category/v1/remove-product/${productId}/${lineId}/${categoryId}`,
  );

  return response;
};

export const saveProduct = async (
  productId: number,
  productName: string,
  isNew: boolean,
  reviewedApproved: boolean,
  outOfLine: boolean,
  description?: string,
) => {
  const body = {
    id: productId,
    name: productName,
    isNew,
    reviewedApproved,
    outOfLine,
    description,
  };

  const response = await api.post<Product>('/product/v1', body);

  return response;
};

export const updateProductStatus = async (
  updateProductStatusDto: UpdateProductStatusDto,
) => {
  const response = await api.patch<UpdateProductStatus>(
    '/product/v1/status',
    updateProductStatusDto,
  );

  return response;
};

export const updateProduct = async (
  productId: number,
  productName: string,
  isNew: boolean,
  reviewedApproved: boolean,
  outOfLine: boolean,
  description?: string,
) => {
  const body = {
    id: productId,
    name: productName,
    isNew,
    reviewedApproved,
    outOfLine,
    description,
  };

  const response = await api.put<Product>('/product/v1', body);

  return response;
};

export const deleteProduct = async (productId: number) => {
  const response = await api.delete<Product>(`/product/v1/${productId}`);

  return response;
};

export const findAllByLineIdPaginated = async (
  lineId: number,
  page: number,
  direction: 'ASC' | 'DESC' = 'ASC',
  limit?: number,
) => {
  const url = generatePaginatedUrl(
    `/product-line-category/v1/products-by-line/${lineId}`,
    page,
    limit,
    direction,
  );
  const response = await api.get<ProductsInPageSort>(url);
  return response;
};

export const verifyProductHasImageByProductId = async (
  productId: number,
  type = 'products',
): Promise<ProductHasImage> => {
  const imageUrl = getImageFromApi(productId, type);
  const isProductId = await fetch(imageUrl, { method: 'HEAD' });

  return {
    id: productId,
    hasImage: isProductId.ok,
  };
};
