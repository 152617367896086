import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { pagesByLoggedUserCache } from '../../constants/requestCacheName';
import { getAllPagesByLoggedUser } from '../../services/userTypePages';
import { FindAllPages } from '../../types/userTypePages';
import { logout } from '../../services/auth';
import { useMemo } from 'react';

export const useSideNavbar = () => {
  const navigate = useNavigate();

  const { mutate: updateProductStatusMutate } = useMutation({
    mutationFn: logout,

    onSuccess: () => {
      navigate('/login');
    },

    onError: () => {
      alert('Falha ao sair');
    },
  });

  const { data: userPagesData } = useQuery<FindAllPages>(
    pagesByLoggedUserCache,
    async () => (await getAllPagesByLoggedUser()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const userPages = useMemo(() => {
    const userData =
      userPagesData?.items
        .filter(
          ({ page }) =>
            page.id !== 5000001 && page.id !== 5000002 && page.id !== 5000003,
        )
        .sort((a, b) => a.page.name.localeCompare(b.page.name)) ?? [];

    // const testPages =
    //   userPagesData?.items.filter(
    //     ({ page }) =>
    //       page.id === 5000001 || page.id === 5000002 || page.id === 5000003,
    //   ) ?? [];

    return [...userData];
  }, [userPagesData?.items]);

  const handleLogout = () => {
    updateProductStatusMutate();
  };

  return { userPages, handleLogout };
};
