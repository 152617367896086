import { Box } from '@mui/material';
import ListingHeader from '../ListingHeader';
import SerchInput from '../SearchInput';
import Table from '../Table';
import Loading from '../Loading';
import TableRoot from '../Table/TableRoot';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import { usePartsContent } from './usePartsContent';
import { Part } from '../../types/parts';
import ListingHeaderModal from '../ListingHeaderModal';
import CheckIcon from '@mui/icons-material/Check';
import LoadMore from '../LoadMore';
import Alert from '../Alert';
import Main from '../Main';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

type PartsContentProps = {
  onTableRowClick: (part: Part) => void;
  onTablePartEditClick?: (
    partId: string,
    partName: string,
    isNew: boolean,
    outOfLine: boolean,
    reviewedApproved: boolean,
  ) => void;
  isModal?: boolean;
  alreadyInUseList?: number[];
  onAddIconClick?: () => void;
};

export default function PartsContent({
  isModal,
  alreadyInUseList,
  onTableRowClick,
  onAddIconClick,
  onTablePartEditClick,
}: PartsContentProps) {
  const {
    sortField,
    partsShown,
    inputRef,
    partsShownLength,
    isFetching,
    isSearchInputDirty,
    partsLength,
    theme,
    isSearch,
    isMobile,
    endPageRef,
    isLoadMoreFetching,
    errorMessage,
    partsHasImage,
    nameColWidth,
    isAdmin,
    clearError,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
    handleGeneratePdf,
  } = usePartsContent();

  return (
    <>
      {isModal && (
        <ListingHeaderModal
          title="Peças"
          inputRef={inputRef}
          isSearchInputDirty={isSearchInputDirty}
          onCancelSearch={handleCancelSearch}
          onSearch={handleSearch}
          onButtonClick={handleSearchDatabase}
          onKeyDown={handleEnterClickSearchInput}
        >
          <Table.Head>
            <Box className="flex">
              <Table.Cell
                items={partsShown}
                type="id"
                onSort={handleSortTable}
                isSortActive={sortField === 'id'}
              >
                Código
              </Table.Cell>
              <Table.Cell
                items={partsShown}
                type="name"
                onSort={handleSortTable}
                isSortActive={sortField === 'name'}
              >
                Nome
              </Table.Cell>
            </Box>
          </Table.Head>
        </ListingHeaderModal>
      )}

      {!isModal && (
        <ListingHeader
          backButton
          onPdfClick={handleGeneratePdf}
          add={!!isAdmin}
          onAddIconClick={onAddIconClick}
          pdfSx={{
            '@media(max-width: 760px)': {
              right: '1.5rem',
            },
          }}
        >
          <div className="mt-4">
            <h1 className="mb-4 text-2xl max-md:mx-2">Peças</h1>
            <SerchInput
              ref={inputRef}
              onSearch={handleSearch}
              onButtonClick={handleSearchDatabase}
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
              buttonContent="Buscar"
              onKeyDown={handleEnterClickSearchInput}
            />
          </div>
          <Table.Head>
            <Box className="flex">
              <Table.Cell
                items={partsShown}
                type="id"
                onSort={handleSortTable}
                isSortActive={sortField === 'id'}
              >
                Código
              </Table.Cell>
              <Table.Cell
                items={partsShown}
                type="name"
                onSort={handleSortTable}
                isSortActive={sortField === 'name'}
                style={{
                  width: `${nameColWidth}ch`,
                }}
              >
                Nome
              </Table.Cell>
              {isAdmin && (
                <>
                  <div className="flex gap-8">
                    <Table.CellStatic className="w-24 max-w-36 flex-1 justify-center text-center">
                      Revis. aprov.
                    </Table.CellStatic>
                    <Table.CellStatic className="max-w-10 flex-1 justify-center">
                      Novo
                    </Table.CellStatic>
                    <Table.CellStatic className="w-40 flex-1 justify-center">
                      Fora de linha
                    </Table.CellStatic>
                  </div>
                  <Table.Cell items={partsShown} className="w-40">
                    <div className="w-40 text-center">Imagem indisponível</div>
                  </Table.Cell>
                </>
              )}
            </Box>
          </Table.Head>
        </ListingHeader>
      )}
      <Main isModal={isModal}>
        {isFetching && <Loading loadMore={!isModal} />}
        {!isFetching && (
          <TableRoot
            itemsLength={partsShown.length}
            itemType="Produtos"
            paddingTop="0"
            showNoContentMessage={partsShown.length <= 0 && isSearch}
            showSearchMessage={partsShown.length <= 0 && !isSearch}
            className={`${isModal ? 'mb-32 max-h-[60vh]' : ''}`}
          >
            {partsShown.map((part) => {
              const isInUse = alreadyInUseList?.some(
                (item) => item === part.id,
              );
              return (
                <Table.Row
                  key={part.id}
                  className={isInUse ? 'cursor-default opacity-50' : ''}
                >
                  <div
                    className="flex flex-1"
                    onClick={() => !isInUse && onTableRowClick(part)}
                  >
                    <Table.Cell items={partsShown} type="id">
                      {part.id}
                    </Table.Cell>
                    <Table.Cell
                      items={partsShown}
                      type="name"
                      style={{
                        width: `${nameColWidth}ch`,
                      }}
                    >
                      {part.name}
                    </Table.Cell>
                    {isAdmin && (
                      <div className="flex justify-center gap-8">
                        <Table.CellStatic className="w-[5.5rem] flex-1 items-center justify-center">
                          <div className="w-full text-center">
                            <input
                              readOnly
                              type="checkbox"
                              className="size-4"
                              checked={part.reviewedApproved}
                            />
                          </div>
                        </Table.CellStatic>
                        <Table.CellStatic className="w-9 flex-1 items-center justify-center">
                          <div className="w-full text-center">
                            <input
                              readOnly
                              type="checkbox"
                              className="size-4"
                              checked={part.new}
                            />
                          </div>
                        </Table.CellStatic>
                        <Table.CellStatic className="w-40 flex-1 items-center justify-center">
                          <div className="w-full text-center">
                            <input
                              readOnly
                              type="checkbox"
                              className="size-4"
                              checked={part.outOfLine}
                            />
                          </div>
                        </Table.CellStatic>
                      </div>
                    )}
                    <div className="w-40 text-center">
                      {partsHasImage.some(
                        (partHasImage) =>
                          partHasImage.id === part.id && !partHasImage.hasImage,
                      ) &&
                        isAdmin && (
                          <ImageNotSupportedIcon
                            fontSize="small"
                            className="text-zinc-400"
                          />
                        )}
                    </div>
                  </div>
                  <div className="flex items-center gap-8">
                    {isAdmin && !isModal && !isMobile && (
                      <button
                        className="text-primary"
                        type="button"
                        onClick={() =>
                          onTablePartEditClick &&
                          onTablePartEditClick(
                            part.id,
                            part.name,
                            part.new,
                            part.outOfLine,
                            part.reviewedApproved,
                          )
                        }
                      >
                        Editar
                      </button>
                    )}

                    {isModal && isInUse && (
                      <CheckIcon className="text-primary" />
                    )}
                    {part.isAvailable ? (
                      <Table.Cell items={partsShown}>
                        <Link
                          to={`/part-compatibilities/${part.id}/${encodeURIComponent(part.name)}`}
                          style={{ color: theme.palette.secondary.main }}
                          className="text-sm"
                        >
                          Compatibilidade
                        </Link>
                      </Table.Cell>
                    ) : (
                      !isModal && <div className="w-[7.5rem]"></div>
                    )}
                  </div>
                </Table.Row>
              );
            })}
            <LoadMore
              isModal={!!isModal}
              isLoadMoreFetching={isLoadMoreFetching}
              isContentFetching={isFetching}
              ref={endPageRef}
            />
          </TableRoot>
        )}
      </Main>
      <Footer
        current={partsShownLength}
        total={partsLength}
        itemType="Peças"
        className={
          isModal
            ? 'absolute mx-[1px] mb-[1px] mt-[-10vh] h-28 rounded-b-3xl'
            : ''
        }
        infosStyle={{ mt: isModal ? '-2rem' : '' }}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
