import { pagesMapper } from '../../constants/pages';
import SideNavbarItems from '../SideNavbarItem';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useSideNavbar } from './useSideNavbar';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import { useState } from 'react';

type SideNavbarProps = {
  isOpen: boolean;
  onClickOut: () => void;
};

export default function SideNavbar({ isOpen, onClickOut }: SideNavbarProps) {
  const { userPages, handleLogout } = useSideNavbar();
  const [openMenu, setOpenMenu] = useState<string[]>([]);


  const allowedPages = userPages?.map((p) => p.page.name) ?? [];

  const pagesGroup: Record<string, string[]> = {
    Administrador: ['Domínio', 'Usuários', 'Tipos de usuário', 'Extensão'],
    Documentos: ['Tipos de documentos', 'Documentos', 'PDF'],
    Produto: [
      'Produtos',
      'Peças',
      'Tipos de descrição',
      'Conjunto de peças',
      'Descrição',
      'Descrição de peças e produtos',
      'Categorias',
      'Linhas',
      'Catálogo de Produtos',
    ],
    'E-mails': ['Assinaturas', 'Emails de Disparo'],
    Imagens: ['Imagem', 'Tipos de imagem'],
    Notícias: ['Tipos de Notícias', 'Notícias'],
    Atendimento: ['Fale Conosco', 'Perguntas Frequentes', 'Assuntos'],
    Duimp: ['Duimp', 'Erros Duimp', 'Logs Duimp'],
    Etiquetas: ['Etiquetas NF', 'Código de barras'],
    Outros: ['Dimensionamentos', 'Sincronização', 'Pop-Up'],
  };

  return (
    <div className="flex">
      <aside
        data-isOpen={isOpen}
        className={`transition-medium fixed bottom-0 left-0 top-0 z-40 w-96 bg-headerPrimary pl-4 pt-24 data-[isOpen=false]:-left-96`}
      >
        <nav className="flex h-full flex-col justify-between overflow-y-auto overflow-x-hidden">
          <Accordion
            type="multiple"
            className="flex flex-col gap-4 bg-headerPrimary"
            value={openMenu}
            onValueChange={setOpenMenu}
          >
            {Object.entries(pagesGroup).map(([groupTitle, pageNames]) => {
              const visiblePages = pageNames.filter((name) =>
                allowedPages.includes(name),
              );

              if (!visiblePages.length) return null;

              return (
                <AccordionItem value={groupTitle} key={groupTitle}>
                  <AccordionTrigger className="flex items-center bg-[#343434] text-white">
                    {openMenu.includes(groupTitle) ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                    {groupTitle}
                  </AccordionTrigger>
                  <AccordionContent className="overflow-hidden pl-4 pt-2 transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                    <ul className="flex flex-col gap-4">
                      {visiblePages.map((name) => (
                        <SideNavbarItems
                          key={name}
                          content={name}
                          icon={pagesMapper[name]?.icon}
                          to={pagesMapper[name]?.path}
                        />
                      ))}
                    </ul>
                  </AccordionContent>
                </AccordionItem>
              );
            })}
          </Accordion>
          <button
            onClick={handleLogout}
            className="transition-fast hover my-8 flex gap-2 text-zinc-200 hover:text-white"
          >
            <div className="size-5">
              <ExitToAppIcon />
            </div>
            Sair
          </button>
        </nav>
      </aside>
      {isOpen && (
        <div
          className="fixed bottom-0 left-0 right-0 top-0 h-screen flex-1"
          onClick={onClickOut}
        />
      )}
    </div>
  );
}
