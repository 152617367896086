import { useCallback } from 'react';
import { useSynchronizationStore } from '../../store/synchronization';
import Websocket from '../Websocket';

export default function WebsocketsSynchronization() {
  const {
    actions: { setCurrentSynchronization },
  } = useSynchronizationStore();

  const handleSuccess = useCallback(
    (eventName: string) => {
      setCurrentSynchronization(eventName);
    },
    [setCurrentSynchronization],
  );

  return (
    <>
      <Websocket
        eventName="technical-assistance-synchronization"
        successMessage="Dados da assistência técnica sincronizados com sucesso"
        errorMessage="Falha ao sincronizar os dados da assistência técnica"
        onSuccess={handleSuccess}
      />
      <Websocket
        eventName="representative-synchronization"
        successMessage="Dados dos representantes sincronizados com sucesso"
        errorMessage="Falha ao sincronizar os dados dos representantes"
        onSuccess={handleSuccess}
      />
      <Websocket
        eventName="productsParts-synchronization"
        successMessage="Dados das peças e produtos sincronizados com sucesso"
        errorMessage="Falha ao sincronizar os dados das peças e produtos"
        onSuccess={handleSuccess}
      />
      <Websocket
        eventName="generate-pdf-cache"
        successMessage="PDF gerado com sucesso"
        errorMessage="Falha ao gerar PDF"
        onSuccess={handleSuccess}
      />
    </>
  );
}
