import {
  deleteObject,
  listAll,
  ref,
  uploadBytes,
  uploadBytesResumable,
  UploadResult,
} from 'firebase/storage';
import {
  News,
  NewsDto,
  NewsListInPageSort,
  NewsListInPageSortPaginated,
  SaveNewsListDto,
  UpdateNewsDto,
} from '../types/news';
import { api } from './api';
import { storage } from '../libs/firebase';
import { addSearchParamsInUrl } from '../utils/generatedPaginatedUrl';
import { NewsType } from '../types/newsType';

export const getAllNewsType = async (domainId: number) => {
  const type = await api.get<NewsType[]>(`/news-domain/v1/domain/${domainId}`);

  return type;
};

export const getAllNewsListPaginated = async (dto?: NewsDto) => {
  const url = addSearchParamsInUrl(
    '/news/v1/search',
    { name: 'page', value: dto?.page },
    { name: 'limit', value: dto?.limit },
    { name: 't', value: dto?.type !== 'Todos' ? dto?.type : null },
    { name: 'd', value: dto?.domain !== 'Todos' ? dto?.domain : null },
  );

  const response = await api.get<NewsListInPageSortPaginated>(url);
  return response;
};

export const updateNews = async ({
  id,
  name,
  type,
  url,
  fileType,
  date,
  pdfBook,
}: UpdateNewsDto) => {
  const body: UpdateNewsDto = { id, name, type, url, fileType, pdfBook };

  if (date) {
    body.date = date;
  }

  const response = await api.put<News>('/news/v1', body);
  return response;
};

export const getAllNewsList = async (newsList: string[]) => {
  const newsListResponse = await Promise.all(
    newsList.map((news) => {
      const newsRef = ref(storage, `news/${news}`);
      return listAll(newsRef);
    }),
  );

  const newsListNameList = newsListResponse.map((res, index) =>
    res.items.map((item) => {
      return { name: item.name, type: newsList[index] };
    }),
  );

  return {
    items: newsListNameList.flat(),
    meta: {
      totalItems: newsListNameList.length,
    },
  };
};

export const uploadNewsStorage = (
  newsType: string,
  itemId: string,
  file: File,
) => {
  const storageRef = ref(
    storage,
    `news/${decodeURIComponent(newsType)}/${decodeURIComponent(itemId)}`,
  );
  const response = uploadBytesResumable(storageRef, file);
  return response;
};

export const uploadNewsListStorage = async (
  newsType: string,
  files: FileList,
  saveNews: () => void | Promise<void>,
  fileName?: string | null,
) => {
  try {
    const promises: Promise<UploadResult>[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const storageRef = ref(
        storage,
        `news/${decodeURIComponent(newsType)}/${decodeURIComponent(fileName ?? file.name)}`,
      );
      const response = uploadBytes(storageRef, file);
      promises.push(response);
    }

    await Promise.all(promises);
    await saveNews();
  } catch (error) {
    const promises: Promise<string | undefined>[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const response = deleteNewsStorage(fileName ?? file.name, newsType);
      promises.push(response);
    }

    await Promise.all(promises);

    throw error;
  }
};

export const saveNews = async ({
  name,
  type,
  url,
  fileType,
  date,
  pdfBook,
}: SaveNewsListDto) => {
  const body: SaveNewsListDto = { name, type, url, fileType, pdfBook };

  if (date) {
    body.date = date;
  }

  const response = await api.post<News>('/news/v1', body);
  return response;
};

export const saveNewsList = async (dto: SaveNewsListDto[]) => {
  const body = {
    newsList: dto.map(({ date, ...rest }) => {
      if (date) {
        return { ...rest, date };
      }

      return rest;
    }),
  };

  const response = await api.post<void>('/news/v1/batch', body);
  return response;
};

export const deleteNewsStorage = async (
  newsReference: string,
  newsType: string,
) => {
  const newsRef = ref(
    storage,
    `news/${newsType}/${decodeURIComponent(newsReference)}`,
  );
  try {
    await deleteObject(newsRef);
  } catch (error) {
    return `Falha ao deletar notícia. ${JSON.stringify(error)}`;
  }
};

export const deleteNews = async (newsId: number) => {
  const response = await api.delete(`/news/v1/${newsId}`);
  return response;
};
