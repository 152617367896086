export const formatCPF = (value: string) => {
  if (!value) return value;

  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{3})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return value;
};

export const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  // Remove todos os caracteres não numéricos
  value = value.replace(/\D/g, '');

  if (value.length > 13) {
    return value;
  }

  // Verifica o comprimento para aplicar o formato correto
  if (value.length === 13) {
    const prefix = `+${value.slice(0, -11)} `;

    // Aplica a máscara nos 11 últimos dígitos
    const formattedNumber = value
      .slice(-11)
      .replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');

    return `${prefix}${formattedNumber}`;
  } else if (value.length === 12) {
    const prefix = `+${value.slice(0, -10)} `;

    // Aplica a máscara nos 11 últimos dígitos
    const formattedNumber = value
      .slice(-10)
      .replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');

    return `${prefix}${formattedNumber}`;
  } else if (value.length > 10) {
    // Número com DDD e 9 dígitos (ex: +55 (11) 91234-5678)
    value = value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
  } else if (value.length === 10) {
    // Número com DDD e 8 dígitos (ex: +55 (11) 1234-5678)
    value = value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
  } else if (value.length === 9) {
    // Número sem DDD, com 9 dígitos (ex: 91234-5678)
    value = value.replace(/^(\d{5})(\d{4})$/, '$1-$2');
  } else if (value.length === 8) {
    // Número sem DDD, com 8 dígitos (ex: 1234-5678)
    value = value.replace(/^(\d{4})(\d{4})$/, '$1-$2');
  } else if (value.length > 6) {
    // Formatação intermediária de DDD e parte do número (ex: (11) 91234)
    value = value.replace(/^(\d{2})(\d{1,5})$/, '($1) $2');
  } else if (value.length > 2) {
    // Formatação intermediária com DDD e número parcial (ex: (11) 12)
    value = value.replace(/^(\d{2})(\d{1,4})$/, '($1) $2');
  } else if (value.length > 0) {
    // Apenas o DDD parcial (ex: (11)
    value = value.replace(/^(\d{1,2})$/, '($1');
  }

  return value;
};

export const formatCep = (cep: string) => {
  if (!cep) return cep;

  return cep.replace(/(\d{5})(\d{3})/, '$1-$2');
};

export const capitalizeText = (text: string) => {
  if (!text) return text;

  // Lista de palavras que devem permanecer em minúsculas (exceções)
  const exceptions = ['do', 'de', 'da', 'dos', 'das'];

  return text
    .toLowerCase() // Converte tudo para minúsculas primeiro
    .split(' ') // Divide o texto em palavras
    .map((word, index) => {
      // Verifica se a palavra está nas exceções e não é a primeira palavra
      if (exceptions.includes(word) && index !== 0) {
        return word;
      }
      // Capitaliza a primeira letra e junta com o restante em minúscula
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' '); // Junta tudo de volta em uma única string
};

export const formatDateString = (input: string) => {
  // Limitar a entrada a no máximo 8 caracteres
  const sanitizedInput = input.replaceAll('/', '').slice(0, 8);

  if (sanitizedInput.length <= 2) {
    // Apenas o dia
    return sanitizedInput;
  } else if (sanitizedInput.length <= 4) {
    // Dia e mês
    return `${sanitizedInput.slice(0, 2)}/${sanitizedInput.slice(2)}`;
  } else {
    // Dia, mês e ano
    return `${sanitizedInput.slice(0, 2)}/${sanitizedInput.slice(2, 4)}/${sanitizedInput.slice(4)}`;
  }
};

export const formatOnlyNumber = (value: string) => {
  return value.replace(/\D/g, '');
};

export const decodeHtml = (html: string) => {
  const parser = new DOMParser();
  const decoded = parser.parseFromString(html, 'text/html');
  return decoded.documentElement.textContent || '';
};
