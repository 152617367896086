export default function DescriptionTypeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
      <image
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADGSURBVDhPYxihIDMzUymNQhAfHy8ANY6BITU1dQ4QlwDFQ8nBQL39IP1gw4ACZ4CcOjCHTBAaGsoGchTQrFUg1x2GilMMwGahGcgIDE9BYjHQRfxQfWCAYWBMTAw3UNFuYjFQ71KoVjDA5kKKAO0NzMrK4gF65QyxGKh3C1QrGNDehZQCsFlAp68BMlYAEyczVJxkAMp2QHNAsd4FFgBy2oB4AVCggxwM1LsZSMeCDQMBUCIFCriQi4EGOgNdyQE1bmQBBgYAh1binGADP8QAAAAASUVORK5CYII="
        height="100%"
        width="100%"
        style={{ filter: 'invert(1) brightness(2)' }}
      />
    </svg>
  );
}
