import { useCallback, useRef } from 'react';
import { PdfType } from '../../../types/pdf';
import { useMutation, useQuery } from 'react-query';
import { linesApiCache } from '../../../constants/requestCacheName';
import { getAllLines } from '../../../services/lines';
import { Line, Lines } from '../../../types/lines';
import { useModal } from '../../../hooks/useModal';
import { useHandleTable } from '../../../hooks/useHandleTable';
import { generateLinePdf } from '../../../services/pdf';
import { useError } from '../../../hooks/useError';
import { mutationErrorHandling } from '../../../utils/errorHandling';
import { socket } from '../../../libs/socketIo';
import { useQueryCache } from '../../../hooks/useQueryCache';

export const usePdf = () => {
  const { data: lines } = useQuery<Lines>(
    linesApiCache,
    async () => (await getAllLines()).data,
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const { updateItemOnScreen } = useQueryCache();

  const { errorMessage, clearError, setErrorMessage } = useError();

  const currentLine = useRef<Line | null>(null);

  const { mutate: generateLinePdfMutate } = useMutation({
    mutationFn: async ({ lineId }: Record<'lineId', number>) => {
      return (await generateLinePdf(lineId, socket.id ?? '')).data;
    },

    onSuccess: () => {
      handleCloseConfirmGeneratePdfModal();
    },

    onError: (error) => {
      mutationErrorHandling(error, 'Falha ao gerar PDF', setErrorMessage);
    },
  });

  const {
    itemsShown,
    isSearchInputDirty,
    inputRef,
    sortField,
    itemsLength: linesLength,
    itemsShownLength,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
  } = useHandleTable(lines?.meta.totalItems ?? 0, lines?.items ?? []);
  const linesShown: Line[] = itemsShown;

  const {
    isModalOpen: isManageModalOpen,
    handleCloseModal: closeManageModal,
    handleOpenModal: handleOpenManageModal,
  } = useModal();

  const {
    isModalOpen: isConfirmGeneratePdfModalOpen,
    handleCloseModal: handleCloseConfirmGeneratePdfModal,
    handleOpenModal: handleOpenConfirmGeneratePdfModal,
  } = useModal();

  const handleCloseManageModal = useCallback(() => {
    closeManageModal();
  }, [closeManageModal]);

  const handleTableRowClick = useCallback(
    (pdfType: PdfType) => {
      if (pdfType.name === 'Linha') {
        handleOpenManageModal();
      }
    },
    [handleOpenManageModal],
  );

  const handleGenerateLinePdf = (line: Line) => {
    currentLine.current = line;
    handleOpenConfirmGeneratePdfModal();
  };

  const handleConfirmGeneratePdf = () => {
    if (!currentLine.current) return;

    generateLinePdfMutate({ lineId: currentLine.current?.id });

    const updatedLine = lines?.items.find(
      (line) => line.id === currentLine.current?.id,
    );

    if (updatedLine) {
      updatedLine.pdfStatus = 'PENDENTE';
      updateItemOnScreen<Lines>(linesApiCache, updatedLine);
    }
  };

  return {
    linesShown,
    isManageModalOpen,
    isSearchInputDirty,
    inputRef,
    sortField,
    linesLength,
    errorMessage,
    currentLine: currentLine.current,
    isConfirmGeneratePdfModalOpen,
    itemsShownLength,
    handleCloseConfirmGeneratePdfModal,
    handleConfirmGeneratePdf,
    clearError,
    handleSearch,
    handleSortTable,
    handleCancelSearch,
    handleGenerateLinePdf,
    handleCloseManageModal,
    handleTableRowClick,
  };
};
