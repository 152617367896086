import { LogLaunch } from '../../types/productDuimp';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import Loading from '../Loading';
import Main from '../Main';
import Table from '../Table';
import { useLogsDuimpContent } from './useLogsDuimpContent';

type LogsDuimpContentProps = {
  isModal?: boolean;
  onTableRowClick: (logs: LogLaunch) => void;
};

export default function LogsDuimpContent({
  isModal,
  onTableRowClick,
}: LogsDuimpContentProps) {
  const {
    navigate,
    isFetching,
    logsLaunchListDuimpShown,
    logsLaunchListDuimp,
    logsLaunchListDuimpLength,
    logsLaunchListDuimpShownLength,
  } = useLogsDuimpContent();

  return (
    <>
      {!isModal && (
        <ListingHeader pdfTop="3rem" pdf={false}>
          <div className="my-4">
            <div className="flex items-start justify-between">
              <h1 className="mb-4 text-2xl">Logs de Lançamento</h1>
              <div className="flex flex-col items-start">
                <button
                  onClick={() => navigate('/admin/duimp')}
                  className="text-base text-primary"
                >
                  Retornar aos lançamentos
                </button>
              </div>
            </div>
          </div>

          <Table.Head>
          <Table.Cell items={logsLaunchListDuimpShown} className="w-1/6">
              Data do Lançamento
            </Table.Cell>
            <Table.Cell items={logsLaunchListDuimpShown} className="w-1/6">
              Total Produtos com Sucesso
            </Table.Cell>
            <Table.Cell items={logsLaunchListDuimpShown} className="w-1/6">
              Total Produtos com Erro
            </Table.Cell>
            <Table.Cell items={logsLaunchListDuimpShown} className="w-1/6">
              Total Fabricantes com Sucesso
            </Table.Cell>
            <Table.Cell items={logsLaunchListDuimpShown} className="w-1/6">
              Total Fabricantes com Erro
            </Table.Cell>

          </Table.Head>
        </ListingHeader>
      )}
      <Main isModal={isModal}>
        {isFetching && <Loading />}
        {!isFetching ? (
          logsLaunchListDuimp ? (
            <Table.Root
              itemType="produtosDuimp"
              itemsLength={logsLaunchListDuimpLength}
              className={`${isModal ? 'max-h-[60vh]' : ''}`}
              paddingTop="0"
            >
              {logsLaunchListDuimpShown?.map((log) => {
                return (
                  <Table.Row key={log.id} onClick={() => onTableRowClick(log)}>

                    <Table.Cell
                      items={logsLaunchListDuimpShown}
                      type="dtLancamento"
                      className="w-1/6"
                    >
                      {log.dtLancamento}
                    </Table.Cell>
                    <Table.Cell
                      items={logsLaunchListDuimpShown}
                      type="totalProdutosComSucesso"
                      className="w-1/6"
                    >
                      {log.totalProdutosComSucesso}
                    </Table.Cell>
                    <Table.Cell
                      items={logsLaunchListDuimpShown}
                      type="totalProdutosComErro"
                      className="w-1/6"
                    >
                      {log.totalProdutosComErro}
                    </Table.Cell>
                    <Table.Cell
                      items={logsLaunchListDuimpShown}
                      type="totalFabricantesComSucesso"
                      className="w-1/6"
                    >
                      {log.totalFabricantesComSucesso}
                    </Table.Cell>
                    <Table.Cell
                      items={logsLaunchListDuimpShown}
                      type="totalFabricantesComErro"
                      className="w-1/6"
                    >
                      {log.totalFabricantesComErro}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Root>
          ) : (
            <div className="flex justify-center p-8">
              Nenhum log foi encontrado
            </div>
          )
        ) : (
          <></>
        )}
      </Main>

      <Footer
        className={`${isModal ? 'mb-1 ml-[5vw] w-[90vw]' : ''}`}
        current={logsLaunchListDuimpShownLength}
        total={logsLaunchListDuimpLength}
        itemType="Logs"
      />
    </>
  );
}
