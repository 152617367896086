import { useQuery } from 'react-query';
import { descriptionTypesApiCache } from '../../constants/requestCacheName';
import { useHandleTable } from '../../hooks/useHandleTable';
import { Description } from '../../types/description';
import { Service } from '.';
import formatSearch from '../../utils/formatSearch';
import { useCallback, useEffect, useMemo } from 'react';
import { getTableColumnWidthByBiggestElement } from '../../utils/tableColumnStyles';
import { getAllDescriptionTypes } from '../../services/descriptionTypes';
import { FindAllDescriptionTypes } from '../../types/descriptionTypes';
import { useSearchParams } from '../../hooks/useSearchParams';
import { useNavigate } from 'react-router-dom';
import { Search, search as searchDatabase } from '../../services/search';

export const useDescriptionContent = (
  baseUrl: string,
  service: Service,
  souldFilter: boolean,
) => {
  const [descriptionTypeFilter] = useSearchParams('descriptionType') as [
    string,
  ];

  const navigate = useNavigate();

  const { data: descriptionTypes } = useQuery<FindAllDescriptionTypes>(
    descriptionTypesApiCache,
    async () => (await getAllDescriptionTypes()).data,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: souldFilter,
    },
  );

  // const {
  //   data: descriptions,
  //   isFetching: isFetchingDescriptions,
  //   refetch: refetchDescriptionsPaginated,
  // } = useQuery<DescriptionsPaginated>(
  //   descriptionApiCache,
  //   async () => (await service(currentPage + 1)).data,
  //   {
  //     retry: false,
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //   },
  // );

  const doSearch = useCallback(
    (baseUrl: string, searchValue: string, page: number) => {
      return searchDatabase<Description>(
        baseUrl,
        searchValue,
        page,
        null,
        null,
        [
          {
            name: 'descriptionType',
            value:
              descriptionTypeFilter !== 'Todos' ? descriptionTypeFilter : '',
          },
        ],
      );
    },
    [descriptionTypeFilter],
  );

  const loadMore = useCallback(
    (page: number, direction?: 'ASC' | 'DESC', limit?: number) => {
      return service(page, direction, limit, [
        {
          name: 'descriptionType',
          value: descriptionTypeFilter !== 'Todos' ? descriptionTypeFilter : '',
        },
        {
          name: 's',
          value: inputRef.current?.value ?? '',
        },
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [descriptionTypeFilter, service],
  );

  const {
    currentPage,
    sortField,
    inputRef,
    pagination,
    isSearchInputDirty,
    searchItems: descriptions,
    itemsLength: descriptionsLength,
    itemsShownLength: descriptionsShownLength,
    itemsShown,
    isSearchFetching,
    isSearch,
    endPageRef,
    isLoadMoreFetching,
    originalSearchItems,
    resetLoadMoreCurrentPage,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch: search,
    refetchSearch,
    handleChangePage,
    handleChangePagination,
    handleSortTable,
    handleCancelSearch,
  } = useHandleTable(
    0,
    [],
    null,
    null,
    baseUrl,
    loadMore,
    null,
    doSearch as Search,
  );

  useEffect(() => {
    resetLoadMoreCurrentPage();
    refetchSearch();
  }, [descriptionTypeFilter, refetchSearch, resetLoadMoreCurrentPage]);
  const descriptionsShown: Description[] = itemsShown;

  const isFetching = isSearchFetching;

  const handleSearch = () => {
    search<Description>(
      (item, formatedSearch) =>
        formatSearch(item.name).includes(formatedSearch) ||
        formatSearch(item.descriptionType.name).includes(formatedSearch),
    );
  };

  const descriptionTypeColWidth = useMemo(
    () =>
      getTableColumnWidthByBiggestElement<Description>(
        originalSearchItems ?? [],
        'descriptionType.name',
        10,
      ),
    [originalSearchItems],
  );

  const handleFilterByDescriptionType = useCallback(
    (filterName: string) => {
      navigate({ search: `descriptionType=${filterName}` }, { replace: true });
    },
    [navigate],
  );

  return {
    sortField,
    inputRef,
    isSearchInputDirty,
    descriptionsLength,
    descriptionsShownLength,
    isFetching,
    descriptionsShown,
    descriptions,
    pagination,
    currentPage,
    isSearch,
    endPageRef,
    isLoadMoreFetching,
    descriptionTypeColWidth,
    descriptionTypes,
    descriptionTypeFilter,
    handleFilterByDescriptionType,
    handleEnterClickSearchInput,
    handleSearchDatabase,
    handleSearch,
    handleChangePage,
    handleChangePagination,
    handleSortTable,
    handleCancelSearch,
  };
};
