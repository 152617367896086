import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ComponentProps } from 'react';

type RemoveButtonProps = ComponentProps<'div'>;

export default function RemoveButton({ ...rest }: RemoveButtonProps) {
  return (
    <span {...rest}>
      <RemoveCircleIcon className="cursor-pointer text-red-500" />
    </span>
  );
}
