import Alert from '../../../components/Alert';
import ConfirmModal from '../../../components/ConfirmModal';
import HeaderModalManageItem from '../../../components/HeaderModalManageItem';
import Input from '../../../components/Input';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import PreviewLabel from '../../../components/PreviewImageLabel';
import SizingContent from '../../../components/SizingContent';
import { useUploadSizing } from './useUploadSizing';
import FileUploadIcon from '@mui/icons-material/FileUpload';

export default function Sizing() {
  const {
    isMenageModalOpen,
    sizingNameUpdate,
    isConfirmDeleteModalOpen,
    previewSizing,
    dirtyFields,
    reference,
    errors,
    screenHeight,
    errorMessage,
    isSizingLoading,
    isSizingDeleteLoading,
    isShowEditButton,
    sizingIdUpdate,
    files,
    handleEdit,
    handleConfirmDeleteSizing,
    handleCloseConfirmDeleteModal,
    clearError,
    handleCancelIconClick,
    handleSubmit,
    register,
    handleButtonOkClick,
    handleDeleteButtonClick,
    handleCloseMenageModal,
    handleTableRowClick,
    handleAddImageClick,
  } = useUploadSizing();

  return (
    <>
      <SizingContent
        onTableRowClick={handleTableRowClick}
        onAddSizingClick={handleAddImageClick}
      />
      <Modal
        isOpen={isMenageModalOpen}
        onClose={handleCloseMenageModal}
        cancelIcon={false}
        height="80vh"
        contentStyle="max-h-[850px]"
      >
        <HeaderModalManageItem
          showDeleteButton={!!sizingIdUpdate}
          deleteButtonContent={
            isSizingDeleteLoading ? (
              <div>
                <Loading />
              </div>
            ) : (
              'Apagar dimensionamento'
            )
          }
          isEditable={!isShowEditButton}
          onDeleteButtonClick={
            !isSizingDeleteLoading ? handleDeleteButtonClick : undefined
          }
          title={sizingNameUpdate ? 'Dimensionamento' : 'Novo dimensionamento'}
          mainButtonContent={
            isSizingLoading ? (
              <div className="absolute bottom-6 right-4 top-3">
                <Loading />
              </div>
            ) : isShowEditButton ? (
              'Editar'
            ) : (
              'Ok'
            )
          }
          onEditClick={isShowEditButton ? handleEdit : undefined}
          onCancelClick={handleCloseMenageModal}
          onOkClick={
            !isSizingLoading ? handleSubmit(handleButtonOkClick) : undefined
          }
        />

        <div className="bottom-14 mt-14 flex flex-col gap-8">
          <Input
            maxLength={44}
            isDirty={dirtyFields.reference}
            type="text"
            id="reference"
            label="Nome do dimensionamento"
            disabled={isShowEditButton || files?.length > 1}
            helperText={errors.reference?.message}
            onCancelIconClick={handleCancelIconClick}
            {...register('reference')}
            value={reference}
          />

          <div className="max-h-[500px] min-h-[300px]">
            <PreviewLabel htmlFor="input-file">
              {(files?.length <= 1 || !files) && (
                <>
                  {previewSizing ? (
                    <div className="h-full w-full">
                      <iframe
                        src={previewSizing}
                        title="PDF Preview"
                        className="h-full w-full"
                      />
                    </div>
                  ) : (
                    <FileUploadIcon
                      fontSize="large"
                      className="absolute cursor-pointer text-secondaryText"
                    />
                  )}
                </>
              )}

              {files?.length > 1 && (
                <ul className="flex size-full max-h-[300px] flex-col gap-4 overflow-auto px-4">
                  {files.map((file) => (
                    <li key={file.name}>{file.name}</li>
                  ))}
                </ul>
              )}

              <input
                type="file"
                id="input-file"
                accept="application/pdf"
                multiple
                {...register('sizing')}
                disabled={isShowEditButton}
                className="absolute flex cursor-pointer opacity-0 enabled:h-[350px] enabled:w-full"
              />
            </PreviewLabel>
            <span className="text-xs text-red-600">
              {errors.sizing?.message}
            </span>
          </div>
        </div>
      </Modal>

      <ConfirmModal
        contentMessage="Deseja excluir esse dimensionamento?"
        isModalOpen={isConfirmDeleteModalOpen}
        onCloseModal={handleCloseConfirmDeleteModal}
        onConfirm={handleConfirmDeleteSizing}
        onNotConfirm={handleCloseConfirmDeleteModal}
      />

      <Alert
        content={errorMessage}
        isOpen={!!errorMessage}
        onClose={clearError}
      />
    </>
  );
}
