import { Box } from '@mui/material';
import { useDomainImageView } from './useDomainImageView';
import { Layer, Rect, Stage } from 'react-konva';
import { explodedViewContainerStyle } from './styles';
import RemoveButton from '../RemoveButton';
import AddButton from '../AddButton';
import Input from '../Input';
import Editor from '../Editor';
import EditorMenu from '../Editor/EditorMenu';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Checkbox from '../Checkbox';

type DomainImageViewProps = {
  canEdit: boolean;
  // imageName?: string;
  currentFieldHoverId: number | null;
  onFieldMouseEnter: (fieldId: number) => void;
  onFieldMouseOut: () => void;
  // onFieldClick: (fieldId: number) => void;
};

export default function DomainImageView({
  canEdit,
  // imageName = '',
  currentFieldHoverId,
  onFieldMouseEnter,
  onFieldMouseOut,
  // onFieldClick,
}: DomainImageViewProps) {
  const {
    editorPreView,
    fieldValue,
    size,
    canAddCoordinate,
    isExplodedViewImageError,
    stageRef,
    explodedViewReduction,
    currentFieldClicked,
    rectanglesWithFieldsHover,
    currentRectangleIdHover,
    currentMouseCoordinates,
    rectangles,
    currentCoordinateIdToDelete,
    domain,
    isFirstEditorSet,
    handleClickField,
    handleCheckboxClick,
    handleChangeInputSize,
    handleSomeEditorMenuButtonClick,
    handleExplodedViewImageError,
    handlePointerDown,
    handlePointerUp,
    handlePointerMove,
    handleInputMouseEnter,
    handleRemoveCoordinate,
    handleChangeCoordinateInput,
    handleDeleteCoordinate,
    handleClickAddCoordinate,
  } = useDomainImageView(
    canEdit,
    // imageName,
    onFieldMouseEnter,
    onFieldMouseOut,
  );

  return (
    <>
      <Box component="section" sx={explodedViewContainerStyle}>
        <h2 className="bg-white text-zinc-500 xl:z-10 xl:w-[600px]">
          Imagem da Assinatura
        </h2>
        <div
          className={`relative mr-[5rem] flex h-[209px] w-[640px] justify-center max-sm:h-[150px] max-sm:w-[300px]`}
        >
          {!isExplodedViewImageError && domain && (
            <img
              src={domain.emailSignatureImage}
              alt={`Imagem da assinatura de email`}
              onError={handleExplodedViewImageError}
              width={640}
              height={209}
              className={`${canAddCoordinate && 'border border-primary'}`}
            />
          )}

          {isExplodedViewImageError && (
            <div className="mt-4">
              <img
                src="/images/unavailable.png"
                alt="Imagem da assinatura indisponível"
                width={640}
                height={209}
              />
            </div>
          )}

          <div
            className="absolute left-0 top-0"
            style={{
              width: 640 * explodedViewReduction,
              height: 209 * explodedViewReduction,
            }}
          >
            {rectanglesWithFieldsHover.map((rectangle) => {
              const fieldValueForRectangle = fieldValue.find(
                (field) =>
                  field.fieldId === rectangle.fieldId &&
                  field.domainId === rectangle.domainId,
              );

              if (!rectangle.isVisible) return null;

              return (
                <div
                  key={`${rectangle.fieldId}${rectangle.domainId}`}
                  className="absolute"
                  style={{
                    textAlign: fieldValueForRectangle?.textAlign,
                    width: rectangle.width * explodedViewReduction,
                    height: rectangle.height * explodedViewReduction,
                    left: rectangle.x,
                    top: rectangle.y,
                    verticalAlign: 'top',
                  }}
                >
                  {fieldValueForRectangle?.text === 'QRCode' && (
                    <QrCode2Icon
                      style={{ fontSize: fieldValueForRectangle.fontSize }}
                    />
                  )}

                  {fieldValueForRectangle?.text !== 'QRCode' && (
                    <span
                      style={{
                        color: fieldValueForRectangle?.color,
                        fontSize: fieldValueForRectangle?.fontSize,
                        fontStyle: fieldValueForRectangle?.fontStyle,
                        fontWeight: fieldValueForRectangle?.bold,
                        textDecoration: fieldValueForRectangle?.textDecoration,
                        verticalAlign: 'top',
                      }}
                    >
                      {fieldValueForRectangle?.text}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
          <Stage
            ref={stageRef}
            width={640 * explodedViewReduction}
            height={209 * explodedViewReduction}
            className={`absolute left-0 top-0`}
            onPointerDown={handlePointerDown}
            onPointerMove={handlePointerMove}
            onPointerUp={handlePointerUp}
            onMouseLeave={handlePointerUp}
          >
            <Layer
              width={640 * explodedViewReduction}
              height={300 * explodedViewReduction}
            >
              {rectanglesWithFieldsHover.map((rectangle) => (
                <Rect
                  key={`${rectangle.fieldId}${rectangle.domainId}`}
                  x={rectangle.x}
                  y={rectangle.y}
                  width={rectangle.width}
                  height={rectangle.height}
                  fill={`${currentRectangleIdHover === rectangle.id ? '#333' : '#42a0dd'}`}
                  opacity={
                    rectangle.fieldId === currentFieldHoverId ||
                    rectangle.fieldId === currentFieldClicked?.field.id
                      ? 0.5
                      : 0
                  }
                  onMouseOut={onFieldMouseOut}
                  onMouseEnter={() => onFieldMouseEnter(rectangle.fieldId)}
                  onClick={() => handleClickField(rectangle.fieldId)}
                />
              ))}
            </Layer>
          </Stage>
        </div>
        {currentFieldClicked && canEdit && (
          <div className="w-[640px] text-left">
            <div className="flex w-28 gap-4 text-left">
              <span className="text-sm">x: {currentMouseCoordinates?.x}</span>
              <span className="text-sm">y: {currentMouseCoordinates?.y}</span>
            </div>
            <div className="mt-10">
              <div className="max-h-72 overflow-y-auto overflow-x-hidden pr-2">
                {rectangles ? (
                  rectangles.map((rectangle) => (
                    <div key={`${rectangle.fieldId}${rectangle.domainId}`}>
                      <h2 className="mb-4 text-zinc-500">Coordenadas</h2>
                      <div
                        onMouseEnter={() => handleInputMouseEnter(rectangle.id)}
                        onMouseOut={() => handleInputMouseEnter('')}
                        className="group mb-1 flex cursor-pointer hover:border-primary"
                      >
                        <RemoveButton
                          className="flex items-center"
                          onClick={() => handleRemoveCoordinate(rectangle.id)}
                        />
                        <div className="flex flex-1 justify-between border-b border-zinc-300 py-1 group-hover:border-primary">
                          <div className="flex items-center">
                            <div className="item-center flex">
                              <label
                                htmlFor="x"
                                className="mx-2 flex items-center text-xs font-bold"
                              >
                                X
                              </label>
                              <Input
                                onChange={(event) =>
                                  handleChangeCoordinateInput(
                                    event,
                                    rectangle.id,
                                  )
                                }
                                type="number"
                                name="x"
                                className="w-14 rounded border border-zinc-400 pl-2"
                                value={rectangle.x}
                              />
                            </div>
                            <div className="item-center flex">
                              <label
                                htmlFor="y"
                                className="mx-2 flex items-center text-xs font-bold"
                              >
                                Y
                              </label>
                              <Input
                                onChange={(event) =>
                                  handleChangeCoordinateInput(
                                    event,
                                    rectangle.id,
                                  )
                                }
                                type="number"
                                name="y"
                                className="w-14 rounded border border-zinc-400 pl-2"
                                value={rectangle.y}
                              />
                            </div>
                          </div>
                          <div className="flex">
                            <div className="item-center flex">
                              <label
                                htmlFor="height"
                                className="mx-2 flex items-center text-xs font-bold"
                              >
                                Altura
                              </label>
                              <Input
                                onChange={(event) =>
                                  handleChangeCoordinateInput(
                                    event,
                                    rectangle.id,
                                  )
                                }
                                type="number"
                                name="height"
                                className="w-14 rounded border border-zinc-400 pl-2"
                                value={rectangle.height}
                              />
                            </div>
                            <div className="flex items-center">
                              <label
                                htmlFor="width"
                                className="mx-2 flex items-center text-xs font-bold"
                              >
                                Largura
                              </label>
                              <Input
                                onChange={(event) =>
                                  handleChangeCoordinateInput(
                                    event,
                                    rectangle.id,
                                  )
                                }
                                type="number"
                                name="width"
                                className="w-14 rounded border border-zinc-400 pl-2"
                                value={rectangle.width}
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          onClick={() => handleDeleteCoordinate(rectangle.id)}
                          className={`transition-slow border-[1px] border-errorText bg-errorText text-zinc-100 ${currentCoordinateIdToDelete === rectangle.id ? 'ml-2 w-20 opacity-100' : 'w-0 opacity-0'}`}
                        >
                          Apagar
                        </button>
                      </div>
                      <div className="mt-4 flex flex-col items-start justify-center">
                        {rectangle.fieldId !== 7 && (
                          <h2 className="text-zinc-500">Formatação</h2>
                        )}

                        <div
                          data-qrcode={rectangle.fieldId === 7}
                          className="mb-4 flex w-full items-center justify-between data-[qrcode=true]:justify-end"
                        >
                          {rectangle.fieldId !== 7 && (
                            <div className="flex">
                              <Input
                                type="number"
                                value={size.toString()}
                                // max={Math.min(rectangle.height, rectangle.width)}
                                onChange={(e) => {
                                  handleChangeInputSize(
                                    e.target.value,
                                    rectangle.height,
                                    rectangle.width,
                                  );
                                }}
                                className="h-full w-24 rounded-md border border-gray-300 bg-white px-3 py-2 text-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                style={{
                                  appearance: 'none', // Remove a aparência padrão do input
                                  WebkitAppearance: 'none', // No Chrome/Safari
                                  MozAppearance: 'none', // No Firefox
                                }}
                              />
                              {rectangle.fieldId !== 7 && (
                                <EditorMenu
                                  showColorBox
                                  showTextAlign
                                  editor={editorPreView}
                                  onButtonClick={(props) => {
                                    isFirstEditorSet.current = false;

                                    handleSomeEditorMenuButtonClick(props);
                                  }}
                                />
                              )}
                            </div>
                          )}

                          <div className="w-fit">
                            <Checkbox
                              label="Visível"
                              className="size-4"
                              checked={rectangle.isVisible}
                              onClick={() =>
                                handleCheckboxClick(rectangle.fieldId)
                              }
                              labelClickable
                            />
                          </div>
                        </div>
                        {rectangle.fieldId !== 7 && (
                          <>
                            <span className="text-zinc-500">
                              Pré-visualização
                            </span>
                            <div className="flex w-full items-start justify-start gap-5">
                              <Editor
                                editor={editorPreView}
                                className="w-full"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <AddButton
                    content="Adicionar coordenada"
                    onClick={handleClickAddCoordinate}
                    className="mt-2"
                  />
                )}
              </div>

              {rectangles.length === 0 && (
                <AddButton
                  content="Adicionar coordenada"
                  onClick={handleClickAddCoordinate}
                  className="mt-2"
                />
              )}
            </div>
          </div>
        )}
      </Box>
    </>
  );
}
