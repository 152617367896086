import { IconButton, useMediaQuery } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';
import Button from '../Button';
import { DownloadIcon } from 'lucide-react';
import ToolTipCustom from '../ToolTip';

type PdfButtonProps = ComponentProps<'div'> & {
  isPdfLoading: boolean;
  onPdfClick?: () => void;
  fontSize?: number;
};

export default function PdfButton({
  isPdfLoading,
  onPdfClick,
  className,
  fontSize,
  ...rest
}: PdfButtonProps) {
  const isMobile = useMediaQuery('(max-width: 640px)');

  return (
    <div
      data-isMobile={isMobile}
      className={twMerge(
        // 'flex items-center justify-end data-[isMobile=true]:mb-4',
        'flex items-center justify-end',
        className,
      )}
      {...rest}
    >
      {isPdfLoading && <ClipLoader size={25} className="mr-1" />}
      <ToolTipCustom title="Download pdf" placement="top">
        {/* <IconButton > */}
        {/* <Button isMobile={isMobile} onClick={onPdfClick}> */}
        <div
          className={`flex`}
          style={{ width: `${fontSize}rem` }}
          onClick={onPdfClick}
        >
          {/* {!isMobile && (
            <img
            src={DocumentIcon}
            alt="Document Icon"
            style={{ marginRight: '8px' }}
            />
            )} */}
          {/* <span>PDF</span> */}
          <DownloadIcon className="text-black hover:text-[#eab308]" />
        </div>
        {/* </Button> */}
        {/* </IconButton> */}
      </ToolTipCustom>
    </div>
  );
}
