import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import html2canvas from 'html2canvas';
import { LabelItem } from '../../types/labelNF';

const generateLabelNFPdf = async (
  labels: LabelItem[],
  startIndex: number,
  onError: (errorMessage: string) => void,
) => {
  pdfMake.vfs = pdfFonts.vfs;
  const labelSize = {
    columns: 2,
    // row: 9,
    width: 267,
    height: 155,
    // fontSize: 8,
    pageMarginTop: 10,
    marginLeft: 10,
    columnGap: 50,
    gap: 2.2,
  } as const;

  const chunkArray = (array: Content[], size: number) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const barcodeImages = await Promise.all(
    labels.map(async (itemSelected) => {
      const element = document.getElementById(itemSelected.labelAccessKey)!;

      const canvas = await html2canvas(element);
      const imageData = canvas.toDataURL('image/png');

      return {
        image: imageData,
        width: labelSize.width,
        height: labelSize.height,
        marginTop: labelSize.gap,
      } as Content;
    }),
  );

  const totalItemsSelectedInArray = labels.flatMap((itemSelected, index) =>
    Array.from({ length: itemSelected.labelAmount }, () => ({
      ...itemSelected,
      index,
    })),
  );

  const totalLabelsInArray = totalItemsSelectedInArray.map(
    (itemSelected) =>
      [
        {
          ...(barcodeImages[itemSelected.index] as any),
        },
      ] as Content,
  );

  for (let i = 0; i < startIndex; i++) {
    totalLabelsInArray.unshift([
      {
        ...(barcodeImages[0] as any),
        opacity: 0,
      },
    ] as Content);
  }

  const dividedArrays = chunkArray(totalLabelsInArray, 2);

  const lastArryaItem = dividedArrays.at(-1);

  if (lastArryaItem && lastArryaItem.length === 1) {
    dividedArrays.at(-1)?.push({
      text: '',
      margin: [0, 0, 0, 0],
    });
  }

  const pdfDefinitions: TDocumentDefinitions = {
    // pageSize: {
    //   width: 630,
    //   height: 842,
    // },
    pageSize: 'A4',

    pageMargins: [13, labelSize.pageMarginTop, 0, 0],

    content: [
      {
        table: {
          widths: Array.from(
            { length: labelSize.columns },
            () => labelSize.width,
          ),
          body: dividedArrays,
        },
        layout: {
          defaultBorder: false,
          paddingLeft: (i, node) => labelSize.marginLeft,
        },
      },
    ],
  };

  pdfMake.createPdf(pdfDefinitions).getBlob((blob) => {
    const url = URL.createObjectURL(blob);

    const pdf = window.open(url, '_blank');

    if (!pdf) {
      onError(
        'O navegador bloqueou o PDF. Desative a opção Bloquear Pop-ups e tente gerar novamente',
      );
    }
  });
};

export default generateLabelNFPdf;
