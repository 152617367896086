import { format } from 'date-fns';
import {
  DeletePackingListDto,
  FindAllPackingList,
  FinishPackingListDto,
} from '../types/packingList';
import { api } from './api';

export const getAllPackingListByCarrierAndDate = async (
  carrierId: number,
  date: Date,
) => {
  const response = await api.get<FindAllPackingList>(
    `/packing/v1/idCarrier/${carrierId}/departureDate/${format(date, 'yyyy-MM-dd')}`,
  );
  return response;
};

export const finishPackingList = async (dto: FinishPackingListDto[]) => {
  const body = {
    items: dto.map(({ id }) => ({ id })),
  };

  const response = await api.put<void>(`/packing/v1/finish-packing`, body);

  return response;
};

export const deletePackingList = async (dto: DeletePackingListDto[]) => {
  const body = {
    items: dto.map(({ id }) => ({ id })),
  };

  const response = await api.delete<void>(`/packing/v1`, {
    data: body,
  });

  return response;
};
