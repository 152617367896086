import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';

import Logo from '../../assets/images/LogoPreta.png';
import LogoBuffalo from '../../assets/images/BuffaloMotoresAcompladosLogoAmarela.png';
import LogoBuffaloRental from '../../assets/images/BuffaloMotoresAcompladosLogoRental.png';

export default function HomeInicial() {
  return (
    <>
      <div className="adjust-content-bf">
        <div>
          <div>
            <div className="flex w-full justify-center bg-yellow-500 p-5 text-center">
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
          </div>
          <div className="mx-4 pb-8 md:mx-11">
            <div className="mt-5 flex flex-col justify-center text-center">
              <h4 className="text-xl font-semibold md:text-2xl">
                Conheça nossas soluções em máquinas e ferramentas
                <span className="text-3xl text-yellow-500 md:text-4xl">.</span>
              </h4>
            </div>
            <div className="flex flex-col pt-5 md:flex-row md:justify-center md:gap-5">
              <div className="flex flex-col">
                <div className="flex w-full justify-center p-5 text-center md:p-10">
                  <Link to="/home">
                    <img src={LogoBuffalo} alt="Logo Buffalo" />
                  </Link>
                </div>
                <div className="mt-5 flex h-1/6 w-full items-center justify-center text-center">
                  <Link
                    to="/home"
                    className="w-2/3 rounded-lg bg-yellow-500 p-2 font-medium text-black md:w-1/3 md:p-4"
                  >
                    <button className="text-center">VER MAIS</button>
                  </Link>
                </div>
              </div>

              <div className="flex flex-col">
                <Link
                  to={process.env.REACT_APP_BUFFALO_RENTAL_BASE_URL ?? ''}
                  className="flex w-full justify-center p-5 text-center md:p-10"
                >
                  <img src={LogoBuffaloRental} alt="Logo Buffalo Rental" />
                </Link>

                <div className="mb-5 mt-5 flex h-1/6 w-full items-center justify-center text-center md:mb-0">
                  <Link
                    to={process.env.REACT_APP_BUFFALO_RENTAL_BASE_URL ?? ''}
                    className="w-2/3 rounded-lg bg-black p-2 font-medium text-white md:w-1/3 md:p-4"
                  >
                    <button>VER MAIS</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-0 w-full [@media(min-width:1100px)]:absolute">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
