import ListingHeader from '../ListingHeader';
import Table from '../Table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Input from '../Input';
import { LabelItem } from '../../types/labelNF';

type LabelNFListingProps = {
  labels: LabelItem[];
  handleRemoveAllLabels: () => void;
  handleRemoveLabel: (labelAccessKey: string) => void;
  handleChangeLabelsAmount: (labelAccessKey: string, amount: string) => void;
};

export default function LabelNFListing({
  labels,
  handleRemoveAllLabels,
  handleRemoveLabel,
  handleChangeLabelsAmount,
}: LabelNFListingProps) {
  return (
    <section className="col-span-2 w-full 2xl:mt-[6.55rem]">
      <h2 className="text-sm font-bold">Relação de etiquetas a imprimir</h2>
      <ListingHeader
        pdf={false}
        backButton={false}
        className="pl-0"
        showBackground={false}
      >
        <Table.Head rowStyle="">
          <div className="flex w-full">
            <Table.Cell className="w-full max-w-[25rem]">
              Transportadora
            </Table.Cell>
            <Table.Cell className="w-full max-w-32">NF</Table.Cell>
            <Table.Cell className="w-full max-w-44">Nº de volumes</Table.Cell>
            <Table.Cell className="w-full max-w-48">
              <EditIcon fontSize="small" className="mr-1" />
              Qtd etiquetas
            </Table.Cell>
            <Table.Cell className="mr-5">
              <DeleteIcon fontSize="small" onClick={handleRemoveAllLabels} />
            </Table.Cell>
            <Table.Cell className="w-[15rem] flex-1">Responsável</Table.Cell>
          </div>
        </Table.Head>
      </ListingHeader>

      <div className="mr-8">
        <Table.Root
          itemType=""
          showNoContentMessage={false}
          itemsLength={labels.length}
          className="overflow-y-auto"
        >
          {labels.map((label) => {
            return (
              <div key={label.id}>
                <Table.Row>
                  <div className="flex w-full">
                    <Table.CellStatic className="w-full max-w-[25rem]">
                      {label.transp.transporta?.xNome._text ?? 'Cliente Retira'}
                    </Table.CellStatic>
                    <Table.CellStatic className="w-full !max-w-32">
                      {label.ide.cNF._text}
                    </Table.CellStatic>
                    <Table.CellStatic className="w-full max-w-44">
                      {label.transp.vol.qVol._text}
                    </Table.CellStatic>
                    <Table.CellStatic className="w-full max-w-48">
                      <Input
                        onChange={(event) =>
                          handleChangeLabelsAmount(
                            label.labelAccessKey,
                            event.target.value,
                          )
                        }
                        type="text"
                        value={label.labelAmount}
                        className="border-none"
                      />
                    </Table.CellStatic>
                    <Table.CellStatic className="mr-5">
                      <DeleteIcon
                        fontSize="small"
                        onClick={() => handleRemoveLabel(label.labelAccessKey)}
                      />
                    </Table.CellStatic>
                    <Table.CellStatic className="mr-10 flex-1">
                      {label.infRespTec.xContato._text}
                    </Table.CellStatic>
                  </div>
                </Table.Row>
              </div>
            );
          })}
        </Table.Root>
      </div>
    </section>
  );
}
