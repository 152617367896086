import { Box, SxProps, Theme } from '@mui/material';
import { useState, ComponentProps, useEffect } from 'react';
import { cardImageStyle, imageContainerStyle } from './styles';
import { verifyImageUrlExists } from '../../../utils/getImageUrl';

type CardImageProps = ComponentProps<'div'> & {
  src: string;
  fallbackSrc: string;
  alt?: string;
  fallbackAlt?: string;
  containerStyle?: SxProps<Theme>;
  style?: React.CSSProperties;
  fallbackStyle?: React.CSSProperties;
  width?: number;
  height?: number;
};

export default function CardImage({
  src,
  fallbackSrc,
  alt,
  fallbackAlt,
  containerStyle = {},
  style,
  width,
  height,
  fallbackStyle,
  className,
  ...rest
}: CardImageProps) {
  const [imageSrc, setImageSrc] = useState(fallbackSrc);
  const [browser, setBrowser] = useState<string | undefined>(undefined); // Use useState for reactivity

  useEffect(() => {
    // Identifying the browser
    const userAgent = navigator.userAgent;
    if (userAgent.includes('Chrome') && !userAgent.includes('Edge')) {
      setBrowser('Google Chrome');
    } else if (userAgent.includes('Firefox')) {
      setBrowser('Mozilla Firefox');
    } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      setBrowser('Safari');
    } else if (userAgent.includes('Edge')) {
      setBrowser('Microsoft Edge');
    } else if (userAgent.includes('Trident')) {
      setBrowser('Internet Explorer');
    } else {
      setBrowser('Navegador desconhecido');
    }
  }, []);

  const handleImageError = () => {
    setImageSrc(fallbackSrc);
  };

  useEffect(() => {
    const verifyImageExists = async () => {
      const imageExists = await verifyImageUrlExists(src);
      if (imageExists) {
        setImageSrc(src);
        return;
      }

      setImageSrc(fallbackSrc);
    };

    verifyImageExists();
  }, [fallbackSrc, src]);

  const appliedStyle = imageSrc === fallbackSrc ? fallbackStyle : style;

  return (
    <Box sx={{ ...imageContainerStyle, ...containerStyle } as SxProps<Theme>}>
      <div {...rest}>
        {imageSrc === fallbackSrc && (
          <img
            alt={fallbackAlt}
            src={imageSrc}
            width={width}
            height={height}
            onError={handleImageError}
            style={{
              ...cardImageStyle,
              ...appliedStyle,
            }}
            className={className}
          />
        )}
        {imageSrc === src &&
          (browser === 'Google Chrome' ||
            browser === 'Microsoft Edge' ||
            browser === 'Safari' ||
            browser === 'Navegador desconhecido') && (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img
              title={imageSrc === fallbackSrc ? fallbackAlt : alt}
              src={imageSrc}
              width={width}
              height={height}
              onError={handleImageError}
              style={{
                border: 'none',
                pointerEvents: 'auto',
                ...cardImageStyle,
                ...appliedStyle,
                background: 'transparent',
              }}
              className={className}
            />
          )}
      </div>
      {imageSrc === src && browser === 'Mozilla Firefox' && (
        <div
          className="flex h-full w-full items-center justify-center"
          {...rest}
        >
          <iframe
            title={imageSrc === fallbackSrc ? fallbackAlt : alt}
            src={imageSrc}
            width={width}
            height={height}
            onError={handleImageError}
            style={{
              border: 'none',
              pointerEvents: 'auto',
              ...cardImageStyle,
              ...appliedStyle,
              background: 'transparent',
            }}
            className={className}
          />
        </div>
      )}
    </Box>
  );
}
