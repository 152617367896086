import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import EditButton from '../EditButton';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import Loading from '../Loading';
import Main from '../Main';
import SearchInput from '../SearchInput';
import Table from '../Table';
import { useNewsTypesContent } from './useNewsTypeContent';

type NewsTypeContentProps = {
  isModal?: boolean;
  onTableRowClick: (id: number, name: string) => void;
  onAddNewsTypeClick?: () => void;
};

export default function NewsTypeContent({
  isModal,
  onTableRowClick,
  onAddNewsTypeClick,
}: NewsTypeContentProps) {
  const {
    newsTypes,
    newsTypesLength,
    newsTypesShown,
    inputRef,
    isFetching,
    isSearchInputDirty,
    sortField,
    newsTypesShownLength,
    canEditOrder,
    handleDragEnd,
    handleButtonCancelEditClick,
    handleChangeEditOrderButtonClick,
    defineItemsShown,
    handleSearch,
    handleCancelSearch,
    handleSortTable,
  } = useNewsTypesContent();

  return (
    <>
      {!isModal && (
        <ListingHeader
          add
          onAddIconClick={onAddNewsTypeClick}
          pdfTop="3rem"
          pdf={false}
        >
          <div className="mt-4">
            <div className="mb-4 flex justify-between">
              <h1 className="text-2xl">Tipo de notícia</h1>
              <EditButton
                setContentList={defineItemsShown}
                contentList={newsTypes?.items ?? []}
                finishMessage="Salvar ordem"
                canEdit={canEditOrder}
                editMessage="Editar ordem"
                cancelMessage="Cancelar ordenação"
                onCancelEditClick={handleButtonCancelEditClick}
                onEditButtonClick={handleChangeEditOrderButtonClick}
              />
            </div>
            <SearchInput
              ref={inputRef}
              onSearch={handleSearch}
              // onButtonClick={handleCancelSearch}
              showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
              disabled={canEditOrder}
            />
          </div>

          <Table.Head>
            <Table.Cell
              items={newsTypesShown}
              type="name"
              onSort={handleSortTable}
              isSortActive={sortField === 'name'}
            >
              Tipo de notícia
            </Table.Cell>
          </Table.Head>
        </ListingHeader>
      )}

      <Main isModal={isModal}>
        {isFetching && <Loading />}
        {!isFetching && newsTypes && (
          <Table.Root
            itemType="tipos de notícias"
            itemsLength={newsTypesLength}
            className={`${isModal ? 'max-h-[60vh]' : ''}`}
            paddingTop="0"
          >
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                isDropDisabled={!canEditOrder}
                droppableId="category-products"
                type="list"
                direction="vertical"
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {newsTypesShown.map((newsType, index) => (
                      <Table.Row
                        key={newsType.id}
                        onClick={() =>
                          onTableRowClick(newsType.id, newsType.name)
                        }
                      >
                        <Draggable
                          isDragDisabled={!canEditOrder}
                          draggableId={newsType.id.toString()}
                          index={index}
                          key={newsType.id}
                        >
                          {(provided) => (
                            <div
                              className="flex w-full items-center justify-between bg-backgroundPaper"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Table.CellStatic>
                                <span>{newsType.name}</span>
                              </Table.CellStatic>

                              {canEditOrder && (
                                <img
                                  src="/icons/drag.png"
                                  alt="Ícone para arrastar tipo de notícia"
                                  className="mr-4 size-5 cursor-grab"
                                />
                              )}
                            </div>
                          )}
                        </Draggable>
                      </Table.Row>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Table.Root>
        )}
      </Main>

      <Footer
        className={`${isModal ? 'mb-1 ml-[5vw] w-[90vw]' : ''}`}
        current={newsTypesShownLength}
        total={newsTypesLength}
        itemType="Tipos de notícias"
      />
    </>
  );
}
