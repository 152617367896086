import HeaderModalManageItem from '../../components/HeaderModalManageItem';
import Input from '../../components/Input';
import LogsDuimpContent from '../../components/LogsDuimpContent';
import Modal from '../../components/Modal';
import { useLogsDuimp } from './useLogsDuimp';

export default function LogsDuimp() {
  const {
    isManageModalOpen,
    totalProdutosComSucesso,
    totalProdutosComErro,
    totalFabricantesComSucesso,
    totalFabricantesComErro,
    dtLancamento,
    register,
    handleTableRowClick,
    handleCloseManageModal,
  } = useLogsDuimp();

  return (
    <>
      <LogsDuimpContent onTableRowClick={handleTableRowClick} />

      <Modal
        isOpen={isManageModalOpen}
        onClose={handleCloseManageModal}
        cancelIcon={false}
        height="100vh"
        contentStyle="overflow-y-auto pb-16"
      >
        <HeaderModalManageItem
          title={'Log'}
          mainButtonContent={''}
          onCancelClick={handleCloseManageModal}
          isEditable={false}
        />

        <div className="bottom-14 mb-4 mt-14 flex flex-col gap-8">
          <Input
            type="text"
            placeholder="Data do lançamento"
            disabled={true}
            {...register('dtLancamento')}
            value={dtLancamento}
          />
          <Input
            type="text"
            placeholder="Total de Produtos com Sucesso"
            disabled={true}
            {...register('totalProdutosComSucesso')}
            value={totalProdutosComSucesso}
          />
          <Input
            type="text"
            placeholder="Total de Produtos com Erro"
            disabled={true}
            {...register('totalProdutosComErro')}
            value={totalProdutosComErro}
          />
          <Input
            type="text"
            placeholder="Total de Fabricantes com Sucesso"
            disabled={true}
            {...register('totalFabricantesComSucesso')}
            value={totalFabricantesComSucesso}
          />
          <Input
            type="text"
            placeholder="Total de Fabricantes com Erro"
            disabled={true}
            {...register('totalFabricantesComErro')}
            value={totalFabricantesComErro}
          />
        </div>
      </Modal>
    </>
  );
}
