import { SelectChangeEvent } from '@mui/material';
import { useState, useEffect } from 'react';
import { verifyScreenIsLessThan } from '../../utils/verifyScreenIsLessThan';
import { useSearchParams } from '../../hooks/useSearchParams';
import { useQueryClient } from 'react-query';
import { categoriesCache } from '../../constants/requestCacheName';
import { Categories } from '../../types/categories';

export const useCategoriesNavbar = () => {
  const [selectedCategory, setSetselectedCategory] = useState('');
  const [currentLineId, currentCategoryId] = useSearchParams(
    'lineId',
    'categoryId',
  );

  const queryClient = useQueryClient();
  const categories = queryClient.getQueryData<Categories>(
    `${categoriesCache}${currentLineId}`,
  );

  useEffect(() => {
    if (categories && categories.items.length > 0) {
      setSetselectedCategory(categories.items[0].name);
    }
  }, [categories]);

  const isResponsive = verifyScreenIsLessThan(900);

  const handleSelectPartGroup = (event: SelectChangeEvent) => {
    setSetselectedCategory(event.target.value);
  };

  const categoryId = currentCategoryId ? +currentCategoryId : null;

  return {
    categories,
    categoryId,
    selectedCategory,
    currentLineId,
    isResponsive,
    handleSelectPartGroup,
  };
};
