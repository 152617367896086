import { AxiosResponse } from 'axios';
import { getContactDemandsPaginated } from '../../services/contactUs';
import Table from '../Table';
import { useContactContent } from './useContactContent';
import SerchInput from '../SearchInput';
import Main from '../Main';
import Loading from '../Loading';
import LoadMore from '../LoadMore';
import Footer from '../Footer';
import ListingHeader from '../ListingHeader';
import { ContactStatus } from '../../enums/contactStatus';
import Filter from '../Filter';

export type Service = (
  page: number,
  direction?: 'ASC' | 'DESC',
  limit?: number,
) => Promise<AxiosResponse>;

type ContactUsContentProps = {
  isModal?: boolean;
  contactsInUse?: Record<'id', number>[];
  service?: Service;
  handleTableRowClick: (
    id: number,
    empresa: string,
    mensagem: string,
    status: ContactStatus,
    resposta: string,
    dtCriacao: Date,
    dtConclusao: Date,
    email: string,
    nome: string,
  ) => void;
};

export default function ContactUsContent({
  isModal = false,
  contactsInUse,
  service = getContactDemandsPaginated,
  handleTableRowClick,
}: ContactUsContentProps) {
  const {
    endPageRef,
    contactsShown,
    contactFilter,
    inputRef,
    domains,
    isFetchingContacts,
    isLoadMoreFetching,
    domainFilter,
    isSearchInputDirty,
    contactsLength,
    sortField,
    subjects,
    subjectFilter,
    handleFilterBySubject,
    handleEnterClickSearchInput,
    handleCancelSearch,
    handleSearch,
    handleSortTable,
    handleFilterByDomain,
    handleFilterByType,
    handleSearchDatabase,
  } = useContactContent();

  function formatDateTime(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function truncateText(text: string, maxLength: number = 20): string {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  }

  function handleMouseEnter(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    text: string,
  ) {
    const element = event.currentTarget;
    if (element.offsetWidth < element.scrollWidth) {
      element.setAttribute('title', text);
    } else {
      element.removeAttribute('title');
    }
  }

  return (
    <section className="relative col-span-2 h-screen overflow-hidden">
      <ListingHeader pdf={false}>
        <div className="mt-4 ">
          <h1 className="mb-4 text-2xl">Fale Conosco - Chamados</h1>
          <Filter
            title="Assunto"
            filters={[
              {
                filter: 'Todos',
                isCurrent: !subjectFilter,
                name: 'Todos',
              },
              ...(subjects?.items ?? []).map((subject) => ({
                filter: subject?.name,
                isCurrent: subjectFilter === subject?.name,
                name: subject?.name,
              })),
            ]}
            onFilter={handleFilterBySubject}
          />
          <Filter
            title="Status"
            filters={[
              {
                name: 'Pendente',
                filter: 'PENDENTE',
                isCurrent:
                  !contactFilter || contactFilter?.toUpperCase() === 'PENDENTE',
              },
              {
                name: 'Arquivado',
                filter: 'ARQUIVADO',
                isCurrent: contactFilter?.toUpperCase() === 'ARQUIVADO',
              },
              {
                name: 'Concluído',
                filter: 'CONCLUIDO',
                isCurrent: contactFilter?.toUpperCase() === 'CONCLUIDO',
              },
            ]}
            onFilter={handleFilterByType}
          />
          <Filter
            title="Dominio"
            filters={[
              {
                filter: 'Todos',
                isCurrent: !domainFilter || domainFilter === 'Todos',
                name: 'Todos',
              },
              ...(domains?.items ?? []).map((domain) => ({
                filter: domain?.name,
                isCurrent: domainFilter === domain?.name,
                name: domain?.name,
              })),
            ]}
            onFilter={handleFilterByDomain}
          />

          <SerchInput
            ref={inputRef}
            onSearch={handleSearch}
            onButtonClick={handleSearchDatabase}
            buttonContent="Buscar"
            showCancelOptions={isSearchInputDirty}
            onCancelSearch={handleCancelSearch}
            onKeyDown={handleEnterClickSearchInput}
          />
        </div>
        <Table.Head rowStyle="pr-[3.25rem]">
          <div className="flex w-full">
            <Table.Cell
              items={contactsShown}
              type="nome"
              onSort={handleSortTable}
              isSortActive={sortField === 'nome'}
              className="flex flex-1"
            >
              Nome
            </Table.Cell>
            <Table.Cell
              items={contactsShown}
              type="email"
              onSort={handleSortTable}
              className="flex flex-1 truncate"
              onMouseEnter={(e) => handleMouseEnter(e, 'Email Completo')}
            >
              Email
            </Table.Cell>
            <Table.Cell
              items={contactsShown}
              type="telefone"
              onSort={handleSortTable}
              isSortActive={sortField === 'telefone'}
              className="flex flex-1"
            >
              Telefone
            </Table.Cell>
            <Table.Cell
              items={contactsShown}
              type="cpf"
              onSort={handleSortTable}
              isSortActive={sortField === 'cpf'}
              className="flex flex-1"
            >
              CPF
            </Table.Cell>
            <Table.Cell
              items={contactsShown}
              type="empresa"
              onSort={handleSortTable}
              isSortActive={sortField === 'empresa'}
              className="flex flex-1"
            >
              Empresa
            </Table.Cell>
            <Table.Cell
              items={contactsShown}
              type="cidade"
              className="flex flex-1"
            >
              Cidade
            </Table.Cell>
            <Table.Cell items={contactsShown} type="uf" className="flex flex-1">
              UF
            </Table.Cell>
            <Table.Cell
              items={contactsShown}
              type="status"
              onSort={handleSortTable}
              isSortActive={sortField === 'status'}
              className="flex flex-1"
            >
              Status
            </Table.Cell>
            <Table.Cell
              items={contactsShown}
              type="dtCriacao"
              className="flex flex-1"
            >
              Criado em
            </Table.Cell>
          </div>
        </Table.Head>
      </ListingHeader>

      <Main className="pb-0 ">
        {isFetchingContacts && <Loading loadMore />}
        {!isFetchingContacts && contactsShown && (
          <Table.Root
            itemType="Contatos"
            itemsLength={contactsLength}
            className="max-h-[calc(100vh-28rem)] 1.5xl:max-h-[calc(100vh-26rem)] overflow-y-auto"
          >
            {contactsShown.map((contact) => {
              const isInUse = contactsInUse?.some(
                (item) => item.id === contact.id,
              );
              return (
                <Table.Row
                  key={contact.id}
                  className={isInUse ? 'cursor-default opacity-50' : ''}
                  onClick={() =>
                    !isInUse &&
                    handleTableRowClick(
                      contact.id ?? 0,
                      contact.empresa ?? '',
                      contact.mensagem,
                      contact.status,
                      contact.resposta ?? '',
                      contact.dtCriacao,
                      contact.dtConclusao,
                      contact.email,
                      contact.nome,
                    )
                  }
                >
                  <td
                    className="flex flex-1 truncate"
                    onMouseEnter={(e) => handleMouseEnter(e, contact.nome)}
                  >
                    {truncateText(contact.nome, 18)}
                  </td>
                  <td
                    className="flex flex-1 truncate"
                    onMouseEnter={(e) => handleMouseEnter(e, contact.email)}
                  >
                    {truncateText(contact.email, 18)}
                  </td>
                  <td className="flex flex-1">{contact.telefone}</td>
                  <td className="flex flex-1">{contact.cpf}</td>
                  <td className="flex flex-1">
                    {truncateText(contact.empresa ?? '', 14)}
                  </td>
                  <td className="flex flex-1">
                    {truncateText(contact.cidade?.nome ?? '')}
                  </td>
                  <td className="flex flex-1">{contact.cidade?.uf.sigla}</td>
                  <td className="flex flex-1">{contact.status}</td>
                  <td className="flex flex-1">
                    {contact.dtCriacao
                      ? formatDateTime(new Date(contact.dtCriacao))
                      : ''}
                  </td>
                </Table.Row>
              );
            })}
            <LoadMore
              isModal={isModal}
              isContentFetching={isFetchingContacts}
              isLoadMoreFetching={isLoadMoreFetching}
              ref={endPageRef}
            />
          </Table.Root>
        )}
      </Main>

      <Footer
        current={contactsShown.length}
        total={contactsLength}
        itemType="Contatos"
        className="absolute bottom-0 mr-0"
      />
    </section>
  );
}
