import { useState } from 'react';

export const useEdit = () => {
  const [canEdit, setCanEdit] = useState(false);

  const handleEdit = () => {
    setCanEdit(true);
  };

  const handleUnedit = () => {
    setCanEdit(false);
  };

  return {
    canEdit,
    handleEdit,
    handleUnedit,
  };
};
