import Footer from '../../components/Footer';
import Header from '../../components/Header';
import BackgroundTopoNews from '../../assets/images/backgrounds/BuffaloNews.svg';
import { useNews } from './useNews';
import { twMerge } from 'tailwind-merge';
import NewsCard from '../../../components/NewsCard';
import SearchInput from '../../../components/SearchInput';
import SearchBar from '../../components/SearchBar';

export default function BuffaloNews() {
  const {
    newsFilter,
    newsTypes,
    newsListShown,
    // cardWidthInPx,
    // newsSelectedId,
    videoRefs,
    handlePlay,
    setNewsCardWidthInPx,
    inputRef,
    isSearchInputDirty,
    handleSearchDatabase,
    handleSearch,
    handleCancelSearch,
    handleFilterByType,
    handleCardClick,
    handleEnterClickSearchInput,
    // handleCloseModal,
  } = useNews();

  return (
    <>
      <div className="sticky top-0 z-30">
        <Header />
        <div className="flex flex-col justify-between gap-2 bg-zinc-100/90 p-2 backdrop-blur-sm md:px-5 md:py-4 xl:flex-row">
          <div className="flex flex-wrap items-center gap-2">
            {/* <span
                  onClick={() => handleFilterByType('Todos')}
                  className={twMerge(
                    'cursor-pointer rounded-lg border-2 p-2',
                    !newsFilter || newsFilter === 'Todos'
                      ? 'link-news link-news-select'
                      : 'link-news link-news-not-select',
                  )}
                >
                  Todos
                </span> */}
            {newsTypes?.map((newsType) => (
              <span
                key={newsType.id}
                onClick={() => handleFilterByType(newsType.name)}
                className={twMerge(
                  'cursor-pointer rounded-lg border-2 p-2',
                  newsFilter === newsType.name
                    ? 'link-news link-news-select'
                    : 'link-news link-news-not-select',
                )}
              >
                {newsType.name}
              </span>
            ))}
          </div>
          <div className="w-full xl:w-[30rem]">
            <SearchBar
              ref={inputRef}
              onChange={handleSearch}
              onKeyDown={handleEnterClickSearchInput}
              placeholderInput="Buscar"
            />
            {/* <SearchInput
              ref={inputRef}
              onSearch={handleSearch}
              // onButtonClick={handleSearchDatabase}
              // buttonContent="Buscar em Buffalo News"
              cancelIcon={isSearchInputDirty}
              // showCancelOptions={isSearchInputDirty}
              onCancelSearch={handleCancelSearch}
              containerSx={{
                borderRadius: '0.5rem 0.5rem 0.5rem 0.5rem',
              }}
              onKeyDown={handleEnterClickSearchInput}
              className='text-zinc-50'
              sx={{
                borderRadius: '0.5rem',
                width: '100%',
                height: '3rem',
                background: '#fff',
                border: 'solid 2px #d4d4d8',
              }}
            /> */}
          </div>
        </div>
      </div>
      <div className="z-20 flex min-h-screen flex-col">
        <main className="flex-grow">
          <img
            src={BackgroundTopoNews}
            alt=""
            width={1000}
            height={200}
            className="w-full"
          />
          <div className="mb-10 mt-5">
            <div className="mx-4 md:mx-5">
              <div
                // style={{
                //   gridTemplateColumns: `repeat(auto-fill, minmax(${newsCardWidthInPx}px, 1fr))`,
                // }}
                className="mt-5 grid min-h-[600px] gap-4 md:grid-cols-auto-fill"
              >
                {newsListShown?.map((news, index) => (
                  <NewsCard
                    index={index}
                    key={news.id}
                    news={news}
                    ref={(ref) => {
                      videoRefs.current[index] = ref;
                    }}
                    onVideoPlay={handlePlay}
                    setNewsCardWidthInPx={setNewsCardWidthInPx}
                    // onCloseModal={handleCloseModal}
                    onCardClick={handleCardClick}
                    // newsSelectedId={newsSelectedId}
                  />
                ))}
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}
